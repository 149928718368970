import { CheckboxGroup, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { FocusedArea, Strings } from '@hBits.Core';
import React from 'react';
import { CustomCheckBox } from '../styled-componets';
import { removeValueFromArray } from './utils';
import styles from '../channel-partner.module.scss';
import OtherCity from './OtherCity';

interface FocusedAreaProps {
  selectedFocusedArea: string[];
  selectedFocusedAreaOther: string;
  showOtherCity: boolean;
  errorInFocusedAreaOther: boolean;
  updateSelectedFocusedArea: (event: any) => void;
  updateSelectedFocusedAreaOther: (event: any) => void;
}

/**
 * @param {Object} props
 * @returns {JSX.Element} - A React Functional Component
 */
const FocusedAreaSelectCheckboxes: React.FC<FocusedAreaProps> = ({
  selectedFocusedArea,
  updateSelectedFocusedArea,
  showOtherCity,
  selectedFocusedAreaOther,
  updateSelectedFocusedAreaOther,
  errorInFocusedAreaOther,
}) => {
  const handleChangeAssetType = (focusedAreaKey: FocusedArea) => {
    if (selectedFocusedArea.includes(FocusedArea[focusedAreaKey])) {
      const filteredFocusedArea = removeValueFromArray(
        selectedFocusedArea,
        FocusedArea[focusedAreaKey],
      );
      updateSelectedFocusedArea([...filteredFocusedArea]);
      return;
    }

    updateSelectedFocusedArea([
      ...selectedFocusedArea,
      FocusedArea[focusedAreaKey],
    ]);
  };

  const isChecked = (key: string): boolean => {
    return selectedFocusedArea.includes(FocusedArea[key as FocusedArea]);
  };

  return (
    <Flex direction={'column'} gap={2}>
      <Flex direction={['column', 'row']} columnGap={2}>
        <Text fontWeight={'bold'}>*{Strings.focusedAreaMicroMarket}</Text>
        <Text>({Strings.selectAllThatApplies})</Text>
      </Flex>

      <CheckboxGroup colorScheme="blue" defaultValue={['naruto', 'kakashi']}>
        <SimpleGrid columns={[1, 2, 4]} spacing="2rem">
          {Object.keys(FocusedArea).map((key: string) => (
            <Flex margin={0} key={key}>
              <CustomCheckBox
                className={isChecked(key) ? '' : 'unchecked'}
                onClick={() => handleChangeAssetType(key as FocusedArea)}
              >
                <div className={isChecked(key) ? 'circle checked' : 'circle'}>
                  {isChecked(key) && (
                    <i className="fa-regular fa-circle-check"></i>
                  )}
                </div>
                <p>{FocusedArea[key as FocusedArea]}</p>
              </CustomCheckBox>
            </Flex>
          ))}
          {showOtherCity && (
            <OtherCity
              selectedOtherCity={selectedFocusedAreaOther}
              updateSelectedFocusedArea={updateSelectedFocusedAreaOther}
              errorInFeild={errorInFocusedAreaOther}
            />
          )}
        </SimpleGrid>
      </CheckboxGroup>
    </Flex>
  );
};

export default FocusedAreaSelectCheckboxes;
