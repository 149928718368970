import { Flex, Image, Text } from '@chakra-ui/react';
import { Strings } from '@hBits.Core';
import { Colors } from 'apps/customer-portal-web/src/theme';

const ReferralSubmit = (props: any) => {
  const { setSubmitted } = props;
  const handleSendAnotherClick = () => setSubmitted(false);
  return (
    <Flex
      bg={Colors.white}
      flex={1}
      mt={5}
      py={20}
      px={10}
      borderRadius={10}
      flexDirection={'column'}
      alignItems="center"
      justifyContent={'center'}
    >
      <Image src={'../../../../../assets/check_circle.svg'} w={20} h={20} alt="" />
      <Text fontSize={'2xl'} as="b" color={Colors.headerColor} mt={2}>
        Thank You!
      </Text>
      <Text
        mt={2}
        w={'50%'}
        fontSize={'sm'}
        as="b"
        align="center"
        color={Colors.grey600}
      >
        {Strings.referralSubmitMsg}
      </Text>
      <Text
        color={Colors.headerColor}
        as="b"
        mt={10}
        cursor="pointer"
        onClick={handleSendAnotherClick}
      >
        {Strings.sendAnother}
      </Text>
    </Flex>
  );
};

export default ReferralSubmit;
