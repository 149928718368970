import {
  Modal,
  Flex,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import Iframe from 'react-iframe';
import React, { FC } from 'react';

interface CustomFrameProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  zohoDocLink?: string;
  investmentId?: string;
  isTopUp?: boolean;
  onCloseFrame?: any;
}
const CustomFrame: FC<CustomFrameProps> = (props) => {
  const { isOpen, onClose, title, zohoDocLink = '', onCloseFrame } = props;
  const finalRef = React.useRef(null);
  return (
    <div>
      <Modal
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={'5xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onCloseFrame();
            }}
          />
          <ModalBody>
            <Flex justify={'center'}>
              <Iframe
                url={zohoDocLink}
                width="90%"
                height="500px"
                id=""
                className=""
                display="block"
                position="relative"
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CustomFrame;
