import { HTTPMethod, fetchAsync } from '@hBits.Core';
import endpoints, { baseApiUrl } from '@hBits.Core/endpoints';

export const dmsUpload = async (data: any) => {
  const req = {
    method: HTTPMethod.POST,
    data: data,
    url: baseApiUrl + endpoints.dms,
  };
  const response = await fetchAsync(req);
  return response;
};
