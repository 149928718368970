import { FunctionComponent, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import './channelPartner.module.scss';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ChakraProvider,
  Flex,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
export interface ChannelPartnerTncModalProps {
  onModalClose: () => void;
}

const ChannelPartnerTncModal: FunctionComponent<
  ChannelPartnerTncModalProps
> = ({ onModalClose }) => {
  const redirectToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    addEventListener('focus', redirectToTop);
  }, []);
  return (
    <ChakraProvider>
      <Modal
        isOpen={true}
        onClose={onModalClose}
        isCentered={true}
        size={'5xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ padding: 0 }}>
            <Flex
              bg={'#1a295b'}
              flex={1}
              pt={3}
              pb={3}
              pl={[0, 0, '60px', '60px']}
              pr={[0, 0, '60px', '60px']}
              justifyContent={'space-between'}
              style={{ zIndex: 10000000 }}
            >
              <Text fontSize="3xl" ml={5} color={'white'}>
                Terms and Conditions
              </Text>
              <Flex
                data-test="button-close-terms-conditions"
                className="titleCloseBtnTnc"
                onClick={onModalClose}
              >
                <Flex
                  borderWidth={'1px'}
                  borderColor="white"
                  justifyContent={'center'}
                  alignItems="center"
                  borderRadius={50}
                  h={'35px'}
                  w={'35px'}
                  style={{ cursor: 'pointer' }}
                >
                  <Text color="white" fontSize={'20px'}>
                    X
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex
              style={{
                overflow: 'scroll',
                height: '70vh',
              }}
              pl={[0, 0, '50px', '50px']}
              pr={[0, 0, '50px', '50px']}
              pt={'30px'}
              className="modalBody main-container-flex"
              flexDirection={'column'}
            >
              <Flex flexDirection={'column'}>
                <Text fontSize="3xl" color={'#1a295b'} as="b">
                  Channel Partner Terms And Conditions
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={5}>
                  This Platform (defined later) is owned and operated by{' '}
                  <span className="hBit_red_span">
                    hBits Proptech Private Limited{' '}
                  </span>
                  <span className="term-in-use">(“hBits”)</span>, a company
                  incorporated under the laws of India and having its registered
                  office located at Ground Floor, G-4, Court Chambers, 35, Sir
                  Vithaldas Thackersey Marg, New Marine Lines, Mumbai – 400020,
                  Maharashtra, India.
                  <br /> <br />
                  The terms on hBits’ Platform (defined later), “we”, “our” and
                  “us” in these Terms and Conditions refer to hBits. By
                  accessing{' '}
                  <a href="https://www.hbits.co" style={{ fontWeight: 'bold' }}>
                    {' '}
                    www.hbits.co{' '}
                  </a>
                  (“ <span className="term-in-use">Platform</span>”) and any of
                  its pages, You (“
                  <span className="term-in-use">Channel Partner / CP</span>”)
                  have offered to assist hBits in marketing the investment
                  opportunities available with hBits on a non-exclusive basis,
                  in consideration for a Brokerage / Commission subject to the
                  terms and conditions contained herein. hBits and CP shall
                  hereinafter be collectively referred to as "
                  <span className="term-in-use">Parties</span>” and individually
                  as “<span className="term-in-use">Party</span>” wherever the
                  context so permits.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  1. Business Process:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <ol
                    type="a"
                    style={{ display: 'grid', gap: '10px', marginLeft: '35px' }}
                  >
                    <li>
                      The primary duty of CP shall be to market/refer the
                      investment opportunity in any such commercial real estate
                      asset identified and finalized by hBits to such
                      prospective investors including individuals, corporate
                      entities or such other entity being eligible to invest in
                      such asset under Applicable Law. hBits will set and
                      intimate to CP the monetary investment limit and other
                      considerations setting out the Terms and Conditions of the
                      investment. CP shall not make any representations or give
                      any warranty or guarantee in respect of the asset that is
                      not in absolute conformity with the information and
                      material provided by hBits for this purpose, without the
                      express written authority of hBits.
                    </li>
                    <li>
                      CP shall collect all such information required by hBits in
                      respect of each prospective investor that CP shall refer
                      to hBits for investment in the asset. CP shall have risk
                      profiled the prospective investor before referring them to
                      hBits for investment in the asset. CP shall assist
                      prospective investors to sign up on the Platform and make
                      the investment as more fully set out on the said Platform
                      or shall add the prospective investor on the CP portal
                      i.e.
                      <a
                        href="https://hbits.co/channel-partner"
                        style={{ fontWeight: 'bold' }}
                      >
                        {' '}
                        https://hbits.co/channel-partner{' '}
                      </a>{' '}
                      (“<span className="term-in-use">CP Portal</span>”).
                    </li>
                    <li>
                      Each such investment made by the investors on the CP
                      portal shall form a fraction of the sum required to fund
                      the asset completely and the asset shall be considered
                      ‘fully funded’ when the entire consideration for purchase
                      of the asset is committed to hBits independently as well
                      as through its CP’s.
                    </li>
                    <li>
                      These Terms and Conditions describe the terms applicable
                      to CP’s use of CP Portal.
                    </li>
                    <li>
                      By using the CP Portal, the CP expressly represents that
                      he/she has read and understood these Terms and Conditions
                      and agrees to be bound by these Terms and Conditions.
                      Furthermore, by using the CP Portal, the CP expressly
                      represents that (a) he/she has the capacity to be bound by
                      these Terms and Conditions; (b) if he/she is acting on
                      behalf of a company or other entity, then he/she has the
                      relevant authority to bind such company or entity; (c)
                      he/she will not violate any rights of hBits, including
                      Intellectual Property Rights such as copyright or
                      trademark rights; and (d) he/she has not been or is not
                      suspended or removed from the Platform and / or CP Portal
                      by hBits for any reason whatsoever.
                    </li>
                  </ol>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  2. Definitions:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Unless the context otherwise requires, for the purpose of
                  these Terms and Conditions, the following terms shall have the
                  meaning ascribed to them hereunder:
                  <ol
                    type="a"
                    style={{
                      display: 'grid',
                      gap: '10px',
                      marginLeft: '35px',
                      marginTop: '10px',
                    }}
                  >
                    <li>
                      “<span className="term-in-use">Applicable Law</span>”
                      shall mean Indian law and includes the constitution,
                      statute, law, rule, regulation, ordinance, judgment,
                      order, decree, authorization, or any published directive,
                      guideline, requirement or governmental restriction having
                      the force of law, or any determination by, or
                      interpretation of any of the foregoing by, any Government
                      Authority, which has the force of law in India, as in
                      effect at the relevant time;
                    </li>
                    <li>
                      “
                      <span className="term-in-use">Intellectual Property</span>
                      ” includes but is not limited to concepts, creations,
                      discoveries, inventions, improvements, know how, trade
                      secrets, trademarks, service marks, designs, devices,
                      compositions, methods, processes, systems, algorithms,
                      works of authorship, flowcharts, papers, models, sketches,
                      formulas, proprietary techniques, research projects, and
                      other confidential and proprietary information, patents,
                      copyrights, computer programming code, databases,
                      documents, instruction manuals, records, notes, or any
                      written and verbal instructions/ comments, in all forms,
                      whether or not copyrightable, patentable or capable of
                      being protected under common law or its equivalent
                      overseas laws, including Applicable Laws;
                    </li>
                    <li>
                      “
                      <span className="term-in-use">
                        Intellectual Property Rights
                      </span>
                      ” means and includes all vested, contingent and future
                      intellectual property rights of hBits including but not
                      limited to goodwill, reputation, rights in know-how and
                      Confidential Information, copyright, trademarks, logos,
                      service marks, patents, devices, plans, models, diagrams,
                      specifications, source and object code materials, data and
                      processes, design rights, know how, trade secrets,
                      inventions, database rights (whether registered or
                      unregistered) and any products or registrations for the
                      protection of these rights and all renewals and extensions
                      thereof existing in any part of the world whether now
                      known or created in the future;
                    </li>
                    <li>
                      “<span className="term-in-use">CP</span>” as aforesaid
                      includes CP’s permitted assigns, successors, heirs and
                      legal representatives, unless the context requires
                      otherwise;
                    </li>
                  </ol>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  3. Tenure and Termination:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <ol
                    type="a"
                    style={{ display: 'grid', gap: '10px', marginLeft: '35px' }}
                  >
                    <li>
                      These Terms and Conditions shall be valid from the date of
                      acceptance of these terms by the CP until either of the
                      Parties conveys its intention to terminate the arrangement
                      and the other party acknowledges the same in writing
                    </li>
                    <li>
                      hBits may terminate this arrangement immediately for
                      reasons including but not limited to any misrepresentation
                      or liability arising out of incorrect marketing, incorrect
                      usage of brand/logo etc., by the CP. In such event, no
                      Brokerage / Commission shall be paid to the CP. In other
                      case, both the Parties may terminate by giving 15 days
                      prior written notice to the other Party.
                    </li>
                    <li>
                      In case at the time of termination, hBits has approached
                      and begun discussions with any prospective investor
                      introduced by CP, hBits can continue its negotiations with
                      such prospective investor, and shall pay the agreed upon
                      Brokerage / Commission to the CP.
                    </li>
                    <li>
                      Upon such termination of this understanding, CP shall
                      remove all representations associating CP with hBits in
                      vouchers, webpages, investment memos and all other media;
                      and shall discontinue use of all such information and
                      material issued to CP by hBits.
                    </li>
                  </ol>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  4. Brokerage / Commission:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <ol
                    type="a"
                    style={{ display: 'grid', gap: '10px', marginLeft: '35px' }}
                  >
                    <li>
                      In lieu of the arrangement captured herein, the Brokerage
                      / Commission shall be communicated to the CP from time to
                      time via email.
                    </li>
                    <li>
                      The Brokerage / Commission may be specific to the
                      investment opportunity being marketed.
                    </li>
                  </ol>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  5. Information Sharing:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  By using the CP Portal, CP confirms that CP is of the required
                  legal age and competent to form a binding contract and is not
                  a person barred from receiving services under the laws as
                  applicable in India. CP also agree to provide true, accurate,
                  current, complete and updated information about themselves as
                  well as for the prospective investors. If CP provide any
                  information that is untrue, inaccurate, not current or
                  incomplete (or becomes untrue, inaccurate, not current or
                  incomplete), or we have reasonable grounds to suspect that
                  such information is untrue, inaccurate, not current or
                  incomplete, we have the right to suspend or terminate CP
                  account from the CP Portal. CP undertakes to indemnify and
                  keep indemnified hBits for any loss, claim, damage, costs
                  (including legal and professional costs) or whatsoever
                  relating to any abuse or violation including those aforesaid.
                  <br />
                  <br />"<span className="term-in-use">CP Information</span>" is
                  defined as any information provided to us by the CP during the
                  registration process, in the feedback area or through any
                  email feature including but not limited to information
                  regarding CP’s / prospective investor’s name, address,
                  telephone number, date of birth, gender, e-mail address,
                  organization, etc. (“
                  <span className="term-in-use">Personal Information</span>”).
                  We will protect CP Information in accordance with our Privacy
                  Policy (separately uploaded). hBits will treat all the
                  Personal Information provided by CP(s) confidential. If the CP
                  uses the Platform / CP Portal, CP solely will be responsible
                  for maintaining the confidentiality of CP Login ID and
                  Password and for restricting access to CP computer, and CP
                  agree to accept responsibility for all activities that occur
                  under CP Login ID or Password. We shall not be liable to any
                  person for any loss or damage which may arise as a result of
                  any failure by CP to protect CP password or account. CP
                  undertakes to indemnify and keep indemnified hBits for any
                  loss, claim, damage, costs (including legal and professional
                  costs) or whatsoever relating to CP responsibility or
                  liability or failure including those aforesaid. CP agree to
                  notify us immediately of any unauthorized use of CP account or
                  any other breach of security. We reserve the right to refuse
                  service, terminate accounts, or remove or edit content in its
                  sole discretion.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  6. CP Agree and Confirm that CP shall:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <ol
                    type="a"
                    style={{ display: 'grid', gap: '10px', marginLeft: '35px' }}
                  >
                    <li>
                      use the services provided by us, for lawful purposes only
                      and shall comply with all Applicable Laws and regulations
                      while using the Platform and CP Portal.
                    </li>
                    <li>
                      provide authentic and true information in all instances
                      where such information is requested of the CP. We reserve
                      the right to confirm and validate the information and
                      other details provided by the CP at any point of time. If
                      upon confirmation CP details are found to be false (wholly
                      or partly), we have the right and the sole discretion to
                      reject the registration and debar the CP from using the
                      Platform and CP Portal and/or other affiliated products
                      without prior intimation whatsoever
                    </li>
                    <li>
                      access the Platform and CP Portal and transaction thereon
                      at its sole risk and shall use its best judgment before
                      entering into any transaction.
                    </li>
                    <li>
                      agree that the use of the Platform and CP Portal does not
                      amount to the creation of an attorneyclient relationship.
                      Any transmission, receipt or use of the Platform and CP
                      Portal and information and materials contained therein, or
                      sending an e-mail to our offices, will not create or
                      constitute an attorney-client relationship.
                    </li>
                    <li>
                      make themselves aware of the laws of their respective
                      country or those applicable to them in relation to any of
                      the matters described in these pages. If the CP chooses to
                      access the the Platform and CP Portal then it shall be
                      deemed to be done on his/ her own initiative and he/she
                      shall be held responsible for compliance with applicable
                      local, national or international laws.
                    </li>
                  </ol>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  7. Restrictions on use:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  CP agrees and confirms that CP shall not use the Platform and
                  CP Portal for any of the following purposes:
                  <ol
                    type="a"
                    style={{
                      marginTop: '10px',
                      display: 'grid',
                      gap: '10px',
                      marginLeft: '35px',
                    }}
                  >
                    <li>
                      license, sell, rent, lease, transfer, assign, distribute,
                      host, or otherwise commercially exploit the Platform and
                      CP Portal, without express written consent of hBits;
                    </li>
                    <li>
                      disseminating any unlawful, harassing, libelous, abusive,
                      threatening, harmful, vulgar, obscene, or otherwise
                      objectionable material/content;
                    </li>
                    <li>
                      transmitting material that encourages conduct that
                      constitutes a criminal offence, results in civil liability
                      or otherwise breaches any relevant laws, regulations or
                      code of practice;
                    </li>
                    <li>
                      gaining unauthorized access to other computer systems;
                    </li>
                    <li>
                      access the Platform and CP Portal in order to build a
                      similar or competitive service;
                    </li>
                    <li>
                      interfering with any other person's use or enjoyment of
                      the Platform and CP Portal;
                    </li>
                    <li>
                      breaching and violating any Applicable Laws, rules or
                      regulations;
                    </li>
                    <li>
                      interfering or disrupting networks or web sites connected
                      to the Platform and CP Portal;
                    </li>
                    <li>
                      making, transmitting or storing electronic copies of
                      materials protected by copyright without the permission of
                      the copyright owner;
                    </li>
                    <li>
                      collecting or storing any personally identifiable
                      information from the Platform and CP Portal from other
                      CP’s of the Platform and CP Portal without their express
                      permission;
                    </li>
                    <li>
                      post or transmit, or cause to be posted or transmitted,
                      any communication or solicitation designed or intended to
                      obtain password, access to another CP’s account, or
                      private information from any CP;
                    </li>
                    <li>
                      create or send unwanted email (spam) to any other CP;
                    </li>
                    <li>
                      impersonate any person or entity, or falsely state or
                      otherwise misrepresent your affiliation with a person or
                      entity;
                    </li>
                    <li>
                      infringe upon the Intellectual Property Rights of hBits,
                      other CP’s, or any third party, while accessing or using
                      the Platform and CP Portal;
                    </li>
                    <li>
                      post or transmit, or cause to be posted or transmitted,
                      any content that is harmful, infringing, libelous,
                      defamatory, abusive, offensive, obscene, pornographic,
                      pedophilic, invasive of another's privacy, hateful, or
                      racially, ethnically objectionable, disparaging, relating
                      or encouraging money laundering or otherwise violates any
                      Applicable Law or right of any third party; or that
                      threatens the unity, integrity, defense, security or
                      sovereignty of India, friendly relations with foreign
                      states, or public order or causes incitement to the
                      commission of any cognizable offence or prevents
                      investigation of any offence or is insulting any other
                      nations;
                    </li>
                    <li>
                      encouraging or enabling any other individual to do any of
                      the foregoing.
                    </li>
                  </ol>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  8. Representations, warranties and responsibilities:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <ol
                    type="a"
                    style={{ display: 'grid', gap: '10px', marginLeft: '35px' }}
                  >
                    <li>
                      CP represents that they have full right, power and
                      authority to enter into and carry out the terms of this
                      understanding and have been and the person accepting these
                      Terms and Conditions, are on the date of accepting these
                      terms, duly authorized by all necessary and appropriate
                      corporate or other actions necessary to accept these Terms
                      and Conditions and that there are no prior commitments,
                      arrangements or agreements with any other person or an
                      entity which might bar, hamper with, or preclude the
                      carrying out of these Terms and Conditions.
                    </li>
                    <li>
                      CP has and shall always comply with all requirements under
                      Applicable Law that they shall be subject to at any point
                      during the Tenure to perform the duties as mentioned
                      herein. CP shall furnish all such information and
                      documents requested for by hBits forthwith during the
                      existence of this understanding.
                    </li>
                    <li>
                      CP shall have sole responsibility for breach of any
                      Applicable Law by them during the course of extending
                      their services under this understanding.
                    </li>
                    <li>
                      CP shall not make, receive or accept any secret income,
                      profit or other benefit in connection with this
                      understanding from any person/entity except as agreed
                      hereunder while performing the duties/services as
                      mentioned in Terms and Conditions.
                    </li>
                    <li>
                      CP shall bear all costs and expenses incurred by them in
                      performing their obligations under this understanding.
                    </li>
                    <li>
                      CP shall act diligently and in good faith towards hBits
                      and prospective investors and seek to enhance the
                      reputation of hBits and shall not make any statements, or
                      take any other actions whatsoever, to disparage, defame,
                      sully or compromise the goodwill, name, brand or
                      reputation of hBits or any of its affiliates or that could
                      likely injure, hinder or interfere with the business,
                      business relationships or goodwill of hBits or its
                      affiliates.
                    </li>
                    <li>
                      CP to market on its own responsibility and liability the
                      properties listed during the Tenure and to provide
                      services to prospective investors in the areas of sales
                      specified for the listed properties on a non-exclusive
                      basis for end customers. CP hereby agrees and acknowledges
                      that such authorization and appointment as a CP is on a
                      non-exclusive basis and that the properties may be sold or
                      provided to end customers by hBits or other channel
                      partners of hBits.
                    </li>
                    <li>
                      CP shall be independent from hBits and shall not be an
                      agent or representative of, or in any way be entitled to
                      act on behalf or in the name of, hBits in connection with
                      the sale of the properties or the rendering of services.
                    </li>
                    <li>
                      CP shall in no event divulge the Brokerage / Commission
                      communicated to them to any third party in any case
                      whatsoever.
                    </li>
                    <li>
                      CP shall be responsible and liable for any wrong
                      information and unauthorized commitment given to their
                      clients / prospective investors on behalf of hBits.
                    </li>
                    <li>CP shall be responsible for:</li>
                  </ol>
                  <ul
                    style={{
                      marginTop: '10px',
                      display: 'grid',
                      gap: '10px',
                      marginLeft: '35px',
                    }}
                  >
                    <li>
                      adding their respective leads and linked leads on CP
                      dashboard on the Platform
                    </li>
                    <li>
                      conducting regular webinars for their respective clients /
                      customers with hBits.{' '}
                    </li>
                    <li>
                      ensuring that each of their clients / customers shall
                      complete the entire end-to-end investment process
                      including the KYCs on the Platform.{' '}
                    </li>
                    <li>
                      ensuring that the balance investment money and the PAS 4
                      is signed by the respective clients / customers.
                    </li>
                    <li>
                      all or any activities as and when assigned by hBits.
                    </li>
                  </ul>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  9. Disclaimer of Liability and Warranty:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  The content published on the Platform may include inaccuracies
                  or errors, including but not limited to pricing errors or
                  arithmetical errors. We do not guarantee the accuracy of, and
                  disclaim all liability for any errors or other inaccuracies
                  relating to the information and description of the content,
                  products, and services. We expressly reserve the right to
                  correct any pricing errors or arithmetical calculations on the
                  Platform and/or on pending reservations made under an
                  incorrect price or calculation. hBits makes no representation
                  about the suitability of the information, Software, products,
                  and services contained on this site for any purpose, and the
                  inclusion or offering of any products or services on this site
                  does not constitute any endorsement or recommendation of such
                  products or services. All such information, software,
                  products, and services are provided on "AS IS" basis without
                  express or implied warranties, representations, endorsements
                  or conditions of any kind. hBits disclaims all warranties and
                  conditions that this Platform, its services or any email sent
                  from hBits, its affiliates, and/or their respective or
                  associated service providers are free of viruses or other
                  harmful components. hBits hereby disclaims all warranties and
                  conditions with regard to this information, software,
                  products, and services, including all implied warranties and
                  conditions of merchantability, fitness for a particular
                  purpose, title, and no infringement. hBits and its affiliates
                  have no liability and will make no refund in the event of any
                  delay, cancellation, strike, force majeure or other causes
                  beyond their direct control, and they have no responsibility
                  for any additional expense omissions delays or acts of any
                  government or authority. In no event shall hBits and/or its
                  affiliates be liable for any direct, indirect, punitive,
                  incidental, special, or consequential damages arising out of,
                  or in any way connected with, CP’s access to, display of or
                  use of the Platform or with the delay or inability to access,
                  display or use this Platform (including, but not limited to,
                  CP’s reliance upon opinions appearing on the Platform; any
                  computer viruses, information, software, linked sites,
                  products, and services obtained through this site; or
                  otherwise arising out of the access to, display of or use of
                  the Platform) whether based on a theory of negligence,
                  contract, tort, strict liability, or otherwise, and even if
                  hBits and/or its affiliates their respective service providers
                  have been advised of the possibility of such damages. The CP
                  understands that the Platform is used by him/her solely at
                  his/her own risk, cost and liability.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  10. Use of Cookies (as applicable):
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <ol
                    type="a"
                    style={{ display: 'grid', gap: '10px', marginLeft: '35px' }}
                  >
                    <li>
                      We use data collection devices such as “cookies” on
                      certain pages of the Platform / CP Portal to manage our
                      CP’s sessions, store preferences, serve ads, track
                      information, help analyze our web page flow, measure
                      promotional effectiveness, and promote trust and safety
                    </li>
                    <li>
                      The types of information cookies collect may include
                      personal information such as your name, your IP address,
                      contact details, personal preferences, and details of your
                      use of the Platform / CP Portal.
                    </li>
                    <li>
                      You have the ability to either accept or decline the use
                      of cookies on your computer / device, whether you are
                      registered with us or not. Typically, you can configure
                      your browser to not accept cookies. However, declining the
                      use of cookies may limit your access to certain features
                      of the Platform / CP Portal.
                    </li>
                    <li>
                      In order to avail services from us, we may share your
                      personal information to third parties and/or regulatory
                      bodies, subject to Applicable Law. We may also allow
                      third-party advertising companies and marketing services
                      companies to serve ads and other content when you visit
                      the Platform / CP Portal and elsewhere on the internet and
                      in other media. These third-party service providers may
                      place third-party cookies and use the information about
                      your visits, buying behavior, browsing history,
                      preferences, to this Platform / CP Portal and elsewhere to
                      serve ads and other content to you. We cannot control or
                      access cookies used by third-party service providers
                    </li>
                    <li>
                      In addition, we may provide your personal information to
                      marketing services companies on an anonymous and aggregate
                      basis to allow them to market their products or services,
                      or for other marketing purposes. This may be information
                      we received from you offline and online
                    </li>
                  </ol>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  11. Intellectual Property Right
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  The content and information contained within the Platform / CP
                  Portal or delivered to CP in connection with CP’s use of our
                  Platform / CP Portal is the property of hBits and any other
                  third party (where applicable). The trademark, trade names and
                  logos (the "<span className="term-in-use">Trade Marks</span>
                  ") that are used and displayed on our Platform / CP Portal
                  include registered and unregistered Trade Marks of us and
                  other third parties. Nothing on our Platform / CP Portal
                  should be construed as granting any license or right to use
                  any Trade Marks displayed on our Platform / CP Portal. We
                  retain all proprietary rights on our Platform / CP Portal. CP
                  is prohibited from using the same without written permission
                  of hBits of such or such other parties. The materials on this
                  Platform / CP Portal are protected by copyright and no part of
                  such materials may be modified, reproduced, stored in a
                  retrieval system, transmitted (in any form or by any means),
                  copied, distributed, used for creating derivative works or
                  used in any other way for commercial or public purposes
                  without the prior written consent of hBits.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  12. Limitation on Liability:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  By using the Platform / CP Portal, you expressly agree that
                  such use is at your sole risk. The Platform / CP Portal and
                  related services are provided on an "As is", "As available"
                  and "With all faults" basis. Neither hBits nor any of its
                  respective officers, directors, employees, agents, third-party
                  content providers, designers, contractors, distributors,
                  merchants, sponsors, licensors or the like (collectively,
                  "Affiliates") warrant that use of the Platform / CP Portal or
                  related services will be uninterrupted or errorfree. Neither
                  hBits nor its Affiliates warrant the accuracy, integrity,
                  completeness, availability or timeliness of the content
                  provided in the Platform / CP Portal or the materials or
                  services offered in the Platform / CP Portal now or in the
                  future. hBits and its Affiliates specifically disclaim all
                  warranties, whether expressed or implied, including but not
                  limited to warranties of title, merchantability, fitness for a
                  particular purpose or non-infringement of the Platform / CP
                  Portal, information on the Platform / CP Portal or the results
                  obtained from use of the Platform / CP Portal or related
                  services. hBits and its Affiliates accepts no responsibility
                  for updating any party of the Platform / CP Portal or the
                  content therein.
                  <br />
                  <br />
                  Under no circumstances will hBits or its Affiliates be liable
                  to you or anyone else for any direct, consequential,
                  incidental, special, exemplary, punitive or other indirect
                  damages, including but not limited to lost profits, trading
                  losses, unauthorized access, systems failure, communications
                  line failure, internet failure or damages that result from use
                  or loss of use of the Platform / CP Portal, content,
                  inconvenience or delay. This is true even if hBits has been
                  advised of the possibility of such damages or losses.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  13. Arbitration:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  If any dispute arises between you and hBits during your use of
                  the Platform / CP Portal or thereafter, in connection with the
                  validity, interpretation, implementation or alleged breach of
                  any provision of the Terms and Conditions, or the documents
                  they incorporate by reference, the dispute shall be referred
                  to a sole Arbitrator who shall be an independent and neutral
                  third party identified by hBits. The place of arbitration
                  shall be Mumbai, Maharashtra, India. The arbitration
                  proceedings shall be in the English Language and shall be
                  governed by Arbitration & Conciliation Act, 1996
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  14. Governing Law and Jurisdiction
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  These Terms and Conditions shall be construed in accordance
                  with the Applicable Laws of India without giving effect to any
                  principles of conflicts of laws. The Courts at Mumbai shall
                  have exclusive jurisdiction in any proceedings or disputes
                  arising in relation to or out of these Terms and Conditions
                  and its interpretation thereof. hBits causes the control and
                  maintenance of this Platform / CP Portal from India.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  15. Notices:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Except as explicitly stated otherwise, any notices shall be
                  given by postal mail to hBits at Ground Floor, G-4, Court
                  Chambers, 35, Sir Vithaldas Thackersey Marg, New Marine Lines,
                  Mumbai – 400020, City, Maharashtra, 400020 India (in the case
                  of hbits) or to the email address you provide to us during the
                  registration process (in your case). Notice shall be deemed
                  given 24 hours after email is sent, unless the sending party
                  is notified that the email address is invalid. Alternatively,
                  we may give you notice by certified mail, postage prepaid and
                  return receipt requested, to the address provided to us during
                  the registration process. In such case, notice shall be deemed
                  given 3 days after the date of mailing.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  16. SMS and Voice Communications
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  All CP’s who register on our Platform / CP Portal agree,
                  acknowledge and accept as per the Terms and Conditions and
                  that even if they are registered under NDNC (National Do Not
                  Call) or DND (Do Not Disturb), by accepting the terms, hBits
                  is authorised to call and assist the CP and/or SMS/E-Mail the
                  CP’s by virtue of them having voluntarily registered in{' '}
                  <a href="https://www.hbits.co" style={{ fontWeight: 'bold' }}>
                    www.hbits.co
                  </a>{' '}
                  . hBits, will not be responsible for any NDNC Registry
                  regulation that will come into play.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  17. Survival of Terms After Agreement Ends
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Notwithstanding any other provisions of this Terms of Use or
                  the Privacy Policy, or any general legal principles to the
                  contrary, any provision of this Terms of Use that imposes or
                  contemplates continuing obligations on the user will survive
                  the expiration or termination of this Terms of Use.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  18. Severability:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  If any of the Terms and Conditions shall be deemed invalid,
                  void or for any reason unenforceable, such Terms and
                  Conditions shall be deemed severable and shall not affect the
                  validity and enforceability of the remaining Terms and
                  Conditions mentioned herein.
                </Text>
              </Flex>

              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  19. Assignment:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  CP agrees that their rights and obligations contained herein
                  may not be assigned or transferred by CP without the written
                  consent of hBits. hBits may transfer its rights and
                  obligations (also known as "assign") without your prior
                  express consent.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  20. Amendments and Modifications:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits reserves the right to delete, modify, change, update or
                  discontinue any or all of the information on the Platform / CP
                  Portal without giving notice to any CP, individual, group of
                  individuals, institutions and any such governing bodies.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  21. Password Protection
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  We do not represent or warrant that the Platform / CP Portal
                  will be available and meet CP’s requirements, that access will
                  not be interrupted, that there will be no delays, failures,
                  errors or loss of transmitted information, that no viruses or
                  other contaminating or destructive properties will be
                  transmitted or that no damage or loss will occur to CP’s
                  computer system. CP have sole responsibility for adequate
                  protection and back up of data and/or equipment and for
                  undertaking reasonable and appropriate precautions to scan for
                  computer viruses or other destructive properties. We make no
                  representations or warranties regarding the accuracy,
                  functionality or performance of any third party software that
                  may be used in connection with the Platform / CP Portal.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  22. Confidentiality:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Except as otherwise provided herein or with the consent of the
                  other party hereto, each of the parties agrees that all
                  information including, without limitation, the terms of this
                  understanding, business and financial information, investor,
                  customer and vendor lists and pricing and sales information,
                  business plans, details of transaction between hBits and
                  prospective investors, quarterly analysis reports, data,
                  concerning either party, or any of their respective
                  Affiliates, provided by or on behalf of any of them shall
                  remain strictly confidential and secret and shall not be
                  utilized, directly or indirectly by the party receiving such
                  information for its own business purposes or for any other
                  purpose, except and solely to the extent that any such
                  information is generally known or available to the public
                  through a source or sources other than such party hereto or
                  its Affiliates. Notwithstanding the foregoing, each party is
                  hereby authorized to deliver a copy of any such information
                  (a) to any person pursuant to an order issued by any court or
                  administrative agency, (b) to its accountants, attorneys or
                  other agents (including employees and investors on a need to
                  know basis) on a confidential basis and (c) otherwise as
                  required by Applicable Law, rule, regulation or legal process.
                  The party receiving confidential information will not
                  translate, modify, adapt, de-compile, disassemble the
                  information, data, etc., except as specifically agreed to by
                  the other party.
                  <br />
                  <br />
                  Parties hereby agree and acknowledge that monetary damages
                  would not be a sufficient remedy for a breach of this clause
                  and that the suffering party shall be entitled to specific
                  performance or any other injunctive relief as a remedy in
                  equity for any such breach of this clause. Any remedy shall
                  not be deemed to be exclusive or all-inclusive and shall be in
                  addition to any and all other remedies which may be available
                  to the suffering Party in law or equity. The Confidentiality
                  clause shall survive the termination of this understanding.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  23. Investigations
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits reserves the right to investigate complaints or reported
                  violations of these Terms and Conditions and to take any
                  action we deem necessary and appropriate. Such action may
                  include but not be limited to reporting any suspected unlawful
                  activity to law enforcement officials, regulators, or other
                  third parties. In addition, we may take action to disclose any
                  information necessary or appropriate to such persons or
                  entities relating to CP’s profiles, e-mail addresses, usage
                  history, IP addresses and traffic information.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  24. Legal Remedies
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits reserves the right to seek all remedies available at law
                  and in equity for violations of these Terms and Conditions by
                  the CP.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  25. Privacy Policy
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Please review our Privacy Policy to understand what kind of
                  information we gather from CP and the specific measures we
                  take to protect CP’ personal information.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  26. Reach us
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  If CP has any questions or comments regarding the Terms and
                  Conditions, please contact us via email at{' '}
                  <a href="mailto:info@hbits.co" style={{ fontWeight: 'bold' }}>
                    info@hbits.co
                  </a>
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};
export default ChannelPartnerTncModal;
