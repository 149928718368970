import { Flex, Input } from '@chakra-ui/react';
import { Strings } from '@hBits.Core';
import styles from '../channel-partner.module.scss';
import { ErrorMessage } from '../styled-componets';

interface FocusedAreaOtherProps {
  selectedOtherCity: string;
  errorInFeild: boolean;
  updateSelectedFocusedArea: (event: any) => void;
}

/**
 * @param {Object} props
 * @returns {JSX.Element} - A React Functional Component
 */
const OtherCity: React.FC<FocusedAreaOtherProps> = ({
  selectedOtherCity,
  errorInFeild,
  updateSelectedFocusedArea,
}) => {
  const RequiredTagAlphabets = () => (
    <ErrorMessage>*{Strings.onlyAlphabetsAllowed}</ErrorMessage>
  );
  const RequiredTag = () => <ErrorMessage>*{Strings.required}</ErrorMessage>;
  return (
    <Flex direction={'column'} width={'100%'}>
      <Input
        className={styles['input_box']}
        placeholder={`*${Strings.specify}`}
        value={selectedOtherCity}
        variant="flushed"
        onChange={(e) => {
          updateSelectedFocusedArea(e.target.value);
        }}
      />
      {errorInFeild &&
        (selectedOtherCity === '' ? <RequiredTag /> : <RequiredTagAlphabets />)}
    </Flex>
  );
};

export default OtherCity;
