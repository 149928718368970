import { createReducer } from '../reducerUtils';
import {
  INVESTMENT_USER,
  PROPERTY_INVESTMENT_MODAL,
  SET_LOADER,
  UPDATE_ALL_PROPERTIES,
  UPDATE_GET_ALL_PROPERTIES_STATUS,
  SET_INVESTMENT_PROPERTY_DETAILS,
  DOWNLOAD_FILE_URL,
  FIRST_DASHBOARD,
  INVESTMENT_DOCUMENTS,
} from '../../constants/ReduxConstants';
import { API_REQ_STATUS } from 'apps/customer-portal-web/src/utils';

interface InvestmentReducerType {
  userData: any;
  properties: any;
  propertyData: any;
  propertyDetails: any;
  loader: boolean;
  downloadFileUrl: [];
  getPropertiesStatus: string;
  firstDashboard: boolean;
  investmentDocuments: any;
}

const initialState = {
  userData: null,
  properties: [],
  propertyDetails: {},
  getPropertiesStatus: API_REQ_STATUS.INIT,
  propertyData: {},
  downloadFileUrl: [],
  loader: true,
  firstDashboard: false,
  investmentDocuments: [],
};

export const investMentUser = (
  state: InvestmentReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    propertyData: payload,
  };
};

export const setLoader = (
  state: InvestmentReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    loader: payload,
  };
};

export const updateAllProperties = (
  state: InvestmentReducerType,
  payload: any,
): typeof state => ({
  ...state,
  properties: payload,
});

export const updateGetPropertiesStatus = (
  state: InvestmentReducerType,
  payload: any,
): typeof state => ({
  ...state,
  getPropertiesStatus: payload,
});

export const setInvestmentPropertyDetails = (
  state: InvestmentReducerType,
  payload: any,
): typeof state => ({
  ...state,
  propertyDetails: payload,
});

export const setDownloadFileUrl = (
  state: InvestmentReducerType,
  payload: any,
): typeof state => ({
  ...state,
  downloadFileUrl: payload,
});

export const setFirstDashboard = (
  state: InvestmentReducerType,
  payload: any,
): typeof state => ({
  ...state,
  firstDashboard: payload,
});

export const investmentDocument = (
  state: InvestmentReducerType,
  payload: any,
): typeof state => ({
  ...state,
  investmentDocuments: payload,
});

export default createReducer(initialState, {
  [UPDATE_ALL_PROPERTIES]: updateAllProperties,
  [UPDATE_GET_ALL_PROPERTIES_STATUS]: updateGetPropertiesStatus,
  [PROPERTY_INVESTMENT_MODAL]: investMentUser,
  [SET_LOADER]: setLoader,
  [SET_INVESTMENT_PROPERTY_DETAILS]: setInvestmentPropertyDetails,
  [DOWNLOAD_FILE_URL]: setDownloadFileUrl,
  [FIRST_DASHBOARD]: setFirstDashboard,
  [INVESTMENT_DOCUMENTS]: investmentDocument,
});
