import { Flex, Text } from '@chakra-ui/react';
import { Strings } from '@hBits.Core';
import accountManager from '@hbits.lib/auth/account-manager';
import {
  getChannelParterDeatils,
  getListOfPropertiesInvestedIn,
} from 'apps/customer-portal-web/src/services/dashboard/overview';
import { Colors } from 'apps/customer-portal-web/src/theme';
import { NUM_DIFFERENTIATION } from 'apps/landing-pages-web/src/components/common/utils/helper';
import { useEffect, useState } from 'react';
import SinglePropertyCard from '../../../../InvestmentDashboard/MainSection/Overview/SinglePropertyCard';
import InvestmentDetailCard from './helperComponents/InvestmentDetailCard';

const Overview = () => {
  const [propertyListInvestedIn, setPropertyListInvestedIn] = useState<any>([]);
  const [liveSummaryProperties, setLiveSummaryProperties] = useState<any>();

  const getPropertiesListsFromApi = async () => {
    const listOfPropertiesInvestedIn = await getListOfPropertiesInvestedIn();
    setPropertyListInvestedIn(listOfPropertiesInvestedIn);
  };

  useEffect(() => {
    getPropertiesListsFromApi();
  }, []);

  useEffect(() => {
    getLiveSummaryProperties();
  }, [propertyListInvestedIn]);

  const getLiveSummaryProperties = () => {
    setLiveSummaryProperties(propertyListInvestedIn.investedProperties);
  };
  const getCPDetails = async () => {
    const res = await getChannelParterDeatils();
    if (res) {
      accountManager.setChannelPartnerId(res?.id);
    }
  };
  useEffect(() => {
    getCPDetails();
  }, []);

  return (
    <Flex
      flex={1}
      flexDirection={'column'}
      mb={100}
      minHeight={'100vh'}
      p={[4, 4, 0, 0]}
      pl={[2, 2, 8, 8]}
    >
      <Flex
        mt={0}
        justifyContent={{ base: 'center', md: 'space-between' }}
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems={{ base: 'center', md: 'space-between' }}
      >
        <InvestmentDetailCard
          label={
            window.innerWidth > 990 ? 'Total AUM' : 'Total Amount Invested'
          }
          value={`₹${NUM_DIFFERENTIATION(propertyListInvestedIn?.totalAum)}`}
        />
        <InvestmentDetailCard
          label={'Clients'}
          value={propertyListInvestedIn?.numOfClients}
          isClient={true}
        />
        <InvestmentDetailCard
          label={'Assets Sold'}
          value={propertyListInvestedIn?.investedProperties?.length}
        />
      </Flex>
      <Flex mt={10} flexDirection="column">
        <Flex alignItems={'center'} mb={5}>
          <Text
            fontSize="18px"
            as={'b'}
            color={Colors.headerColor}
            mr={2}
            fontWeight={'bold'}
          >
            {Strings.liveAssetSummary}
          </Text>
        </Flex>
        <Flex gap={'1rem'} flexDirection={'column'}>
          {liveSummaryProperties && (
            <>
              {liveSummaryProperties.map((resource: any) => (
                <SinglePropertyCard
                  propertyId={resource?.propertyId}
                  displayName={resource.propertyName}
                  ownedPercentage={resource?.totalOwnershipPercentage}
                  city={resource?.location[0]?.city}
                  investedValue={resource.totalInvestmentAmount}
                  monthlyRent={resource.totalRentReceived}
                  image={resource.image}
                  showViewDetails={true}
                />
              ))}
            </>
          )}
          {!liveSummaryProperties && (
            <Text color="#535353" fontSize={'18px'}>
              No Property Invested at the moment.
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Overview;
