import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { Environment } from '@hBits.Core';
import { IS_SUBMITTED_PAGE_VISIBLE } from 'apps/customer-portal-web/src/store/constants/ReduxConstants';
import { Colors } from 'apps/customer-portal-web/src/theme';
import { useDispatch, useSelector } from 'react-redux';
import greentick from '../../../assets/kyc/greentick.png';

interface KycSubmittedPageProps {
  holderNumber: number;
}

const KycSubmittedPage = (props: KycSubmittedPageProps) => {
  const { holderNumber } = props;
  const dispatch = useDispatch();
  const selectorState = useSelector((state: any) => state.KycReducer);
  const { isSubmittedPageVisible } = selectorState;
  const updateDetails = () => {
    dispatch({
      type: IS_SUBMITTED_PAGE_VISIBLE,
      payload: {
        ...isSubmittedPageVisible,
        [holderNumber]: false,
      },
    });
  };
  const moveToProperties = () => {
    const hostingRoot = Environment.getInstance().getParam(
      'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
    );
    window.location.assign(hostingRoot + '/properties');
  };

  return (
    <Flex justifyContent={'center'} direction={'column'} w={'100%'}>
      <Flex w={'100%'} justifyContent={'center'}>
        <Image src={greentick} w={{ base: '50px', md: '100px' }} h={{ base: '50px', md: '100px' }} alt=""></Image>
      </Flex>
      <Flex justifyContent={'center'} direction={'column'}>
        <Text data-test='p-details-have-been-submitted' textAlign={'center'}>Details have been Submitted</Text>
        <Text textAlign={'center'}>Thank You for submitting your details.</Text>
        <br />
        <Text textAlign={'center'}>
          You can still update your details till the KYC goes under verification
          process.
        </Text>
      </Flex>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={'center'}
        mt={8}
      >
        <Flex justifyContent={'center'}>
          <Button
            bgColor={Colors.orangeColor}
            _hover={{ bg: Colors.orangeColor }}
            _active={{ bg: Colors.orangeColor }}
            onClick={updateDetails}
          >
            Update Details
          </Button>
        </Flex>
        <Flex
          justifyContent={'center'}
          pt={{ base: 4, md: 0 }}
          pl={{ base: 0, md: 6 }}
        >
          <Button
            pl="30px"
            pr="30px"
            textAlign={'center'}
            bgColor={Colors.orangeColor}
            _hover={{ bg: Colors.orangeColor }}
            _active={{ bg: Colors.orangeColor }}
            onClick={moveToProperties}
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default KycSubmittedPage;
