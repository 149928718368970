import endpoints from 'libs/src/config/endpoints';
import { BlogAxios } from '../../NetworkHelper';

export const fetchAllTestimonials = async (
  channel: string,
  pageNumber = 1,
  size = 100,
): Promise<any> => {
  try {
    let data = await BlogAxios.get(
      `/api${endpoints.testimonials}${channel}&pagination[page]=${pageNumber}&pagination[pageSize]=${size}`,
    );
    return data.data;
  } catch (error) {
    return [];
  }
};
