import { fetchAsync, HTTPMethod } from '@hBits.Core';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';

export const getHolders = (id: any) => {
  const req = {
    method: HTTPMethod.GET,
    data: {},
    url: baseApiUrl + endpoints.customers + id,
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};

export const kycAccountStatus = (id: any) => {
  const req = {
    method: HTTPMethod.GET,
    data: {},
    url: baseApiUrl + endpoints.customers + id + endpoints.kycStatus,
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};
export const updateKycAccountType = async (accountId: any, data: any) => {
  const req = {
    method: HTTPMethod.PUT,
    data: data,
    url:
      baseApiUrl + endpoints.customers + accountId + endpoints.kycAccountType,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};
export const updateBasicDetails = async (
  accountId: any,
  id: any,
  data: any,
) => {
  const req = {
    method: HTTPMethod.PUT,
    data: data,
    url:
      baseApiUrl +
      endpoints.customers +
      accountId +
      endpoints.getHolders +
      '/' +
      id +
      endpoints.updateBasicDetails,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};
export const addBasicDetails = async (accountId: any, data: any) => {
  const req = {
    method: HTTPMethod.POST,
    data: data,
    url: baseApiUrl + endpoints.customers + accountId + endpoints.getHolders,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};

export const addGuardianDetails = async (
  accountId: any,
  holderId: any,
  data: any,
) => {
  const req = {
    method: HTTPMethod.POST,
    data: data,
    url:
      baseApiUrl +
      endpoints.customers +
      accountId +
      endpoints.getHolders +
      '/' +
      holderId +
      endpoints.guardianDetails,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};
export const updateGuardianDetails = async (
  accountId: any,
  holderId: any,
  data: any,
) => {
  const req = {
    method: HTTPMethod.PUT,
    data: data,
    url:
      baseApiUrl +
      endpoints.customers +
      accountId +
      endpoints.getHolders +
      '/' +
      holderId +
      endpoints.guardianDetails,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};

export const addAddressDetails = async (
  accountId: any,
  holderId: any,
  data: any,
) => {
  const req = {
    method: HTTPMethod.POST,
    data: data,
    url:
      baseApiUrl +
      endpoints.customers +
      accountId +
      endpoints.getHolders +
      '/' +
      holderId +
      endpoints.addressDetails,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};
export const updateAddressDetails = async (
  accountId: any,
  holderId: any,
  data: any,
) => {
  const req = {
    method: HTTPMethod.PUT,
    data: data,
    url:
      baseApiUrl +
      endpoints.customers +
      accountId +
      endpoints.getHolders +
      '/' +
      holderId +
      endpoints.addressDetails,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};

export const addBankDetails = async (
  accountId: any,
  holderId: any,
  data: any,
) => {
  const req = {
    method: HTTPMethod.POST,
    data: data,
    url:
      baseApiUrl +
      endpoints.customers +
      accountId +
      endpoints.getHolders +
      '/' +
      holderId +
      endpoints.bankDetails,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};
export const updateBankDetails = async (
  accountId: any,
  holderId: any,
  data: any,
) => {
  const req = {
    method: HTTPMethod.PUT,
    data: data,
    url:
      baseApiUrl +
      endpoints.customers +
      accountId +
      endpoints.getHolders +
      '/' +
      holderId +
      endpoints.bankDetails,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};
export const addNomineeDetails = async (
  accountId: any,
  holderId: any,
  data: any,
) => {
  const req = {
    method: HTTPMethod.POST,
    data: data,
    url:
      baseApiUrl +
      endpoints.customers +
      accountId +
      endpoints.getHolders +
      '/' +
      holderId +
      endpoints.nomineeDetails,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};
export const updateNomineeDetails = async (
  accountId: any,
  holderId: any,
  data: any,
) => {
  const req = {
    method: HTTPMethod.PUT,
    data: data,
    url:
      baseApiUrl +
      endpoints.customers +
      accountId +
      endpoints.getHolders +
      '/' +
      holderId +
      endpoints.nomineeDetails,
  };
  return fetchAsync(req).then((response) => {
    return response;
  });
};

export const dmsUpload = async (data: any) => {
  const req = {
    method: HTTPMethod.POST,
    data: data,
    url: baseApiUrl + endpoints.dms,
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};
export const dmsDelete = async (data: any) => {
  const req = {
    method: HTTPMethod.DELETE,
    data: data,
    url: baseApiUrl + endpoints.dms,
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};

export const leadOwner = (id: any) => {
  const req = {
    method: HTTPMethod.GET,
    data: {},
    url: baseApiUrl + endpoints.customers + id + endpoints.leadOwner,
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};

export const fetchBankData = () => {
  const req = {
    method: HTTPMethod.GET,
    data: {},
    url: baseApiUrl + endpoints.bankData,
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};

export const fetchCountryData = () => {
  const req = {
    method: HTTPMethod.GET,
    data: {},
    url: baseApiUrl + endpoints.countriesList,
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};

export const fetchStateData = (isCountry: string) => {
  const req = {
    method: HTTPMethod.GET,
    data: {},
    url: baseApiUrl + endpoints.countriesList + isCountry + endpoints.states,
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};
