import overviewIcon from '../../assets/overview.svg';
import overviewIconHover from '../../assets/overview_hover.svg';
import clientIcon from '../../assets/clients.svg';
import clientIconHover from '../../assets/clients_hover.svg';
import assetIcon from '../../assets/assets.svg';
import assetIconHover from '../../assets/assets_hover.svg';
import collateralsIcon from '../../assets/collaterals.svg';
import collateralsIconHover from '../../assets/collaterals_hover.svg';
import teamIcon from '../../assets/team.svg';
import teamHoverIcon from '../../assets/team_hover.svg';
import pocIcon from '../../assets/poc.svg';
import pocIconHover from '../../assets/poc_hover.svg';
import faqIcon from '../../assets/faq.svg';
import faqIconHover from '../../assets/faq_hover.svg';
import kycIcon from '../../assets/kycIcon.svg';
import kycIconHover from '../../assets/kycIcon_hover.svg';

export enum NavbarLinkName {
  Overview = 'Overview',
  Kyc = 'KYC',
  Clients = 'Clients',
  Assets = 'Assets',
  Collaterals = 'Collaterals',
  POC = 'POC',
  FAQs = 'FAQs',
  MyTeam = 'My Team',
}

export enum NavbarLinkHash {
  Overview = '#overview',
  Kyc = '#kyc',
  Clients = '#clients',
  Assets = '#assets',
  Collaterals = '#collaterals',
  POC = '#poc',
  FAQs = '#faq',
  MyTeam = '#myteam',
}

export const NavbarLinksName = [
  {
    name: NavbarLinkName.Overview,
    link: NavbarLinkHash.Overview,
    logoSrc: overviewIcon,
    logoSrcHover: overviewIconHover,
  },
  {
    name: NavbarLinkName.Kyc,
    link: NavbarLinkHash.Kyc,
    logoSrc: kycIcon,
    logoSrcHover: kycIconHover,
  },
  {
    name: NavbarLinkName.Clients,
    link: NavbarLinkHash.Clients,
    logoSrc: clientIcon,
    logoSrcHover: clientIconHover,
  },
  {
    name: NavbarLinkName.Assets,
    link: NavbarLinkHash.Assets,
    logoSrc: assetIcon,
    logoSrcHover: assetIconHover,
  },
  {
    name: NavbarLinkName.Collaterals,
    link: NavbarLinkHash.Collaterals,
    logoSrc: collateralsIcon,
    logoSrcHover: collateralsIconHover,
  },
  {
    name: NavbarLinkName.MyTeam,
    link: NavbarLinkHash.MyTeam,
    logoSrc: teamIcon,
    logoSrcHover: teamHoverIcon,
  },
  {
    name: NavbarLinkName.POC,
    link: NavbarLinkHash.POC,
    logoSrc: pocIcon,
    logoSrcHover: pocIconHover,
  },
];
