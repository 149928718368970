import {
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import { Colors } from 'apps/customer-portal-web/src/theme';
import { Strings } from '@hBits.Core';
import { FormInput } from '..';
import { camelCaseToTitle } from 'apps/customer-portal-web/src/utils';

const ReferalTable = (props: any) => {
  const { referralList } = props;
  return (
    <div>
      <TableContainer bg={Colors.white} mt={10}>
        <Table variant="simple" size="lg" overflowX="auto" overflowY="hidden">
          <Thead>
            <Tr>
              <Th>{Strings.name}</Th>
              <Th>{Strings.emailID}</Th>
              <Th>{Strings.mobileNumber}</Th>
              <Th>{Strings.city}</Th>
              <Th>{Strings.status}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {referralList.map((item: FormInput) => (
              <Tr key={item.id}>
                <Td>
                  <Text as="b" color={Colors.blackColor}>
                    {item.firstName} {item.lastName}
                  </Text>
                </Td>
                <Td>
                  <Text fontWeight={'medium'} color={Colors.grey}>
                    {item.emailAddress}
                  </Text>
                </Td>
                <Td>
                  <Text
                    fontWeight={'medium'}
                    color={Colors.grey}
                  >{`${item.mobileCountryCode} ${item.mobileNumber}`}</Text>
                </Td>
                <Td>
                  <Text fontWeight={'medium'} color={Colors.grey}>
                    {item.city}
                  </Text>
                </Td>
                <Td>
                  <Text fontWeight={'medium'} color={Colors.grey}>
                    {camelCaseToTitle(item.status)}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReferalTable;
