export const environment = {
  production: false,
  NEXT_PUBLIC_ENV_NAME: 'Develop',
  NEXT_PUBLIC_ENV_VERSION: '1.0.0.0',
  NEXT_PUBLIC_CORE_API_URL: 'https://apiv3-dev.hbits.co',
  NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT: 'https://dev.hbits.co',
  NEXT_PUBLIC_CUSTOMER_PORTAL_HOSTING_ROOT: 'https://app-dev.hbits.co',
  NEXT_PUBLIC_OPS_PORTAL_HOSTING_ROOT: 'https://ops-dev.hbits.co',
  NEXT_PUBLIC_PARENT_HOSTING_DOMAIN: '.hbits.co',
  NEXT_PUBLIC_API_BASE_URL: 'https://apiv3-dev.hbits.co/api',
  NEXT_PUBLIC_API_BLOG_URL: 'https://blogs-dev.hbits.co',
  NEXT_PUBLIC_BILDESK_PAYMENT_PROVIDER_BASE_URL:
    'https://uat1.billdesk.com/u2/web/v1_2/embeddedsdk',
  NEXT_PUBLIC_NEW_CUSTOMER_PORTAL_HOSTING_ROOT: 'https://cp-dev.hbits.co',
};
