import React, { useState } from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { fullPayScreenSelector } from '../util';
import FullPay1 from './FullPay1';
import FullPay2 from './FullPay2';
import FullPay3 from './FullPay3';

interface EOIStep1Props {
  openZoho?: any;
  setCurrentPas4Value?: any;
}

const FullPay: FC<EOIStep1Props> = (props) => {
  const investmentModal = useSelector(
    (state: any) => state.InvestmentReducer?.propertyData,
  );
  const { fullPay } = investmentModal;
  const [currentFullPayValue, setFullPayValue] = useState(
    fullPayScreenSelector(fullPay),
  );

  const renderFullPayScren = () => {
    switch (currentFullPayValue) {
      case 0:
        return <FullPay1 />;
      case 1:
        return <FullPay2 />;
      case 2:
        return <FullPay3 setFullPayValue={setFullPayValue} />;
      default:
        return <FullPay1 />;
    }
  };

  return renderFullPayScren();
};

export default FullPay;
