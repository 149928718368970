import React from 'react'
import { Flex, Text } from '@chakra-ui/react';
const Home = () => {
  return (
    <Flex
      justifyContent={'center'}
      align="center"
      w="100%"
      h="80vh"
      flexDir={'column'}
    >
      <Text fontSize="6xl">Home</Text>
    </Flex>
  );
};

export default Home