enum FocusedArea {
  Banglore = 'Banglore',
  Mumbai = 'Mumbai',
  Hyderabad = 'Hyderabad',
  Chennai = 'Chennai',
  Delhi = 'Delhi',
  Pune = 'Pune',
  Other = 'Other',
}

export default FocusedArea;
