import React from "react";

interface CpKycContainerHeaderTileProps {
    isClicked: boolean
    handleClick: (id:string) => void
    text:string
    idText:any
}

export default function CpKycContainerHeaderTile(props:CpKycContainerHeaderTileProps) {

    const {isClicked, handleClick, text, idText} = props;

    return (
        <p
              className={
                isClicked
                  ? 'cpkyc_container-header-bold'
                  : 'cpkyc_container-header'
              }
              onClick={() => {
                handleClick(idText);
              }}
            >
              {text}
            </p>
    )
}