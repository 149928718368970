import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useToast,
} from '@chakra-ui/react';
import convertEnumToArray, { Colors, Strings } from '@hBits.Core';
import { getListOfProperties } from 'apps/customer-portal-web/src/services/dashboard/UpcomingProperties';
import { getAllClientInformation } from 'apps/customer-portal-web/src/services/dashboard/client';
import { eventEmitter } from 'apps/landing-pages-web/src/components/events';
import accountManager from 'libs/src/auth/account-manager';
import { TOAST_TIMEOUT } from 'libs/src/constants';
import { channelPartnerInvestmentStages } from 'libs/src/enums/channel-partner-investmentStages.enum';
import { useEffect, useState } from 'react';
import searchIcon from '../../../../../assets/my_team/search-icon.svg';
import { PageTitle } from '../../../styled-components';
import AddClientForm from './AddClient';
import ClientCard from './ClientCard';
import style from './styles/index.module.scss';

let COUNT: any;

const Client = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [isPaginationVisible, setIsPaginationVisible] = useState<boolean>();
  const [isNoRecordFound, setIsNoRecordFound] = useState<boolean>();
  const [totalClients, setTotalClients] = useState<any>();

  const handlePageChange = (nextPage: number, pageSize: number): void => {
    setCurrentPage(nextPage);
    setPageSize(pageSize);
  };

  const [searchQuery, setSearchQuery] = useState<string>();
  const channelPartnerInvestmentStagesArray = convertEnumToArray(
    channelPartnerInvestmentStages,
  );
  const [clientInformation, setClientInformation] = useState<any>();
  const [propertyList, setPropertyList] = useState<any>();
  const [propertyId, setPropertyId] = useState<any>();
  const [investmentStage, setInvestmentStage] = useState<string>();
  const toast = useToast();
  const fetchClientMembers = async () => {
    if (accountManager.getAccountId()) {
      const tempSearchQueryParams = {
        pageSize: 10,
        page: currentPage,
        fetchAll: true,
      };

      let CurrentSeacrhQueryParams: any = { ...tempSearchQueryParams };
      if (propertyId) {
        CurrentSeacrhQueryParams = {
          ...CurrentSeacrhQueryParams,
          propertyId: propertyId,
        };
      }
      if (investmentStage) {
        CurrentSeacrhQueryParams = {
          ...CurrentSeacrhQueryParams,
          investmentStage: investmentStage,
        };
      }
      if (searchQuery) {
        CurrentSeacrhQueryParams = {
          ...CurrentSeacrhQueryParams,
          searchKey: searchQuery,
        };
      }

      const { data, error } = await getAllClientInformation(
        CurrentSeacrhQueryParams,
      );
      if (data?.customers?.length >= 0) {
        setTotalClients(data.totalCustomer);
        setClientInformation([...data.customers]);
        return;
      }
      if (error) {
        toast({
          title: Strings.failed,
          description: Strings.internalServerError,
          status: 'error',
          duration: TOAST_TIMEOUT,
          isClosable: true,
        });
      }
    }
  };

  const setPaginationVisibility = () => {
    if (clientInformation) {
      if (clientInformation?.length < 10 && currentPage == 1) {
        setIsPaginationVisible(false);
      } else {
        setIsPaginationVisible(true);
      }
    }
  };

  const setNoRecordFound = () => {
    if (clientInformation) {
      if (
        clientInformation?.length < 1 &&
        (searchQuery != undefined ||
          investmentStage != undefined ||
          propertyId != undefined)
      ) {
        setIsNoRecordFound(true);
      } else {
        setIsNoRecordFound(false);
      }
    }
  };

  const getWholeAndDecimal = (value: any) => {
    const [whole, decimal] = String(value).split('.');
    return [Number(whole), Number(decimal)];
  };

  const setCountValue = () => {
    const number = totalClients / 10;
    const [whole, decimal] = getWholeAndDecimal(number);
    if (decimal) {
      COUNT = whole * 10 + 10;
    } else {
      COUNT = whole * 10;
    }
  };
  useEffect(() => {
    setCountValue();
  }, [totalClients]);

  useEffect(() => {
    setPaginationVisibility();
    setNoRecordFound();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [clientInformation]);

  const getPropertiesListsFromApi = async () => {
    const listOfProperties = await getListOfProperties();
    setPropertyList(listOfProperties);
  };
  const setSearchKey = (value: any) => {
    if (value?.length > 3) {
      setSearchQuery(value);
    } else {
      setSearchQuery(undefined);
    }
  };
  const handlePropertyId = (value: any) => {
    setPropertyId(value);
  };
  const handleInvestmentStagesChange = (value: any) => {
    setInvestmentStage(value);
  };

  useEffect(() => {
    getPropertiesListsFromApi();
  }, []);

  useEffect(() => {
    fetchClientMembers();
    eventEmitter.subscribe('ClientUpdated', fetchClientMembers);
  }, [currentPage, propertyId, searchQuery, pageSize, investmentStage]);

  return (
    <Flex
      direction={'column'}
      minHeight={'100vh'}
      width={'100%'}
      p={'2rem'}
      pt={0}
      gap={'2rem'}
      mt={['30px', '30px', '30px', '0px']}
    >
      <PageTitle>Clients</PageTitle>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={'space-between'}
      >
        <Flex w={{ base: '100%', md: '60%' }} className={style['my_clients']}>
          <InputGroup className={style['input_group']}>
            <Input
              className={style['input_box']}
              backgroundColor={Colors.white}
              placeholder="Search Client"
              value={searchQuery}
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <InputRightElement
              height={'100%'}
              children={<img src={searchIcon} />}
            />
          </InputGroup>
        </Flex>
        <Flex
          w={{ base: '100%', md: '30%' }}
          justifyContent={{ base: 'center', md: 'flex-end' }}
          mt={['30px', '30px', '0px', '0px']}
        >
          <AddClientForm />
        </Flex>
      </Flex>
      <Flex width={'100%'} flexDirection={{ base: 'column', md: 'row' }}>
        <Flex
          alignItems={'flex-start'}
          width={{ base: '100%', md: '50%' }}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Flex
            width={{ base: '50%', md: '30%' }}
            alignSelf={{ base: 'flex-start', md: 'center' }}
            color={Colors.textGreyColor}
            fontFamily={'Roboto'}
            fontSize={'18px'}
            fontWeight={'normal'}
            letterSpacing={0}
          >
            Filter by status
          </Flex>
          <Flex
            width={{ base: '100%', md: '50%' }}
            alignSelf={'center'}
            pl={{ base: '0', md: '2' }}
          >
            <Select
              size="md"
              pb={0}
              fontSize={{ base: '18px', md: '16px' }}
              borderColor={Colors.bgDarkBlue}
              fontWeight={'bold'}
              variant="flushed"
              placeholder="All stages"
              onChange={(e) => handleInvestmentStagesChange(e.target.value)}
            >
              {channelPartnerInvestmentStagesArray?.map((value: any) => (
                <option key={value.key} value={value.value}>
                  {value?.key}
                </option>
              ))}
            </Select>
          </Flex>
        </Flex>
        <Flex
          width={{ base: '100%', md: '50%' }}
          mt={['30px', '30px', '0px', '0px']}
          justifyContent={'flex-end'}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Flex
            width={{ base: '50%', md: '40%' }}
            alignSelf={{ base: 'flex-start', md: 'center' }}
            color={Colors.textGreyColor}
            fontFamily={'Roboto'}
            fontSize={'18px'}
            fontWeight={'normal'}
            letterSpacing={0}
            justifyContent={{ base: 'flex-start', md: 'flex-end' }}
          >
            Filter by property
          </Flex>
          <Flex
            width={{ base: '100%', md: '55%' }}
            alignSelf={{ base: 'flex-start', md: 'flex-end' }}
            justifyContent={'flex-end'}
            pl={{ base: '0', md: '4' }}
          >
            <Select
              size="md"
              pb={0}
              fontSize={{ base: '18px', md: '16px' }}
              borderColor={Colors.bgDarkBlue}
              fontWeight={'bold'}
              variant="flushed"
              placeholder="Property Name"
              onChange={(e) => handlePropertyId(e.target.value)}
            >
              {propertyList?.map((value: any) => (
                <option key={value.displayName} value={value.id}>
                  {value?.displayName}
                </option>
              ))}
            </Select>
          </Flex>
        </Flex>
      </Flex>
      {isNoRecordFound && (
        <Flex color={Colors.blackColor}>No Record Found</Flex>
      )}
      <Flex direction={'column'} gap={'2rem'}>
        {clientInformation?.map((clientInformation: any) => (
          <ClientCard
            firstName={clientInformation?.firstName}
            lastName={clientInformation?.lastName}
            emailAddress={clientInformation?.emailAddress}
            mobileCountryCode={clientInformation?.mobileCountryCode}
            mobileNumber={clientInformation?.mobileNumber}
            propertyName={clientInformation?.propertyName}
            totalInvestmentValue={clientInformation?.totalInvestmentValue}
            investmentStage={clientInformation?.investmentStage}
            associatedChannelPartnerName={
              clientInformation?.associatedChannelPartnerName
            }
          />
        ))}
      </Flex>
      {/* {isPaginationVisible && (
        <Flex justifyContent={'flex-end'}>
          <PaginationCard currentPageNumber={handlePageChange} COUNT={COUNT} />
        </Flex>
      )} */}
    </Flex>
  );
};

export default Client;
