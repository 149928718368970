export enum InvestmentPropertyStatus {
  NotStarted = 'Not Started',
  PrimaryEOI = 'EOI',
  TopUpEOI = 'Topup EOI',
  PAS4 = 'PAS4',
  FullPay = 'Full Pay',
  RegistrationComplete = 'Registered',
  Abandoned = 'Abandoned',
}

export default InvestmentPropertyStatus;
