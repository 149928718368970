import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPMethod, HTTPStatusCode } from 'libs/src/enums';
import { fetchAsync } from 'libs/src/services/NetworkHelper';

export const addGuardianDetails = async (
  accountId: any,
  holderId: any,
  basicDetailsPayload: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.POST,
      data: basicDetailsPayload,
      url:
        baseApiUrl +
        endpoints.customers +
        accountId +
        endpoints.getHolders +
        '/' +
        holderId +
        endpoints.guardianDetails,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return response.error;
  } catch (error) {
    return [];
  }
};
export const updateGuardianDetails = async (
  accountId: any,
  holderId: any,
  basicDetailsPayload: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      data: basicDetailsPayload,
      url:
        baseApiUrl +
        endpoints.customers +
        accountId +
        endpoints.getHolders +
        '/' +
        holderId +
        endpoints.guardianDetails,
    };

    const response = await fetchAsync(request);

    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return response.error;
  } catch (error) {
    return [];
  }
};
