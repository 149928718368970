import {
  Button,
  Flex,
  Image,
  ModalCloseButton,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
} from '@chakra-ui/react';
import { Colors } from '@hBits.Core';

const CollateralPreview = (props: any) => {
  const {
    baseImage,
    companyLogoUrl,
    watch,
    handleCaptureClick,
    previewRef,
    isPreview,
    step,
    setStep,
  } = props;
  return (
    <Flex
      display={step == 3 ? 'block' : 'flex'}
      justifyContent={'center'}
      width={isPreview ? 'auto' : '100%'}
      pb={10}
      pt={5}
    >
      <Flex
        direction={'column'}
        className="form-container1"
        justifyContent={'center'}
        alignItems={'center'}
        width={isPreview ? 'auto' : '100%'}
        pb={10}
      >
        <Text pt={2} fontSize={'xl'} fontWeight={'bold'}>
          Preview
        </Text>
        <div className="collateral" ref={previewRef}>
          <img className="base-image" src={baseImage}></img>
          <div className="collateral-details">
            <div className="logo-container">
              <Image w={'10rem'} h={'auto'} src={companyLogoUrl} alt="" />
            </div>
            <TableContainer my={4}>
              <Table variant={'simple'} size={'md'} borderColor={Colors.yellow}>
                <Tbody>
                  {watch('companyName') && (
                    <Tr>
                      <Th>Company Name</Th>
                      <Td fontSize={'md'}>{watch('companyName')}</Td>
                    </Tr>
                  )}
                  {watch('personName1') && (
                    <Tr>
                      <Th>Name</Th>
                      <Td fontSize={'md'}>{watch('personName1')}</Td>
                    </Tr>
                  )}
                  {watch('personMobileNumber1') && (
                    <Tr>
                      <Th>Mobile Number</Th>
                      <Td fontSize={'md'}>
                        {watch('personMobileCountryCode1')}{' '}
                        {watch('personMobileNumber1')}
                      </Td>
                    </Tr>
                  )}
                  {watch('personName2') && (
                    <Tr>
                      <Th>Name</Th>
                      <Td fontSize={'md'}>{watch('personName2')}</Td>
                    </Tr>
                  )}
                  {watch('personMobileNumber2') && (
                    <Tr>
                      <Th>Mobile Number</Th>
                      <Td fontSize={'md'}>
                        {watch('personMobileCountryCode2')}{' '}
                        {watch('personMobileNumber2')}
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {!isPreview && <div>Your collaterals are ready for download</div>}
        <div className="button-container">
          {!isPreview && (
            <Flex gap={4} w={['100%', 'auto']}>
              <Button
                flex={1}
                variant={'outline'}
                borderColor={'black'}
                px={10}
                color={'black'}
                py={7}
                fontSize={16}
                maxW={'199px'}
                maxH={'60px'}
                h={'60px'}
                fontWeight={'bold'}
                onClick={() => setStep(1)}
                _hover={{
                  backgroundColor: 'white',
                }}
              >
                Back
              </Button>
              <Button
                flex={1}
                bg="#FFB657"
                onClick={handleCaptureClick}
                mx={1}
                py={7}
                px={10}
                maxW={'199px'}
                maxH={'60px'}
                h={'60px'}
                fontSize={'16px'}
                _hover={{
                  backgroundColor: '#FFB657',
                }}
                color={Colors.blackColor}
              >
                Download
              </Button>
            </Flex>
          )}
        </div>
      </Flex>
      {isPreview && <ModalCloseButton position={'absolute'} size={'lg'} />}
      {step === 3 && (
        <Flex
          position={'absolute'}
          top={'0'}
          left={'0'}
          right={0}
          bottom={0}
          justifyContent="center"
          bgImage="linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2))"
        >
          <Spinner mt={'20%'} mr={'10%'} size={'xl'} thickness={'4px'} />
        </Flex>
      )}
    </Flex>
  );
};

export default CollateralPreview;
