import { Flex, Text, useToast } from '@chakra-ui/react';
import colors from 'libs/src/theme/colors';
import styles from '../index.module.scss';
import UrlShower from './UrlViewer';
import { capitalizeFirstLetter } from '../utils';
import { getFileUploadUrl } from './getFileUploadUrl';
import { useSelector } from 'react-redux';

interface fileUploadProps {
  text?: string;
  name?: string;
  values?: any;
  errors?: any;
  onChange?: any;
  valuesType?: any;
  nameType?: any;
  rejectionReason?: string;
  rejectedReasonName?: string;
  holderNumber: number;
  setFieldTouched: any;
  touched?: any;
  setValues?: any;
  isDisabled?: boolean;
  testId?: string;
}

const fileUpload = (props: fileUploadProps) => {
  const { isAllFieldsDisable } = useSelector((state: any) => state.KycReducer);
  const toast = useToast();
  const {
    text = '*Please Upload Proof',
    name,
    onChange,
    values,
    errors,
    valuesType,
    nameType,
    rejectionReason,
    rejectedReasonName,
    holderNumber,
    setFieldTouched,
    touched,
    isDisabled = true,
    setValues,
    testId,
  } = props;

  const deleteUrl = (value: boolean) => {
    if (value) {
      onChange(name, '');
      onChange(nameType, '');
    }
  };

  const handleChangeUploadFile = async (val: any) => {
    const response = await getFileUploadUrl(val, toast);
    onChange(rejectedReasonName, undefined);
    onChange(name, response?.data?.documentUrl);
    onChange(nameType, capitalizeFirstLetter(response?.data?.documentType));
  };

  return (
    <Flex mt={{ base: 4, md: 8 }} direction={'column'}>
      {values && (
        <UrlShower
          values={values}
          valuesType={valuesType}
          deleteUrl={deleteUrl}
          holderNumber={holderNumber}
          rejectionReason={rejectionReason}
          isDisabledFlag={isDisabled}
        />
      )}
      {!values && (
        <>
          <Text
            fontSize={'12px'}
            color={colors.kycPlaceholder}
            fontWeight={'light'}
          >
            {text}
          </Text>
          <Flex direction={'column'}>
            <label className={styles['fileUpload']}>
              <input
                data-test={`input-${testId}-upload-PAN-card`}
                id="file"
                type="file"
                accept="image/*,.doc, .docx,.pdf"
                onChange={async (val) => {
                  handleChangeUploadFile(val);
                }}
                onBlur={() => setFieldTouched(`${name}`)}
                disabled={isAllFieldsDisable[holderNumber] && isDisabled}
              />
              <Flex
                direction={'column'}
                justifyContent={'center'}
                flex={1}
                color={colors.bgDarkBlue}
              >
                <Text alignSelf={'center'} fontSize={'18px'} fontWeight={'500'}>
                  Choose File
                </Text>
                <Text alignSelf={'center'} fontSize="12px">
                  (Maximum file size 2 MB)
                </Text>
              </Flex>
            </label>
            {errors && touched && (
              <div className={styles['errorRegMessage']}>
                {errors.toString()}
              </div>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default fileUpload;
