import { Button, ChakraProvider, Flex, Image } from '@chakra-ui/react';
import { Environment, UserType, authTokenStorageProvider } from '@hBits.Core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import handShakeImg from '../../assets/handshake-img.svg';
import styles from './channel-partner.module.scss';

const ChannelPartnerLandingPage = () => {
  const navigate = useNavigate();
  const [isUserCP, setIsUserCP] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkIfUserIsAlreadyChannelPartner = () => {
    const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
    if (authTokenModel) {
      setIsLoggedIn(true);
    }

    if (authTokenModel?.userTypes.includes(UserType.ChannelPartner)) {
      setIsUserCP(true);
      return;
    }
    setIsUserCP(false);
  };

  const goToEnrollmentPage = () => {
    navigate('/channel-partner/enrollment');
  };

  const goToDashboard = () => {
    window.location.href =
      Environment.getInstance().getParam(
        'NEXT_PUBLIC_NEW_CUSTOMER_PORTAL_HOSTING_ROOT',
      ) + '/dashboard';
  };

  useEffect(() => {
    checkIfUserIsAlreadyChannelPartner();
  }, []);

  return (
    <ChakraProvider>
      <Flex
        justify={'center'}
        className={styles['cp_wrapper']}
        direction={'column'}
      >
        <Image src={handShakeImg} alt="" />
        <p className={styles['cp_heading']}>Channel Partner Registration</p>
        {isUserCP ? (
          <Button onClick={goToDashboard} className={styles['cp_action_btn']}>
            Go To Dashboard
          </Button>
        ) : (
          <Button
            onClick={goToEnrollmentPage}
            className={styles['cp_action_btn']}
          >
            Enroll
          </Button>
        )}
      </Flex>
    </ChakraProvider>
  );
};

export default ChannelPartnerLandingPage;
