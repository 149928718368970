import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { Colors, Environment, Strings } from '@hBits.Core';
import { Formik } from 'formik';
import React, { useState } from 'react';
import SelectCountryDropdown from '../../../ChannelPartner/helper-components/CountriesDropDown';
import SelectStatesDropdown from '../../../ChannelPartner/helper-components/StatesDropDown';
import styles from '../styles/index.module.scss';
import PhoneInput from 'react-phone-input-2';
import * as Yup from 'yup';
import { addChannelPartner } from 'libs/src/services/modules/channel-partner';
import { TOAST_TIMEOUT } from 'libs/src/constants';
import circleLogo from '../../../../components/Kyc/resources/circleLogo.svg';
import { eventEmitter } from 'apps/landing-pages-web/src/components/events';
import { CustomLink } from '../../../ChannelPartner/styled-componets';
import TncModal from 'libs/src/components/TncModal';
import PrivacyModal from 'libs/src/components/PrivacyModal';

const landingPagesHostingRoot = Environment.getInstance().getParam(
  'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
);

interface AddFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}
interface AddFormProps {
  fetchTeammatesAgain: () => void;
}

interface AddMembersInt {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  country: string;
  state: string;
  city: string;
  emailNote: string;
}

interface AddTeamAPIResponse {
  error: boolean;
  errorMessage: string | null | undefined;
}

const AddFormModal = ({ isOpen, onClose }: AddFormModalProps) => {
  const [countryCode, setCountryCode] = useState(91);
  const [minMobileNumberLength, setMinMobileNumberLength] = useState(22);
  const [isOpenTncModal, setIsOpenTncModal] = useState<boolean>(false);
  const [isOpenPrivacyModal, setIsOpenPrivacyModal] = useState<boolean>(false);
  const toast = useToast();

  const toggleTncModal = () => {
    setIsOpenTncModal(!isOpenTncModal);
  };

  const togglePrivacyModal = () => {
    setIsOpenPrivacyModal(!isOpenPrivacyModal);
  };

  const getInitialValues: () => AddMembersInt = () => {
    let initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      country: '',
      state: '',
      city: '',
      emailNote: '',
    };
    return initialValues;
  };

  const AddMemberSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .min(9, 'Invalid Mobile Number!')
      .max(minMobileNumberLength, 'Invalid Mobile Number!')
      .required('This is required.'),
    firstName: Yup.string()
      .min(3, 'First Name entered is too Short!')
      .max(30, 'First Name entered is too Long!')
      .matches(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/, 'Invalid First Name')
      .required('This is required.'),
    lastName: Yup.string()
      .min(3, 'Last Name entered is too Short!')
      .max(30, 'Last Name entered is too Long!')
      .matches(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/, 'Invalid Last Name')
      .required('This is required.'),
    email: Yup.string()
      .max(40, 'Invalid Email')
      .matches(
        /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
        'Invalid Email',
      )
      .required('This is required.'),
    country: Yup.string().required('This is required.'),
    state: Yup.string().trim()
    .matches(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/, 'Invalid State Name')
    .required('This is required.'),
    city: Yup.string().trim()
    .matches(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/, 'Invalid City Name')
    .required('This is required.'),
    emailNote: Yup.string().trim(),
  });

  const handleSubmit = async (data: any) => {
    const body = { ...data };
    body.mobileNumber = body.mobileNumber.slice(
      countryCode.toString().length,
      body.mobileNumber.length,
    );
    body.mobileCountryCode = `+${countryCode}`;

    if (body.emailNote.trim() === '') {
      body.emailNote = ' ';
    }

    const addedChannelPartnerResponse: AddTeamAPIResponse =
      await addChannelPartner(body);

    if (!addedChannelPartnerResponse.error) {
      toast({
        title: Strings.success,
        description: Strings.addedMember,
        status: 'success',
        duration: TOAST_TIMEOUT,
        isClosable: true,
      });
      onClose();
      return;
    }
    toast({
      title: Strings.failed,
      description: addedChannelPartnerResponse.errorMessage,
      status: 'error',
      duration: TOAST_TIMEOUT,
      isClosable: true,
    });
  };

  return (
    <Modal
      size={['md', '4xl']}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        padding={['15px 10px', '25px 60px']}
        className={styles['add_teams_model_container']}
      >
        <ModalHeader
          backgroundColor={Colors.white}
          color={Colors.blackColor}
          fontSize={['1.2rem', '2rem']}
          className="text-center"
          px={['0.5rem', '1rem']}
        >
          Add Team Member
        </ModalHeader>
        <ModalCloseButton marginRight={'1rem'} marginTop={'1rem'}>
          <img src={circleLogo}></img>
        </ModalCloseButton>
        <ModalBody>
          <Formik
            initialValues={getInitialValues()}
            onSubmit={handleSubmit}
            validationSchema={AddMemberSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              setFieldTouched,
              errors,
              touched,
              isValid,
            }) => (
              <Flex
                justifyContent={'space-between'}
                direction={'column'}
                rowGap={['1rem', '3rem']}
                columnGap={'7rem'}
              >
                <Flex
                  justifyContent={'space-between'}
                  direction={'column'}
                  rowGap={['1.5rem', '3rem']}
                  columnGap={'7rem'}
                  maxHeight={['450px', 'fit-content']}
                  overflowY={'auto'}
                  className={styles['add_team_members_input']}
                >
                  <Flex gap={'1.5rem'} direction={['column', 'row']}>
                    <Flex flex={1} direction={'column'} gap={'1'}>
                      <Input
                        placeholder={'*First Name'}
                        borderRadius={0}
                        borderColor={Colors.greyBorderColor}
                        onChange={handleChange('firstName')}
                        onBlur={() => setFieldTouched('firstName')}
                        value={values.firstName}
                        variant="flushed"
                      />
                      {errors.firstName && touched.firstName && (
                        <div className="errorRegMessage text-capitalize">
                          {errors.firstName.toString()}
                        </div>
                      )}
                    </Flex>
                    <Flex flex={1} direction={'column'} gap={'1'}>
                      <Input
                        placeholder={'*Last Name'}
                        onChange={handleChange('lastName')}
                        onBlur={() => setFieldTouched('lastName')}
                        value={values.lastName}
                        borderRadius={0}
                        borderColor={Colors.greyBorderColor}
                        variant="flushed"
                      />
                      {errors.lastName && touched.lastName && (
                        <div className="errorRegMessage text-capitalize">
                          {errors.lastName.toString()}
                        </div>
                      )}
                    </Flex>
                  </Flex>
                  <Flex gap={'1.5rem'} direction={['column', 'row']}>
                    <Flex flex={1} direction={'column'} gap={'1'}>
                      <Input
                        placeholder={'*Email'}
                        onChange={handleChange('email')}
                        onBlur={() => setFieldTouched('email')}
                        value={values.email}
                        borderRadius={0}
                        borderColor={Colors.greyBorderColor}
                        variant="flushed"
                      />
                      {errors.email && touched.email && (
                        <div className="errorRegMessage text-capitalize">
                          {errors.email.toString()}
                        </div>
                      )}
                    </Flex>
                    <Flex
                      flex={1}
                      direction={'column'}
                      gap={'1'}
                      className={styles['phone_input_parent']}
                      pt={'10px'}
                    >
                      <PhoneInput
                        inputProps={{
                          autoFocus: false,
                          prefix: '+',
                        }}
                        autoFormat={true}
                        country="in"
                        placeholder="*Enter Mobile Number*"
                        enableSearch
                        isValid
                        countryCodeEditable={false}
                        value={values.mobileNumber}
                        onChange={(ph: any, data: any) => {
                          handleChange('mobileNumber')(ph);
                          setCountryCode(data.dialCode);
                          setMinMobileNumberLength(data.format.length || 22);
                        }}
                        onBlur={() => setFieldTouched('mobileNumber')}
                      />
                      {errors.mobileNumber && touched.mobileNumber && (
                        <div className="errorRegMessage text-capitalize">
                          {errors.mobileNumber.toString()}
                        </div>
                      )}
                    </Flex>
                  </Flex>
                  <Flex gap={'1.5rem'} direction={['column', 'row']}>
                    <Flex flex={1} direction={'column'} gap={'1'}>
                      <SelectCountryDropdown
                        selectedCountry={values.country}
                        setSelectedCountry={handleChange('country')}
                        isColorGrey={true}
                      />
                      {errors.country && touched.country && (
                        <div className="errorRegMessage text-capitalize">
                          {errors.country.toString()}
                        </div>
                      )}
                    </Flex>
                    <Flex flex={1} direction={'column'} gap={'1'}>
                      <SelectStatesDropdown
                        selectedCoutry={values.country}
                        selectedState={values.state}
                        setSelectedState={handleChange('state')}
                        isColorGrey={true}
                      />
                      {errors.state && touched.state && (
                        <div className="errorRegMessage text-capitalize">
                          {errors.state.toString()}
                        </div>
                      )}
                    </Flex>
                    <Flex flex={1} direction={'column'} gap={'0.9'}>
                      <Input
                        placeholder={'*City'}
                        onChange={handleChange('city')}
                        onBlur={() => setFieldTouched('city')}
                        value={values.city}
                        borderRadius={0}
                        borderColor={Colors.greyBorderColor}
                        variant="flushed"
                        height={'40px'}
                      />
                      {errors.city && touched.city && (
                        <div className="errorRegMessage text-capitalize">
                          {errors.city.toString()}
                        </div>
                      )}
                    </Flex>
                  </Flex>
                  <Flex flex={1} direction={'column'} gap={1}>
                    <Textarea
                      onChange={handleChange('emailNote')}
                      onBlur={() => setFieldTouched('emailNote')}
                      value={values.emailNote}
                      placeholder="Your Comment Here"
                      resize={'none'}
                    />
                    {errors.emailNote && touched.emailNote && (
                      <div className="errorRegMessage">
                        {errors.emailNote.toString()}
                      </div>
                    )}
                  </Flex>
                  <Text
                    textAlign={'left'}
                    color={Colors.textGreyColor}
                    fontWeight={'light'}
                    fontSize="16px"
                  >
                    {Strings.submitClickInfo}
                    <CustomLink onClick={toggleTncModal}>
                      {Strings.tnc}
                    </CustomLink>
                    {Strings.and}
                    <CustomLink onClick={togglePrivacyModal}>
                      {Strings.pp}
                    </CustomLink>
                  </Text>
                </Flex>
                <Flex justifyContent={'center'}>
                  <Button
                    type={'submit'}
                    py={'1.5rem'}
                    px={'4rem'}
                    bgColor={Colors.orangeColor}
                    _hover={{ bg: Colors.orangeColor }}
                    _active={{ bg: Colors.orangeColor }}
                    onClick={() => handleSubmit()}
                  >
                    SUBMIT
                  </Button>
                </Flex>
              </Flex>
            )}
          </Formik>
          {isOpenTncModal && <TncModal onModalClose={toggleTncModal} />}
          {isOpenPrivacyModal && (
            <PrivacyModal onModalClose={togglePrivacyModal} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const AddMemberForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    eventEmitter.dispatch('teamMemberUpdated');
  };

  return (
    <>
      <Button
        bgColor={Colors.bgDarkBlue}
        color={Colors.orangeColor}
        onClick={() => {
          setIsOpen(true);
        }}
        className={styles['add_team_btn']}
        _hover={{ bg: Colors.bgDarkBlue }}
        _active={{ bg: Colors.bgDarkBlue }}
      >
        <i className="fa-solid fa-plus"></i>
        <span className="px-2" style={{ fontSize: '16px' }}>
          ADD TEAM
        </span>
      </Button>
      <AddFormModal isOpen={isOpen} onClose={handleClose} />
    </>
  );
};

export default AddMemberForm;
