import React from 'react';
import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import colors from 'apps/customer-portal-web/src/theme/colors';
import CustomButton from 'apps/customer-portal-web/src/components/CustomButton';
import {
  DownloadIcon,
  SuccessGreenIcon,
} from 'apps/customer-portal-web/src/assets/customIcons';

interface EOIStep1Props {
  removePgRedirect: any;
  amount?: string;
  recidUrl?: string;
  isSucess?: boolean;
}

const PaymentRecid: FC<EOIStep1Props> = (props) => {
  const { removePgRedirect, amount, recidUrl, isSucess } = props;
  return (
    <>
      <Flex
        width={'100%'}
        flexDirection={'column'}
        pl={[0, 0, 3, 3]}
        mt={[3, 3, 0, 0]}
      >
        <Flex
          width={'60%'}
          borderBottomWidth=".5px"
          borderBottomColor={colors.blackColor}
          pb={3}
          alignItems="center"
        >
          <SuccessGreenIcon height={'25px'} w={'25px'} />
          <Text as="b" fontSize="16px" color={colors.grey200} ml={'5px'}>
            {isSucess ? '  Payment Done!' : 'Payment Failed!'}
          </Text>
        </Flex>
        <Flex mt={3}>
          {isSucess ? (
            <Text fontSize="16px" color={colors.grey}>
              We have received a payment of{' '}
              <Text as="b" fontSize="16px" color={colors.headerColor}>
                {`₹${amount?.toLocaleString()}`}
              </Text>
            </Text>
          ) : (
            <Text fontSize="13px" color={colors.grey} as="b">
              Please Press Proceed to Repay Amount
            </Text>
          )}
        </Flex>
        {isSucess && (
          <Flex alignItems={'center'} mt={'18px'}>
            <a target="_BLANK" href={recidUrl}>
              <DownloadIcon />
            </a>
            <Text fontSize="16px" as="b" color={colors.headerColor} ml={'5px'}>
              Download Payment Receipt
            </Text>
          </Flex>
        )}

        <Flex mt={'100px'} flexDirection="column">
          <Flex>
            <CustomButton
              btnText={'PROCEED'}
              btnPressed={() => removePgRedirect()}
              size={'lg'}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default PaymentRecid;
