import { Flex, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Colors } from '@hBits.Core';
import React, { useState } from 'react';
import { PageTitle } from '../Dashboard/styled-components';
import AddMemberForm from './MyTeams/helper-components/AddMember';
import style from './MyTeams/styles/index.module.scss';
import TeamMembers from './MyTeams/TeamMembers';
import searchIcon from '../../assets/my_team/search-icon.svg';

const MyTeam = () => {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Flex
      direction={'column'}
      minHeight={'100vh'}
      width={'100%'}
      paddingRight={['30px', '0px', '0px', '5rem']}
      gap={'2rem'}
      paddingLeft={['30px', '0px', '0px', '2rem']}
      paddingBottom={'1rem'}
      paddingTop={['1rem', '0']}
    >
      <PageTitle>Teams</PageTitle>
      <Flex direction={['column', 'row']} columnGap={'2rem'} rowGap={'1.2rem'}>
        <Flex flex={1} className={style['my_teams']}>
          <InputGroup className={style['input_group']} flex={1}>
            <Input
              className={style['input_box']}
              backgroundColor={Colors.white}
              placeholder="Search Team"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <InputRightElement
              height={'100%'}
              children={<img src={searchIcon} />}
            />
          </InputGroup>
        </Flex>
        <Flex flex={1} justifyContent={['center', 'flex-end']}>
          <AddMemberForm />
        </Flex>
      </Flex>
      <TeamMembers searchQuery={searchQuery} />
    </Flex>
  );
};

export default MyTeam;
