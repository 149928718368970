import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'apps/customer-portal-web/src/theme';
import styles from '../mainsection.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAddClient } from 'apps/customer-portal-web/src/constants/globalConstants';

interface InvestmentInfoCardProps {
  label: string;
  value: any;
}

const InvestmentInfoCard = ({ label, value }: InvestmentInfoCardProps) => {

  return (
    <Flex
      className={styles['box-shadow-contsiner']}
      w={['100%', '100%', '100%', '30%']}
      bg={Colors.white}
      borderRadius={5}
      flexDirection={'column'}
      justifyContent="center"
      alignItems={'center'}
      py={10}
      borderTopColor={Colors.buttonColor}
      borderTopWidth={2}
      mt={[5, 5, 5, 0]}
    >
      <Text fontSize="2xl" as={'b'} color={Colors.headerColor}>
        {value}
      </Text>
      <Text fontSize="md" color={Colors.grey200} mb={2}>
        {label}
      </Text>
    </Flex>
  );
};

export default InvestmentInfoCard;
