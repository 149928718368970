import * as React from 'react';
import './styles/Faq.scss';
import { faqData } from './assets/faqData';
('./assets/faqData');
import { Col, Container, Row, Accordion, Card } from 'react-bootstrap/';

const Faq = () => {
  const [singleFaq, setSingleFaq] = React.useState(faqData[0]);
  const [singleFaqDes, setSingleFaqDes] = React.useState(faqData[0].items[0]);
  const [selectedItem, setSelectedItem] = React.useState(faqData[0].title);
  const [selectedDesItem, setSelectedDesItem] = React.useState(
    faqData[0].items[0].title,
  );

  const handelHeader = (item: any) => {
    setSingleFaq(item);
    setSingleFaqDes(item.items[0]);
    setSelectedItem(item.title);
    setSelectedDesItem(item.items[0].title);
    const selectedElement = document.querySelector<HTMLElement>('.titleMain')
    selectedElement?.scrollTo(0,0)
  };

  const handleChnage = (e: any) => {
    let value = e.target.value;
    let data = faqData.filter((list) => list.title === value);
    setSingleFaq(data[0]);
    
  };

  return (
    <>
      <div className="faq">
        <Container>
          <div className="headerMain">
            <div className="headerfaq">
              <h4>FAQs</h4>
            </div>
            <div className="headerlink">
              <button
                type="button"
                className="headerbutton headerbutton_filter"
              >
                Filter by
              </button>
            </div>
            <div className="headerline">|</div>

            {faqData.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    selectedItem === item.title
                      ? 'headerlink clickItem'
                      : 'headerlink'
                  }
                  onClick={() => handelHeader(item)}
                >
                  <button type="button" className="headerbutton">
                    {item.title}
                  </button>
                </div>
              );
            })}
          </div>
          <Row>
            <Col lg={4}>
              <div className="sidebar">
                <div className="titleMain">
                  {singleFaq &&
                    singleFaq?.items.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedDesItem === item.title
                              ? 'titlebox selected'
                              : 'titlebox'
                          }
                          onClick={() => {
                            setSingleFaqDes(item);
                            setSelectedDesItem(item.title);
                          }}
                        >
                          <div className="titlebutton">{item?.title}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Col>

            <Col lg={8}>
              <div className="faqcontent">
                <h3 className="faq_content_title">{singleFaqDes?.title}</h3>
                <p
                  className="faq_content_description"
                  dangerouslySetInnerHTML={{
                    __html: singleFaqDes?.description,
                  }}
                ></p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* mobile view */}
      <div className="mobile_faq">
        <Container>
          <div className="headerMain">
            <div className="headerfaq">
              <h4>FAQS</h4>
            </div>
          </div>
          <div className="content">
            <div className="selection">
              <select
                className="form-select form-select-lg "
                onChange={handleChnage}
              >
                {faqData.map((item, index) => {
                  return (
                    <option key={index} value={item.title}>
                      {item.title}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="accordion">
              <Accordion defaultActiveKey="0">
                {singleFaq &&
                  singleFaq.items.map((item, index) => {
                    return (
                      <Accordion.Item
                        eventKey={item?.title}
                        key={index}
                        className="itme_accordion"
                      >
                        <Accordion.Header className="title_accordion">
                          {item?.title}
                        </Accordion.Header>
                        <Accordion.Body className="title_accordin">
                          <p
                            className="faq_content_description"
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          ></p>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </Accordion>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Faq;
