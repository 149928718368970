export const DocumentIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.5}
      height={26.5}
      viewBox="0 0 21.5 26.5"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__n{fill:none;stroke:#1a295b;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
          }
        </style>
      </defs>
      <path
        className="prefix__n"
        d="M13.25.75h-10a2.5 2.5 0 00-2.5 2.5v20a2.5 2.5 0 002.5 2.5h15a2.5 2.5 0 002.5-2.5v-15z"
      />
      <path
        className="prefix__n"
        d="M13.27.75v7.48h7.48M15.777 14.787h-10M15.777 19.801h-10M8.245 9.5H5.752"
      />
    </svg>
  );
};

export const DownloadIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14.5}
      height={14.5}
      viewBox="0 0 19.5 19.5"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__b{fill:none;stroke:#1a295b;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
          }
        </style>
      </defs>
      <path
        className="prefix__b"
        d="M18.75 12.75v4a2 2 0 01-2 2h-14a2 2 0 01-2-2v-4M4.75 7.75l5 5 5-5M9.75 12.75v-12"
      />
    </svg>
  );
};
export const EditIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={21.203}
      viewBox="0 0 21 21.203"
      {...props}
    >
      <path
        d="M15.63 1.539a2.853 2.853 0 014.035 4.035L6.048 19.19.5 20.703l1.513-5.547z"
        fill="none"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CrossIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 20}
      height={props.height || 20}
      viewBox="0 0 32 32"
      {...props}
    >
      <circle cx={16} cy={16} r={16} fill="red" />
      <path
        d="M20.97 11.279a.953.953 0 00-1.348 0l-3.5 3.5-3.5-3.5a.953.953 0 00-1.348 1.348l3.5 3.5-3.5 3.5a.953.953 0 001.348 1.348l3.5-3.5 3.5 3.5a.953.953 0 001.348-1.347l-3.5-3.5 3.5-3.5a.953.953 0 000-1.349z"
        fill="#fff"
        stroke="#fff"
      />
    </svg>
  );
};

export const SuccessGreenIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 32}
      height={props.height || 32}
      viewBox="0 0 32 32"
      {...props}
    >
      <g transform="translate(-921 -321)">
        <circle
          cx={16}
          cy={16}
          r={16}
          transform="translate(921 321)"
          fill="#6dc773"
        />
        <path
          d="M935.187 341.1a.581.581 0 01-.411-.17l-3.105-3.103a.581.581 0 01.822-.822l2.671 2.67 5.336-5.981a.581.581 0 01.867.774l-5.75 6.44a.582.582 0 01-.417.192z"
          fill="#fff"
          stroke="#fff"
        />
      </g>
    </svg>
  );
};

export const StarIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 56 56"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ffb657" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
      </defs>
      <g transform="translate(-758 -113)">
        <circle
          cx={28}
          cy={28}
          r={28}
          transform="translate(758 113)"
          fill="#1a295b"
        />
        <path
          d="M15.149 22.079l8.126 4.9-2.156-9.239 7.18-6.22-9.454-.8L15.149 2l-3.695 8.718L2 11.52l7.18 6.22-2.157 9.244z"
          transform="translate(770.851 126.008)"
          fillRule="evenodd"
          fill="url(#prefix__a)"
        />
      </g>
    </svg>
  );
};

export const MoneyIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="0 0 56 56"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__b{stroke:#1a295b;fill:none;stroke-linejoin:round;stroke-miterlimit:10}'
          }
        </style>
      </defs>
      <g stroke="#1a295b" fill="#fff">
        <circle cx={28} cy={28} r={28} stroke="none" />
        <circle cx={28} cy={28} r={27.5} fill="none" />
      </g>
      <g transform="translate(9.822 11.366)">
        <path className="prefix__b" d="M3.178 8.634h28v16h-28z" />
        <circle
          className="prefix__b"
          cx={4}
          cy={4}
          r={4}
          transform="translate(13.178 12.634)"
        />
        <path
          className="prefix__b"
          d="M7.362 9A4.375 4.375 0 013 13.362M3 19.906a4.375 4.375 0 014.362 4.362M26.993 9a4.375 4.375 0 004.362 4.362M31.355 19.906a4.375 4.375 0 00-4.362 4.362"
        />
      </g>
    </svg>
  );
};

export const MoneySelectedIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 56 56"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ffb657" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ffb657" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <style>
          {
            '.prefix__c{stroke:#1a295b;stroke-linejoin:round;stroke-miterlimit:10;fill:url(#prefix__b)}'
          }
        </style>
      </defs>
      <g stroke="#1a295b" fill="#1a295b">
        <circle cx={28} cy={28} r={28} stroke="none" />
        <circle cx={28} cy={28} r={27.5} fill="none" />
      </g>
      <g transform="translate(9.822 11.366)">
        <path
          transform="translate(3.178 8.634)"
          fill="url(#prefix__a)"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          stroke="#1a295b"
          d="M0 0h28v16H0z"
        />
        <circle
          className="prefix__c"
          cx={4}
          cy={4}
          r={4}
          transform="translate(13.178 12.634)"
        />
        <path
          className="prefix__c"
          d="M7.362 9A4.375 4.375 0 013 13.362M3 19.906a4.375 4.375 0 014.362 4.362M26.993 9a4.375 4.375 0 004.362 4.362M31.355 19.906a4.375 4.375 0 00-4.362 4.362"
        />
      </g>
    </svg>
  );
};

export const CertificateIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="0 0 56 56"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__b{stroke:#1a295b;fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}'
          }
        </style>
      </defs>
      <g stroke="#1a295b" fill="#fff">
        <circle cx={28} cy={28} r={28} stroke="none" />
        <circle cx={28} cy={28} r={27.5} fill="none" />
      </g>
      <g transform="translate(13.313 13.471)">
        <circle
          className="prefix__b"
          cx={9.746}
          cy={9.746}
          r={9.746}
          transform="translate(4.852 1)"
        />
        <circle
          className="prefix__b"
          cx={6.202}
          cy={6.202}
          r={6.202}
          transform="translate(8.396 4.544)"
        />
        <path
          className="prefix__b"
          d="M14.775 20.493l3.19 6.734 3.1-3.367 4.607-.266-3.366-6.911M6.89 16.771L3.7 23.593l4.519.266 3.1 3.367 3.19-6.734"
        />
      </g>
    </svg>
  );
};

export const CertificateSelectedIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 56 56"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ffb657" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <style>{'.prefix__a{fill:#1a295b}'}</style>
      </defs>
      <g transform="translate(-864 -112)">
        <circle
          className="prefix__a"
          cx={28}
          cy={28}
          r={28}
          transform="translate(864 112)"
        />
        <g transform="translate(877.313 125.471)">
          <path
            d="M-1597.237-105.979h-.27l-3.19 6.734-3.1-3.367-4.519-.266 3.19-6.823.076.037a9.705 9.705 0 01-2.114-6.062 9.746 9.746 0 019.746-9.746 9.746 9.746 0 019.746 9.746 9.7 9.7 0 01-2.029 5.954l3.359 6.894-4.608.266-3.1 3.367z"
            transform="translate(1612.013 126.471)"
            fill="url(#prefix__a)"
          />
          <circle
            className="prefix__a"
            cx={6.202}
            cy={6.202}
            r={6.202}
            transform="translate(8.396 4.544)"
          />
        </g>
      </g>
    </svg>
  );
};

export const SuccessIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 40}
      height={props.width || 40}
      viewBox="0 0 56 56"
      {...props}
    >
      <g fill="#fff" stroke="#1a295b">
        <circle cx={28} cy={28} r={28} stroke="none" />
        <circle cx={28} cy={28} r={27.5} fill="none" />
      </g>
      <path
        d="M25.339 35.634a1.167 1.167 0 01-.825-.342l-6.233-6.236a1.167 1.167 0 011.65-1.65l5.365 5.36 10.725-12.01a1.167 1.167 0 111.741 1.555L26.214 35.244a1.168 1.168 0 01-.838.389z"
        fill="#1a295b"
      />
    </svg>
  );
};
export const SuccessSelectedIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="0 0 56 56"
      {...props}
    >
      <g fill="#1a295b" stroke="#1a295b">
        <circle cx={28} cy={28} r={28} stroke="none" />
        <circle cx={28} cy={28} r={27.5} fill="none" />
      </g>
      <path
        d="M25.339 35.634a1.167 1.167 0 01-.825-.342l-6.233-6.236a1.167 1.167 0 011.65-1.65l5.365 5.36 10.725-12.01a1.167 1.167 0 111.741 1.555L26.214 35.244a1.168 1.168 0 01-.838.389z"
        fill="#FFB657"
      />
    </svg>
  );
};