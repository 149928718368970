import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import './../index.scss'

export const DatePickerField = ({
  name,
  value,
  onChange,
  setDate,
  setFieldTouched,
  maxDate = new Date(),
  isDisable,
  placeholder = '*DOB(DD/MM/YYYY)',
}: {
  name: any;
  value: any;
  onChange: any;
  setDate?: any;
  setFieldTouched: any;
  maxDate?: any;
  isDisable?: any;
  placeholder?: string;
}) => {
  return (
    <DatePicker
      placeholderText={placeholder}
      dateFormat="dd/MM/yyyy"
      disabled={isDisable}
      onBlur={() => setFieldTouched(`${name}`)}
      maxDate={maxDate}
      showMonthDropdown
      showYearDropdown
      yearDropdownItemNumber={115}
      scrollableYearDropdown
      closeOnScroll={true}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      selected={(value && new Date(value)) || null}
      onChange={(val) => {
        setDate(val);
        onChange(name, val);
      }}
    />
  );
};
