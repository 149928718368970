import styled from 'styled-components';
import { Colors } from '@hBits.Core';
import { Flex } from '@chakra-ui/react';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
};

export const NavbarWrapper = styled(Flex)`
  background-color: ${Colors.bgDarkBlue};
  border-radius: 0.625rem;
  flex-direction: column;
  width: 100%;
  color: ${Colors.white};
  font-size: 0.8rem;
  height: fit-content;
  padding: 1.25rem 0;
  &:hover {
    color: ${Colors.white};
  }
`;

export const NavbarItems = styled(Flex)`
  padding: 0.675rem 2rem;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  color: ${(props) => (props['isActive'] ? Colors.orangeColor : Colors.white)};
  @media (min-width: ${size.mobileS}) {
    justify-content: center;
  }

  @media (max-width: ${size.mobileM}) {
    justify-content: center;
  }
  @media (min-width: ${size.mobileL}) {
    justify-content: center;
  }
`;
