import * as React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const GoogleRecaptchaBox = ({
  setFieldValue,
  errors,
  touched,
  justify = 'center',
}: any) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  React.useEffect(() => {
    if (!executeRecaptcha) return;
    executeRecaptcha('submit').then((token) => {
      setFieldValue('token', token);
    });
  }, [executeRecaptcha, setFieldValue]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: justify,
        flexDirection: 'column',
      }}
    >
      {errors.token && touched.token && (
        <div
          data-test="div-inline-error-msg-mobileno"
          className="errorRegMessage"
        >
          {errors.token.toString()}
        </div>
      )}
    </div>
  );
};

export default GoogleRecaptchaBox;
