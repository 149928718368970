import React, { useState } from 'react';
import {} from 'apps/customer-portal-web/src/theme/colors';
import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import '../propertyInvestment.scss';
import PAS4Step1 from './PAS4Step1';
import PAS4Step2 from './PAS4Step2';
import CustomFrame from '../CustomFrame';
import {
  getPas4EoiDocumentStatus,
  getPas4EoiDocumentUrl,
} from 'apps/customer-portal-web/src/services/propertyInvestment/pas4StepServices';
import { useDispatch, useSelector } from 'react-redux';
import { getPas4Step } from '../util';
import { setInvestmentDetails } from 'apps/customer-portal-web/src/store/actions';

const Pas4Step: FC<{}> = () => {
  const [zohoModal, setZohoModal] = useState(false);
  const [zohoDocLink, setZohoDocLink] = useState('');
  const [pas4DocLoader, setPas4DocLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const investmentModal = useSelector(
    (state: any) => state.InvestmentReducer?.propertyData,
  );
  const { investmentAmountEoi, pas4 } = investmentModal;

  const { investmentId } = investmentAmountEoi;

  const openZoho = async () => {
    setPas4DocLoader(true);
    const zohoLink = await getPas4EoiDocumentUrl(investmentId);
    if (zohoLink) {
      setZohoDocLink(zohoLink);
      setZohoModal(true);
    }
    setPas4DocLoader(false);
  };

  const closeEDocumentModal = async () => {
    const signedDocumentStatus = await getPas4EoiDocumentStatus(investmentId);
    dispatch(setInvestmentDetails(signedDocumentStatus));
  };

  const renderPAS4Step = (value: number) => {
    switch (value) {
      case 0:
        return <PAS4Step1 openZoho={openZoho} pas4DocLoader={pas4DocLoader} />;
      case 1:
        return <PAS4Step2 openZoho={openZoho} pas4DocLoader={pas4DocLoader} />;
      default:
        return <></>;
    }
  };

  return (
    <Flex>
      {zohoModal && (
        <CustomFrame
          isOpen={zohoModal}
          zohoDocLink={zohoDocLink}
          onClose={() => setZohoModal(false)}
          title="Please sign the document"
          investmentId={investmentId}
          onCloseFrame={closeEDocumentModal}
        />
      )}
      {renderPAS4Step(getPas4Step(pas4))}
    </Flex>
  );
};

export default Pas4Step;
