import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import BasicDetails from '../../KycScreens/BasicDetails';
import GuardianDetails from '../../KycScreens/GuardianDetails';
import MainAddressDetails from '../../KycScreens/MainAddressDetails';
import BankDetails from '../../KycScreens/BankDetails';
import colors from 'libs/src/theme/colors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TAB_VALUE } from 'apps/customer-portal-web/src/store/constants/ReduxConstants';
import { TabNameEnum } from '@hBits.Core';
import { useSearchParams } from 'react-router-dom';

interface IndividualHolderProps {
  tabName: string[];
  holderNumber: number;
}

const IndividualHolder = (props: IndividualHolderProps) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { tabName, holderNumber } = props;
  const [currentTab, setCurrentTab] = useState<any>(tabName);
  const [isGuardian, setIsGuardian] = useState<boolean>(false);
  useEffect(() => {
    setCurrentTab(tabName);
  }, [tabName]);
  const { tabValue } = useSelector((state: any) => state.KycReducer);
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    if (searchParams.get('tab') === 'PanBank' && holderNumber === 0) {
      isGuardian ? setTabIndex(3) : setTabIndex(2);
    }
    else {
      setTabIndex(tabValue[holderNumber]);
    }
  }, [tabValue, searchParams]);
  const isGuardianDetail = (value: any) => {
    setIsGuardian(value);
    if (!value) {
      const newTab = [];
      for (let itm of currentTab) {
        if (itm === TabNameEnum.Guardian_Details && !value) {
          continue;
        }
        newTab.push(itm);
      }
      setCurrentTab(newTab);
      return;
    }
    setCurrentTab(tabName);
  };
  useEffect(() => {
    if (holderNumber !== 0) {
      const newTab = [];
      for (let itm of currentTab) {
        if (itm === TabNameEnum.Guardian_Details) {
          continue;
        }
        newTab.push(itm);
      }
      setCurrentTab(newTab);
    }
  }, []);
  const renderTabValues = (value: any) => {
    switch (value) {
      case TabNameEnum.Basic_Details:
        return (
          <BasicDetails
            isIndividual={true}
            isGuardianDetail={isGuardianDetail}
            holderNumber={holderNumber}
          />
        );
      case TabNameEnum.Guardian_Details:
        return <GuardianDetails holderNumber={holderNumber} />;
      case TabNameEnum.Investor_Address:
        return (
          <MainAddressDetails isIndividual={true} holderNumber={holderNumber} />
        );
      case TabNameEnum.PAN_Bank_Details:
        return <BankDetails isIndividual={true} holderNumber={holderNumber} />;
      default:
        return (
          <BasicDetails
            isGuardianDetail={isGuardianDetail}
            holderNumber={holderNumber}
          />
        );
    }
  };

  return (
    <Tabs
      variant="unstyled"
      flex={1}
      index={tabIndex}
      onChange={(index) => {
        setTabIndex(index);
        dispatch({
          type: TAB_VALUE,
          payload: {
            ...tabValue,
            [holderNumber]: index,
          },
        });
      }}
    >
      <TabList>
        {currentTab.map((value: any) => (
          <Tab
            fontSize={{ base: '12px', md: '20px' }}
            color={colors.greyUserNameColor}
            _selected={{ color: colors.bgDarkBlue, fontWeight: '700' }}
          >
            {value}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {currentTab.map((value: any) => (
          <TabPanel>{renderTabValues(value)}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default IndividualHolder;
