export enum PropertyAmenities {
  CarParking = 'Car Parking',
  HighSpeedElevators = 'High Speed Elevators',
  CCTVCameras = 'CCTV Cameras',
  Restaurants = 'Restaurants',
  Banking = 'Banking',
  PowerBackup = 'Power Backup',
  ClubFacilities = 'Club Facilities',
  HVACSystems = 'HVAC Systems',
  OpenCarParking = 'Open Car Parking',
  Schools = 'Schools',
  Hospital = 'Hospital',
  ShoppingMall = 'Shopping Mall',
  Gymkhana = 'Gymkhana',
  Park = 'Park',
  FoodPlazas = 'Food Plazas',
  RecreationalGround = 'Recreational Ground',
  OutdoorSportsFacilities = 'Outdoor sports facilities',
}
