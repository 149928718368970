export enum AccountTypeEnum {
  Individual = 'Individual',
  LLP = 'LLP',
  Private = 'Private',
  Joint = 'Joint',
  Partnership = 'Partnership',
  Huf = 'Huf',
  Trust = 'Trust',
}

export enum TabNameEnum {
  Basic_Details = 'Basic Details',
  Guardian_Details = 'Guardian Details',
  Investor_Address = 'Investor Address',
  PAN_Bank_Details = 'PAN & Bank Details',
  Karta_Address = 'Karta Address',
  Partner_Address = 'Partner Address',
  LLP_Address = 'LLP Address',
  Firm_Address = 'Firm Address',
  Director_Address = 'Director Address',
  Company_Address = 'Company Address',
  Trust_Address = 'Trust Address',
  Trustee_Address = 'Trustee Address',
}

export enum FileUploadType {
  Image = 'image',
  Pdf = 'pdf',
  Word = 'word',
  msWord = 'msword',
}

export enum InvestorOrganizationEnum {
  INDIAN_PVT_SECTOR = 'Indian Pvt. Sector',
  MNC = 'MNC',
  ENTREPRENEUR_SELF_EMPLOYED = 'Entrepreneur Self-Employed',
  FREELANCER = 'Freelancer',
  PSU_GOVERNMENT = 'PSU Government',
  PROFESSIONAL_DOCTOR_LAWYER_ETC = 'Professional (Doctor/Lawyer etc)',
  RETIRED = 'Retired',
  HOME_MAKER = 'Home Maker',
  OTHER = 'Other',
}

export enum ResidentialStatusEnum {
  RI = 'RI',
  NRI = 'NRI',
}

export enum RelationshipWithMinorEnum {
  FATHER = 'Father',
  MOTHER = 'Mother',
  SON = 'Son',
  DAUGHTER = 'Daughter',
  SPOUSE = 'Spouse',
  BROTHER = 'Brother',
  SISTER = 'Sister',
  GRANDFATHER = 'GrandFather',
  GRANDMOTHER = 'GrandMother',
  GRANDSON = 'GrandSon',
  GRANDDAUGHTER = 'GrandDaughter',
  AUNT = 'Aunt',
  NEPHEW = 'Nephew',
  NIECE = 'Niece',
  COUSIN = 'Cousin',
  MOTHER_IN_LAW = 'Mother In Law',
  FATHER_IN_LAW = 'Father In Law',
  OTHERS = 'Other',
}

export enum RelationshipWithHufEnum {
  KARTA = 'Karta',
  COPARCENER = 'Coparcener',
  OTHER = 'Other',
}
export enum RelationshipWithPrivateEnum {
  DIRECTOR = 'Director',
  SHAREHOLDER = 'Shareholder',
  OTHER = 'Other',
}

export enum RelationshipWithPartnershipEnum {
  PARTNER = 'Partner',
  OTHER = 'Other',
}
export enum RelationshipWithLlpEnum {
  DESIGNATED_PARTNER = 'Designated Partner',
  OTHER = 'Other',
}

export enum GenderEnum {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export enum AddressProfEnum {
  AADHAAR = 'Aadhar',
  PASSPORT = 'Passport',
  DRIVING_LICENSE = 'Driving License',
  GST = 'Gst',
  TRC = 'Trc',
}

export enum BankAccountTypeEnum {
  CURRENT = 'Current',
  SAVINGS = 'Savings',
  NRO = 'NRO',
}

export enum KycReducerEnum {
  HolderNumber = 'HolderNumber',
}

export enum KycVendors {
  Hyperverge = 'Hyperverge',
  Decentro = 'Decentro',
}
