import { fetchAsync, HTTPMethod } from '@hBits.Core';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';


export const getData = (id: any) => {
    const req = {
        method: HTTPMethod.GET,
        data: {},
        url: baseApiUrl + endpoints.channelPartners + '/' + id
    };
    return fetchAsync(req).then((response) => {
        return response.data;
    });
};

export const updateBasicDetails = async(
    id: any,
    data: any,
) => {
    const req = {
        method: HTTPMethod.PUT,
        data: data,
        url:
            baseApiUrl +
            endpoints.channelPartners +
            '/' +
            id +
            endpoints.updateBasicDetails,
    };
    return fetchAsync(req).then((response) => {
        return response;
    });
};

export const addBankDetails = async(
    accountId: any,
    data: any,
) => {
    const req = {
        method: HTTPMethod.POST,
        data: data,
        url:
            baseApiUrl +
            endpoints.channelPartners +
            '/' +
            accountId +
            endpoints.bankDetails,
    };
    return fetchAsync(req).then((response) => {
        return response;
    });
};

export const updateBankDetails = async(
    accountId: any,
    data: any,
) => {
    const req = {
        method: HTTPMethod.PUT,
        data: data,
        url:
            baseApiUrl +
            endpoints.channelPartners +
            '/' +
            accountId +
            endpoints.bankDetails,
    };
    return fetchAsync(req).then((response) => {
        return response;
    });
};

export const addGstDetails = async(
    accountId: any,
    data: any,
) => {
    const req = {
        method: HTTPMethod.POST,
        data: data,
        url:
            baseApiUrl +
            endpoints.channelPartners +
            '/' +
            accountId +
            endpoints.gstDetails,
    };
    return fetchAsync(req).then((response) => {
        return response;
    });
};

export const updateGstDetails = async(
    accountId: any,
    data: any,
) => {
    const req = {
        method: HTTPMethod.PUT,
        data: data,
        url:
            baseApiUrl +
            endpoints.channelPartners +
            '/' +
            accountId +
            endpoints.gstDetails,
    };
    return fetchAsync(req).then((response) => {
        return response;
    });
};