import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import * as ReactDOM from 'react-dom/client';
import { store, persistor } from './store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import RoutingManager from './routes';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import { breakpoints } from './constants/globalConstants';
import { Environment } from '@hBits.Core';
import { environment } from './environments/environment';

Sentry.init({
  enabled:
    environment['NEXT_PUBLIC_ENV_NAME'] == 'UAT' ||
    environment['NEXT_PUBLIC_ENV_NAME'] == 'Production',
  environment: environment['NEXT_PUBLIC_ENV_NAME'],
  dsn: 'https://dc54e9aa98a544dd94455fefcb51cf70@o219811.ingest.sentry.io/6620771',
  integrations: [new BrowserTracing()],
  release: process.env['NX_ENV_VERSION'],
  attachStacktrace: true,
  //attach stack trace

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.3,
});

const theme = extendTheme({ breakpoints });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

Environment.getInstance().setEnvironment(environment);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ChakraProvider theme={theme}>
        <RoutingManager />
      </ChakraProvider>
    </PersistGate>
  </Provider>,
);
