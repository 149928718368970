import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPMethod } from 'libs/src/enums';
import { fetchAsync } from '../../NetworkHelper';
import { authTokenStorageProvider } from 'libs/src/auth';

export const addLinkedAccounts = async (userData: object) => {
  const authTokenModel = authTokenStorageProvider.getAuthTokenModel();

  try {
    const request = {
      method: HTTPMethod.POST,
      data: userData,
      url:
        baseApiUrl +
        endpoints.accounts +
        authTokenModel?.accountId +
        endpoints.linkedAccounts,
    };

    const response = await fetchAsync(request);
    return response;
  } catch (_error) {
    return null;
  }
};
