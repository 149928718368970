import { Flex, HStack } from '@chakra-ui/react';
import { Colors } from '@hBits.Core';
import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  NavbarLinkName,
  NavbarLinkHash,
} from '../../screens/Dashboard/DashboardContents';
import { NavbarItems, NavbarWrapper } from './styles';

interface NavbarItem {
  name: NavbarLinkName;
  link: NavbarLinkHash;
  logoSrc: string;
  logoSrcHover: string;
}

interface NavigationSectionProps {
  navbarItems: NavbarItem[];
  handleTabNav: (tabNumber: NavbarLinkHash) => void;
}

const NavigationSection: React.FC<NavigationSectionProps> = ({
  navbarItems,
  handleTabNav,
}) => {
  const location = useLocation();
  const isActiveTab = React.useCallback(
    (tab: string): boolean => location.hash === tab,
    [location],
  );

  return (
    <NavbarWrapper>
      {navbarItems.map((item) => (
        <NavLink
          to={item.link}
          style={
            !isActiveTab(item.link)
              ? { color: Colors.white, textDecoration: 'none' }
              : { color: Colors.orangeColor, textDecoration: 'none' }
          }
          onClick={() => handleTabNav(item.link)}
        >
          <NavbarItems fontSize={['14px', '1.125rem']} key={item.name}>
            <HStack>
              <Flex>
                {isActiveTab(item.link) ? (
                  <img
                    width={25}
                    height={25}
                    alt="img-logo"
                    src={item.logoSrcHover}
                  />
                ) : (
                  <img
                    width={25}
                    height={25}
                    alt="img-logo"
                    src={item.logoSrc}
                  />
                )}
              </Flex>
              <Flex
                className={
                  isActiveTab(item.link) ? 'dashboard-nav-link-active' : ''
                }
                width={'100%'}
                justifyContent={['center', 'flex-start']}
              >
                <Flex minW={'70px'}>{item.name}</Flex>
              </Flex>
            </HStack>
          </NavbarItems>
        </NavLink>
      ))}
    </NavbarWrapper>
  );
};

export default NavigationSection;
