import { Flex } from '@chakra-ui/react';
import { InputTile } from './CustomInputTile';

interface CustomPanCardTileProps {
  setFieldTouched: any;
  valuePanNumber: string;
  errorPanNumber: string | undefined;
  touchedPanNumber: boolean | undefined;
  valuePanName: string;
  errorPanName: string | undefined;
  touchedPanName: boolean | undefined;
  placeholderText?: string;
  frontPlaceHolderText?: string;
  holderNumber: number;
  setFieldValue?: any;
}

const CustomPanCardTile = (props: CustomPanCardTileProps) => {
  const {
    setFieldTouched,
    valuePanNumber,
    errorPanNumber,
    touchedPanNumber,
    touchedPanName,
    errorPanName,
    valuePanName,
    placeholderText = '',
    frontPlaceHolderText = '',
    holderNumber,
    setFieldValue
  } = props;
  return (
    <Flex>
      <Flex flex={1} direction={{ base: 'column', md: 'row' }}>
        <InputTile
          testId='input-pan-card-number'
          placeholder={`*PAN Number${placeholderText}`}
          paddingRight={6}
          handleChangeName={'panNumber'}
          value={valuePanNumber}
          errors={errorPanNumber}
          setFieldTouched={setFieldTouched}
          touched={touchedPanNumber}
          isAllLettersCapital={true}
          holderNumber={holderNumber}
          setFieldValue={setFieldValue}
        />
        <InputTile
          testId='input-name-as-pan-card'
          placeholder={`*${frontPlaceHolderText}Name as per PAN Card${placeholderText}`}
          paddingRight={0}
          handleChangeName={'panHolderName'}
          value={valuePanName}
          errors={errorPanName}
          setFieldTouched={setFieldTouched}
          touched={touchedPanName}
          isPanNameText={true}
          isAllLettersCapital={true}
          holderNumber={holderNumber}
          setFieldValue={setFieldValue}
        />
      </Flex>
    </Flex>
  );
};

export default CustomPanCardTile;
