import { Environment } from '@hBits.Core';
import * as React from 'react';
import styles from './PageNotFound.module.css';

const PageNotFound = () => {
  const moveToHomePage = () => {
    const hostingRoot = Environment.getInstance().getParam(
      'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
    );
    window.location.assign(hostingRoot);
  };

  return (
    <div className={styles.main}>
      <div className={styles.content_layout}>
        <div className={styles.description}>
          <div className={styles.title}>404</div>
          <div className={styles.subtitle}>Page Not Found</div>
          <p className={styles.text}>
            Its look like you have taken a wrong turn Don't Worry....it happens
            to most of us
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button className={styles.back_btn} onClick={moveToHomePage}>
              GO BACK TO OUR HOMEPAGE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
