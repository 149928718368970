import { Divider, Flex, Text } from '@chakra-ui/react';
import style from '../styles/index.module.scss';
import CustomStepper from 'apps/customer-portal-web/src/components/CustomStepper';
import stepKeys from 'apps/customer-portal-web/src/screens/PropertyInvestment/stepperSteps';
import { Colors } from '@hBits.Core';
import { NUM_DIFFERENTIATION } from 'apps/landing-pages-web/src/components/common/utils/helper';
import { getInvestmentStep } from 'apps/customer-portal-web/src/screens/PropertyInvestment/util';
import mailIcon from '../../../../../../assets/my_team/mail-icon.svg';
import callIcon from '../../../../../../assets/my_team/phone-call.svg';

interface ClientCardProps {
  firstName: string;
  lastName: string;
  emailAddress: any;
  mobileCountryCode: any;
  mobileNumber: any;
  propertyName: string;
  totalInvestmentValue: number;
  investmentStage: string;
  associatedChannelPartnerName: string;
}

function ClientCard(props: ClientCardProps) {
  const {
    firstName,
    lastName,
    emailAddress,
    mobileCountryCode,
    mobileNumber,
    propertyName,
    totalInvestmentValue,
    associatedChannelPartnerName,
  } = props;
  let { investmentStage } = props;

  if (investmentStage === 'PrimaryEOI' || investmentStage === 'TopUpEOI') {
    investmentStage = 'EOI';
  }
  let outerWidth;

  if (window.innerWidth < 768) {
    outerWidth = ['150%', '150%', '150%', '150%'];
  } else {
    outerWidth = ['80%', '80%', '80%', '80%'];
  }

  return (
    <Flex
      direction={'column'}
      width={'100%'}
      height={'100%'}
      className={style['client_card']}
    >
      <Flex p={2} pt={0} direction={{ base: 'column', md: 'row' }}>
        <Flex flex={2} direction={'column'}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            alignItems={'flex-start'}
            justify={'space-between'}
            flex={1}
          >
            <Flex direction={'column'} w={{ base: '100%', md: '30%' }}>
              <Flex
                color={Colors.blackColor}
                fontFamily={'Roboto'}
                fontSize={'26px'}
                fontWeight={'medium'}
                p={1}
                pl={0}
                letterSpacing={0}
              >
                <p>
                  {firstName + ' '}
                  {lastName + ' '}
                </p>
              </Flex>
              <Flex
                color={Colors.textGreyColor}
                fontFamily={'Roboto'}
                fontSize={'16px'}
                fontWeight={'bold'}
                letterSpacing={0}
              >
                <p>Property: {propertyName}</p>
              </Flex>
            </Flex>
            <Flex
              justifyContent={{ base: 'flex-start', md: 'flex-end' }}
              w={{ base: '100%', md: '70%' }}
              h={'70px'}
              mt={{ base: '45px', md: '30px' }}
            >
              {investmentStage && (
                <CustomStepper
                  stepKeys={stepKeys}
                  currentStep={getInvestmentStep(investmentStage)}
                  outerWidth={outerWidth}
                />
              )}
            </Flex>
          </Flex>
          <Flex direction={'column'} p={1}>
            <Flex
              color={Colors.textGreyColor}
              fontFamily={'Roboto'}
              fontSize={'14px'}
              fontWeight={'normal'}
              letterSpacing={0}
            >
              <p>Investment Value</p>
            </Flex>
            <Flex
              color={Colors.textGreyColor}
              fontFamily={'Roboto'}
              fontSize={'26px'}
              fontWeight={'bold'}
              letterSpacing={0}
            >
              <p>₹{NUM_DIFFERENTIATION(totalInvestmentValue, false)}</p>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Divider
        borderColor={Colors.dividerLine}
        width={'95%'}
        opacity={1}
        pl={7}
      />
      <Flex p={4} direction={{ base: 'column', md: 'row' }}>
        <Flex
          direction={'column'}
          justifyContent={'center'}
          color={Colors.textGreyColor}
          fontFamily={'Roboto'}
          fontSize={'16px'}
          fontWeight={'medium'}
          letterSpacing={0}
          w={{ base: '100%', md: '42%' }}
        >
          <Flex gap={'1rem'} align={'center'} w={'100%'}>
            <img src={callIcon} />
            <Text>
              {' '}
              {mobileCountryCode}
              {' ' + mobileNumber}
            </Text>
          </Flex>
          <Flex align={'center'} pt={2} w="100%" flexWrap={'wrap'}>
            <img src={mailIcon} />
            <Text m={'5px'}>{emailAddress}</Text>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          flex={3}
          justify={'space-between'}
          color={Colors.textGreyColor}
          fontFamily={'Roboto'}
          fontSize={'14px'}
          fontWeight={'medium'}
          letterSpacing={0}
          mt={['20px', '20px', '0px', '0px']}
        >
          <Flex direction={'column'} pl={{ base: '0', md: '3' }}>
            <Flex p={'1px'} fontWeight={'normal'}>
              Team Member
            </Flex>
            <Flex
              fontSize={'16px'}
              fontWeight={'bold'}
              p={'1px'}
              pt={{ base: '0', md: '2' }}
            >
              {associatedChannelPartnerName}
            </Flex>
          </Flex>
          <Flex direction={'column'}>
            <Flex
              fontWeight={'normal'}
              justifyContent={{ base: 'flex-start', md: 'flex-end' }}
              mt={{ base: '4', md: '0' }}
            >
              Status
            </Flex>
            <Flex
              fontSize={'16px'}
              fontWeight={'bold'}
              pt={{ base: '0', md: '2' }}
              justifyContent={{ base: 'flex-start', md: 'flex-end' }}
            >
              {investmentStage === 'RegistrationComplete'
                ? 'Registration'
                : investmentStage}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ClientCard;
