import React from 'react';

const BotInput = ({ values, handleChange, setFieldTouched }: any) => {
  return (
    <div id="userEmail">
      <input
        type="text"
        data-test="user-email"
        placeholder="Email Id*"
        value={values.userEmail}
        onChange={handleChange('userEmail')}
        onBlur={() => setFieldTouched('userEmail')}
        style={{ position: 'absolute', left: '-2000px' }}
      />
    </div>
  );
};

export default BotInput;
