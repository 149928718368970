import { Checkbox, Flex, Text, useToast } from '@chakra-ui/react';
import AddressForm from '../HelperComponents/AddressForm';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import CustomPageBottom from '../HelperComponents/CustomPageBottom';
import colors from 'libs/src/theme/colors';
import AddressProofTile from '../HelperComponents/AddressProofTile';
import { useEffect, useState } from 'react';
import {
  checkMultipleDocumentsErrors,
  getHolderTypeName,
  toastMessage,
} from '../utils';
import { AddressType, HTTPStatusCode, KycStatus, Strings } from '@hBits.Core';
import { getKycData } from 'apps/customer-portal-web/src/services/Kyc/getKycData';
import {
  SetMultipleAddressProofDetails,
  setProofValues,
} from '../HelperComponents/SetMultipleAddressProofDetails';
import accountIdManager from 'libs/src/auth/account-manager';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAddressDetails,
  updateAddressDetails,
} from 'apps/customer-portal-web/src/services/Kyc/addressDetails';
import {
  ADDRESS_REPRESENTATIVE_HOLDER_NUMBER,
  ADDRESS_REPRESENTATIVE_PERMANENT_HOLDER_NUMBER,
  ADDRESS_REPRESENTATIVE_PROOF_HOLDER_NUMBER,
  IS_GET_KYC_DATA,
  IS_SUBMITTED_PAGE_VISIBLE,
} from 'apps/customer-portal-web/src/store/constants/ReduxConstants';

interface RepresentativeAddressDetailsInt {
  country: string;
  pincode: string;
  fullAddress: string;
  landmark: string;
  state: string;
  city: string;
  isPermanentSame: boolean;
  permanentCountry: string;
  permanentPincode: string;
  permanentFullAddress: string;
  permanentLandmark: string;
  permanentState: string;
  permanentCity: string;
  aadharNumber: string;
  aadharProofFrontUrl: string;
  aadharProofBackUrl: string;
  aadharProofType: string | undefined;
  gstCertificateNumber: string;
  gstCertificateProofUrl: string;
  gstCertificateProofType: string | undefined;
  passportNumber: string;
  passportProofUrl: string;
  passportProofType: string | undefined;
  passportRejectionReason: string;
  trcNumber: string;
  trcProofUrl: string;
  trcProofType: string | undefined;
  drivingLicenseNumber: string;
  drivingLicenseProofUrl: string;
  drivingLicenseProofType: string | undefined;
  otherDocumentNumber: string;
  otherDocumentProofUrl: string;
  otherDocumentProofType: string | undefined;
  addressProofTypes: any;
}

interface RepresentativeAddressDetailsProps {
  holderNumber: number;
}

let tempRepresentativeAddressHolderNumber = { 0: -2, 1: -2, 2: -2 },
  tempRepresentativeAddressProofHolderNumber = { 0: -2, 1: -2, 2: -2 },
  tempRepresentativeAddressPermanentHolderNumber = { 0: -2, 1: -2, 2: -2 };

const RepresentativeAddressDetails = (
  props: RepresentativeAddressDetailsProps,
) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const selectorState = useSelector((state: any) => state.KycReducer);
  const {
    holderId,
    kycStatusForHolder,
    addressRecordNumber,
    isNri,
    isAllFieldsDisable,
    isGetKycData,
    isSubmittedPageVisible,
    addressRepresentativeHolderNumber,
    addressRepresentativeProofHolderNumber,
    addressRepresentativePermanentHolderNumber,
  } = selectorState;
  const { holderNumber } = props;
  const [addressProofDetails, setAddressProofDetails] = useState<any>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<any>(['']);
  const [
    representativeAddressDetailsData,
    setRepresentativeAddressDetailsData,
  ] = useState<any>();
  const [saveAndContinueClicked, setSaveAndContinueClicked] =
    useState<boolean>(false);
  const [aadharProofBackUrl, setAadharProofBackUrl] = useState<string>();

  const submitBtnClicked = () => {
    setSaveAndContinueClicked(true);
  };

  const getAddressDetails = async () => {
    const response = await getKycData(accountIdManager?.getAccountId());
    if (response?.status === HTTPStatusCode.OK) {
      if (!representativeAddressDetailsData) {
        setRepresentativeAddressDetailsData(
          response?.data?.holders[holderNumber],
        );
      }
    }
  };
  useEffect(() => {
    getAddressDetails();
  }, []);

  useEffect(() => {
    setApiValues();
  }, [representativeAddressDetailsData]);
  const setApiValues = () => {
    for (
      let i = 0;
      i < representativeAddressDetailsData?.addressDetails?.length;
      i++
    ) {
      if (
        representativeAddressDetailsData?.addressDetails[i]?.addressBelongsTo ==
        Strings.representative
      ) {
        if (
          representativeAddressDetailsData?.addressDetails[i]?.addressType ==
          AddressType.Communication
        ) {
          if (tempRepresentativeAddressHolderNumber[holderNumber] != -2) {
            tempRepresentativeAddressHolderNumber[holderNumber] = i;
            tempRepresentativeAddressProofHolderNumber[holderNumber] = i - 1;
            if (tempRepresentativeAddressHolderNumber[holderNumber] != i) {
              tempRepresentativeAddressPermanentHolderNumber[holderNumber] =
                i - 1;
            }
          }
          if (tempRepresentativeAddressHolderNumber[holderNumber] == -2) {
            tempRepresentativeAddressHolderNumber[holderNumber] = i;
          }
        }
        if (
          representativeAddressDetailsData?.addressDetails[i]?.addressType ==
          AddressType.Permanent
        ) {
          if (tempRepresentativeAddressHolderNumber[holderNumber] == -2) {
            tempRepresentativeAddressHolderNumber[holderNumber] = i;
          }
          tempRepresentativeAddressProofHolderNumber[holderNumber] = i;
          if (tempRepresentativeAddressHolderNumber[holderNumber] != i) {
            tempRepresentativeAddressPermanentHolderNumber[holderNumber] = i;
          }
        }
      }
    }
    dispatch({
      type: ADDRESS_REPRESENTATIVE_HOLDER_NUMBER,
      payload: tempRepresentativeAddressHolderNumber,
    });
    dispatch({
      type: ADDRESS_REPRESENTATIVE_PROOF_HOLDER_NUMBER,
      payload: tempRepresentativeAddressProofHolderNumber,
    });
    dispatch({
      type: ADDRESS_REPRESENTATIVE_PERMANENT_HOLDER_NUMBER,
      payload: tempRepresentativeAddressPermanentHolderNumber,
    });
  };
  useEffect(() => {
    if (addressRepresentativeProofHolderNumber[holderNumber] != -2) {
      setAadharProofBackUrl(
        representativeAddressDetailsData?.addressDetails[
          addressRepresentativeProofHolderNumber[holderNumber]
        ]?.aadharProofBackUrl,
      );
      const addressProofTypes =
        representativeAddressDetailsData?.addressDetails[
          addressRepresentativeProofHolderNumber[holderNumber]
        ]?.addressProofTypes;
      const response =
        representativeAddressDetailsData?.addressDetails[
        addressRepresentativeProofHolderNumber[holderNumber]
        ];
      setAddressProofDetails(
        SetMultipleAddressProofDetails(
          addressProofTypes,
          addressProofDetails,
          response,
          isNri[holderNumber],
          true
        )?.[0],
      );
      setSelectedDocuments(
        SetMultipleAddressProofDetails(
          addressProofTypes,
          addressProofDetails,
          response,
          isNri[holderNumber],
          true
        )?.[1],
      );
    }
  }, [
    addressRepresentativeProofHolderNumber[holderNumber],
    representativeAddressDetailsData,
  ]);
  const getInitialValues: () => RepresentativeAddressDetailsInt = () => {
    const addressDetails =
      representativeAddressDetailsData?.addressDetails[
      addressRepresentativeHolderNumber[holderNumber]
      ];
    const permanentAddressDetails =
      representativeAddressDetailsData?.addressDetails[
      addressRepresentativePermanentHolderNumber[holderNumber]
      ];
    let initialValues = {
      country: addressDetails?.country,
      pincode: addressDetails?.pincode,
      fullAddress: addressDetails?.fullAddress,
      landmark: addressDetails?.landmark,
      state: addressDetails?.state,
      city: addressDetails?.city,
      isPermanentSame:
        addressRepresentativePermanentHolderNumber[holderNumber] === -2
          ? true
          : false,
      permanentCountry: permanentAddressDetails?.country,
      permanentPincode: permanentAddressDetails?.pincode,
      permanentFullAddress: permanentAddressDetails?.fullAddress,
      permanentLandmark: permanentAddressDetails?.landmark,
      permanentState: permanentAddressDetails?.state,
      permanentCity: permanentAddressDetails?.city,
      aadharNumber: '',
      aadharProofFrontUrl: '',
      aadharProofBackUrl: '',
      aadharProofType: undefined,
      gstCertificateNumber: '',
      gstCertificateProofUrl: '',
      gstCertificateProofType: undefined,
      passportNumber: '',
      passportProofUrl: '',
      passportProofType: undefined,
      passportRejectionReason: '',
      trcNumber: '',
      trcProofUrl: '',
      trcProofType: undefined,
      drivingLicenseNumber: '',
      drivingLicenseProofUrl: '',
      drivingLicenseProofType: undefined,
      otherDocumentNumber: '',
      otherDocumentProofUrl: '',
      otherDocumentProofType: undefined,
      addressProofTypes: [],
      addressBelongsTo: Strings.representative,
      holderType: getHolderTypeName(holderNumber),
    };
    return initialValues;
  };
  const AddBasicDetailsSchema = Yup.object().shape({
    country: Yup.string().required('This is required.'),
    pincode: Yup.string()
      .trim()
      .max(20, 'Length should not exceed 20')
      .matches(
        /^[a-zA-Z0-9 ]+$/,
        'Letters(A-Za-z),Digits(0-9) are only allowed',
      )
      .required('This is required.'),
    fullAddress: Yup.string()
      .trim()
      .matches(
        /^([A-Za-z0-9#,/.-]+ )+[A-Za-z0-9#,/.-]+$|^[A-Za-z0-9#,/.-]+$/,
        'Letters(A-Z, a-z),Numbers(0-9),Symbols(#,/-.) are allowed',
      )
      .required('This is required.'),
    landmark: Yup.string()
      .trim()
      .matches(
        /^([A-Za-z0-9#,/.-]+ )+[A-Za-z0-9#,/.-]+$|^[A-Za-z0-9#,/.-]+$/,
        'Letters(A-Z, a-z),Numbers(0-9),Symbols(#,/-.) are allowed',
      )
      .required('This is required.'),
    state: Yup.string().trim().required('This is required.'),
    city: Yup.string()
      .trim()
      .required('This is required.')
      .max(30, 'Length should not exceed 30')
      .matches(
        /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
        'Letters(A-Z, a-z) are only required',
      ),
    isPermanentSame: Yup.boolean(),
    permanentCountry: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string().required('This is required.'),
    }),
    permanentPincode: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string()
        .trim()
        .max(20, 'Length should not exceed 20')
        .matches(
          /^[a-zA-Z0-9 ]+$/,
          'Letters(A-Za-z),Digits(0-9) are only allowed',
        )
        .required('This is required.'),
    }),
    permanentFullAddress: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string()
        .trim()
        .matches(
          /^([A-Za-z0-9#,/.-]+ )+[A-Za-z0-9#,/.-]+$|^[A-Za-z0-9#,/.-]+$/,
          'Letters(A-Z, a-z),Numbers(0-9),Symbols(#,/-.) are allowed',
        )
        .required('This is required.'),
    }),
    permanentLandmark: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string()
        .trim()
        .matches(
          /^([A-Za-z0-9#,/.-]+ )+[A-Za-z0-9#,/.-]+$|^[A-Za-z0-9#,/.-]+$/,
          'Letters(A-Z, a-z),Numbers(0-9),Symbols(#,/-.) are allowed',
        )
        .required('This is required.'),
    }),
    permanentState: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string().trim().required('This is required.'),
    }),
    permanentCity: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string()
        .trim()
        .required('This is required.')
        .max(30, 'Length should not exceed 30')
        .matches(
          /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
          'Letters(A-Z, a-z) are only required',
        ),
    }),
  });

  const addressBtnAction = (response: any) => {
    dispatch({
      type: IS_GET_KYC_DATA,
      payload: !isGetKycData,
    });
    if (
      kycStatusForHolder[holderNumber] === KycStatus.KycInProgress ||
      kycStatusForHolder[holderNumber] === KycStatus.KycNotStarted
    ) {
      toastMessage(
        response,
        toast,
        0,
        holderNumber,
        dispatch,
        selectorState,
        false,
        false,
      );
    } else {
      if (!response?.additionalDetails) {
        toast({
          title: Strings.success,
          description: Strings.success,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        dispatch({
          type: IS_SUBMITTED_PAGE_VISIBLE,
          payload: {
            ...isSubmittedPageVisible,
            [holderNumber]: true,
          },
        });
        return;
      }
      toast({
        title: Strings.failed,
        description: response.additionalDetails,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onSubmit = async (data: any) => {
    setProofValues(addressProofDetails, data);
    data.aadharProofBackUrl = aadharProofBackUrl;
    const accountId = accountIdManager?.getAccountId();
    if (checkMultipleDocumentsErrors(addressProofDetails, aadharProofBackUrl)) {
      if (
        !representativeAddressDetailsData?.addressDetails[
        addressRecordNumber[holderNumber]
        ]
      ) {
        const addedRepresentativeAddressDetailsResponse =
          await addAddressDetails(accountId, holderId[holderNumber], data);
        if (addedRepresentativeAddressDetailsResponse?.additionalDetails) {
          toast({
            title: Strings.failed,
            description:
              addedRepresentativeAddressDetailsResponse.additionalDetails,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        addressBtnAction(addedRepresentativeAddressDetailsResponse);
      }
      if (
        representativeAddressDetailsData?.addressDetails[
        addressRecordNumber[holderNumber]
        ]
      ) {
        const updatedRepresentativeAddressDetailsResponse =
          await updateAddressDetails(accountId, holderId[holderNumber], data);
        if (updatedRepresentativeAddressDetailsResponse?.additionalDetails) {
          toast({
            title: Strings.failed,
            description:
              updatedRepresentativeAddressDetailsResponse.additionalDetails,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        addressBtnAction(updatedRepresentativeAddressDetailsResponse);
      }
    }
  };
  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      validationSchema={AddBasicDetailsSchema}
      enableReinitialize
    >
      {({
        handleChange,
        values,
        errors,
        setFieldTouched,
        touched,
        setFieldValue,
      }) => (
        <Form>
          <Flex mt={2} width={'100%'} flex={1} direction={'column'}>
            <Flex direction={'column'} flex={1}>
              <Text fontSize={'16px'}>Communication Address</Text>
              <AddressForm
                errors={errors}
                handleChange={handleChange}
                values={values}
                setFieldTouched={setFieldTouched}
                touched={touched}
                isCommunicationAddress={true}
                holderNumber={holderNumber}
                setFieldValue={setFieldValue}
                saveAndContinueClicked={saveAndContinueClicked}
                isAllFieldsDisable={isAllFieldsDisable[holderNumber]}
              />
              <Text fontSize={'16px'} mt={4}>
                Permanent Address
              </Text>
              <Checkbox
                mt={6}
                onChange={(e) =>
                  setFieldValue('isPermanentSame', e.target.checked)
                }
                defaultChecked={false}
                disabled={isAllFieldsDisable[holderNumber]}
                _disabled={{ color: 'black' }}
                isChecked={values.isPermanentSame}
              >
                <Text fontSize={'12px'} color={colors.greyUserNameColor}>
                  Permanent address same as communication address{' '}
                </Text>
              </Checkbox>
              {!values.isPermanentSame && (
                <AddressForm
                  marginTop={6}
                  errors={errors}
                  handleChange={handleChange}
                  values={values}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                  isCommunicationAddress={false}
                  holderNumber={holderNumber}
                  setFieldValue={setFieldValue}
                  saveAndContinueClicked={saveAndContinueClicked}
                  isAllFieldsDisable={isAllFieldsDisable[holderNumber]}
                />
              )}
              <AddressProofTile
                addressProofDetails={addressProofDetails}
                setAddressProofDetails={setAddressProofDetails}
                selectedDocuments={selectedDocuments}
                setSelectedDocuments={setSelectedDocuments}
                holderNumber={holderNumber}
                saveAndContinueClicked={saveAndContinueClicked}
                setAadharProofBackUrl={setAadharProofBackUrl}
                aadharProofBackUrl={aadharProofBackUrl}
                dataTestid='representative-address'
                testId='for-representative-address'
              />
            </Flex>
            <CustomPageBottom
              holderNumber={holderNumber}
              btnClicked={submitBtnClicked}
            />
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default RepresentativeAddressDetails;
