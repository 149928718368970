import {
  PROPERTY_INVESTMENT_MODAL,
  SET_LOADER,
  SET_INVESTMENT_PROPERTY_DETAILS,
  DOWNLOAD_FILE_URL,
  FIRST_DASHBOARD,
} from '../../constants/ReduxConstants';

export const setInvestmentDetails = (payload: any) => ({
  type: PROPERTY_INVESTMENT_MODAL,
  payload,
});

export const setLoader = (payload: any) => ({
  type: SET_LOADER,
  payload,
});

export const setInvestmentPropertyDetails = (payload: any) => ({
  type: SET_INVESTMENT_PROPERTY_DETAILS,
  payload,
});

export const setDownloadFileUrl = (payload: any) => ({
  type: DOWNLOAD_FILE_URL,
  payload,
});

export const setFirstDashboard = (payload: any) => ({
  type: FIRST_DASHBOARD,
  payload,
});
