enum OutBoundActivityFields {
  Sid = 'sid',
  StartTime = 'start-time',
  EndTime = 'end-time',
  TotalDuration = 'total-duration',
  TotalTalkTime = 'total-talk-time',
  Status = 'status',
  OwnerId = 'owner-id',
  OwnerName = 'owner-name',
  RecordingUrl = 'recording-url',
  FromLegOutcome = 'from-leg-outcome',
  ToLegOutcome = 'to-leg-outcome',
}

enum InBoundActivityFields {
  Sid = 'sid',
  StartTime = 'start-time',
  EndTime = 'end-time',
  TotalDuration = 'total-duration',
  totalTalkTime = 'total-talk-time',
  Status = 'status',
  OwnerId = 'owner-id',
  OwnerName = 'owner-name',
  RecordingUrl = 'recording-url',
  FromLegOutcome = 'from-leg-outcome',
  ToLegOutcome = 'to-leg-outcome',
}

enum EngagementPhoneCallActivityFields {
  EngagementConcluded = 'engagement-concluded',
  EngagementOutcome = 'engagement-outcome',
  CallNotConnectedOutcome = 'call-not-connected-outcome',
  BudgetRange = 'budget-range',
  ClosureDate = 'closure-date',
  FollowUpDateTime = 'follow-up-datetime',
  FollowUpMode = 'follow-up-mode',
  Reason = 'reason',
  PreferredLanguage = 'preferred-language',
  MinutesOfMeeting = 'minutes-of-meeting',
  ActionItems = 'action-items',
  InternalStakeholders = 'internal-stake-holders',
  ExternalStakeholders = 'external-stake-holders',
  CallConnected = 'call-connected',
  PropertyName = 'property-name',
  PropertyId = 'property-id',
  Product = 'product',
  UpdatedFields = 'updated-fields',
  ActionOnOpportunity = 'action-on-opportunity',
  OpportunityId = 'opportunity-id',
}

enum EngagementMeetingActivityFields {
  MeetingScheduleType = 'meeting-schedule-type',
  MeetingDateTime = 'meeting-datetime',
  EngagementConcluded = 'engagement-concluded',
  EngagementOutcome = 'engagement-outcome',
  BudgetRange = 'budget-range',
  ClosureDate = 'closure-date',
  FollowUpDateTime = 'follow-up-datetime',
  FollowUpMode = 'follow-up-mode',
  Reason = 'reason',
  PreferredLanguage = 'preferred-language',
  MinutesOfMeeting = 'minutes-of-meeting',
  ActionItems = 'action-items',
  InternalStakeholders = 'internal-stake-holders',
  ExternalStakeholders = 'external-stake-holders',
  CallConnected = 'call-connected',
  PropertyName = 'property-name',
  PropertyId = 'property-id',
  Product = 'product',
  UpdatedFields = 'updated-fields',
  ActionOnOpportunity = 'action-on-opportunity',
  OpportunityId = 'opportunity-id',
  MeetingStartTime = 'meeting-start-time',
  MeetingEndTime = 'meeting-end-time',
  MeetingDuration = 'meeting-duration',
  MeetingLocation = 'meeting-location',
}

enum LeadUpdatedActivityFields {
  updatedData = 'updated-data',
}

enum LeadTransferredActivityFields {
  tranferredLead = 'transferred-lead',
}

enum LeadFollowUpActivityFields {
  followUpDate = 'follow-up-date',
  followUpMode = 'follow-up-mode',
}

enum LeadLoginActivityFields {
  loginTime = 'login-time',
}

enum LeadLogoutActivityFields {
  logoutTime = 'logout-time',
}

enum LeadSignupActivityFields {
  FirstName = 'first-name',
  LastName = 'last-name',
  MobileCountryCode = 'mobile-country-code',
  MobileNumber = 'mobile-number',
  EmailAddress = 'email-address',
  Source = 'source',
  Subsource = 'subsource',
  AdditionalDetails = 'additional-details',
  Country = 'country',
  State = 'state',
  City = 'city',
  OwnerId = 'owner-id',
  OwnerName = 'owner-name',
}

enum LeadEnquiryActivityFields {
  FirstName = 'first-name',
  LastName = 'last-name',
  MobileCountryCode = 'mobile-country-code',
  MobileNumber = 'mobile-number',
  EmailAddress = 'email-address',
  Source = 'source',
  Subsource = 'subsource',
  AdditionalDetails = 'additional-details',
  Country = 'country',
  State = 'state',
  City = 'city',
  OwnerId = 'owner-id',
  OwnerName = 'owner-name',
  ReInquiry = 're-inquiry',
}
enum UserRegistrationActivityFields {
  RegistrationDate = 'registration-date',
}

enum UserEnrolledActivityFields {
  EnrolledDate = 'enrolled-date',
}

enum LeadOwnerChangeFields {
  OldOwnerId = 'old-owner-id',
  OldOwnerName = 'old-owner-name',
  NewOwnerId = 'new-owner-id',
  NewOwnerName = 'new-owner-name',
}

enum ActivityCode {
  OutBoundCall = 'outbound-call',
  InBoundCall = 'inbound-call',
  EngagementPhoneCall = 'engagement-phone-call',
  EngagementMeeting = 'engagement-meeting',
  LeadUpdated = 'lead-updated',
  LeadTransferred = 'lead-transferred',
  LeadFollowUp = 'lead-follow-up',
  LeadLogin = 'lead-login',
  LeadLogout = 'lead-logout',
  LeadSignup = 'lead-signup',
  LeadEnquiry = 'lead-enquiry',
  OutboundPhoneCallActivity = 'outbound-phone-call-activity',
  PhoneCall = 'phone-call',
  UserRegistration = 'user-registration',
  UserEnrolled = 'user-enrolled',
  // LeadOwnerChange = 'lead-owner-change',
}

enum OpportunityStatus {
  Won = 'Won',
  Open = 'Open',
  Lost = 'Lost',
}

enum OpportunityStage {
  Hot = 'Hot',
  Warm = 'Warm',
  Cold = 'Cold',
  Converted = 'Converted',
  Cancelled = 'Cancelled',
}

enum LeadTypes {
  Lead = 'Lead',
  CPLead = 'CP Lead',
  ChannelPartner = 'Channel Partner',
}

enum LeadStageCodeName {
  Assigned = 'assigned',
  Interested = 'interested',
  FollowUp = 'followUp',
  NotInterested = 'not-interested',
  Junk = 'junk',
  FutureProspect = 'future-prospect',
}

enum LeadSource {
  DirectTraffic = 'Direct Traffic',
  OrganicSearch = 'Organic Search',
  CPPortal = 'CP_Portal',
}

enum LeadSubSource {
  Website = 'Website',
  InboundCall = 'Inbound Call',
}

export {
  OutBoundActivityFields,
  InBoundActivityFields,
  ActivityCode,
  OpportunityStatus,
  LeadUpdatedActivityFields,
  LeadTransferredActivityFields,
  LeadFollowUpActivityFields,
  EngagementPhoneCallActivityFields,
  EngagementMeetingActivityFields,
  LeadStageCodeName,
  OpportunityStage,
  LeadLoginActivityFields,
  LeadLogoutActivityFields,
  LeadSignupActivityFields,
  LeadEnquiryActivityFields,
  UserRegistrationActivityFields,
  UserEnrolledActivityFields,
  LeadTypes,
  LeadSource,
  LeadSubSource,
  LeadOwnerChangeFields,
};
