import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPStatusCode } from 'libs/src/enums';
import { fetchAsync } from 'libs/src/services/NetworkHelper';

export const getInvestmentDocument = async (
  accountId: any,
  propertyId: string,
): Promise<any> => {
  try {
    const request = {
      url:
        baseApiUrl +
        endpoints.investmentDocuments
          .replace('#{accountId}', accountId)
          .replace('#{propertyId}', propertyId),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};
