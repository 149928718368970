import React, { useState } from 'react';
import { FC } from 'react';
import { Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import colors from 'apps/customer-portal-web/src/theme/colors';
import { CrossIcon } from 'apps/customer-portal-web/src/assets/customIcons';
import { Colors, Strings } from '@hBits.Core';
import {
  getPaymentUrl,
  getTopUpPaymentUrl,
} from 'apps/customer-portal-web/src/services/propertyInvestment/eoiStepServices';
import CustomButton from 'apps/customer-portal-web/src/components/CustomButton';

interface EOIStep4Props {
  setRetryPayment: (value: boolean) => void;
}

const EOIStep4: FC<EOIStep4Props> = (props) => {
  const { setRetryPayment } = props;

  const getPaymentLink = async () => {
    setRetryPayment(true);
  };

  return (
    <>
      <Flex
        width={'100%'}
        flexDirection={'column'}
        pl={[0, 0, 3, 3]}
        mt={[3, 3, 0, 0]}
      >
        <Flex
          width={'70%'}
          borderBottomWidth=".5px"
          borderBottomColor={colors.blackColor}
          pb={3}
          alignItems="center"
        >
          <CrossIcon />
          <Text as="b" fontSize="14px" color={colors.grey200} ml="10px">
            Payment Failed !
          </Text>
        </Flex>
        <Flex mt={'20px'} flexDirection="column">
          <Text fontSize="13px" color={colors.grey} as="b">
            Initial payment of 5% will be required to start the process.
          </Text>
          <Text mt={'10px'} fontSize="13px" color={colors.grey} as="b">
            {` Please press PROCEED to continue payment !`}
          </Text>
        </Flex>

        <Flex mt={12} flexDirection="column">
          <Flex>
            <CustomButton
              btnText={'PROCEED'}
              btnPressed={() => getPaymentLink()}
              size={'lg'}
            />
          </Flex>
        </Flex>
        <Flex mt={12} flexDirection="column">
          <Flex></Flex>
          <Text fontSize="14px" as="b" color={colors.grey} mt={3}>
            {Strings.forQueries}
          </Text>
          <Text fontSize="13px" as="b" color={Colors.headerBlue} mt={1}>
            {Strings.contactusregestration}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default EOIStep4;
