import endpoints, { baseApiUrl } from '@hBits.Core/endpoints';
import { HTTPMethod } from 'libs/src/enums';
import { fetchAsync } from './NetworkHelper';

const getKycData = async (accountId: any) => {
  try {
    const req = {
      method: HTTPMethod.GET,
      url: baseApiUrl + endpoints.customers + accountId,
    };
    const response = await fetchAsync(req);
    return response;
  } catch (_error) {
    return null;
  }
};

export default getKycData;
