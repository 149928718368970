import { Text } from '@chakra-ui/react';
import { Colors, Environment, Strings } from '@hBits.Core';
import accountIdManager from 'libs/src/auth/account-manager';
import PrivacyModal from 'libs/src/components/PrivacyModal';
import TncModal from 'libs/src/components/TncModal';
import { useEffect, useState } from 'react';
import { leadOwner } from '../../../../../../components/Kyc/ApiData/ApiData';
import { CustomLink } from '../../../../../ChannelPartner/styled-componets';
interface CpKycContainerBottomProps {
  kycVerified: boolean;
  handleSubmitButton?: () => void;
}

function CpKycContainerBottomTile(props: CpKycContainerBottomProps) {
  const { kycVerified, handleSubmitButton } = props;
  const [leadOwnerName, setLeadOwnerName] = useState<any>();
  const [leadOwnerEmail, setleadOwnerEmail] = useState<any>();
  const [leadOwnerMobileNumber, setLeadOwnerMobileNumber] = useState<any>();
  const [isOpenTncModal, setIsOpenTncModal] = useState<boolean>(false);
  const [isOpenPrivacyModal, setIsOpenPrivacyModal] = useState<boolean>(false);

  const newCustomerPortalHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_NEW_CUSTOMER_PORTAL_HOSTING_ROOT',
  );

  useEffect(() => {
    leadOwner(accountIdManager?.getAccountId()).then((res: any) => {
      setLeadOwnerName(res?.leadOwner?.fullName);
      setleadOwnerEmail(res?.leadOwner?.emailAddress);
      setLeadOwnerMobileNumber(res?.leadOwner?.mobileNumber);
    });
  }, []);

  const skipClicked = () => {
    window.location.assign(newCustomerPortalHostingRoot + '/dashboard');
  };

  const toggleTncModal = () => {
    setIsOpenTncModal(!isOpenTncModal);
  };

  const togglePrivacyModal = () => {
    setIsOpenPrivacyModal(!isOpenPrivacyModal);
  };

  return (
    <>
      {kycVerified ? (
        <div
          style={{
            marginTop: '3rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button type="submit" className="cpkyc_btn" onClick={skipClicked}>
            Go To Dashboard
          </button>
        </div>
      ) : (
        <div style={{ marginTop: '3rem' }}>
          <div className="cpkyc_bottomcontainer">
            <button
              type="button"
              className="cpkyc_btn cpkyc_btn_skip"
              onClick={skipClicked}
            >
              SKIP FOR NOW
            </button>
            &nbsp;&nbsp;
            <button
              type="submit"
              className="cpkyc_btn"
              disabled={kycVerified}
              onClick={handleSubmitButton}
            >
              SAVE & CONTINUE
            </button>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <p className="cpkyc_p-tag-for-queries">
              For queries, connect with your RM{' '}
              <span className="cpkyc_">{leadOwnerName}</span> on
              <br />
              <span className="cpkyc_">
                {leadOwnerMobileNumber
                  ? leadOwnerMobileNumber
                  : Strings.contactusregestration}
                {leadOwnerEmail && <span>/{leadOwnerEmail}</span>}
              </span>
            </p>
          </div>
        </div>
      )}
      <div style={{ marginTop: '2rem' }}>
        <Text
          textAlign={'left'}
          color={Colors.textGreyColor}
          fontWeight={'normal'}
          fontSize={'16px'}
        >
          By Clicking 'Save & Continue' I agree to abide by the
          <CustomLink onClick={toggleTncModal}>{Strings.tnc}</CustomLink>
          {Strings.and}
          <CustomLink onClick={togglePrivacyModal}>{Strings.pp}</CustomLink>
        </Text>
      </div>
      {isOpenTncModal && <TncModal onModalClose={toggleTncModal} />}
      {isOpenPrivacyModal && <PrivacyModal onModalClose={togglePrivacyModal} />}
    </>
  );
}
export default CpKycContainerBottomTile;
