import { Flex, Text, useToast, Image } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import colors from 'libs/src/theme/colors';
import PhoneInput from 'react-phone-input-2';
import { InputTile } from '../HelperComponents/CustomInputTile';
import * as Yup from 'yup';
import styles from '../index.module.scss';
import CustomSelectDropdown from '../HelperComponents/CustomSelectDropdown';
import {
  InvestorOrganizationEnum,
  ResidentialStatusEnum,
  RelationshipWithMinorEnum,
  GenderEnum,
  KycStatus,
  HTTPStatusCode,
} from '@hBits.Core';
import { useEffect, useState } from 'react';
import SelectCountryDropdown from '../../ChannelPartner/helper-components/CountriesDropDown';
import { isAgeValid } from '../utils';
import CustomPageBottom from '../HelperComponents/CustomPageBottom';
import CustomButton from 'apps/customer-portal-web/src/components/CustomButton';
import { DatePickerField } from '../HelperComponents/DatePickerField';
import { getHolderTypeName } from '../utils';
import {
  addBasicDetails,
  updateBasicDetails,
} from '../../../services/Kyc/basicDetails';
import { toastMessage } from '../utils';
import accountIdManager from 'libs/src/auth/account-manager';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  HOLDER_ID,
  IS_NRI,
  IS_USER_TYPE_API,
} from 'apps/customer-portal-web/src/store/constants/ReduxConstants';
import { getKycData } from 'apps/customer-portal-web/src/services/Kyc/getKycData';
import verifiedImage from '../../../assets/kyc/greentick.png';

interface BasicDetailsProps {
  isIndividual?: boolean;
  isHUF?: boolean;
  isJoint?: boolean;
  isGuardianDetail?: any;
  holderNumber: number;
}

interface BasicDetailsInt {
  firstName: string;
  lastName: string;
  fatherName: string;
  emailAddress: string;
  mobileNumber: string;
  investorProfession: string;
  residentialStatus: string;
  gender: string;
  investorOrganisation: string;
  kartaOrganisation: string;
  guardianRelation: string;
  investorOrganisationOther: string;
  kartaOrganisationOther: string;
  kartaProfession: string;
  country: string;
  guardianName: string;
  guardianAddress: string;
  dateOfBirth: any;
}

const BasicDetails = (props: BasicDetailsProps) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const selectorState = useSelector((state: any) => state.KycReducer);
  const {
    isIndividual = false,
    isHUF = false,
    isGuardianDetail,
    isJoint = false,
    holderNumber,
  } = props;
  const [showGuardianDetails, setShowGuardianDetails] = useState<boolean>();
  const [date, setDate] = useState<any>();
  const [countryCode, setCountryCode] = useState(91);
  const [minMobileNumberLength, setMinMobileNumberLength] = useState(22);
  const [basicDetailsData, setBasicDetailsData] = useState<any>();
  const [schemaState, setSchemaState] = useState<boolean>(
    isIndividual || isJoint || isHUF,
  );
  const [emailStatus, setEmailStatus] = useState<boolean>(false);
  const [saveAndContinueClicked, setSaveAndContinueClicked] =
    useState<boolean>(false);
  const [isGuardianErrors, setIsGuardianErrors] = useState<boolean>(false);
  const submitBtnClicked = () => {
    setSaveAndContinueClicked(true);
  };
  useEffect(() => {
    if (isIndividual || isJoint) {
      isGuardianDetail(!isAgeValid(date));
      setShowGuardianDetails(!isAgeValid(date));
    }
  }, [date]);

  useEffect(() => {
    if (isIndividual || isJoint) {
      setIsGuardianErrors(true);
    }
  }, [holderNumber]);

  const {
    isAllFieldsDisable,
    holderId,
    isNri,
    tabValue,
    kycStatusForAccount,
    kycStatusForHolder,
    isUserTypeApi,
  } = selectorState;

  const getBasicDetails = async () => {
    if (basicDetailsData === undefined) {
      const response = await getKycData(accountIdManager?.getAccountId());
      if (response?.status === HTTPStatusCode.OK) {
        setEmailStatus(response?.data?.isEmailVerified);
        setBasicDetailsData(response?.data?.holders[holderNumber]);
      }
    }
  };

  useEffect(() => {
    getBasicDetails();
  }, []);

  const setSomeFields = () => {
    if (basicDetailsData?.dateOfBirth) {
      setDate(basicDetailsData?.dateOfBirth);
    }
    setCountryCode(basicDetailsData?.mobileCountryCode);
  };
  useEffect(() => {
    setSomeFields();
  }, [basicDetailsData]);

  const getInitialValues: () => BasicDetailsInt = () => {
    const mobileNo = `${basicDetailsData?.mobileCountryCode} ${basicDetailsData?.mobileNumber}`;
    let initialValues = {
      firstName: basicDetailsData?.firstName,
      lastName: basicDetailsData?.lastName,
      fatherName: basicDetailsData?.fatherName,
      emailAddress: basicDetailsData?.emailAddress,
      mobileNumber: mobileNo,
      guardianRelation: basicDetailsData?.guardianRelation,
      residentialStatus: basicDetailsData?.residentialStatus,
      gender: basicDetailsData?.gender,
      country: basicDetailsData?.country,
      guardianName: basicDetailsData?.guardianName,
      guardianAddress: basicDetailsData?.guardianAddress,
      dateOfBirth: basicDetailsData?.dateOfBirth,
      isIndividual: isIndividual,
      isHUF: isHUF,
      isJoint: isJoint,
      holderType: getHolderTypeName(holderNumber),
      investorProfession: basicDetailsData?.investorProfession,
      investorOrganisation: basicDetailsData?.investorOrganisation,
      investorOrganisationOther: basicDetailsData?.investorOrganisationOther,
      kartaOrganisation: basicDetailsData?.kartaOrganisation,
      kartaOrganisationOther: basicDetailsData?.kartaOrganisationOther,
      kartaProfession: basicDetailsData?.kartaProfession,
      schemaState: schemaState,
    };
    return initialValues;
  };
  useEffect(() => {
    if (countryCode == 91) setMinMobileNumberLength(12);
    else setMinMobileNumberLength(10);
  }, [countryCode]);

  const AddBasicDetailsSchema = Yup.object().shape({
    isJoint: Yup.boolean(),
    isIndividual: Yup.boolean(),
    schemaState: Yup.boolean(),
    mobileNumber: Yup.string()
      .min(minMobileNumberLength, 'Invalid Mobile Number!')
      .max(22, 'Invalid Mobile Number!')
      .required('This is required.'),
    firstName: Yup.string()
      .min(3, 'First Name entered is too Short!')
      .matches(
        /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
        '(A-Z a-z)letters only allowed',
      )
      .required('This is required.'),
    lastName: Yup.string()
      .min(3, 'Last Name entered is too Short!')
      .matches(
        /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
        '(A-Z a-z)letters only allowed',
      )
      .required('This is required.'),
    fatherName: Yup.string().when('schemaState', {
      is: (schemaState: boolean) => schemaState,
      then: Yup.string()
        .required('This is required')
        .min(3, 'Father Name entered is too Short!')
        .matches(
          /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
          '(A-Z a-z)letters only allowed',
        ),
    }),
    emailAddress: Yup.string()
      .min(8, 'Invalid Email')
      .max(40, 'Invalid Email')
      .matches(
        /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        'Invalid Email',
      )
      .required('This is required.'),
    residentialStatus: Yup.string().required('This is required.'),
    country: Yup.string().when('residentialStatus', {
      is: (residentialStatus: any) => residentialStatus === 'NRI',
      then: Yup.string().required('This is required'),
    }),
    dateOfBirth: Yup.date().required('This is required.'),
    gender: Yup.string().required('This is required.'),
    ...(isGuardianErrors && {
      guardianName: Yup.string().when('dateOfBirth', {
        is: (dateOfBirth: any) => !isAgeValid(dateOfBirth),
        then: Yup.string()
          .min(3, 'Guardian Name entered is too Short!')
          .matches(
            /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
            '(A-Z a-z)letters only allowed',
          )
          .required('This is required.'),
      }),
      guardianRelation: Yup.string().when('dateOfBirth', {
        is: (dateOfBirth: any) => !isAgeValid(dateOfBirth),
        then: Yup.string().required('This is required'),
      }),
      guardianAddress: Yup.string().when('dateOfBirth', {
        is: (dateOfBirth: any) => !isAgeValid(dateOfBirth),
        then: Yup.string().required('This is required.'),
      }),
    }),
    investorProfession: Yup.string().when('isIndividual', {
      is: (isIndividual: boolean) => isIndividual,
      then: Yup.string()
        .required('This is required')
        .min(3, 'Profession Name entered is too Short!')
        .matches(
          /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
          '(A-Z a-z)letters only allowed',
        ),
    }),
    investorOrganisation: Yup.string(),
    investorOrganisationOther: Yup.string().when('investorOrganisation', {
      is: (investorOrganisation: string) => investorOrganisation === 'Other',
      then: Yup.string()
        .required('This is required')
        .min(3, 'Organization Name entered is too Short!')
        .matches(/^[a-zA-Z ]+$/, '(A-Z a-z)letters only allowed'),
    }),
    kartaProfession: Yup.string().when('isHUF', {
      is: true,
      then: Yup.string()
        .required('This is required')
        .min(3, 'Profession Name entered is too Short!')
        .matches(/^[a-zA-Z0-9 ]+$/, '(A-Z a-z)letters only allowed'),
    }),
    kartaOrganisationOther: Yup.string().when('kartaOrganisation', {
      is: (kartaOrganisation: any) => kartaOrganisation === 'Other',
      then: Yup.string()
        .required('This is required')
        .min(3, 'Organization Name entered is too Short!')
        .matches(/^[a-zA-Z ]+$/, '(A-Z a-z)letters only allowed'),
    }),
    kartaOrganisation: Yup.string(),
  });

  const onSubmit = async (data: any) => {
    const body = { ...data };
    dispatch({
      type: IS_NRI,
      payload: {
        ...isNri,
        [holderNumber]: body.residentialStatus === 'NRI' ? true : false,
      },
    });

    body.mobileNumber = body.mobileNumber.includes('+')
      ? body.mobileNumber.slice(
        countryCode.toString().length + 1,
        body.mobileNumber.length,
      )
      : body.mobileNumber.slice(
        countryCode.toString().length,
        body.mobileNumber.length,
      );
    body.mobileNumber = body.mobileNumber.replace(/\s/g, '');
    body.mobileCountryCode = countryCode.toString().includes('+')
      ? countryCode
      : `+${countryCode}`;
    body.country = body.residentialStatus === 'NRI' ? body.country : '101';
    const accountId = accountIdManager?.getAccountId();
    if (basicDetailsData?.firstName === undefined) {
      const addedBasicDetailsResponse = await addBasicDetails(accountId, body);
      dispatch({
        type: HOLDER_ID,
        payload: {
          ...holderId,
          [holderNumber]: addedBasicDetailsResponse.holderId,
        },
      });
      dispatch({
        type: IS_USER_TYPE_API,
        payload: !isUserTypeApi,
      });
      toastMessage(
        addedBasicDetailsResponse,
        toast,
        tabValue[holderNumber] + 1,
        holderNumber,
        dispatch,
        selectorState,
        kycStatusForHolder[holderNumber] === KycStatus.KycInvalidated
          ? true
          : false,
        kycStatusForAccount === KycStatus.KycInvalidated ? true : false,
      );
    }
    if (basicDetailsData?.firstName !== undefined) {
      const updatedBasicDetailsResponse = await updateBasicDetails(
        accountId,
        holderId[holderNumber],
        body,
      );
      toastMessage(
        updatedBasicDetailsResponse,
        toast,
        tabValue[holderNumber] + 1,
        holderNumber,
        dispatch,
        selectorState,
        kycStatusForHolder[holderNumber] === KycStatus.KycInvalidated
          ? true
          : false,
        kycStatusForAccount === KycStatus.KycInvalidated ? true : false,
      );
    }
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      validationSchema={AddBasicDetailsSchema}
      enableReinitialize
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        setFieldTouched,
        touched,
        setFieldValue,
      }) => (
        <Form>
          <>
            <Flex flexDirection={'column'} w={'100%'} flex={1}>
              <Text
                fontSize={'12px'}
                color={colors.greyUserNameColor}
                w={'100%'}
              >
                Provide your personal information as per your Bank Account
              </Text>
              <Flex mt={4} direction={{ base: 'column', md: 'row' }}>
                <InputTile
                  testId='input-first-name'
                  placeholder="*First Name"
                  paddingRight={6}
                  handleChangeName={'firstName'}
                  setFieldValue={setFieldValue}
                  value={values.firstName}
                  errors={errors.firstName}
                  setFieldTouched={setFieldTouched}
                  touched={touched.firstName}
                  holderNumber={holderNumber}
                />
                <InputTile
                  testId='input-last-name'
                  placeholder="*Last Name"
                  paddingRight={6}
                  handleChangeName={'lastName'}
                  setFieldValue={setFieldValue}
                  value={values.lastName}
                  errors={errors.lastName}
                  setFieldTouched={setFieldTouched}
                  touched={touched.lastName}
                  holderNumber={holderNumber}
                />
                {(isIndividual || isJoint || isHUF) && (
                  <InputTile
                    testId='input-fathers-name'
                    placeholder="*Father's Name"
                    paddingRight={0}
                    handleChangeName={'fatherName'}
                    setFieldValue={setFieldValue}
                    value={values.fatherName}
                    errors={errors.fatherName}
                    setFieldTouched={setFieldTouched}
                    touched={touched.fatherName}
                    holderNumber={holderNumber}
                  />
                )}
              </Flex>
              <Flex
                mt={{ base: 0, md: 4 }}
                direction={{ base: 'column', md: 'row' }}
              >
                <InputTile
                  testId='input-email'
                  placeholder="*Email Id"
                  paddingRight={6}
                  handleChangeName={'emailAddress'}
                  setFieldValue={setFieldValue}
                  value={values.emailAddress}
                  errors={errors.emailAddress}
                  setFieldTouched={setFieldTouched}
                  touched={touched.emailAddress}
                  isEmailVerified={emailStatus}
                  isDisabled={
                    isAllFieldsDisable[holderNumber] ||
                    (emailStatus && holderNumber === 0)
                  }
                  holderNumber={holderNumber}
                  isValueNormal={true}
                />
                <Flex
                  flex={1}
                  pt={'10px'}
                  direction={'column'}
                  className={styles['phoneInputWrapper']}
                >
                  <Flex>
                    <PhoneInput
                      inputProps={{
                        autoFocus: false,
                        prefix: '+',
                      }}
                      country="in"
                      placeholder="Enter Mobile Number*"
                      enableSearch
                      disabled={
                        isAllFieldsDisable[holderNumber] || holderNumber === 0
                      }
                      isValid
                      countryCodeEditable={false}
                      value={values.mobileNumber}
                      onBlur={() => setFieldTouched('mobileNumber')}
                      onChange={(ph: any, data: any) => {
                        handleChange('mobileNumber')(ph);
                        setCountryCode(data.dialCode);
                      }}
                    />
                    {holderNumber === 0 && (
                      <Image
                        src={verifiedImage}
                        width={'35px'}
                        height={'35px'}
                        alt=""
                      ></Image>
                    )}
                  </Flex>
                  {errors.mobileNumber && touched.mobileNumber && (
                    <div className={styles['errorRegMessage']}>
                      {errors.mobileNumber.toString()}
                    </div>
                  )}
                </Flex>
              </Flex>
              {isIndividual && (
                <Flex
                  mt={{ base: 0, md: 4 }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <InputTile
                    testId='input-investor-profession'
                    placeholder="*Investor's Profession"
                    paddingRight={6}
                    handleChangeName={'investorProfession'}
                    setFieldValue={setFieldValue}
                    value={values.investorProfession}
                    errors={errors.investorProfession}
                    setFieldTouched={setFieldTouched}
                    touched={touched.investorProfession}
                    holderNumber={holderNumber}
                  />
                  <CustomSelectDropdown
                    testId='select-investor-organization'
                    paddingRight={
                      values.investorOrganisation === 'Other' ? 6 : 0
                    }
                    placeholder={"Investor's Organization"}
                    values={[...Object.values(InvestorOrganizationEnum)]}
                    setSelectedDropdownValue={handleChange(
                      'investorOrganisation',
                    )}
                    handleChangeName={'investorOrganisation'}
                    value={values.investorOrganisation}
                    isMandatory={false}
                    errors={errors.investorOrganisation}
                    touched={touched.investorOrganisation}
                    setFieldTouched={setFieldTouched}
                    holderNumber={holderNumber}
                  />
                  {values.investorOrganisation === 'Other' && (
                    <InputTile
                      placeholder="*Investor's Organization"
                      paddingRight={0}
                      handleChangeName={'investorOrganisationOther'}
                      setFieldValue={setFieldValue}
                      value={values.investorOrganisationOther}
                      errors={errors.investorOrganisationOther}
                      setFieldTouched={setFieldTouched}
                      touched={touched.investorOrganisationOther}
                      holderNumber={holderNumber}
                    />
                  )}
                </Flex>
              )}
              {isHUF && (
                <Flex
                  mt={{ base: 0, md: 4 }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <InputTile
                    testId='input-kartas-profession'
                    placeholder="*Karta's Profession"
                    paddingRight={6}
                    handleChangeName={'kartaProfession'}
                    setFieldValue={setFieldValue}
                    value={values.kartaProfession}
                    errors={errors.kartaProfession}
                    setFieldTouched={setFieldTouched}
                    touched={touched.kartaProfession}
                    holderNumber={holderNumber}
                  />
                  <CustomSelectDropdown
                    testId='select-kartas-organization'
                    paddingRight={values.kartaOrganisation === 'Other' ? 6 : 0}
                    placeholder={"Karta's Organization"}
                    values={[...Object.values(InvestorOrganizationEnum)]}
                    setSelectedDropdownValue={handleChange('kartaOrganisation')}
                    handleChangeName={'kartaOrganisation'}
                    value={values.kartaOrganisation}
                    errors={errors.kartaOrganisation}
                    touched={touched.kartaOrganisation}
                    setFieldTouched={setFieldTouched}
                    holderNumber={holderNumber}
                  />
                  {values.kartaOrganisation === 'Other' && (
                    <InputTile
                      placeholder="*Karta's Organization"
                      paddingRight={0}
                      handleChangeName={'kartaOrganisationOther'}
                      setFieldValue={setFieldValue}
                      value={values.kartaOrganisationOther}
                      errors={errors.kartaOrganisationOther}
                      setFieldTouched={setFieldTouched}
                      touched={touched.kartaOrganisationOther}
                      holderNumber={holderNumber}
                    />
                  )}
                </Flex>
              )}

              <Flex
                mt={{ base: 0, md: 4 }}
                direction={{ base: 'column', md: 'row' }}
              >
                <CustomSelectDropdown
                  testId='select-residential-status'
                  paddingRight={6}
                  placeholder={'*Residential Status'}
                  values={[...Object.values(ResidentialStatusEnum)]}
                  setSelectedDropdownValue={handleChange('residentialStatus')}
                  handleChangeName={'residentialStatus'}
                  value={values.residentialStatus}
                  errors={errors.residentialStatus}
                  touched={touched.residentialStatus}
                  setFieldTouched={setFieldTouched}
                  holderNumber={holderNumber}
                />
                {values.residentialStatus === 'NRI' && (
                  <Flex
                    flex={1}
                    direction={'column'}
                    pt="5px"
                    pr={{ base: 0, md: 6 }}
                  >
                    <SelectCountryDropdown
                      testId='select-country'
                      selectedCountry={values.country}
                      setSelectedCountry={handleChange('country')}
                      isColorGrey={true}
                      isKyc={true}
                      isDisable={isAllFieldsDisable[holderNumber]}
                    />
                    {errors.country && saveAndContinueClicked && (
                      <div className={styles['errorRegMessage']}>
                        {errors.country.toString()}
                      </div>
                    )}
                  </Flex>
                )}
                <Flex
                  flex={1}
                  direction={'column'}
                  w={'100%'}
                  className={styles['datePicker_wrapper']}
                  alignItems={'flex-end'}
                >
                  <DatePickerField
                    data-test='input-date-of-birth'
                    name="dateOfBirth"
                    value={values.dateOfBirth}
                    onChange={setFieldValue}
                    setDate={setDate}
                    setFieldTouched={setFieldTouched}
                    maxDate={new Date()}
                    isDisable={isAllFieldsDisable[holderNumber]}
                  />
                  {errors.dateOfBirth && touched.dateOfBirth && (
                    <Flex className={styles['errorRegMessage']} w={'100%'}>
                      This is Required
                    </Flex>
                  )}
                </Flex>
              </Flex>
              {showGuardianDetails && (
                <>
                  <Flex
                    mt={{ base: 0, md: 4 }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <InputTile
                      testId='input-name-of-Guardian'
                      placeholder="*Name of Guardian"
                      paddingRight={6}
                      handleChangeName={'guardianName'}
                      setFieldValue={setFieldValue}
                      value={values.guardianName}
                      errors={errors.guardianName}
                      setFieldTouched={setFieldTouched}
                      touched={touched.guardianName}
                      holderNumber={holderNumber}
                    />
                    <InputTile
                      testId='input-address-of-guardian'
                      placeholder="*Address of Guardian"
                      paddingRight={0}
                      handleChangeName={'guardianAddress'}
                      setFieldValue={setFieldValue}
                      value={values.guardianAddress}
                      errors={errors.guardianAddress}
                      setFieldTouched={setFieldTouched}
                      touched={touched.guardianAddress}
                      holderNumber={holderNumber}
                    />
                  </Flex>
                  <Flex
                    mt={{ base: 0, md: 4 }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <CustomSelectDropdown
                      testId='select-relationship-with-minor'
                      flex={0.5}
                      paddingRight={6}
                      placeholder={'*Relationship with Minor'}
                      values={[...Object.values(RelationshipWithMinorEnum)]}
                      setSelectedDropdownValue={handleChange(
                        'guardianRelation',
                      )}
                      handleChangeName={'guardianRelation'}
                      value={values.guardianRelation}
                      errors={errors.guardianRelation}
                      touched={touched.guardianRelation}
                      setFieldTouched={setFieldTouched}
                      holderNumber={holderNumber}
                    />
                  </Flex>
                </>
              )}
              <Flex
                mt={{ base: 0, md: 4 }}
                direction={{ base: 'column', md: 'row' }}
              >
                <CustomSelectDropdown
                  testId='select-gender'
                  flex={0.5}
                  paddingRight={6}
                  placeholder={'*Gender'}
                  values={[...Object.values(GenderEnum)]}
                  setSelectedDropdownValue={handleChange('gender')}
                  handleChangeName={'gender'}
                  value={values.gender}
                  errors={errors.gender}
                  touched={touched.gender}
                  setFieldTouched={setFieldTouched}
                  holderNumber={holderNumber}
                />
              </Flex>
              <Flex>
                <CustomPageBottom
                  buttonIsVisible={false}
                  holderNumber={holderNumber}
                />
              </Flex>
              <Flex justifyContent={'flex-end'}>
                <CustomButton
                  testId="button-save-continue"
                  btnText="SAVE & CONTINUE"
                  pl={'19px'}
                  pr={'19px'}
                  fontSize={'12px'}
                  btnPressed={submitBtnClicked}
                  isDisable={isAllFieldsDisable[holderNumber]}
                />
              </Flex>
            </Flex>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default BasicDetails;
