import twitter from '../../assets/svg/icons8-twitter-circled.svg';
import facebook from '../../assets/svg/icons8-facebook-circled.svg';
import linkedin from '../../assets/svg/icons8-linkedin-circled.svg';
import youtube from '../../assets/svg/icons8-youtube.svg';
import insta from '../../assets/svg/icons8-instagram.svg';
import hbitLogo from 'libs/src/assets/svg/hbit-logo.svg';
import { Environment } from 'libs/src/services/EnvironmentHelper';
import { useRouter } from 'next/router';
import { Container } from 'react-bootstrap';
import Footerdata from './Data/footerDataIndex';
import { FooterLinkWrapper } from '../LinkWrapper';
import FooterdataCommercialRealEstate from './Data/footerDataCommercialRealEstate';
import FooterdataPreLeasedProperty from './Data/footerDataPreLeasedProperty';
import * as React from 'react';
import FooterdataFractionalOwnershipInvestment from './Data/footerDataFractionalOwnershipInvestmentData';
import FooterdataAIF from './Data/footerDataAIF';
import PagesUrl from 'libs/src/enums/pages-url.enum';
import FooterdataNRI from './Data/footerDataNRI';

const Footer = () => {
  const router = useRouter();
  const landingPage = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  const getFooterData = () => {
    const url = router?.pathname;
    switch (url) {
      case PagesUrl.HomePage:
        return <Footerdata />;
      case PagesUrl.CommercialRealEstateInvestment:
        return <FooterdataCommercialRealEstate />;
      case PagesUrl.FractionalOwnershipInvestment:
        return <FooterdataFractionalOwnershipInvestment />;
      case PagesUrl.PreleasedProperty:
        return <FooterdataPreLeasedProperty />;
      case PagesUrl.AlternativeInvestmentFund:
        return <FooterdataAIF />;
      case PagesUrl.NRIRealEstate:
        return <FooterdataNRI />;
      default:
        return;
    }
  };

  return (
    <div className="footer">
      <Container>
        <div className="footer-container">
          <div className="ro">
            <a href={landingPage + '/'}>
              <img src={hbitLogo} className={'logo'} />
            </a>
          </div>
          <div className="ro footer-menu">
            <div className="col-lg-4">
              <h5>COMPANY</h5>
              <div className="ro">
                <div className="col-lg-6">
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/properties'}
                      text={'Properties'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/about'}
                      text={'About Us'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/how-it-works'}
                      text={'How It Works'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/media'}
                      text={'Media'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/testimonials'}
                      text={'Testimonials'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/faq'}
                      text={'FAQs'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/contact-us'}
                      text={'Contact Us'}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/blog'}
                      text={'Blog'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/careers'}
                      text={'Careers'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/sitemap'}
                      text={'Sitemap'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/channel-partner'}
                      text={'Channel Partner'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={
                        landingPage + '/alternative-investment-funds-aif'
                      }
                      text={'AIF'}
                    />
                  </div>
                  <div>
                    <FooterLinkWrapper
                      urlLink={landingPage + '/nri-real-estate-investment'}
                      text={'NRI'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <h5>LEGAL</h5>
              <div>
                <FooterLinkWrapper
                  urlLink={landingPage + '/terms-and-conditions'}
                  text={'Terms & Conditions'}
                />
              </div>
              <div>
                <FooterLinkWrapper
                  urlLink={landingPage + '/privacy'}
                  text={'Privacy Policy'}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <h5>CONTACT</h5>
              <div>
                <p>
                  hBits, G-4, Court Chambers,
                  <br />
                  35 New Marine Lines
                  <br />
                  Mumbai 400020, Maharashtra,
                  <br />
                  India
                  <br />
                  <a href="mailto:info@hbtis.co">info@hbits.co</a>
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <h5>SOCIAL</h5>
              <a target={'_blank'} href="https://twitter.com/hBitsPlatform">
                <img src={twitter} alt="twitter" loading="lazy" />
              </a>
              <a
                target={'_blank'}
                href="https://www.facebook.com/hBitsPlatform"
              >
                <img src={facebook} alt="facebook" loading="lazy" />
              </a>
              <a
                target={'_blank'}
                href="https://www.linkedin.com/company/hbits-official"
              >
                <img src={linkedin} alt="linkedin" loading="lazy" />
              </a>
              <a
                target={'_blank'}
                href="https://www.youtube.com/channel/UCe_I9W3piDVN8oXIC3xSFkg"
              >
                <img src={youtube} alt="youtube" loading="lazy" />
              </a>
              <a
                target={'_blank'}
                href="https://www.instagram.com/hbitsofficial/?hl=en"
              >
                <img src={insta} alt="insta" loading="lazy" />
              </a>
              <div className="copyright">
                <p>COPYRIGHT © 2010-2025</p>
                <p>HBITS PROPTECH PRIVATE LIMITED</p>
                <p className="mt-4">ALL RIGHTS RESERVED.</p>
              </div>
            </div>
          </div>
          <div className="ro">
            <div className="col-lg-4 mt-5">
              <div className="rera_details">
                <p>MahaRera Registration No.</p>
                <p>A51900027303</p>
              </div>
              <div className="rera_web">
                <a
                  href="https://maharera.mahaonline.gov.in/"
                  target="_BLANK"
                  rel="noreferrer"
                >
                  https://maharera.mahaonline.gov.in/
                </a>
              </div>
            </div>
            <div className="col-lg-8 mt-5">
              <div className="tech_stack">
                <h4>
                  <span>POWERED BY </span>
                  <span />
                </h4>
              </div>
              <div className="tech_stack_logos">
                <div className="icons">
                  <div className={'isdiv'}>
                    <img
                      className="cashfree_img"
                      src={
                        router
                          ? '/assets/images/footer/cashfree.png'
                          : require('../../assets/images/cashfree.png')
                      }
                      alt="Cash Free Logo"
                    />
                  </div>
                  <div className={'isdiv'}>
                    <img
                      className="aws_img"
                      src={
                        router
                          ? '/assets/images/footer/aws.png'
                          : require('../../assets/images/aws.png')
                      }
                      alt="AWS Logo"
                    />
                  </div>
                  <div className={'isdiv'}>
                    <img
                      className="images"
                      src={
                        router
                          ? '/assets/images/footer/knowlarity.png'
                          : require('../../assets/images/knowlarity.png')
                      }
                      alt="Knowlarity Logo"
                    />
                  </div>
                  <div className={'isdiv'}>
                    <img
                      className="lead_img"
                      src={
                        router
                          ? '/assets/images/footer/LeadSquared.webp'
                          : require('../../assets/images/LeadSquared.webp')
                      }
                      alt="Lead Squared Logo"
                    />
                  </div>

                  <div className={'isdiv'}>
                    <img
                      className="postgres_img"
                      src={
                        router
                          ? '/assets/images/footer/postgres.png'
                          : require('../../assets/images/postgres.png')
                      }
                      alt="Postgres Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {getFooterData()}
      </Container>
    </div>
  );
};
export default Footer;
