import { Strings } from '@hBits.Core';

export const tenent = 'Tenent';
export const tenentText = `An ideal opportunity to invest in a Grade A+ asset located at Ackruti
Centre Point, Andheri.The asset is located on the 6th and 7th floor
of the building and is let out to ICICI bank and IIFL Securities
(IIFL).Both ICICI bank and IIFL are Grade A tenants with long leases
and lock -in.ICICI bank is one of the 'Big Four' banks of India while
lIFL is one of the leading financial services company in India.`;

export const analyticsTableHeads = [
  Strings.particulars,
  Strings.grossInflow,
  Strings.outflow,
  Strings.tDSFundedByCO,
  Strings.monthlyTotal,
];
export const analyticsTableHeadsValues = [
  {
    particulars: 'Rental Income',
    grossInflow: '',
    taxDeducted: '',
    outFlow: '',
    tds: '',
    monthlyTotal: '710,360.16',
  },
  {
    particulars: 'Rent Top-up',
    grossInflow: '',
    taxDeducted: '',
    outFlow: '',
    tds: '',
    monthlyTotal: '710,360.16',
  },
  {
    particulars: 'Income on SD',
    grossInflow: '',
    taxDeducted: '',
    outFlow: '',
    tds: '',
    monthlyTotal: '710,360.16',
  },
  {
    particulars: 'Property Tax',
    grossInflow: '',
    taxDeducted: '10%',
    outFlow: '-1324',
    tds: '',
    monthlyTotal: '710,360.16',
  },
  {
    particulars: 'Management Fees',
    grossInflow: '',
    taxDeducted: '8%',
    outFlow: '-2525',
    tds: '',
    monthlyTotal: '710,360.16',
  },
  {
    particulars: 'TDS',
    grossInflow: '',
    taxDeducted: '',
    outFlow: '6%',
    tds: '-25352',
    monthlyTotal: '710,360.16',
  },
  {
    particulars: 'TDS on Rental Income',
    grossInflow: '',
    taxDeducted: '4%',
    outFlow: '2352352',
    tds: '34534',
    monthlyTotal: '710,360.16',
  },
  {
    particulars: 'TDS on Rent Top Up',
    grossInflow: '',
    taxDeducted: '5%',
    outFlow: '5t2453',
    tds: '654',
    monthlyTotal: '710,360.16',
  },
  {
    particulars: 'TDS on Interest on SD',
    grossInflow: '',
    taxDeducted: '6%',
    outFlow: '54635',
    tds: '345',
    monthlyTotal: '710,360.16',
  },
  {
    particulars: 'Total',
    grossInflow: '',
    taxDeducted: '6%',
    outFlow: '54635',
    tds: '345',
    monthlyTotal: '710,360.16',
  },
];
export const referalTable = [
  {
    name: 'Tejasva Singh',
    email: 'tejasvaSingh@cawstudhios.com',
    mobileNo: '9161463059',
    city: 'Hyderabad',
    status: 'KYC DONE',
  },
  {
    name: 'Ayush Singh',
    email: 'Ayush@cawstudhios.com',
    mobileNo: '9161463059',
    city: 'Hyderabad',
    status: 'KYC DONE',
  },
  {
    name: 'Pavan Kumar',
    email: 'Pavan@cawstudhios.com',
    mobileNo: '9161463059',
    city: 'Hyderabad',
    status: 'KYC DONE',
  },
  {
    name: 'Kartik Bansal',
    email: 'Kartik@cawstudhios.com',
    mobileNo: '9161463059',
    city: 'Hyderabad',
    status: 'KYC DONE',
  },
];
