import { combineReducers } from 'redux';
import appReducer from './GlobalReducer/App.reducer';
import counterReducer from './CounterReducer/counter.reducer';

const rootReducer = combineReducers({
  appReducer,
  counterReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
