import { Flex } from '@chakra-ui/react';
import SelectCountryDropdown from 'apps/customer-portal-web/src/screens/ChannelPartner/helper-components/CountriesDropDown';
import { InputTile } from './CustomInputTile';
import styles from '../index.module.scss';
import SelectStatesDropdown from 'apps/customer-portal-web/src/screens/ChannelPartner/helper-components/StatesDropDown';

interface AddressFormProps {
  errors: any;
  handleChange: any;
  values: any;
  setFieldTouched: any;
  touched: any;
  isCommunicationAddress: boolean;
  marginTop?: any;
  holderNumber: any;
  setFieldValue: any;
  saveAndContinueClicked: boolean;
  isAllFieldsDisable: boolean;
}

const AddressForm = (props: AddressFormProps) => {
  const {
    handleChange,
    values,
    errors,
    setFieldTouched,
    touched,
    isCommunicationAddress,
    holderNumber,
    marginTop,
    setFieldValue,
    saveAndContinueClicked,
    isAllFieldsDisable,
  } = props;

  return (
    <Flex flex={1} direction={'column'} mt={marginTop}>
      <Flex flex={1} direction={{ base: 'column', md: 'row' }}>
        <Flex flex={1} direction={'column'} pt="5px" pr={{ base: 0, md: 6 }}>
          <SelectCountryDropdown
            testId='select-country-name'
            selectedCountry={
              isCommunicationAddress ? values.country : values.permanentCountry
            }
            setSelectedCountry={handleChange(
              isCommunicationAddress ? 'country' : 'permanentCountry',
            )}
            isColorGrey={true}
            isKyc={true}
            isDisable={isAllFieldsDisable}
          />
          {isCommunicationAddress && (
            <>
              {errors.country && saveAndContinueClicked && (
                <div className={styles['errorRegMessage']}>
                  {errors.country.toString()}
                </div>
              )}
            </>
          )}
          {!isCommunicationAddress && (
            <>
              {errors.permanentCountry && saveAndContinueClicked && (
                <div className={styles['errorRegMessage']}>
                  {errors.permanentCountry.toString()}
                </div>
              )}
            </>
          )}
        </Flex>
        <Flex flex={1}>
          <InputTile
            testId="input-pincode"
            setFieldValue={setFieldValue}
            placeholder={'*Pincode'}
            handleChangeName={
              isCommunicationAddress ? 'pincode' : 'permanentPincode'
            }
            setFieldTouched={setFieldTouched}
            value={
              isCommunicationAddress ? values.pincode : values.permanentPincode
            }
            errors={
              isCommunicationAddress ? errors.pincode : errors.permanentPincode
            }
            isAllLettersCapital={true}
            touched={
              isCommunicationAddress
                ? touched.pincode
                : touched.permanentPincode
            }
            holderNumber={holderNumber}
          />
        </Flex>
      </Flex>
      <Flex mt={{ base: 0, md: 4 }} direction={{ base: 'column', md: 'row' }}>
        <InputTile
          testId="input-full-address"
          setFieldValue={setFieldValue}
          placeholder={'*FullAddress'}
          handleChangeName={
            isCommunicationAddress ? 'fullAddress' : 'permanentFullAddress'
          }
          setFieldTouched={setFieldTouched}
          value={
            isCommunicationAddress
              ? values.fullAddress
              : values.permanentFullAddress
          }
          errors={
            isCommunicationAddress
              ? errors.fullAddress
              : errors.permanentFullAddress
          }
          touched={
            isCommunicationAddress
              ? touched.fullAddress
              : touched.permanentFullAddress
          }
          holderNumber={holderNumber}
        />
      </Flex>
      <Flex mt={{ base: 0, md: 4 }} direction={{ base: 'column', md: 'row' }}>
        <InputTile
          testId="input-landmark-province-region"
          setFieldValue={setFieldValue}
          paddingRight={6}
          placeholder={'*Landmark/ Province/ Region'}
          handleChangeName={
            isCommunicationAddress ? 'landmark' : 'permanentLandmark'
          }
          setFieldTouched={setFieldTouched}
          value={
            isCommunicationAddress ? values.landmark : values.permanentLandmark
          }
          errors={
            isCommunicationAddress ? errors.landmark : errors.permanentLandmark
          }
          touched={
            isCommunicationAddress
              ? touched.landmark
              : touched.permanentLandmark
          }
          holderNumber={holderNumber}
        />
        <Flex flex={1} direction={'column'} pt="5px">
          <SelectStatesDropdown
            testId='select-state'
            selectedCoutry={
              isCommunicationAddress ? values.country : values.permanentCountry
            }
            selectedState={
              isCommunicationAddress ? values.state : values.permanentState
            }
            setSelectedState={handleChange(
              isCommunicationAddress ? 'state' : 'permanentState',
            )}
            isColorGrey={true}
            isKyc={true}
            isDisable={isAllFieldsDisable}
          />
          {isCommunicationAddress && (
            <>
              {errors.state && touched.state && (
                <div className={styles['errorRegMessage']}>
                  {errors.state.toString()}
                </div>
              )}
            </>
          )}
          {!isCommunicationAddress && (
            <>
              {errors.permanentState && touched.permanentState && (
                <div className={styles['errorRegMessage']}>
                  {errors.permanentState.toString()}
                </div>
              )}
            </>
          )}
        </Flex>
      </Flex>
      <Flex mt={{ base: 0, md: 4 }} direction={{ base: 'column', md: 'row' }}>
        <InputTile
          testId="input-city-name"
          flex={0.5}
          setFieldValue={setFieldValue}
          paddingRight={6}
          placeholder={'*City'}
          handleChangeName={isCommunicationAddress ? 'city' : 'permanentCity'}
          setFieldTouched={setFieldTouched}
          value={isCommunicationAddress ? values.city : values.permanentCity}
          errors={isCommunicationAddress ? errors.city : errors.permanentCity}
          touched={
            isCommunicationAddress ? touched.city : touched.permanentCity
          }
          holderNumber={holderNumber}
        />
      </Flex>
    </Flex>
  );
};

export default AddressForm;
