import assetIcon from '../../assets/assets.svg';
import assetIconHover from '../../assets/assets_hover.svg';
import clientIcon from '../../assets/clients.svg';
import clientIconHover from '../../assets/clients_hover.svg';
import collateralsIcon from '../../assets/collaterals.svg';
import collateralsIconHover from '../../assets/collaterals_hover.svg';
import overviewIcon from '../../assets/overview.svg';
import overviewIconHover from '../../assets/overview_hover.svg';
import teamIcon from '../../assets/team.svg';
import teamHoverIcon from '../../assets/team_hover.svg';

export enum InvestmentHash {
  Overview = '#overview',
  Portfolio = '#portfolio',
  Documnets = '#documents',
  Analytics = '#analytics',
  Referral = '#referral',
  Statement = '#statement',
}

export const InvestmentLinks = [
  {
    name: 'Overview',
    link: '#overview',
    logoSrc: overviewIcon,
    logoSrcHover: overviewIconHover,
  },
  {
    name: 'Portfolio',
    link: '#portfolio',
    logoSrc: clientIcon,
    logoSrcHover: clientIconHover,
  },
  {
    name: 'Documents',
    link: '#documents',
    logoSrc: assetIcon,
    logoSrcHover: assetIconHover,
  },
  {
    name: 'Analytics',
    link: '#analytics',
    logoSrc: collateralsIcon,
    logoSrcHover: collateralsIconHover,
  },
  {
    name: 'Referral',
    link: '#referral',
    logoSrc: teamIcon,
    logoSrcHover: teamHoverIcon,
  },
  {
    name: 'Statement',
    link: '#statement',
    logoSrc: teamIcon,
    logoSrcHover: teamHoverIcon,
  },
];
