import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPMethod, HTTPStatusCode } from 'libs/src/enums';
import { fetchAsync } from 'libs/src/services/NetworkHelper';

export const getKycData = async (accountId: any) => {
  try {
    const req = {
      method: HTTPMethod.GET,
      url: baseApiUrl + endpoints.customers + accountId,
    };
    const response = await fetchAsync(req);
    return response;
  } catch (_error) {
    return null;
  }
};

export const updateUserType = async (accountId: any, body: any) => {
  try {
    const req = {
      method: HTTPMethod.PUT,
      data: body,
      url:
        baseApiUrl + endpoints.customers + accountId + endpoints.kycAccountType,
    };
    const response = await fetchAsync(req);
    return response;
  } catch (_error) {
    return null;
  }
};

export const getLeadOwnerData = async (accountId: any) => {
  try {
    const req = {
      method: HTTPMethod.GET,
      url: baseApiUrl + endpoints.customers + accountId + endpoints.leadOwner,
    };
    const response = await fetchAsync(req);
    return response;
  } catch (_error) {
    return null;
  }
};
