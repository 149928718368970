import { OpsRole } from '@hBits.Core';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPMethod, HTTPStatusCode } from 'libs/src/enums';
import { fetchAsync } from '../../NetworkHelper';

type OpsTeamAPIResponse = {
  isError: boolean;
  errorStatusCode?: number | undefined;
};

export const fetchAllOpsTeamMembers = async (): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.GET,
      url: baseApiUrl + endpoints.opsTeam,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const addOpsTeamMember = async (
  id: string,
  role: OpsRole,
): Promise<boolean> => {
  try {
    const request = {
      method: HTTPMethod.POST,
      data: {
        role,
      },
      url: baseApiUrl + endpoints.opsTeam + `/${id}`,
    };

    const response = await fetchAsync(request);
    return response.status === HTTPStatusCode.NO_CONTENT;
  } catch (error) {
    return false;
  }
};

export const updateOpsTeamMembersRole = async (
  id: string,
  role: OpsRole,
): Promise<OpsTeamAPIResponse> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      data: {
        role,
      },
      url: baseApiUrl + endpoints.opsTeam + `/${id}`,
    };

    const response = await fetchAsync(request);
    if (response.status !== HTTPStatusCode.NO_CONTENT) {
      return {
        isError: true,
        errorStatusCode: response.status,
      };
    }
    return {
      isError: false,
    };
  } catch (error) {
    return {
      isError: true,
    };
  }
};

export const removeMemberFromOpsTeam = async (
  id: string,
): Promise<OpsTeamAPIResponse> => {
  try {
    const request = {
      method: HTTPMethod.DELETE,
      url: baseApiUrl + endpoints.opsTeam + `/${id}`,
    };

    const response = await fetchAsync(request);
    if (response.status !== HTTPStatusCode.NO_CONTENT) {
      return {
        isError: true,
        errorStatusCode: response.status,
      };
    }
    return {
      isError: false,
    };
  } catch (error) {
    return {
      isError: true,
    };
  }
};
