export default {
  headerColor: '#1a295b',
  grey: '#333333',
  grey100: '#999999',
  grey200: '#666666',
  grey300: '#E9ECF3',
  grey400: '#F9FBFF',
  grey500: '#f9f9f9',
  grey600: '#535353',
  bgDark: '#F2F2F2',
  bgLight: '#FBFCFF',
  shadowColor: '#7574b5',
  borderColor: '#D3E0FC',
  blackColor: '#000000',
  buttonColor: '#ffb657',
  white: '#ffffff',
  bgDarkBlue: '#1a295b',
  orangeColor: '#ffb657',
};
