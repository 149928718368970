import { Flex, Select, Grid, Text, Checkbox, useToast } from '@chakra-ui/react';
import {
  addBankDetails,
  fetchBankData,
  updateBankDetails,
} from 'apps/customer-portal-web/src/services/Kyc/bankDetails';
import { Form, Formik } from 'formik';
import { Colors } from 'libs/src/theme';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { InputTile } from '../HelperComponents/CustomInputTile';
import CustomPageBottom from '../HelperComponents/CustomPageBottom';
import CustomPanCardTile from '../HelperComponents/CustomPanCardTile';
import CustomSelectDropdown from '../HelperComponents/CustomSelectDropdown';
import FileUpload from '../HelperComponents/FileUpload';
import styles from '../index.module.scss';
import {
  capitalizeFirstLetter,
  getHolderTypeName,
  toastMessage,
} from '../utils';
import {
  BankAccountTypeEnum,
  HTTPStatusCode,
  KycStatus,
  ResidentialStatusEnum,
  Strings,
} from '@hBits.Core';
import { DatePickerField } from '../HelperComponents/DatePickerField';
import { useDispatch, useSelector } from 'react-redux';
import accountIdManager from 'libs/src/auth/account-manager';
import { getKycData } from 'apps/customer-portal-web/src/services/Kyc/getKycData';
import {
  IS_GET_KYC_DATA,
  IS_SUBMITTED_PAGE_VISIBLE,
} from 'apps/customer-portal-web/src/store/constants/ReduxConstants';

interface BankDetailsInt {
  panHolderName: string;
  panNumber: string;
  panIdProofUrl: string;
  panIdProofType: string;
  panRejectionReason: string;
  bankName: string;
  bankNameOther: string;
  bankAccountType: string;
  bankAccountNumber: any;
  ifscCode: string;
  bankAccountProofUrl: string;
  bankAccountProofType: string;
  bankAccountRejectionReason: string;
  dateOfIncorporation: any;
  residentialStatus: any;
  consentFromCoParceners: boolean;
}

interface BankDetailsProps {
  isIndividual?: boolean;
  isJoint?: boolean;
  isHuf?: boolean;
  frontPanNameText?: string;
  holderNumber: number;
}

const BankDetails = (props: BankDetailsProps) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const selectorState = useSelector((state: any) => state.KycReducer);
  const {
    isAllFieldsDisable,
    holderId,
    tabValue,
    isNri,
    kycStatusForHolder,
    kycStatusForAccount,
    isSubmittedPageVisible,
    isGetKycData,
  } = selectorState;
  const {
    isIndividual = false,
    isJoint = false,
    frontPanNameText = '',
    isHuf = false,
    holderNumber,
  } = props;
  const [bankData, setBankData] = useState<any>();
  const [bankDetailsData, setBankDetailsData] = useState<any>();
  const [date, setDate] = useState<any>();
  const [schemaState, setSchemaState] = useState<boolean>(
    !(isIndividual || isJoint),
  );
  const getBankData = async () => {
    const response = await fetchBankData();
    setBankData(response);
  };
  useEffect(() => {
    getBankData();
    getBankDetails();
  }, []);

  const getBankDetails = async () => {
    if (bankDetailsData === undefined) {
      const response = await getKycData(accountIdManager?.getAccountId());
      if (response?.status === HTTPStatusCode.OK) {
        setBankDetailsData(response?.data?.holders[holderNumber]?.bankDetails);
      }
    }
  };
  const getInitialValues: () => BankDetailsInt = () => {
    let initialValues = {
      panHolderName: bankDetailsData?.panHolderName,
      panNumber: bankDetailsData?.panNumber,
      panIdProofUrl: bankDetailsData?.panIdProofUrl,
      panIdProofType: bankDetailsData?.panIdProofType,
      bankName: capitalizeFirstLetter(bankDetailsData?.bankName),
      bankNameOther: bankDetailsData?.bankNameOther,
      bankAccountType: bankDetailsData?.bankAccountType,
      bankAccountNumber: bankDetailsData?.bankAccountNumber,
      ifscCode: bankDetailsData?.ifscCode,
      bankAccountProofUrl: bankDetailsData?.bankAccountProofUrl,
      bankAccountProofType: bankDetailsData?.bankAccountProofType,
      panRejectionReason: bankDetailsData?.panRejectionReason,
      bankAccountRejectionReason: bankDetailsData?.bankAccountRejectionReason,
      dateOfIncorporation: bankDetailsData?.dateOfIncorporation,
      residentialStatus: bankDetailsData?.residentialStatus,
      consentFromCoParceners: bankDetailsData?.consentFromCoParceners,
      schemaState: schemaState,
      holderType: getHolderTypeName(holderNumber),
    };
    return initialValues;
  };
  const AddBankDetailsSchema = Yup.object().shape({
    panNumber: Yup.string()
      .matches(
        /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
        'Pan No format should be ABCDE1234F',
      )
      .required('This is required.'),
    panHolderName: Yup.string()
      .min(3, 'PAN Name entered is too Short!')
      .matches(
        /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
        '(A-Z a-z)letters only allowed',
      )
      .required('This is required.'),
    panIdProofUrl: Yup.string().required('This is required'),
    bankName: Yup.string().required('This is required.'),
    bankNameOther: Yup.string().when('bankName', {
      is: (bankName: any) => bankName === 'Others',
      then: Yup.string()
        .required('This is required')
        .min(3, 'Bank Name entered is too Short!')
        .max(25, 'Bank Name entered is too Long!')
        .matches(
          /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
          '(A-Z a-z)letters only allowed',
        ),
    }),
    bankAccountType: Yup.string().required('This is required.'),
    bankAccountNumber: Yup.string()
      .min(8, 'Account Number should be minimun of 8 digits')
      .max(18, 'Account Number should not exceed 18 digits')
      .matches(/^[0-9]*$/, 'Only Digits are allowed')
      .required('This is required.'),
    ifscCode: Yup.string()
      .required('This is required.')
      .trim()
      .max(32, 'IFSC Code should not exceed 32 characters'),
    bankAccountProofUrl: Yup.string().required('This is required'),
    schemaState: Yup.boolean(),
    dateOfIncorporation: Yup.string().when('schemaState', {
      is: (schemaState: boolean) => schemaState,
      then: Yup.string().required('This is required.'),
    }),
    residentialStatus: Yup.string().when('schemaState', {
      is: (schemaState: boolean) => schemaState,
      then: Yup.string().required('This is required.'),
    }),
  });
  const isDocumentsInvalidated = (
    panRejection: string | undefined,
    bankRejection: string | undefined,
  ) => {
    if (panRejection != undefined || bankRejection !== undefined) {
      return false;
    } else {
      return true;
    }
  };

  const bankBtnAction = (response: any) => {
    dispatch({
      type: IS_GET_KYC_DATA,
      payload: !isGetKycData,
    });
    if (
      kycStatusForHolder[holderNumber] === KycStatus.KycInProgress ||
      kycStatusForHolder[holderNumber] === KycStatus.KycNotStarted
    ) {
      toastMessage(
        response,
        toast,
        0,
        holderNumber,
        dispatch,
        selectorState,
        false,
        false,
      );
    } else {
      if (!response?.additionalDetails) {
        toast({
          title: Strings.success,
          description: Strings.success,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        dispatch({
          type: IS_SUBMITTED_PAGE_VISIBLE,
          payload: {
            ...isSubmittedPageVisible,
            [holderNumber]: true,
          },
        });
        return;
      }
      toast({
        title: Strings.failed,
        description: response.additionalDetails,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const onSubmit = async (data: any) => {
    const accountId = accountIdManager?.getAccountId();
    if (
      isDocumentsInvalidated(
        data.bankAccountRejectionReason,
        data.panRejectionReason,
      )
    ) {
      if (!bankDetailsData) {
        const addedBasicDetailsResponse = await addBankDetails(
          accountId,
          holderId[holderNumber],
          data,
        );
        if (addedBasicDetailsResponse?.additionalDetails) {
          toast({
            title: Strings.failed,
            description: addedBasicDetailsResponse?.additionalDetails,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        if (isHuf || isIndividual || isJoint) {
          bankBtnAction(addedBasicDetailsResponse);
        } else {
          toastMessage(
            addedBasicDetailsResponse,
            toast,
            tabValue[holderNumber] + 1,
            holderNumber,
            dispatch,
            selectorState,
            kycStatusForHolder[holderNumber] === KycStatus.KycInvalidated
              ? true
              : false,
            kycStatusForAccount === KycStatus.KycInvalidated ? true : false,
          );
        }
      }
      if (bankDetailsData) {
        const updatedBasicDetailsResponse = await updateBankDetails(
          accountId,
          holderId[holderNumber],
          data,
        );
        if (updatedBasicDetailsResponse?.additionalDetails) {
          toast({
            title: Strings.failed,
            description: updatedBasicDetailsResponse?.additionalDetails,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        if (isHuf || isIndividual || isJoint) {
          bankBtnAction(updatedBasicDetailsResponse);
        } else {
          toastMessage(
            updatedBasicDetailsResponse,
            toast,
            tabValue[holderNumber] + 1,
            holderNumber,
            dispatch,
            selectorState,
            kycStatusForHolder[holderNumber] === KycStatus.KycInvalidated
              ? true
              : false,
            kycStatusForAccount === KycStatus.KycInvalidated ? true : false,
          );
        }
      }
    }
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      validationSchema={AddBankDetailsSchema}
      enableReinitialize
    >
      {({
        handleChange,
        values,
        errors,
        setFieldTouched,
        touched,
        setFieldValue,
      }) => (
        <Form>
          {!(isIndividual || isJoint) && (
            <Flex mb={4}>
              <Flex
                flex={1}
                direction={'column'}
                w={'100%'}
                className={styles['datePicker_wrapper']}
                alignItems={'flex-end'}
                pr={6}
              >
                <DatePickerField
                  name="dateOfIncorporation"
                  placeholder="*Date Of Incorporation"
                  value={values.dateOfIncorporation}
                  setDate={setDate}
                  onChange={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  isDisable={isAllFieldsDisable[holderNumber]}
                />
                {errors.dateOfIncorporation && touched.dateOfIncorporation && (
                  <Flex className={styles['errorRegMessage']} w={'100%'}>
                    This is Required
                  </Flex>
                )}
              </Flex>
              <CustomSelectDropdown
                testId='select-company-residential-status'
                paddingRight={0}
                placeholder={`*${frontPanNameText}Residential Status`}
                values={[...Object.values(ResidentialStatusEnum)]}
                setSelectedDropdownValue={handleChange('residentialStatus')}
                handleChangeName={'residentialStatus'}
                value={values.residentialStatus}
                errors={errors.residentialStatus}
                touched={touched.residentialStatus}
                setFieldTouched={setFieldTouched}
                holderNumber={holderNumber}
              />
            </Flex>
          )}
          <Flex flexDirection={'column'} w={'100%'} flex={1}>
            <CustomPanCardTile
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              valuePanNumber={values.panNumber}
              errorPanNumber={errors.panNumber}
              touchedPanNumber={touched.panNumber}
              valuePanName={values.panHolderName}
              errorPanName={errors.panHolderName}
              touchedPanName={touched.panHolderName}
              frontPlaceHolderText={frontPanNameText}
              holderNumber={holderNumber}
            />
            <FileUpload
              text={`*Please upload PAN Card`}
              name="panIdProofUrl"
              nameType="panIdProofType"
              onChange={setFieldValue}
              values={values.panIdProofUrl}
              errors={errors.panIdProofUrl}
              valuesType={values.panIdProofType}
              rejectedReasonName={'panRejectionReason'}
              rejectionReason={values.panRejectionReason}
              holderNumber={holderNumber}
              setFieldTouched={setFieldTouched}
              touched={touched.panIdProofUrl}
              testId='bank-details'
            />
            {isHuf && (
              <Text mt={8} color="#535353">
                *Consent from Co-Parceners
                <Checkbox
                  data-test="label-click-on-checkox"
                  ml={4}
                  mt={1}
                  disabled={isAllFieldsDisable[holderNumber]}
                  onChange={(e) =>
                    setFieldValue('consentFromCoParceners', e.target.checked)
                  }
                  isChecked={values.consentFromCoParceners}
                />
              </Text>
            )}
            <Grid
              templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
              rowGap={2}
              mt={6}
            >
              <Flex flex={1} pr={{ base: 0, md: 6 }} direction={'column'}>
                <Select
                  data-test="select-Bank"
                  width={'100%'}
                  fontSize={'16px'}
                  height={'45px'}
                  borderColor={Colors.buttonBorderGrey}
                  value={values.bankName}
                  onChange={(e) => {
                    setFieldValue('bankName', e.target.value);
                  }}
                  placeholder={'*Select Bank'}
                  color={
                    values.bankName ? Colors.blackColor : Colors.textGreyColor
                  }
                  onBlur={() => setFieldTouched('bankName')}
                  disabled={isAllFieldsDisable[holderNumber]}
                  _disabled={{ color: 'black' }}
                  variant="flushed"
                >
                  {Array.isArray(bankData) &&
                    bankData?.map((value: any) => (
                      <option
                        key={value.id}
                        value={capitalizeFirstLetter(value.name)}
                        style={{ color: Colors.blackColor }}
                      >
                        {capitalizeFirstLetter(value.name)}
                      </option>
                    ))}
                </Select>
                {errors.bankName && touched.bankName && (
                  <div className={styles['errorRegMessage']}>
                    This is Required
                  </div>
                )}
              </Flex>
              {values.bankName === 'Others' && (
                <InputTile
                  placeholder="*Select Bank"
                  paddingRight={6}
                  handleChangeName={'bankNameOther'}
                  setFieldValue={setFieldValue}
                  value={values.bankNameOther}
                  errors={errors.bankNameOther}
                  setFieldTouched={setFieldTouched}
                  touched={touched.bankNameOther}
                  holderNumber={holderNumber}
                />
              )}
              <CustomSelectDropdown
                testId='select-account-type'
                paddingRight={values.bankName === 'Others' ? 0 : 6}
                placeholder={'*Account Type'}
                values={[...Object.values(BankAccountTypeEnum)]}
                setSelectedDropdownValue={handleChange('bankAccountType')}
                handleChangeName={'bankAccountType'}
                value={values.bankAccountType}
                errors={errors.bankAccountType}
                touched={touched.bankAccountType}
                setFieldTouched={setFieldTouched}
                holderNumber={holderNumber}
              />
              <InputTile
                testId="input-account-number"
                placeholder="*Account Number"
                paddingRight={values.bankName === 'Others' ? 6 : 0}
                handleChangeName={'bankAccountNumber'}
                setFieldValue={setFieldValue}
                value={values.bankAccountNumber}
                errors={errors.bankAccountNumber}
                setFieldTouched={setFieldTouched}
                touched={touched.bankAccountNumber}
                holderNumber={holderNumber}
              />
              <InputTile
                testId="input-IFSC-code"
                placeholder="*IFSC Code"
                paddingRight={6}
                handleChangeName={'ifscCode'}
                setFieldValue={setFieldValue}
                value={values.ifscCode}
                errors={errors.ifscCode}
                setFieldTouched={setFieldTouched}
                touched={touched.ifscCode}
                holderNumber={holderNumber}
                isAllLettersCapital={true}
              />
            </Grid>
            <FileUpload
              text="*Please upload Cancelled cheque/Bank statement"
              name="bankAccountProofUrl"
              nameType="bankAccountProofType"
              onChange={setFieldValue}
              values={values.bankAccountProofUrl}
              errors={errors.bankAccountProofUrl}
              valuesType={values.bankAccountProofType}
              rejectionReason={values.bankAccountRejectionReason}
              rejectedReasonName={'bankAccountRejectionReason'}
              holderNumber={holderNumber}
              setFieldTouched={setFieldTouched}
              touched={touched.bankAccountProofUrl}
            />
            <CustomPageBottom holderNumber={holderNumber} />
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default BankDetails;
