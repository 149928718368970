import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { Environment, InvestmentStage, Strings } from '@hBits.Core';
import CustomButton from 'apps/customer-portal-web/src/components/CustomButton';
import { updateTopAmount } from 'apps/customer-portal-web/src/services/propertyInvestment/eoiStepServices';
import {
  setInvestmentDetails,
  setLoader,
} from 'apps/customer-portal-web/src/store/actions';
import colors from 'apps/customer-portal-web/src/theme/colors';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PaymentRecid from '../../PaymentRecid';
interface EoiStep3Props {
  investmentModal: any;
}

const EOIStep3: FC<EoiStep3Props> = ({ investmentModal }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  let location = useLocation();
  const addTopUpAmount = async () => {
    dispatch(setLoader(true));
    const topUpAmount = await updateTopAmount(
      investmentModal?.id,
      investmentModal?.minimumTopUpSizeApplied,
      investmentModal,
      (error: string) => {
        toast({
          title: 'Error',
          description: error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    );
    await dispatch(setInvestmentDetails(topUpAmount));
    dispatch(setLoader(false));
  };
  const removePgRedirect = () => {
    const customerProtalWeb = Environment.getInstance().getParam(
      'NEXT_PUBLIC_CUSTOMER_PORTAL_HOSTING_ROOT',
    );
    window.location.assign(customerProtalWeb + location.pathname);
  };

  const { investmentAmountEoi, topUpEoiModels, investmentStage } =
    investmentModal;

  return (
    <>
      {location.search.includes('?transactionStatus') ? (
        <>
          <PaymentRecid
            removePgRedirect={removePgRedirect}
            amount={
              investmentStage === InvestmentStage.TopUpEOI
                ? topUpEoiModels &&
                  topUpEoiModels[topUpEoiModels.length - 1]
                    ?.eoiInvestmentAmontPaid
                : investmentAmountEoi?.investmentAmountToBePaidAtEOI
            }
            recidUrl={
              investmentStage === InvestmentStage.TopUpEOI
                ? topUpEoiModels &&
                  topUpEoiModels[topUpEoiModels.length - 1]
                    ?.investmentAmountPaidAtEOIRecieptUrl
                : investmentAmountEoi?.investmentAmountPaidAtEOIRecieptUrl
            }
            isSucess={true}
          />
        </>
      ) : (
        <Flex
          width={['100%', '100%', '80%', '80%']}
          flexDirection={'column'}
          pl={[0, 0, 3, 3]}
          mt={[3, 3, 0, 0]}
        >
          <Flex borderBottomWidth={'1px'} borderBottomColor={colors.grey100}>
            <Text
              fontSize="16px"
              className="min-payment-text"
              mt={3}
              mb={'10px'}
              ml={'5px'}
            >
              {Strings.eoiCompleted}
            </Text>
          </Flex>

          <Text
            fontSize="14px"
            className="min-payment-text"
            mt={3}
            color={'#535353'}
          >
            {Strings.eoiCompletionMessage}
          </Text>
          <Text
            fontSize="14px"
            className="min-payment-text"
            mt={3}
            color={'#535353'}
          >
            {Strings.eoiCompletionUpdateOverMailMessage}
          </Text>
          <Box mt={10}>
            <CustomButton
              btnPressed={() => addTopUpAmount()}
              btnText="ADD INVESTMENT AMOUNT"
              pl={'20px'}
              pr={'20px'}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default EOIStep3;
