import * as React from 'react';
import { Environment } from '@hBits.Core';
import { Link } from '@chakra-ui/react';

function capitalizeFirstLetter(string = '') {
  return string
    ?.toLowerCase()
    ?.split(' ')
    ?.map(function (word) {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1);
    })
    ?.join(' ');
}

const breadcrumbs = () => {
  let path = [''];
  if (typeof window !== 'undefined') {
    path = window.location.pathname.split(/[/]/).slice(1);
  }

  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  const convertBreadcrumb = (value: string) => {
    return value === 'faq'
      ? value
          ?.replace(/[/-]/g, (match) => {
            if (match === '-') {
              return ' ';
            } else return match;
          })
          .toUpperCase()
      : capitalizeFirstLetter(
          value
            ?.replace('testimonials-home', 'testimonials ')
            ?.replace(/[/-]/g, (match) => {
              if (match === '-') {
                return ' ';
              } else return match;
            }),
        );
  };

  const getBreadcrumb = (name: string, idx: number) => {
    const str = convertBreadcrumb(name);
    if (idx + 1 < path.length) {
      return str + ' -> ';
    } else return str;
  };

  const gethref = (arr: any, idx: number) => {
    let redirectUrl = '';
    for (let i = 0; i <= idx; i++) {
      redirectUrl += arr[i];
      redirectUrl += '/';
    }
    return `${landingPagesHostingRoot}/${redirectUrl}`;
  };

  return (
    <div className="breadcrumbs">
      <Link href={landingPagesHostingRoot} style={{ color: 'white' }}>
        Homepage -&gt;&nbsp;
      </Link>
      {path.map((name, idx) => (
        <Link href={gethref(path, idx)} style={{ color: '#ffb657' }}>
          {getBreadcrumb(name, idx)}
        </Link>
      ))}
    </div>
  );
};

export default breadcrumbs;
