export const customFrameUrl = `https://sign.zoho.in/zsguest?locale=en&sign_id=234b4d535f4956237fb3524afd9870614c9baca4b2fd44a13cfe6704e9d025d8634902441326f6d6ad8cce3e3fd59112bd52ce4cbc0242f644d889b8dac17d9eb3809f4ec1491d44b3001145194501a2a53ac2dff885bcb9cb7b665612f78fa1c2efa098779cd0d7c51c8e6765764d23f7b4442aa03a0c52de13366f859ebca973e3471b25b5ddba&frameorigin=https://7184-183-82-112-34.in.ngrok.io`;

export const getCurrentDate = (): Date => {
  return new Date();
};

export const getAge = (birthDate: Date): number => {
  const today = getCurrentDate();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const convertDate = (d: Date): string => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const nth = (day: number): string => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };
  return `${d.getDate()}${nth(d.getDate())} ${
    monthNames[d.getMonth()]
  } ${d.getFullYear()}`;
};

export function isEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}
export function capitalizeFirstLetter(string = '') {
  return string
    ?.toLowerCase()
    ?.split(' ')
    ?.map(function (word) {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1);
    })
    ?.join(' ');
}
export function capitalizeEachLetter(string = '') {
  return string?.toUpperCase();
}

export const API_REQ_STATUS = {
  INIT: 'init',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const camelCaseToTitle = (text: String): String => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const numberInIndianFormat = (number: string): String => {
  const num = +number;
  const formattedNumber = num.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR',
  });
  return formattedNumber;
};
