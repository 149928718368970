import {
  Button,
  Flex,
  ListItem,
  OrderedList,
  Select,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { Colors } from 'apps/customer-portal-web/src/theme';
import styles from '../mainsection.module.scss';
import BarChart from '../../Charts/BarChart/BarChart';
import Styles from './analaytics.module.scss';
import { analyticsTableHeads, analyticsTableHeadsValues } from '../../mock';
import { AccountManager, Strings } from '@hBits.Core';
import { useEffect, useMemo, useState } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'apps/customer-portal-web/src/store/hooks';
import { getAllProperties } from 'apps/customer-portal-web/src/store/actions/investment.actions';
import { fetchUserEarnings } from 'apps/customer-portal-web/src/services/investment-dashboard/Analytics';
import {
  getCurrentDate,
  monthNames,
  numberInIndianFormat,
} from 'apps/customer-portal-web/src/utils';

export interface GraphDataType {
  name: string;
  data: string[];
}

const Analaytics = () => {
  const dispatch = useAppDispatch();
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>();
  const today = getCurrentDate();
  const [selectedChartOption, setSelectedChartOption] = useState<any>(
    today.getFullYear(),
  );

  const [earningStats, setEarningStats] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<any>(0);
  const [months, setMonths] = useState<any>([]);
  const [statsForSelectedMonth, setStatsForSelectedMonth] = useState<any[]>([]);
  const [graphData, setGraphData] = useState<GraphDataType[]>([
    { name: 'inflow', data: [] },
    { name: 'outflow', data: [] },
  ]);
  const [graphCategories, setGraphCategories] = useState<string[]>([
    'jan',
    'feb',
  ]);

  const accountId = AccountManager.getAccountId();

  const properties = useAppSelector(
    (state) => state.InvestmentReducer.properties,
  );

  useEffect(() => {
    if (properties?.length === 0) {
      dispatch(getAllProperties(accountId));
    }
  }, []);

  useEffect(() => {
    if (!selectedPropertyId && properties.length > 0) {
      setSelectedPropertyId(properties[0].propertyId);
    }
  }, [properties]);

  const selectedProperty = useMemo(
    () => properties.find((p: any) => p.propertyId === selectedPropertyId),
    [selectedPropertyId],
  );

  useEffect(() => {
    if (selectedPropertyId && selectedChartOption) {
      const fromMonth = 1;
      const toMonth = 12;
      const fromYear: any = selectedChartOption;
      const toYear: any = selectedChartOption;
      fetchUserEarnings(
        accountId,
        selectedPropertyId,
        fromMonth,
        toMonth,
        fromYear,
        toYear,
      ).then((res) => {
        res.earnings && setEarningStats(res?.earnings);
      });
    }
  }, [selectedChartOption, selectedPropertyId]);

  const generateStatsData = (month: number) => {
    const stats = earningStats[month];
    const analyticsTableHeadsValues = [
      {
        particulars: 'Rental Income',
        grossInflow: '+' + numberInIndianFormat(stats?.rentalIncome),
        outFlow: '',
        tds: '',
        monthlyTotal: '+' + numberInIndianFormat(stats?.rentalIncome),
      },
      {
        particulars: 'Rent Top-up',
        grossInflow: '+' + numberInIndianFormat(stats?.rentTopUp),
        outFlow: '',
        tds: '',
        monthlyTotal: '+' + numberInIndianFormat(stats?.rentTopUp),
      },
      {
        particulars: 'Income on SD',
        grossInflow: '+' + numberInIndianFormat(stats?.incomeOnSD),
        outFlow: '',
        tds: '',
        monthlyTotal: '+' + numberInIndianFormat(stats?.incomeOnSD),
      },
      {
        particulars: 'Property Tax',
        grossInflow: '',
        outFlow: stats?.propertyTax.startsWith('-')
          ? numberInIndianFormat(stats?.propertyTax)
          : '-' + numberInIndianFormat(stats?.propertyTax),
        tds: '',
        monthlyTotal: stats?.propertyTax.startsWith('-')
          ? numberInIndianFormat(stats?.propertyTax)
          : '-' + numberInIndianFormat(stats?.propertyTax),
      },
      {
        particulars: 'Management Fees',
        grossInflow: '',
        outFlow: stats?.managementFees.startsWith('-')
          ? numberInIndianFormat(stats?.managementFees)
          : '-' + numberInIndianFormat(stats?.managementFees),
        tds: '',
        monthlyTotal: stats?.managementFees.startsWith('-')
          ? numberInIndianFormat(stats?.managementFees)
          : '-' + numberInIndianFormat(stats?.managementFees),
      },
      {
        particulars: 'Others',
        grossInflow: '',
        outFlow: stats?.otherCharges.startsWith('-')
          ? numberInIndianFormat(stats?.otherCharges)
          : '-' + numberInIndianFormat(stats?.otherCharges),
        tds: '',
        monthlyTotal: stats?.otherCharges.startsWith('-')
          ? numberInIndianFormat(stats?.otherCharges)
          : '-' + numberInIndianFormat(stats?.otherCharges),
      },
      {
        particulars: 'TDS',
        grossInflow: '',
        outFlow: stats?.tds.startsWith('-')
          ? numberInIndianFormat(stats?.tds)
          : '+' + numberInIndianFormat(stats?.tds),
        tds: '',
        monthlyTotal: stats?.tds.startsWith('-')
          ? numberInIndianFormat(stats?.tds)
          : '+' + numberInIndianFormat(stats?.tds),
      },
      {
        particulars: 'TDS on Rental Income',
        grossInflow: '',
        outFlow: stats?.tdsOnRentIncome.startsWith('-')
          ? numberInIndianFormat(stats?.tdsOnRentIncome)
          : '-' + numberInIndianFormat(stats?.tdsOnRentIncome),
        tds: stats?.tdsOnRentIncome.startsWith('-')
          ? numberInIndianFormat(stats?.tdsOnRentIncome).replace('-', '+')
          : '+' + numberInIndianFormat(stats?.tdsOnRentIncome),
        monthlyTotal: '₹0.00',
      },
      {
        particulars: 'TDS on Rent Top Up',
        grossInflow: '',
        outFlow: stats?.tdsRentTopup.startsWith('-')
          ? numberInIndianFormat(stats?.tdsRentTopup)
          : '-' + numberInIndianFormat(stats?.tdsRentTopup),
        tds: stats?.tdsRentTopup.startsWith('-')
          ? numberInIndianFormat(stats?.tdsRentTopup).replace('-', '+')
          : '+' + numberInIndianFormat(stats?.tdsRentTopup),
        monthlyTotal: '₹0.00',
      },
      {
        particulars: 'TDS on Interest on SD',
        grossInflow: '',
        outFlow: stats?.tdsInterestOnSD.startsWith('-')
          ? numberInIndianFormat(stats?.tdsInterestOnSD)
          : '-' + numberInIndianFormat(stats?.tdsInterestOnSD),
        tds: stats?.tdsInterestOnSD.startsWith('-')
          ? numberInIndianFormat(stats?.tdsInterestOnSD).replace('-', '+')
          : '+' + numberInIndianFormat(stats?.tdsInterestOnSD),
        monthlyTotal: '₹0.00',
      },
      {
        particulars: 'Total',
        grossInflow: '+₹' + stats?.totalGrossInflow,
        outFlow: stats?.totalGrossOutflow.startsWith('-')
          ? numberInIndianFormat(stats?.totalGrossOutflow)
          : '-' + numberInIndianFormat(stats?.totalGrossOutflow),
        tds: stats?.totalTdsFundedByCo.startsWith('-')
          ? numberInIndianFormat(stats?.totalTdsFundedByCo)
          : '+' + numberInIndianFormat(stats?.totalTdsFundedByCo),
        monthlyTotal: stats?.monthTotal.startsWith('-')
          ? numberInIndianFormat(stats?.monthTotal)
          : '+' + numberInIndianFormat(stats?.monthTotal),
      },
    ];
    return analyticsTableHeadsValues;
  };

  const generateGraphData = () => {
    const data: GraphDataType[] = [
      { name: 'inflow', data: [] },
      { name: 'outflow', data: [] },
    ];
    const categories: string[] = [];
    earningStats.map((stat: any) => {
      data[0].data.push(stat?.totalGrossInflow.replace('-', ''));
      data[1].data.push(stat?.totalGrossOutflow.replace('-', ''));
      categories.push(monthNames[stat.month - 1]);
    });
    setGraphData(data);
    setGraphCategories(categories);
  };

  useEffect(() => {
    earningStats?.length && setSelectedMonth(earningStats.length - 1);
    const minMonths = earningStats[0]?.month;
    const maxMonths =
      earningStats?.length > 1
        ? earningStats[earningStats?.length - 1]?.month
        : minMonths;
    const newMonths = [];
    var genValue = 0;
    for (let itr = 1; itr <= monthNames.length; itr++) {
      if (itr >= minMonths && itr <= maxMonths) {
        newMonths.push({ name: monthNames[itr - 1], value: genValue });
        genValue++;
      }
    }
    setMonths(newMonths);
    generateGraphData();
    earningStats?.length &&
      statsForSelectedMonth?.length === 0 &&
      setStatsForSelectedMonth(generateStatsData(selectedMonth));
  }, [earningStats]);

  useEffect(() => {
    earningStats?.length &&
      setStatsForSelectedMonth(generateStatsData(selectedMonth));
  }, [selectedMonth]);

  const getBarChartYears = () => {
    const today = getCurrentDate();
    const curretYear = today.getFullYear();
    let graphYear = [];
    for (let i = curretYear; i >= 2019; i--) {
      graphYear.push(i);
    }
    return graphYear || [];
  };

  return (
    <Flex
      flex={1}
      ps={[0, 5]}
      flexDirection={'column'}
      mb={'100'}
      mr={[0, 5]}
      w={'100%'}
    >
      {properties?.length > 1 && (
        <Select
          w={['sm']}
          bg={Colors.white}
          borderRadius={5}
          mb={6}
          onChange={(event) => setSelectedPropertyId(event.target.value)}
        >
          {properties.map((p: any) => (
            <option value={p.propertyId} key={p.propertyId}>
              {p?.propertyDetails?.displayName}
            </option>
          ))}
        </Select>
      )}
      <Flex
        w={'100%'}
        mt={5}
        className={styles['box-shadow-contsiner']}
        borderRadius={5}
        px={5}
        py={5}
        bg={Colors.white}
        flexDirection={'column'}
        position={'relative'}
      >
        <BarChart
          data={graphData}
          categories={graphCategories}
          title={selectedProperty?.propertyDetails?.displayName}
        />
        <Select
          position={'absolute'}
          right={5}
          top={5}
          width={['18%']}
          onChange={(event) => setSelectedChartOption(event.target.value)}
          value={selectedChartOption}
        >
          {getBarChartYears().map((item) => (
            <option key={item} value={`${item}`}>
              {`Year ${item}`}
            </option>
          ))}
        </Select>
      </Flex>
      {earningStats?.length !== 0 && (
        <Flex
          flexDirection={'column'}
          borderColor={'#bebfc2'}
          borderWidth={1}
          mt={10}
          w="100%"
        >
          <Flex
            justifyContent={'space-between'}
            bg={Colors.grey300}
            flex={1}
            flexDirection={'row'}
            p={3}
            w="100%"
          >
            <Flex>
              <Text color={Colors.headerColor} fontSize={'xl'} as="b">
                Price Breakup
              </Text>
            </Flex>
            <Flex>
              <Flex>
                <Menu>
                  <MenuButton as={Button}>
                    {`${monthNames[earningStats[selectedMonth]?.month - 1]}`}
                  </MenuButton>
                  <MenuList>
                    {months.map((item: any, itr: number) => (
                      <MenuItem
                        key={item.value}
                        value={itr}
                        onClick={() => {
                          setSelectedMonth(itr);
                        }}
                      >
                        {`${item.name}`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
              <Flex>
                <Select
                  onChange={(event) =>
                    setSelectedChartOption(event.target.value)
                  }
                  value={selectedChartOption}
                  style={{ fontWeight: 'bold' }}
                >
                  {getBarChartYears().map((item) => (
                    <option key={item} value={`${item}`}>
                      {`${item}`}
                    </option>
                  ))}
                </Select>
              </Flex>
            </Flex>
          </Flex>
          <Flex bg={Colors.white} flexDirection="column" w="100%">
            <Flex
              flex={1}
              borderBottomColor={'#bebfc2'}
              borderBottomWidth={1}
              pt={2}
              w="100%"
            >
              {analyticsTableHeads.map((item, idx) => (
                <Flex
                  flex={1}
                  py={2}
                  pr={5}
                  w={'100%'}
                  wordBreak={['break-all', 'keep-all']}
                  fontSize={['10px', 'inherit']}
                  justifyContent={idx === 4 ? 'flex-end' : 'start'}
                  key={idx}
                >
                  <Text
                    textAlign={'center'}
                    color={Colors.blackColor}
                    as="b"
                    ml={idx == 0 ? 3 : 0}
                  >
                    {item}
                  </Text>
                </Flex>
              ))}
            </Flex>
            <Flex flex={1} flexDirection="column" w="100%">
              {statsForSelectedMonth.map((item, idxs) => (
                <Flex
                  flex={1}
                  py={2}
                  pr={5}
                  borderBottomStyle={'dashed'}
                  borderBottomWidth={1}
                  bg={
                    idxs === analyticsTableHeadsValues.length - 1
                      ? Colors.grey400
                      : Colors.white
                  }
                  key={idxs}
                >
                  {Object.keys(item).map((keys, idx) => (
                    <Flex
                      flex={idx === 0 ? 1 : 1}
                      py={2}
                      justifyContent={idx === 4 ? 'flex-end' : 'start'}
                      key={idx}
                    >
                      <Text
                        color={Colors.grey600}
                        wordBreak={['break-all', 'keep-all']}
                        fontSize={['0.5rem', '14px']}
                        className={
                          idx === 0
                            ? Styles['font-size-1']
                            : Styles['bold-text']
                        }
                        ml={idx === 0 ? 3 : 0}
                      >
                        {item[keys]}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              ))}
            </Flex>
            <Flex justifyContent={['space-between']} flex={1} p={3} w="100%">
              <Flex>
                <Text color={Colors.headerColor} fontSize={'xl'} as="b">
                  Net Amount Distributed
                </Text>
              </Flex>
              <Flex>
                <Text color={Colors.headerColor} fontSize={'xl'} as="b">
                  {statsForSelectedMonth?.at(-1)?.monthlyTotal}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex mt={12} flexDirection="column">
        <Text fontSize={'2xl'} as="b" color={Colors.headerColor} mt={5} mb={2}>
          {Strings.taxesRate}
        </Text>
        <OrderedList>
          <ListItem>
            <Text mb={2}>{Strings.taxesRate1}</Text>
          </ListItem>
          <ListItem>
            <Text mb={2}>{Strings.taxesRate2}</Text>
          </ListItem>
          <ListItem>
            <Text mb={2}>{Strings.taxesRate3}</Text>
          </ListItem>
        </OrderedList>
      </Flex>
    </Flex>
  );
};

export default Analaytics;
