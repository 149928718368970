import Link from 'next/link';
import * as React from 'react';

interface linkWrapperProps {
  urlLink?: any;
  text?: string;
  classNameText?: any;
  onClick?: () => void;
  keyText?: any;
}

export function LinkWrapper(props: linkWrapperProps) {
  const [urlParams, setUrlParams] = React.useState<any>();
  React.useEffect(() => {
    if (window !== undefined) {
      setUrlParams(sessionStorage.getItem('utmParams'));
    }
  });
  const { urlLink, text, classNameText, onClick, keyText } = props;
  return (
    <a
      href={`${urlLink}${urlParams ?? ''}`}
      key={keyText}
      className={classNameText}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {text}
    </a>
  );
}

export function FooterLinkWrapper(props: linkWrapperProps) {
  const [urlParams, setUrlParams] = React.useState<any>();
  React.useEffect(() => {
    if (window !== undefined) {
      setUrlParams(sessionStorage.getItem('utmParams'));
    }
  });
  const { urlLink, text } = props;
  return (
    <a href={`${urlLink}${urlParams ?? ''}`}>{text}</a>
  );
}
