import invalidated from './../resources/invaidated.jpeg'
import '../kyc.scss';
interface kycStatusInvalidated {
  className: string
  text: string
}
const KycStatusInvalidated = (props: kycStatusInvalidated) => {
  const { className, text } = props;
  return (
    <div className={className}>
      <div className='kyc_Invalidated'>
        <img src={invalidated} className="kyc_green-tick-for-verified"></img>{' '}&nbsp;&nbsp;
        <h6 className="kyc_h6-tag-for-kyc-status-page-verified">
          KYC has been Invalidated {text}
        </h6>
      </div>
    </div>
  );
}

export default KycStatusInvalidated;