export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';
export const SET_ERROR = 'SET_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';
export const RESET_APP_REDUCER = 'RESET_APP_REDUCER';
export const PROPERTY_INVESTMENT_MODAL = 'PROPERTY_INVESTMENT_MODAL';
export const SET_LOADER = 'SET_LOADER';
export const INVESTMENT_USER = 'INVESTMENT_USER';
export const UPDATE_ALL_PROPERTIES = 'UPDATE_ALL_PROPERTIES';
export const SET_INVESTMENT_PROPERTY_DETAILS =
  'SET_INVESTMENT_PROPERTY_DETAILS';
export const UPDATE_GET_ALL_PROPERTIES_STATUS =
  'UPDATE_GET_ALL_PROPERTIES_STATUS';
export const DOWNLOAD_FILE_URL = 'DOWNLOAD_FILE_URL';
export const FIRST_DASHBOARD = 'FIRST_DASHBOARD';
export const INVESTMENT_DOCUMENTS = 'INVESTMENT_DOCUMENTS';
export const HOLDER_ID = 'HOLDER_ID';
export const IS_NRI = 'IS_NRI';
export const TAB_VALUE = 'TAB_VALUE';
export const IS_DISPLAY_FOR_VERIICATION_IN_PROGRESS =
  'IS_DISPLAY_FOR_VERIICATION_IN_PROGRESS';
export const KYC_STATUS_FOR_ACCOUNT = 'KYC_STATUS_FOR_ACCOUNT';
export const KYC_STATUS_FOR_HOLDER = 'KYC_STATUS_FOR_HOLDER';
export const KYC_DATA = 'KYC_DATA';
export const IS_GET_KYC_DATA = 'IS_GET_KYC_DATA';
export const IS_SUBMITTED_PAGE_VISIBLE = 'IS_SUBMITTED_PAGE_VISIBLE';
export const IS_ALL_FIELDS_DISABLED = 'IS_ALL_FIELDS_DISABLED';
export const ADDRESS_RECORD_NUMBER = 'ADDRESS_RECORD_NUMBER';
export const IS_USER_TYPE_API = 'IS_USER_TYPE_API';
export const ADDRESS_HOLDER_NUMBER = 'ADDRESS_HOLDER_NUMBER';
export const ADDRESS_PROOF_HOLDER_NUMBER = 'ADDRESS_PROOF_HOLDER_NUMBER';
export const ADDRESS_PERMANENT_HOLDER_NUMBER =
  'ADDRESS_PERMANENT_HOLDER_NUMBER';
export const ADDRESS_REPRESENTATIVE_HOLDER_NUMBER =
  'ADDRESS_REPRESENTATIVE_HOLDER_NUMBER';
export const ADDRESS_REPRESENTATIVE_PROOF_HOLDER_NUMBER =
  'ADDRESS_REPRESENTATIVE_PROOF_HOLDER_NUMBER';
export const ADDRESS_REPRESENTATIVE_PERMANENT_HOLDER_NUMBER =
  'ADDRESS_REPRESENTATIVE_PERMANENT_HOLDER_NUMBER';
