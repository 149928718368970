import { fetchAccountStatement } from 'apps/customer-portal-web/src/services/dashboard/client';
import { useEffect, useState } from 'react';

const StatementOfAccount = () => {
  const [link, setLink] = useState('');

  useEffect(() => {
    const geStatementLink = async () => {
      const res = await fetchAccountStatement();
      setLink(res.data);
    };
    geStatementLink();
  }, []);

  return (
    <div className="flex w-full">
      <div className="w-full ">
        <iframe src={link} title="statement" className="h-[80vh] w-full" />
      </div>
    </div>
  );
};

export default StatementOfAccount;
