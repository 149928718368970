//  eslint-disable no-undef
/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-ignore: Object is possibly 'null'.
import { Environment, Strings, UserType, clearAccessToken } from '@hBits.Core';
import { isWebView } from '@hbits.lib/util';
import moneyControlLogo from 'libs/src/assets/svg/MC_Logo.svg';
import downArrowOrange from 'libs/src/assets/svg/down-arrow-orange.svg';
import downArrow from 'libs/src/assets/svg/down-arrow-white.svg';
import hbitLogo from 'libs/src/assets/svg/hbit-logo.svg';
import LoginIcon from 'libs/src/assets/svg/login-icon.svg';
import MenuIcon from 'libs/src/assets/svg/menu-icon.svg';
import PhoneCall from 'libs/src/assets/svg/phone-call.svg';
import rightArrow from 'libs/src/assets/svg/right-arrow.svg';
import UserAccount from 'libs/src/assets/svg/user-account.svg';
import { AccountManager, authTokenStorageProvider } from 'libs/src/auth';
import accountManager from 'libs/src/auth/account-manager';
import useAuth from 'libs/src/hooks/useAuth';
import { setupInterceptors } from 'libs/src/services/NetworkHandler';
import { fetchInvestmentStats } from 'libs/src/services/modules/investment/index';
import { getLinkedAccount } from 'libs/src/services/modules/linkedAccounts/getLinkedAccount';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { eventEmitter } from '../../../../apps/landing-pages-web/src/components/events';
import Breadcrumbs from '../Breadcrumbs';
import ChannelPartnerTncModal from '../ChannelPartnerTncModal';
import { ClarityTracking } from '../ClarityTracking';
import { LinkWrapper } from '../LinkWrapper';
import RegistrationModal from '../RegistrationModal';
import UnAuthorizedUserModal from '../UnAuthorizedUserModal';
import './components.header.module.scss';
import { cpResources, resources } from './header.resources';

setupInterceptors();

const LoginModal = dynamic(() => import('../LoginModal'), {
  suspense: false,
});

const LoginOtpModal = dynamic(() => import('../LoginOtpModal'), {
  suspense: false,
});

const RegistrationOtpModal = dynamic(() => import('../RegistrationOtpModal'), {
  suspense: false,
});

const TncModal = dynamic(() => import('../TncModal'), {
  suspense: false,
});

const CompletionModal = dynamic(() => import('../CompletionModal'), {
  suspense: false,
});

const NomineeModal = dynamic(() => import('../NomineeModal'), {
  suspense: false,
});

const ShowHeaderTransparentInScreens = resources
  .filter((resource) => resource.isTransparent)
  .map((obj) => obj.url)
  .concat([
    '/',
    '/channel-partner',
    '/sitemap',
    '/careers',
    '/referral',
    '/utm',
    '/thankyou',
    '/not-found',
    '/fractional-ownership-investment',
    '/commercial-real-estate-investment',
    '/preleased-property',
  ]);

interface headerProps {
  isbreadCrumbVisible?: boolean;
  env?: any;
}

export const Header = (props: headerProps) => {
  const { isbreadCrumbVisible = false } = props;
  const initialRef: any = null;
  const headerRef = useRef(initialRef);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [navbar, setNavbar] = useState(true);
  const [activeClass, setActiveClass] = useState('');
  const [headerTransparent, setHeaderTransparent] = useState(true);
  const [isHeaderOptionDropdown, setIsHeaderOptionDropdown] =
    useState<boolean>(false);
  const {
    preLoginFlag,
    userTypeOps,
    userTypeCP,
    setLinkedAccountFlag,
    username,
    tncModalState,
    registrationModalState,
    preRegFlag,
    registrationData,
    hideRegistrationModal,
    onLoginRegisterClick,
    onLoginClick,
    openTnCModal,
    linkedAccountFlag,
    registrationOTPModalState,
    onRegisterModalClose,
    onChangeMobileClick,
    openCompletionModal,
    loginModalState,
    loginData,
    hideLoginModal,
    onRegisterClick,
    openLoginOTPModal,
    onLoginCompletion,
    loginOTPModalState,
    onTnCClick,
    onLoginOtpModalClose,
    onOpenRegistrationModalClose,
    checkIfAuthTokenIsValid,
    logoutUser,
    setTnCModalState,
    setRegistrationModalState,
    openLoginModal,
    openRegistrationModal,
    completionModalState,
    setNomineeModalState,
    nomineeModalState,
    onNomineeModalClose,
    openNomineeModal,
    setRegistrationOTPModalState,
    isMoneyControl,
    setIsMoneyControl,
    setUsername,
    lsqUserLogout,
    setLoginModalState,
  } = useAuth();

  const [isHeaderTransparent, setIsHeaderTransparent] = useState(true);
  const [notAuthorized, setNotAuthorized] = useState<any>(null);

  const [headerDropdown, setHeaderDropdown] = useState(false);
  const [isArrowHovered, setIsArrowHovered] = useState(false);

  const router = useRouter();

  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  const customerPortalHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_CUSTOMER_PORTAL_HOSTING_ROOT',
  );

  // for kyc
  const landingPageHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  const newCPPortalHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_NEW_CUSTOMER_PORTAL_HOSTING_ROOT',
  );

  const opsPortalHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_OPS_PORTAL_HOSTING_ROOT',
  );

  const [isInChannelPartner, setIsInChannelPartner] = useState(true);

  const [subMenu, setSubMenu] = useState<any[]>();
  // Auth cookie callback

  const [investedPropertyCount, setInvestedPropertyCount] = useState<Number>(0);
  const [isUserCP, setIsUserCP] = useState(false);

  const [pathName, setPathName] = useState<string>('');
  const [toggleLinkedAccounts, setToggleLinkedAccounts] =
    useState<boolean>(false);

  const isWebview = isWebView();

  let pathname = '';
  if (typeof window !== 'undefined') {
    pathname = window.location.pathname;
  }

  function getParameterByName() {
    const urlparams = window.location.search;
    const searchParams: any = new URLSearchParams(urlparams);
    var arr = new Array();
    for (const [key, value] of searchParams) {
      arr.push([key, value]);
    }
    var attemptIdArray = arr.find(function (element) {
      return element[0] === 'attemptId';
    });
    var attemptId = attemptIdArray ? attemptIdArray[1] : null;
    return attemptId;
  }

  useEffect(() => {
    if (window.location.search.includes('utm')) {
      sessionStorage.setItem('utmParams', window.location.search);
    }
    if (window.location.search.includes('Moneycontrol')) {
      if (window.location.pathname === '/utm') {
        const authToken = authTokenStorageProvider.getRawAuthToken();
        if (authToken) {
          lsqUserLogout(authToken);
          clearAccessToken();
          authTokenStorageProvider.clearAuthToken();
          accountManager.clearAccountId();
          accountManager.clearLogoutFlag();
          setUsername('');
        }
      }
      setIsMoneyControl(true);
    }
    const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
    if (!authTokenModel && window.location.pathname === '/utm') {
      if (window.location.search.includes('SignupScreen')) {
        setRegistrationModalState(true);
      }
      if (window.location.search.includes('OTPScreen')) {
        registrationData.attemptId = getParameterByName();
        setRegistrationOTPModalState(true);
      }
      if (window.location.search.includes('loginScreen')) {
        setLoginModalState(true);
      }
    }
  }, []);

  useEffect(() => {
    if (
      loginModalState ||
      loginOTPModalState ||
      registrationModalState ||
      registrationOTPModalState ||
      completionModalState ||
      tncModalState
    ) {
      document.body.style.overflow = 'hidden';
      document.onkeydown = function (evt) {
        if (evt.key == 'Escape') {
          setTnCModalState(false);
        }
      };
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [
    loginModalState,
    loginOTPModalState,
    registrationModalState,
    registrationOTPModalState,
    completionModalState,
    tncModalState,
    username,
  ]);

  const getNumOfPropertiesInvestedIn = async () => {
    const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
    if (authTokenModel) {
      const response = await fetchInvestmentStats(
        accountManager.getAccountId(),
      );
      setInvestedPropertyCount(response.totalPropertiesOwned);
    }
  };
  useEffect(() => {
    getNumOfPropertiesInvestedIn();
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes('channel-partner')) {
      setIsInChannelPartner(false);
    }
  });

  useEffect(() => {
    checkIfAuthTokenIsValid();
  }, [completionModalState, loginOTPModalState]);

  useEffect(() => {
    getChildData();
  }, [completionModalState, loginOTPModalState, registrationOTPModalState]);

  const getChildData = () => {
    const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
    if (authTokenModel) {
      getLinkedAccount().then((data) => {
        setSubMenu(data);
      });
    }
  };

  const updateKyc = () => {
    const currentAccountId =
      authTokenStorageProvider.getAuthTokenModel()?.accountId;
    if (currentAccountId) {
      AccountManager.setAccountId(currentAccountId);
    }
    window.location.assign(landingPageHostingRoot + '/kyc');
  };

  const gotoOPS = () => {
    window.location.href = opsPortalHostingRoot!;
  };
  const gotoCP = () => {
    if (isUserCP) {
      window.location.href = newCPPortalHostingRoot + '/dashboard';
    } else {
      landingPageHostingRoot + '/channel-partner';
    }
  };

  const gotoDashboard = () => {
    if (isUserCP) {
      window.location.href = newCPPortalHostingRoot + '/dashboard';
    } else {
      window.location.href = customerPortalHostingRoot + '/customer/dashboard';
    }
  };

  if (typeof window !== 'undefined') {
    useEffect(() => {
      if (
        pathname === '/properties' ||
        pathname.split('/')[1] === 'viewproperty' ||
        pathname.split('/')[1] === 'blog' ||
        pathname === '/blog' ||
        pathname === '/faq' ||
        pathname === '/terms-and-conditions' ||
        pathname === '/privacy' ||
        pathname === '/testimonials-home' ||
        pathname === '/careers' ||
        pathname === '/media'
      ) {
        setNavbar(true);
      }
      setScreenWidth(window.innerWidth);
      const changeWidth = () => {
        setScreenWidth(window.innerWidth);
      };
      window.addEventListener('resize', changeWidth);

      return () => {
        window.removeEventListener('resize', changeWidth);
      };
    }, []);
  }

  const clicked = () => {
    setToggleMenu(!toggleMenu);
  };

  const addLinkedAccount = () => {
    setHeaderDropdown(!headerDropdown);
    setRegistrationModalState(true);
    setLinkedAccountFlag(true);
    getChildData();
  };

  const setLinkedAccountId = (id: string) => {
    AccountManager.setAccountId(id);
    window.location.reload();
  };

  const setUserAccountId = () => {
    const currentAccountId =
      authTokenStorageProvider.getAuthTokenModel()?.accountId;
    if (currentAccountId) {
      AccountManager.setAccountId(currentAccountId);
    }
    window.location.reload();
  };

  const getResources = () => {
    if (typeof window !== 'undefined') {
      const userType = authTokenStorageProvider.getAuthTokenModel()?.userTypes;
      if (userType?.includes('ChannelPartner')) {
        if (window?.location?.hash.includes('kyc')) {
          return cpResources;
        } else if (!investedPropertyCount) {
          return cpResources?.filter((itm) => itm.text !== 'Dashboard');
        }
      }
    }
    if (!investedPropertyCount) {
      return resources.filter((itm) => itm.text !== 'Dashboard');
    }
    return resources;
  };

  const currentResources = getResources();

  const options = [
    {
      label: 'updateKyc',
      value: 'Update KYC',
      usertype: '',
      callback: updateKyc,
    },
    {
      label: 'gotoOPS',
      value: 'Go to OPS portal',
      usertype: 'Ops',
      callback: gotoOPS,
    },
    {
      label: 'linkedAccount',
      value: 'Linked Account',
      usertype: '',
      callback: () => {},
    },
    {
      label: 'addNominee',
      value: 'Add Nominee',
      usertype: '',
      callback: openNomineeModal,
    },
    {
      label: 'SwitchToMainUser',
      value: 'Switch to Main User',
      usertype: 'SwitchToMainUser',
      callback: setUserAccountId,
    },
    {
      label: 'gotoCP',
      value: 'Go to CP Portal',
      usertype: 'ChannelPartner',
      callback: gotoCP,
    },
    ...(investedPropertyCount
      ? [
          {
            label: 'dashboard',
            value: 'Dashboard',
            usertype: '',
            callback: gotoDashboard,
          },
        ]
      : []),
    { label: 'logout', value: 'Logout', usertype: '', callback: logoutUser },
  ];

  eventEmitter.subscribe('propertyAuthCheck', () => openLoginModal(true));
  eventEmitter.subscribe('propertyEnrollCheck', () =>
    openRegistrationModal(true),
  );

  const handleDropDown = (event: any) => {
    // for dropdown in profile
    const dropdown = document.querySelector('.header_dropdown');
    const headerProfile = document.querySelector('.header-profile-container');
    if (dropdown && !headerProfile?.contains(event.target)) {
      setHeaderDropdown(false);
      setToggleLinkedAccounts(false);
    }
    // for dropdown in headings
    const optiondropdown = document.querySelector('.header-options_dropdown');
    const optionDropdownHeader = document.querySelector('.dropdownmenu');
    if (optiondropdown && !optionDropdownHeader?.contains(event.target)) {
      setIsHeaderOptionDropdown(false);
    }
  };
  useEffect(() => {
    document.body.addEventListener('click', handleDropDown);
    return () => {
      document.body.removeEventListener('click', handleDropDown);
    };
  }, []);

  useEffect(() => {
    const listenScrollEvent = () => {
      if (window.scrollY > 300) {
        setHeaderTransparent(false);
      } else {
        if (window.scrollY < 100) {
          setActiveClass('');
        }
        if (window.scrollY >= 100 && window.scrollY < 200) {
          setActiveClass('active_100');
        }
        if (window.scrollY >= 200 && window.scrollY < 300) {
          setActiveClass('active_200');
        }
        setHeaderTransparent(true);
      }
    };
    window.addEventListener('scroll', listenScrollEvent);
    setPathName(window.location.pathname);
  }, [typeof window !== 'undefined' && window?.scrollY]);

  const getLinkedAccountName = () => {
    const currentLinkedUser = AccountManager.getAccountId();
    const tenpSubMenu = subMenu === undefined ? [] : subMenu;
    let linkedUserName = '';
    for (let itm of tenpSubMenu) {
      if (itm.id === currentLinkedUser) {
        linkedUserName = `${itm.firstName} ${itm.lastName}`;
        break;
      }
    }
    return linkedUserName;
  };

  const [urlParams, setUrlParams] = useState<any>();

  useEffect(() => {
    if (window !== undefined) {
      sessionStorage.getItem('utmParams') === null
        ? setUrlParams('')
        : setUrlParams(sessionStorage.getItem('utmParams'));
    }
  }, [pathName, router && router?.asPath]);

  const isMainAccount = () => {
    const currentLinkedUser = AccountManager.getAccountId();
    const currentAccountId =
      authTokenStorageProvider.getAuthTokenModel()?.accountId;
    return currentLinkedUser === currentAccountId;
  };

  useEffect(() => {
    if (router && router?.asPath) {
      const isReferralUrl =
        String(router.asPath).startsWith('/referral?code=') ||
        String(router.asPath).startsWith('/utm?');
      if (isReferralUrl) {
        ShowHeaderTransparentInScreens.push(`${router.asPath}`);
      }
      setIsHeaderTransparent(
        ShowHeaderTransparentInScreens.includes(router.asPath),
      );
    } else {
      if (typeof window !== 'undefined') {
        setIsHeaderTransparent(
          ShowHeaderTransparentInScreens.includes(pathName),
        );
      }
    }
  }, [pathName, router && router?.asPath]);

  useEffect(() => {
    if (accountManager.getLogoutFlag() !== null) {
      setNotAuthorized(accountManager.getLogoutFlag());
    }
  }, [accountManager.getLogoutFlag()]);

  const isHeaderIconsVisibile =
    (router && router.asPath) || pathName === '/channel-partner/enrollment';

  const mobileStyleForScreens = pathName === '/channel-partner' || !router;

  const checkIfUserIsAlreadyChannelPartner = () => {
    const authTokenModel = authTokenStorageProvider.getAuthTokenModel();

    if (authTokenModel?.userTypes.includes(UserType.ChannelPartner)) {
      setIsUserCP(true);
      return;
    }
    setIsUserCP(false);
  };

  useEffect(() => {
    checkIfUserIsAlreadyChannelPartner();
  }, []);

  return (
    <>
      <ClarityTracking />
      <div
        ref={headerRef}
        className={`main-container ${
          headerTransparent && isHeaderTransparent ? `${activeClass}` : 'active'
        }`}
        id="myTopnav"
      >
        <Container>
          <div
            className={
              toggleMenu ? 'header_container toggle_open' : 'header_container'
            }
          >
            <div className="iconDivWrap">
              {isMoneyControl && (
                <img src={moneyControlLogo} className="moneyControlLogo"></img>
              )}
              <div className="logo-icon">
                <a
                  href={
                    isWebview
                      ? newCPPortalHostingRoot + '/home'
                      : landingPagesHostingRoot + `/${urlParams ?? ''}`
                  }
                >
                  <img
                    src={hbitLogo}
                    alt=" hBits - Fractional Ownership Platform "
                    className={'mobile-logo'}
                    style={
                      screenWidth && screenWidth < 800
                        ? mobileStyleForScreens
                          ? { marginTop: -8 }
                          : {}
                        : {}
                    }
                  />
                </a>
              </div>
            </div>
            {(toggleMenu || screenWidth > 800) && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '20px',
                  }}
                >
                  <div data-test="div-navigation-links" className="nav-links">
                    {(currentResources || []).map((resource, index) =>
                      !resource.button.isDropdown ? (
                        <div className="header-options" key={index}>
                          <LinkWrapper
                            urlLink={
                              resource.text === 'Dashboard'
                                ? (isUserCP
                                    ? newCPPortalHostingRoot
                                    : customerPortalHostingRoot + '/customer') +
                                  resource.url
                                : landingPagesHostingRoot + resource.url
                            }
                            keyText={resource.id}
                            onClick={() => setToggleMenu(false)}
                            classNameText={
                              pathname == resource.url
                                ? 'links highlight'
                                : 'links'
                            }
                            text={resource.text}
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="header-options" key={index}>
                            <div style={{ display: 'flex' }}>
                              <p
                                key={resource.id}
                                onClick={() => {
                                  setIsHeaderOptionDropdown(
                                    !isHeaderOptionDropdown,
                                  );
                                }}
                                onMouseEnter={() => setIsArrowHovered(true)}
                                onMouseLeave={() => setIsArrowHovered(false)}
                                className={'links dropdownmenu t-align'}
                                style={{
                                  cursor: 'pointer',
                                  paddingRight: '5px',
                                }}
                              >
                                {resource.text}
                              </p>
                              <img
                                src={
                                  isArrowHovered ? downArrowOrange : downArrow
                                }
                                style={{
                                  height: '12px',
                                  alignSelf: 'center',
                                  marginRight: '22px',
                                }}
                              ></img>
                            </div>
                          </div>
                          {isHeaderOptionDropdown && (
                            <div className="btns header-profile-container2 m_bt ">
                              <div className="header_dropdown header-options_dropdown">
                                {resource.button.value.length > 0 &&
                                  resource.button.value.map((itm, index) => {
                                    return (
                                      <LinkWrapper
                                        urlLink={
                                          landingPagesHostingRoot + itm.url
                                        }
                                        keyText={resource.id}
                                        classNameText={
                                          'dropdown_item header-options_dropdown_item'
                                        }
                                        onClick={() => {
                                          setToggleMenu(false);
                                          setIsHeaderOptionDropdown(false);
                                        }}
                                        text={itm.text}
                                      />
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </div>
                      ),
                    )}
                  </div>
                  {router?.pathname !== '/' &&
                    router?.pathname &&
                    isbreadCrumbVisible &&
                    !router?.asPath.includes('utm') &&
                    !router?.asPath.includes('viewproperty') && <Breadcrumbs />}
                </div>

                {username == '' ? (
                  <div className="btns">
                    <button
                      className="new_btn"
                      type="submit"
                      style={{
                        border: 'none',
                      }}
                    >
                      <img
                        src={PhoneCall}
                        alt="Contact us"
                        className={'login-icon'}
                      />

                      <a
                        href={`tel:${Strings.contactusregestration}`}
                        data-test="button-login"
                        className="a-btn"
                      >
                        {Strings.contactusregestration}
                      </a>
                    </button>
                    {/* Can be add notification feature later on */}
                    {/* <div
                      style={{ border: 'none', display: 'none' }}
                      className="bell-icon-button"
                    >
                      <img src={BellIcon} height={'20px'} width={'20px'} />
                    </div> */}
                    <button
                      className="new_btn"
                      type="submit"
                      style={{ border: 'none' }}
                      onClick={() => {
                        openLoginModal(false);
                        setToggleMenu(false);
                      }}
                    >
                      <div>
                        <img
                          src={LoginIcon}
                          alt="Invest in Real Estate"
                          className={'login-icon'}
                        />
                      </div>
                      <a data-test="button-login" className="a-btn">
                        Login
                      </a>
                    </button>
                    <button
                      className="new_btn"
                      type="submit"
                      onClick={() => {
                        openRegistrationModal(false);
                        setToggleMenu(false);
                        setLinkedAccountFlag(false);
                      }}
                    >
                      <a data-test="button-signup" className="a-btn">
                        Sign Up
                      </a>
                    </button>

                    {/* <button
                      className="new_btn"
                      type="submit"
                      style={{
                        border: 'none',
                      }}
                    >
                      <a
                        data-test="button-login"
                        className="a-btn"
                        href={whatsAppLink}
                        target="_blank"
                      >
                        <img src={WhatsappIcon} className={'login-icon'} />
                        Connect
                      </a>
                    </button> */}
                  </div>
                ) : (
                  <div
                    className="btns header-profile-container"
                    style={
                      isInChannelPartner && screenWidth < 800
                        ? { marginTop: 30 }
                        : {}
                    }
                  >
                    <div className="linked-account-text">
                      {!isMainAccount() && (
                        <p
                          data-test="p-linked-account-name"
                          className={'links'}
                          style={{ marginRight: '10px', color: 'white' }}
                        >
                          {getLinkedAccountName()
                            ? `Linked Account : ${getLinkedAccountName()}`
                            : ''}
                        </p>
                      )}
                      <div
                        data-test="div-header-profile-image"
                        className="header_profile"
                        onClick={() => {
                          setHeaderDropdown(!headerDropdown);
                        }}
                        style={{
                          marginTop:
                            isInChannelPartner && screenWidth < 800
                              ? '-30px'
                              : '',
                        }}
                      >
                        <div className="header_profile_image">
                          <img src={UserAccount} alt="Invest in Real Estate" />
                        </div>
                        <a className="user-name">{username}</a>
                      </div>
                    </div>

                    {headerDropdown && (
                      <div
                        data-test="div-class-header-dropdown"
                        className="header_dropdown"
                      >
                        {options.map((option, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              if (option.label == 'linkedAccount') {
                                setToggleLinkedAccounts(!toggleLinkedAccounts);
                              }
                            }}
                          >
                            {option.usertype == '' && (
                              <>
                                {(!isInChannelPartner ||
                                  option.label === 'logout') && (
                                  <div>
                                    <button
                                      data-test="button-header-options"
                                      key={option.label}
                                      type="button"
                                      className={
                                        'dropdown_item' +
                                        (option.label == 'linkedAccount'
                                          ? ' dropdown_linkedaccount '
                                          : '')
                                      }
                                      onClick={option.callback}
                                    >
                                      <div
                                        className={
                                          option.label == 'linkedAccount'
                                            ? ' submenu_title '
                                            : ''
                                        }
                                      >
                                        <div>{option.value}</div>
                                        {option.label == 'linkedAccount' && (
                                          <div
                                            data-test="div-option-linked-account"
                                            className="submenu_icon"
                                          >
                                            <img
                                              src={rightArrow}
                                              alt=">"
                                              width={'8px'}
                                              height={'8px'}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {toggleLinkedAccounts && (
                                        <div className="linkedAccount">
                                          {option.label == 'linkedAccount' && (
                                            <div>
                                              {subMenu?.length ? (
                                                <div
                                                  className={
                                                    subMenu &&
                                                    subMenu.length > 5
                                                      ? 'sub_menu_class'
                                                      : ''
                                                  }
                                                >
                                                  {subMenu?.map(
                                                    (submenuoption, index) => (
                                                      <div key={index}>
                                                        {submenuoption.mobileNumber !==
                                                          '' && (
                                                          <div>
                                                            <button
                                                              type="button"
                                                              className="dropdown_item"
                                                              onClick={() => {
                                                                setLinkedAccountId(
                                                                  submenuoption.id,
                                                                );
                                                              }}
                                                            >
                                                              {submenuoption.firstName +
                                                                ' ' +
                                                                submenuoption.lastName}
                                                            </button>
                                                          </div>
                                                        )}
                                                      </div>
                                                    ),
                                                  )}
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                              {subMenu?.length ? (
                                                <hr
                                                  style={{ margin: '0px' }}
                                                ></hr>
                                              ) : (
                                                <></>
                                              )}
                                              <div>
                                                <button
                                                  data-test="button-add-linked-account"
                                                  type="button"
                                                  className="dropdown_item"
                                                  onClick={() => {
                                                    addLinkedAccount();
                                                  }}
                                                >
                                                  Add Linked Account
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                            {!isInChannelPartner &&
                              authTokenStorageProvider.getAuthTokenModel()
                                ?.accountId != accountManager.getAccountId() &&
                              option.label == 'SwitchToMainUser' && (
                                <div>
                                  <button
                                    key={option.label}
                                    type="button"
                                    className="dropdown_item"
                                    onClick={option.callback}
                                  >
                                    {option.value}
                                  </button>
                                </div>
                              )}

                            {!isInChannelPartner &&
                              userTypeOps &&
                              option.usertype === UserType.Ops && (
                                <div>
                                  <button
                                    key={option.label}
                                    type="button"
                                    className="dropdown_item"
                                    onClick={option.callback}
                                  >
                                    {option.value}
                                  </button>
                                </div>
                              )}
                            {!isInChannelPartner &&
                              userTypeCP &&
                              option.usertype === UserType.ChannelPartner && (
                                <div>
                                  <button
                                    key={option.label}
                                    type="button"
                                    className="dropdown_item"
                                    onClick={option.callback}
                                  >
                                    {option.value}
                                  </button>
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {isHeaderIconsVisibile && (
              <>
                <a
                  href={`tel:${Strings.contactusregestration}`}
                  data-test="button-login"
                >
                  <span className="call_icon">
                    <img src={PhoneCall} />
                  </span>
                </a>
                {/* <a href={whatsAppLink} target="_blank" data-test="button-login">
                  <button className="whatsapp_icon" type="button">
                    <img src={WhatsappIcon} />
                  </button>
                </a> */}
              </>
            )}

            <button type="button" className="icon" onClick={clicked}>
              <img src={MenuIcon} />
            </button>
          </div>
        </Container>
      </div>
      {registrationModalState && (
        <RegistrationModal
          registrationData={preRegFlag ? registrationData : null}
          onModalClose={hideRegistrationModal}
          onRegisterClick={onLoginRegisterClick}
          onLoginClick={onLoginClick}
          onTnCClick={openTnCModal}
          linkedFlag={linkedAccountFlag}
        />
      )}
      {registrationOTPModalState && registrationData.attemptId && (
        <Suspense fallback={`Loading...`}>
          <RegistrationOtpModal
            attemptId={registrationData.attemptId}
            onModalClose={onRegisterModalClose}
            onChangeMobileClick={onChangeMobileClick}
            onCompletion={openCompletionModal}
          />
        </Suspense>
      )}
      {loginModalState && (
        <Suspense fallback={`Loading...`}>
          <LoginModal
            loginData={preLoginFlag ? loginData : null}
            onModalClose={hideLoginModal}
            onRegisterClick={onRegisterClick}
            onLoginClick={openLoginOTPModal}
            onCompletion={onLoginCompletion}
          />
        </Suspense>
      )}
      {loginOTPModalState && loginData.accountId && (
        <Suspense fallback={`Loading...`}>
          <LoginOtpModal
            accountId={loginData.accountId}
            onChangeMobileClick={onLoginClick}
            onModalClose={onLoginOtpModalClose}
            onRegisterClick={onOpenRegistrationModalClose}
            onCompletion={onLoginCompletion}
          />
        </Suspense>
      )}
      {tncModalState && !isInChannelPartner && (
        <Suspense fallback={`Loading...`}>
          <TncModal onModalClose={onTnCClick} />
        </Suspense>
      )}
      {tncModalState && isInChannelPartner && (
        <Suspense fallback={`Loading...`}>
          <ChannelPartnerTncModal onModalClose={onTnCClick} />
        </Suspense>
      )}
      {completionModalState && (
        <Suspense fallback={`Loading...`}>
          <CompletionModal onModalClose={onLoginCompletion} />
        </Suspense>
      )}
      {notAuthorized != null && (
        <Suspense fallback={`Loading...`}>
          <UnAuthorizedUserModal isOpen={true} onModalClose={logoutUser} />
        </Suspense>
      )}
      {nomineeModalState && (
        <NomineeModal
          isOpen={nomineeModalState}
          onModalClose={onNomineeModalClose}
        />
      )}
    </>
  );
};
