import { Environment } from '@hBits.Core';
import '../kyc.scss';
import hourGlass from './../resources/hourglass.png';
interface KycStatusKycInProgressProps {
  isChannelPartner: boolean;
}

const KycStatusKycInProgress = (props: KycStatusKycInProgressProps) => {
  const { isChannelPartner } = props;
  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  const newCustomerPortalHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_NEW_CUSTOMER_PORTAL_HOSTING_ROOT',
  );

  const continueButton = () => {
    if (isChannelPartner) {
      window.location.assign(newCustomerPortalHostingRoot + '/dashboard');
    } else {
      window.location.assign(landingPagesHostingRoot + '/properties');
    }
  };
  return (
    <div>
      <div className="kyc_margin-top-1">
        <div className="kyc_text-align-center kyc_padding-top-3 kyc_padding-bottom-3">
          <img src={hourGlass} className="kyc_green-tick"></img>
          <br />
          <h6 className="kyc_h6-tag-for-kyc-status-page">
            KYC verification in progress....
          </h6>
          <p className="kyc_p-tag-for-kyc-status-page">
            Thankyou for submitting your details. We are in process of verifying
            your details.
            <br />
            <br />
            We highly appreciate your patience.
          </p>
          <br />
          <button className="kyc_btn" onClick={continueButton}>
            {isChannelPartner ? 'Back To Dashboard' : 'Continue'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default KycStatusKycInProgress;
