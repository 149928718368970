import { Container, Flex, Text } from '@chakra-ui/react';
import { HTTPStatusCode } from '@hBits.Core';
import { getLeadOwnerData } from 'apps/customer-portal-web/src/services/Kyc/getKycData';
import accountIdManager from 'libs/src/auth/account-manager';
import colors from 'libs/src/theme/colors';
import { useEffect, useState } from 'react';
import { FaMobile, FaUser } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { ArticleContainer } from '../../Dashboard/styled-components';
const RmDetails = () => {
  const [leadOwnerInfo, setLeadOwnerInfo] = useState<any>();

  const setLeadOwnerDetails = async () => {
    const response = await getLeadOwnerData(accountIdManager?.getAccountId());
    if (response?.status === HTTPStatusCode.OK) {
      setLeadOwnerInfo(response?.data?.leadOwner);
    }
  };

  useEffect(() => {
    setLeadOwnerDetails();
  }, []);

  return (
    <Container>
      <ArticleContainer>
        <Flex mt={2} direction={{ base: 'column', md: 'row' }} flex={1}>
          {leadOwnerInfo && (
            <Flex
              direction={'column'}
              justifyContent={'center'}
              justifyItems={'center'}
            >
              <Flex fontSize={'14px'} mb={2}>
                <Text color={'#535353'}>For queries, connect with your RM</Text>
              </Flex>
              <Flex className="flex flex-row items-center justify-start">
                <FaUser />
                <Text
                  as="span"
                  fontWeight={500}
                  color={colors.bgDarkBlue}
                  marginLeft={2}
                >
                  {leadOwnerInfo?.fullName}
                </Text>
              </Flex>
              <Flex className="flex flex-row items-center justify-start">
                <FaMobile />
                <Text fontWeight={500} color={colors.bgDarkBlue} marginLeft={2}>
                  {leadOwnerInfo?.mobileNumber}
                </Text>
              </Flex>
              <Flex className="flex flex-row items-center justify-start">
                <MdEmail />
                <Text fontWeight={500} color={colors.bgDarkBlue} marginLeft={2}>
                  {leadOwnerInfo?.emailAddress}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </ArticleContainer>
    </Container>
  );
};

export default RmDetails;
