import { fetchAsync, HTTPMethod } from '@hBits.Core';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';

export const fetchInvestmentStats = (id: any) => {
  const req = {
    method: HTTPMethod.GET,
    data: {},
    url: baseApiUrl + endpoints.investmentStats.replace('#{accountId}', id),
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};
