import { Flex, Input, Text, Image, Button } from '@chakra-ui/react';
import colors from 'libs/src/theme/colors';
import { useSelector } from 'react-redux';
import styles from '../index.module.scss';
import { capitalizeFirstLetter, capitalizeEachLetter } from '../utils';
import verifiedImage from '../../../assets/kyc/greentick.png';
import { useEffect, useState } from 'react';
import EmailVerifyOtpModal from './EmailVerifyModal/index';
import { initializeEmailOtp } from '../../../services/Kyc/emailVerify';
import { HTTPStatusCode } from '@hBits.Core';

interface InputTileProps {
  placeholder?: string;
  paddingRight?: number;
  value?: string;
  handleChangeName?: string;
  errors?: any;
  setFieldTouched?: any;
  touched?: any;
  isPanNameText?: boolean;
  isAllLettersCapital?: boolean;
  flex?: number;
  isDisabled?: boolean;
  holderNumber: number;
  isValueNormal?: boolean;
  isEmailVerified?: boolean;
  setFieldValue?: any;
  setInputValue?: any;
  index?: number;
  testId?: string;
}

export const InputTile = (props: InputTileProps) => {
  const { isAllFieldsDisable } = useSelector((state: any) => state.KycReducer);
  const {
    placeholder,
    paddingRight = 0,
    value,
    handleChangeName,
    errors,
    setFieldTouched,
    touched,
    isPanNameText = false,
    isAllLettersCapital = false,
    flex = 1,
    holderNumber,
    isDisabled = isAllFieldsDisable[holderNumber],
    isValueNormal = false,
    setFieldValue,
    isEmailVerified = false,
    setInputValue,
    index,
    testId,
  } = props;
  const [emailStatus, setEmailStatus] = useState<boolean>();
  const [emailVerifyOtpModal, setEmailVerifyOtpModal] =
    useState<boolean>(false);
  const [emailError, setEmailError] = useState<any>();
  const [attemptId, setAttemptId] = useState<string>('');

  const handleChange = (e: any) => {
    if (setInputValue) {
      setInputValue(
        handleChangeName,
        index,
        isAllLettersCapital
          ? capitalizeEachLetter(e.target.value)
          : isValueNormal
          ? e.target.value
          : capitalizeFirstLetter(e.target.value),
      );
    }
    if (setFieldValue) {
      setFieldValue(
        `${handleChangeName}`,
        isAllLettersCapital
          ? capitalizeEachLetter(e.target.value)
          : isValueNormal
          ? e.target.value
          : capitalizeFirstLetter(e.target.value),
      );
    }
  };

  const handleVerifyButton = () => {
    const data = { emailAddress: value };
    initializeEmailOtp(data).then((response) => {
      if (response?.status === HTTPStatusCode.OK) {
        setAttemptId(response?.data?.attemptId);
        setEmailVerifyOtpModal(true);
        setEmailError(undefined);
      } else if (response?.status === HTTPStatusCode.INTERNAL_SERVER_ERROR) {
        setEmailError('Email is already in use');
      }
    });
  };

  useEffect(() => {
    setEmailStatus(isEmailVerified);
  }, [isEmailVerified]);

  return (
    <>
      <Flex flex={flex} pr={{ base: 0, md: paddingRight }} direction={'column'}>
        <Flex>
          <Input
            data-test={testId}
            height={'45px'}
            fontSize={'16px'}
            placeholder={placeholder}
            _placeholder={{ color: colors.kycPlaceholder, fontSize: '16px' }}
            borderRadius={0}
            borderColor={colors.buttonBorderGrey}
            onChange={(e) => handleChange(e)}
            onBlur={() => setFieldTouched(`${handleChangeName}`)}
            value={value}
            variant="flushed"
            disabled={
              isDisabled ||
              (handleChangeName === 'emailAddress' &&
                holderNumber === 0 &&
                emailStatus)
            }
            _disabled={{ color: 'black' }}
          />
          {handleChangeName === 'emailAddress' && holderNumber === 0 && (
            <>
              {emailStatus && (
                <Image
                  src={verifiedImage}
                  width={'40px'}
                  height={'40px'}
                  alt=""
                ></Image>
              )}
              {!emailStatus && (
                <Button
                  data-test="button-verify-email"
                  marginTop={3}
                  padding={3}
                  height={3}
                  backgroundColor={colors.orangeColor}
                  _hover={{ bg: colors.orangeColor }}
                  _active={{ bg: colors.orangeColor }}
                  onClick={() => handleVerifyButton()}
                >
                  Verify
                </Button>
              )}
            </>
          )}
        </Flex>
        {emailError && (
          <div className={styles['errorRegMessage']}>{emailError}</div>
        )}
        {errors && touched && (
          <div className={styles['errorRegMessage']}>{errors.toString()}</div>
        )}
        {isPanNameText && (
          <Text fontSize={'12px'} color={colors.buttonBorderGrey}>
            <Text
              as="span"
              fontSize={'14px'}
              color={colors.bgDarkBlue}
              fontWeight={'500'}
            >
              Note:
            </Text>{' '}
            All PAS-4, Share certificates and other documents will be issued in
            this name only
          </Text>
        )}
      </Flex>
      {emailVerifyOtpModal && (
        <EmailVerifyOtpModal
          EmailValue={value}
          attemptId={attemptId}
          onModalClose={() => setEmailVerifyOtpModal(false)}
          onChangeMobileClick={() => setEmailVerifyOtpModal(false)}
          onEmailVerified={() => setEmailStatus(true)}
        />
      )}
    </>
  );
};
