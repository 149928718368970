import endpoints from 'libs/src/config/endpoints';

import { HTTPMethod, HTTPStatusCode } from 'libs/src/enums';
import { fetchAsync } from '@hBits.Core';

export const getInvestMentStatus = async (
  accountId: string,
  propertyId: string,
  data: any,
  requestType: string,
): Promise<any> => {
  try {
    const request = {
      method: requestType,
      url: endpoints.createInvestment
        .replace('#{accountId}', accountId)
        .replace('#{propertyId}', propertyId),
      data,
    };
    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return response;
  } catch (error) {
    return [];
  }
};

export const updateInvestmentAmount = async (
  investmentId: string,
  investmentAmount: string,
  investmentModal?: any,
  calBack?: any,
  successCallback?: () => void,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.updateInvestmentAmount.replace(
        '#{investmentId}',
        investmentId,
      ),
      data: {
        investmentAmount: parseInt(investmentAmount),
      },
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      if (successCallback) {
        successCallback();
      }
      return response.data;
    }

    calBack(response.error.additionalDetails);

    return investmentModal;
  } catch (error) {
    return investmentModal;
  }
};

export const updateMinTokenAmount = async (
  investmentId: string,
  minTokenAmount: string,
  investmentModal?: any,
  calBack?: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.updateMinTokenAmount.replace(
        '#{investmentId}',
        investmentId,
      ),
      data: {
        eoiAmount: parseInt(minTokenAmount),
      },
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }

    calBack(response.error.additionalDetails);

    return investmentModal;
  } catch (error) {
    return investmentModal;
  }
};

export const getEoiDocumentUrl = async (
  investmntId: string,
  calBack?: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.eoiDocumentUrl.replace('#{investmentId}', investmntId),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    calBack(response.error.additionalDetails);
    return [];
  } catch (error) {
    return [];
  }
};

export const getTopUpEoiDocumentUrl = async (
  investmntId: string,
  calBack?: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.eoiTopUpDocumentUrl.replace(
        '#{investmentId}',
        investmntId,
      ),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    calBack(response.error.additionalDetails);
    return [];
  } catch (error) {
    return [];
  }
};

export const setEoiDocumentSucess = async (
  investmntId: string,
  investmentModal: any,
  callBack?: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.eoiDocumentUrlSucess.replace(
        '#{investmentId}',
        investmntId,
      ),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    callBack(response.error.additionalDetails);
    return investmentModal;
  } catch (error) {
    return [];
  }
};

export const setEoiTopUpDocumentSucess = async (
  investmntId: string,
  investmentModal?: any,
  callBack?: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.eoiTopUpDocumentUrlSucess.replace(
        '#{investmentId}',
        investmntId,
      ),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    callBack(response.error.additionalDetails);
    return investmentModal;
  } catch (error) {
    return [];
  }
};

export const getPaymentUrl = async (
  investmntId: string,
  callBack?: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.investmentPayment.replace('#{investmentId}', investmntId),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    callBack(response.error.additionalDetails);
    return [];
  } catch (error) {
    return [];
  }
};

export const getTopUpPaymentUrl = async (
  investmntId: string,
  callBack?: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.topUpPaymentUrl.replace('#{investmentId}', investmntId),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    callBack(response.error.additionalDetails);
    return [];
  } catch (error) {
    return [];
  }
};

export const updateTopAmount = async (
  investmentId: string,
  amount: string,
  investmentModal?: any,
  calBack?: any,
  successCallback?: () => void,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.addTopUpAmount.replace('#{investmentId}', investmentId),
      data: {
        topUpAmount: parseInt(amount),
      },
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      if (successCallback) {
        successCallback();
      }
      return response.data;
    }
    calBack(response.error.additionalDetails);

    return investmentModal;
  } catch (error) {
    return [];
  }
};

export const updateMinTopUpTokenAmount = async (
  investmentId: string,
  minTopUpTokenAmount: string,
  investmentModal?: any,
  calBack?: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.addMinTopUpTokenAmount.replace(
        '#{investmentId}',
        investmentId,
      ),
      data: {
        eoiAmount: parseInt(minTopUpTokenAmount),
      },
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }

    calBack(response.error.additionalDetails);

    return investmentModal;
  } catch (error) {
    return investmentModal;
  }
};

export const deleteTopUp = async (
  investmentId: string,
  callBack?: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.DELETE,
      url: endpoints.deleteTopUp.replace('#{investmentId}', investmentId),
    };
    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    callBack(response.error.additionalDetails);
    return response;
  } catch (error) {
    return [];
  }
};

export const getBillDeskJWT = async (
  investmntId: string,
  callBack?: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.billdeskPayment.replace('#{investmentId}', investmntId),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    callBack(response.error.additionalDetails);
    return [];
  } catch (error) {
    return [];
  }
};
