import { HTTPMethod, fetchAsync } from '@hBits.Core';
import endpoints from 'libs/src/config/endpoints';
import { Axios } from '../../NetworkHelper';

export const fetchAllProperties = async (channel: string): Promise<any> => {
  try {
    let data = await Axios.get(`${endpoints.properties}${channel}`);
    return data.data;
  } catch (error) {
    return [];
  }
};

export const fetchPropertyData = async (propertyId: string): Promise<any> => {
  try {
    let data = await Axios.get(`${endpoints.property}${propertyId}`);
    return data.data;
  } catch (error) {
    return [];
  }
};

export const fetchAllPropertiesByAsync = async (
  channel: string,
): Promise<any> => {
  try {
    const req = {
      method: HTTPMethod.GET,
      url: `${endpoints.properties}`,
    };
    const response = await fetchAsync(req);
    return response.data;
  } catch (_error) {
    return null;
  }
};
