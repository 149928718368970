import { Flex } from '@chakra-ui/react';
import { numberInIndianFormat } from 'apps/customer-portal-web/src/utils';
import Chart from 'react-apexcharts';

const BarChart = (props) => {
  const { data, categories, title } = props;

  const responsiveGraph = {
    sm: 480,
    mid: 700,
    lg: 980,
  };

  const getgraphWidth = () => {
    let width = screen.width;
    if (width >= responsiveGraph.lg) return 620;
    if (width >= responsiveGraph.mid) return 400;
    if (width > responsiveGraph.sm) return 450;
    return 300;
  };

  const getgraphLegengMargin = () => {
    let width = screen.width;
    if (width >= responsiveGraph.lg) {
      return 60;
    }
    return 20;
  };

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      width: '100%',
      toolbar: {
        show: false
      },
    },
    colors: ['#00a768', '#ff0000'],
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: 50,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },

    legend: {
      position: 'top',
      verticalAlign: 'top',
      horizontalAlign: 'center',
      itemMargin: {
        horizontal: 5,
        vertical: 20
    },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ['transparent'],
    },
    responsive: [
      {
        breakpoint: 480,
        chart: {
          width: 350,
          height: 100,
        },
        options: {
          plotOptions: {
            bar: {
              borderRadius: 10,
              horizontal: false,
            },
          },
          legend: {
            position: 'top',
          },
        },
      },
    ],
    title: {
      text: title,
      floating: true, 
      margin:20,
      offsetY: -10,
      style: {
        color: "#1A295B",
        fontSize:  '20px',
        fontWeight:  'bold',
      }
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      strokeDashArray: 0,
      labels:{
        formatter: function (val) {
          return numberInIndianFormat(val);
      }
    }
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return numberInIndianFormat(val);
        },
      },
    },
  };
  return (
    <Flex ml={1}>
      <Chart
        options={options}
        series={data}
        type="bar"
        height={350}
        width={getgraphWidth()}
      />
    </Flex>
  );
};

export default BarChart;
