import { fetchAsync, HTTPMethod, HTTPStatusCode } from '@hBits.Core';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';

export const getListOfProperties = async () => {
  try {
    const req = {
      method: HTTPMethod.GET,
      data: {},
      url: baseApiUrl + endpoints.listOfPropertiesWithoutDrafts,
    };
    const response = await fetchAsync(req);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (_error) {
    return null;
  }
};
