import {
  Flex,
  ListItem,
  OrderedList,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
  Link,
} from '@chakra-ui/react';
import { Environment } from '@hBits.Core';
import * as React from 'react';

const Footerdata = () => {
  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );
  return (
    <Flex flexDirection="column" color="#94969f" marginTop={'100px'}>
      <h2>Introduction to Fractional Ownership Real estate</h2>
      <Text>
        Fractional ownership real estate refers to the division of a property
        into several shares, each owned by different individuals. This type of
        ownership structure allows multiple people to own a stake in the
        property, which typically includes high-value assets.
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/fractional-ownership-investment'}
        >
          Fractional ownership in real estate
        </Link>{' '}
        presents an innovative way for investors to get a piece of high-end
        properties they may not be able to afford as sole owners. Moreover,
        fractional ownership real estate allows investors to participate in the
        real estate market without the inconvenience of managing and maintaining
        a physical asset. Fractional ownership can provide benefits such as
        shared upkeep and maintenance costs among co-owners as well as
        scheduling access to the property in a fair and equitable manner.
        Fractional ownership of real estate has become an increasingly popular
        concept that offers many benefits for those who want to invest in the
        property market but may not be able to do so on their own.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Fractional Ownership Real Estate -{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/how-it-works'}
        >
          How it Works
        </Link>{' '}
      </h2>
      <UnorderedList>
        <ListItem>
          Fractional ownership is a form of shared ownership in real estate,
          where multiple individuals or entities own a portion of a property.
          Each owner holds a percentage of the property's title.
        </ListItem>
        <ListItem>
          Fractional ownership can be organized in several ways, but the most
          common is through a real estate company that manages the property and
          handles all the administrative tasks related to ownership.
        </ListItem>
        <ListItem>
          The property management company is responsible for maintaining the
          property, handling rentals, and ensuring that the property is in good
          condition.
        </ListItem>
        <ListItem>
          Some popular types of properties that are often offered through
          fractional ownership real estate include vacation homes, private
          residences, luxury apartments, and even yachts or private jets.
        </ListItem>
      </UnorderedList>
      <h2 style={{ marginTop: '20px' }}>
        Types of Fractional Property Investment
      </h2>
      <Text>
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/properties'}
        >
          Fractional property investment
        </Link>{' '}
        comes in various types. Each type has its own unique benefits and risks,
        and it's important to work with a reputable real estate company and
        carefully consider these factors before making a decision.
      </Text>
      <Text>Here are some of the most common types:</Text>
      <OrderedList>
        <ListItem>
          Fractional Ownership: This type of fractional real estate investment
          involves purchasing a percentage share in a specific property or
          asset, such as a condo or a vacation home. As a fractional owner, you
          have the right to use the property for a set amount of time each year
          and share in all the expenses associated with owning the property.
        </ListItem>
        <ListItem>
          Fractional Rental Investment: In this type of fractional property
          investment, investors pool their money to purchase a rental property
          with the intention of renting it out and sharing the resulting
          profits.
        </ListItem>
        <ListItem>
          Private Residence Club: Private residence clubs are an exclusive type
          of fractional ownership where investors purchase access to a luxury
          vacation home or resort property for a certain number of weeks or
          months each year.
        </ListItem>
        <ListItem>
          Real Estate Investment Trusts (REITs): A REIT is a type of investment
          fund that owns and operates income-generating real estate properties
          and allows investors to buy shares in the fund.
        </ListItem>
      </OrderedList>
      <h2 style={{ marginTop: '20px' }}>
        Benefits of Fractional Property Investment
      </h2>
      <UnorderedList>
        <ListItem>
          Fractional real estate investment offers several benefits, including
          reduced financial risk as investors only need to contribute a portion
          of the total purchase price. This can make real estate investment more
          accessible to a wider range of investors, including those without the
          financial means to purchase an entire property outright.
        </ListItem>
        <ListItem>
          Fractional property investment allows investors to diversify their
          portfolios by investing in multiple properties instead of putting all
          their funds into a single real estate asset.
        </ListItem>
        <ListItem>
          It increases the ability of investors to invest in higher-end
          properties that would otherwise be out of reach, as well as access to
          professional management services that handle all aspects of the
          property investment.
        </ListItem>
        <ListItem>
          Fractional investment offers greater flexibility than traditional real
          estate investments.
        </ListItem>
      </UnorderedList>
      <h2 style={{ marginTop: '20px' }}>
        Difference between Fractional Real Estate Investment & Traditional Real
        Estate Investment
      </h2>
      <Text>
        Fractional real estate investment is a new trend that allows investors
        to own a small portion of high-value properties. Here is a table
        contrasting fractional and traditional real estate investment:
      </Text>
      <Table variant="striped" border="2px solid" borderWidth="2px">
        <Tbody>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Aspect</Td>
            <Td border="2px solid">Fractional Real Estate Investment</Td>
            <Td>Traditional Real Estate Investment</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Ownership Structure</Td>
            <Td border="2px solid">
              Investors own a portion of the property and share the returns
            </Td>
            <Td>Investors own the entire property and receive all profits</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Investment Size</Td>
            <Td border="2px solid">
              Smaller investment size and more affordable entry
            </Td>
            <Td>Larger investment size and requires significant capital</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Risk</Td>
            <Td border="2px solid">
              Lower risk due to a diversified portfolio
            </Td>
            <Td>Higher risk due to concentrated portfolio</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Liquidity</Td>
            <Td border="2px solid">
              More liquidity as investors can buy or sell their portion of the
              property easily
            </Td>
            <Td>
              Less liquidity as investors need to find a buyer for the entire
              property
            </Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Management</Td>
            <Td border="2px solid">
              Property management and maintenance are typically handled by the
              fractional investment company or property manager
            </Td>
            <Td>
              Investors are responsible for all aspects of property management
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <h2 style={{ marginTop: '20px' }}>
        Here's how to do Fractional Real estate Investment
      </h2>
      <Text>
        Fractional real estate investment involves buying a portion of a
        property with other investors, and sharing the costs and potential
        profits. Here's{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/contact-us'}
        >
          how to get started
        </Link>{' '}
        in 100 words:
      </Text>
      <UnorderedList>
        <ListItem>
          Choose a reputable platform that offers fractional real estate
          investment opportunities.
        </ListItem>
        <ListItem>
          Research the available properties and choose one that aligns with your
          investment goals.
        </ListItem>
        <ListItem>
          Review the financials and assess the potential risks and returns.
        </ListItem>
        <ListItem>
          Invest your desired amount and wait for the property to appreciate or
          generate rental income.
        </ListItem>
        <ListItem>
          Keep track of your investment and evaluate its performance regularly.
        </ListItem>
      </UnorderedList>
      <Text>
        Fractional property investment can be a great way to diversify your
        portfolio and generate passive income, but it's important to understand
        the risks involved.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Why Fractional Ownership Real Estate investment is a better option?
      </h2>
      <Text>
        Fractional ownership real estate investment is a superior option for
        savvy investors who want to maximize their return on investment while
        minimizing their risks. Compared to traditional real estate investment,
        fractional ownership offers a range of benefits that make it a more
        compelling option.
      </Text>
      <OrderedList>
        <ListItem>
          The lower entry costs associated with fractional ownership make it
          accessible to a wider range of investors, enabling them to get a foot
          in the door of the lucrative real estate market.
        </ListItem>
        <ListItem>
          Fractional ownership offers unparalleled diversification
          opportunities, allowing investors to spread their risk across multiple
          properties and regions, minimizing the impact of market fluctuations
          and economic uncertainties.
        </ListItem>
        <ListItem>
          The passive income generated by fractional ownership provides
          investors with a stable and reliable income stream, freeing up their
          time and energy to focus on other pursuits.
        </ListItem>
        <ListItem>
          Professional management ensures that the property is well-maintained
          and managed, providing investors with peace of mind and reducing their
          workload.
        </ListItem>
        <ListItem>
          Fractional ownership offers greater liquidity, enabling investors to
          sell their shares and realize their gains more easily than they would
          with traditional real estate investment.
        </ListItem>
      </OrderedList>
      <h2 style={{ marginTop: '20px' }}>
        hBits - One of the best real estate options in India
      </h2>
      <Text>
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/about'}
        >
          hBits is a premium real estate investment platform
        </Link>{' '}
        that offers unparalleled investment opportunities to discerning
        investors seeking to maximize their returns while minimizing their
        risks. Our platform provides fractional ownership of carefully selected,
        high-quality properties that are professionally managed, providing
        investors with a stable and reliable passive income stream which is why
        it is one of the best real estate options in India. Our team of
        experienced real estate professionals conducts thorough due diligence to
        ensure that each property we offer meets our high standards for quality,
        value, and potential returns. By investing in hBits, investors can
        diversify their portfolios, benefit from professional management, and
        enjoy greater liquidity than traditional real estate investments.
        Whether you are a seasoned investor or a newcomer to the world of real
        estate investment, hBits offers a range of investment opportunities that
        can help you achieve your financial goals making it one of the best real
        estate options in India. With our commitment to excellence,
        transparency, and investor satisfaction, hBits is the ideal platform for
        investors looking to invest in real estate with confidence and peace of
        mind.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Benefits of choosing one of the best real estate options in India
      </h2>
      <Text>
        Here are some benefits of choosing one of the best real estate options
        in India:
      </Text>
      <UnorderedList>
        <ListItem>Appreciation in property value over time</ListItem>
        <ListItem>Regular rental income from the property</ListItem>
        <ListItem>Diversification of investment portfolio</ListItem>
        <ListItem>Hedge against inflation and market volatility</ListItem>
        <ListItem>
          Potential tax benefits on rental income and property ownership
        </ListItem>
        <ListItem>Long-term wealth creation opportunities</ListItem>
        <ListItem>Professional property management services</ListItem>
        <ListItem>
          Access to prime locations and high-quality properties
        </ListItem>
        <ListItem>Greater liquidity through fractional ownership</ListItem>
      </UnorderedList>
      <Text>
        Choosing the best real estate options in India can offer investors a
        range of benefits, including financial stability, diversification, and
        the potential for long-term wealth creation. With professional
        management and access to prime locations and high-quality properties,
        investors can enjoy a reliable and lucrative passive income stream while
        benefiting from the appreciation in property value over time.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Things to consider while choosing real estate investment companies in
        India
      </h2>
      <Text>
        Investing in real estate requires careful consideration and research of
        real estate investment companies in India.
      </Text>
      <UnorderedList>
        <ListItem>
          When choosing a real estate investment company in India, consider
          their track record and reputation by researching successful
          investments, and reading online reviews and testimonials.{' '}
        </ListItem>
        <ListItem>
          Evaluate the team's expertise in real estate investments including
          qualifications, experience, and knowledge of market trends.
        </ListItem>
        <ListItem>
          Look for companies that offer diverse investment options with proven
          returns.
        </ListItem>
      </UnorderedList>
      <h2 style={{ marginTop: '20px' }}>
        Transparency and communication of the best real estate investment
        companies in India
      </h2>
      <Text>
        {' '}
        Best real estate investment companies in India distinguish themselves by
        their unwavering dedication to transparency and communication, which
        they recognize as critical to thriving in a fiercely competitive market.
        To this end, they prioritize forthright and open communication, keeping
        all stakeholders informed at every stage. They believe that transparency
        forms the bedrock of strong and lasting relationships, and leave no
        stone unturned in providing accurate and timely information to clients
        regarding their investments. Moreover, these companies take transparency
        to the next level by welcoming scrutiny of their decision-making
        processes. Clients and investors are encouraged to pose questions and
        provide feedback, which the companies take seriously. Such two-way
        communication nurtures a sense of partnership between the company and
        clients, fostering an atmosphere where all feel heard and valued. This
        commitment to transparency and communication is no mere advertising ploy
        for these real estate investment companies. It is a core value that
        permeates every aspect of their business. They understand that building
        a solid reputation requires continuous effort and attention, and by
        prioritizing transparency and communication, they instill confidence in
        their clients and investors about investing in the Indian real estate
        market.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        What are Online Real Estate Platforms?
      </h2>
      <Text>
        Online real estate platforms like hBits have transformed the{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/preleased-property'}
        >
          real estate investment
        </Link>{' '}
        landscape by leveraging technology to provide investors with greater
        convenience, access, and transparency. Our platform offers fractional
        ownership of carefully selected, high-quality properties, enabling
        investors to invest in real estate with lower entry costs and reduced
        risks. Our team of experienced professionals conducts thorough due
        diligence to ensure that each property we offer meets our high standards
        for quality, value, and potential returns. With hBits, investors can
        benefit from professional management services, access to prime
        locations, and greater liquidity compared to traditional real estate
        investment. Investing in real estate through online real estate
        platforms provides investors with a range of benefits, including
        diversification, the potential for long-term wealth creation, and stable
        passive income streams.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Benefits of using online real estate platforms
      </h2>
      <Text>Here are some benefits of using online real estate platforms:</Text>
      <UnorderedList>
        <ListItem>
          Lower entry costs and reduced risks through fractional ownership
        </ListItem>
        <ListItem>
          Access to prime locations and high-quality properties
        </ListItem>
        <ListItem>
          Professional management services and reliable passive income streams
        </ListItem>
        <ListItem>
          Greater liquidity compared to traditional real estate investment
        </ListItem>
        <ListItem>Diversification of the portfolio of investments</ListItem>
        <ListItem>
          Transparency and easy access to investment information
        </ListItem>
        <ListItem>
          Convenient online investment process from anywhere in the world
        </ListItem>
        <ListItem>Protection from market volatility and inflation</ListItem>
      </UnorderedList>
      <h2 style={{ marginTop: '20px' }}>
        Features and tools offered by online real estate platforms
      </h2>
      <Text>
        Online real estate platforms offer a range of features and tools that
        make real estate investment more accessible, convenient, and profitable
        for investors. Some of the key features and tools offered include:
      </Text>
      <UnorderedList>
        <ListItem>
          Fractional ownership of carefully selected, high-quality properties
        </ListItem>
        <ListItem>
          Professional property management services for hassle-free ownership
        </ListItem>
        <ListItem>
          Transparent and secure online investment process from anywhere in the
          world
        </ListItem>
        <ListItem>
          Access to prime locations and high-quality properties
        </ListItem>
        <ListItem>
          Portfolio diversification through fractional ownership
        </ListItem>
        <ListItem>
          Automated reporting and analytics tools to monitor investment
          performance
        </ListItem>
        <ListItem>Built-in risk management tools and strategies</ListItem>
        <ListItem>
          Customizable investment plans to suit individual investor needs
        </ListItem>
        <ListItem>
          Easy online account management and investor support services
        </ListItem>
        <ListItem>
          Potential for stable passive income streams and long-term wealth
          creation
        </ListItem>
      </UnorderedList>
      <Text>
        With these features and tools, online real estate platforms provide
        investors with a profitable and reliable real estate investment
        experience.
      </Text>
      <h2 style={{ marginTop: '20px' }}>Frequently Asked Questions</h2>
      <h3 style={{ marginTop: '20px' }}>
        Q1. What are the costs associated with fractional ownership real estate?
      </h3>
      <Text>
        The costs associated with fractional ownership real estate typically
        include initial purchase or acquisition fees, as well as ongoing
        maintenance and management fees for the property. These costs can be
        shared among the co-owners according to their respective fractional
        shares of ownership. Additionally, fractional owners may be responsible
        for property taxes and insurance costs associated with the real estate.
        It is important for potential fractional owners to carefully review the
        terms and conditions of their ownership agreement and assess whether the
        anticipated benefits of co-ownership outweigh any potential financial
        burdens associated with it.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q2. How is the return on investment calculated in fractional ownership
        real estate?
      </h3>
      <Text>
        In fractional ownership real estate, the return on investment is
        calculated based on the percentage of ownership held by the investor. To
        calculate the return on investment, the investor needs to consider both
        the rental income and the potential capital appreciation of the
        property. To determine the overall return on investment, the rental
        income and potential capital appreciation are combined, and any expenses
        such as property management fees and maintenance costs are subtracted.
        The resulting figure represents the net return on investment for the
        investor's share of the property.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q3. What types of properties can be owned through fractional property
        investment?
      </h3>
      <Text>
        Fractional property investment is a type of real estate investment where
        multiple investors pool their resources to purchase and own a portion or
        fraction of a property. Typically, fractional property investment is
        used for high-value properties such as vacation homes,{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/commercial-real-estate-investment'}
        >
          commercial real estate
        </Link>
        , and luxury residential buildings. Investors can own fractions of these
        properties and earn returns on their investment, either through rental
        income or capital gains when the property is sold.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q.4 Are there any risks associated with investing in fractional property
        investment?
      </h3>
      <Text>
        Fractional property investment, like any other investment, carries
        certain risks that investors need to be aware of. These risks include
        market fluctuations, changes in regulations or zoning laws, potential
        property management issues, and unexpected expenses related to repairs
        or renovations. Furthermore, fractional ownership may not provide the
        same level of control as owning a property outright, and investors may
        have limited decision-making power regarding the property. Therefore, it
        is important for investors to perform thorough due diligence and
        carefully evaluate the risks before investing in fractional property
        investment.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q5. How long should I hold my fractional real estate investment?
      </h3>
      <Text>
        The length of time to hold a fractional real estate investment depends
        on various factors such as financial goals and market conditions.
        Typically, real estate investments are considered long-term and held for
        at least 3-5 years for potential appreciation in value and rental
        income. However, holding periods may vary depending on individual
        circumstances. The decision of how long to hold a fractional real estate
        investment should be based on careful analysis and consideration of
        various factors.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q6. Can I sell my fractional real estate investment?
      </h3>
      <Text>
        Yes, in most cases you can sell your fractional real estate investment.
        However, the process and requirements for selling fractional real estate
        ownership can vary depending on the specific investment type and
        provider. It is important to carefully review the terms and conditions
        of your fractional real estate investment, as well as any applicable
        laws or regulations.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q7. What are the best real estate investment options in India?
      </h3>
      <Text>
        The best real estate investment options in India vary based on
        individual financial goals and risk tolerance. Popular options include
        investing in residential or commercial properties, real estate mutual
        funds, REITs, and fractional ownership. It's important to evaluate each
        option and consult with a financial advisor before making any investment
        decisions.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q8. How much money do I need if I choose the best real estate investment
        options in India?
      </h3>
      <Text>
        Determining how much money is needed to invest in real estate in India
        depends on various factors, such as location, type of investment, and
        market conditions. Some real estate investment options in India are
        affordable, while others require significant capital. However, you can
        start investing in real estate in India for as low as Rs.25 lakhs. It is
        recommended to consult with a financial advisor or real estate
        professional who can analyze your financial situation and investment
        goals, and provide tailored advice on the best real estate investment
        options in India that suit your needs.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q9. What services do online real estate platforms offer?
      </h3>
      <Text>
        Online real estate platforms offer property information, tools for
        investment analysis and management, and property search and purchase
        options. Some platforms also provide property management services and
        financing options. The goal is to simplify the real estate investment
        process for investors.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q10. What types of properties are available on online real estate
        platforms?
      </h3>
      <Text>
        Online real estate platforms typically offer a wide variety of
        properties, ranging from residential apartments, villas, and townhouses
        to commercial properties like office spaces, retail shops, and
        industrial properties. Some platforms also offer the opportunity to
        invest in land, and others may specialize in certain types of
        properties, such as student housing or vacation rentals. The exact types
        of properties available may vary depending on the platform and the
        region or market being served.
      </Text>
    </Flex>
  );
};

export default Footerdata;
