import { client } from './NetworkHandler';
import { HTTPContentType, HTTPHeader } from '../enums/HttpConstants';

export const setAccessToken = (accessToken: string) => {
  client.defaults.headers.common[
    HTTPHeader.AUTHORIZATION
  ] = `Bearer ${accessToken}`;
  client.defaults.headers.common[HTTPHeader.ACCEPT] =
    HTTPContentType.APPLICATION_JSON;
  client.defaults.headers.common[HTTPHeader.CONTENT_TYPE] =
    HTTPContentType.APPLICATION_JSON;
};
export const clearAccessToken = async () => {
  delete client.defaults.headers.common[HTTPHeader.AUTHORIZATION];
};
