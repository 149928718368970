import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import colors from 'apps/customer-portal-web/src/theme/colors';
import { CrossIcon } from 'apps/customer-portal-web/src/assets/customIcons';
import CustomButton from 'apps/customer-portal-web/src/components/CustomButton';

interface EOIStep1Props {
  setFullPayValue?: any;
}

const FullPay3: FC<EOIStep1Props> = ({ setFullPayValue }) => {
    
  return (
    <>
      <Flex width="100%" flexDirection="column" pl={[0, 0, 3, 3]} mt={[3, 3, 0, 0]}>
        <Flex width="70%" borderBottomWidth="0.5px" borderBottomColor={colors.blackColor} pb={3} alignItems="center">
          <CrossIcon />
          <Text as="b" fontSize="14px" color={colors.grey200} ml="10px">
            Payment Failed!
          </Text>
        </Flex>
        <Flex mt="20px" flexDirection="column">
          <Text mt="10px" fontSize="13px" color={colors.grey} as="b">
            Please press PROCEED to continue payment!
          </Text>
        </Flex>
        <Flex mt={12} flexDirection="column">
          <CustomButton
            btnText="PROCEED"
            btnPressed={() => setFullPayValue(0)}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default FullPay3;
