import * as React from 'react';

const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30}>
    <defs>
      <style>{'.a{fill:#535353}'}</style>
    </defs>
    <path
      className="a"
      d="M20.295 18.705 16.589 15l3.705-3.705a1.124 1.124 0 0 0-1.589-1.589L15 13.411l-3.705-3.705a1.124 1.124 0 0 0-1.589 1.589L13.411 15l-3.705 3.705a1.086 1.086 0 0 0 0 1.589 1.116 1.116 0 0 0 1.589 0L15 16.589l3.705 3.705a1.129 1.129 0 0 0 1.589 0 1.116 1.116 0 0 0 .001-1.589Z"
    />
    <path
      className="a"
      d="M15 2.019a12.976 12.976 0 1 1-9.18 3.8 12.895 12.895 0 0 1 9.18-3.8M15 0a15 15 0 1 0 15 15A15 15 0 0 0 15 0Z"
    />
  </svg>
);

export default CloseIcon;
