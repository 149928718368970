import React from 'react';
import { Colors } from '@hBits.Core';
import { FC } from 'react';
import { Circle, Flex, Text } from '@chakra-ui/react';

interface CustomStepperProps {
  currentStep: number;
  stepKeys?: any;
  outerWidth?: any;
}
const CustomStepper: FC<CustomStepperProps> = (props) => {
  const { currentStep, stepKeys, outerWidth=['90%', '90%', '60%', '30%'] } = props;
  

  return (
    <Flex width={outerWidth} justify="space-between">
      {stepKeys.map((item: any, idx: number) => (
        <Flex
          flexDirection={'column'}
          justifyContent="space-between"
          alignItems={'flex-start'}
          pb={3}
          borderTopColor={
            idx + 1 <= currentStep ? Colors.buttonColor : '#A5A5A5'
          }
          borderTopWidth={
            idx + 1 === stepKeys.length
              ? '0px'
              : idx + 1 <= currentStep
              ? '2px'
              : '1px'
          }
          flex={1}
          pr={idx + 1 === stepKeys.length - 1 ? 2 : 0}
        >
          <Circle
            bg={currentStep === idx + 1 ? '#1a295b' : 'white'}
            color={currentStep === idx + 1 ? 'white' : '#1a295b'}
            my={'-25px'}
          >
            {idx + 1 <= currentStep ? item.activeIcon : item.inactiveIcon}
          </Circle>
          <Text
            color={currentStep >= idx + 1 ? '#1a295b' : '#A5A5A5'}
            fontSize="14px"
            as="b"
            w={{base: "200%", md:'100%'}}
            textAlign={{base: 'left', md:'left'}}
            mt={{base: '30px', md:'0px'}}
            ml={item.marginLeft}
          >
            {item.title}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default CustomStepper;
