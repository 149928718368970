import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Button, ChakraProvider } from '@chakra-ui/react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Text,
} from '@chakra-ui/react';

import closeCircle from '../../assets/svg/close-circle-outline.svg';
import { Colors } from 'libs/src/theme';
import accountManager from 'libs/src/auth/account-manager';
import { Environment } from 'libs/src/services/EnvironmentHelper';
import { authTokenStorageProvider } from 'libs/src/auth';
import { clearAccessToken } from 'libs/src/services/AuthHelper';

export interface AthorizedModalProps {
  isOpen: boolean;
  onModalClose?: any;
}

const UnAuthorizedUserModal: FunctionComponent<AthorizedModalProps> = ({
  isOpen,
  onModalClose,
}): any => {
  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );
  const sessionExpired = () => {
    accountManager.clearAccountId();
    accountManager.clearLogoutFlag();
    clearAccessToken();
    authTokenStorageProvider.clearAuthToken();
    window.location.assign(landingPagesHostingRoot + '/');
  };
  return (
    <ChakraProvider>
      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        isCentered={true}
        size={'2xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justifyContent={'center'} pr={5}>
              <Text fontSize="3xl" ml={5} mt={'20px'}>
                Session has expired
              </Text>
              <Flex
                onClick={onModalClose}
                position="absolute"
                top="0"
                right="0"
                mr={5}
                mt={5}
                cursor="pointer"
              >
                <img src={closeCircle} />
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              mb={'20px'}
            >
              <Text fontSize={'14px'} as="b" color={Colors.headerBlue}>
                Sorry, your request could not be processed
              </Text>
              <Text fontSize={'14px'} as="b" color={Colors.headerBlue}>
                your session has expired please press 'OK' and login again
              </Text>
              <button
                style={{
                  backgroundColor: Colors.buttonColor,
                  borderRadius: '5px',
                  padding: '10px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  marginTop: '20px',
                }}
                onClick={() => {
                  sessionExpired();
                }}
              >
                OK
              </button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};
export default UnAuthorizedUserModal;
