enum AddressProofType {
  Aadhar = 'Aadhar',
  Passport = 'Passport',
  GstCertificate = 'GstCertificate',
  TRC = 'TRC',
  DrivingLicense = 'DrivingLicense',
  VoterId = 'VoterId',
  Others = 'Others',
}

enum BankAccountType {
  Current = 'Current',
  Savings = 'Savings',
  NRO = 'NRO',
}

enum KycProofStatus {
  Verified = 'Verified',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

enum DematStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Verified = 'Verified',
  Rejected = 'Rejected',
  Updated = 'Updated',
}

enum KycStatus {
  KycNotStarted = 'KycNotStarted',
  KycInProgress = 'KycInProgress',
  KycSubmitted = 'KycSubmitted',
  KycUpdated = 'KycUpdated',
  KycVerificationInProgress = 'KycVerificationInProgress',
  KycVerified = 'KycVerified',
  KycInvalidated = 'KycInvalidated',
}

enum ResidentialStatus {
  RI = 'RI',
  NRI = 'NRI',
}

enum AccountType {
  Individual = 'Individual',
  Huf = 'Huf',
  Private = 'Private',
  LLP = 'LLP',
  Partnership = 'Partnership',
  Joint = 'Joint',
  Trust = 'Trust',
}

enum AddressType {
  Communication = 'Communication',
  Permanent = 'Permanent',
}

enum HolderType {
  Primary = 'Primary',
  Second = 'Second',
  Third = 'Third',
}

enum AddressBelongsTo {
  Main = 'Main',
  Representative = 'Representative',
}

enum KycProofType {
  Image = 'Image',
  Word = 'Word',
  Pdf = 'Pdf',
}

enum EKycProofType {
  Pancard = 'pan',
  Passport = 'passport',
  Aadhaar = 'aadhaar',
  DrivingLicense = 'dl',
  GstCertificate = 'GstCertificate',
  VoterId = 'voterId',
}

enum DecentroEKycProofType {
  Pancard = 'PAN_COMPARE',
  OnlyPancard = 'PAN',
  DrivingLicense = 'DRIVING_LICENSE',
  Passport = 'PASSPORT',
  Aadhaar = 'aadhaar',
  GstCertificate = 'GstCertificate',
  VoterId = 'VOTER_ID',
}

export {
  AccountType,
  AddressBelongsTo,
  AddressProofType,
  AddressType,
  BankAccountType,
  DecentroEKycProofType,
  EKycProofType,
  HolderType,
  KycProofStatus,
  KycProofType,
  KycStatus,
  ResidentialStatus,
  DematStatus,
};
