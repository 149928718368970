enum AUM {
  // Asset Under Management
  upTo20Crore = 'Upto 20 Crore',
  Rs21To50Crore = '₹21 - ₹50 Crore',
  Rs51To100Crore = '₹51 - ₹100 Crore',
  Rs101To500Crore = '₹101 - ₹500 Crore',
  MoreThan50Crore = '₹500 + Crore',
}

export default AUM;
