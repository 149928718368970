import { Box, Progress, Text } from '@chakra-ui/react';
import { NUM_FORMATTING } from 'apps/landing-pages-web/src/components/common/utils/helper';
import { useSelector } from 'react-redux';

interface ProgessProps {
  investmentAmountEoi: any;
  from: string;
  getInitialValues: any;
}

const PaymentProgress = ({ investmentAmountEoi, from }: ProgessProps) => {
  const {
    eoiInvestmentAmontPaid,
    investmentAmount,
    minInvestmentAmountToBePaidAtEOI,
    percentageInvestmentAmountToBePaidAtEOIApplied,
  } = investmentAmountEoi;

  const investmentModal = useSelector(
    (state: any) => state.InvestmentReducer?.propertyData,
  );

  const { totalInvestmentAmount, totalAmountPaidPercentage, topUpAmountEoi } =
    investmentModal;

  const PaidPercentage = (eoiInvestmentAmontPaid / investmentAmount) * 100;
  const minimumPaidPercentage = (
    (eoiInvestmentAmontPaid / minInvestmentAmountToBePaidAtEOI) *
    100
  ).toFixed(2);

  const topUpPercentage =
    (topUpAmountEoi?.eoiInvestmentAmontPaid /
      topUpAmountEoi?.minInvestmentAmountToBePaidAtEOI) *
    100;

  return (
    <Box className="bg-[#F0F0F0] px-4 py-1 rounded-[10px]">
      <Text className="mb-2 text-[16px] font-medium text-black">
        {!!eoiInvestmentAmontPaid
          ? `Payment done so far by you ${
              from === 'eoi'
                ? PaidPercentage.toFixed(2)
                : totalAmountPaidPercentage.toFixed(2)
            }% of (₹${NUM_FORMATTING(totalInvestmentAmount)})`
          : `Initial payment of ${percentageInvestmentAmountToBePaidAtEOIApplied}% is advised to start the process`}
      </Text>
      <Box>
        <Box className="">
          <Progress
            colorScheme="yellow"
            bg={'#1A295B'}
            size="sm"
            // value={Number(`${from === 'eoi' ? minimumPaidPercentage : totalAmountPaidPercentage.toFixed(2) }`)}
            value={Number(
              `${
                from !== 'topup'
                  ? from === 'eoi'
                    ? minimumPaidPercentage
                    : totalAmountPaidPercentage.toFixed(2)
                  : topUpPercentage
              }`,
            )}
            borderRadius={'20px'}
            className="mb-[2px]"
            height="5px"
          />
          {from === 'eoi' && (
            <Text className={`text-[13px] font-medium text-black ml-[5%]`}>
              {`${minimumPaidPercentage || 0}% of (₹${NUM_FORMATTING(
                minInvestmentAmountToBePaidAtEOI,
              )})`}
            </Text>
          )}

          {from === 'fullpayment' && (
            <Text className={`text-[13px] font-medium text-black ml-[5%]`}>
              {`${
                totalAmountPaidPercentage.toFixed(2) || 0
              }% of (₹${NUM_FORMATTING(totalInvestmentAmount)})`}
            </Text>
          )}

          {from === 'topup' && (
            <Text className={`text-[13px] font-medium text-black ml-[5%]`}>
              {`${topUpPercentage.toFixed(2) || 0}% of (₹${NUM_FORMATTING(
                topUpAmountEoi?.minInvestmentAmountToBePaidAtEOI,
              )})`}
            </Text>
          )}
        </Box>
        <Box className="flex flex-row justify-end">
          <Text className="text-[13px] font-medium text-[#FF0000]">
            {`Rest  ${
              from === 'eoi' ? '95' : 100 - Number(PaidPercentage.toFixed(2))
            }% to be paid`}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentProgress;
