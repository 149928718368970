import { Flex, SimpleGrid, Spinner, Text, WrapItem } from '@chakra-ui/react';
import { getListOfProperties } from 'apps/customer-portal-web/src/services/dashboard/UpcomingProperties';
import { useEffect, useState } from 'react';
import AssetListItem from './AssetListItem';

const Assets = () => {
  const [propertyList, setPropertyList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getListOfProperties().then((res) => {
      setPropertyList(res);
      setIsLoading(false);
    });
  }, []);

  return (
    <Flex ml={[4, 6]} mr={4} direction={'column'}>
      <Text fontWeight={'bold'}>Assets</Text>
      {isLoading ? (
        <Flex h={'40vh'} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Flex>
      ) : (
        <SimpleGrid columns={[1, 2, 3, 3]} spacing="20px" mt={6}>
          {propertyList.map((property, index) => (
            <WrapItem w={['auto']} key={index}>
              <AssetListItem property={property} />
            </WrapItem>
          ))}
        </SimpleGrid>
      )}
    </Flex>
  );
};

export default Assets;
