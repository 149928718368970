import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import CrownIcon from 'apps/customer-portal-web/src/assets/CongratulationIcon.svg';

interface DashboardWelcomeNote {
  isOpen: boolean;
  onClose?: any;
}
const DashboardWelcomeNote: FC<DashboardWelcomeNote> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <>
      <Box tabIndex={-1} aria-label="Focus moved to this box">
        Some other content that'll receive focus on close.
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={'xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              alignItems={'center'}
              justifyContent="center"
              flexDirection={'column'}
              mt={'40px'}
              px={'30px'}
            >
              <img src={CrownIcon} />
              <Text fontSize={'24px'} as="b" color="#1A295B" mt={'10px'}>
                Congratulations!
              </Text>
              <Text
                textAlign={'center'}
                color="#535353"
                fontSize={'16px'}
                as="b"
                mt={'10px'}
              >
                It's great to have you as part of the hBits family, the most
                experienced and leading fractional ownership platform in the
                country. Thank you for choosing us!
              </Text>
              <Text
                textAlign={'center'}
                mt={'25px'}
                fontSize={'16px'}
                mb={'40px'}
              >
                You will find all your Documents related to this property in the{' '}
                <span style={{ color: '#1A295B', fontWeight: 'bold' }}>
                  Documents{' '}
                </span>{' '}
                section
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DashboardWelcomeNote;
