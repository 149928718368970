import endpoints from 'libs/src/config/endpoints';

import { HTTPMethod, HTTPStatusCode } from 'libs/src/enums';
import { fetchAsync } from '@hBits.Core';

export const getPas4EoiDocumentUrl = async (
  investmntId: string,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.pas4EoiDocumentUrl.replace('#{investmentId}', investmntId),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getPas4EoiDocumentStatus = async (
  investmntId: string,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.pas4EoiDocumentStatus.replace(
        '#{investmentId}',
        investmntId,
      ),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};
