import { Flex, Img, Text } from '@chakra-ui/react';
import { Colors, Environment, Strings } from '@hBits.Core';
import {
  convertDate,
  getCurrentDate,
} from 'apps/customer-portal-web/src/utils';
import { ArticleContainer, ReadMoreLink } from '../../../styled-components';

const UpcomingPropertyItem = (props: any) => {
  const { property } = props;
  const landingPage = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );
  return (
    <ArticleContainer>
      <Flex flex={5} justifyContent={'center'}>
        <Img
          borderRadius={'5px'}
          height={100}
          width={120}
          src={
            property.propertyMediaModel?.propertyBannerBox[0]?.imageUrl
              ? property.propertyMediaModel?.propertyBannerBox[0]?.imageUrl
              : property.propertyMediaModel?.propertyBannerBox[0]
          }
        />
      </Flex>
      <Flex flex={7} direction={'column'} gap={'0.1rem'}>
        <Text fontWeight={'bold'} fontSize={'lg'} color={Colors.textGreyColor}>
          {property.displayName}
        </Text>
        <Text color={Colors.textGreyColor}>
          {property.locations[0]?.city}, {property.locations[0]?.area}
        </Text>
        {true && (
          <Text
            color={Colors.darkRed}
            mt={2}
            fontWeight={'medium'}
            fontSize={'sm'}
          >
            {new Date(property?.propertyInvestment?.expectedGoLiveDate) >
            getCurrentDate()
              ? `${Strings.fundingStartsOn} ${convertDate(
                  new Date(property?.propertyInvestment?.expectedGoLiveDate),
                )}`
              : property?.propertyInvestment
                  ?.percentagePropertyFundedDisplayedToUser < 100
              ? Strings.openForInvestment
              : 'Closed'}
          </Text>
        )}

        <ReadMoreLink href={`${landingPage}/properties/${property.id}`}>
          View Details
        </ReadMoreLink>
      </Flex>
    </ArticleContainer>
  );
};

export default UpcomingPropertyItem;
