import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPStatusCode } from 'libs/src/enums';
import { HTTPMethod } from 'libs/src/enums';
import { fetchAsync } from '../../NetworkHelper';

import { authTokenStorageProvider } from 'libs/src/auth';

export const getLinkedAccount = async (): Promise<any> => {
  const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
  try {
    const request = {
      method: HTTPMethod.GET,
      url:
        baseApiUrl +
        endpoints.accounts +
        authTokenModel?.accountId +
        endpoints.linkedAccounts,
    };
    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};
