import { Field } from 'formik';
import React from 'react';

const BotCheckbox = () => {
  return (
    <div
      id="terms"
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: -20,
        opacity: 0.01,
      }}
    >
      <Field
        type="checkbox"
        data-test="terms_conditons"
        name={'checkedState'}
        style={{ cursor: 'pointer' }}
      />
      <label style={{ fontSize: 10 }}>
        *Please accept terms and conditons below
      </label>
    </div>
  );
};

export default BotCheckbox;
