export class Environment {
  private static instance: Environment;
  private state = {};

  private constructor() {}

  public setEnvironment(env: any) {
    this.state = env;
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): Environment {
    if (!Environment.instance) {
      Environment.instance = new Environment();
    }

    return Environment.instance;
  }

  getParam = (key: string) => {
    return (this.state as any)[key];
  };
}
