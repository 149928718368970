import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux';
import rootReducer from './reducer';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { logger } from 'redux-logger';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const blackListTransform = createTransform(null, () => {}, {});

export const config = {
  key: 'root',
  storage,
  transforms: [blackListTransform],
  whitelist: [],
};
const persisted = persistReducer(config, rootReducer);

const middlewares = [thunk as ThunkMiddleware];
// eslint-disable-next-line dot-notation
const isDevMode = process.env['NODE_ENV'] === 'development';
if (isDevMode) {
  middlewares.push(logger);
}
// Create a store out of this configuration
const store = createStore(
  persisted,
  compose(
    applyMiddleware(...middlewares),
    isDevMode && (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f,
  ),
);

const persistor = persistStore(store);
export { persistor, store };

export type AppDispatch = typeof store.dispatch;
