import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPStatusCode } from 'libs/src/enums';
import { authTokenStorageProvider } from 'libs/src/auth';
import { fetchAsync } from 'libs/src/services/NetworkHelper';
import { setAccessToken } from 'libs/src/services/AuthHelper';

export const verifyEmailOTP = async (userData: object) => {
  //invitationCode to be added
  try {
    const request = {
      method: 'post',
      data: userData,
      url: baseApiUrl + endpoints.customers + endpoints.emailVerify,
    };

    const response = await fetchAsync(request);
    console.log(response);
    return response;
  } catch (_error) {
    return null;
  }
};

export const resendEmailOTP = async (registrationData: object) => {
  try {
    const request = {
      method: 'put',
      data: registrationData,
      url: baseApiUrl + endpoints.customers + endpoints.emailResend,
    };

    const response = await fetchAsync(request);
    return response;
  } catch (_error) {
    return null;
  }
};

export const initializeEmailOtp = async (userData: any) => {
  try {
    const request = {
      method: 'post',
      data: userData,
      url: baseApiUrl + endpoints.customers + endpoints.emailStart,
    };

    const response = await fetchAsync(request);
    return response;
  } catch (_error) {
    return null;
  }
};
