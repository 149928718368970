import { Checkbox, Flex, Text, useToast } from '@chakra-ui/react';
import AddressForm from '../HelperComponents/AddressForm';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import CustomPageBottom from '../HelperComponents/CustomPageBottom';
import colors from 'libs/src/theme/colors';
import AddressProofTile from '../HelperComponents/AddressProofTile';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileUpload from '../HelperComponents/FileUpload';
import { InputTile } from '../HelperComponents/CustomInputTile';
import CustomPanCardTile from '../HelperComponents/CustomPanCardTile';
import {
  SetMultipleAddressProofDetails,
  setProofValues,
} from '../HelperComponents/SetMultipleAddressProofDetails';
import accountIdManager from 'libs/src/auth/account-manager';
import {
  checkMultipleDocumentsErrors,
  getHolderTypeName,
  toastMessage,
} from '../utils';
import {
  addAddressDetails,
  updateAddressDetails,
} from 'apps/customer-portal-web/src/services/Kyc/addressDetails';
import {
  AddressProofType,
  AddressType,
  HTTPStatusCode,
  KycStatus,
  Strings,
} from '@hBits.Core';
import { getKycData } from 'apps/customer-portal-web/src/services/Kyc/getKycData';
import {
  ADDRESS_HOLDER_NUMBER,
  ADDRESS_PERMANENT_HOLDER_NUMBER,
  ADDRESS_PROOF_HOLDER_NUMBER,
  ADDRESS_RECORD_NUMBER,
} from 'apps/customer-portal-web/src/store/constants/ReduxConstants';

interface MainAddressDetailsInt {
  country: string;
  pincode: string;
  fullAddress: string;
  landmark: string;
  state: string;
  city: string;
  isPermanentSame: boolean;
  permanentCountry: string;
  permanentPincode: string;
  permanentFullAddress: string;
  permanentLandmark: string;
  permanentState: string;
  permanentCity: string;
  panHolderName: string;
  panNumber: string;
  panIdProofUrl: string;
  panIdProofType: string;
  panRejectionReason: string;
  aadharNumber: string;
  aadharProofFrontUrl: string;
  aadharProofBackUrl: string;
  aadharProofType: string | undefined;
  gstCertificateNumber: string;
  gstCertificateProofUrl: string;
  gstCertificateProofType: string | undefined;
  passportNumber: string;
  passportProofUrl: string;
  passportProofType: string | undefined;
  passportRejectionReason: string;
  trcNumber: string;
  trcProofUrl: string;
  trcProofType: string | undefined;
  drivingLicenseNumber: string;
  drivingLicenseProofUrl: string;
  drivingLicenseProofType: string | undefined;
  otherDocumentNumber: string;
  otherDocumentProofUrl: string;
  otherDocumentProofType: string | undefined;
  addressProofTypes: any;
}

interface MainAddressDetailsProps {
  isIndividual?: boolean;
  isJoint?: boolean;
  backPanNameText?: string;
  holderNumber: number;
}
let tempAddressHolderNumber = { 0: -2, 1: -2, 2: -2 },
  tempAddressProofHolderNumber = { 0: -2, 1: -2, 2: -2 },
  tempAddressPermanentHolderNumber = { 0: -2, 1: -2, 2: -2 };

const MainAddressDetails = (props: MainAddressDetailsProps) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const selectorState = useSelector((state: any) => state.KycReducer);
  const {
    isNri,
    holderId,
    tabValue,
    kycStatusForHolder,
    kycStatusForAccount,
    addressRecordNumber,
    isAllFieldsDisable,
    addressHolderNumber,
    addressProofHolderNumber,
    addressPermanentHolderNumber,
  } = selectorState;
  const {
    isIndividual = false,
    isJoint = false,
    backPanNameText = '',
    holderNumber,
  } = props;
  const [addressProofDetails, setAddressProofDetails] = useState<any>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<any>(['']);
  const [mainAddressDetailsData, setMainAddressDetailsData] = useState<any>();
  const [saveAndContinueClicked, setSaveAndContinueClicked] =
    useState<boolean>(false);
  const [aadharProofBackUrl, setAadharProofBackUrl] = useState<string>();
  const [schemaState, setSchemaState] = useState<boolean>(
    !(isIndividual || isJoint),
  );
  const getAddressDetails = async () => {
    const response = await getKycData(accountIdManager?.getAccountId());
    if (response?.status === HTTPStatusCode.OK) {
      if (!mainAddressDetailsData) {
        setMainAddressDetailsData(response?.data?.holders[holderNumber]);
      }
    }
  };
  useEffect(() => {
    getAddressDetails();
  }, []);

  useEffect(() => {
    setApiValues();
  }, [mainAddressDetailsData]);

  const setApiValues = () => {
    for (let i = 0; i < mainAddressDetailsData?.addressDetails?.length; i++) {
      if (
        mainAddressDetailsData?.addressDetails[i]?.addressBelongsTo ==
        Strings.main
      ) {
        if (
          mainAddressDetailsData?.addressDetails[i]?.addressType ==
          AddressType.Communication
        ) {
          if (tempAddressHolderNumber[holderNumber] != -2) {
            tempAddressHolderNumber[holderNumber] = i;
            tempAddressProofHolderNumber[holderNumber] = i - 1;
            tempAddressPermanentHolderNumber[holderNumber] = i - 1;
          }
          if (tempAddressHolderNumber[holderNumber] == -2)
            tempAddressHolderNumber[holderNumber] = i;
        }
        if (
          mainAddressDetailsData?.addressDetails[i]?.addressType ==
          AddressType.Permanent
        ) {
          if (tempAddressHolderNumber[holderNumber] == -2) {
            tempAddressHolderNumber[holderNumber] = i;
          }
          tempAddressProofHolderNumber[holderNumber] = i;
          if (tempAddressHolderNumber[holderNumber] != i) {
            tempAddressPermanentHolderNumber[holderNumber] = i;
            dispatch({
              type: ADDRESS_RECORD_NUMBER,
              payload: {
                ...addressRecordNumber,
                [holderNumber]: 2,
              },
            });
          }
        }
      }
    }
    dispatch({
      type: ADDRESS_HOLDER_NUMBER,
      payload: tempAddressHolderNumber,
    });
    dispatch({
      type: ADDRESS_PROOF_HOLDER_NUMBER,
      payload: tempAddressProofHolderNumber,
    });
    dispatch({
      type: ADDRESS_PERMANENT_HOLDER_NUMBER,
      payload: tempAddressPermanentHolderNumber,
    });
  };

  useEffect(() => {
    if (addressProofHolderNumber[holderNumber] != -2) {
      setAadharProofBackUrl(
        mainAddressDetailsData?.addressDetails[
          addressProofHolderNumber[holderNumber]
        ]?.aadharProofBackUrl,
      );
      const addressProofTypes =
        mainAddressDetailsData?.addressDetails[
          addressProofHolderNumber[holderNumber]
        ]?.addressProofTypes;
      const response =
        mainAddressDetailsData?.addressDetails[
        addressProofHolderNumber[holderNumber]
        ];
      setAddressProofDetails(
        SetMultipleAddressProofDetails(
          addressProofTypes,
          addressProofDetails,
          response,
          isNri[holderNumber],
          true,
        )?.[0],
      );
      setSelectedDocuments(
        SetMultipleAddressProofDetails(
          addressProofTypes,
          addressProofDetails,
          response,
          isNri[holderNumber],
          true,
        )?.[1],
      );
    }
  }, [addressProofHolderNumber[holderNumber], mainAddressDetailsData]);

  const submitBtnClicked = () => {
    setSaveAndContinueClicked(true);
  };

  const getInitialValues: () => MainAddressDetailsInt = () => {
    const addressDetails =
      mainAddressDetailsData?.addressDetails[addressHolderNumber[holderNumber]];
    const permanentAddressDetails =
      mainAddressDetailsData?.addressDetails[
      addressPermanentHolderNumber[holderNumber]
      ];
    const proofAddressDetails =
      mainAddressDetailsData?.addressDetails[
      addressProofHolderNumber[holderNumber]
      ];
    let initialValues = {
      country: addressDetails?.country,
      pincode: addressDetails?.pincode,
      fullAddress: addressDetails?.fullAddress,
      landmark: addressDetails?.landmark,
      state: addressDetails?.state,
      city: addressDetails?.city,
      isPermanentSame:
        addressPermanentHolderNumber[holderNumber] === -2 ? true : false,
      permanentCountry: permanentAddressDetails?.country,
      permanentPincode: permanentAddressDetails?.pincode,
      permanentFullAddress: permanentAddressDetails?.fullAddress,
      permanentLandmark: permanentAddressDetails?.landmark,
      permanentState: permanentAddressDetails?.state,
      permanentCity: permanentAddressDetails?.city,
      panHolderName: proofAddressDetails?.panHolderName,
      panNumber: proofAddressDetails?.panNumber,
      panIdProofUrl: proofAddressDetails?.panIdProofUrl,
      panIdProofType: proofAddressDetails?.panIdProofType,
      panRejectionReason: proofAddressDetails?.panRejectionReason,
      aadharNumber: '',
      aadharProofFrontUrl: '',
      aadharProofBackUrl: '',
      aadharProofType: undefined,
      gstCertificateNumber: '',
      gstCertificateProofUrl: '',
      gstCertificateProofType: undefined,
      passportNumber: proofAddressDetails?.passportNumber,
      passportProofUrl: proofAddressDetails?.passportProofUrl,
      passportProofType: proofAddressDetails?.passportProofType,
      passportRejectionReason: proofAddressDetails?.passportRejectionReason,
      trcNumber: '',
      trcProofUrl: '',
      trcProofType: undefined,
      drivingLicenseNumber: '',
      drivingLicenseProofUrl: '',
      drivingLicenseProofType: undefined,
      otherDocumentNumber: '',
      otherDocumentProofUrl: '',
      otherDocumentProofType: undefined,
      addressProofTypes: [],
      addressBelongsTo: Strings.main,
      holderType: getHolderTypeName(holderNumber),
      isNri: isNri[holderNumber],
      schemaState: schemaState,
    };
    return initialValues;
  };
  const AddBasicDetailsSchema = Yup.object().shape({
    country: Yup.string().required('This is required.'),
    pincode: Yup.string()
      .trim()
      .max(20, 'Length should not exceed 20')
      .matches(/^[A-Z0-9 ]+$/, 'Letters(A-Za-z),Digits(0-9) are only allowed')
      .required('This is required.'),
    fullAddress: Yup.string().required('This is required.').trim(),
    landmark: Yup.string().required('This is required.').trim(),
    state: Yup.string().trim().required('This is required.'),
    city: Yup.string()
      .trim()
      .required('This is required.')
      .max(30, 'Length should not exceed 30')
      .matches(
        /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
        'Letters(A-Z, a-z) are only required',
      ),
    isPermanentSame: Yup.boolean(),
    permanentCountry: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string().required('This is required.'),
    }),
    permanentPincode: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string()
        .trim()
        .max(20, 'Length should not exceed 20')
        .matches(/^[A-Z0-9 ]+$/, 'Letters(A-Za-z),Digits(0-9) are only allowed')
        .required('This is required.'),
    }),
    permanentFullAddress: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string().trim().required('This is required.'),
    }),
    permanentLandmark: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string().trim().required('This is required.'),
    }),
    permanentState: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string().trim().required('This is required.'),
    }),
    permanentCity: Yup.string().when('isPermanentSame', {
      is: false,
      then: Yup.string()
        .trim()
        .required('This is required.')
        .max(30, 'Length should not exceed 30')
        .matches(
          /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
          'Letters(A-Z, a-z) are only required',
        ),
    }),
    isNri: Yup.boolean(),
    passportNumber: Yup.string().when('isNri', {
      is: (isNri: boolean) => isNri,
      then: Yup.string().trim().required('This is required.'),
    }),
    passportProofUrl: Yup.string().when('isNri', {
      is: (isNri: boolean) => isNri,
      then: Yup.string().trim().required('This is required.'),
    }),
    schemaState: Yup.boolean(),
    panNumber: Yup.string().when('schemaState', {
      is: (schemaState: boolean) => schemaState,
      then: Yup.string()
        .trim()
        .matches(
          /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
          'Pan No format should be ABCDE1234F',
        )
        .required('This is required.'),
    }),
    panHolderName: Yup.string().when('schemaState', {
      is: (schemaState: boolean) => schemaState,
      then: Yup.string()
        .min(3, 'PAN Name entered is too Short!')
        .matches(
          /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
          '(A-Z a-z)letters only allowed',
        )
        .required('This is required.'),
    }),
    panIdProofUrl: Yup.string().when('schemaState', {
      is: (schemaState: boolean) => schemaState,
      then: Yup.string().trim().required('This is required.'),
    }),
  });

  const checkInvalidatedProofs = (data: any) => {
    if (
      data?.panRejectionReason != undefined ||
      data?.passportRejectionReason != undefined
    ) {
      return false;
    } else {
      if (
        !checkMultipleDocumentsErrors(addressProofDetails, aadharProofBackUrl)
      ) {
        return false;
      }
      return true;
    }
  };
  const onSubmit = async (data: any) => {
    const finalData = {
      ...data,
      passportNumber: isNri[holderNumber] ? data.passportNumber : '',
      passportProofUrl: isNri[holderNumber] ? data.passportProofUrl : '',
    };
    if (finalData.isPermanentSame === false) {
      dispatch({
        type: ADDRESS_RECORD_NUMBER,
        payload: {
          ...addressRecordNumber,
          [holderNumber]: 2,
        },
      });
    }
    setProofValues(addressProofDetails, finalData);
    for (let i = 0; i < addressProofDetails.length; i++) {
      if (
        addressProofDetails[i].documentName === AddressProofType.Passport &&
        addressProofDetails[i].documentRejectionReason === undefined
      ) {
        finalData.passportRejectionReason = undefined;
      }
    }
    finalData.aadharProofBackUrl = aadharProofBackUrl;
    const accountId = accountIdManager?.getAccountId();
    if (checkInvalidatedProofs(finalData)) {
      if (!mainAddressDetailsData?.addressDetails[0]) {
        const addedMainAddressDetailsResponse = await addAddressDetails(
          accountId,
          holderId[holderNumber],
          finalData,
        );
        toastMessage(
          addedMainAddressDetailsResponse,
          toast,
          tabValue[holderNumber] + 1,
          holderNumber,
          dispatch,
          selectorState,
          kycStatusForHolder[holderNumber] === KycStatus.KycInvalidated
            ? true
            : false,
          kycStatusForAccount === KycStatus.KycInvalidated ? true : false,
        );
      }
      if (mainAddressDetailsData?.addressDetails[0]) {
        const updatedMainAddressDetailsResponse = await updateAddressDetails(
          accountId,
          holderId[holderNumber],
          finalData,
        );
        toastMessage(
          updatedMainAddressDetailsResponse,
          toast,
          tabValue[holderNumber] + 1,
          holderNumber,
          dispatch,
          selectorState,
          kycStatusForHolder[holderNumber] === KycStatus.KycInvalidated
            ? true
            : false,
          kycStatusForAccount === KycStatus.KycInvalidated ? true : false,
        );
      }
    }
  };
  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      validationSchema={AddBasicDetailsSchema}
      enableReinitialize={true}
    >
      {({
        handleChange,
        values,
        errors,
        setFieldTouched,
        touched,
        setFieldValue,
      }) => (
        <Form>
          <Flex mt={2} width={'100%'} flex={1} direction={'column'}>
            <Flex direction={'column'} flex={1}>
              <Text fontSize={'16px'}>Communication Address</Text>
              <AddressForm
                errors={errors}
                handleChange={handleChange}
                values={values}
                setFieldTouched={setFieldTouched}
                touched={touched}
                isCommunicationAddress={true}
                holderNumber={holderNumber}
                setFieldValue={setFieldValue}
                saveAndContinueClicked={saveAndContinueClicked}
                isAllFieldsDisable={isAllFieldsDisable[holderNumber]}
              />
              <Text fontSize={'16px'} mt={4}>
                Permanent Address
              </Text>
              <Checkbox
                mt={6}
                onChange={(e) =>
                  setFieldValue('isPermanentSame', e.target.checked)
                }
                disabled={isAllFieldsDisable[holderNumber]}
                _disabled={{ color: 'black' }}
                defaultChecked={true}
                isChecked={values.isPermanentSame}
              >
                <Text fontSize={'12px'} color={colors.greyUserNameColor}>
                  Permanent address same as communication address{' '}
                </Text>
              </Checkbox>
              {!values.isPermanentSame && (
                <AddressForm
                  marginTop={6}
                  errors={errors}
                  handleChange={handleChange}
                  values={values}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                  isCommunicationAddress={false}
                  holderNumber={holderNumber}
                  setFieldValue={setFieldValue}
                  saveAndContinueClicked={saveAndContinueClicked}
                  isAllFieldsDisable={isAllFieldsDisable[holderNumber]}
                />
              )}
              {isNri[holderNumber] && (
                <Flex flexDirection={'column'} w={'100%'} flex={1} mt={4}>
                  <Flex>
                    <InputTile
                      paddingRight={6}
                      setFieldValue={setFieldValue}
                      value={'Passport'}
                      setFieldTouched={setFieldTouched}
                      isDisabled={true}
                      holderNumber={holderNumber}
                    />
                    <InputTile
                      testId='input-passport-number'
                      placeholder="*Passport Number"
                      handleChangeName={'passportNumber'}
                      setFieldValue={setFieldValue}
                      value={values.passportNumber}
                      errors={errors.passportNumber}
                      setFieldTouched={setFieldTouched}
                      touched={touched.passportNumber}
                      isAllLettersCapital={true}
                      holderNumber={holderNumber}
                    />
                  </Flex>
                  <FileUpload
                    text="*Please upload Passport"
                    name="passportProofUrl"
                    nameType="passportProofType"
                    onChange={setFieldValue}
                    values={values.passportProofUrl}
                    errors={errors.passportProofUrl}
                    valuesType={values.passportProofType}
                    rejectedReasonName={'passportRejectionReason'}
                    rejectionReason={values.passportRejectionReason}
                    holderNumber={holderNumber}
                    setFieldTouched={setFieldTouched}
                    touched={touched.passportProofUrl}
                  />
                </Flex>
              )}
              {!(isIndividual || isJoint) && (
                <Flex flexDirection={'column'} w={'100%'} flex={1} mt={6}>
                  <CustomPanCardTile
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    valuePanNumber={values.panNumber}
                    errorPanNumber={errors.panNumber}
                    touchedPanNumber={touched.panNumber}
                    valuePanName={values.panHolderName}
                    errorPanName={errors.panHolderName}
                    touchedPanName={touched.panHolderName}
                    placeholderText={backPanNameText}
                    holderNumber={holderNumber}
                  />
                  <FileUpload
                    text={`*Please upload PAN Card${backPanNameText}`}
                    name="panIdProofUrl"
                    nameType="panIdProofType"
                    onChange={setFieldValue}
                    values={values.panIdProofUrl}
                    errors={errors.panIdProofUrl}
                    valuesType={values.panIdProofType}
                    rejectedReasonName={'panRejectionReason'}
                    rejectionReason={values.panRejectionReason}
                    holderNumber={holderNumber}
                    setFieldTouched={setFieldTouched}
                    touched={touched.panIdProofUrl}
                    testId='for-main-address'
                  />
                </Flex>
              )}
              <AddressProofTile
                addressProofDetails={addressProofDetails}
                setAddressProofDetails={setAddressProofDetails}
                selectedDocuments={selectedDocuments}
                setSelectedDocuments={setSelectedDocuments}
                holderNumber={holderNumber}
                saveAndContinueClicked={saveAndContinueClicked}
                setAadharProofBackUrl={setAadharProofBackUrl}
                aadharProofBackUrl={aadharProofBackUrl}
                dataTestid='main-address'
                testId='for-main-address'
              />
            </Flex>
            <CustomPageBottom
              holderNumber={holderNumber}
              btnClicked={submitBtnClicked}
            />
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default MainAddressDetails;
