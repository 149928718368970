export const faqData = [
  {
    items: [
      {
        title: 'What KYC documents are needed to sign up with Hbits?',
        description:
          'Our KYC process is completely digital. You will require a scan/image of your Aadhar Card and PAN card, along with registered your mobile number.',
      },
      {
        title: 'Do you have a mobile app?',
        description:
          'No. To facilitate secure and easy validation of ownership documents, we prefer all financial documentation to be directed through our website to ensure transparency for all official documents',
      },
      {
        title: 'What happens if a property fails to be fully funded?',
        description:
          "Investor's funds will be reimbursed to their registered bank account if a property on our platform doesn't complete its funding target",
      },
      {
        title: 'What are the risks associated with these investments?',
        description:
          'This is a relatively low risk investment model. Risks associated could include changes In property prices, or renegotiations of rentals. We ensure that all the assets we pick for you are Grade A buildings with top-tier tenants, clean contracts and long commitment periods, to ensure consistent monthly returns for our investors, and choose assets with we believe have a strong potential to appreciate in value over the years. Various risks associated with fractional investments such as liquidity, tenant, and market risks have been mitigated as much as possible with our robust due diligence and management processes',
      },
      {
        title: 'What sort of returns can I expect?',
        description:
          'Hbits expects to see 8-10% yearly rental returns on its listed assets, with an expected IRR of 12-20% over a period of 5 years. ',
      },
      {
        title: 'Is fractional ownership risky?',
        description:
          'Fractional ownership is a lucrative investment option due to its low risk/high return profile. Yes, as with any investment model, it carries inherent risk limited to profit expectations, but the risk is relatively very low compared to most investment options at these expected returns. hBits uses machine learning and blockchain technology to create transparency of information and ease of access, to mitigate these risks as much as possible ',
      },
      {
        title:
          'How can I get specific questions answered on a particular property listing on the platform?',
        description:
          'For asset specific queries please enter your name, contact number and email ID in the enquiry form and one of our representatives will get in touch with you to resolve your queries. ',
      },
      {
        title: 'What is fractional real estate investment?',
        description:
          'Fractional real estate investment is an innovative way to invest in commercial real estate. It refers to the legal sharing of property among multiple owners. The property title is divided in a manner that each investor owns a fraction of the real estate asset. The investors earn monthly rentals and benefit from appreciation in the value of the asset.',
      },
      {
        title: 'What is hBits?',
        description:
          'hBits is a commercial real estate ownership platform that enables its customers to buy a fraction of Grade A assets rented out to multinational companies. At hBits, we choose premium properties in Commercial Real Estate that command higher rentals, attract large Indian and MNC tenants and have the potential for substantial capital appreciation. Please visit <a href="/how-it-works"> How it works </a> to know more.',
      },
      {
        title: 'Who can invest with hBits?',
        description:
          'Any Indian citizen, NRI, company (Pvt Ltd/ Proprietorship), HUF/Trust, cooperative society can invest with us subject to valid KYC and regulatory guidelines. Please contact hBits at&nbsp;<a href="mailto:info@hBits.co">info@hBits.co</a>&nbsp;for any related queries. <br> <b>Note for NRI investors</b>: NRI investors can only invest through an NRO Account or from a normal savings bank account in India. We cannot accept payments from NRE Account or in FDI.',
      },
      {
        title: 'How do I get started as an investor with hBits?',
        description:
          'You can register on our platform by clicking on Sign up. Alternatively, you can click on Enquire now and our experts will guide you through the registration process. Once registered, you will be able to see all the details of the assets listed on our platform.',
      },
      {
        title: 'What process does Hbits follow when acquiring an asset?',
        description:
          'Property listing is the most important process in hBits. We follow a robust, data-driven asset selection process for our investors to make maximum returns on investments. Due to our stringent due diligence and evaluation process, only 2% of the total properties analyzed get listed on our platform. For more details <br> Please visit: <a href="/how-it-works">How it works</a>',
      },
      {
        title: 'Will I need any legal support to make an investment?',
        description:
          'We will make sure we take care of all the legal aspects of your property purchase, digitally and transparently so you can access all the related documents online on your account. hBits will take care of all the due diligence on your behalf. Still, if you want to proceed with your own legal support, kindly reach out to us for further information.',
      },
      {
        title: 'How do you keep my personal information secure?',
        description:
          'hBits uses a highly secure platform to provide ultimate data security and simplify the ease and security of transactions. All the sensitive investor data is encrypted and stored with 256 bit SHA encryption. We also follow Non-Disclosure And Confidentiality Policy.',
      },
      {
        title: 'What is the minimum investment?',
        description:
          'The minimum investment amount differs from property to property. Kindly refer to the respective property page to find the minimum investment amount.',
      },
      {
        title: 'What happens if a property fails to be fully funded?',
        description:
          "Investor's funds will be reimbursed to their registered bank account if a property on our platform doesn't complete its funding target.",
      },
      {
        title: 'Will I need to travel to the location of the property or such?',
        description:
          "No. You don't have to travel for any documentation procedure. All necessary document verification, signatures will be carried out digitally.",
      },
      {
        title: 'Do you offer any guarantee on returns?',
        description:
          'No, hBits does not offer any guarantee on returns. Instead, we advise our potential investors to be cautious of any scheme which claims guaranteed returns.',
      },
      {
        title: 'What are the risks associated with these investments?',
        description:
          'The risk profiling category is low as per this category of investment. Various risks associated with fractional investments such as liquidity, tenant, and market risks have been mitigated as much as possible with our robust due diligence and management processes.',
      },
      {
        title: 'What sort of returns can I expect?',
        description:
          'The expected IRR is 13-20% over a period of 5 years. <br> Note: hBits does not guarantee returns on the assets listed on our platform.',
      },
      {
        title: 'Incase of re-leasing will there be any brokerage?',
        description:
          'If re-leasing is directly through a client there will be no brokerage. If the client comes through a broker, the brokerage has to be paid out of company funds or from future rentals',
      },
      {
        title:
          'Is there one payout at the end of each year? What is the IRR if there is 0% capital appreciation? What if the property is not sold at the end of its tenure?',
        description:
          'There is no fixed tenure for selling the asset. We look to exit in a 4 to 6 year time period. So we wait for the right opportunity to exit the asset according to the market cycle, interest rates, and demand-supply dynamics to maximize capital appreciation. Having said that if there is 0% appreciation then IRR would be around the average rental yield.',
      },
      {
        title: 'Is fractional ownership risky?',
        description:
          'Fractional ownership is a lucrative investment option due to its low risk/high return profile. hBits uses machine learning and blockchain technology to create transparency of information and build ease of accessibility.',
      },
      {
        title: 'How will I know about new property listings on the platform?',
        description:
          'Kindly register on our platform for new property notifications and updates as per your interest. Alternatively, you could drop an enquiry with us and we will update you as soon as we have a new opportunity.',
      },
      {
        title: 'Is investing in properties listed on the platform risky?',
        description:
          'We list only the best properties after stringent due diligence and evaluation process. Only 2% of the total properties analyzed are listed on the platform.',
      },
      {
        title: 'Is my investment liquid?',
        description:
          'Investors can exit the asset anytime by selling it directly to another investor or by listing it on the platform. In the latter case, you would only be able to sell when there is a willing buyer on the platform.',
      },
      {
        title: 'Why commercial real estate?',
        description:
          'Commercial real estate is premium properties that command higher rentals, attract large Indian and MNC tenants and have the potential for substantial capital appreciation. Please visit <a href="/how-it-works" style="color: rgb(26, 41, 91);">How it works</a> to know more.',
      },
    ],
    title: 'Overview',
  },
  {
    items: [
      {
        title:
          'What if something goes wrong with hBits? Is my investment safe?',
        description:
          'Transparency is the cornerstone that we are built on. We ensure the safety of your investments with us. Every asset is listed as a Special Purpose Vehicle in the form of a seperate private limited company, and all financial control and ownership remains with the investors. So the finances of this SPV will be completely independent of any financial risk from Hbits. In any event of something going wrong with Hbits, we could be easily replaced by another asset management firm. We will just conduct legal compliance and manage operations for you to ensure the investment process goes seamlessly. Additionally, your ownership, governance, and compliance records are stored in public databases and government records. Your investment remains completely secure.',
      },
      {
        title:
          'How are the investments structured and what exactly will I own legally, as a fraction of the property?',
        description:
          'Every asset we acquire for our investors is listed as an SPV (special purpose vehicle)- as a private limited company- with rentals paid out in the form of dividends each year. Legally speaking, each investor will own shares in that private limited company, in proportion to their investment in the asset.',
      },
      {
        title: 'Are the distributions paid out monthly or annually?',
        description:
          'We like to maintain complete financial transparency with our investors so we ensure that rentals are paid out monthly as and when they are received by the SPV under which the asset is registered.',
      },
      {
        title:
          'Do we get direct ownership of the assets? Can we transfer our ownership to others?',
        description:
          'Yes, as a fractional owner of the asset you will receive a proportionate ownership in the form of shares of the private limited company which the asset is registered as. These shares can be traded with other investors on our platform just as they would on a stock exchange.',
      },
      {
        title: "What are the two hBits's fees?",
        description:
          'hBits charges a management fee of 1% per year on the amount invested and performance fee of 10% above hurdle rate of 8% on capital appreciation at the time of sale of the asset.',
      },
      {
        title: 'Are the distributions transferred monthly or annually?',
        description: 'Monthly.',
      },
      {
        title: 'Who takes care of tenancy and management?',
        description:
          'Our team looks after all aspects of tenancy, asset management, timely transactions & notifications.',
      },
      {
        title: 'How will I be updated on the progress of my investments?',
        description:
          'You can regularly monitor the performance of your investment through hBits online dashboard.',
      },

      {
        title:
          'Will there be a management fee when the property is not tenanted?',
        description:
          'No, there will be no management fee charged until the investment property is not tenanted.',
      },
      {
        title: 'What does the management fee paid to hBits cover?',
        description:
          'The management fee covers the costs for rent collections, TDS filing, GST filing, facilitating property tax payments, SPV compliances, sending monthly MIS to investors and insurance.',
      },
      {
        title: 'What is the contingency reserve used for?',
        description:
          'Contingency reserve is used to serve any unexpected expenses that arise in the asset. If the reserves are unused then they are refunded back to the investors at the time of sale.',
      },
      {
        title: 'Who decides exit timing and pricing?',
        description:
          'As an investor, you will have the right to participate in all decisions including the timing and price at which the asset is sold.',
      },
    ],
    title: 'Property ownership',
  },
  {
    items: [
      {
        title: 'What is an SPV?',
        description:
          'An SPV- Special Purpose Vehicle is a subsidiary company created to ensure complete financial security from any external factors. For Hbits, an SPV ensures that our investors cumulatively have complete financial control on the asset they want to invest in, without any risk of external financial influence from Hbits. Hbits will manage all the operations and legal compliance for the asset, but ownership as well as all financial decisions will remain with the investors.',
      },
      {
        title: 'What are the KYC documents needed to sign up with hBits?',
        description:
          "We require the following KYC documents <ol><li>PAN card</li><li>Address Proof (Aadhaar/Driver's license/Passport)</li></ol>",
      },
      {
        title: 'Can I invest in more than one property and have a portfolio?',
        description:
          'Yes, you can invest in more than one property and get benefits from a diversified portfolio.',
      },
      {
        title: 'How are the investments structured?',
        description:
          'For each commercial asset, a Specific Purpose Vehicle (SPV) is created in which funds are raised to own and manage the asset. Each investor is a shareholder in the SPV in proportion to their contribution to the asset. hBits assumes the responsibility of the SPV and the underlying asset on behalf of the investors. The SPV can either be a limited liability partnership or a private limited company.',
      },
      {
        title: 'What is the investment process?',
        description:
          '<ol><li>You can start your investment process by signing the Expression of Interest (EOI) and making a payment of 5% of your investment amount as token to block your share in the asset.</li><li>You can proceed to upload your KYC documents which are verified by hBits.</li><li>Once the  property is fully funded hBits calls for the payment of balance investment amount.</li><li>After the investments are received from all the investors the property is registered, documents are shared with investors and the shares are transferred to your respective demat account.</li> </ol>',
      },
      {
        title: 'What happens if I want to withdraw my initial token advance?',
        description:
          "You can withdraw your token amount before the company makes an offer. Once the offer is made you will have to pay a penalty of 50% of the token amount if you wish to withdraw. Exceptionally, if the asset doesn't get fully funded, investor's token amount will be reimbursed to their registered bank account.",
      },
      {
        title: 'What kind of documentation will I need to sign?',
        description:
          'You will sign an Expression of Interest (EOI) and private placement offer letter (PAS4) with hBits. If you are an NRI you will also have to sign a tax declaration form. All these documents will require a digital signature and no physical copies are required. This makes the process faster, transparent, and convenient.',
      },
      {
        title: 'Do I need to be present for property registration?',
        description:
          'No. You need not be present at the time of property registration. The assets are housed in a special purpose vehicle (SPV) which can authorise a person to complete the registration on behalf of the SPV.',
      },
      {
        title:
          'How does hBits guarantee that all property titles are in the clear?',
        description:
          'Our expert legal and technical team does all the due diligence and analyzes every aspect of the property before acquisition. We have a tier 1 law firm as the signing authority for property title reports clearance.',
      },
      {
        title: 'Are investors allowed to see rental agreement?',
        description:
          'Yes. hBits shares all the lease/rental agreement copies with the investors.',
      },
      {
        title: 'What are the reporting standards and disclosures?',
        description:
          'hBits investor dashboard is a real-time reporting system with 100% transparency. Owners can view or download their property documents, legal and rental agreements. All the tenant details, property title reports, due diligence reports, monthly financials, etc are stored in the dashboard.',
      },
      {
        title: 'Is there any lock-in on my investment?',
        description:
          'Once the property payment is done completely , you are free to sell your property share anytime, as there is no lock-in period.',
      },
      {
        title: 'When is my investment process complete?',
        description:
          'Your investment process will be complete when the property gets fully funded by other co-investors. hBits has a time frame of 30 -90 days in which we ensure that the property receives complete funding.',
      },
      {
        title: 'How long do you hold these investments for?',
        description:
          'It takes 3-5 years for capital appreciation of the asset although investors can exit early through our liquidity options. But this could adversely affect the total returns on the investment.',
      },
      {
        title: 'How do I exit my Investment?',
        description:
          'You can exit from your ownership in any property in 3 different ways:<ol><li> Resale market on our platformYou can list your fraction on hBits’ resale market through the dashboard. Once a new investor has purchased your fraction, the sale proceeds will be credited in your registered bank account with us.</li><li>Private sale Investors are free to sell their fraction to anyone on their own, subject to valid KYC and regulatory guidelines. hBits’ will facilitate the transfer of ownership and onboarding of the new investor on the platform.</li><li>Property sale hBits will enable investors to sell the complete asset in 3-5 years. Based on majority investors decision for a specific asset, hBits will initiate the liquidation of the asset. Once the asset is sold, you will receive the credit of your sale proceeds in your registered bank account with us. If the investors vote to hold the asset, the ownership will continue as it is with the monthly returns inflow into the investors registered bank account.</li> </ol>',
      },
      {
        title: 'When I invest in deals on hBits, what do I own?',
        description:
          'You will own equity shares and debentures of the SPV which owns the asset. These will be in demat form.',
      },
      {
        title: 'Can investor appoint nominee for the investment ?',
        description:
          'Yes. The Investor can appoint a nominee for the Investment. Investor to note that a nominee can be any person except a minor.',
      },
    ],
    title: 'Property Management',
  },
  {
    items: [
      {
        title:
          'From which account the payment is to be done by the NRI and where will the rental proceeds go?',
        description:
          'NRI investors can only invest through an NRO Account or from a normal savings bank account in India. Distributions will go to the account given by the investor.',
      },
      {
        title: 'I am an NRI. What taxes will I need to pay?',
        description:
          'Under Indian income-tax law, a Non-resident Individual (NRI) is required to pay tax on any Indian sourced or received income in NRO account that exceeds the basic exemption limit, as per applicable slab rates. NRIs can benefit from Double Taxation Avoidance Agreement (DTAA) entered with their respective country, subject to availability of Tax Residency Certificate (TRC). Please consult your financial advisor for more details as income tax provisions and tax treaties are subject to change from time to time.',
      },
      {
        title:
          'Is there any restriction on the amount invested by NRI in a single name?',
        description:
          'There is no restriction on maximum investment by NRI on a single name',
      },
      {
        title: 'Can I transfer my transaction amount in foreign currency?',
        description:
          'Transfer of foreign currency by NRIs are regulated by extant RBI and FEMA guidelines. Please reach out to us for more information on the same.',
      },
      {
        title:
          'What documents are required if NRI is transferring funds through NRO account?',
        description:
          'We require copies of following KYC documents:<ul><li>PAN Card </li><li>Address Proof (Aadhar card or Driving License or Passport or Tax Residence certificate)</li>        <li>NRO bank account details (Bank name, account number, IFSC code or cancelled Cheque with name printed on it)</li>        </ul>',
      },
    ],
    title: 'NRI',
  },
  {
    items: [
      {
        title: 'What are the deductions?',
        description:
          'The typical deductions are tax deduction at source, property management fee and property tax.',
      },
      {
        title: 'How will I be taxed on distributions?',
        description:
          'Distributions are taxable directly in the hands of the investor as per his/her tax bracket. If you are a Resident Indian, TDS would be deducted on distributed amount at 10% and for Non-resident Individuals (“NRI”) it will be deducted at 30% or as per applicable rates in DTAA subject to availability of valid TRC and income tax provisions.',
      },
      {
        title: 'Does tenant pay GST? ',
        description: 'Yes.',
      },
      {
        title: 'What are the tax implications on my investments? ',
        description:
          '<b>Rents </b>: Rents received from the property are distributed as interest on debentures which are taxable directly in the hands of the investors (post applicable withholding tax which can be claimed by the investor at the end of the year).<p > <b>Capital Gains</b> :The profit on sale of commercial property is considered as capital gains. The same shall be long-term, if the property was held for more than 24 months and will be taxed at 20%, irrespective of the quantum of gains. The benefit of indexation can be explored in case of long term capital gains. However, if the property were to be sold before 24 months have passed, the same becomes taxable as short-term capital gains and is taxed as normal income. Please consult your financial advisor for more details as income tax provisions are subject to change from time to time.</p>',
      },
      {
        title: 'Is the TDS deposited against my name? ',
        description:
          'Yes, the TDS on your distributions is paid and deposited against your PAN and can be claimed back at the end of the year.',
      },
      {
        title:
          'I do not have any taxable income in India. Why do I still need to pay tax?',
        description:
          'Even if an NRI’s income in India does not exceed the basic exemption limit, taxes may be withheld as per income tax provisions. Such an NRI can claim refund of taxes withheld by filing a tax return in India.',
      },
      {
        title: 'What is a TRC? How can I get a TRC? ',
        description:
          'A TRC is a Tax Residency Certificate provided by the country where you are currently residing. India has a Double Tax Avoidance Agreement (DTAA) with almost all major countries that reduce TDS to lower thresholds of 10-15% (depending on the provisions of the DTAA). However, the benefit of the reduced tax rate is only available to users who are able to produce a TRC. Please speak to your tax advisor on how you can procure a TRC for your country of residence..',
      },
      {
        title: 'I am an NRI. What taxes will I need to pay? ',
        description:
          'Under Indian Income-tax law, a Non-resident Individual (NRI) is required to pay tax on any Indian sourced or received income in NRO account that exceeds the basic exemption limit, as per applicable slab rates.<p>NRIs can benefit from Double Taxation Avoidance Agreement (DTAA) entered with their respective country, subject to availability of Tax Residency Certificate (TRC). Please consult your financial advisor for more details as income tax provisions and tax treaties are subject to change from time to time.</p><p>*TDS will not be dedcuted subject to submission of forms 15G and 15H by specific persons.</p>',
      },
    ],
    title: 'Tax',
  },
  {
    items: [
      {
        title: 'How is hBits different from a REIT/Real Estate Fund?',
        description:
          "In a REIT or a Real Estate fund, the investor does not have exposure to directly owning a real estate property. The investor invests in a fund that has fund managers who decide how the capital is deployed and managed. On the other hand, hBits is a platform that connects you directly with investment opportunities in commercial real estate, allowing you to invest and own fractional ownership in assets of your choice and reap it's yield and capital appreciation over time.",
      },
      {
        title:
          'Why is fractional-ownership a better way to invest than traditional real estate models?',
        description:
          'Fractional ownership allows investors to invest in high-end commercial properties at a low ticket size. In traditional real estate model, only those with high capital were able to invest in such properties. Fractional ownership has made investing in commercial properties more affordable and accessible.',
      },
      {
        title: 'What are the key differences between a REIT & hBits?',
        description:
          'hBits lets you choose the asset you want to invest in unlike in REITs, giving you more control over the money and the benefits of diversification.',
      },
    ],
    title: "Fractional vs REIT's",
  },
];
