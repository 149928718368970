import React from 'react';
import { Flex, HStack } from '@chakra-ui/react';
import { Colors } from 'apps/customer-portal-web/src/theme';
import { InvestmentLinks } from '../utils';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  NavbarLinkHash,
  NavbarLinkName,
} from '../../Dashboard/DashboardContents';
import {
  NavbarItems,
  NavbarWrapper,
} from 'apps/customer-portal-web/src/components/NavSection/styles';

interface NavbarItem {
  name: NavbarLinkName;
  link: NavbarLinkHash;
  logoSrc: string;
  logoSrcHover: string;
}

interface NavigationSectionProps {
  navbarItems: NavbarItem[];
}

const RouteSection: React.FC<NavigationSectionProps> = ({
  navbarItems = InvestmentLinks,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActiveTab = React.useCallback(
    (tab: string): boolean => location.hash === tab,
    [location],
  );
  const changeMainSection = (route: string) => {
    navigate(route);
  };

  return (
    <NavbarWrapper>
      {navbarItems.map((item: any) => (
        <NavLink
          to={item.link}
          style={
            !isActiveTab(item.link)
              ? { color: Colors.white, textDecoration: 'none' }
              : { color: Colors.orangeColor, textDecoration: 'none' }
          }
          onClick={() => changeMainSection(item.link)}
          key={item.name}
        >
          <NavbarItems fontSize={['14px', '1.125rem']} key={item.name}>
            <HStack>
              <Flex>
                {isActiveTab(item.link) ? (
                  <img
                    width={25}
                    height={25}
                    alt="img-logo"
                    src={item.logoSrcHover}
                  />
                ) : (
                  <img
                    width={25}
                    height={25}
                    alt="img-logo"
                    src={item.logoSrc}
                  />
                )}
              </Flex>
              <Flex
                className={
                  isActiveTab(item.link) ? 'dashboard-nav-link-active' : ''
                }
                width={'100%'}
                justifyContent={['center', 'flex-start']}
              >
                <Flex minW={'70px'}>{item.name}</Flex>
              </Flex>
            </HStack>
          </NavbarItems>
        </NavLink>
      ))}
    </NavbarWrapper>
  );
};

export default RouteSection;
