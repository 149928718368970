import React, { useEffect } from 'react';
import { Colors, Environment, InvestmentStage, Strings } from '@hBits.Core';
import { FC } from 'react';
import { Box, Flex, Text, Circle, useDisclosure } from '@chakra-ui/react';
import colors from 'libs/src/theme/colors';
import PaymentRecid from '../PaymentRecid';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoaderIcon from 'apps/customer-portal-web/src/assets/LoaderIcon.svg';
import { setFirstDashboard } from 'apps/customer-portal-web/src/store/actions';

const Registration: FC<{}> = () => {
  const investmentModal = useSelector(
    (state: any) => state.InvestmentReducer?.propertyData,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let location = useLocation();
  const { fullPay } = investmentModal;
  const removePgRedirect = () => {
    const customerProtalWeb = Environment.getInstance().getParam(
      'NEXT_PUBLIC_CUSTOMER_PORTAL_HOSTING_ROOT',
    );

    window.location.assign(customerProtalWeb + location.pathname);
  };

  const redirectToDashboard = async () => {
    await dispatch(setFirstDashboard(true));
    navigate({
      pathname: '/customer/dashboard?isFirst=true',
      search: '?isFirst=true',
    });
  };
  useEffect(() => {
    if (
      investmentModal?.investmentStage === InvestmentStage.RegistrationComplete
    ) {
      redirectToDashboard();
    }
  }, [investmentModal?.investmentStage]);

  return (
    <>
      {location.search.includes('?transactionStatus') ? (
        <>
          <PaymentRecid
            removePgRedirect={removePgRedirect}
            amount={fullPay?.balanceAmount}
            recidUrl={fullPay?.balanceAmountPaymentTransactionUrls[0]}
            isSucess={true}
          />
        </>
      ) : (
        <Flex
          width={'100%'}
          flexDirection={'column'}
          pl={[0, 0, 3, 3]}
          mt={[3, 3, 0, 0]}
        >
          <Flex
            width={'60%'}
            borderBottomWidth=".5px"
            borderBottomColor={colors.blackColor}
            pb={3}
          >
            <img src={LoaderIcon} height={'12px'} width={'12px'} />
            <Text as="b" fontSize="14px" color={colors.blackColor} ml={'10px'}>
              {Strings.registrationProcess}
            </Text>
          </Flex>
          <Flex direction={'column'} pt={2}>
            <Text fontSize="14px" color={colors.grey}>
              {Strings.weWillGetBack}
            </Text>
            <Text fontSize="14px" mt={'40px'} color={colors.grey} pr={'30px'}>
              {Strings.forQueries2}
            </Text>
          </Flex>
          <Flex mt={'20px'} flexDirection="column">
            <Text fontSize="14px" as="b" color={colors.grey} mt={3}>
              {Strings.forQueries}
            </Text>
            <Text fontSize="14px" as="b" color={colors.headerBlue} mt={1}>
              {Strings.contactusregestration}
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Registration;
