import React, { useEffect, useState } from 'react';
import { fetchStateByCountryId } from '../../../../../../libs/src/services/modules/channel-partner';
import { Input, Select } from '@chakra-ui/react';
import { Colors, Strings } from '@hBits.Core';
import styles from '../channel-partner.module.scss';
import colors from 'libs/src/theme/colors';

type StateType = {
  id: string;
  name: string;
  countryName: string;
};

interface SelectCountryProps {
  selectedCoutry: string | undefined;
  selectedState: string | undefined;
  setSelectedState: (country: string) => void;
  isColorGrey?: any;
  isKyc?: any;
  isDisable?: boolean;
  testId?: string
}

/**
 * @param {Object} props
 * @returns {JSX.Element} - A React Functional Component
 */
const SelectStatesDropdown: React.FC<SelectCountryProps> = ({
  selectedCoutry,
  selectedState,
  setSelectedState,
  isColorGrey = false,
  isKyc = false,
  isDisable = false,
  testId,
}) => {
  const [states, setStates] = useState<StateType[]>([]);
  const [placeholderColor, setPlaceholderColor] = useState(Colors.blackColor);

  useEffect(() => {
    if (!selectedCoutry) return;
    fetchStateByCountryId(selectedCoutry).then((data) => {
      setStates(data);
      if (!isKyc) {
        setSelectedState('');
      }
    });
  }, [selectedCoutry]);

  useEffect(() => {
    if (isColorGrey) {
      if (isKyc) {
        setPlaceholderColor(colors.kycPlaceholder);
      } else {
        setPlaceholderColor(Colors.greyPlaceholder);
      }
    } else {
      setPlaceholderColor(Colors.blackColor);
    }
  }, [isColorGrey]);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedState(event.target.value);
  };

  return states.length > 0 ? (
    <Select
      data-test={testId}
      width={'100%'}
      height={'40px'}
      fontSize={'16px'}
      borderRadius={0}
      borderColor={isKyc ? colors.buttonBorderGrey : Colors.greyBorderColor}
      value={selectedState}
      onChange={handleChange}
      color={selectedState ? Colors.blackColor : placeholderColor}
      placeholder={`*${Strings.select} ${Strings.state}`}
      variant="flushed"
      disabled={isDisable}
      _disabled={{ color: 'black' }}
    >
      {states.map((state) => (
        <option
          key={state.id}
          value={state.name}
          style={{ color: colors.blackColor }}
        >
          {state.name}
        </option>
      ))}
    </Select>
  ) : (
    <Input
      onChange={handleChange}
      className={styles['input_box']}
      type='text'
      borderRadius={0}
      disabled={isDisable}
      _disabled={{ color: 'black' }}
      borderColor={isKyc ? colors.buttonBorderGrey : Colors.greyBorderColor}
      value={selectedState}
      placeholder={`*${Strings.state}`}
      _placeholder={{ color: placeholderColor }}
      variant="flushed"
    />
  );
};

export default SelectStatesDropdown;
