import React from 'react';
import { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@chakra-ui/react';
import { DocumentIcon, DownloadIcon } from '../../assets/customIcons';
import styles from './download-wizid.module.scss';
import { Strings } from '@hBits.Core';
import { useSelector } from 'react-redux';

const DownloadWizid: FC<{}> = () => {
  
  const downloadFileUrl = useSelector(
    (state: any) => state.InvestmentReducer?.downloadFileUrl,
  );
  return (
    <Flex direction="row" className={styles['container']}>
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Flex flex="1" textAlign="left">
                <Flex>
                  <DocumentIcon />
                </Flex>
                <Flex
                  ml={5}
                  mr={3}
                  className={styles['downloadtext']}
                  alignItems={'center'}
                >
                  {Strings.documentsForDownload}
                </Flex>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Flex flexDirection={'column'}>
              {downloadFileUrl.map((item: any) => (
                <Flex ml={'20px'} alignItems="center" mt={1} mb={1}>
                  <a target="_BLANK" href={item.url}>
                    <DownloadIcon />
                  </a>
                  <Text as="b" fontSize={'14px'} ml={'10px'}>
                    {item.title}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
};

export default DownloadWizid;
