import {
  Strings,
  HTTPStatusCode,
  AccountTypeEnum,
  KycProofStatus,
  AddressProofType,
} from '@hBits.Core';
import accountIdManager from 'libs/src/auth/account-manager';
import { getKycData } from '../../services/Kyc/getKycData';
import {
  IS_GET_KYC_DATA,
  IS_SUBMITTED_PAGE_VISIBLE,
  TAB_VALUE,
} from '../../store/constants/ReduxConstants';

export const categoryType = [
  {
    name: 'Individual',
    tabName: [
      'Basic Details',
      'Guardian Details',
      'Investor Address',
      'PAN & Bank Details',
    ],
  },
  {
    name: 'LLP',
    tabName: [
      'Basic Details',
      'Partner Address',
      'PAN & Bank Details',
      'LLP Address',
    ],
  },
  {
    name: 'Private',
    tabName: [
      'Basic Details',
      'Director Address',
      'PAN & Bank Details',
      'Company Address',
    ],
  },
  {
    name: 'Joint',
    tabName: [
      'Basic Details',
      'Guardian Details',
      'Investor Address',
      'PAN & Bank Details',
    ],
  },
  {
    name: 'Partnership',
    tabName: [
      'Basic Details',
      'Partner Address',
      'PAN & Bank Details',
      'Firm Address',
    ],
  },
  {
    name: 'Huf',
    tabName: ['Basic Details', 'Karta Address', 'PAN & Bank Details'],
  },
  {
    name: 'Trust',
    tabName: [
      'Basic Details',
      'Trustee Address',
      'PAN & Bank Details',
      'Trust Address',
    ],
  },
];

export const isAgeValid = (date: string) => {
  if (date === undefined) return false;
  let isAgeGreaterThan18 = true;
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  if (age < 18) {
    isAgeGreaterThan18 = false;
  } else {
    isAgeGreaterThan18 = true;
  }
  return isAgeGreaterThan18;
};

export function capitalizeFirstLetter(string = '') {
  return string
    ?.toLowerCase()
    ?.split(' ')
    ?.map(function (word) {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1);
    })
    ?.join(' ');
}

export function capitalizeEachLetter(string = '') {
  return string?.toUpperCase();
}

export const getKycDataFunc = async () => {
  const accountId = accountIdManager?.getAccountId();
  const kycData = await getKycData(accountId);
  return kycData;
};

export const toastMessage = async (
  response: any,
  toast: any,
  value: number | undefined,
  holderNumber: number,
  dispatch: any,
  selectorState: any,
  isCallHolder?: boolean,
  isCallAccount?: boolean,
) => {
  if (!response?.additionalDetails) {
    toast({
      title: Strings.success,
      description: Strings.success,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    const { isGetKycData, tabValue, isSubmittedPageVisible } = selectorState;
    if (isCallAccount) {
      dispatch({
        type: IS_GET_KYC_DATA,
        payload: !isGetKycData,
      });
      value = await setTabValueWhenKycIsInvalidated(
        holderNumber,
        dispatch,
        isCallHolder,
        isSubmittedPageVisible,
      );
    }

    dispatch({
      type: TAB_VALUE,
      payload: {
        ...tabValue,
        [holderNumber]: value,
      },
    });
    return;
  }
  toast({
    title: Strings.failed,
    description: response.additionalDetails,
    status: 'error',
    duration: 5000,
    isClosable: true,
  });
};

export const setTabValueWhenKycIsInvalidated = async (
  holderNumber: number,
  dispatch: any,
  isCallHolder: boolean | undefined,
  isSubmittedPageVisible: any,
) => {
  let value = 0;
  if (isCallHolder) {
    const getData = async () => {
      const response = await getKycDataFunc();
      if (response?.status === HTTPStatusCode.OK) {
        const accountType = response?.data?.holders[holderNumber]?.accountType;
        const currentDetails = response?.data?.holders[holderNumber];
        const isGuardianDetails = !isAgeValid(currentDetails?.dateOfBirth);
        if (
          accountType === AccountTypeEnum.Individual ||
          accountType === AccountTypeEnum.Joint
        ) {
          const { guardianDetails, addressDetails, bankDetails } =
            currentDetails;
          if (
            guardianDetails?.panVerificationStatus ===
              KycProofStatus.Rejected ||
            checkMultipleDocumentsState(guardianDetails)
          ) {
            value = 1;
            return;
          }
          if (
            checkMultipleDocumentsState(
              addressDetails[addressDetails.length === 1 ? 0 : 1],
            ) ||
            addressDetails[addressDetails.length === 1 ? 0 : 1]
              ?.panVerificationStatus === KycProofStatus.Rejected
          ) {
            if (isGuardianDetails) {
              value = 2;
              return;
            }
            value = 1;
            return;
          }
          if (
            bankDetails?.panVerificationStatus === KycProofStatus.Rejected ||
            bankDetails?.bankAccountVerificationStatus ===
              KycProofStatus.Rejected
          ) {
            if (isGuardianDetails) {
              value = 3;
              return;
            }
            value = 2;
            return;
          }
          dispatch({
            type: IS_SUBMITTED_PAGE_VISIBLE,
            payload: {
              ...isSubmittedPageVisible,
              [holderNumber]: true,
            },
          });
        } else if (accountType === AccountTypeEnum.Huf) {
          const { addressDetails, bankDetails } = currentDetails;
          if (
            checkMultipleDocumentsState(
              addressDetails[addressDetails.length === 1 ? 0 : 1],
            ) ||
            addressDetails[addressDetails.length === 1 ? 0 : 1]
              ?.panVerificationStatus === KycProofStatus.Rejected
          ) {
            value = 1;
            return;
          }
          if (
            bankDetails?.panVerificationStatus === KycProofStatus.Rejected ||
            bankDetails?.bankAccountVerificationStatus ===
              KycProofStatus.Rejected
          ) {
            value = 2;
            return;
          }
          dispatch({
            type: IS_SUBMITTED_PAGE_VISIBLE,
            payload: {
              ...isSubmittedPageVisible,
              [holderNumber]: true,
            },
          });
        } else {
          const { addressDetails, bankDetails } = currentDetails;
          for (let i = 0; i < addressDetails?.length; i++) {
            if (addressDetails[i]?.addressBelongsTo == Strings.main) {
              if (
                checkMultipleDocumentsState(addressDetails[i]) ||
                addressDetails[i]?.panVerificationStatus ===
                  KycProofStatus.Rejected
              ) {
                value = 1;
                return;
              }
            }
          }
          if (
            bankDetails?.panVerificationStatus === KycProofStatus.Rejected ||
            bankDetails?.bankAccountVerificationStatus ===
              KycProofStatus.Rejected
          ) {
            value = 2;
            return;
          }
          for (let i = 0; i < addressDetails?.length; i++) {
            if (addressDetails[i]?.addressBelongsTo == Strings.representative) {
              if (checkMultipleDocumentsState(addressDetails[i])) {
                value = 3;
                return;
              }
            }
          }
          dispatch({
            type: IS_SUBMITTED_PAGE_VISIBLE,
            payload: {
              ...isSubmittedPageVisible,
              [holderNumber]: true,
            },
          });
        }
        {
          return;
        }
      } else {
        return;
      }
    };
    await getData();
    return value;
  }
  {
    return;
  }
};

const checkMultipleDocumentsState = (response: any) => {
  if (
    response?.aadharVerificationStatus === KycProofStatus.Rejected ||
    response?.gstCertificateVerificationStatus === KycProofStatus.Rejected ||
    response?.drivingLicenseVerificationStatus === KycProofStatus.Rejected ||
    response?.passportVerificationStatus === KycProofStatus.Rejected ||
    response?.trcVerificationStatus === KycProofStatus.Rejected ||
    response?.otherDocumentVerificationStatus === KycProofStatus.Rejected
  ) {
    return true;
  } else {
    return false;
  }
};

export const getHolderTypeName = (holderType: number) => {
  switch (holderType) {
    case 0:
      return 'Primary';
    case 1:
      return 'Second';
    case 2:
      return 'Third';
    default:
      return 'Primary';
  }
};

export const checkMultipleDocumentsErrors = (
  addressProofDetails: any,
  aadharProofBackUrl: any,
) => {
  let state = false;
  for (let j = 0; j < addressProofDetails?.length; j++) {
    if (
      addressProofDetails[j].documentName == '' ||
      addressProofDetails[j].documentNumber == '' ||
      addressProofDetails[j].documentUrl == '' ||
      addressProofDetails[j].documentRejectionReason !== undefined
    ) {
      state = false;
      break;
    } else {
      if (addressProofDetails[j].documentName === AddressProofType.Aadhar) {
        if (aadharProofBackUrl === undefined || aadharProofBackUrl === '') {
          state = false;
          break;
        }
      }
      state = true;
    }
  }
  return state;
};
