import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  AUM,
  ChannelPartnerOraganizationRole,
  ChannelPartnerType,
  Colors,
  DistributorCategory,
  Environment,
  FocusedArea,
  Strings,
  UserType,
  authTokenStorageProvider,
} from '@hBits.Core';
import ChannelPartnerTncModal from 'libs/src/components/ChannelPartnerTncModal';
import PrivacyModal from 'libs/src/components/PrivacyModal';
import { TOAST_TIMEOUT } from 'libs/src/constants';
import { enrollAsChnnelPartner } from 'libs/src/services/modules/channel-partner';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../../icons/close-icon';
import styles from './channel-partner.module.scss';
import validateEnrollmentPayload from './enrollmentPayloadValidator';
import AssetTypeSelectCheckboxes from './helper-components/AssetTypeSelectCheckboxes';
import SelectCountryDropdown from './helper-components/CountriesDropDown';
import FocusedAreaSelectCheckboxes from './helper-components/FocusedAreaSelectCheckboxes';
import SelectDropdown from './helper-components/SelectDropdown';
import SelectStatesDropdown from './helper-components/StatesDropDown';
import { CustomBtn, CustomLink, ErrorMessage } from './styled-componets';

const errorsDefaultValues = {
  companyName: false,
  country: false,
  state: false,
  city: false,
  assetTypes: false,
  focusedArea: false,
  assetUnderManagement: false,
  distributorCategory: false,
  focusedAreaOther: false,
};

interface PayloadInterface {
  channelPartnerType: ChannelPartnerType;
  companyName?: string;
  country: string | undefined;
  state: string | undefined;
  city: string;
  assetTypes: string[];
  focusedArea: string[];
  assetUnderManagement: AUM | undefined;
  distributorCategory: DistributorCategory | undefined;
  focusedAreaOther?: string;
  role: ChannelPartnerOraganizationRole;
  accountId: string;
}

type channelPartnerEnrollmentResponse = {
  accessToken: string | null;
};

const Enrollment = (): JSX.Element => {
  const toast = useToast();
  const navigate = useNavigate();

  const [country, setCountry] = useState<string | undefined>(undefined);
  const [state, setState] = useState<string | undefined>(undefined);
  const [city, setCity] = useState('');
  const [channelPartnerType, setChannelPartnerType] =
    useState<ChannelPartnerType>(ChannelPartnerType.Individual);
  const [assetUnderManagement, selectAssetUnderManagement] = useState<
    AUM | undefined
  >();
  const [isOpenTncModal, setIsOpenTncModal] = useState<boolean>(false);
  const [isOpenPrivacyModal, setIsOpenPrivacyModal] = useState<boolean>(false);

  const [distributorCategory, selectDistributorCategory] = useState<
    DistributorCategory | undefined
  >();

  const [assetTypes, setAssetTypes] = useState<string[]>([]);
  const [focusedArea, setFocusedArea] = useState<string[]>([]);
  const [focusedAreaOther, setFocusedAreaOther] = useState('');
  const [companyName, setCompanysName] = useState('');

  const [errors, setErrors] = useState({
    ...errorsDefaultValues,
  });
  const [isProceededOnce, setIsProceededOnce] = useState(false);

  const handleChangeAUM = (event: React.ChangeEvent<HTMLSelectElement>) => {
    selectAssetUnderManagement(event.target.value as AUM);
  };

  const handleChangeDistubutorCategory = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    selectDistributorCategory(event.target.value as DistributorCategory);
  };

  const getButtonClassName = (cpType: ChannelPartnerType) => {
    return channelPartnerType === cpType ? '' : 'inv';
  };

  const toggleTncModal = () => {
    setIsOpenTncModal(!isOpenTncModal);
  };

  const togglePrivacyModal = () => {
    setIsOpenPrivacyModal(!isOpenPrivacyModal);
  };

  const validateFeildsAndGetErrors = () => {
    const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
    if (!authTokenModel) return;
    const { accountId } = authTokenModel;

    const enrollmentPayload: PayloadInterface = {
      channelPartnerType,
      country,
      state: state ? state.trim() : state,
      city: city ? city.trim() : city,
      assetTypes,
      focusedArea,
      assetUnderManagement,
      distributorCategory,
      accountId,
      role: ChannelPartnerOraganizationRole.Admin,
    };

    if (channelPartnerType === ChannelPartnerType.Company) {
      enrollmentPayload.companyName = companyName.trim();
    }

    if (focusedArea.includes(FocusedArea.Other)) {
      enrollmentPayload.focusedAreaOther = focusedAreaOther.trim();
    }

    const errorsFeilds = validateEnrollmentPayload(enrollmentPayload);

    const combinedError = {
      ...errorsDefaultValues,
      ...errorsFeilds,
    };

    setErrors({ ...combinedError });
    return combinedError;
  };

  const handleProceed = async () => {
    const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
    if (!authTokenModel) return;
    const { accountId } = authTokenModel;

    const enrollmentPayload: PayloadInterface = {
      channelPartnerType,
      country,
      state: state ? state.trim() : state,
      city: city ? city.trim() : city,
      assetTypes,
      focusedArea,
      assetUnderManagement,
      distributorCategory,
      accountId,
      role: ChannelPartnerOraganizationRole.Admin,
    };

    if (channelPartnerType === ChannelPartnerType.Company) {
      enrollmentPayload.companyName = companyName.trim();
    }

    if (focusedArea.includes(FocusedArea.Other)) {
      enrollmentPayload.focusedAreaOther = focusedAreaOther.trim();
    }

    const combinedError = validateFeildsAndGetErrors();
    const errorInAnyFeild = Object.values(combinedError).some(
      (val: any) => val === true,
    );

    if (errorInAnyFeild) {
      setIsProceededOnce(true);
      toast({
        title: Strings.failed,
        description: 'Enter all Required Feilds',
        status: 'error',
        duration: TOAST_TIMEOUT,
        isClosable: true,
      });
      return;
    }
    const response: channelPartnerEnrollmentResponse =
      await enrollAsChnnelPartner(enrollmentPayload);
    if (response?.accessToken) {
      authTokenStorageProvider.storeRawAuthToken(response?.accessToken);
      window.location.href =
        Environment.getInstance().getParam(
          'NEXT_PUBLIC_NEW_CUSTOMER_PORTAL_HOSTING_ROOT',
        ) + '/dashboard';
      return;
    }
    toast({
      title: Strings.failed,
      description: Strings.internalServerError,
      status: 'error',
      duration: TOAST_TIMEOUT,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (isProceededOnce) validateFeildsAndGetErrors();
    const userType = authTokenStorageProvider.getAuthTokenModel()?.userTypes;
    if (userType && userType.includes(UserType.ChannelPartner)) {
      window.location.href =
        Environment.getInstance().getParam(
          'NEXT_PUBLIC_NEW_CUSTOMER_PORTAL_HOSTING_ROOT',
        ) + '/dashboard';
      return;
    }
  }, []);

  const RequiredTagMinThreeCharacters = () => (
    <ErrorMessage>* {Strings.minLengthIsThree}</ErrorMessage>
  );
  const RequiredTagAlphabets = () => (
    <ErrorMessage>*{Strings.onlyAlphabetsAllowed}</ErrorMessage>
  );
  const RequiredTag = () => <ErrorMessage>*{Strings.required}</ErrorMessage>;

  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  return (
    <>
      <ChakraProvider>
        <Flex className={styles['cp_wrapper']}>
          <Flex className="container" justify={'center'}>
            <Flex
              className={styles['enrollment_form']}
              direction={'column'}
              gap={'32px'}
              padding={'32px'}
              width={['100%', '100%', '100%', '80%']}
            >
              <Flex justifyContent="flex-end">
                <Box onClick={() => navigate(-1)}>
                  <CloseIcon />
                </Box>
              </Flex>
              <h1 className="text-center" style={{ marginTop: '-20px' }}>
                {Strings.moreDetails}
              </h1>
              <Flex
                fontSize={'17.6px'}
                justify={'center'}
                align={'center'}
                gap={4}
                direction={['column', 'row', 'row', 'row']}
              >
                <Text fontSize={'17.6px'}>{Strings.iam}</Text>
                <Flex alignItems={'center'} gap={2}>
                  <CustomBtn
                    className={getButtonClassName(
                      ChannelPartnerType.Individual,
                    )}
                    onClick={() =>
                      setChannelPartnerType(ChannelPartnerType.Individual)
                    }
                  >
                    {ChannelPartnerType.Individual}
                  </CustomBtn>
                  <CustomBtn
                    className={getButtonClassName(ChannelPartnerType.Company)}
                    onClick={() =>
                      setChannelPartnerType(ChannelPartnerType.Company)
                    }
                  >
                    {ChannelPartnerType.Company}
                  </CustomBtn>
                </Flex>
              </Flex>
              {channelPartnerType === ChannelPartnerType.Company && (
                <Flex direction={'column'} width={'100%'}>
                  <Input
                    className={styles['input_box']}
                    placeholder={`*${Strings.companysName}`}
                    variant="flushed"
                    value={companyName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCompanysName(event.target.value);
                    }}
                  />
                  {errors.companyName &&
                    (companyName === '' ? (
                      <RequiredTag />
                    ) : (
                      <RequiredTagMinThreeCharacters />
                    ))}
                </Flex>
              )}
              <Flex gap={5} direction={['column', 'row']}>
                <Flex direction={'column'} flex={1}>
                  <SelectCountryDropdown
                    selectedCountry={country || ''}
                    setSelectedCountry={setCountry}
                  />
                  {errors.country && <RequiredTag />}
                </Flex>
                <Flex direction={'column'} flex={1}>
                  <SelectStatesDropdown
                    selectedCoutry={country}
                    selectedState={state}
                    setSelectedState={setState}
                  />
                  {errors.state && <RequiredTag />}
                </Flex>
                <Flex direction={'column'} flex={1}>
                  <Input
                    className={styles['input_box']}
                    placeholder={`*${Strings.city}`}
                    _placeholder={{ color: Colors.placeholderColor }}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    borderRadius={0}
                    borderColor={Colors.greyBorderColor}
                    variant="flushed"
                  />
                  {errors.city &&
                    (city === '' ? <RequiredTag /> : <RequiredTagAlphabets />)}
                </Flex>
              </Flex>
              <Flex
                gap={5}
                direction={['column', 'row']}
                marginTop={[-4, 0, 0, 0]}
              >
                <Flex direction={'column'} width={'100%'}>
                  <SelectDropdown
                    name={Strings.distributorCategory}
                    values={[...Object.values(DistributorCategory)]}
                    value={distributorCategory}
                    handleChange={handleChangeDistubutorCategory}
                    placeholder={Strings.distributorCategory}
                  />
                  {errors.distributorCategory && <RequiredTag />}
                </Flex>
                <Flex direction={'column'} width={'100%'}>
                  <SelectDropdown
                    name={Strings.assetUnderManagement}
                    values={[...Object.values(AUM)]}
                    value={assetUnderManagement}
                    handleChange={handleChangeAUM}
                    placeholder={'How much AUM do you manage?'}
                  />
                  {errors.assetUnderManagement && <RequiredTag />}
                </Flex>
              </Flex>
              <Flex direction={'column'} width={'100%'}>
                <AssetTypeSelectCheckboxes
                  selectedAssetType={assetTypes}
                  updateSelectedAssetType={setAssetTypes}
                />
                {errors.assetTypes && <RequiredTag />}
              </Flex>
              <Flex direction={'column'} width={'100%'}>
                <FocusedAreaSelectCheckboxes
                  selectedFocusedArea={focusedArea}
                  updateSelectedFocusedArea={setFocusedArea}
                  selectedFocusedAreaOther={focusedAreaOther}
                  updateSelectedFocusedAreaOther={setFocusedAreaOther}
                  errorInFocusedAreaOther={errors.focusedAreaOther}
                  showOtherCity={focusedArea.includes(FocusedArea.Other)}
                />
                {errors.focusedArea && <RequiredTag />}
              </Flex>

              <Flex gap={10} justify={'center'} alignItems={'center'}>
                <Button
                  bgColor={Colors.orangeColor}
                  color={Colors.blackColor}
                  onClick={handleProceed}
                  className={styles['action_btns']}
                  style={{ backgroundColor: Colors.orangeColor }}
                >
                  {Strings.proceed}
                </Button>
              </Flex>
              <Text
                textAlign={'center'}
                color={Colors.textGreyColor}
                fontWeight={'600'}
              >
                {Strings.proceedClickInfo}
                <CustomLink onClick={toggleTncModal}>{Strings.tnc}</CustomLink>
                {Strings.and}
                <CustomLink onClick={togglePrivacyModal}>
                  {Strings.pp}
                </CustomLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </ChakraProvider>
      {isOpenTncModal && (
        <ChannelPartnerTncModal onModalClose={toggleTncModal} />
      )}
      {isOpenPrivacyModal && <PrivacyModal onModalClose={togglePrivacyModal} />}
    </>
  );
};

export default Enrollment;
