export default {
  count: 'Count',
  login: 'Login',
  signup: 'Sign Up',
  decreaseCount: 'Decrease Count',
  pageNotFound: 'Page Not Found',
  channelPartners: 'Channel Partners',
  listChannelPartners: 'List Channel Partners',
  common: 'Common',
  tenants: 'Tenants',
  addtenant: 'Add Tenant',
  team: 'Team',
  enquiries: 'Enquiries',
  location: 'Location',
  ownership: 'Ownership',
  transfer: 'Transfer',
  ownershipTransfer: 'Ownership Transfer',
  customers: 'Customers',
  temp: 'Temp',
  properties: 'Properties',
  success: 'Success',
  addedSuccessfully: 'Added Successfully',
  updateSuccessfull: 'Update Successfull',
  uploadSuccessfull: 'Upload Successfull',
  updatedSuccessfully:
    'Updated Successfully- Note: Reload Required to Update Status',
  updateUnsuccessful: 'Update Unsuccessful',
  deletedSuccessfully: 'Deleted Successfully',
  balanceAmount: 'Balance Amount',
  uploadFullPayReceipt: 'Upload Payment Receipt',
  uploadTransactionRecepit: 'Upload Transaction Receipt',
  update: 'Update',
  eoi: 'EOI',
  overrideEOI: 'Override EOI',
  overriddedEOI: 'EOI Overridden',
  topup: 'Topup',
  uploadSignedEOIDocument: 'Upload Signed EOI Document',
  uploadPAS4SignedDocument: 'Upload Signed PAS4 Document',
  hbits: 'hBits',
  logout: 'Logout',
  logdata: 'Log Data',
  addproperty: 'Add Property',
  listOfProperties: 'List Of Properties',
  listOfEnquiries: 'List Of Enquiries',
  listofPropertyUpdates: 'List Of Property Updates',
  listofPropertyRent: 'List Of Property Rent',
  listofPropertyValuation: 'List Of Property Valuation',
  listofPropertyTransactions: 'List Of Property Transactions',
  listofbulkuploads: 'List Of Bulk Uploads',
  listofuserdocuments: 'List of User Documents',
  listoftenant: 'List of Tenants',
  listofrentforecasts: 'List Of Property Rent Forecast',
  listofpropertymedia: 'List Of Property Media',
  uploads: 'Bulk Upload of User Documents',
  enable: 'Enable',
  makeItPublic: 'Make it Public',
  makeItPrivate: 'Make it Private',
  suspend: 'Suspend',
  pending: 'Pending',
  unsavedChanges: 'Unsaved Changes',
  errorUpdatingPropertyStatus: 'Error Updating Property Status',
  listOfCustomers: 'List Of Customers',
  listOfTeammates: 'List of Teammates',
  documents: 'Documents',
  listProperties: 'List Properties',
  propertyUpdates: 'Property Updates',
  propertyRent: 'Property Rent',
  propertyValuations: 'Property Valuations',
  recentTransactions: 'Recent Transactions',
  bulkUploads: 'Bulk Uploads',
  userDocuments: 'User Documents',
  addAnotherDocument: '+ Add Another Document',
  submit: 'Submit',
  upload: 'Upload',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  verificationStatus: 'Verification Status',
  KYCVerStatus: 'KYC Status',
  action: 'Action',
  fields: 'Fields',
  export: 'Export',
  search: 'Search',
  clear: 'Clear',
  viewMore: 'View more',
  edit: 'Edit',
  delete: 'Delete',
  aboutus: 'About Us',
  media: 'Media',
  testimonials: 'Testimonials',
  faq: 'Faq',
  contactus: 'Contact Us',
  blog: 'Blog',
  career: 'Career',
  sitemap: 'Sitemap',
  cplogin: 'CP Login',
  cpregister: 'CP Registration',
  tnc: 'Terms and Conditions',
  pp: 'Privacy Policy',
  copyrighttext: 'COPYRIGHT © 2010-2025',
  howitworks: 'How It Works',
  // Customer Details Strings Begin
  investorOrganisation: 'Investor Organisation',
  investorProfession: 'Investor Profession',
  customerDetails: 'Customer Details',
  userOwnerships: 'User Ownerships',
  add: 'Add',
  propertyName: 'Property Name',
  purchasedOn: 'Purchased On',
  percentOwned: 'Percent Owned',
  ownershipStatus: 'Ownership Status',
  userOwnershipProgram: 'User Ownership Progress',
  srNo: 'Sr No',
  customerName: 'Customer Name',
  totalInvestmentAmount: 'Total Investment Amount',
  totalInvestmentPercentage: 'Total Investment %',
  lastAttemptedAt: 'Last Attempted At',
  stage: 'Stage',
  step: 'Step',
  investmentAmount: 'Investment Amount',
  userAlreadyInvested: 'User already Invested in this Property',
  createdOn: 'Created On',
  eoiAndTopUp: 'EOI and TopUp',
  userOwnershipDetails: 'User Ownership Details',
  lastModifiedOn: 'Last Modified On',
  interestedAmount: 'Interested Amount',
  kycDetails: 'KYC Details',
  viewKYCDetails: 'View KYC Details',
  viewKYCLogs: 'View KYC Logs',
  customerId: 'Customer Id',
  firstName: 'First Name',
  lastName: 'Last Name',
  viewLogs: 'View Logs',
  activityLogs: 'Activity Logs',
  addPropertyOwnership: 'Add Property Ownership',
  addRentForecast: 'Add Rent Forecast',
  updatePropertyOwnershipProgress: 'Update Property Ownership Progress',
  selectProperty: 'Select Property',
  listOfKYCLogs: 'List Of KYC Logs',
  userType: 'User Type',
  residentialStatus: 'Residential Status',
  editCustomerDetails: 'Edit Customer Details',
  basicDetails: 'Basic Details',
  guardianDetails: 'Guardian Details',
  panAndBankDetails: 'Pan & Bank Details',
  nominee: 'Nominee',
  saveChanges: 'Save Changes',
  addressDetails: 'Address Details',
  log: 'Log',
  date: 'Date',
  reason: 'Reason',
  listOfUserActivities: 'List Of User Activities',
  sno: 'S.No',
  url: 'Url',
  error: 'Error',
  method: 'Method',
  back: 'Back',
  taxResidencyReceived: 'Tax Residency Received',
  samePermanentAndCureentAddress: 'Permanent Address same as Current Address',
  accountType: 'Account Type',
  isNomineeMinor: 'Is Nominee a Minor?',
  permanentAddress: 'Permanent Address',
  country: 'Country',
  state: 'State',
  city: 'City',
  fullAddress: 'Full address',
  landmarkProvinceRegion: 'Landmark/Province/region',
  pinCode: 'Pin Code',
  documentType: 'Document Type',
  eoiAmount: 'EOI Amount',
  calculate: 'Calculate',
  appliedSuccessFull: 'Applied Successfully',
  appliedUnsuccessFull: 'Apply Failed',
  transactionReceipt: 'Transaction Receipt',
  documentNumber: 'Document Number',
  fatherName: 'Father Name',
  tds: 'TDS%',
  relationship: 'Relationship',
  nameOfGuardian: 'Name of Guardian',
  addressOfGuardian: 'Address of Guardian',
  dateOfBirth: 'Date of Birth',
  guardianName: 'Guardian Name',
  guardianAddress: 'Guardian Address',
  guardianNameAsOnPAN: 'Guardian Name as on PAN Card',
  guardianPAN: 'Guardian PAN No',
  guardianRelationship: 'Guardian Relationship',
  guardianRelation: 'Guardian Relation',
  relationShipWithMinor: 'RelationShip with Minor',
  guardianBankName: 'Guardian Bank Name',
  guardianBankIFSC: 'Guardian Bank IFSC',
  guardianBankAccountType: 'Guardian Bank Account Type',
  guardianBankAccountNo: 'Guardian Bank Account No',
  relationshipWithMinor: 'Relationship with Minor',
  relationshipWithHolder: 'Relationship with Holder',
  percentage: 'Percentage',
  emailID: 'Email ID',
  mobileNumber: 'Mobile Number',
  panNumber: 'PAN Number',
  bankName: 'Bank Name',
  nameAsPerPAN: 'Name As Per PAN',
  accountNumber: 'Account Number',
  ifscCode: 'IFSC Code',
  property: 'Property',
  propertyValuation: 'Property Valuation',
  neighbourhoods: 'Neighbourhoods',
  cities: 'Cities',
  searchUser: 'Search User',
  opsRole: 'OpsRole',
  selectedOpsRole: 'Selected Ops Role',
  updateUsersRoleHeading: `Update User's Role`,
  updateRole: 'Update Role',
  opsRoleUpdated: 'Ops Role Updated',
  deleteFromOpsTeam: 'Delete User from Ops Team',
  removedFromOpsTeam: 'Deleted User',
  cancel: 'Cancel',
  addUserToOpsTeam: 'Add User to OPS team',
  addedMember: 'Added Member',
  deletedMember: 'Deleted Member',
  internalServerError: 'Internal Server Error',
  failed: 'Failed',
  attribute: 'Attribute',
  createdAt: 'Created At',
  forAccountId: 'For Account Id',
  id: 'Id',
  eventName: 'Event Name',
  secondaryHolder: 'Secondary Holder KYC',
  TertiaryHolder: 'Tertiary Holder KYC',
  companyName: 'Company Name',
  designation: 'Designation',
  aadharNumber: 'Aadhar Number',
  trcNumber: 'TRC Number',
  kartaProfession: 'Karta Profession',
  kartaOrganisation: 'Karta Organisation',
  gender: 'Gender',
  mobileCountryCode: 'Mobile Country Code',
  assetTypeIntrestedIn: 'Asset Type intrested in',
  selectAllThatApplies: 'Select All that applies',
  selectState: 'Select State',
  focusedAreaMicroMarket: 'Focused Area/Micro Market',
  select: 'Select',
  moreDetails: 'More Details',
  required: 'Required',
  thisIsRequired: 'This is Required',
  proceedClickInfo: `By clicking 'Proceed' I agree to abide by the`,
  submitClickInfo: `By clicking 'SUBMIT' I agree to abide by the`,
  and: 'and',
  proceed: 'PROCEED',
  iam: 'I am',
  specify: 'Specify',
  distributorCategory: 'Distributor Category',
  assetUnderManagement: 'Asset Under Management',
  companysName: `Company's Name`,
  demateAccount: 'Please provide us your Demat Account Number',
  dematNumber: '*Demat Number',
  submitCap: 'SUBMIT',
  pleaseUploadTax: 'Please upload Declaration for Tax Exemption Document',
  uploadDocument: 'UPLOAD DOCUMENT',
  viewDetails: 'View Details',
  myOwnerShip: 'My Ownership Documents',
  particulars: 'Particulars',
  grossInflow: 'Gross Inflow',
  taxDeducted: `Tax % Deducted`,
  outflow: 'Outflow',
  tDSFundedByCO: `TDS Funded By CO.`,
  monthlyTotal: 'Monthly Total',
  query: 'Query',
  taxesRate: 'Taxes Rates',
  taxesRate1: 'For Indian citizen the tax rate is 10%',
  taxesRate2: `For NRI citizens who have not submitted tax residency form the
  applicable tax rate is 30%`,
  taxesRate3: `For RI Citizens who have submitted the tax residency form the
  respective country's tax rate will be applicable (as per DTAA)`,
  addReferalL: 'Add Referral',
  basixDetails: 'Basic Details',
  send: 'SEND',
  accountId: 'Account Id',
  gstAndOthers: 'GST & Other Details',
  dateOfIncorporation: 'Date of Incorporation',
  mainAccount: 'Main Account Name',
  relationWithMainAccount: 'Relation With Main Account',
  linkedAccount: 'Linked Accounts',
  noLinkedAccount: 'Current User has No Linked Accounts',
  noTeam: 'Current User has No Team Members',
  earnings: 'Earnings',
  save: 'Save',
  countryOfResidence: 'Country of Residence',
  tdsPercentage: 'TDS Percentage',
  notStarted: 'Not Started',
  msmeNumber: 'MSME Number',
  gstNumber: 'GST Number',
  arnNumber: 'ARN Number',
  euinNumber: 'EUIN Number',
  otherDocumentType: 'Other Document Type',
  expiryDate: 'Expiry Date',
  sebi: 'SEBI',
  sebiNumber: 'SEBI Number',
  reraNumber: 'RERA Number',
  othersBankName: 'Others Bank Name',
  channelPartnerDetails: 'Channel Partner Details',
  inProgress: 'In Progress',
  submitted: 'Submitted',
  updated: 'Updated',
  verificationInProgress: 'Verification In Progress',
  verified: 'Verified',
  invalidated: 'Invalidated',
  byclickingSend: `By clicking 'Send' I agree to abide by the`,
  termsandCondition: ' Terms & Conditions ',
  privacyPolicy: ' Privacy Policy ',
  pastReferal: 'Past Referrals',
  status: 'Status',
  documentsForDownload: ' Documents for Download',
  onlyAlphabetsAllowed: 'Only Alphabets are allowed',
  minLengthIsThree: 'Minimum 3 Characters',
  listOfChannelPartners: 'List Of Channel Partners',
  deals: 'Deals',
  oneMajorTenantNotice: 'Please Add One Major Tenant',
  displayOnLandingPages: 'Display on Landing Pages',
  videoUrlNotice: 'Video url should be of type youtube embed video links',
  urlNotice: 'URL should be of type youtube embed video links',
  mobleOtpError: 'Please enter registered phone number ',
  fillTheMandatoryFields: 'Please fill all the Mandatory* Fields',
  cannotAddEditTenants:
    'Cannot Add/Edit Tenants while Property is in LIVE State',
  startKyc: 'Start KYC',
  completeKyc: 'Complete KYC',
  investorAddress: 'Investor Address',
  cannotDeleteOwnAccount: 'You cannot delete your own account',
  cannotUpdateOwnAccount: 'You cannot update your own account',
  searchGreaterThanThree:
    'Search Key should be greater than or equal to 3 characters',
  invalidFileFormat: 'Invalid File',
  editTenant: 'Edit Tenant',
  noResultsFound: 'No Results Found',
  verify: 'Verify',
  reject: 'Reject',
  rejectionReason: 'Please provide a reason to reject',
  rejected: 'Rejected',
  guardian: 'Guardian',
  addressProofType: 'Address Proof Type',
  llpAddress: 'LLP Address',
  partnerAddress: 'Partner Address',
  directorAddress: 'Director Address',
  companyAddress: 'Company Address',
  trusteeAddress: 'Trustee Address',
  trustAddress: 'Trust Address',
  firmAddress: 'Firm Address',
  kartaAddress: 'Karta Address',
  representativeAddress: 'Representative Address Details',
  investNow: 'Invest Now',
  goThere: 'Go There',
  yourCurrentInvestmentStatus: 'Your current investment status :',
  startYourInvestmentStatus: 'Start your gaining journey',
  verificationInProg: 'Verification in process..',
  thankuForSubmittingDetals:
    'Thank you for submitting your details we will need a little time to verify the details you have entered',
  onApproval: 'On approval a notification will be sent over Email and SMS',
  fundingStartsOn: 'Funding starts on',
  openForInvestment: 'Open for Investment',
  openForFunding: 'Open for Funding',
  referralSubmitMsg:
    'We will take this form from here, we really appreciate the efforts you are putting to make the hBits family proud.',
  sendAnother: 'SEND ANOTHER',
  liveAssetSummary: 'Live Asset Summary',
  updateKyc:
    'Updating your KYC details will speed up your payments and unlock more features.',
  updateNow: 'UPDATE NOW',
  closed: 'Closed',
  investmentValue: 'Investment Value',
  registrationProcess: ' Registration in process...',
  weWillGetBack: 'We will get back to you shorty',
  forQueries: 'For queries, connect with your RM on',
  forQueries2:
    'You will soon get the access to your Dashboard, which will feature the rentals and other details once the process is completed.',
  contactusregestration: '+91 865 594 4200',
  congratulations: 'Congratulations!',
  congratulationsText:
    "It's great to have you as part of the hBits family, the most experienced and leading fractional ownership platform in the country. Thank you for choosing us",
  eoiDocument: 'EOI Document',
  topUpDocument: 'TopUp EOI Document',
  topUpPaymentReciept: 'TopUp Payment Reciept',
  eoiPaymentReciept: 'EOI Payment Reciept',
  pas4EOIDocument: 'PAS4 Document',
  fullPaymentReciept: 'FULL PAY Payment Reciept',
  abandonedInvestmemnt: 'Abandoned Investmemnt',
  expressInterest: 'Expression of Interest',
  pas4DocumentToBeSigned: 'PAS4 Document To Be Signed',
  fullPayment: 'Full Payment',
  registration: 'Registration',
  addedClient: 'Added Client',
  investmentDetails: 'Investment Details',
  ownershipDetails: 'Ownership Details',
  noDocumentsFound: 'No Documents Found',
  tooLarge: 'File Size Too Large, expected size < 10mb',
  tooLargeKycError: 'File Size Too Large, expected size < 2mb',
  amount: 'Amount',
  payments: 'Payments',
  fileType: 'Image PDF and Word only allowed',
  main: 'Main',
  representative: 'Representative',
  frontBackError: 'Front and Back Document should be of same type',
  uploadBackAadharError: 'Please upload back side of Aadhar',
  generateReceipt: 'Generate Receipt',
  typeOfPayment: 'Type of Payment',
  transactionId: 'Transaction ID',
  paymentOfMode: 'Payment Mode',
  paymentDate: 'Payment Date',
  propertyInvested: 'Property Invested',
  unlockPAS4: 'Unlock PAS4',
  serialNumber: 'Serial No.',
  linkACustomer: 'Link A Customer',
  link: 'Link Accounts',
  linkCustomertitle: 'You want to link the Customer as:',
  client: 'Client',
  clients: 'Clients',
  member: 'Member',
  noClients: 'Current User has No Clients',
  deleteUnsuccessful: 'Delete Unsuccessful',
  transferMember: 'Transfer Member',
  transferMemberSubtitle: "*Transfer Aryan Thakur's Clientele to",
  transferSuccessful: 'Transfered Successfully',
  transferUnsuccessful: 'Transfer Unsuccessful',
  leadSource: 'Lead Source',
  leadSubsource: 'Lead Sub-source',
  leadOwner: 'Lead Owner',
  leadNumber: 'Lead Number',
  cpMail: 'CP Mail',
  cpName: 'CP Name',
  cpPhone: 'CP Mob. No',
  relation: 'Relation',
  chooseBank: 'Choose Bank for Investment*',
  jointHolderDetails: 'Joint Holder Details',
  manager: 'Manager',
  listOfManagers: 'List of Lead Managers',
  managerDetails: 'Manager Details',
  addManager: 'Add Manager',
  referalCode: 'Referral Code',
  lmsId: 'Manager Id',
  customerReferralLink: 'Customer Referral Link',
  channelPartnerLink: 'Channel Partner Link',
  Validate: 'Validate Nominee',
  transferInProgress: 'Transfer Ownership Progress',
  transferLogs: 'Transfer Logs',
  listOfTransferredInvestments: 'List of Transferred Investments',
  userFriendlyId: 'User Friendly ID',
  ccdEquityShare: 'CCD & Equity Share',
  viewEarnings: 'View Earnings',
  recomputeTds: 'Recompute TDS',
  startDate: 'Start Date',
  endDate: 'End Date',
  investmentDocuments: 'Investment Documents',
  transferDocuments: 'Transfer Documents',
  equityShareNumber: 'Equity Share Number',
  ccdNumber: 'CCD Number',
  tdsLogs: 'TDS Logs',
  unregistered: 'Unregistered',
  listOfUnregisteredUsers: 'List of Unregistered Users',
  registeredSuccessfully: 'Registered Successfully',
  registerUnsuccessful: 'Register Unsuccessful',
  consentCoParceners: 'Consent from Co-Parceners',
  ccdShareCertificate: 'CCD & Share certificate',
  noRentData: 'No details found for provided month',
  addNewNominee: 'Add New Nominee',
  addSPV: 'Add SPV',
  onlyOneSPV: 'Only one SPV can be active',
  spvName: 'SPV Name',
  atleastOneSPV: 'Atleast one SPV should be active',
  EOIPaymentBankDetails: [
    `Our Payment Gateway is currently unavailable due to some regulatory issues.
    However account details for fund transfer are mentioned in the EOI page 4.`,
    `For any further assistance kindly reach out to your Investment Advisor.
    Furthermore, please find herewith the account details :`,
  ],
  spv: 'select Spv',
  communications: 'Communications',
  segment: 'Segment',
  emailTemplate: 'Email Template',
  whatsappp: 'Whatsapp Template',
  campaign: 'Campaign',
  preview: 'preview',
  Template_testing_window: 'Template testing window',
  dematStatus: 'Demat Status',
  documentRequired: 'Document upload is required to proceed.',
  eoiCompleted: 'EOI Completed!',
  eoiCompletionMessage:
    'Your Expression of Interest (EOI) is confirmed, and your payment has been securely processed. The property has entered the funding stage and will progress once fully subscribed.',
  eoiCompletionUpdateOverMailMessage: `You'll receive further updates on the progress through Email.`,
};
