import { Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { PageTitle } from '../../styled-components';
import POC from './POC';
import FAQ from './Faq';
import Overview from './Overiew';
import ChannelPartnerKyc from './ChannelPartnerKyc';
import { NavbarLinkHash } from '../../DashboardContents';
import Assets from './Assets';
import Team from '../../../ChannelPartnerTeams';
import Client from './Clients';
import { useLocation } from 'react-router-dom';
import Collaterals from './Collateral';

interface MainSectionProps {
  controlMainSec: string;
}

const MainSection: React.FC<MainSectionProps> = ({
  controlMainSec: tabName,
}) => {
  const location = useLocation();
  const Section = useMemo(() => {
    switch (location.hash) {
      case NavbarLinkHash.FAQs:
        return <FAQ />;
      case NavbarLinkHash.POC:
        return <POC />;
      case NavbarLinkHash.Overview:
        return <Overview />;
      case NavbarLinkHash.Clients:
        return <Client />;
      case NavbarLinkHash.Kyc:
        return <ChannelPartnerKyc />;
      case NavbarLinkHash.Assets:
        return <Assets />;
      case NavbarLinkHash.MyTeam:
        return <Team />;
      case NavbarLinkHash.Collaterals:
        return <Collaterals />;
      default:
        return <p>404 not found</p>;
    }
  }, [location.hash]);
  return (
    <Flex width={'100%'}>
      <PageTitle>{Section}</PageTitle>
    </Flex>
  );
};

export default MainSection;
