import {
  AssetType,
  AUM,
  ChannelPartnerOraganizationRole,
  ChannelPartnerType,
  DistributorCategory,
  FocusedArea,
} from '@hBits.Core';
import * as Joi from 'joi';
import { getClassSchema, JoiSchema } from 'joi-class-decorators';

class EnrollmentPayloadValidator {
  @JoiSchema(Joi.string().required())
  country = '';

  @JoiSchema(Joi.string().required())
  state = '';

  @JoiSchema(
    Joi.string()
      .pattern(/^([a-zA-Z]+\s)*[a-zA-Z]+$/)
      .required(),
  )
  city = '';

  @JoiSchema(
    Joi.string()
      .valid(...Object.values(ChannelPartnerType))
      .required(),
  )
  channelPartnerType = '';

  @JoiSchema(Joi.string().min(3))
  companyName = '';

  @JoiSchema(
    Joi.string()
      .valid(...Object.values(AUM))
      .required(),
  )
  assetUnderManagement = '';

  @JoiSchema(
    Joi.string()
      .valid(...Object.values(DistributorCategory))
      .required(),
  )
  distributorCategory = '';

  @JoiSchema(
    Joi.array()
      .min(1)
      .items(Joi.valid(...Object.values(AssetType))),
  )
  assetTypes = [];

  @JoiSchema(
    Joi.array()
      .min(1)
      .items(Joi.valid(...Object.values(FocusedArea))),
  )
  focusedArea = [];

  @JoiSchema(
    Joi.string()
      .valid(...Object.values(ChannelPartnerOraganizationRole))
      .required(),
  )
  role = '';

  @JoiSchema(Joi.string().pattern(/^([a-zA-Z]+\s)*[a-zA-Z]+$/))
  focusedAreaOther = '';

  @JoiSchema(Joi.string().required())
  accountId = '';
}

export default function validateEnrollmentPayload(enrollmentPayload: any) {
  const schema = getClassSchema(EnrollmentPayloadValidator);
  const validate: any = schema.validate(enrollmentPayload, {
    abortEarly: false,
  });

  let errorInKeys: string[] = [];

  if (!validate.error) return errorInKeys;

  errorInKeys = validate.error.details.map(
    (detail: { path: string[] }) => detail.path[0],
  );

  const errorsFeilds = errorInKeys.reduce(
    (accumulator: any, feild: any) => ({
      ...accumulator,
      [feild]: true,
    }),
    {},
  );

  return errorsFeilds;
}
