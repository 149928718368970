function convertEnumToArray(
  dropdown: { [s: string]: string } | ArrayLike<string>,
) {
  const arrayObjects = [];
  for (const [propertyKey, propertyValue] of Object.entries(dropdown)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    arrayObjects.push({ key: propertyKey, value: propertyValue });
  }
  return arrayObjects;
}

export default convertEnumToArray;
