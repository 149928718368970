import React, { useEffect, useState, useMemo } from 'react';
import { Flex, Text, Select, Image, Img, Spinner } from '@chakra-ui/react';
import { AccountManager, Strings } from '@hBits.Core';
import { Colors } from 'apps/customer-portal-web/src/theme';
import Styles from '../mainsection.module.scss';
import moment from 'moment';
import { getAllProperties } from 'apps/customer-portal-web/src/store/actions/investment.actions';
import {
  useAppDispatch,
  useAppSelector,
} from 'apps/customer-portal-web/src/store/hooks';
import { getInvestmentDocument } from 'apps/customer-portal-web/src/services/investment-dashboard/Documents';
import PdfIcon from 'apps/customer-portal-web/src/assets/PDFIcon.svg';
import { DocumentType, InvestmentStage } from '@hBits.Core';
import { saveAs } from 'file-saver';

const Documents = () => {
  const dispatchApp = useAppDispatch();
  const properties = useAppSelector(
    (state) => state.InvestmentReducer.properties,
  );

  const [currentDocumnets, setcurrentDocumnets] = useState<[]>([]);
  const [currentProperty, setCurrentProperty] = useState<any>();
  const [currentPropertyId, setCurrentPropertyId] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);

  const accountId = AccountManager.getAccountId();

  const getPropertyImage = (propeId: string) => {
    const curntProp = properties.filter(
      (item: any) => item?.propertyId === propeId,
    );
    if (curntProp && curntProp.length !== 0) {
      return {
        image: curntProp[0]?.propertyDetails?.propertyMediaModel
          ?.desktopBannerUrl?.[0]?.imageUrl
          ? curntProp[0]?.propertyDetails?.propertyMediaModel
              ?.desktopBannerUrl?.[0]?.imageUrl
          : curntProp[0]?.propertyDetails?.propertyMediaModel
              ?.desktopBannerUrl?.[0],
        name: curntProp[0]?.investmentPropertyResponsePayload?.displayName,
        investmentStage: curntProp[0]?.investmentStage,
      };
    }
    return {};
  };

  const getPropertyDocuments = async (propertyId: string) => {
    setLoader(true);
    let documents = await getInvestmentDocument(accountId, propertyId);
    const currentProperty = properties.filter(
      (itm: any) => itm.propertyId === propertyId,
    );
    if (
      currentProperty[0]?.investmentStage !==
      InvestmentStage.RegistrationComplete
    ) {
      documents = documents.filter(
        (itm: any) => itm.documentType !== DocumentType.OWNERSHIP_DOCUMENT,
      );
    }
    setcurrentDocumnets(documents);
    setLoader(false);
  };

  useEffect(() => {
    if (properties?.length === 0) {
      dispatchApp(getAllProperties(accountId));
    }
  }, []);

  useEffect(() => {
    if (currentPropertyId) {
      setCurrentProperty(getPropertyImage(currentPropertyId));
      getPropertyDocuments(currentPropertyId);
    }
  }, [currentPropertyId]);

  useEffect(() => {
    if (properties?.length !== 0) {
      getPropertyDocuments(properties[0]?.propertyId);
      setCurrentProperty(getPropertyImage(properties[0]?.propertyId));
    }
  }, [properties]);

  const splitDocName = (file: string = '') => {
    if (file.length === 0) {
      return '';
    }
    const fileArray = file.split('.');
    return `${fileArray[0].substring(0, 15)}.${fileArray.pop()}`;
  };

  const docDowloadBtn = (documentName: string, values: string) => {
    saveAs(values, `${documentName}`);
  };

  return (
    <Flex
      flexDir={'column'}
      flex={1}
      mx={[0, 0, 5, 5]}
      mb={'100px'}
      mt={[5, 5, 0, 0]}
    >
      <Flex>
        <Select
          w={['sm']}
          bg={Colors.white}
          borderRadius={5}
          onChange={(event) => {
            setCurrentPropertyId(event.target.value);
          }}
        >
          {properties.length > 0 &&
            properties.map((item: any, idx: number) => (
              <option value={item?.propertyId} key={idx}>
                {item?.investmentPropertyResponsePayload?.displayName}
              </option>
            ))}
        </Select>
      </Flex>
      <Flex
        bg={Colors.white}
        w="100%"
        mt={5}
        px={4}
        pt={5}
        borderRadius={10}
        flexDirection={'column'}
      >
        <Text fontSize={'xl'} as="b" color={Colors.headerColor}>
          {Strings.myOwnerShip} - {currentProperty?.investmentStage == "Transferred" ? "Transferred" : "Active"}
        </Text>
        <Image
          src={currentProperty?.image}
          alt="Dan Abramov"
          h={'180px'}
          my={5}
          borderRadius={5}
        />
        <Text fontSize={'16px'} as={'b'} color={Colors.headerColor}>
          {currentProperty?.name}
        </Text>
        {loader ? (
          <Flex
            justifyContent={'flex-start'}
            alignItems="center"
            ml={['10px', '10px', '20px', '20px']}
            mt={'20px'}
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        ) : (
          <Flex flexWrap={'wrap'} pb={'50px'} justifyContent="space-between">
            {currentDocumnets.map((item: any, idx: number) => (
              <Flex mr={5} mt={4} key={idx} mb={'20px'} w={'25%'}>
                <Img
                  borderRadius={'5px'}
                  height={20}
                  width={10}
                  src={PdfIcon}
                />
                <Flex
                  flexDirection={'column'}
                  justifyContent="center"
                  ml={2}
                  flexWrap={'wrap'}
                >
                  <Text color={'#535353'} as={'b'} w={'70%'} fontSize={'10px'}>
                    {item.documentName}
                  </Text>
                  <Text
                    className={Styles['documnetpaymetrecipt']}
                    color={Colors.grey}
                    fontSize={'12px'}
                  >
                    {`  Date: ${moment(item?.uploadedAtUtc).format(
                      'DD/MM/YYYY',
                    )}`}
                  </Text>

                  <Text
                    className={Styles['documnetpaymetrecipt']}
                    color={Colors.headerColor}
                    as="u"
                    fontSize={'14px'}
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  >
                    <a
                      onClick={() =>
                        docDowloadBtn(item.documentName, item?.url)
                      }
                    >
                      Download
                    </a>
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Documents;
