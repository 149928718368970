import { Flex, Img, Link, Text } from '@chakra-ui/react';
import { Colors, Environment, Strings } from '@hBits.Core';
import {
  convertDate,
  getCurrentDate,
} from 'apps/customer-portal-web/src/utils';
import { inWords } from '../../../ChannelPartner/helper-components/utils';

const DISPLAY_NAME_LENGTH = 20;

const AssetListItem = (props: any) => {
  const { property } = props;
  const landingPage = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  const textSlicer = (value: string = '', dlimiter: number = 0) => {
    return value.length >= dlimiter
      ? `${value.substring(0, dlimiter)}...`
      : value;
  };

  return (
    <Link
      href={`${landingPage}/viewproperty/${property.locations[0].city}/${property.id}`}
      textDecoration={'none'}
      sx={{
        '&:hover': { textDecoration: 'none' },
      }}
    >
      <Flex
        direction={'column'}
        p={5}
        background={Colors.white}
        borderRadius={'10px'}
        minHeight={300}
        boxShadow={'0 3px 6px rgba(0, 0, 0, 0.10196078431372549)'}
      >
        <Img
          borderRadius={'5px'}
          objectFit="cover"
          boxSize={['400px', '220px']}
          src={
            property.propertyMediaModel?.propertyBannerBox[0]?.imageUrl
              ? property.propertyMediaModel?.propertyBannerBox[0]?.imageUrl
              : property.propertyMediaModel?.propertyBannerBox[0]
          }
        />
        <Flex direction={'column'} mt={4}>
          <Text
            fontWeight={'bold'}
            fontSize={'xl'}
            color={Colors.headerBlue}
            textTransform={'uppercase'}
            h={'50px'}
          >
            {textSlicer(property.displayName, DISPLAY_NAME_LENGTH)}
          </Text>
          <Text
            color={
              new Date(property?.propertyInvestment?.expectedGoLiveDate) >
              getCurrentDate()
                ? Colors.lightRed
                : property?.propertyInvestment
                    ?.percentagePropertyFundedDisplayedToUser < 100
                ? Colors.lightGreen
                : Colors.lightRed
            }
            h={'40px'}
            mt={'20px'}
            fontWeight={'bold'}
            fontSize={'md'}
          >
            {new Date(property?.propertyInvestment?.expectedGoLiveDate) >
            getCurrentDate()
              ? `${Strings.fundingStartsOn} ${convertDate(
                  new Date(property?.propertyInvestment?.expectedGoLiveDate),
                )}`
              : property?.propertyInvestment
                  ?.percentagePropertyFundedDisplayedToUser < 100
              ? Strings.openForFunding
              : Strings.closed}
          </Text>
          <Text
            fontSize={'sm'}
            mt={3}
            color={Colors.textGreyColor}
            fontWeight={'light'}
          >
            {Strings.location}
          </Text>
          <Text
            color={Colors.blackColor}
            fontSize={'md'}
            fontWeight={'bold'}
            h={'40px'}
          >
            {property.locations[0]?.city}, {property.locations[0]?.area}
          </Text>
          <Text
            fontSize={'sm'}
            mt={3}
            color={Colors.textGreyColor}
            fontWeight={'light'}
          >
            {Strings.investmentValue}
          </Text>
          <Text color={Colors.blackColor} fontSize={'md'} fontWeight={'bold'}>
            ₹{inWords(property?.propertyFinancials?.totalAssetCost)}
          </Text>
        </Flex>
      </Flex>
    </Link>
  );
};

export default AssetListItem;
