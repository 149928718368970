import React, { useEffect, useState } from 'react';
import {} from 'apps/customer-portal-web/src/theme/colors';
import { FC } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import '../propertyInvestment.scss';
import { useDispatch, useSelector } from 'react-redux';
import CustomFrame from '../CustomFrame';
import EOIStep1 from './EOIStep1';
import EOIStep2 from './EOIStep2';
import EOIStep3 from './EOIStep3';
import {
  getEoiDocumentUrl,
  getTopUpEoiDocumentUrl,
  setEoiDocumentSucess,
  setEoiTopUpDocumentSucess,
} from 'apps/customer-portal-web/src/services/propertyInvestment/eoiStepServices';
import { getEoiStep } from '../util';
import { setInvestmentDetails } from 'apps/customer-portal-web/src/store/actions';
import EOIStep4 from './EOIStep4';

const EoiStep: FC<{}> = () => {
  const investmentModal = useSelector(
    (state: any) => state.InvestmentReducer?.propertyData,
  );

  const {
    investmentAmountEoi,
    minTicketSizeApplied,
    maxTicketSizeApplied,
    topUpAmountEoi = {},
  } = investmentModal;

  const isTopUp = Object.keys(topUpAmountEoi || {}).length > 0;
  const dispatch = useDispatch();
  const [currentEoiValue, setCurrentEoiValue] = useState(
    getEoiStep(investmentAmountEoi, topUpAmountEoi),
  );
  const [retryPayment, setRetryPayment] = useState(false);

  useEffect(() => {
    setCurrentEoiValue(getEoiStep(investmentAmountEoi, topUpAmountEoi));
  }, [investmentAmountEoi, topUpAmountEoi]);

  const {
    investmentAmountToBePaidAtEOI,
    investmentAmount,
    percentageInvestmentAmountToBePaidAtEOIApplied,
    investmentId,
    eoiDocumentSigningStatus,
    minInvestmentAmountToBePaidAtEOI,
    eoiInvestmentAmontPaid,
  } = investmentAmountEoi;
  const toast = useToast();
  const [zohoModal, setZohoModal] = useState(false);
  const [zohoDocLink, setZohoDocLink] = useState('');
  const [eoiDocLoader, setEoiDocLoader] = useState(false);

  const documentSigningError = (error: string) => {
    toast({
      title: 'Error',
      description: error,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const openZoho = async () => {
    setEoiDocLoader(true);
    if (isTopUp) {
      const zohoLink = await getTopUpEoiDocumentUrl(
        investmentId,
        (error: string) => {
          documentSigningError(error);
        },
      );
      if (!Array.isArray(zohoLink)) {
        setZohoDocLink(zohoLink);
        setZohoModal(true);
      }
    } else {
      const zohoLink = await getEoiDocumentUrl(
        investmentId,
        (error: string) => {
          documentSigningError(error);
        },
      );
      if (!Array.isArray(zohoLink)) {
        setZohoDocLink(zohoLink);
        setZohoModal(true);
      }
    }
    setEoiDocLoader(false);
  };

  const closeEDocumentModal = async () => {
    if (isTopUp) {
      const signedDocument = await setEoiTopUpDocumentSucess(
        investmentId,
        investmentModal,
        (error: string) => {
          documentSigningError(error);
        },
      );
      dispatch(setInvestmentDetails(signedDocument));
    } else {
      const signedDocument = await setEoiDocumentSucess(
        investmentId,
        investmentModal,
        (error: string) => {
          documentSigningError(error);
        },
      );
      dispatch(setInvestmentDetails(signedDocument));
    }
  };

  const renderEoiStep = (value: number) => {
    switch (value) {
      case 0:
        return (
          <EOIStep1
            openZoho={openZoho}
            minTicketSizeApplied={minTicketSizeApplied}
            investmentAmountToBePaidAtEOI={investmentAmountToBePaidAtEOI}
            investmentAmount={investmentAmount}
            minPercentageToPaid={percentageInvestmentAmountToBePaidAtEOIApplied}
            investmentId={investmentId}
            eoiDocumentSigningStatus={eoiDocumentSigningStatus}
            minInvestmentAmountToBePaidAtEOI={minInvestmentAmountToBePaidAtEOI}
            topUpAmountEoi={topUpAmountEoi}
            eoiDocLoader={eoiDocLoader}
            maxTicketSizeApplied={maxTicketSizeApplied}
            eoiInvestmentAmountPaid={eoiInvestmentAmontPaid}
            investmentAmountEoi={investmentAmountEoi}
          />
        );
      case 1:
        return <EOIStep2 openZoho={openZoho} eoiDocLoader={eoiDocLoader} />;
      case 2:
        return <EOIStep3 investmentModal={investmentModal} />;
      case 3:
        return <EOIStep4 setRetryPayment={setRetryPayment} />;
      default:
        return <></>;
    }
  };
  return (
    <Flex justifyContent="center">
      {zohoModal && (
        <CustomFrame
          isOpen={zohoModal}
          zohoDocLink={zohoDocLink}
          isTopUp={isTopUp}
          onClose={() => setZohoModal(false)}
          title="Please sign the document"
          investmentId={investmentId}
          onCloseFrame={closeEDocumentModal}
        />
      )}
      {renderEoiStep(!retryPayment ? currentEoiValue : 0)}
    </Flex>
  );
};

export default EoiStep;
