import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Environment, Strings } from '@hBits.Core';
import { Colors } from 'apps/customer-portal-web/src/theme';
import { NUM_DIFFERENTIATION } from 'apps/landing-pages-web/src/components/common/utils/helper';
import styles from '../mainsection.module.scss';

export interface PropertyProps {
  propertyId: string;
  displayName: string;
  ownedPercentage: number;
  investedValue: number;
  monthlyRent: number;
  city: string;
  image: string;
  avgRentalYield?: number;
  targetIRR?: number;
  showViewDetails?: boolean;
  children?: any;
  shareNumber?: string;
  ccd?: string;
  investmentStage?:string;
}

const SinglePropertyCard = ({
  propertyId,
  displayName,
  ownedPercentage,
  investedValue,
  monthlyRent,
  city,
  image,
  avgRentalYield,
  targetIRR,
  showViewDetails,
  children,
  shareNumber,
  ccd,
  investmentStage,
}: PropertyProps) => {
  return (
    <Flex
      className={styles['box-shadow-contsiner']}
      borderRadius={5}
      px={5}
      py={5}
      bg={Colors.white}
      flexDirection="column"
      w={'100%'}
    >
      <Flex flexDirection={['column', 'column', 'column', 'row']}>
        <Flex w={['100%', '100%', '100%', '65%']}>
          <Image
            w={'100%'}
            h={['auto', 'auto', 'auto', '320']}
            objectFit="cover"
            src={image}
            alt={displayName}
            borderRadius={3}
            mb={2}
          />
        </Flex>
        <Box
          flex={1}
          w={['100%', '100%', '100%', '40%']}
          pl={[0, 0, 0, 5]}
          position={'relative'}
          pb={10}
        >
          <Text
            fontSize={['16px', '18px']}
            as={'b'}
            color={Colors.headerColor}
            mb={[2, 0, 0, 0]}
          >
            {displayName}
          </Text>
          <Flex flexDirection={'column'} mt={5}>
            <Flex
              justifyContent={'space-between'}
              borderBottomColor={Colors.grey300}
              borderBottomWidth={1}
              alignItems={'center'}
              pb={1}
              pt={1}
            >
              <Text
                fontSize={'14px'}
                color={Colors.grey200}
                fontWeight={'normal'}
              >
                Property Owned
              </Text>
              <Text
                fontSize={'16px'}
                fontWeight={'900'}
                color={Colors.blackColor}
              >
                {ownedPercentage?.toFixed(2)}%
              </Text>
            </Flex>
            {/* currently we did't have value for Monthly Rent */}

            {/* <Flex
              justifyContent={'space-between'}
              mt={2}
              pb={1}
              pt={1}
              borderBottomColor={Colors.grey300}
              borderBottomWidth={1}
              alignItems={'center'}
            >
              <Text
                fontSize={'14px'}
                color={Colors.grey200}
                fontWeight={'normal'}
              >
                Monthly Rent
              </Text>
              <Text
                fontSize={'16px'}
                fontWeight={'900'}
                color={Colors.blackColor}
              >
                ₹{NUM_DIFFERENTIATION(monthlyRent)}
              </Text>
            </Flex> */}
            <Flex
              justifyContent={'space-between'}
              mt={2}
              pb={1}
              pt={1}
              borderBottomColor={Colors.grey300}
              borderBottomWidth={1}
              alignItems={'center'}
            >
              <Text
                fontSize={'14px'}
                color={Colors.grey200}
                fontWeight={'normal'}
              >
                Location
              </Text>
              <Text
                fontSize={'16px'}
                fontWeight={'900'}
                color={Colors.blackColor}
              >
                {city}
              </Text>
            </Flex>
            <Flex
              justifyContent={'space-between'}
              mt={2}
              pb={1}
              pt={1}
              borderBottomColor={Colors.grey300}
              borderBottomWidth={avgRentalYield && 1}
              alignItems={'center'}
            >
              <Text
                fontSize={'14px'}
                color={Colors.grey200}
                fontWeight={'normal'}
              >
                Investment Value
              </Text>
              <Text
                fontSize={'16px'}
                fontWeight={'900'}
                color={Colors.blackColor}
              >
                ₹{NUM_DIFFERENTIATION(investedValue, false)}
              </Text>
            </Flex>
            {/* stage */}
            <Flex
              justifyContent={'space-between'}
              mt={2}
              pb={1}
              pt={1}
              borderBottomColor={Colors.grey300}
              borderBottomWidth={avgRentalYield && 1}
              alignItems={'center'}
            >
              <Text
                fontSize={'14px'}
                color={Colors.grey200}
                fontWeight={'normal'}
              >
               Stage
              </Text>
              <Text
                fontSize={'16px'}
                fontWeight={'900'}
                color={Colors.blackColor}
              >
                {investmentStage === "Transferred" ? "Transferred" : "Active"}
              </Text>
            </Flex>
            {avgRentalYield && (
              <Flex
                justifyContent={'space-between'}
                mt={2}
                pb={1}
                pt={1}
                borderBottomColor={Colors.grey300}
                borderBottomWidth={1}
                alignItems={'center'}
              >
                <Text
                  fontSize={'14px'}
                  color={Colors.grey200}
                  fontWeight={'normal'}
                >
                  Avg Rental Yield
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'900'}
                  color={Colors.blackColor}
                >
                  {avgRentalYield}%
                </Text>
              </Flex>
            )}
            {avgRentalYield && (
              <Flex
                justifyContent={'space-between'}
                mt={2}
                pb={1}
                pt={1}
                borderBottomColor={shareNumber ? Colors.grey300 : ''}
                borderBottomWidth={shareNumber ? 1 : 0}
                alignItems={'center'}
              >
                <Text
                  fontSize={'14px'}
                  color={Colors.grey200}
                  fontWeight={'normal'}
                >
                  Expected IRR
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'900'}
                  color={Colors.blackColor}
                >
                  {targetIRR}%
                </Text>
              </Flex>
            )}
          </Flex>
          {shareNumber && (
            <Flex
              justifyContent={'space-between'}
              mt={2}
              pb={1}
              pt={1}
              borderBottomColor={ccd ? Colors.grey300 : ''}
              borderBottomWidth={ccd ? 1 : 0}
              alignItems={'center'}
            >
              <Text
                fontSize={'14px'}
                color={Colors.grey200}
                fontWeight={'normal'}
              >
                Share No.
              </Text>
              <Text
                fontSize={'16px'}
                fontWeight={'900'}
                color={Colors.blackColor}
              >
                {shareNumber}
              </Text>
            </Flex>
          )}
          {ccd && (
            <Flex
              justifyContent={'space-between'}
              mt={2}
              pb={1}
              pt={1}
              alignItems={'center'}
            >
              <Text
                fontSize={'14px'}
                color={Colors.grey200}
                fontWeight={'normal'}
              >
                CCD
              </Text>
              <Text
                fontSize={'16px'}
                fontWeight={'900'}
                color={Colors.blackColor}
              >
                {ccd}
              </Text>
            </Flex>
          )}
          {showViewDetails && (
            <Flex position={'absolute'} right={0} bottom={'10px'}>
              <Text
                as="u"
                color={Colors.headerColor}
                fontWeight={'900'}
                fontSize={['14px']}
                alignSelf={'flex-end'}
                cursor={'pointer'}
                onClick={() => {
                  window.location.assign(
                    Environment.getInstance().getParam(
                      'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
                    ) + `/properties/${propertyId}`,
                  );
                }}
              >
                {Strings.viewDetails}
              </Text>
            </Flex>
          )}
        </Box>
      </Flex>
      {children}
    </Flex>
  );
};

export default SinglePropertyCard;
