import { useToast } from '@chakra-ui/react';
import { fetchBankData } from 'apps/customer-portal-web/src/components/Kyc/ApiData/ApiData';
import UploadProof from 'apps/customer-portal-web/src/components/Kyc/HelperComponents/UploadProof';
import accountIdManager from 'libs/src/auth/account-manager';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  capitalizeEachLetter,
  capitalizeFirstLetter,
  isEmpty,
} from '../../../../../../utils/index';
import { addBankDetails, getData, updateBankDetails } from '../ApiData/apiData';
import CpKycContainerBottomTile from '../HelperComponents/CpKycContainerBottomTile';
import '../index.scss';

type FormInputs = {
  panNumber: string;
  panHolderName: string;
  bankName: string;
  bankAccountNumber: number;
  ifscCode: string;
  msmeNumber: any;
};
interface BankDetailsProps {
  updateStateCallback: (state: any) => void;
  kycVerified: boolean;
  checkKycStatus: () => void;
}

function PanBankDetails(props: BankDetailsProps) {
  const toast = useToast();
  const { updateStateCallback, kycVerified, checkKycStatus } = props;

  React.useEffect(() => {
    fetchBankData().then((response) => setBankData(response));
  }, []);

  const [bankAccountProofUrl, setBankAccountProofUrl] = useState('');
  const [bankAccountProofType, setBankAccountProofType] = useState<any>();
  const [errorBankStatusRejected, setErrorBankStatusRejected] = useState(false);
  const [errorBankStatusRejectedMessage, setErrorBankStatusRejectedMessage] =
    useState('');
  const [bankData, setBankData] = useState<any[]>([]);
  const [panIdProofUrl, setPanIdProofUrl] = useState('');
  const [panIdProofType, setPanIdProofType] = useState<any>();
  const [errorPanStatusRejected, setErrorPanStatusRejected] = useState(false);
  const [errorPanStatusRejectedMessage, setErrorPanStatusRejectedMessage] =
    useState('');
  const [msmeProofUrl, setMsmeProofUrl] = useState('');
  const [msmeProofType, setMsmeProofType] = useState<any>();
  const [errorMsmeStatusRejected, setErrorMsmeStatusRejected] = useState(false);
  const [errorMsmeStatusRejectedMessage, setErrorMsmeStatusRejectedMessage] =
    useState('');
  const [isSaveAndContinueClicked, setIsSaveAndContinueClicked] =
    useState(false);
  const [bankDetailsHolderData, setBankDetailsHolderData] = useState<any>();

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<FormInputs>();

  const saveAndContinue = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    updateStateCallback('gstotherdetails');
    checkKycStatus();
  };

  const [userAccountId, setUserAccountId] = useState<string>();

  React.useEffect(() => {
    if (!accountIdManager?.getAccountId()) return;
    setUserAccountId(accountIdManager?.getAccountId());
    getData(accountIdManager?.getAccountId()).then((res: any) => {
      if (isEmpty(res)) return;
      if (res.channelPartnerBankDetails) {
        if (res.channelPartnerBankDetails.bankAccountProofUrl) {
          setBankAccountProofType(
            res.channelPartnerBankDetails.bankAccountProofType,
          );
          setBankAccountProofUrl(
            res.channelPartnerBankDetails.bankAccountProofUrl,
          );
          if (
            res.channelPartnerBankDetails.bankAccountVerificationStatus ==
            'Rejected'
          ) {
            setErrorBankStatusRejected(true);
            setErrorBankStatusRejectedMessage(
              res.channelPartnerBankDetails.bankAccountRejectionReason,
            );
          }
        }
        if (res.channelPartnerBankDetails.panIdProofUrl) {
          setPanIdProofType(res.channelPartnerBankDetails.panIdProofType);
          setPanIdProofUrl(res.channelPartnerBankDetails.panIdProofUrl);
          if (
            res.channelPartnerBankDetails.panVerificationStatus == 'Rejected'
          ) {
            setErrorPanStatusRejected(true);
            setErrorPanStatusRejectedMessage(
              res.channelPartnerBankDetails.panRejectionReason,
            );
          }
        }
        if (res.channelPartnerBankDetails.msmeProofUrl) {
          setMsmeProofType(res.channelPartnerBankDetails.msmeProofType);
          setMsmeProofUrl(res.channelPartnerBankDetails.msmeProofUrl);
          if (
            res.channelPartnerBankDetails.msmeVerificationStatus == 'Rejected'
          ) {
            setErrorMsmeStatusRejected(true);
            setErrorMsmeStatusRejectedMessage(
              res.channelPartnerBankDetails.msmeRejectionReason,
            );
          }
        }

        reset({
          bankName: res.channelPartnerBankDetails.bankName,
          bankAccountNumber: res.channelPartnerBankDetails.bankAccountNumber,
          ifscCode: res.channelPartnerBankDetails.ifscCode,
          panNumber: res.channelPartnerBankDetails.panNumber,
          panHolderName: res.channelPartnerBankDetails.panHolderName,
          msmeNumber: res.channelPartnerBankDetails.msmeNumber,
        });

        setBankDetailsHolderData(res.channelPartnerBankDetails);
      }
    });
  }, []);

  const handleSubmitButton = () => {
    setIsSaveAndContinueClicked(true);
  };

  const onSubmit = (formData: any) => {
    const BankDetailsData = {
      bankName: formData.bankName,
      bankAccountNumber: formData.bankAccountNumber,
      ifscCode: formData.ifscCode,
      bankAccountProofUrl: bankAccountProofUrl,
      bankAccountProofType: bankAccountProofType,
      panNumber: formData.panNumber,
      panHolderName: formData.panHolderName,
      panIdProofUrl: panIdProofUrl,
      panIdProofType: panIdProofType,
      msmeNumber: formData.msmeNumber,
      msmeProofUrl: msmeProofUrl,
      msmeProofType: msmeProofType,
    };

    if (
      !errorBankStatusRejected &&
      !errorMsmeStatusRejected &&
      !errorPanStatusRejected
    ) {
      if (bankAccountProofUrl && panIdProofUrl) {
        {
          !bankDetailsHolderData &&
            addBankDetails(userAccountId, BankDetailsData).then((response) => {
              if (response.status === 200 || response.status === 204) {
                toast({
                  title: 'Success',
                  description: 'Details have been Submitted',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
                saveAndContinue();
              } else {
                alert(response.error.errorMessage);
              }
            });
        }
        {
          bankDetailsHolderData &&
            updateBankDetails(userAccountId, BankDetailsData).then(
              (response) => {
                if (response.status === 200 || response.status === 204) {
                  toast({
                    title: 'Success',
                    description: 'Details have been Updated',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  });
                  saveAndContinue();
                } else {
                  alert(response.error.errorMessage);
                }
              },
            );
        }
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="cpkyc_row cpkyc_margin-top-2">
          <div className="cpkyc_col-12 cpkyc_col-md-4 ">
            <div className="cpkyc_bottom-line">
              <select
                className="cpkyc_ "
                {...register('bankName', {
                  required: 'Select an Option',
                })}
                disabled={kycVerified}
                value={capitalizeFirstLetter(watch().bankName)}
              >
                <option value="" disabled selected hidden>
                  *Select Bank
                </option>
                <option className="cpkyc_ "></option>
                {bankData.map((option) => (
                  <option
                    className="cpkyc_ "
                    value={capitalizeFirstLetter(option.name)}
                  >
                    {capitalizeFirstLetter(option.name)}
                  </option>
                ))}
              </select>
            </div>
            {errors.bankName && (
              <p className="cpkyc_errorMessage">Select an Option</p>
            )}
          </div>
          <div className="cpkyc_col-12 cpkyc_col-md-4 cpkyc_margin-top-1_for-mobile ">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="*Bank Account Number"
                disabled={kycVerified}
                {...register('bankAccountNumber', {
                  required: 'Account Number is required',
                  maxLength: {
                    value: 18,
                    message: 'Account Number should not exceed 18',
                  },
                  minLength: {
                    value: 8,
                    message: 'Account Number should be minimun of 8',
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Only Digits are allowed',
                  },
                })}
              />
            </div>
            {errors.bankAccountNumber ? (
              <>
                {errors.bankAccountNumber.type === 'required' && (
                  <p className="cpkyc_errorMessage">
                    {errors.bankAccountNumber.message}
                  </p>
                )}
                {errors.bankAccountNumber.type === 'minLength' && (
                  <p className="cpkyc_errorMessage">
                    {errors.bankAccountNumber.message}
                  </p>
                )}
                {errors.bankAccountNumber.type === 'maxLength' && (
                  <p className="cpkyc_errorMessage">
                    {errors.bankAccountNumber.message}
                  </p>
                )}
                {errors.bankAccountNumber.type === 'pattern' && (
                  <p className="cpkyc_errorMessage">
                    {errors.bankAccountNumber.message}
                  </p>
                )}
              </>
            ) : null}
          </div>
          <div className="cpkyc_col-12 cpkyc_col-md-4 cpkyc_margin-top-1_for-mobile ">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="*IFSC Code"
                disabled={kycVerified}
                value={capitalizeEachLetter(watch().ifscCode)}
                {...register('ifscCode', {
                  required: 'IFSC Code is required',
                })}
              />
            </div>
            {errors.ifscCode ? (
              <>
                {errors.ifscCode.type === 'required' && (
                  <p className="cpkyc_errorMessage">
                    {errors.ifscCode.message}
                  </p>
                )}
              </>
            ) : null}
          </div>
        </div>
        <UploadProof
          textForPTag="*Please upload Cancelled cheque"
          urlArgs="bankAccountProofUrl"
          resetProofUrl={bankAccountProofUrl}
          setresetProofUrl={setBankAccountProofUrl}
          proofType={bankAccountProofType}
          setProofType={setBankAccountProofType}
          id="bank"
          errorStatusRejected={errorBankStatusRejected}
          setErrorStatusRejected={setErrorBankStatusRejected}
          errorStatusRejectedMessage={errorBankStatusRejectedMessage}
          setErrorStatusRejectedMessage={setErrorBankStatusRejectedMessage}
          accountKycVerified={kycVerified}
          isSaveAndContinueClicked={isSaveAndContinueClicked}
        />
        <div className="cpkyc_row cpkyc_margin-top-2 cpkyc_margin-top-1_for-mobile ">
          <div className="cpkyc_col-12 cpkyc_col-md-6">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="*PAN Number"
                disabled={kycVerified}
                value={capitalizeEachLetter(watch().panNumber)}
                {...register('panNumber', {
                  required: 'PAN Number is required',
                  pattern: {
                    value: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
                    message: 'Pan No format should be ABCDE1234F',
                  },
                })}
              />
            </div>
            {errors.panNumber ? (
              <>
                {errors.panNumber.type === 'required' && (
                  <p className="cpkyc_errorMessage">
                    {errors.panNumber.message}
                  </p>
                )}
                {errors.panNumber.type === 'pattern' && (
                  <p className="cpkyc_errorMessage">
                    {errors.panNumber.message}
                  </p>
                )}
              </>
            ) : null}
          </div>
          <div className="cpkyc_col-12 cpkyc_col-md-6 cpkyc_margin-top-1_for-mobile ">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="*Name as per PAN Card"
                disabled={kycVerified}
                value={capitalizeEachLetter(watch().panHolderName)}
                {...register('panHolderName', {
                  required: 'Name is required',
                  minLength: {
                    value: 3,
                    message: 'Length should be more than 3',
                  },
                  pattern: {
                    value: /^[A-Za-z0-9 ]+$/,
                    message:
                      'Only alphanumeric characters (A-Z, a-z, 0-9) and spaces are allowed',
                  },
                })}
              />
            </div>
            {errors.panHolderName ? (
              <>
                {errors.panHolderName.type === 'required' && (
                  <p className="cpkyc_errorMessage">
                    {errors.panHolderName.message}
                  </p>
                )}
                {errors.panHolderName.type === 'minLength' && (
                  <p className="cpkyc_errorMessage">
                    {errors.panHolderName.message}
                  </p>
                )}
                {errors.panHolderName.type === 'pattern' && (
                  <p className="cpkyc_errorMessage">
                    {errors.panHolderName.message}
                  </p>
                )}
              </>
            ) : null}
          </div>
        </div>
        <UploadProof
          textForPTag="*Please upload front side of your PAN Card"
          urlArgs="panIdProofUrl"
          resetProofUrl={panIdProofUrl}
          setresetProofUrl={setPanIdProofUrl}
          proofType={panIdProofType}
          setProofType={setPanIdProofType}
          errorStatusRejected={errorPanStatusRejected}
          errorStatusRejectedMessage={errorPanStatusRejectedMessage}
          setErrorStatusRejected={setErrorPanStatusRejected}
          setErrorStatusRejectedMessage={setErrorPanStatusRejectedMessage}
          accountKycVerified={kycVerified}
          id="pan"
          isSaveAndContinueClicked={isSaveAndContinueClicked}
        />
        <div className="cpkyc_row cpkyc_margin-top-2 cpkyc_margin-top-1_for-mobile ">
          <div className="cpkyc_col-12 cpkyc_col-md-6">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="MSME Number"
                disabled={kycVerified}
                value={capitalizeEachLetter(watch().msmeNumber)}
                {...register('msmeNumber', {
                  required: false,
                })}
              />
            </div>
            {errors.msmeNumber ? (
              <>
                {errors.msmeNumber.type === 'required' && (
                  <p className="cpkyc_errorMessage">MSME Number is required</p>
                )}
              </>
            ) : null}
          </div>
        </div>
        <UploadProof
          textForPTag="Please upload MSME Document"
          urlArgs="msmeIdProofUrl"
          resetProofUrl={msmeProofUrl}
          setresetProofUrl={setMsmeProofUrl}
          proofType={msmeProofType}
          setProofType={setMsmeProofType}
          errorStatusRejected={errorMsmeStatusRejected}
          errorStatusRejectedMessage={errorMsmeStatusRejectedMessage}
          setErrorStatusRejected={setErrorMsmeStatusRejected}
          setErrorStatusRejectedMessage={setErrorMsmeStatusRejectedMessage}
          accountKycVerified={kycVerified}
          id="pan"
          isSaveAndContinueClicked={isSaveAndContinueClicked}
          isMandatory={false}
        />
        <CpKycContainerBottomTile
          kycVerified={kycVerified}
          handleSubmitButton={handleSubmitButton}
        />
      </form>
    </>
  );
}
export default PanBankDetails;
