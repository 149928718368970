import { useEffect, useMemo, useState } from 'react';
import {
  Flex,
  Select,
  Text,
  Image,
  Box,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Colors } from 'apps/customer-portal-web/src/theme';
import { AccountManager } from '@hBits.Core';
import { getAllProperties } from 'apps/customer-portal-web/src/store/actions/investment.actions';
import {
  useAppDispatch,
  useAppSelector,
} from 'apps/customer-portal-web/src/store/hooks';
import SinglePropertyCard from '../Overview/SinglePropertyCard';
import { NUM_DIFFERENTIATION } from 'apps/landing-pages-web/src/components/common/utils/helper';

const Portfolio = () => {
  const dispatch = useAppDispatch();
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>();
  const [selectedTenant, setSelectedTenant] = useState<any>();
  const accountId = AccountManager.getAccountId();
  const properties = useAppSelector(
    (state) => state.InvestmentReducer.properties,
  );

  useEffect(() => {
    if (properties?.length === 0) {
      dispatch(getAllProperties(accountId));
    }
  }, []);

  useEffect(() => {
    if (!selectedPropertyId && properties.length > 0) {
      setSelectedPropertyId(properties[0].propertyId);
    }
  }, [properties]);

  const selectedProperty: any = useMemo(
    () => properties.find((p: any) => p.propertyId === selectedPropertyId),
    [selectedPropertyId],
  );

  useEffect(() => {
    if (selectedProperty?.propertyDetails?.propertyLease?.length !== 0) {
      setSelectedTenant(selectedProperty?.propertyDetails?.propertyLease[0]);
    }
  }, [selectedPropertyId]);


  return (
    <Flex flex={1} px={5} flexDirection={'column'} mb={100}>
      {properties?.length > 1 && (
        <Select
          w={['sm']}
          bg={Colors.white}
          borderRadius={5}
          mb={6}
          onChange={(event) => setSelectedPropertyId(event.target.value)}
        >
          {properties.map((p: any) => (
            <option value={p.propertyId}>
              {p?.propertyDetails?.displayName}
            </option>
          ))}
        </Select>
      )}
      <SinglePropertyCard
        propertyId={selectedProperty?.propertyId}
        displayName={selectedProperty?.propertyDetails?.displayName}
        ownedPercentage={selectedProperty?.percentageOwned}
        ccd={selectedProperty?.ccdNumber}
        shareNumber={selectedProperty?.equityShareNumber}
        city={selectedProperty?.propertyDetails?.locations[0]?.city}
        image={
          selectedProperty?.propertyDetails?.propertyMediaModel
            ?.propertyBannerBox[0]?.imageUrl
            ? selectedProperty?.propertyDetails?.propertyMediaModel
                ?.propertyBannerBox[0]?.imageUrl
            : selectedProperty?.propertyDetails?.propertyMediaModel
                ?.propertyBannerBox[0]
        }
        investedValue={selectedProperty?.totalInvestmentAmount}
        monthlyRent={Math.round(
          selectedProperty?.propertyDetails?.propertyFinancials
            ?.contractualAnnualRent / 12,
        )}
        avgRentalYield={
          selectedProperty?.propertyDetails?.propertyFinancials?.avgRentalYield
        }
        targetIRR={
          selectedProperty?.propertyDetails?.propertyFinancials?.targetIRR
        }
        showViewDetails={false}
        investmentStage={selectedProperty?.investmentStage}
      >
        {selectedTenant && (
          <>
            <Flex my={5} alignItems={'center'}>
              <Text
                fontSize="sm"
                as={'b'}
                color={Colors.headerColor}
                mt={1}
                mr={2}
              >
                Tenants:
              </Text>
              <Wrap>
                {selectedProperty?.propertyDetails?.propertyLease?.map(
                  (ten: any) => {
                    return (
                      <WrapItem
                        key={ten.id}
                        p={2}
                        background={
                          selectedTenant.id === ten.id ? '#eee' : 'transparent'
                        }
                        borderRadius={'100%'}
                      >
                        <Image
                          w={10}
                          h={10}
                          objectFit="contain"
                          src={ten?.propertyTenantResponsePayload?.logoUrl}
                          alt={ten?.propertyTenantResponsePayload?.displayName}
                          borderRadius={3}
                          onClick={() => setSelectedTenant(ten)}
                          cursor={'pointer'}
                        />
                      </WrapItem>
                    );
                  },
                )}
              </Wrap>
            </Flex>
            <Text fontSize="md" color={Colors.grey200}>
              {selectedTenant?.background}
            </Text>
            <Flex py={10} alignSelf="center" w={['100%', '100%', '70%', '70%']}>
              <Box width="100%">
                <Flex>
                  <Flex
                    style={{
                      height: 14,
                      width: 14,
                      borderRadius: 14,
                      backgroundColor: Colors.headerColor,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      style={{
                        height: 6,
                        width: 6,
                        borderRadius: 6,
                        backgroundColor: Colors.buttonColor,
                      }}
                    />
                  </Flex>
                  <Box
                    style={{
                      backgroundColor: Colors.grey100,
                      alignSelf: 'center',
                    }}
                    width="100%"
                    flex={1}
                    height={'2px'}
                  />
                </Flex>

                <Box pt={3}>
                  <Text
                    fontSize="md"
                    fontWeight={'500'}
                    color={Colors.blackColor}
                  >
                    Security Deposit
                  </Text>
                  <Text
                    fontSize="md"
                    fontWeight={'900'}
                    color={Colors.headerColor}
                  >
                    ₹{NUM_DIFFERENTIATION(selectedTenant?.securityDeposit)}
                  </Text>
                </Box>
                <Box pt={5}>
                  <Text
                    fontSize="md"
                    fontWeight={'500'}
                    color={Colors.blackColor}
                  >
                    Lease Started
                  </Text>
                  <Text
                    fontSize="md"
                    fontWeight={'900'}
                    color={Colors.headerColor}
                  >
                    {selectedTenant?.leasePeriodStartMonth}{' '}
                    {selectedTenant?.leasePeriodStartYear}
                  </Text>
                </Box>
              </Box>
              <Box width="100%">
                <Flex>
                  <Flex
                    style={{
                      height: 14,
                      width: 14,
                      borderRadius: 14,
                      backgroundColor: Colors.headerColor,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      style={{
                        height: 6,
                        width: 6,
                        borderRadius: 6,
                        backgroundColor: Colors.buttonColor,
                      }}
                    />
                  </Flex>
                  <Flex
                    style={{
                      backgroundColor: Colors.grey100,
                      alignSelf: 'center',
                    }}
                    width="100%"
                    flex={1}
                    height={'2px'}
                  />
                </Flex>
                <Box pt={3}>
                  <Text
                    fontSize="md"
                    fontWeight={'500'}
                    color={Colors.blackColor}
                  >
                    Escalation
                  </Text>
                  <Text
                    fontSize="md"
                    fontWeight={'900'}
                    color={Colors.headerColor}
                  >
                    {selectedTenant?.esclation}% every{' '}
                    {selectedTenant?.esclationInYears} years
                  </Text>
                </Box>
                <Box pt={5}>
                  <Text
                    fontSize="md"
                    fontWeight={'500'}
                    color={Colors.blackColor}
                  >
                    Lock In
                  </Text>
                  <Text
                    fontSize="md"
                    fontWeight={'900'}
                    color={Colors.headerColor}
                  >
                    {selectedTenant?.lockInPeriodMonth}{' '}
                    {selectedTenant?.lockInPeriodYear}
                  </Text>
                </Box>
              </Box>
              <Box width="80%">
                <Flex
                  style={{
                    height: 14,
                    width: 14,
                    borderRadius: 14,
                    backgroundColor: Colors.headerColor,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    style={{
                      height: 6,
                      width: 6,
                      borderRadius: 6,
                      backgroundColor: Colors.buttonColor,
                    }}
                  />
                </Flex>
                <Box pt={3}>
                  <Text
                    fontSize="md"
                    fontWeight={'500'}
                    color={Colors.blackColor}
                  >
                    Annual Rent
                  </Text>
                  <Text
                    fontSize="md"
                    fontWeight={'900'}
                    color={Colors.headerColor}
                  >
                    ₹{NUM_DIFFERENTIATION(selectedTenant?.annualRent)}
                  </Text>
                </Box>
                <Box pt={5}>
                  <Text
                    fontSize="md"
                    fontWeight={'500'}
                    color={Colors.blackColor}
                  >
                    Lease Ends
                  </Text>
                  <Text
                    fontSize="md"
                    fontWeight={'900'}
                    color={Colors.headerColor}
                  >
                    {selectedTenant?.leasePeriodEndMonth}{' '}
                    {selectedTenant?.leasePeriodEndYear}
                  </Text>
                </Box>
              </Box>
            </Flex>
          </>
        )}
      </SinglePropertyCard>
    </Flex>
  );
};

export default Portfolio;
