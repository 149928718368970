import {
  Checkbox,
  CheckboxGroup,
  Flex,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { AssetType, Strings } from '@hBits.Core';
import * as React from 'react';
import { CustomCheckBox } from '../styled-componets';
import { removeValueFromArray } from './utils';
import styles from '../channel-partner.module.scss';

interface AssetsTypesProps {
  selectedAssetType: string[];
  updateSelectedAssetType: (event: any) => void;
}

/**
 * @param {Object} props
 * @returns {JSX.Element} - A React Functional Component
 */
const AssetTypeSelectCheckboxes: React.FC<AssetsTypesProps> = ({
  selectedAssetType,
  updateSelectedAssetType,
}) => {
  const handleChangeAssetType = (assetTypeKey: AssetType) => {
    if (selectedAssetType.includes(AssetType[assetTypeKey])) {
      const filteredAssetType = removeValueFromArray(
        selectedAssetType,
        AssetType[assetTypeKey],
      );
      updateSelectedAssetType([...filteredAssetType]);
      return;
    }
    updateSelectedAssetType([...selectedAssetType, AssetType[assetTypeKey]]);
  };

  const isChecked = (key: string) => {
    return selectedAssetType.includes(AssetType[key as AssetType]);
  };

  return (
    <Flex direction={'column'} gap={2}>
      <Flex direction={['column', 'row']} columnGap={2}>
        <Text fontWeight={'bold'}>*{Strings.assetTypeIntrestedIn}</Text>
        <Text>({Strings.selectAllThatApplies})</Text>
      </Flex>
      <CheckboxGroup colorScheme="blue" defaultValue={['naruto', 'kakashi']}>
        <SimpleGrid columns={[1, 2, 4]} spacing="2rem">
          {Object.keys(AssetType).map((key) => (
            <CustomCheckBox
              className={isChecked(key) ? '' : 'unchecked'}
              onClick={() => handleChangeAssetType(key as AssetType)}
              key={key}
            >
              <div className={isChecked(key) ? 'circle checked' : 'circle'}>
                {isChecked(key) && (
                  <i className="fa-regular fa-circle-check"></i>
                )}
              </div>
              <p>{AssetType[key as AssetType]}</p>
            </CustomCheckBox>
          ))}
        </SimpleGrid>
      </CheckboxGroup>
    </Flex>
  );
};

export default AssetTypeSelectCheckboxes;
