import {
  Flex,
  ListItem,
  OrderedList,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
  Link,
} from '@chakra-ui/react';
import { Environment } from '@hBits.Core';
import * as React from 'react';

const FooterdataAIF = () => {
  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );
  return (
    <Flex flexDirection="column" color="#94969f" marginTop={'50px'}>
      <h2>Introduction To Alternative Investment Funds AIF</h2>
      <Text>
        Explore the world of commercial real estate through alternative
        investment funds AIF with{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/'}
        >
          hBits
        </Link>
        , India’s leading platform for investors.
        <br />
        <br />
        In the financial landscape, Alternative Investment Fund AIF has seen the
        sharpest growth in the recent years. It combines the stability of real
        estate investments with the flexibility of alternative investment
        strategies. We prioritize due diligence and carefully tailored
        investment strategies to ensure long-term stability.
        <br />
        <br />
        Whether you are an experienced investor or just starting, we are here to
        provide you with an exceptional opportunity to diversify and expand your
        wealth through Alternative Investment Funds (AIF). We take pride in our
        accomplished team, which is dedicated to delivering consistent returns.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Importance And Growing Trend Of AIF Alternative Investment Funds In The
        Financial World
      </h2>
      <Text>
        AIF fund's significance in financial markets is essential for creating a
        portfolio, allowing investors to participate, secure their future, and
        achieve remarkable financial freedom.
        <br />
        <br />
        It is a lucrative market that has seen substantial growth in recent
        years due to evolving regulations. Regulatory changes have made
        establishing and operating AIFs easier, encouraging more investors to
        enter the market. Additionally, the growth in AIF alternative investment
        funds is driven by the need to diversify risk across multiple asset
        classes.
        <br />
        <br />
        AIF alternative investment funds allow investors to diversify their
        portfolios by pooling funds from various sources and investing in
        commercial real estate properties. By spreading risk across different
        assets, investors can mitigate potential losses.
        <br />
        <br />
        High-net-worth individuals and institutional investors both benefit from
        parking their funds in AIFs. Accessibility and liquidity allow investors
        to acquire high-priced real estate holdings, which generate income and,
        in turn, increase their capital.
        <br />
        <br />
        This emerging trend is one to monitor closely. This form of investment
        encompasses access to lucrative{'  '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/commercial-real-estate-investment'}
        >
          commercial real estate properties
        </Link>
        , an opportunity to diversify, mitigate risk, enhance liquidity, and
        flexibility to achieve optimal returns.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        AIF Alternative Investment Fund Strategies For Optimal Portfolio Growth
      </h2>
      <Text>
        We have highlighted the potential of AIF investment as it is an
        attractive investment option. At hBits, we ensure the portfolio stays
        robust and optimizes the risk-return ratio. Our goal is to meet the
        needs of investors of varying risk profiles.{' '}
      </Text>
      <OrderedList>
        <ListItem>
          <strong>Professionally Managed Funds:</strong> As the experts deeply
          understand market trends, their expertise in selecting the AIF
          alternative investment fund and managing properties leads to superior
          returns.
        </ListItem>
        <ListItem>
          <strong>Premium Assets:</strong> AIF funds often invest in premium
          properties, and investors can access them by pooling resources. It
          allows maximum growth potential for their portfolios, tapping into a
          wide range of promising investment options.
        </ListItem>
        <ListItem>
          <strong>Risk Mitigation:</strong> A well-thought-out investment
          strategy safeguards investments to meet investors' long-term financial
          goals. It is achieved through due diligence, active management, and
          diversification.
        </ListItem>
        <ListItem>
          <strong>Vast Network:</strong> Esteemed AIF fund managers have
          extensive networks in real estate, which can add value to the projects
          and cost-saving initiatives to benefit the investors.
        </ListItem>
        <ListItem>
          <strong>Passive Income:</strong> Consistent cash flow can be a
          valuable source of passive income, helping investors achieve financial
          goals and success.
        </ListItem>
        <ListItem>
          <strong>Liquidity Options:</strong> AIF alternative investment funds
          are highly liquid and provide flexibility to the investors to access
          funds.
        </ListItem>
        <ListItem>
          <strong>Balanced Approach:</strong> The core strategy is to ensure
          growth and offer stability in the long run. The aim is to align the
          investor's long-term goals and vision for optimal portfolio
          performance.
        </ListItem>
        <ListItem>
          <strong>Diversification:</strong> Diversification provides a solid
          foundation to spread risk and park investor's funds in plush
          properties, paving the way for sustainable growth across asset
          classes.
        </ListItem>
      </OrderedList>
      <Text>
        By employing these tactics, AIF funds aim to give investors stable
        income, capital appreciation, and significant rewards that can help
        investors realize their portfolio's full potential.
      </Text>
      <h2 style={{ marginTop: '20px' }}>AIF Minimum Investment Requirements</h2>
      <Text>The prerequisite for minimum investment in AIF is as follows:</Text>
      <OrderedList>
        <ListItem>
          An AIF must have a corpus of at least Rs. 20 crores (an Angel Fund
          needs only Rs. 5 crores)
        </ListItem>
        <ListItem>
          The minimum amount an investor can invest in an Alternative Investment
          Fund is INR 1 crore. However, each investor must put at least Rs. 25
          lakhs to get in on the Angel Fund.
        </ListItem>
        <ListItem>
          An Alternative Investment Fund Manager employee or director must
          invest at least Rs. 25 lakhs.
        </ListItem>
      </OrderedList>
      <Text>
        Under SEBI, the AIFs can be primarily classified into the following
        three categories:{' '}
      </Text>
      <UnorderedList>
        <ListItem>
          <strong>Category I:</strong>
          <OrderedList>
            <ListItem>
              <u>Venture Capital Funds-</u> The minimum amount must not be less
              than 5 lakhs. VCFs contribute to the economy by raising capital
              for startups and entrepreneurs.
            </ListItem>
            <ListItem>
              <u>Infrastructure Funds-</u> Focuses on facilitating investment in
              the infrastructure development of the public sector, like
              railways, airports, logistics, etc. Therefore, regarding
              infrastructure funds, a trustee must be registered with SEBI and
              invest 80% of infra assets to generate income. At the same time, a
              sponsor can be a promoter or a company with a net worth of a
              minimum of Rs. 100 crore to classify as a sponsor. Additionally,
              they must hold at least 15% of the total investment with a minimum
              of 3 years of the lock-in period. The structure of infrastructure
              funds also includes a project and investment manager.
            </ListItem>
            <ListItem>
              <u>Angel Funds-</u> Angel investors bring management expertise to
              guide startups and entrepreneurs. It is a subcategory of Venture
              Capital. The minimum investment required by an angel investor is
              Rs. 25 lakhs.
            </ListItem>
            <ListItem>
              <u>Social Venture Funds-</u>This type of venture focuses on
              investing in socially responsible companies that aim to bring real
              change in society. The fund size of Social venture funds must be
              Rs. 20 crores, while the minimum investment is 1 crore.
            </ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          <strong>Category II:</strong>
          <Text>
            The AIF minimum investment across the second category is Rs. 1
            crore. hBits caters to high-net-worth individuals by providing
            services under the second category.{' '}
          </Text>
          <OrderedList>
            <ListItem>
              <u>Private Equity Funds (PE)-</u> PE funds invest in unlisted
              private firms and acquire a stake. Since unlisted private firms
              cannot issue stock or debt, they seek PE funding. These businesses
              also offer diverse equity portfolios, reducing investment risk. PE
              fund investment horizons are usually 4–7 years. The company aims
              to exit the investment after 7 years profitably.
            </ListItem>
            <ListItem>
              <u>Funds Of Funds-</u> The name of this fund gives away the fact
              that it is a pooled portfolio of numerous Alternative Investment
              Funds. Rather than creating its portfolio or choosing a specific
              industry, the fund's investment strategy is to invest in a
              portfolio of other AIFs.
            </ListItem>
            <ListItem>
              <u>Debt Funds-</u>Companies with poor credit ratings often issue
              high-risk, high-yield debt products. Companies with strong
              development potential, solid corporate procedures, and capital
              constraints are suitable debt fund investments.
            </ListItem>
            <ListItem>
              <u>Distressed Assets-</u> Invest in opportunities created by
              specific events like mergers, bankruptcies, or regulatory changes.
            </ListItem>
          </OrderedList>
          <Text>
            AIFs provide HNIs and ultra-HNIs with access to different investment
            strategies for diversifying their portfolios. Private equity and
            debt investments allow them to participate in private markets and
            increase their risk-adjusted returns.
          </Text>
        </ListItem>
        <ListItem>
          <strong>Category III:</strong>
          <OrderedList>
            <ListItem>
              <u>Hedge Funds-</u> Financial institutions and accredited
              investors pool their money to form hedge funds. These funds put
              their money into debt and stock markets in domestic and
              international markets.
            </ListItem>
            <ListItem>
              <u>Private Investment In Public Equity Fund(PIPE)-</u> Shares in
              publicly listed corporations are the target of a PIPE investment.
              It is a fund for investing in public companies that is privately
              managed and uses assets raised privately.
            </ListItem>
          </OrderedList>
        </ListItem>
      </UnorderedList>
      <h2 style={{ marginTop: '20px' }}>
        Factors Influencing The Minimum Investment In AIF And Its Variations
      </h2>
      <Text>
        The following factors influence the minimum investment in AIF:
      </Text>
      <OrderedList>
        <ListItem>
          <u>Fund Type and Strategy-</u> Different AIFs may have varying
          investment strategies and requirements. For example, a real estate
          development fund might have a higher minimum investment than a fund
          focused on income-generating properties.
        </ListItem>
        <ListItem>
          <u>AIF Category-</u> The three groups have different minimum
          investment and corpus requirements.
        </ListItem>
        <ListItem>
          <u>Market Conditions-</u> The overall real estate market conditions,
          including supply and demand dynamics, interest rates, and economic
          indicators, can impact minimum investments. In a competitive market,
          minimum investments may be higher.
        </ListItem>
        <ListItem>
          <u>Location-</u>Due to differences in local rules and market
          conditions, minimum investment in AIF requirements may vary by
          location.
        </ListItem>
        <ListItem>
          <u>Fund Size-</u>A minimum investment may be required depending on the
          total value of assets in the AIF. Smaller funds may have higher
          minimums to cover overhead costs and have a diverse portfolio.
        </ListItem>
        <ListItem>
          <u>Risk Profile-</u>To mitigate risk and attract investors comfortable
          with this level of risk, AIFs with higher-risk strategies may require
          larger investments.
        </ListItem>
        <ListItem>
          <u>Lock-up Period-</u>Investors' funds in some AIFs may be locked up
          for a while before they can be withdrawn.
        </ListItem>
        <ListItem>
          <u>Regulatory Guidelines-</u>Each type of AIF may have its minimum
          requirements outlined in the SEBI regulations.
        </ListItem>
      </OrderedList>
      <h2 style={{ marginTop: '20px' }}>Benefits Of AIF Investment</h2>
      <Text>
        <strong>AIF Investment</strong> has numerous advantages, including:
      </Text>
      <OrderedList>
        <ListItem>
          It Promotes diversification in a wide range of assets across equity,
          debt, structured finance, and real estate.
        </ListItem>
        <ListItem>
          It has a higher return potential due to its flexibility—a great source
          of passive income.
        </ListItem>
        <ListItem>
          Fewer rules govern AIFs, giving them more freedom to pursue various
          investing strategies.
        </ListItem>
        <ListItem>
          AIF investment targets rapidly expanding industries, allowing
          investors to profit from the sector's rapid development.
        </ListItem>
        <ListItem>
          Investments in alternatives can come with favorable tax treatment.
          Although the particular advantages of each investment may differ, many
          alternative investments are eligible for reduced capital gains taxes.
        </ListItem>
        <ListItem>
          AIF funds have lower volatility than standard stock investing. It may
          appeal to risk-averse investors seeking stability.
        </ListItem>
      </OrderedList>
      <h2 style={{ marginTop: '20px' }}>
        AIF Investment Contributes To Portfolio Diversification
      </h2>
      <Text>
        Adding AIF investments to a portfolio is a great way to broaden the
        investment base and lower the risk profile. Unlike more familiar asset
        classes like stocks and bonds, AIFs include private equity, real estate,
        debt, and funds of funds. By investing in AIFs, investors can spread
        their risk and receive exposure to several different asset classes that
        tend to move independently of one another rather than in lockstep with
        the traditional market.
        <br />
        <br />
        Since various assets react differently to economic changes, this lower
        degree of correlation cushions the portfolio against volatility.
        Therefore, AIFs can serve as a balancing factor in a diversified
        portfolio, improving returns relative to risk. They are also helpful in
        managing risk and improving long-term financial stability since they
        give entry to alternative strategies that can produce returns
        uncorrelated with traditional assets.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Regulatory Compliance and AIF Funds: What You Need to Know
      </h2>
      <Text>
        AIF Funds must comply with the SEBI Regulations (Alternative Investment
        Funds) rules, 2012.
        <br />
        In India, AIFs are subject to regulations from the market regulator on
        registration, compliance, and ongoing reporting. <br />
        Here’s what you need to know:
      </Text>
      <UnorderedList>
        <ListItem>
          AIF Manager Oversight:
          <OrderedList>
            <ListItem>AIF managers are regulated entities.</ListItem>
            <ListItem>Licensing and reporting obligations.</ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          Investor Eligibility:
          <OrderedList>
            <ListItem>
              AIFs are typically for professional/sophisticated investors.
            </ListItem>
            <ListItem>Income/net worth thresholds often apply.</ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          Disclosure and Transparency:
          <OrderedList>
            <ListItem>Regular reporting to investors is required.</ListItem>
            <ListItem>
              Information on performance and portfolio holdings.
            </ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          Risk Management:
          <OrderedList>
            <ListItem>AIF Funds managers establish risk controls.</ListItem>
            <ListItem>
              Protecting investor interests through risk mitigation.
            </ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          Valuation and Pricing:
          <OrderedList>
            <ListItem>Guidelines for accurate asset valuation.</ListItem>
            <ListItem>Preventing conflicts of interest and fraud.</ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          Marketing and Distribution:
          <OrderedList>
            <ListItem>Regulations on how AIFs can be marketed.</ListItem>
            <ListItem>Mandatory disclosure in marketing materials.</ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          Regulatory Reporting:
          <OrderedList>
            <ListItem>AIF managers report to regulatory authorities.</ListItem>
            <ListItem>
              Ensures compliance and allows regulatory oversight.
            </ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          <strong>Importance of Compliance for Investors:</strong>
          <OrderedList>
            <ListItem>
              Investor Protection: Compliance safeguards investor interests.
            </ListItem>
            <ListItem>
              Transparency: Regulatory compliance ensures accurate information.
            </ListItem>
            <ListItem>
              Risk Mitigation: Compliance reduces the likelihood of losses.
            </ListItem>
            <ListItem>
              Legal Consequences: Non-compliance can lead to legal actions.
            </ListItem>
            <ListItem>
              Reputational Impact: Compliance upholds trust and reputation.
            </ListItem>
            <ListItem>
              Informed Decisions: Compliance aids in making sound investment
              choices.
            </ListItem>
          </OrderedList>
        </ListItem>
      </UnorderedList>
      <h2 style={{ marginTop: '20px' }}>
        AIF Funds vs. Traditional Investment Options: A Comparative Analysis
      </h2>
      <Text>
        Here’s a comprehensive comparison of AIF funds and traditional{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/properties'}
        >
          investment options
        </Link>
        .
      </Text>
      <Table
        variant="striped"
        border="2px solid"
        borderWidth="2px"
        style={{ marginTop: '20px' }}
      >
        <Tbody>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Parameter</Td>
            <Td border="2px solid">Alternative Funds</Td>
            <Td>Traditional Investment</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Minimum Requirement</Td>
            <Td border="2px solid">
              High, typically suited for high-net-worth individuals and
              institutions
            </Td>
            <Td>Varies, often accessible to retail investors</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Risk Profile</Td>
            <Td border="2px solid">
              The risk can be higher due to exposure to alternative asset funds
              like real estate
            </Td>
            <Td>Generally lower risk, based on asset classes</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Returns</Td>
            <Td border="2px solid">
              Highly volatile but provides higher returns
            </Td>
            <Td>Generally more stable, moderate returns</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Transparency</Td>
            <Td border="2px solid">
              It may offer limited transparency depending on the AIF
            </Td>
            <Td>High transparency and easy access to information</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Entry/Exit Restrictions</Td>
            <Td border="2px solid">
              Often have restrictions on entry and exit
            </Td>
            <Td>Usually more flexible in terms of entry and exit</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'70px'}>
            <Td border="2px solid">Accessibility</Td>
            <Td border="2px solid">Accessible for accredited investors</Td>
            <Td>Accessible to a broader range of investors</Td>
          </Tr>
        </Tbody>
      </Table>
      <h2 style={{ marginTop: '20px' }}>
        How to Invest in AIF: Step-by-Step Guide for Novice Investors
      </h2>
      <Text>
        Here are all the steps you need to know to{'  '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/contact-us'}
        >
          invest in AIF
        </Link>{' '}
        .
      </Text>
      <OrderedList>
        <ListItem>
          Research about AIF investments and their various categories.
          Familiarize yourself with the different types of investment and their
          characteristics.
        </ListItem>
        <ListItem>
          Determine your investing goals, risk tolerance, and timeline. Aligning
          your goals with the correct AIF category is crucial because AIF risk
          and return will vary widely.
        </ListItem>
        <ListItem>
          SEBI sets qualifying requirements. AIFs usually accept high-net-worth
          individuals and institutional investors.
        </ListItem>
        <ListItem>
          Research and choose an AIF that matches your investing goals. Consider
          the fund's performance, management competence, and portfolio assets.
        </ListItem>
        <ListItem>
          Examine the fund's performance, fees, and investing approach. Request
          and study the fund's offering documents, including the PPM.
        </ListItem>
        <ListItem>
          Complete KYC and send paperwork to the AIF and fund management. This
          contains ID, address, and financial statements.
        </ListItem>
        <ListItem>
          Your AIF investment amount must meet the fund's minimal criteria.
        </ListItem>
        <ListItem>
          Complete the AIF subscription form and send money. This usually
          entails depositing the money into the fund's bank account.
        </ListItem>
        <ListItem>
          Review fund manager statements and reports to track your AIF
          investment. Track the fund's performance and investing plan changes.
        </ListItem>
        <ListItem>
          Learn about AIF exit possibilities. AIFs have lock-in periods and exit
          limitations, so expect liquidity issues.
        </ListItem>
        <ListItem>
          If you have questions regarding AIF investing, talk to a financial
          advisor or investment expert.
        </ListItem>
        <ListItem>
          Diversify your risk by investing in many asset classes.
        </ListItem>
        <ListItem>
          Monitor legislation, market conditions, and AIF investment
          performance. Review your investing plan often to ensure it meets your
          financial goals.
        </ListItem>
      </OrderedList>
      <Text>
        To invest in AIFs, it's crucial to understand your financial situation,
        goals and the specifics of the AIF you're considering before investing.
      </Text>
      <h2 style={{ marginTop: '20px' }}>Frequently Asked Questions</h2>
      <h3 style={{ marginTop: '20px' }}>
        A. What are the primary advantages of investing in AIF funds over other
        investment options?
      </h3>
      <Text>
        Alternative Investment Funds (AIFs) offer numerous advantages over
        traditional investments. AIF Funds use private equity, real estate, and
        hedge funds to spread their financial exposure across multiple markets.
        Their professional fund managers may have specific skills and deliver
        superior returns. AIFs provide more customizable investing strategies
        depending on investor preferences and risk tolerance. Furthermore, AIFs
        are often available to institutional and individual investors, expanding
        the pool of potential capital.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        B. What are the typical returns one can expect from AIF investments?
      </h3>
      <Text>
        AIF Investments manager strives to provide superior returns compared to
        relevant benchmark indexes. But the returns cannot be determined. The
        returns are based on the different categories of AIF funds. At the end
        of June last year, the AIF industry's assets were Rs 6.94 trillion, an
        increase of 22% yearly. From a starting point of Rs 1.8 trillion in
        assets, the sector has multiplied by 4.7 in the last five years.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        C. What is the AIF minimum investment required to participate?
      </h3>
      <Text>
        The AIF minimum investment requirement to participate depends on the
        three broad categories of AIF under SEBI regulations. The minimum
        investment in AIF funds is Rs. 1 crore but can vary depending on the
        specific fund and its regulations. Investors should check the fund's
        offering documents or contact the fund manager for detailed information
        on the minimum investment requirement for a particular AIF.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        D. What factors determine the minimum investment in AIF?
      </h3>
      <Text>
        The minimum investment in AIF is influenced by various factors such as
        fund type, strategy, AIF category, market conditions, location, fund
        size, risk profile, lock-up period, and regulatory guidelines. AIFs with
        higher-risk strategies may require more significant investments to
        mitigate risk and attract investors. Additionally, SEBI regulations and
        market conditions can affect minimum investment requirements.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        E. How do alternative investment funds AIF compare to traditional
        investment funds in terms of risk and returns?
      </h3>
      <Text>
        When it comes to risks and returns, alternative investment funds AIF
        differ from traditional investment funds in several ways. Traditional
        investments offer more liquidity. Whereas AIF funds have longer lock-up
        periods, accessing them is difficult. <br />
        <br />
        Alternative investments carry more risk but also have the potential for
        more significant rewards than conventional investments. For instance,
        investing in funds of funds or debt funds can have a larger risk of
        failure than traditional investments. But, still, the potential rewards
        of AIF funds are significant under expert guidance.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        F. How do AIF alternative investment funds provide unique investment
        opportunities?
      </h3>
      <Text>
        AIF Alternative Investment Funds diversify portfolios by focusing on
        unconventional assets like private equity, hedge funds, real estate, and
        start-ups. They offer lower correlation, higher returns, and flexible
        strategies, catering to conservative and aggressive investors. AIFs
        enhance portfolio resilience and financial goals.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        G. What are the essential steps to consider before investing in AIFs?
      </h3>
      <Text>
        Before investing in AIFs, Investing in Alternative Investment Funds
        (AIFs) requires thorough research and due diligence. Understand your
        investment objectives and risk tolerance, select an AIF aligned with
        your goals, evaluate the fund's track record, manager expertise, and
        assets, and consult a financial advisor for suitable portfolio addition.
      </Text>
    </Flex>
  );
};

export default FooterdataAIF;
