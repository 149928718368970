import { FileUploadType, HTTPStatusCode, Strings } from '@hBits.Core';
import { dmsUpload } from 'apps/customer-portal-web/src/services/Kyc/getImageUrl';

export const getFileUploadUrl = async (event: any, toast: any) => {
  if (event.target.files[0]) {
    const fileSize = event.target.files[0].size / (1024 * 1024);
    if (fileSize > 2) {
      toast({
        title: Strings.failed,
        description: Strings.tooLargeKycError,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    const type = event.target.files[0].type.split('/');
    if (type[0] == FileUploadType.Image && type[1] != 'svg+xml') {
      formData.append('type', FileUploadType.Image);
    } else if (type[1] == FileUploadType.Pdf) {
      formData.append('type', FileUploadType.Pdf);
    } else if (type[1] == FileUploadType.msWord) {
      formData.append('type', FileUploadType.Word);
    } else {
      toast({
        title: Strings.failed,
        description: Strings.fileType,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const response = await dmsUpload(formData);

    if (response.status === HTTPStatusCode.OK) {
      return response;
    }
  }
  return;
};
