import * as React from 'react';
import greentick from '../resources/greentick.png';
import '../kyc.scss';
interface KycStatusVerifiedProps {
  classNameOuterDiv: string
  classNameInnerDiv: string
  text: string
}
const KycStatusVerified = (props: KycStatusVerifiedProps) => {
  const {text, classNameInnerDiv, classNameOuterDiv} = props;
  return (
    <div className={classNameOuterDiv}>
      <div className={classNameInnerDiv}>
            <img src={greentick} className="kyc_green-tick-for-verified"></img>{' '}&nbsp;&nbsp;
            <h6 className="kyc_h6-tag-for-kyc-status-page-verified">
              KYC has been Verified {text}
            </h6>
          </div>
    </div>
  );
};

export default KycStatusVerified;
