enum PagesUrl {
  HomePage = '/',
  CommercialRealEstateInvestment = '/commercial-real-estate-investment',
  PreleasedProperty = '/preleased-property',
  FractionalOwnershipInvestment = '/fractional-ownership-investment',
  AlternativeInvestmentFund = '/alternative-investment-funds-aif',
  NRIRealEstate = '/nri-real-estate-investment',
}

export default PagesUrl;
