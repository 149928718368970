import { ChakraProvider, Container, filter, Flex } from '@chakra-ui/react';
import { getListOfProperties } from 'apps/customer-portal-web/src/services/dashboard/UpcomingProperties';
import { getCurrentDate } from 'apps/customer-portal-web/src/utils';
import { useEffect, useState } from 'react';
import UpcomingPropertyItem from './UpcomingPropertyItem';

const UpcomingProperties = () => {
  const [propertyList, setPropertyList] = useState<any[]>([]);
  const [filteredPropertyList, setFilteredPropertyList] = useState<any[]>([]);

  const filterPropertiesForInvestment = (): any[] => {
    const today = getCurrentDate();
    const filtered: any[] = propertyList?.filter((property: any) => {
      const goLiveDate = new Date(
        property?.propertyInvestment?.expectedGoLiveDate,
      );
      return goLiveDate > today;
    });
    if (filtered.length > 2) return filtered.slice(0, 2);
    else if (filtered.length > 0) return filtered;
    else return propertyList?.slice(0, 1);
  };

  useEffect(() => {
    getListOfProperties().then((res) => setPropertyList(res));
  }, []);

  useEffect(() => {
    propertyList.length &&
      setFilteredPropertyList(filterPropertiesForInvestment());
  }, [propertyList]);

  return (
    <ChakraProvider>
      <Container gap={'0.8rem'} mb={5}>
        {filteredPropertyList?.map((property, index) => (
          <UpcomingPropertyItem property={property} key={index} />
        ))}
      </Container>
    </ChakraProvider>
  );
};

export default UpcomingProperties;
