import { Flex } from '@chakra-ui/react';
import { Colors } from '@hBits.Core';
import colors from 'libs/src/theme/colors';
import styled from 'styled-components';

export const ArticleContainer = styled(Flex)`
  width: 100%;
  height: auto;
  overflow: hidden;
  background: ${Colors.white} 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.10196078431372549);
  border: 1px solid ${Colors.lightGrey};
  border-radius: 10px;
  padding: 10px 10px;
  gap: 0.5rem;
  margin: 0 0 15px 0;
`;

export const ArticleSummary = styled(Flex)`
  color: ${Colors.textGreyColor};
  font-size: 0.8rem !important;
  font-weight: 700 !important;
`;

export const ReadMoreLink = styled.a`
  cursor: pointer;
  color: ${Colors.bgDarkBlue};
  font-size: 0.8rem !important;
  font-weight: 700 !important;
  text-decoration: underline;
`;

export const PageTitle = styled.div`
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  text-align: left;
  color: ${Colors.bgDarkBlue};
  width: 100%;
`;

export const DashboardWrapper = styled(Flex)`
  padding: 0.5rem 1rem;
  height: 100%;
  background-image: url('./assets/bg_img_dashboard.png') !important;
`;
