import React, { FunctionComponent, useEffect } from 'react';
import './PrivacyModal.module.scss';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ChakraProvider,
  Flex,
  Text,
  ListItem,
  UnorderedList,
  OrderedList,
} from '@chakra-ui/react';
export interface TncModalProps {
  onModalClose: () => void;
}

const PrivacyModal: FunctionComponent<TncModalProps> = ({ onModalClose }) => {
  return (
    <ChakraProvider>
      <Modal
        isOpen={true}
        onClose={onModalClose}
        isCentered={true}
        size={'5xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ padding: 0 }}>
            <Flex
              bg={'#1a295b'}
              flex={1}
              pt={3}
              pb={3}
              pl={[0, 0, '60px', '60px']}
              pr={[0, 0, '60px', '60px']}
              justifyContent={'space-between'}
              style={{ zIndex: 10000000 }}
            >
              <Text fontSize="3xl" ml={5} color={'white'}>
                Privacy Policy
              </Text>
              <Flex
                data-test="button-close-terms-conditions"
                className="titleCloseBtnTnc"
                onClick={onModalClose}
              >
                <Flex
                  borderWidth={'1px'}
                  borderColor="white"
                  justifyContent={'center'}
                  alignItems="center"
                  borderRadius={50}
                  h={'35px'}
                  w={'35px'}
                  style={{ cursor: 'pointer' }}
                >
                  <Text color="white" fontSize={'20px'}>
                    X
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex
              style={{
                overflow: 'scroll',
                height: '70vh',
              }}
              pl={[0, 0, '50px', '50px']}
              pr={[0, 0, '50px', '50px']}
              pt={'30px'}
              className="modalBody main-container-flex"
              flexDirection={'column'}
            >
              <Flex flexDirection={'column'}>
                <Text fontSize="3xl" color={'#1a295b'} as="b">
                  Privacy Policy
                </Text>

                <Text fontSize="lg" fontWeight={'400'} mt={5}>
                  The domain name{' '}
                  <a href="https://www.hbits.co" style={{ fontWeight: 'bold' }}>
                    www.hbits.co
                  </a>{' '}
                  (hereinafter referred to as "the said Website") is owned by
                  hBits Online Platform Private Limited, a private limited
                  company incorporated under the laws of India and having its
                  registered office located in the State of Maharashtra
                  (hereinafter referred to as “hBits”). For the purpose of this
                  Privacy Policy, the term "You" or "User" shall mean any
                  natural or legal person/entities including both Online and
                  offline clients and the term "We", "Us", "Our" shall mean
                  hBits and its affiliates and their service providers. hBits is
                  committed to safeguarding the privacy of any person who access
                  and uses the said Website that is owned and operated only by
                  hBits.
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  We know that You care how information about You is used and
                  shared and We appreciate Your trust in Us to do that carefully
                  and sensibly. This policy gives details of the information We
                  may collect from You or that You may provide while
                  using/accessing the said Website and the manner in which such
                  information is collected, used, processed, disclosed and
                  maintained. By accessing the said Website and any of its
                  pages, You are agreeing to the clauses specified read along
                  with the terms of use of the said Website. By accessing and
                  using the said Website, You accept and agree to be bound by
                  and comply with (i) this Privacy Policy, and, (ii) the Terms
                  of Use.
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  We urge the user to read and understand the policy carefully.
                  If You do not agree with Our policies and Our practices in the
                  way We treat Your information, You should immediately stop
                  accessing and using the said Website. If You continue to use
                  or access the said Website, it shall constitute Your agreement
                  to this Privacy Policy and by accepting this Privacy Policy,
                  You expressly consent to hBits’ use and disclosure of Your
                  personal information in accordance with this privacy policy.
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  This policy shall apply to all information You provide on the
                  said Website and all information that hBits collects on the
                  Website including but not limited to any information You
                  upload, emails that You exchange with Us and other Users and
                  any information submitted by You to Us.
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  This policy applies to hBits as defined herein and help You
                  understand how we collect, use, store, process, transfer,
                  share and otherwise deal with and protect all Your information
                  when You visit Our Website. Note that this Privacy Policy is
                  an electronic record in the form of electronic contract in
                  terms of Indian Information Technology Act, 2000 and rules
                  made thereunder (as amended from time to time) and does not
                  require any physical signature or seal. Please refer to the
                  detailed outline of the policy.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  1. Controller of Personal Information
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits will be the controller of Your personal information
                  provided by You or otherwise collected by Us. Your data
                  controller is responsible for the collection, use, disclosure,
                  retention, and protection of Your personal information in
                  accordance with its privacy standards as well as any
                  applicable laws.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  2. Information Collected
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits may for the purpose of rendering its services, collect
                  personal information such as
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <UnorderedList>
                    <ListItem>
                      Name, Gender, Residential / Correspondence Address,
                      Telephone Number, Date of Birth, Marital Status, Email
                      Address, IP address or Other Personal Information
                    </ListItem>
                    <ListItem>
                      Bank account or other payment instrument details
                    </ListItem>
                    <ListItem>PAN, KYC, Signature and Photograph</ListItem>
                    <ListItem>
                      Any other detail necessary or ancillary for providing
                      services
                    </ListItem>
                    <ListItem>
                      Information provided by You on third party Websites and
                      users’ information collected by hBits forms part of the
                      aforementioned websites/web pages from Third party
                      websites.
                    </ListItem>
                  </UnorderedList>
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits offers online platform for carrying out transactions
                  that collect such personal data or information so as to
                  process Your financial and/or non-financial transaction
                  requests.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  3. Storage of Your Information
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Your Information will mostly be stored in electronic form
                  however certain data may be stored in physical form. We may
                  store, collect, use and process Your Information within India
                  and/or outside India subject to compliance under applicable
                  laws. We may enter into agreements with third parties (in or
                  outside of India) to store or process Your Information and
                  such third parties may have their own security measures to
                  safeguard Your information with security standards as
                  comparable with good industry practices.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  4. Log File Information Collected and Stored Automatically
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Whenever You visit/log into our Website just to browse, read
                  pages or download information, We will automatically gather
                  and store certain non-personal information about Your visit.
                  The kind of information that is gathered automatically
                  includes, but is not limited to, the type of browser You are
                  using (e.g. Internet Explorer, Firefox, etc.), the type of
                  Operating System / Device like computer, mobile, laptop,
                  tablet or any other internet enabled electronic device. You
                  are using (e.g. Windows or Mac OS), the domain name along with
                  the IP address of Your Internet Service Provider, website
                  navigation, marketing preferences, the date and time of Your
                  visit and the pages on Our website when You use the said
                  Website. Information regarding Your preferences and setting
                  such as geographical location, time zone and language. We may
                  collect process and store Your user ID associated with any
                  social media account (such as you Facebook and Google
                  accounts) that You use to sign into the Platform or connect
                  with or use with the products or services. When You sign in to
                  Your account with Your social media account information, or
                  otherwise connect to Your social media account with the
                  Product or Services, You agree to Our collection, storage, and
                  use, in accordance with this Privacy Policy, of the
                  information that You make available to Us through the social
                  media interface. This could include, without limitation, any
                  information that You have made public through Your social
                  media account, information that the social media service
                  shares with Us, or information that is disclosed during the
                  sign-in process. Please see Your social media provider's
                  privacy policy and help centre for more information about how
                  they share information when You choose to connect. This
                  information cannot and does not identify You personally. We
                  use this information purely for analytics and research
                  purposes, in order to improve Our Website and to ensure the
                  security of the Website.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  5. Purpose of Collection, Storage, Processing of Your
                  Information
                </Text>
                <Text fontSize="lg" mt={3}>
                  hBits collects Your Information solely for the purpose of
                  providing you the products or services that is connected with
                  the function or activity of hBits which includes but not
                  limited to the following <Text as="b">("Purpose"): </Text>
                </Text>

                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <UnorderedList>
                    <ListItem>
                      To inform you about various products and services offered
                      on Our Platform and provide customer support to You.
                    </ListItem>
                    <ListItem>
                      To address Your queries and resolve Your concerns
                      pertaining to any product or service that Your were
                      looking for.
                    </ListItem>
                    <ListItem>
                      To send You survey and marketing communications or
                      facilitate various programs and initiatives launched
                      either by Us or third party which We believe may be of
                      interest to You.
                    </ListItem>
                    <ListItem>
                      To facilitate Your usage of Platform (what features You
                      like, etc.) and improve Our products or services, or any
                      other content on the Platform (such as by personalizing
                      content to Your interests), process and complete Your
                      transactions, and make special offers.
                    </ListItem>

                    <ListItem>
                      To review Platform performance, diagnose technical
                      problems and do data analysis to make Your experience
                      better.
                    </ListItem>
                    <ListItem>
                      To protect the integrity of Our Platform.
                    </ListItem>

                    <ListItem>
                      To inform you about change in any term and condition of
                      this Privacy Policy or Terms of Use of any Platform of
                      hBits.
                    </ListItem>
                    <ListItem>
                      To implement information security practices, to determine
                      any security breaches, contaminant or computer virus, to
                      investigate/prevent/take action against illegal activities
                      and suspected fraud.
                    </ListItem>
                    <ListItem>
                      To keep You informed about news, events and ideas
                      regarding Your preferences and help You to improve Your
                      knowledge and skills about the preferred content.
                    </ListItem>

                    <ListItem>
                      To allow you to participate in interactive features
                      offered through Our Platform.
                    </ListItem>
                    <ListItem>
                      To contact You about Our own and third-party products and
                      services that may be of interest to You. If You do not
                      want Us to use Your Information in this way, please check
                      the relevant box located on the form on which We collect
                      Your data and/or adjust Your user preferences in Your
                      account profile.
                    </ListItem>
                    <ListItem>
                      To produce and share aggregate insights and statistics
                      that do not identify You.
                    </ListItem>
                    <ListItem>
                      To enable Us independently or in association with third
                      party to show You tailored ads that are relevant to You.
                    </ListItem>
                    <ListItem>
                      To enable us to display advertisements to our
                      advertisers/service providers' target audiences. Even
                      though We do not disclose Your Personal Information for
                      these purposes without Your consent, if you click on or
                      otherwise interact with an advertisement, the advertiser
                      may assume that You meet its target criteria.
                    </ListItem>
                    <ListItem>
                      To help advertisers and other partners measure the
                      effectiveness and distribution of their ads and services,
                      and understand the types of people who use their services
                      and how people interact with their websites, apps and
                      services. In any other way We may describe when You
                      provide the Information.
                    </ListItem>
                    <ListItem>
                      For any other purpose at the sole discretion of hBits with
                      Your consent.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  6. Sharing of the Information
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <UnorderedList>
                    <ListItem>
                      Any service or product provided through Our Platform may
                      be provided either by hBits or its vendor, dealer, OEM,
                      channel partners and other third parties ("Other
                      Entities") which are registered with hBits to render the
                      services or provide products through Platform or
                      otherwise. You acknowledge and agree that hBits may share,
                      disclose, transfer or part with Your Information to Other
                      Entities depending upon the type of Product or Service You
                      are looking for or for one or more of the permissible
                      purposes. hBits may also share Your personal information
                      or non-personal information where it is required to share
                      the same with such Other Entities for providing You the
                      product or service and You provide hBits, Your
                      unconditional consent to do the same.
                    </ListItem>
                    <ListItem>
                      hBits may share statistical data and/or other non-personal
                      information or details to facilitate various program or
                      initiatives launched by hBits, third party service
                      providers, partners or financial institutions, from time
                      to time,
                    </ListItem>
                    <ListItem>
                      We use other third parties such as a credit/debit card
                      processing company, payment gateway, pre-paid cards etc.
                      to enable You to make payments for buying products or
                      availing services on Platform. When You sign up for these
                      services, You may have the ability to save Your card
                      details for future reference and faster future payments.
                      In such case, We may share Your relevant Information as
                      necessary for the third parties to provide such services,
                      including Your name, residence and email address. The
                      processing of payments or authorization is solely in
                      accordance with these third parties policies, terms and
                      conditions and We are not in any manner responsible or
                      liable to You or any third party for any delay or failure
                      at their end in processing the payments.
                    </ListItem>
                    <ListItem>
                      The information so collected may be shared with Government
                      of Semi-Government Body, Registrar and transfer Agents/
                      Collecting Banks/ KYC Registration Agencies (KRAs) /
                      Custodian/ Central Depository Services (India) Limited
                      (CDSL) etc. solely for the purpose of processing Your
                      transaction requests or serving You better. The
                      information so collected by hBits shall be shared by hBits
                      with any Governmental and/or Judicial Agencies (eg. Police
                      Authorities/investigating agencies like CBI, EOW etc.) or
                      other authorized law enforcement agencies (LEAS) mandated
                      under law to obtain Your Information for the purpose of
                      verification of identity or for prevention, detection,
                      investigation including but not limited to cyber
                      incidents, prosecution, and punishment of offences etc
                    </ListItem>
                    <ListItem>
                      We may process Your Information outside India ad relay on
                      legally provided mechanisms to lawfully transfer Your
                      Information across borders.
                    </ListItem>
                    <ListItem>
                      We may share Your Information in any other circumstances
                      where We have Your consent.
                    </ListItem>
                    <ListItem>
                      hBits and/or its authorized agents shall not retain or
                      store that information for longer than is required for the
                      purposes, except in cases where the information may be
                      used lawfully or is required under any other law for the
                      time being in force. By agreeing to avail the service
                      offered by hBits You have agreed to the collection and use
                      of Your Sensitive Personal Data or Information by hBits.
                      You may always choose to refuse or withdraw Your consent
                      to share/disseminate Your Sensitive Personal Data or
                      Information by contacting the customer care or if You
                      would like Us to delete any Personal data or information
                      provided by You, You may write to Us at &nbsp;
                      <a
                        href="mailto:info@hbits.co"
                        style={{ fontSize: '20.25px', fontWeight: '700' }}
                      >
                        info@hbits.co
                      </a>{' '}
                      However, in such an event, You would no longer avail the
                      services of hBits.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  7. Updating or Reviewing Your Sensitive Personal Data or
                  Information
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  You may upon written request to us review and rectify the
                  sensitive personal data or information provided.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  8. Reasonable Security Practices for Protecting Information
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits uses commercially reasonable physical, managerial, and
                  technical safeguards to preserve the integrity and security of
                  Your personal information. hBits cannot, however, ensure or
                  warrant the security of any information You transmit to hBits
                  and You do so at Your own risk. Once we receive Your
                  transmission of information, hBits makes commercially
                  reasonable efforts to ensure the security of our systems.
                  However, please note that this is not a guarantee that such
                  information may not be accessed, disclosed, altered, or
                  destroyed by breach of any of our physical, technical, or
                  managerial safeguards. To protect Your privacy and security,
                  hBits takes reasonable steps (such as requesting a unique
                  password) to verify Your identity before granting You access
                  to Your account. You are responsible for maintaining the
                  secrecy of Your unique password and account information, and
                  for controlling access to Your email communications from
                  hBits, at all times. Notwithstanding anything contained in
                  this Privacy Policy or elsewhere, hBits shall not be held
                  responsible for any loss, damage or misuse of the User
                  information or any other data, which You share with Us or
                  which We have with Us if such loss or any damage caused to You
                  because of Force Majeure events. You further acknowledge that
                  any information (including Your personal information)
                  transmitted to Us or from Your use of Website will therefore
                  be at Your own risk.
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  It is clarified that Force Majeure events includes any event
                  which is beyond the reasonable control of hBits which may
                  include but not limited to sabotage, fire, flood, explosion,
                  acts of God, civil commotion, strikes or industrial action of
                  any kind, riots, insurrection, war, acts of government,
                  computer hacking, unauthorised access to computer, computer
                  system or computer network, computer crashes, breach of
                  security and encryption.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  9. Access to Information
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  As per the terms and conditions of use, hBits has the right to
                  restrict access of any User from the Website and the services
                  offered therein ("Restricted User"). With respect to a
                  Restricted User, hBits will delete any or all User Information
                  given by the Restricted User. If hBits, by its sole discretion
                  or due to a legal requirement, decides to retain the User
                  Information for its records, then hBits will be responsible to
                  safeguard that User Information to such extent as required
                  under applicable law.
                </Text>
              </Flex>

              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  10. Links to Other Web Sites
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Our website sometimes has links to other website(s) in the
                  World Wide Web. The privacy policies of these website(s) are
                  not under our control. Once You leave our servers, use of any
                  information You provide is governed by the privacy policy of
                  the operator of the site You are visiting. It is advisable to
                  read their privacy policies for further information. Your
                  browsing and interaction on any other Web site, including
                  those that have links on our Website, is subject to that
                  Website’s policies. hBits shall not be liable for any loss,
                  damage, claim, expenses or whatsoever suffered by You as a
                  result of accessing these third party or other sites.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  11. Children’s information
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  We encourage parents and guardians to observe, participate in,
                  and/or monitor and guide their online activity. hBits does not
                  knowingly collect any Personal Identifiable Information from
                  children under the age of 18. If You think that Your child
                  have provided this kind of information on our website, we
                  strongly encourage You to contact us immediately and we will
                  do our best efforts to promptly remove such information from
                  our records.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  12. Changes to Our Privacy Policy
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Please note that this policy may be changed or updated from
                  time to time at its sole discretion for various reasons
                  including, but not limited to, amendments in the relevant
                  laws. If hBits changes its privacy policies and procedures,
                  hBits will post those changes on hBits website to keep
                  You/Users aware/updated of what information hBits collects,
                  how hBits uses it and under what circumstances hBits may
                  disclose/transmit it. Changes to this Privacy Policy are
                  effective when they are posted on this page. hBits shall be
                  under no obligation to notify the User about the changes.
                  Please visit our website to keep Yourself updated of any
                  changes to this policy and Your continued access and use of
                  the said Website shall be deemed to be Your approval and
                  acceptance of all changes to this Privacy Policy.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  13. Cookies
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Cookies are small files transferred to the hard drive of Your
                  device that enable us to recognize Your browser and capture
                  and remember certain information. They are also used to help
                  us understand Your preferences based on previous or current
                  activity on the Website, which allows us to improve our
                  Website. We also use cookies to help us compile aggregated and
                  anonymized data about the traffic on the Website so that we
                  can offer better experiences and tools in the future. We may
                  also use trusted third-party services that track this
                  information on our behalf.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  14. Authorization:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  You also acknowledge that all the information provided by You
                  are out of Your free will and in compliance with applicable
                  laws in Your territory. If We found that any of Your
                  information is in non-compliance with the terms and conditions
                  and Privacy policy, hBits reserves the right to remove Your
                  non-compliant information from its systems. Further, depending
                  on the seriousness of the non-compliance, we may choose to
                  discontinue some or all of the services being provided to You
                  by us.
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  In purview of the Telecom Regulatory Authority of India
                  <Text as="b">(“TRAI”)</Text> guidelines, You hereby authorize
                  hBits, and its affiliates/partners or otherwise who are
                  accessing Your information by virtue of their association with
                  hBits to communicate with You through whatsapp,
                  telephone/mobile, Email, SMS or other modes of communication
                  (now available or may be available in future) even if Your
                  number/numbers(s) is/are registered in the National Do Not
                  Call Registry (NDNC) or{' '}
                  <a
                    href="https://ndnc.net/"
                    style={{ fontWeight: 'bold', fontSize: '15px' }}
                  >
                    ndnc.net
                  </a>{' '}
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  You also accept that copy rights of the content in terms of
                  copy or images on this Website belongs to hBits and
                  replicating it in any form either by registered or
                  unregistered channel partners or individuals for any form of
                  communication will be considered violation of the Indian
                  Copyright Act no. 14 of 1957.
                </Text>
              </Flex>

              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  15. Rights to control Your personal information
                </Text>

                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <UnorderedList>
                    <ListItem>
                      Rectify Your information: We take all reasonable endeavors
                      to update Our records with latest information as provided
                      by You, however if you see any discrepancy in Your
                      personal information, You may edit some of Your personal
                      information or contact Us through Our Data Protection
                      Officer/Grievance Officer to have Your information updated
                      with Us.
                    </ListItem>
                    <ListItem>
                      Object to, or Limit or Restrict the Use: You can ask Us to
                      stop using or to limit Our use (in partial or full) Your
                      Personal Information (past, existing or future).
                    </ListItem>
                    <ListItem>
                      Deletion of Your information: You can ask Us to erase or
                      delete (in partial or full) Your Personal Information
                      (past, existing or future).
                    </ListItem>
                    <ListItem>
                      Right to Access: You can ask Us for a copy of Your
                      personal information.
                    </ListItem>
                    <ListItem>
                      Right to Account Closure: If You choose to close Your
                      Account, We will delete Your Personal Information or
                      de-identify it so that it is anonymous and not attributed
                      to you identity. We will retain Your Personal Information
                      after You have closed Your account if reasonably necessary
                      to comply with Our legal obligations (including law
                      enforcement requests), meet regulatory requirements,
                      resolve disputes, maintain security, prevent fraud and
                      abuse, enforce Our User Agreement, or fulfil You request
                      to "unsubscribe" from further messages from Us. We will
                      retain de-personalized information after Your account has
                      been closed.
                    </ListItem>
                    <ListItem>
                      Withdraw Consent/Opt-out: At any time, you can withdraw
                      consent that You have provided to Us by going to Your
                      account settings, if you have an account with us or
                      otherwise you may request for Withdrawing you consent by
                      writing an email to us at{' '}
                      <a
                        href="mailto:info@hbits.co"
                        style={{ fontWeight: 'bold', fontSize: '20.25px' }}
                      >
                        info@hbits.co.
                      </a>{' '}
                      We will only collect and process personal information
                      about You where We have lawful bases. Lawful bases include
                      consent (where You have given consent), contract (where
                      processing is necessary for the performance of a contract
                      with You (e.g. to deliver products or services you have
                      requested)) and "legitimate interests". Where We rely on
                      Your consent to process personal information, you have the
                      right to withdraw or decline Your consent at any time and
                      where we rely on legitimate interests, You have the right
                      to object.
                    </ListItem>
                    <ListItem>
                      You may also contact Us using the contact information as
                      mentioned in this Privacy Policy, and We will consider
                      Your request in accordance with applicable laws.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  15. Governing Law and Jurisdiction
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  These Terms shall be construed in accordance with the
                  applicable laws of India without giving effect to any
                  principles of conflicts of laws. The Courts at Mumbai shall
                  have exclusive jurisdiction in any proceedings or disputes
                  arising in relation to or out of these Terms and its
                  interpretation thereof. hBits causes the control and
                  maintenance of this Website from India.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  16. European Union (EU) privacy / data protection rights
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  EU General Data Protection Regulation (
                  <Text as="b">“GDPR”</Text>) (
                  <Text as="b">Chapter 3 - Article</Text> 12-23) has provided
                  the below rights to the EU residents:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <OrderedList>
                    <ListItem>
                      The right to be informed: This means anyone processing
                      Your personal data must make clear what they are
                      processing, why, and who else the data may be passed to.
                    </ListItem>
                    <ListItem>
                      The right of access: This is Your right to see what data
                      is held about You by a Data Controller.
                    </ListItem>
                    <ListItem>
                      The right to rectification: The right to have Your data
                      corrected or amended if what is held is incorrect in
                      someway.
                    </ListItem>
                    <ListItem>
                      The right to erasure (Right to be forgotten): Under
                      certain circumstances You can ask for Your personal data
                      to be deleted. This is also called the Right to be
                      Forgotten'. This would apply if the personal data is no
                      longer required for the purposes it was collected for, or
                      Your consent for the processing of that data has been
                      withdrawn, or the personal data has been unlawfully
                      processed.
                    </ListItem>
                    <ListItem>
                      The right to restrict processing: This gives the Data
                      Subject the right to ask for a temporary halt to
                      processing of personal data, such as in the case where a
                      dispute or legal case has to be concluded, or the data is
                      being corrected.
                    </ListItem>
                    <ListItem>
                      The right to data portability: A Data Subject has the
                      right to ask for any data supplied directly to the Data
                      Controller by him or her, to be provided in a structured,
                      commonly used, and machine-readable format.
                    </ListItem>
                    <ListItem>
                      The right to object: The Data Subject has the right to
                      object to further processing of their data which is
                      inconsistent with the primary purpose for which it was
                      collected, including profiling, automation, and direct
                      marketing.
                    </ListItem>
                    <ListItem>
                      The Rights in relation to automated decision making and
                      profiling: Data Subjects have the right not to be subject
                      to a decision based solely on automated processing
                    </ListItem>
                  </OrderedList>
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  EU residents can exercise these rights by raising a request
                  directly at{' '}
                  <a
                    href="mailto:info@hbits.co"
                    style={{ fontWeight: 'bold', fontSize: '20.25px' }}
                  >
                    info@hbits.co
                  </a>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  17. Reach us
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  If you have any concern related to this Privacy Policy or
                  collection, storage, retention or disclosure of Your
                  Information under the terms of this Privacy Policy or Terms of
                  Use or any other terms and conditions of hBits or its other
                  business entities including any queries or grievances, You can
                  contact to hBits through its Data Protection Officer/Grievance
                  Redressal Officer at the below given details:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Email ID{' '}
                  <a
                    href="mailto:info@hbits.co"
                    style={{ fontWeight: 'bold', fontSize: '20.25px' }}
                  >
                    info@hbits.co
                  </a>
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  We will strive to address you feedback and concerns in a
                  timely and effective manner.
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Please note that the details of the Data Protection
                  Officer/Grievance Officer may be changed by Us from time to
                  time by updating this Privacy Policy.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  ACKNOWLEDGEMENT:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Please read this Privacy Policy carefully. Hence by visiting
                  our Website platform or by using/ availing any of our services
                  or products, You indicate that You understand, agree and
                  consent to this Privacy Policy and You hereby give Your
                  unconditional consent or agreement to hBits as provided under
                  section 43A and section 72A of Indian Information Technology
                  Act, 2000 for collection, use, storage, processing, sharing
                  and transfer and disclosure of Your information.
                </Text>

                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  You acknowledge that You have all legal rights and lawful
                  authority to share the information with us and further
                  acknowledge that by collecting, sharing, processing and
                  transferring information provided by You, shall not cause any
                  loss or wrongful gain to You or any other person.
                </Text>

                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  If You do not agree with the terms of this Privacy Policy,
                  please do not access/use our Website or avail any of our
                  services or products available on our Website.
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};
export default PrivacyModal;
