import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import styles from './full-page-loader.module.scss';

const FullPageLoader = () => {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default FullPageLoader;
