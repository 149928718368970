//  eslint-disable no-undef
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Environment, UserType } from '@hBits.Core';
import { isWebView } from '@hbits.lib/util';
import { eventEmitter } from 'apps/landing-pages-web/src/components/events';
import { AccountManager, authTokenStorageProvider } from 'libs/src/auth';
import accountManager from 'libs/src/auth/account-manager';
import { clearAccessToken } from 'libs/src/services/AuthHelper';
import { userLogOff } from 'libs/src/services/modules/auth/login';
import { setupInterceptors } from 'libs/src/services/NetworkHandler';
import { useEffect, useState } from 'react';

setupInterceptors();
export interface RegistrationProps {
  firstname: string;
  lastname: string;
  emailAddress: string;
  mobileCountryCode: string;
  mobileNumber: string;
  attemptId?: string;
}

export interface LoginProps {
  mobileNumber: string;
  accountId: string;
}
const useAuth = (env?: any) => {
  const [loginModalState, setLoginModalState] = useState(false);
  const [loginOTPModalState, setLoginOTPModalState] = useState(false);
  const [registrationModalState, setRegistrationModalState] = useState(false);

  const [registrationOTPModalState, setRegistrationOTPModalState] =
    useState(false);
  const [completionModalState, setCompletionModalState] = useState(false);
  const [preRegFlag, setPreRegFlag] = useState(false);
  const [preLoginFlag, setPreLoginFlag] = useState(false);
  const [nomineeModalState, setNomineeModalState] = useState(false);
  const [isMoneyControl, setIsMoneyControl] = useState<boolean>(false);
  // If env is not found in process, we extract it from parent
  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  const customerPortalHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_CUSTOMER_PORTAL_HOSTING_ROOT',
  );

  const newCustomerPortalHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_NEW_CUSTOMER_PORTAL_HOSTING_ROOT',
  );

  const [tncModalState, setTnCModalState] = useState(false);
  const [registrationData, setRegistrationData] = useState<RegistrationProps>({
    firstname: '',
    lastname: '',
    emailAddress: '',
    mobileCountryCode: '',
    mobileNumber: '',
  });
  const [loginData, setLoginData] = useState({
    mobileNumber: '',
    accountId: '',
  });
  const [linkedAccountFlag, setLinkedAccountFlag] = useState(false);

  // Auth cookie callback
  let [username, setUsername] = useState('');

  let [userTypeOps, setUserTypeOps] = useState(false);
  let [userTypeCP, setUserTypeCP] = useState(false);

  const checkIfAuthTokenIsValid = () => {
    const authModel = authTokenStorageProvider.getAuthTokenModel();
    const userModel = authModel?.userTypes;
    if (authModel) {
      setUsername(authModel.firstName);
      if (window !== undefined) {
        sessionStorage.removeItem('utmParams');
      }
      if (userModel?.includes(UserType.Ops)) {
        setUserTypeOps(true);
      }
      if (userModel?.includes(UserType.ChannelPartner)) {
        setUserTypeCP(true);
      }
      if (authModel?.source == 'Moneycontrol') {
        setIsMoneyControl(true);
      }
    }
  };

  useEffect(() => {
    checkIfAuthTokenIsValid();
  }, [completionModalState, loginOTPModalState]);

  const goToKyc = (userData: any) => {
    setRegistrationModalState(false);
    AccountManager.setAccountId(userData.id);
    window.location.assign(customerPortalHostingRoot + '/kyc');
  };

  const openRegistrationModal = (preReg = false) => {
    setPreRegFlag(preReg);
    setLoginModalState(false);
    setLoginOTPModalState(false);
    setRegistrationOTPModalState(false);
    setRegistrationModalState(true);
  };

  const openLoginModal = (preLogin = false) => {
    setPreLoginFlag(preLogin);
    setRegistrationModalState(false);
    setLoginOTPModalState(false);
    setLoginModalState(true);
  };

  const openRegistrationOTPModal = (userData: RegistrationProps) => {
    setRegistrationData(userData);
    setRegistrationModalState(false);
    setRegistrationOTPModalState(true);
  };

  const openLoginOTPModal = (loginData: LoginProps) => {
    setLoginData(loginData);
    setLoginModalState(false);
    setLoginOTPModalState(true);
  };

  const openTnCModal = () => {
    setTnCModalState(true);
  };

  const openCompletionModal = () => {
    setRegistrationOTPModalState(false);
    setCompletionModalState(true);
  };

  const onLoginCompletion = () => {
    setCompletionModalState(false);
    setLoginOTPModalState(false);
    const currentAccountId =
      authTokenStorageProvider.getAuthTokenModel()?.accountId;
    if (currentAccountId) {
      AccountManager.setAccountId(currentAccountId);
    }
    if (typeof window !== 'undefined') {
      if (window.location.pathname === '/channel-partner') {
        eventEmitter.dispatch('auth-popup-modal-closed');
        return;
      }
      if (window.location.pathname === '/utm') {
        window.location.assign(`${landingPagesHostingRoot}`);
      } else {
        window.location.assign(
          `${landingPagesHostingRoot + window.location.pathname}`,
        );
      }
    }
  };

  const hideLoginModal = () => {
    setLoginModalState(false);
    if (typeof window !== 'undefined') {
      if (
        window.location.href.indexOf('viewproperty') !== -1 &&
        !window.location.search.includes('partnerId')
      ) {
        window.location.assign(landingPagesHostingRoot + '/properties');
      }
    }
  };

  const hideRegistrationModal = () => {
    setRegistrationModalState(false);
    if (typeof window !== 'undefined') {
      if (window.location.pathname === '/channel-partner') {
        eventEmitter.dispatch('auth-popup-modal-closed');
        return;
      }
      if (
        window.location.href.indexOf('viewproperty') !== -1 &&
        !window.location.search.includes('partnerId')
      ) {
        window.location.assign(landingPagesHostingRoot + '/properties');
      }
    }
  };

  const lsqUserLogout = (accessToken: any) => {
    userLogOff(accessToken)
      .then((response) => {})
      .catch((error) => {});
  };

  const isWebview = isWebView();

  const logoutUser = () => {
    const authToken = authTokenStorageProvider.getRawAuthToken();
    lsqUserLogout(authToken);
    setTimeout(() => {
      clearAccessToken();
      authTokenStorageProvider.clearAuthToken();
      accountManager.clearAccountId();
      accountManager.clearLogoutFlag();
      setUsername('');
      if (isWebview) {
        window.location.assign(newCustomerPortalHostingRoot + '/home');
      } else {
        window.location.assign(landingPagesHostingRoot + '/');
      }
    }, 500);
  };

  const onLoginRegisterClick = (userData: any) => {
    linkedAccountFlag ? goToKyc(userData) : openRegistrationOTPModal(userData);
  };

  const onLoginClick = () => {
    openLoginModal(false);
  };

  const onNomineeModalClose = () => {
    setNomineeModalState(false);
  };

  const openNomineeModal = () => {
    setNomineeModalState(true);
  };

  const onRegisterClick = () => openRegistrationModal(false);

  const onRegisterModalClose = () => setRegistrationOTPModalState(false);
  const onChangeMobileClick = () => openRegistrationModal(true);
  const onLoginOtpModalClose = () => setLoginOTPModalState(false);
  const onOpenRegistrationModalClose = () => openRegistrationModal(false);

  const onTnCClick = () => setTnCModalState(false);

  return {
    ...{
      preLoginFlag,
      userTypeOps,
      userTypeCP,
      setLinkedAccountFlag,
      username,
      tncModalState,
      registrationModalState,
      preRegFlag,
      registrationData,
      hideRegistrationModal,
      onLoginRegisterClick,
      onLoginClick,
      openTnCModal,
      linkedAccountFlag,
      registrationOTPModalState,
      onRegisterModalClose,
      onChangeMobileClick,
      openCompletionModal,
      loginModalState,
      loginData,
      hideLoginModal,
      onRegisterClick,
      openLoginOTPModal,
      onLoginCompletion,
      loginOTPModalState,
      onTnCClick,
      onLoginOtpModalClose,
      onOpenRegistrationModalClose,
      checkIfAuthTokenIsValid,
      logoutUser,
      setTnCModalState,
      setRegistrationModalState,
      openLoginModal,
      openRegistrationModal,
      completionModalState,
      setNomineeModalState,
      nomineeModalState,
      onNomineeModalClose,
      openNomineeModal,
      setRegistrationOTPModalState,
      setIsMoneyControl,
      isMoneyControl,
      setUsername,
      lsqUserLogout,
      setLoginModalState,
    },
  };
};

export default useAuth;
