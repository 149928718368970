const fullmonthName = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const NUM_DIFFERENTIATION = (value: number, approximate = true) => {
  let val;
  if (value >= 10000000) {
    val = ROUND_DOWN(value / 10000000.0);
    const roundVal = approximate ? roundValue(val) : val;
    val = parseFloat(roundVal.toString()) + ' Crore';
  } else if (value >= 100000) {
    val = ROUND_DOWN(value / 100000.0);
    const roundVal = approximate ? roundValue(val) : val;
    val = parseFloat(roundVal.toString()) + ' Lakh';
  } else if (value >= 1000) {
    val = ROUND_DOWN(value / 1000.0);
    const roundVal = approximate ? roundValue(val) : val;
    val = parseFloat(roundVal.toString()) + ' K';
  } else {
    val = value;
  }
  return val;
};

export const convertToCrore = (value: number, approximate = true) => {
  const croreValue = value / 10000000;
  let roundVal = approximate ? roundValue(croreValue) : croreValue;
  let val = roundVal.toFixed(2);
  val = parseFloat(val.toString()) + ' Crore';
  return val;
};
const roundValue = (val: number) => {
  const decimal = val % 1;
  if (decimal < 0.1 || decimal > 0.93) {
    return Math.round(val);
  }
  return val;
};

export function dateFormate(date: string | number | Date) {
  if (!date) return 0;
  var event = new Date(date);
  let month = fullmonthName[event.getMonth()];
  let day = event.getDate();
  let year = event.getFullYear();
  return `${day} ${month}, ${year}`;
}

export const ROUND_DOWN = (value: number) => Math.ceil(value * 100.0) / 100.0;

export const slugify = (a: any) => {
  if (a) {
    return a
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }
};

export function youtube_parser(url: string) {
  if (!url) {
    return null;
  }
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

export const NUM_FORMATTING = (value: any) => {
  if (value == null || isNaN(value)) {
    return '0';
  }

  var val = '';
  if (value) {
    var x = value;
    x = x.toString();
    var afterPoint = '';
    if (x.indexOf('.') > 0) afterPoint = x.substring(x.indexOf('.'), x.length);
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') lastThree = ',' + lastThree;
    if (otherNumbers) {
      val =
        otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
        lastThree +
        afterPoint;
    } else {
      val = x;
    }
  } else {
    return 0;
  }
  return val;
};
