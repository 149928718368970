import { AddressProofType, KycProofStatus } from '@hBits.Core';
import { GuardianDetailsInt } from '../KycScreens/GuardianDetails';
import { AddressProofDetailsInt } from './AddressProofTile';

export const SetMultipleAddressProofDetails = (
  addressProofTypes: string[],
  addressProofDetails: [AddressProofDetailsInt],
  response: {},
  isNri: boolean,
  isMainAddress?: boolean,
) => {
  const emptyAddressDetails = [
      {
        documentIndex: '',
        documentName: '',
        documentNumber: '',
        documentUrl: '',
        documentType: '',
      },
    ],
    emptySelectedDetails: any[] = [];
  if (addressProofTypes) {
    const defaultAddressProofDetails: any = [];
    const selectedDocumentsDetails = [addressProofTypes[0]];
    const documentName = addressProofTypes[0];
    let addressDetails;
    switch (documentName) {
      case AddressProofType.Aadhar:
        addressDetails = addressProofDetails;
        addressDetails = {
          addressDetails,
          ...getAddressDetailsForAadhar(response, addressProofTypes[0]),
        };
        defaultAddressProofDetails.push(addressDetails);
        break;
      case AddressProofType.GstCertificate:
        addressDetails = addressProofDetails;
        addressDetails = {
          addressDetails,
          ...getAddressDetailsForGst(response, addressProofTypes[0]),
        };
        defaultAddressProofDetails.push(addressDetails);
        break;
      case AddressProofType.Passport:
        if (isMainAddress && !isNri) {
          addressDetails = addressProofDetails;
          addressDetails = {
            addressDetails,
            ...getAddressDetailsForPassport(response, addressProofTypes[0]),
          };
          defaultAddressProofDetails.push(addressDetails);
          break;
        } else break;
      case AddressProofType.TRC:
        addressDetails = addressProofDetails;
        addressDetails = {
          addressDetails,
          ...getAddressDetailsForTrc(response, addressProofTypes[0], '0'),
        };
        defaultAddressProofDetails.push(addressDetails);
        break;
      case AddressProofType.DrivingLicense:
        addressDetails = addressProofDetails;
        addressDetails = {
          addressDetails,
          ...getAddressDetailsForDrivingLicense(response, addressProofTypes[0]),
        };
        defaultAddressProofDetails.push(addressDetails);
        break;
      case AddressProofType.Others:
        addressDetails = addressProofDetails;
        addressDetails = {
          addressDetails,
          ...getAddressDetailsForOthers(response, addressProofTypes[0]),
        };
        defaultAddressProofDetails.push(addressDetails);
        break;
      default:
        break;
    }
    if (addressProofTypes.length > 1) {
      for (let i = 1; i < addressProofTypes.length; i++) {
        const documentName = addressProofTypes[i];
        switch (documentName) {
          case AddressProofType.Aadhar:
            defaultAddressProofDetails.push(
              getAddressDetailsForAadhar(
                response,
                addressProofTypes[i],
                i.toString(),
              ),
            );
            selectedDocumentsDetails.push(addressProofTypes[i]);
            break;
          case AddressProofType.GstCertificate:
            defaultAddressProofDetails.push(
              getAddressDetailsForGst(
                response,
                addressProofTypes[i],
                i.toString(),
              ),
            );
            selectedDocumentsDetails.push(addressProofTypes[i]);
            break;
          case AddressProofType.Passport:
            if (isMainAddress && !isNri) {
              defaultAddressProofDetails.push(
                getAddressDetailsForPassport(
                  response,
                  addressProofTypes[i],
                  i.toString(),
                ),
              );
              selectedDocumentsDetails.push(addressProofTypes[i]);
              break;
            } else break;
          case AddressProofType.TRC:
            defaultAddressProofDetails.push(
              getAddressDetailsForTrc(
                response,
                addressProofTypes[i],
                i.toString(),
              ),
            );
            selectedDocumentsDetails.push(addressProofTypes[i]);
            break;
          case AddressProofType.DrivingLicense:
            defaultAddressProofDetails.push(
              getAddressDetailsForDrivingLicense(
                response,
                addressProofTypes[i],
                i.toString(),
              ),
            );
            selectedDocumentsDetails.push(addressProofTypes[i]);
            break;
          case AddressProofType.Others:
            defaultAddressProofDetails.push(
              getAddressDetailsForOthers(
                response,
                addressProofTypes[i],
                i.toString(),
              ),
            );
            selectedDocumentsDetails.push(addressProofTypes[i]);
            break;
          default:
            break;
        }
      }
      return [defaultAddressProofDetails, selectedDocumentsDetails];
    } else {
      return [defaultAddressProofDetails, addressProofTypes];
    }
  } else return [emptyAddressDetails, emptySelectedDetails];
};

const getAddressDetailsForAadhar = (
  response: any,
  addressProofTypes: string,
  index = '0',
) => {
  return {
    documentName: addressProofTypes,
    documentIndex: index,
    documentNumber: response?.aadharNumber,
    documentUrl: response?.aadharProofFrontUrl,
    documentType: response?.aadharProofType,
    documentVerificationStatus: response?.aadharVerificationStatus,
    documentRejectionReason: response?.aadharRejectionReason,
    documentVerficationStatusCall:
      response?.aadharVerificationStatus === KycProofStatus.Rejected
        ? true
        : false,
  };
};
const getAddressDetailsForGst = (
  response: any,
  addressProofTypes: string,
  index = '0',
) => {
  return {
    documentName: addressProofTypes,
    documentIndex: index,
    documentNumber: response?.gstCertificateNumber,
    documentUrl: response?.gstCertificateProofUrl,
    documentType: response?.gstCertificateProofType,
    documentVerificationStatus: response?.gstCertificateVerificationStatus,
    documentRejectionReason: response?.gstCertificateRejectionReason,
    documentVerficationStatusCall:
      response?.gstCertificateVerificationStatus === KycProofStatus.Rejected
        ? true
        : false,
  };
};
const getAddressDetailsForDrivingLicense = (
  response: any,
  addressProofTypes: string,
  index = '0',
) => {
  return {
    documentName: addressProofTypes,
    documentIndex: index,
    documentNumber: response?.drivingLicenseNumber,
    documentUrl: response?.drivingLicenseProofUrl,
    documentType: response?.drivingLicenseProofType,
    documentVerificationStatus: response?.drivingLicenseVerificationStatus,
    documentRejectionReason: response?.drivingLicenseRejectionReason,
    documentVerficationStatusCall:
      response?.drivingLicenseVerificationStatus === KycProofStatus.Rejected
        ? true
        : false,
  };
};
const getAddressDetailsForPassport = (
  response: any,
  addressProofTypes: string,
  index = '0',
) => {
  return {
    documentName: addressProofTypes,
    documentIndex: index,
    documentNumber: response?.passportNumber,
    documentUrl: response?.passportProofUrl,
    documentType: response?.passportProofType,
    documentVerificationStatus: response?.passportVerificationStatus,
    documentRejectionReason: response?.passportRejectionReason,
    documentVerficationStatusCall:
      response?.passportVerificationStatus === KycProofStatus.Rejected
        ? true
        : false,
  };
};
const getAddressDetailsForTrc = (
  response: any,
  addressProofTypes: string,
  index = '0',
) => {
  return {
    documentName: addressProofTypes,
    documentIndex: index,
    documentNumber: response?.trcNumber,
    documentUrl: response?.trcProofUrl,
    documentType: response?.trcProofType,
    documentVerificationStatus: response?.trcVerificationStatus,
    documentRejectionReason: response?.trcRejectionReason,
    documentVerficationStatusCall:
      response?.trcVerificationStatus === KycProofStatus.Rejected
        ? true
        : false,
  };
};
const getAddressDetailsForOthers = (
  response: any,
  addressProofTypes: string,
  index = '0',
) => {
  return {
    documentName: addressProofTypes,
    documentIndex: index,
    documentNumber: response?.otherDocumentNumber,
    documentUrl: response?.otherDocumentProofUrl,
    documentType: response?.otherDocumentProofType,
    documentVerificationStatus: response?.otherDocumentVerificationStatus,
    documentRejectionReason: response?.otherDocumentRejectionReason,
    documentVerficationStatusCall:
      response?.otherDocumentVerificationStatus === KycProofStatus.Rejected
        ? true
        : false,
  };
};

export const setProofValues = (
  addressProofDetails: AddressProofDetailsInt[],
  data: GuardianDetailsInt,
) => {
  const addressProofTypes: string[] = [];
  for (let i = 0; i < addressProofDetails.length; i++) {
    const currentAddressProofDetails = addressProofDetails[i];
    const documentName = currentAddressProofDetails.documentName;
    switch (documentName) {
      case AddressProofType.Aadhar:
        data.aadharNumber = currentAddressProofDetails.documentNumber;
        data.aadharProofFrontUrl = currentAddressProofDetails.documentUrl;
        data.aadharProofType = currentAddressProofDetails.documentType;
        addressProofTypes.push(currentAddressProofDetails.documentName);
        break;
      case AddressProofType.GstCertificate:
        data.gstCertificateNumber = currentAddressProofDetails.documentNumber;
        data.gstCertificateProofUrl = currentAddressProofDetails.documentUrl;
        data.gstCertificateProofType = currentAddressProofDetails.documentType;
        addressProofTypes.push(currentAddressProofDetails.documentName);
        break;
      case AddressProofType.Passport:
        data.passportNumber = currentAddressProofDetails.documentNumber;
        data.passportProofUrl = currentAddressProofDetails.documentUrl;
        data.passportProofType = currentAddressProofDetails.documentType;
        addressProofTypes.push(currentAddressProofDetails.documentName);
        break;
      case AddressProofType.TRC:
        data.trcNumber = currentAddressProofDetails.documentNumber;
        data.trcProofUrl = currentAddressProofDetails.documentUrl;
        data.trcProofType = currentAddressProofDetails.documentType;
        addressProofTypes.push(currentAddressProofDetails.documentName);
        break;
      case AddressProofType.DrivingLicense:
        data.drivingLicenseNumber = currentAddressProofDetails.documentNumber;
        data.drivingLicenseProofUrl = currentAddressProofDetails.documentUrl;
        data.drivingLicenseProofType = currentAddressProofDetails.documentType;
        addressProofTypes.push(currentAddressProofDetails.documentName);
        break;
      case AddressProofType.Others:
        data.otherDocumentNumber = currentAddressProofDetails.documentNumber;
        data.otherDocumentProofUrl = currentAddressProofDetails.documentUrl;
        data.otherDocumentProofType = currentAddressProofDetails.documentType;
        addressProofTypes.push(currentAddressProofDetails.documentName);
        break;
      default:
        break;
    }
  }
  data.addressProofTypes = addressProofTypes;
};
