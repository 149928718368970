import {
  authTokenStorageProvider,
  Environment,
  setAccessToken,
} from '@hBits.Core';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Kyc from '../screens/Kyc/index';
import BaseLayout from '../components/Layout';
import About from '../screens/About';
import ChannelPartnerLandingPage from '../screens/ChannelPartner';
import Enrollment from '../screens/ChannelPartner/ChannelPartnerEnrollment';
import Dashboard from '../screens/Dashboard';
import Home from '../screens/Home';
import InvestmentDashboard from '../screens/InvestmentDashboard';
import PropertyInvestment from '../screens/PropertyInvestment';
import PageNotFound from 'libs/src/components/PageNotFound/PageNotFound';

//  * ProtectedRoutes is a Routing Middleware Component
//  * If a user tries to visit a "protected" route without having logged in, he will be taken to the Authentication Screen
//  * This navigation is driven by Navigate
//  * Else if a user is logged in, then he will be able to view the component
//  * This view is driven by Outlet
//  * @returns {JSX.Element} - A React Component

const RoutingManager = () => {
  useEffect(() => {
    const onFocus = () => {
      const authToken = authTokenStorageProvider.getRawAuthToken();
      if (!authToken) {
        const landingPageRoute = Environment.getInstance().getParam(
          'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
        );
        const originUrl = window.location.href;
        if (landingPageRoute)
          window.location.href =
            landingPageRoute +
            `/authenticate?origin=${encodeURIComponent(originUrl)}`;
      } else {
        setAccessToken(authToken);
      }
    };
    window.addEventListener('focus', onFocus);
    onFocus();

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  return (
    <BrowserRouter>
      <BaseLayout>
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route
              path="/investment/:propertyId"
              element={<PropertyInvestment />}
            />
            <Route path="/how-it-works" element={<About />} />
            <Route
              path="/channel-partner"
              element={<ChannelPartnerLandingPage />}
            />
            <Route path="/kyc" element={<Kyc />} />
            <Route
              path="/channel-partner/enrollment"
              element={<Enrollment />}
            />
            <Route path="/channel-partner/dashboard" element={<Dashboard />} />
            <Route
              path="/customer/dashboard"
              element={<InvestmentDashboard />}
            />
            <Route path="/not-found" element={<PageNotFound />} />
            {/* 404 page added */}
            <Route path="*" element={<Navigate to="/not-found" replace />} />
          </Route>
        </Routes>
      </BaseLayout>
    </BrowserRouter>
  );
};

export default RoutingManager;
