import jwtDecode from 'jwt-decode';
import { Environment } from '../services/EnvironmentHelper';
import AuthTokenModel from './auth-token.model';

declare const document: any;

class AuthTokenStorageProvider {
  private static ENVIRONMENT_PREFIX = Environment.getInstance().getParam(
    'NEXT_PUBLIC_ENV_NAME',
  );
  private static AUTH_TOKEN_COOKIE_NAME = `${AuthTokenStorageProvider.ENVIRONMENT_PREFIX}-hb-auth-token`;

  private static readonly AUTH_TOKEN_COOKIE_EXPIRY_DAYS = 30;

  private setEnvironmentPrefix(): void {
    AuthTokenStorageProvider.ENVIRONMENT_PREFIX =
      Environment.getInstance().getParam('NEXT_PUBLIC_ENV_NAME');

    AuthTokenStorageProvider.AUTH_TOKEN_COOKIE_NAME = `${AuthTokenStorageProvider.ENVIRONMENT_PREFIX}-hb-auth-token`;
  }

  public storeRawAuthToken(token: string): void {
    if (!AuthTokenStorageProvider.ENVIRONMENT_PREFIX) {
      this.setEnvironmentPrefix();
    }
    const date = new Date();
    date.setTime(
      +`${
        date.getTime() +
        AuthTokenStorageProvider.AUTH_TOKEN_COOKIE_EXPIRY_DAYS *
          24 *
          60 *
          60 *
          1000
      }`,
    );

    const parentHostingRoot = Environment.getInstance().getParam(
      'NEXT_PUBLIC_PARENT_HOSTING_DOMAIN',
    );

    const domain = `domain=${parentHostingRoot}; `;

    const expires = `expires=${date.toUTCString()}; `;
    document.cookie = `${AuthTokenStorageProvider.AUTH_TOKEN_COOKIE_NAME}=${token}; ${domain}${expires}path=/`;
  }

  public getAuthTokenModel(): null | AuthTokenModel {
    const token = this.getRawAuthToken();
    if (!token) {
      return null;
    }
    const decodedToken: {
      firstName: string;
      lastName: string;
      accountId: string;
      userTypes: string[];
      opsRole: string | undefined;
      source: string;
    } = (jwtDecode(token) as any).authTokenModel;
    // convert the payload to AuthTokenModel
    const authToken = new AuthTokenModel(
      decodedToken.firstName,
      decodedToken.lastName,
      decodedToken.accountId,
      decodedToken.userTypes,
      decodedToken.opsRole,
      decodedToken.source,
    );
    return authToken;
  }

  public getRawAuthToken(): string | null {
    if (!AuthTokenStorageProvider.ENVIRONMENT_PREFIX) {
      this.setEnvironmentPrefix();
    }
    const decodedCookie = decodeURIComponent(document.cookie);
    const splitCookieArr = decodedCookie.split(';');
    let token = null;
    for (let i = 0; i < splitCookieArr.length; i = i + 1) {
      let c = splitCookieArr[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(AuthTokenStorageProvider.AUTH_TOKEN_COOKIE_NAME) === 0) {
        token = c.substring(
          AuthTokenStorageProvider.AUTH_TOKEN_COOKIE_NAME.length + 1,
          c.length,
        );
      }
    }
    return token;
  }

  public clearAuthToken(): void {
    const parentHostingRoot = Environment.getInstance().getParam(
      'NEXT_PUBLIC_PARENT_HOSTING_DOMAIN',
    );
    document.cookie =
      AuthTokenStorageProvider.AUTH_TOKEN_COOKIE_NAME +
      '=' +
      ';path=/' +
      `;domain=${parentHostingRoot}` +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
}

export default new AuthTokenStorageProvider();
