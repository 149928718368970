import { color, Flex, Text } from '@chakra-ui/react';
import colors from 'libs/src/theme/colors';
import { useEffect, useState } from 'react';
import CustomButton from 'apps/customer-portal-web/src/components/CustomButton';
import { useSelector } from 'react-redux';
import accountIdManager from 'libs/src/auth/account-manager';
import { getLeadOwnerData } from 'apps/customer-portal-web/src/services/Kyc/getKycData';
import { HTTPStatusCode } from '@hBits.Core';

interface CustomPageBottomProps {
  buttonIsVisible?: boolean;
  holderNumber: number;
  btnClicked?: () => void;
}

const CustomPageBottom = (props: CustomPageBottomProps) => {
  const { isAllFieldsDisable } = useSelector((state: any) => state.KycReducer);
  const { buttonIsVisible = true, holderNumber, btnClicked } = props;
  const [leadOwnerInfo, setLeadOwnerInfo] = useState<any>();

  const setLeadOwnerDetails = async () => {
    const response = await getLeadOwnerData(accountIdManager?.getAccountId());
    if (response?.status === HTTPStatusCode.OK) {
      setLeadOwnerInfo(response?.data?.leadOwner);
    }
  };

  useEffect(() => {
    if (holderNumber === 0) {
      setLeadOwnerDetails();
    }
  }, []);
  return (
    <Flex
      mt={8}
      direction={{ base: 'column', md: 'row' }}
      justifyContent={holderNumber === 0 ? 'space-between' : 'flex-end'}
      flex={1}
    >
      {holderNumber === 0 && leadOwnerInfo && (
        <Flex fontSize={'14px'} mb={6}>
          <Text color={'#535353'}>
            For queries, connect with your RM{' '}
            <Text as="span" fontWeight={500} color={colors.bgDarkBlue}>
              {leadOwnerInfo?.fullName}
            </Text>{' '}
            on
            <br />
            <Text fontWeight={500} color={colors.bgDarkBlue}>
              {leadOwnerInfo?.mobileNumber}/{leadOwnerInfo?.emailAddress}
            </Text>
          </Text>
        </Flex>
      )}
      {buttonIsVisible && (
        <Flex mt={6}>
          <CustomButton
            testId='button-for-guardian-details-save&continue'
            btnText="SAVE & CONTINUE"
            pl={'19px'}
            pr={'19px'}
            fontSize={'12px'}
            isDisable={isAllFieldsDisable[holderNumber]}
            btnPressed={btnClicked}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default CustomPageBottom;
