import {
  Button,
  Flex,
  Input,
  Select,
  Text,
  Image,
  useToast,
} from '@chakra-ui/react';
import { Colors, AddressProofType, Strings } from '@hBits.Core';
import colors from 'libs/src/theme/colors';
import { useEffect, useState } from 'react';
import { capitalizeEachLetter, capitalizeFirstLetter } from '../utils';
import UrlShower from './UrlViewer';
import styles from '../index.module.scss';
import CrossSignImage from '../../../assets/kyc/circleLogo.svg';
import { useSelector } from 'react-redux';
import { getFileUploadUrl } from './getFileUploadUrl';

export interface AddressProofDetailsInt {
  documentIndex: number;
  documentName: string;
  documentNumber: string;
  documentUrl: string;
  documentType: string;
  documentVerificationStatus: string;
  documentRejectionReason: string | undefined;
  documentVerficationStatusCall: boolean;
}

interface AddressProofTileProps {
  addressProofDetails: [AddressProofDetailsInt];
  setAddressProofDetails: any;
  selectedDocuments: any;
  setSelectedDocuments: any;
  holderNumber: number;
  saveAndContinueClicked?: boolean;
  setAadharProofBackUrl?: any;
  aadharProofBackUrl?: string;
  dataTestid?: string;
  testId?: string;
}

const AddressProofTile = (props: AddressProofTileProps) => {
  const { isAllFieldsDisable, isNri } = useSelector(
    (state: any) => state.KycReducer,
  );
  const toast = useToast();
  const {
    addressProofDetails,
    setAddressProofDetails,
    selectedDocuments,
    setSelectedDocuments,
    holderNumber,
    saveAndContinueClicked,
    aadharProofBackUrl,
    setAadharProofBackUrl,
    dataTestid,
    testId,
  } = props;
  let [documentTypeList, setDocumentTypeList] = useState<string[]>([]);
  const filterDocumentTypeList = () => {
    const addressProofs = Object.values(AddressProofType);
    const filteredProofs = addressProofs.filter(
      (itm: string) => itm != (isNri[holderNumber] ? 'Passport' : 'TRC'),
    );
    setDocumentTypeList(filteredProofs);
  };

  useEffect(() => {
    handleAddAnotherProof();
    filterDocumentTypeList();
  }, []);

  const handleAddAnotherProof = () => {
    if (!isAllFieldsDisable[holderNumber]) {
      const selectedDocumentsDetails = [];
      selectedDocumentsDetails.push('');
      setSelectedDocuments([...selectedDocuments, selectedDocumentsDetails]);
      setAddressProofDetails([
        ...addressProofDetails,
        {
          documentIndex: '',
          documentName: '',
          documentNumber: '',
          documentUrl: '',
          documentType: '',
        },
      ]);
    }
  };
  const handleDropdownChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    const addressList = [...addressProofDetails];
    addressList[index].documentIndex = index;
    addressList[index].documentName = value;
    setAddressProofDetails(addressList);
    selectedDocuments[index] = value;
    setSelectedDocuments(selectedDocuments);
  };
  const handleCrossSign = (index: number) => {
    if (!isAllFieldsDisable[holderNumber]) {
      selectedDocuments.splice(index, 1);
      setSelectedDocuments(selectedDocuments);
      const addressList = [...addressProofDetails];
      addressList.splice(index, 1);
      setAddressProofDetails(addressList);
    }
  };

  const handleDocumentNumber = (value: string, index: number) => {
    const addressList = [...addressProofDetails];
    addressList[index].documentNumber = value;
    setAddressProofDetails(addressList);
  };

  const handleChangeUploadFile = async (e: any, i: number) => {
    const response = await getFileUploadUrl(e, toast);
    if (response) {
      const addressList = [...addressProofDetails];
      addressList[i].documentUrl = response?.data?.documentUrl;
      addressList[i].documentType = capitalizeFirstLetter(
        response?.data?.documentType,
      );
      addressList[i].documentRejectionReason = undefined;
      setAddressProofDetails(addressList);
    }
  };

  const deleteImage = (index: number) => {
    if (addressProofDetails[index].documentName === AddressProofType.Aadhar) {
      setAadharProofBackUrl('');
    }
    const addressList = [...addressProofDetails];
    addressList[index].documentUrl = '';
    setAddressProofDetails(addressList);
  };

  return (
    <>
      {Array.isArray(addressProofDetails) &&
        addressProofDetails.map((list: any, i: number) => (
          <Flex
            mt={{ base: 4, md: 8 }}
            w={'100%'}
            direction={{ base: 'column', md: 'row' }}
          >
            <Flex
              w={{ base: '100%', md: '45%' }}
              pr={{ base: 0, md: 6 }}
              direction={'column'}
              justifyContent={'center'}
            >
              <Select
                data-test={`select-${dataTestid}-address-proof-type`}
                width={'100%'}
                fontSize={'16px'}
                height={'45px'}
                borderColor={Colors.buttonBorderGrey}
                value={addressProofDetails[i].documentName}
                onChange={(event: any) => handleDropdownChange(event, i)}
                placeholder={'*Select Address Proof Type'}
                color={
                  addressProofDetails[i].documentName
                    ? Colors.blackColor
                    : Colors.textGreyColor
                }
                variant="flushed"
                alignSelf={'flex-end'}
                _disabled={{ color: 'black' }}
                disabled={isAllFieldsDisable[holderNumber]}
              >
                {documentTypeList?.map((value: any) => (
                  <option
                    className={
                      selectedDocuments?.includes(value) ? styles['hide'] : ''
                    }
                    style={{ color: Colors.blackColor }}
                    key={value}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </Select>
              {!addressProofDetails[i].documentName &&
                saveAndContinueClicked && (
                  <div className={styles['errorRegMessage']}>
                    {Strings.thisIsRequired}
                  </div>
                )}
            </Flex>
            <Flex
              w={{ base: '100%', md: '30%' }}
              pr={{ base: 0, md: 6 }}
              direction={'column'}
              justifyContent={'center'}
            >
              <Input
                data-test='input-document-number'
                height={'45px'}
                placeholder={'*Document Number'}
                _placeholder={{ color: '#535353', fontSize: '16px' }}
                borderRadius={0}
                fontSize={'16px'}
                borderColor={colors.buttonBorderGrey}
                onChange={(e) => handleDocumentNumber(e.target.value, i)}
                value={capitalizeEachLetter(
                  addressProofDetails[i].documentNumber,
                )}
                disabled={isAllFieldsDisable[holderNumber]}
                _disabled={{ color: 'black' }}
                variant="flushed"
                alignSelf={'flex-end'}
              />
              {!addressProofDetails[i].documentNumber &&
                saveAndContinueClicked && (
                  <div className={styles['errorRegMessage']}>
                    {Strings.thisIsRequired}
                  </div>
                )}
            </Flex>
            <Flex w={'25%'} flex={1} pt={{ base: 4, md: 0 }}>
              <Flex direction={'column'} alignItems={'start'} flex={1}>
                {list.documentUrl != '' && (
                  <UrlShower
                    values={addressProofDetails[i].documentUrl}
                    valuesType={addressProofDetails[i].documentType}
                    documentName={addressProofDetails[i].documentName}
                    index={i}
                    deleteUrl={deleteImage}
                    isMultiple={true}
                    height={'34px'}
                    width={'60px'}
                    handleCrossSign={handleCrossSign}
                    holderNumber={holderNumber}
                    setAadharProofBackUrl={setAadharProofBackUrl}
                    aadharProofBackUrl={aadharProofBackUrl}
                    saveAndContinueClicked={saveAndContinueClicked}
                    rejectionReason={
                      addressProofDetails[i].documentRejectionReason
                    }
                  />
                )}
                {list.documentUrl == '' && (
                  <Flex direction={'column'}>
                    <Text
                      fontSize={'12px'}
                      color={'#535353'}
                      fontWeight={'light'}
                    >
                      *Please Upload Proof
                    </Text>
                    <Flex>
                      <Flex direction={'column'} pr={4}>
                        <label className={styles['fileUpload']}>
                          <input
                            data-test={`input-${testId}-uploading-details`}
                            id="file"
                            type="file"
                            accept="image/*,.doc, .docx,.pdf"
                            disabled={isAllFieldsDisable[holderNumber]}
                            onChange={(e) => handleChangeUploadFile(e, i)}
                          />
                          <Flex
                            direction={'column'}
                            justifyContent={'center'}
                            flex={1}
                            color={colors.bgDarkBlue}
                          >
                            <Text
                              alignSelf={'center'}
                              fontSize={'18px'}
                              fontWeight={'500'}
                            >
                              Choose File
                            </Text>
                            <Text alignSelf={'center'} fontSize="12px">
                              (Maximum file size 2 MB)
                            </Text>
                          </Flex>
                        </label>
                      </Flex>
                      {i > 0 && (
                        <Flex alignSelf={'center'}>
                          <Image
                            src={CrossSignImage}
                            boxSize="20px"
                            cursor={'pointer'}
                            className={styles['crossSign']}
                            onClick={() => handleCrossSign(i)}
                            alt=""
                          ></Image>
                        </Flex>
                      )}
                    </Flex>
                    {!addressProofDetails[i].documentUrl &&
                      saveAndContinueClicked && (
                        <div className={styles['errorRegMessage']}>
                          {Strings.thisIsRequired}
                        </div>
                      )}
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        ))}
      {addressProofDetails?.length < documentTypeList?.length && (
        <Flex mt={12}>
          <Button
            data-test='button-to-add-another-proof'
            color={colors.buttonColor}
            bgColor={'white'}
            border={'solid 1px'}
            fontSize={'14px'}
            fontWeight={'400'}
            p={'10px 50px'}
            _hover={{ bg: 'white' }}
            _active={{ bg: 'white' }}
            type={'button'}
            onClick={handleAddAnotherProof}
            disabled={isAllFieldsDisable[holderNumber]}
            _disabled={{ color: 'black' }}
          >
            ADD ANOTHER PROOF
          </Button>
        </Flex>
      )}
    </>
  );
};

export default AddressProofTile;
