import React from 'react';
import CustomThreePartition from '../../components/CustomThreePartition';
import backgroundImage from '../../components/Kyc/resources/kycBackground.png';
import MainSection from './MainSection';
import TokenSection from './TokenSecton';
import RouteSection from './RouteSection';
import { useDispatch, useSelector } from 'react-redux';
import DashboardWelcomeNote from './DashboardWelcomeNote';
import { setFirstDashboard } from '../../store/actions';

const InvestmentDashboard = () => {
  const firstDashboard = useSelector(
    (state: any) => state.InvestmentReducer?.firstDashboard,
  );

  const dispatch = useDispatch();

  const onCloseWelcomeModal = () => {
    dispatch(setFirstDashboard(false));
  };
  return (
    <>
      <CustomThreePartition
        background_img={backgroundImage}
        FirstSection={RouteSection}
        SecondSection={MainSection}
        ThirdSection={TokenSection}
      />
      {firstDashboard && (
        <DashboardWelcomeNote
          isOpen={firstDashboard}
          onClose={() => {
            onCloseWelcomeModal();
          }}
        />
      )}
    </>
  );
};

export default InvestmentDashboard;
