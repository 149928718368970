export const removeValueFromArray = (values: string[], value: string) => {
  const newValues = values.filter(function (item) {
    return item !== value;
  });
  return newValues;
};

export const inWords = (num: string): string => {
  var a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen ',
  ];
  var b = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  if ((num = num.toString()).length > 9) return '';
  var n: any = ('000000000' + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return '';
  var str = '';
  if (n[5] != 0) {
    str = Number(n[5]) || n[5][0] + n[5][1];
  }
  if (n[4] != 0) {
    str = (Number(n[4]) || n[4][0] + n[4][1]) + ' Hundred';
  }
  if (n[3] != 0) {
    str = (Number(n[3]) || n[3][0] + n[3][1]) + ' Thousand';
  }
  if (n[2] != 0) {
    str = (Number(n[2]) || n[2][0] + n[2][1]) + ' Lakh';
  }
  if (n[1] != 0) {
    str = (Number(n[1]) || n[1][0] + n[1][1]) + ' Crore';
  }
  if (str === '') {
    return '0';
  }
  return str;
};
