export enum PropertyCostBreakup {
  costOfPremises = 'Cost of Premises',
  otherCharges = 'Other Charges',
  stampDutyAndRegistration = 'Stamp Duty and Registration',
  camAndPropertyTax = 'CAM & Property Tax',
  legalFees = 'Legal Fees',
  adminExpenses = 'Admin Expenses',
  acquistionFees = 'Acquisition Fee',
  reserves = 'Reserves',
}
