import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Environment, Strings } from '@hBits.Core';
import { Colors } from 'apps/customer-portal-web/src/theme';
import { NUM_DIFFERENTIATION } from 'apps/landing-pages-web/src/components/common/utils/helper';
import styles from '../mainsection.module.scss';
import { PropertyProps } from './SinglePropertyCard';

const DISPLAY_NAME_LENGTH = 30;

const PropertyCard = ({
  propertyId,
  displayName,
  ownedPercentage,
  investedValue,
  monthlyRent,
  city,
  image,
  investmentStage,
}: PropertyProps) => {
  const textSlicer = (value: string = '', dlimiter: number = 0) => {
    return value.length >= dlimiter
      ? `${value.substring(0, dlimiter)}...`
      : value;
  };
  return (
    <Box
      key={propertyId}
      className={styles['box-shadow-contsiner']}
      borderRadius={5}
      px={4}
      py={4}
      bg={Colors.white}
      mt={[4, 0]}
      width={['100%', '30%']}
      mr={[0, '3%']}
      my={['20px']}
      overflow={'hidden'}
      textOverflow={'ellipsis'}
    >
      <Image
        w={'100%'}
        h={180}
        objectFit="cover"
        src={image}
        alt={displayName}
        borderRadius={3}
        mb={3}
      />
      <Text
        fontSize="md"
        as={'b'}
        color={Colors.headerColor}
        casing={'uppercase'}
        h={'50px'}
      >
        {textSlicer(displayName, DISPLAY_NAME_LENGTH)}
      </Text>
      <Flex pt={3} justifyContent={'space-between'}>
        <Box>
          <Text fontSize="xs" color={Colors.grey200}>
            Owned
          </Text>
          <Text fontSize="sm" fontWeight={'900'} color={Colors.blackColor}>
            {ownedPercentage?.toFixed(2)}%
          </Text>
        </Box>
        <Box>
          <Text fontSize="xs" color={Colors.grey200} textAlign={'right'}>
            Location
          </Text>
          <Text
            fontSize="sm"
            color={Colors.blackColor}
            fontWeight={'900'}
            textAlign={'right'}
          >
            {city}
          </Text>
        </Box>
      </Flex>
      <Flex pt={3} justifyContent={'space-between'}>
        <Box>
          <Text fontSize="xs" color={Colors.grey200} textAlign={'left'}>
            Investment value
          </Text>
          <Text
            fontSize="sm"
            color={Colors.blackColor}
            fontWeight={'900'}
            textAlign={'left'}
          >
            ₹{NUM_DIFFERENTIATION(investedValue, false)}
          </Text>
        </Box>
        {/* stage */}
        <Box>
          <Text fontSize="xs" color={Colors.grey200} textAlign={'left'}>
            Stage
          </Text>
          <Text
            fontSize="sm"
            color={Colors.blackColor}
            fontWeight={'900'}
            textAlign={'left'}
          >
            {investmentStage === "Transferred" ? "Transferred" : "Active"}
          </Text>
        </Box>
      </Flex>
      <Flex pt={3} justifyContent={'space-between'}>
        <Box />
        <Text
          as="u"
          color={Colors.headerColor}
          fontWeight={'900'}
          fontSize={['14px']}
          alignSelf={'flex-end'}
          cursor={'pointer'}
          onClick={() => {
            window.location.assign(
              Environment.getInstance().getParam(
                'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
              ) + `/properties/${propertyId}`,
            );
          }}
        >
          {Strings.viewDetails}
        </Text>
      </Flex>
    </Box>
  );
};

export default PropertyCard;
