import React from 'react';
import { FC } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import colors from 'apps/customer-portal-web/src/theme/colors';
import CustomButton from 'apps/customer-portal-web/src/components/CustomButton';
import { CrossIcon } from 'apps/customer-portal-web/src/assets/customIcons';
import { string } from 'joi';
import { Colors, Strings } from '@hBits.Core';

interface EOIStep1Props {
  openZoho?: any;
  eoiDocLoader?: boolean;
}

const EOIStep2: FC<EOIStep1Props> = (props) => {
  const { openZoho, eoiDocLoader } = props;

  return (
    <>
      <Flex
        width={'100%'}
        flexDirection={'column'}
        pl={[0, 0, 3, 3]}
        mt={[3, 3, 0, 0]}
      >
        <Flex
          width={'70%'}
          borderBottomWidth=".5px"
          borderBottomColor={colors.blackColor}
          pb={3}
        >
          <CrossIcon />
          <Text as="b" fontSize="14px" color={colors.grey200} ml={'10px'}>
            EOI DocuSign Pending
          </Text>
        </Flex>
        <Flex mt={3}>
          <Text fontSize="14px" color={colors.grey}>
            Please sign the EOI DocuSign document
          </Text>
        </Flex>
        <Flex mt={'50px'} flexDirection="column">
          {eoiDocLoader ? (
            <Flex
              justifyContent={'flex-start'}
              alignItems="center"
              ml={['10px', '10px', '20px', '20px']}
              mt={'20px'}
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Flex>
          ) : (
            <Flex>
              <CustomButton
                btnText={'SIGN THE DOCUMENT'}
                btnPressed={() => openZoho()}
              />
            </Flex>
          )}
        </Flex>
        <Flex mt={12} flexDirection="column">
          <Flex></Flex>
          <Text fontSize="14px" as="b" color={colors.grey} mt={3}>
            {Strings.forQueries}
          </Text>
          <Text fontSize="13px" as="b" color={Colors.headerBlue} mt={1}>
            {Strings.contactusregestration}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default EOIStep2;
