import {
    ChakraProvider,
    ModalOverlay,
    ModalContent,
    Flex,
    Modal,
    ModalBody,
    ModalHeader,
    ModalCloseButton,
  } from '@chakra-ui/react';
  import { Colors } from '@hBits.Core';
  import circleLogo from 'apps/customer-portal-web/src/assets/kyc/circleLogo.svg';
  import ReactPlayer from 'react-player';
  
  export interface KycVideoModalProps {
    isOpen: boolean;
    onModalClose: () => void;
  }
  
  const KycVideoModal = (props: KycVideoModalProps) => {
    const { isOpen, onModalClose } = props;
    return (
      <ChakraProvider>
        <Modal
          size={['sm', '4xl']}
          isOpen={isOpen}
          onClose={onModalClose}
          closeOnOverlayClick={false}
          isCentered
        >
          <ModalOverlay />
          <ModalContent padding={['15px 10px', '35px 70px']}>
            <ModalHeader
              fontWeight="600"
              backgroundColor={Colors.white}
              color={Colors.blackColor}
              fontSize={['18px', '2rem']}
              className="text-center"
              px={['0.5rem', '1rem']}
            >
              Kyc Process
            </ModalHeader>
            <ModalCloseButton mr={3} mt={3}>
              <img  data-test= 'i-close-button' src={circleLogo}></img>
            </ModalCloseButton>
            <ModalBody>
              <Flex p={2}></Flex>
              <ReactPlayer
                playing={true}
                controls={true}
                url={'https://youtu.be/_aQePDxAfn4'}
                height={'400px'}
                width={'100%'}
              />
              <Flex p={2}></Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    );
  };
  
  export default KycVideoModal;
