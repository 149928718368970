import React, { useEffect, useState } from 'react';
import '../ChannelPartnerKyc/index.scss';
import CpKycContainerHeaderTile from '../ChannelPartnerKyc/HelperComponents/CpKycContainerHeaderTile';
import BasicDetails from './BasicDetails';
import PanBankDetails from './PanBankDetails';
import GstOthersDetails from './GstOthersDetails';
import { getData } from './ApiData/apiData';
import { KycStatus } from '../../../../../../../../libs/src/enums/customer-kyc.enum';
import KycDetailsPageEnum from '../../../../../../../../libs/src/enums/channel-partner-kyc.enums';
import accountIdManager from 'libs/src/auth/account-manager';
import KycStatusSubmitted from 'apps/customer-portal-web/src/components/Kyc/KycContainer/kycStatusSubmitted';
import KycStatusKycInProgress from 'apps/customer-portal-web/src/components/Kyc/KycContainer/KycStatusKycInProgress';
import KycStatusInvalidated from 'apps/customer-portal-web/src/components/Kyc/KycContainer/KycStatusInvalidated';
import KycStatusVerified from 'apps/customer-portal-web/src/components/Kyc/KycContainer/KycStatusVerified';
import { isEmpty } from '../../../../../utils/index';
import { Strings } from '@hBits.Core';

function ChannelPartnerKyc() {
  const [isBasicDetailsClicked, setIsBasicDetailsClicked] = useState(true);
  const [isPanBankDetailsClicked, setIsPanBankDetailsClicked] = useState(false);
  const [isGstOtherDetailsClicked, setIsGstOtherDetailsClicked] =
    useState(false);
  const [containerHeaderState, setContainerHeaderState] =
    useState('basicdetails');
  const [accountStatus, setAccountStatus] = useState<string>();
  const [kycSubmitted, setKycSubmitted] = useState(false);
  const [kycNotStarted, setKycNotStarted] = useState(true);
  const [kycUpdated, setKycUpdated] = useState(false);
  const [kycVerificationInProgress, setKycVerificationInProgress] =
    useState(false);
  const [kycVerified, setKycVerified] = useState(false);
  const [kycInvalidated, setKycInvalidated] = useState(false);
  const [kycInProgress, setKycInProgress] = useState(false);
  const [showIsSubmittedPage, setShowIsSubmittedPage] = useState(false);

  const [accountDetails, setAccountDetails] = useState<any>({});

  const updateStateCallback = (state: any) => {
    handleClick(state);
    setContainerHeaderState(state);
  };

  useEffect(() => {
    if (accountStatus === KycStatus.KycInvalidated) {
      if (
        accountDetails?.channelPartnerBankDetails
          ?.bankAccountVerificationStatus === Strings.rejected ||
        accountDetails?.channelPartnerBankDetails?.msmeVerificationStatus ===
          Strings.rejected ||
        accountDetails?.channelPartnerBankDetails?.panVerificationStatus ===
          Strings.rejected
      ) {
        updateStateCallback('panbankdetails');
      } else {
        updateStateCallback('gstotherdetails');
      }
    }
  }, [accountDetails]);

  const handleClick = (id: string) => {
    setContainerHeaderState(id);
    switch (id) {
      case KycDetailsPageEnum.basicdetails:
        setIsBasicDetailsClicked(true);
        setIsPanBankDetailsClicked(false);
        setIsGstOtherDetailsClicked(false);
        break;
      case KycDetailsPageEnum.panbankdetails:
        setIsBasicDetailsClicked(false);
        setIsPanBankDetailsClicked(true);
        setIsGstOtherDetailsClicked(false);
        break;
      case KycDetailsPageEnum.gstotherdetails:
        setIsBasicDetailsClicked(false);
        setIsPanBankDetailsClicked(false);
        setIsGstOtherDetailsClicked(true);
        break;
      default:
        break;
    }
  };

  const checkKycStatus = (isStart?: any) => {
    getData(accountIdManager?.getAccountId()).then((res: any) => {
      if (isEmpty(res)) return;
      setAccountStatus(res.kycStatus);
      setAccountDetails(res);
      if (isStart && res.kycStatus === KycStatus.KycSubmitted) {
        setShowIsSubmittedPage(true);
      }
    });
  };

  React.useEffect(() => {
    if (!accountIdManager?.getAccountId()) return;
    checkKycStatus(true);
  }, []);

  React.useEffect(() => {
    updateKycStatus();
  }, [accountStatus]);

  const updateKycStatus = () => {
    switch (accountStatus) {
      case KycStatus.KycNotStarted:
        setKycNotStarted(true);
        setKycInProgress(false);
        setKycSubmitted(false);
        setKycUpdated(false);
        setKycVerificationInProgress(false);
        setKycVerified(false);
        setKycInvalidated(false);
        break;
      case KycStatus.KycInProgress:
        setKycInProgress(true);
        setKycNotStarted(false);
        setKycSubmitted(false);
        setKycUpdated(false);
        setKycVerificationInProgress(false);
        setKycVerified(false);
        setKycInvalidated(false);
        break;
      case KycStatus.KycSubmitted:
        setKycSubmitted(true);
        setKycNotStarted(false);
        setKycUpdated(false);
        setKycVerificationInProgress(false);
        setKycVerified(false);
        setKycInvalidated(false);
        setKycInProgress(false);
        break;
      case KycStatus.KycUpdated:
        setKycUpdated(true);
        setKycNotStarted(false);
        setKycSubmitted(false);
        setKycVerificationInProgress(false);
        setKycVerified(false);
        setKycInvalidated(false);
        setKycInProgress(false);
        break;
      case KycStatus.KycVerificationInProgress:
        setKycVerificationInProgress(true);
        setKycNotStarted(false);
        setKycUpdated(false);
        setKycSubmitted(false);
        setKycVerified(false);
        setKycInvalidated(false);
        setKycInProgress(false);
        break;
      case KycStatus.KycVerified:
        setKycVerified(true);
        setKycNotStarted(false);
        setKycVerificationInProgress(false);
        setKycUpdated(false);
        setKycSubmitted(false);
        setKycInvalidated(false);
        setKycInProgress(false);

        break;
      case KycStatus.KycInvalidated:
        setKycInvalidated(true);
        setKycNotStarted(false);
        setKycVerified(false);
        setKycVerificationInProgress(false);
        setKycUpdated(false);
        setKycSubmitted(false);
        setKycInProgress(false);
        break;
      default:
        setKycNotStarted(true);
        break;
    }
  };

  return (
    <div className="cpkyccontainer">
      {(kycNotStarted ||
        kycInProgress ||
        kycVerified ||
        kycUpdated ||
        kycInvalidated ||
        (kycSubmitted && !showIsSubmittedPage)) && (
        <div>
          <div>
            <h2 className="cpkyc_update-kyc-details">Update KYC Details</h2>
          </div>
          <div className="cpkyc_outercard">
            {kycVerified && (
              <KycStatusVerified
                classNameOuterDiv=""
                classNameInnerDiv="kyc_verified"
                text=""
              />
            )}
            {kycInvalidated && <KycStatusInvalidated className="" text="" />}
            <div className="cpkyc_d-flex cpkyc_justify-content-between">
              <div className="cpkyc_d-flex ">
                <CpKycContainerHeaderTile
                  isClicked={isBasicDetailsClicked}
                  handleClick={handleClick}
                  text={'Basic Details'}
                  idText={'basicdetails'}
                />
                <CpKycContainerHeaderTile
                  isClicked={isPanBankDetailsClicked}
                  handleClick={handleClick}
                  text={'Bank & Pan Details'}
                  idText={'panbankdetails'}
                />
                <CpKycContainerHeaderTile
                  isClicked={isGstOtherDetailsClicked}
                  handleClick={handleClick}
                  text={'GST & Other Details'}
                  idText={'gstotherdetails'}
                />
              </div>
            </div>
            <div>
              {(() => {
                switch (containerHeaderState) {
                  case KycDetailsPageEnum.basicdetails:
                    return (
                      <BasicDetails
                        updateStateCallback={updateStateCallback}
                        kycVerified={kycVerified}
                      />
                    );
                  case KycDetailsPageEnum.panbankdetails:
                    return (
                      <PanBankDetails
                        updateStateCallback={updateStateCallback}
                        kycVerified={kycVerified}
                        checkKycStatus={checkKycStatus}
                      />
                    );
                  case KycDetailsPageEnum.gstotherdetails:
                    return (
                      <GstOthersDetails
                        kycVerified={kycVerified}
                        checkKycStatus={checkKycStatus}
                        accountStatus={accountStatus}
                        setShowIsSubmittedPage={setShowIsSubmittedPage}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
      )}
      {kycSubmitted && showIsSubmittedPage && (
        <div className="cpkyc_outercard">
          <KycStatusSubmitted
            setSkipAndSave={false}
            isChannelPartner={true}
            setShowIsSubmittedPage={setShowIsSubmittedPage}
            updateStateCallback={updateStateCallback}
          />
        </div>
      )}
      {kycVerificationInProgress && (
        <div className="cpkyc_outercard">
          <KycStatusKycInProgress isChannelPartner={true} />
        </div>
      )}
    </div>
  );
}

export default ChannelPartnerKyc;
