import { Flex, Select } from '@chakra-ui/react';
import { Colors, Strings } from '@hBits.Core';
import colors from 'libs/src/theme/colors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styles from '../index.module.scss';

interface SelectDropdownProps {
  values?: string[];
  value?: string | undefined;
  placeholder?: string;
  flex?: number;
  setSelectedDropdownValue: (value: string) => void;
  paddingRight?: number;
  isMandatory?: boolean;
  errors?: any;
  setFieldTouched?: any;
  touched?: any;
  handleChangeName?: string;
  isDisable?: boolean;
  holderNumber: number;
  testId?: string;
}

const CustomSelectDropdown = (props: SelectDropdownProps) => {
  const { isAllFieldsDisable } = useSelector((state: any) => state.KycReducer);
  const {
    value,
    placeholder,
    values,
    setSelectedDropdownValue,
    paddingRight,
    isMandatory = true,
    flex = 1,
    errors,
    setFieldTouched,
    touched,
    handleChangeName,
    holderNumber,
    isDisable = isAllFieldsDisable[holderNumber],
    testId,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDropdownValue(event.target.value);
  };
  return (
    <Flex flex={{ base: 1, md: flex }} pr={{ base: 0, md: paddingRight }} direction={'column'}>
      <Select
        data-test={testId}
        width={'100%'}
        fontSize={'16px'}
        height={'45px'}
        borderColor={Colors.buttonBorderGrey}
        value={value}
        onChange={handleChange}
        placeholder={`${placeholder}`}
        color={value ? Colors.blackColor : colors.kycPlaceholder}
        onBlur={() => setFieldTouched(`${handleChangeName}`)}
        variant="flushed"
        disabled={isDisable}
        _disabled={{ color: 'black' }}
      >
        {values?.map((value) => (
          <option
            key={value}
            value={value}
            style={{ color: Colors.blackColor }}
          >
            {value}
          </option>
        ))}
      </Select>
      {errors && isMandatory && touched && (
        <div className={styles['errorRegMessage']}>This is Required</div>
      )}
    </Flex>
  );
};
export default CustomSelectDropdown;
