import { useEffect } from 'react';
import { Environment } from 'libs/src/services/EnvironmentHelper';
import * as React from 'react';
import Turnstile, { BoundTurnstileObject, useTurnstile } from 'react-turnstile';

const RecaptchaBox = ({
  setFieldValue,
  errors,
  touched,
  justify = 'center',
  resetCaptcha,
}: any) => {
  const recaptchaKey = Environment.getInstance().getParam(
    'NEXT_PUBLIC_RECAPTCHA_V2_KEY',
  );
  const turnstile = useTurnstile();

  useEffect(() => {
    if (resetCaptcha) {
      setFieldValue('token', null);
      turnstile.reset();
    }
  }, [resetCaptcha]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: justify,
        flexDirection: 'column',
      }}
    >
      <Turnstile
        sitekey={recaptchaKey}
        size="normal"
        onVerify={(token) => {
          setFieldValue('token', token);
        }}
        onExpire={() => {
          setFieldValue('token', null);
          turnstile.reset();
        }}
      />
      {errors.token && touched.token && (
        <div
          data-test="div-inline-error-msg-mobileno"
          className="errorRegMessage"
        >
          {errors.token.toString()}
        </div>
      )}
    </div>
  );
};

export default RecaptchaBox;
