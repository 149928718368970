import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import BasicDetails from '../../KycScreens/BasicDetails';
import RepresentativeAddressDetails from '../../KycScreens/RepresentativeAddressDetails';
import MainAddressDetails from '../../KycScreens/MainAddressDetails';
import BankDetails from '../../KycScreens/BankDetails';
import colors from 'libs/src/theme/colors';
import { TabNameEnum } from '@hBits.Core';
import { TAB_VALUE } from 'apps/customer-portal-web/src/store/constants/ReduxConstants';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

interface LLPHolderProps {
  tabName: string[];
  holderNumber: number;
}

const LLPHolder = (props: LLPHolderProps) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { tabName, holderNumber } = props;
  const { tabValue } = useSelector((state: any) => state.KycReducer);
  const [tabIndex, setTabIndex] = useState(tabValue);
  useEffect(() => {
    if(searchParams.get('tab') === 'PanBank' && holderNumber === 0 ){
      setTabIndex(2);
    }
    else{
      setTabIndex(tabValue[holderNumber]);
    }
  }, [tabValue,searchParams]);
  const renderTabValues = (value: any) => {
    switch (value) {
      case TabNameEnum.Basic_Details:
        return <BasicDetails holderNumber={holderNumber} />;
      case TabNameEnum.Partner_Address:
        return (
          <MainAddressDetails
            backPanNameText="(Designated Partner)"
            holderNumber={holderNumber}
          />
        );
      case TabNameEnum.PAN_Bank_Details:
        return (
          <BankDetails frontPanNameText="Firm " holderNumber={holderNumber} />
        );
      case TabNameEnum.LLP_Address:
        return <RepresentativeAddressDetails holderNumber={holderNumber} />;
      default:
        return <BasicDetails holderNumber={holderNumber} />;
    }
  };

  return (
    <Tabs
      variant="unstyled"
      flex={1}
      index={tabIndex}
      onChange={(index) => {
        setTabIndex(index);
        dispatch({
          type: TAB_VALUE,
          payload: {
            ...tabValue,
            [holderNumber]: index,
          },
        });
      }}
    >
      <TabList>
        {tabName.map((value: any) => (
          <Tab
            fontSize={{ base: '12px', md: '20px' }}
            color={colors.greyUserNameColor}
            _selected={{ color: colors.bgDarkBlue, fontWeight: '700' }}
          >
            {value}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabName.map((value: any) => (
          <TabPanel>{renderTabValues(value)}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
export default LLPHolder;
