import { InvestmentStage, EOIPaymentStatus, Strings } from '@hBits.Core';
export const getInvestmentStep = (value: string, fullPay: any = {}) => {
  // external check for registrion step

  if (
    fullPay?.balanceAmountPaymentStatus ===
      EOIPaymentStatus.TransactionSucceeded &&
    window.location.search.includes('?transactionStatus=successful')
  ) {
    return 3;
  }
  if (
    fullPay?.balanceAmountPaymentStatus ===
      EOIPaymentStatus.TransactionSucceeded &&
    value === InvestmentStage.RegistrationComplete
  ) {
    return 4;
  }
  switch (value) {
    case InvestmentStage.NotStarted:
      return 1;
    case InvestmentStage.PrimaryEOI:
      return 1;
    case InvestmentStage.PAS4:
      return 2;
    case InvestmentStage.FullPay:
      return 3;
    case InvestmentStage.RegistrationComplete:
      return 4;
    default:
      return 1;
  }
  return 1;
};

export const getEoiStep = (
  investmentAmountEoi: any = {},
  topUpAmountEoi: any = {},
) => {
  if (
    topUpAmountEoi &&
    topUpAmountEoi?.eoiDocumentSigningStatus === 'NotStarted'
  ) {
    return 0;
  }

  // check for  EOI Document failure
  if (investmentAmountEoi?.eoiDocumentSigningStatus === 'Error') {
    return 1;
  }

  if (topUpAmountEoi?.eoiDocumentSigningStatus === 'Error') {
    return 1;
  }
  // check for TopUp EOI Document failure
  if (topUpAmountEoi && topUpAmountEoi?.eoiPaymentStatus === 'NotStarted') {
    return 0;
  }

  if (topUpAmountEoi && topUpAmountEoi?.eoiPaymentStatus === 'InProgress') {
    return 0;
  }

  if (
    investmentAmountEoi &&
    investmentAmountEoi?.eoiPaymentStatus === EOIPaymentStatus.TransactionFailed
  ) {
    return 3;
  }
  if (
    topUpAmountEoi &&
    topUpAmountEoi?.eoiPaymentStatus === EOIPaymentStatus.TransactionFailed
  ) {
    return 3;
  }
  if (
    investmentAmountEoi &&
    investmentAmountEoi?.eoiPaymentStatus ===
      EOIPaymentStatus.TransactionSucceeded
  ) {
    return 2;
  }

  return 0;
};

export const getPas4Step = (pas4: any = {}) => {
  if (pas4?.pas4DocumentSigningStatus === 'Error') {
    return 1;
  }
  return 0;
};

export const fullPayScreenSelector = (fullPay: any = {}) => {
  if (
    fullPay?.balanceAmountPaymentStatus === EOIPaymentStatus.TransactionFailed
  ) {
    return 2;
  }
  if (
    fullPay?.balanceAmountPaymentStatus ===
    EOIPaymentStatus.TransactionSucceeded
  ) {
    return 1;
  }
  return 0;
};

// Download widget custom Function

export const extractDownloadFile = (obj: any = {}) => {
  if (Object.keys(obj).length === 0) {
    return [];
  }
  let downloadUrlArray = [];
  const {
    investmentAmountEoi = {},
    topUpEoiModels = [],
    pas4 = {},
    fullPay = {},
  } = obj;

  //extracting eoiDocUrl
  if (investmentAmountEoi?.signedEOIDocumentUrl !== null) {
    downloadUrlArray.push({
      title: Strings.eoiDocument,
      url: investmentAmountEoi?.signedEOIDocumentUrl,
    });
  }
  //extracting eoiPaymentRecieptUrl
  if (investmentAmountEoi?.investmentAmountPaidAtEOIRecieptUrls?.length > 0) {
    {
      Array.isArray(
        investmentAmountEoi?.investmentAmountPaidAtEOIRecieptUrls,
      ) &&
        investmentAmountEoi?.investmentAmountPaidAtEOIRecieptUrls?.map(
          (value: any, index: number) =>
            downloadUrlArray.push({
              title: `${Strings.eoiPaymentReciept} - ${index + 1}`,
              url: value,
            }),
        );
    }
  }

  // extracting topup EOI doc & payment slips .....
  if (topUpEoiModels.length > 0) {
    let i = 0;
    for (let itm of topUpEoiModels) {
      // adding topup eoi doc
      if (itm?.signedEOIDocumentUrl !== null) {
        downloadUrlArray.push({
          title: `${Strings.topUpDocument} ${i + 1}`,
          url: itm?.signedEOIDocumentUrl,
        });
      }

      // adding topup payment slip
      if (itm?.investmentAmountPaidAtEOIRecieptUrls !== null) {
        if (Array.isArray(itm?.investmentAmountPaidAtEOIRecieptUrls)) {
          itm.investmentAmountPaidAtEOIRecieptUrls.forEach(
            (url: any, index: any) => {
              downloadUrlArray.push({
                title: `${Strings.topUpPaymentReciept} ${index + 1}`,
                url: url,
              });
            },
          );
        } else {
          downloadUrlArray.push({
            title: `${Strings.topUpPaymentReciept} 1`,
            url: itm.investmentAmountPaidAtEOIRecieptUrls,
          });
        }
      }
      i++;
    }
  }
  // extracting PAS4 document url
  if (pas4 && pas4?.pas4SignedDocumentUrl !== null) {
    downloadUrlArray.push({
      title: Strings.pas4EOIDocument,
      url: pas4?.pas4SignedDocumentUrl,
    });
  }
  // extracting FULLPAY payment slip
  if (
    fullPay &&
    fullPay?.balanceAmountPaymentTransactionUrls &&
    fullPay?.balanceAmountPaymentTransactionUrls.length > 0
  ) {
    {
      Array.isArray(fullPay?.balanceAmountPaymentTransactionUrls) &&
        fullPay?.balanceAmountPaymentTransactionUrls?.map(
          (value: any, index: number) =>
            downloadUrlArray.push({
              title: `${Strings.fullPaymentReciept} - ${index + 1}`,
              url: value,
            }),
        );
    }
  }
  return downloadUrlArray;
};

export const getLocation = (data = []) => {
  for (let itm of data) {
    if (itm['type'] === 'Property') {
      return itm['city'];
    }
  }
  return '';
};
