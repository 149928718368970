import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { Colors, Environment } from '@hBits.Core';
import { EditIcon } from 'apps/customer-portal-web/src/assets/customIcons';
import RuppeIcon from 'apps/customer-portal-web/src/assets/RuppeIcon.svg';
import CustomButton from 'apps/customer-portal-web/src/components/CustomButton';
import {
  getBalanceAmountUrl1,
  payBalanceAmountUsingBilldesk,
} from 'apps/customer-portal-web/src/services/propertyInvestment/fullPayServices';
import colors from 'apps/customer-portal-web/src/theme/colors';
import { BilldeskPaymentDetails } from 'apps/customer-portal-web/src/types';
import { NUM_FORMATTING } from 'apps/landing-pages-web/src/components/common/utils/helper';
import { Form, Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import BillDeskLogo from '../../../../assets/billdesk-logo.svg';
import CashFreeLogo from '../../../../assets/cash-free-logo.svg';
import PaymentHistory from '../../paymentHistory';
import PaymentProgress from '../../paymentProgress';

interface EOIStep1Props {
  openZoho?: any;
  setCurrentPas4Value?: any;
}

interface FromikValues {
  balanceAmount: any;
}

const FullPay1: FC<EOIStep1Props> = (props) => {
  const investmentModal = useSelector(
    (state: any) => state.InvestmentReducer?.propertyData,
  );

  const {
    fullPay,
    investmentAmountEoi,
    propertyDetails,
    minTicketSizeApplied,
    maxTicketSizeApplied,
    investmentAmount,
  } = investmentModal;
  const [fullPayPaymentLoader, setFullPayPaymentLoader] =
    useState<boolean>(false);
  const [displayPayGatewayOptions, setDisplayPayGatewayOptions] =
    useState<boolean>(false);
  const [enteredAmount, setEnteredAmount] = useState<any>();
  const { investmentId } = investmentAmountEoi;

  const getBalAmountUrl = async () => {
    setFullPayPaymentLoader(true);
    if (enteredAmount > 5000000) {
      confirm('To Pay using cashfree amount must be less than 50 lakhs');
    }
    const balanceAmountUrl = await getBalanceAmountUrl1(
      investmentId,
      enteredAmount,
    );
    if (balanceAmountUrl?.pgUrl !== undefined) {
      window.location.replace(balanceAmountUrl?.pgUrl);
    }
    setFullPayPaymentLoader(false);
  };

  useEffect(() => {
    setEnteredAmount(fullPay.balanceAmount - fullPay.balanceAmountPaid);
  }, [fullPay]);

  const payUsingBillDesk = async () => {
    setFullPayPaymentLoader(true);

    try {
      const billdeskPayment = await payBalanceAmountUsingBilldesk(
        investmentId,
        enteredAmount,
      );

      if (billdeskPayment) {
        const billdeskPaymentDetails: BilldeskPaymentDetails =
          jwtDecode(billdeskPayment);

        const { bdorderid, mercid, ru, links } = billdeskPaymentDetails;

        const form = document.forms['sdklaunch'];

        form.setAttribute(
          'action',
          Environment.getInstance().getParam(
            'NEXT_PUBLIC_BILDESK_PAYMENT_PROVIDER_BASE_URL',
          ),
        );

        form.elements['bdOrderId'].value = bdorderid;
        form.elements['authToken'].value = links[1].headers.authorization;
        form.elements['merchantId'].value = mercid;
        form.elements['returnUrl'].value = ru;

        form.submit();
      }
    } finally {
      setFullPayPaymentLoader(false);
    }
  };

  const checkPaymentOptions = () => {
    const { pgProvider } = propertyDetails;

    if (pgProvider.length === 2) {
      setDisplayPayGatewayOptions(true);
    } else {
      pgProvider[0] === 'BillDesk' ? payUsingBillDesk() : getBalAmountUrl();
    }
  };
  const handleSubmit = () => {};
  const getInitialValues: () => FromikValues = () => {
    var initialValues = {
      balanceAmount: fullPay.balanceAmount - fullPay.balanceAmountPaid,
    };
    return initialValues;
  };
  const SignupSchema = Yup.object().shape({
    totalInvestAmount: Yup.number()
      .min(
        parseInt('130000' || ''),
        `*Please input a minimum amount of ₹${minTicketSizeApplied}`,
      )
      .max(
        parseInt(maxTicketSizeApplied || ''),
        `*Please input amount less than  ₹${maxTicketSizeApplied}`,
      ),
    initialTokenAmount: Yup.number().max(
      parseInt(investmentAmount || ''),
      `*Please input amount less than  ₹${investmentAmount}`,
    ),
  });

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        validationSchema={SignupSchema}
        enableReinitialize={true}
      >
        {({
          handleChange,
          values,
          setFieldTouched,
          errors,
          touched,
          isValid,
        }) => (
          <Form className="w-full">
            <Flex width={'100%'} flexDirection={'column'} pl={[0, 0, 3, 3]}>
              {propertyDetails?.pgProvider?.includes('BillDesk') && (
                <form name="sdklaunch" id="sdklaunch" method="POST" action="">
                  <input
                    type="hidden"
                    id="merchantLogo"
                    name="merchantLogo"
                    value="base64 image of logo"
                  />
                  <input
                    type="hidden"
                    id="flowType"
                    name="flowType"
                    value="payments"
                  />
                  <input
                    type="hidden"
                    id="retryCount"
                    name="retryCount"
                    value="1"
                  />
                  <input type="hidden" id="bdOrderId" name="bdOrderId" />
                  <input type="hidden" id="merchantId" name="merchantId" />
                  <input type="hidden" id="authToken" name="authToken" />
                  <input type="hidden" id="returnUrl" name="returnUrl" />
                </form>
              )}
              {displayPayGatewayOptions ? (
                <>
                  <div className="mt-5 md:mt-0">
                    <div className="">
                      <CustomButton
                        btnText="PAY USING BILL DESK"
                        btnPressed={() => payUsingBillDesk()}
                        fullWidth
                      />
                      <img
                        src={BillDeskLogo}
                        height="40px"
                        width="auto"
                        className="payment-gateway-logo"
                      />
                    </div>
                    <div className="text-center headingName">or</div>
                    <div>
                      <CustomButton
                        btnText="PAY USING CASH FREE"
                        btnPressed={() => getBalAmountUrl()}
                        fullWidth
                      />

                      <img
                        src={CashFreeLogo}
                        height="40px"
                        width="auto"
                        className="payment-gateway-logo"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Flex width={'100%'} pb={3} alignItems="center">
                    <Box className="flex flex-col w-full gap-4 md:flex-row">
                      <Box className="flex flex-col md:w-[60%] mt-6 md:mt-0">
                        <Box className="bg-[#F0F0F0] px-4 py-1 rounded-[10px] mb-2">
                          <PaymentProgress
                            investmentAmountEoi={investmentAmountEoi}
                            from={'fullpayment'}
                            getInitialValues={getInitialValues}
                          />
                        </Box>
                        <Box className="ml-3 md:ml-0">
                          <Text
                            fontSize="12px"
                            className="min-payment-text"
                            mt={'12px'}
                            color={colors.grey200}
                          >
                            Enter the amount for full payment
                          </Text>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              color="#1a295b"
                              fontSize="1.2em"
                              children={<img src={RuppeIcon} />}
                            />
                            <Input
                              placeholder="Enter amount"
                              variant="flushed"
                              w="80%"
                              fontWeight={'bold'}
                              fontSize={'20px'}
                              color={Colors.headerBlue}
                              disabled={false}
                              value={NUM_FORMATTING(values.balanceAmount)}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                const formatNumber = value.replace(/,/g, '');
                                handleChange('balanceAmount')(formatNumber);
                                setEnteredAmount(formatNumber);
                                setFieldTouched('balanceAmount', true);
                              }}
                              onBlur={() => {
                                // setFieldTouched('balanceAmount');
                                setFieldTouched('balanceAmount', true, true);
                              }}
                            />
                            <InputRightElement
                              children={<EditIcon />}
                              mr={['50px']}
                            />
                          </InputGroup>
                          {errors?.balanceAmount && touched?.balanceAmount && (
                            <Text fontSize={'12px'} mt={1} color="red">
                              {' '}
                              {errors.balanceAmount.toString()}
                            </Text>
                          )}
                          <Text
                            fontSize="13px"
                            className="min-payment-text"
                            mt={3}
                          >
                            {/* {`Kindly sign the EOI document and pay minimum ${minPercentageToPaid}% Token amount to move to next step`} */}
                            Amount to be in the multiples of 1,000’s
                          </Text>
                        </Box>
                      </Box>
                      <Box className="md:w-[40%] mt-5 md:mt-0">
                        <PaymentHistory investmentDetails={investmentModal} />
                      </Box>
                    </Box>
                  </Flex>
                  <Flex mt={12} flexDirection="column">
                    {fullPayPaymentLoader ? (
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                      />
                    ) : (
                      <Flex>
                        <CustomButton
                          btnText={`PAY ${NUM_FORMATTING(
                            values.balanceAmount,
                          )}`}
                          btnPressed={checkPaymentOptions}
                        />
                      </Flex>
                    )}

                    <Text fontSize="12px" color={colors.grey} mt={3}>
                      You can update/ change your KYC details before
                      Registration
                    </Text>
                  </Flex>
                </>
              )}
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FullPay1;
