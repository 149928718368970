import {
  Modal,
  Flex,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Button,
} from '@chakra-ui/react';
import Iframe from 'react-iframe';
import React, { FC } from 'react';
import { Colors, Environment } from '@hBits.Core';
import { Strings } from '@hBits.Core';

interface AbandonedInvestmemntProps {
  errorMsg: string;
  isOpen: any;
  onClose?: any;
}
const AbandonedInvestmemnt: FC<AbandonedInvestmemntProps> = (props) => {
  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );
  const { errorMsg, isOpen, onClose } = props;
  const finalRef = React.useRef(null);
  const redirectToPropertyPage = () => {
    window.location.assign(landingPagesHostingRoot + '/properties');
  };
  return (
    <div>
      <Modal
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={'xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Strings.abandonedInvestmemnt}</ModalHeader>
          <ModalBody>
            <Flex
              justify={'center'}
              alignItems="center"
              flexDirection={'column'}
            >
              <Text
                fontSize={'16px'}
                mt="20px"
                color={Colors.headerBlue}
                as="b"
              >
                {errorMsg}
              </Text>
            </Flex>
            <Flex justifyContent={'center'}>
              <Button
                colorScheme="teal"
                size="lg"
                mt={'40px'}
                mb={'20px'}
                onClick={() => {
                  redirectToPropertyPage();
                }}
              >
                Explore More Properties
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AbandonedInvestmemnt;
