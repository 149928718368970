import { request } from './NetworkHandler';
import { HTTPStatusCode } from '../enums';
import { Environment } from './EnvironmentHelper';
import axios from 'axios';
import accountManager from '../auth/account-manager';
import { authTokenStorageProvider } from '../auth';
import * as Sentry from '@sentry/react';

export interface ReturnValueType {
  status: number;
  data: any;
  headers: any;
  error: ErrorObjectType;
  errorData?: any;
}

interface ErrorObjectType {
  method?: any;
  url?: string;
  status?: string;
  errorCode?: string;
  errorMessage?: string;
  additionalDetails?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const fetchAsync = async (req: any) => {
  const returnValue: ReturnValueType = {
    status: 0,
    data: {},
    headers: '',
    error: {},
  };
  let response;
  // eslint-disable-next-line prefer-const
  let envBaseUrl = Environment.getInstance().getParam(
    'NEXT_PUBLIC_API_BASE_URL',
  );

  const authTokenModel = authTokenStorageProvider.getAuthTokenModel();

  const envBaseUrlLength = envBaseUrl.length;

  if (
    envBaseUrl &&
    envBaseUrlLength &&
    envBaseUrl[envBaseUrlLength - 1] === '/'
  ) {
    envBaseUrl = envBaseUrl.slice(0, envBaseUrlLength - 1);
  }

  const requestUrl = envBaseUrl + req.url;

  response = await request({
    method: req.method,
    url: requestUrl,
    params: req.params,
    data: req.data,
    headers: req?.headers,
  });
  if (
    [
      HTTPStatusCode.OK,
      HTTPStatusCode.CREATED,
      HTTPStatusCode.ACCEPTED,
      HTTPStatusCode.NO_CONTENT,
    ].includes(response?.status)
  ) {
    returnValue.data = response?.data;
    returnValue.status = response?.status;
    returnValue.headers = response?.headers;
    return returnValue;
  }
  if (
    typeof window !== 'undefined' &&
    authTokenModel &&
    response?.status === HTTPStatusCode.UNAUTHORIZED
  ) {
    if (!req.ignoreUnauthorized) {
      accountManager.setLogoutFlag('TRUE');
    }
  }

  const errorObject: ErrorObjectType = {
    method: response?.config?.method,
    url: response?.config?.url,
    status: response?.status,
    errorCode: response?.data?.message,
    errorMessage: response?.data?.errorMessage,
    additionalDetails: response?.data?.additionalDetails,
  };

  returnValue.status = response?.status;
  returnValue.data = {};
  returnValue.errorData = response?.data;
  returnValue.error = errorObject;
  return returnValue;
};

export const BlogAxios = axios.create({
  baseURL: `${process.env['NEXT_PUBLIC_API_BLOG_URL']}`,
});

BlogAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    Sentry.captureException(new Error(error));
    return Promise.reject(error);
  },
);

export const Axios = axios.create({
  baseURL: `${process.env['NEXT_PUBLIC_API_BASE_URL']}`,
});

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    Sentry.captureException(new Error(error));
    return Promise.reject(error);
  },
);
