interface EventEmitterProps {
  readonly events: Record<string, (() => void)[]>;
  dispatch(eventType: string): void;
  subscribe(eventType: string, callback: () => void): void;
  unsubscribe(eventType: string): void;
  getEventKey(eventType: string): string;
}
export const eventEmitter: EventEmitterProps = {
  events: {},
  getEventKey(eventType: string) {
    return `${eventType}`;
  },
  dispatch(event) {
    const eventName = this.getEventKey(event);
    if (!this.events[eventName]) return;
    this.events[eventName].forEach((callback: () => void) => callback());
  },
  subscribe(event, callback) {
    const eventName = this.getEventKey(event);
    if (!this.events[eventName]) this.events[eventName] = [];
    if (!this.events[eventName]?.includes(this.events[eventName][0]))
      this.events[eventName]?.push(callback);
  },
  unsubscribe(event) {
    const eventName = this.getEventKey(event);
    if (!this.events[eventName]) return;
    delete this.events[eventName];
  },
};
