// import { Provider, HTTPMethod } from '@/Enums'
import { fetchAsync } from '../../NetworkHelper';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPMethod, HTTPStatusCode } from 'libs/src/enums';
import { authTokenStorageProvider } from 'libs/src/auth';
import { setAccessToken } from '../../AuthHelper';

type userData = {
  google_recaptcha_token?: string | null;
};

export const initializeSignup = async (userData: userData) => {
  try {
    const headerToken = userData.google_recaptcha_token || null;
    delete userData.google_recaptcha_token;

    const request = {
      method: 'post',
      data: userData,
      url: baseApiUrl + endpoints.signupStart,
      headers: headerToken
        ? { 'hb-captcha-token': headerToken, 'recaptcha-action': 'Signup' }
        : '',
    };

    const response = await fetchAsync(request);
    return response;
  } catch (_error) {
    return null;
  }
};

export const verifySignupOTP = async (userData: object) => {
  //invitationCode to be added
  try {
    const request = {
      method: 'post',
      data: userData,
      url: baseApiUrl + endpoints.signupVerify,
    };

    const response = await fetchAsync(request);

    // Code to set Access token
    if (
      response.status === HTTPStatusCode.OK &&
      response?.data &&
      (response?.data as any)?.accessToken
    ) {
      authTokenStorageProvider.storeRawAuthToken(response?.data?.accessToken);
      setAccessToken(response?.data?.accessToken);
    }
    return response;
  } catch (_error) {
    return null;
  }
};

export const resendSignupOTP = async (
  channel: string,
  registrationData: object,
) => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      data: registrationData,
      url: baseApiUrl + endpoints.resendSignupOTP,
    };

    const response = await fetchAsync(request);
    return response;
  } catch (_error) {
    return null;
  }
};
