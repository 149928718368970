export default class AuthTokenModel {
  firstName: string;

  lastName: string;

  accountId: string;

  userTypes: string[];

  opsRole: string | undefined;

  sessionId: string | undefined;

  source!: string | undefined;

  lmsRole!: string | undefined;

  constructor(
    firstName: string,
    lastName: string,
    accountId: string,
    userTypes: string[],
    opsRole: string | undefined,
    source: string,
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.accountId = accountId;
    this.userTypes = userTypes;
    this.opsRole = opsRole;
    this.source = source;
  }
}
