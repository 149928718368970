import { BlogAxios } from './../../NetworkHelper';
import endpoints from 'libs/src/config/endpoints';

export const fetchAllBlogs = async (
  pageNumber = 1,
  size = 100,
): Promise<any> => {
  try {
    let data = await BlogAxios.get(
      `/api${endpoints.blogs}&pagination[page]=${pageNumber}&pagination[pageSize]=${size}&sort=publishedAt:DESC`,
    );
    return data;
  } catch (error) {
    return [];
  }
};
