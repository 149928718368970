import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  IconButton,
  Image,
  Text,
  Wrap,
  WrapItem,
  useDisclosure,
} from '@chakra-ui/react';
import { Colors } from '@hBits.Core';
import { useRef, useState } from 'react';
import CollateralDeleteModal from './CollateralDeleteModal';

const CollateralList = (props: any) => {
  const {
    setStep,
    collatralList,
    downloadImage,
    deleteCollateralImage,
    reset,
    setCompanyLogoUrl,
  } = props;
  const dialogCancelRef = useRef<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteColtlId, setdeleteColtlId] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      bg={Colors.white}
      flex={1}
      mt={5}
      py={5}
      px={5}
      borderRadius={10}
      flexDirection={'column'}
    >
      <Flex w={'100%'} justifyContent={'center'}>
        <Text fontSize={'lg'} color={Colors.blackColor}>
          Generated Collaterals
        </Text>
      </Flex>
      <Wrap>
        {collatralList.map((collateral: any) => (
          <WrapItem
            key={collateral.id}
            w={['100%', '32%']}
            justifyContent={['center', 'flex-start']}
          >
            <Flex mx={1} my={4} direction={'column'} position={'relative'}>
              {/* <a href={collateral?.whatsappPreview} target="_blank"> */}
              <Image
                src={collateral?.whatsappPreview}
                w={'auto'}
                onClick={() => {
                  downloadImage(collateral?.whatsappPreview);
                }}
                cursor={'pointer'}
                alt=""
              />
              {/* </a> */}
              <IconButton
                aria-label="delete collateral"
                position={'absolute'}
                right={2}
                top={2}
                background={'#eeeeee80'}
                icon={
                  <Image
                    onClick={() => {
                      setIsModalOpen(true);
                      setdeleteColtlId(collateral?.id);
                    }}
                    src="../../../../../../assets/my_team/delete_icon.svg"
                    alt=""
                  />
                }
              />
            </Flex>
          </WrapItem>
        ))}
      </Wrap>
      <Flex width={'100%'} alignItems="center" justifyContent={'center'}>
        <Button
          variant={'outline'}
          borderColor={'black'}
          px={10}
          color={'black'}
          py={7}
          fontSize={'16px'}
          w={'199px'}
          h={'60px'}
          maxW={'199px'}
          maxH={'60px'}
          fontWeight={'bold'}
          _hover={{
            backgroundColor: 'white',
          }}
          onClick={() => {
            setStep(2);
            reset();
            setCompanyLogoUrl('');
          }}
        >
          Generate Another
        </Button>
      </Flex>
      {isModalOpen && (
        <CollateralDeleteModal
          id={deleteColtlId}
          deleteCollateralImage={deleteCollateralImage}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </Flex>
  );
};

export default CollateralList;
