import styles from '../index.module.scss';

interface KycVideoSnackBarProps {
  setKycVideoModalState: any;
}

const KycVideoSnackBar = (props: KycVideoSnackBarProps) => {
  const { setKycVideoModalState } = props;
  return (
    <>
      <div className={styles.snackWrapper}>
        <div className={styles.snackContentWrapper}>
          <div className={styles.snackContent}>
            <span>KYC PROCESS</span>
            <button
              className={styles.snackButton}
              onClick={() => setKycVideoModalState(true)}
            >
              VIEW
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KycVideoSnackBar;
