export const baseApiUrl = '';
export const strapiBaseApiUrl = 'https://hbits-blogs-uat.cawstudios.com/api';
const endpoints = {
  signupStart: '/accounts/signup-attempt/start',
  signupVerify: '/accounts/signup-attempt/verify',
  resendSignupOTP: '/accounts/signup-attempt/resend',
  sendLoginOTP: '/accounts/login/sendOtp',
  testLogin: '/accounts/login/sendOtp?testLogin=true',
  verifyLoginOTP: '/accounts/login/verifyOtp',
  resendLoginOTP: '/accounts/login/resend',
  opsTeam: '/accounts/ops-team',
  accounts: '/accounts/',
  linkedAccounts: '/linked-accounts',
  countriesList: '/domain/countries/',
  states: '/states',
  channelPartnerEnrollment: '/channel-partner/enrollment',
  customer: '/customers',
  customers: '/customers/',
  holders: '/holders',
  eventLogs: '/eventLogs',
  properties: '/properties',
  blogs: '/blogs?populate=*',
  testimonials: '/testimonials',
  listOfProperties: '/properties?includeDrafts=true&includeSuspended=true',
  listOfPropertiesWithoutDrafts:
    '/properties?includeDrafts=false&includeSuspended=false',
  property: '/properties/',
  locationQuery: '/properties/locations/?query=',
  getLocation: '/properties/locations/get/',
  tenant: '/tenant',
  editPropertyLease: '/properties/lease/',
  editTenantDetails: '/properties/tenant/',
  getListOfAllLeases: '/properties/leases',
  dms: '/dms/files',
  media: '/media?populate=*',
  careers: '/careers?populate=*',
  kycStatus: '/kyc/status',
  getHolders: '/holders',
  bankData: '/domain/bank',
  updateBasicDetails: '/basicDetails',
  guardianDetails: '/guardianDetails',
  otherKycDetails: '/otherKycDetails',
  addressDetails: '/addressDetails',
  bankDetails: '/bankDetails',
  nomineeDetails: '/nomineeDetails',
  gstDetails: '/otherKycDetails',
  leadOwner: '/lead-owner',
  status: '/status',
  enquiries: '/lms/enquiry/get-all-enquiries/',
  exportEnquiries: '/lms/enquiry/export/',
  kycAccountType: '/kyc/accountType',
  uploadCsv: '/csv',
  kycVerify: '/verify',
  cpKycVerify: '/kyc/verify',
  logOff: '/accounts/logoff',
  investmentSnakBar:
    '/checkInvestment/forAccount/#{accountId}/forProperty/#{propertyId}/investmentStatus',
  createInvestment:
    '/checkInvestment/forAccount/#{accountId}/forProperty/#{propertyId}',
  updateInvestmentAmount: '/investments/#{investmentId}/primary/amount',
  updateMinTokenAmount: '/investments/#{investmentId}/primary/eoi-amount',
  updateEoiSignedDoc:
    '/investments/#{investmentId}/primary/eoi-signed-document',
  updateTopUpEoiSignedDoc:
    '/investments/#{investmentId}/topUp/eoi-signed-document',
  updateEoiTransactionReceipt:
    '/investments/#{investmentId}/primary/eoi-transaction',
  updateTopUpEoiTransactionReceipt:
    '/investments/#{investmentId}/topUp/eoi-transaction',
  updateEOITopUpDocumentUrl:
    '/investments/#{investmentId}/#{stage}/eoi/#{eoiID}/transaction-url',
  eoiDocumentUrl:
    '/investments/#{investmentId}/primary/eoi-document/get-embedded-url',
  eoiDocumentUrlSucess:
    '/investments/#{investmentId}/primary/eoi-document/check-status',
  investmentPayment:
    '/investments/#{investmentId}/primary/eoi-amount/get-payment-url',
  billdeskPayment:
    '/investments/#{investmentId}/primary/eoi-amount/initiate-payment',
  addTopUpAmount: '/investments/#{investmentId}/topup/amount',
  addTopUp: '/investments/#{investmentId}/topup',
  addMinTopUpTokenAmount: '/investments/#{investmentId}/topup/eoi-amount',
  eoiTopUpDocumentUrl:
    '/investments/#{investmentId}/topup/eoi-document/get-embedded-url',
  eoiTopUpDocumentUrlSucess:
    '/investments/#{investmentId}/topup/eoi-document/check-status',
  topUpPaymentUrl:
    '/investments/#{investmentId}/topup/eoi-amount/get-payment-url',
  investments: '/investments',
  referral: '/referral',
  addReferral: '/referral/add',
  investmentStats: '/investments/forAccount/#{accountId}/stats',
  investmentProperties: '/investments/forAccount/#{accountId}/stats/properties',
  overview: '/overview/dashboard',
  channelPartners: '/channel-partners',
  channelPartner: '/channel-partner',
  cpCustomer: '/customer',
  members: '/members',
  addMember: '/addMember',
  children: '/children',
  pas4EoiDocumentUrl:
    '/investments/#{investmentId}/pas4/pas4-document/get-embedded-url',
  pas4EoiDocumentStatus:
    '/investments/#{investmentId}/pas4/pas4-document/check-status',
  balanceAmountUrl:
    '/investments/#{investmentId}/fullPay/balance-amount/get-payment-url',
  balanceAmountBilldeskUrl: `/investments/#{investmentId}/fullPay/balance-amount/initiate-payment`,
  childern: '/children',
  getZipUploadLogs: '/earnings/zip/logs',
  getZipUploadLogsByZipId: '/earnings/zip/logs/',
  uploadZipFile: '/earnings/documents/bulk',
  customerTaxation: '/customerTaxation',
  investmentsForAccountId: '/investments?forAccountId=',
  investmentsForPropertyId: '/investments?forPropertyId=',
  investmentDocuments:
    '/user/documents/forAccount/#{accountId}/forProperty/#{propertyId}',
  userEarnings:
    '/properties/#{propertyId}/rent/forAccount/#{accountId}/earnings?fromMonth=#{fromMonth}&toMonth=#{toMonth}&fromYear=#{fromYear}&toYear=#{toYear}',
  uploadPAS4Document: '/investments/#{investmentId}/pas4/signed-document',
  uploadBalanceAmountUrl: '/investments/#{investmentId}/fullPay/amount',
  updateBalanceAmountUrl:
    '/investments/#{investmentId}/fullPay/transaction-url',
  rentForecast: '/rent/forecast',
  rentEarnings: '/rent/earnings',
  upload: '/upload',
  propertyEarnings:
    '/properties/#{propertyId}/rent/earnings?month=#{month}&year=#{year}',
  applyPropertyEarnings:
    '/properties/#{propertyId}/rent/earnings/apply?month=#{month}&year=#{year}',
  investmentData: '/investments/data/?propertyId=',
  earnings: '/earnings/forAccount/#{accountId}/forProperty/#{propertyId}',
  deleteEarnings: '/earnings/document/delete',
  uploadEarnings: '/earnings/document/upload',
  generateReceipt: '/transactions/generate-receipt',
  generatePreSignedUrl: '/dms/generate/preSignedUrl',
  uploadOwnershipDoc: '/dms/add',
  investmentDetails: '/investments/',
  emailStart: 'email/start',
  emailVerify: 'email/verify',
  emailResend: 'email/resend',
  unlockPAS4: '/investments/unlock-pas4',
  cpLinkage: '/customers/channel-partner/linkage?search=',
  linkCPAccount: '/channel-partner/link/account/',
  cpClients: '/channel-partners/customers',
  deleteCPClient: '/channel-partners/customer',
  transferCPMember: '/channel-partners/members/',
  receiptDelete: '/investments/receipt/delete',
  customerBankDetails: '/customers/#{accountId}/bankAccounts/all/verified',
  deleteTopUp: '/investments/#{investmentId}/topup/delete',
  managers: '/lms/managers/',
  transfer: '/investments/transfer',
  uploadZipFileCCDEquity: '/ccdAndEquityShare/documents/bulkUpload',
  zipUploadCCDEquityLogs: '/ccdAndEquityShare/zip/logs',
  transferOwnershipDocument: '/investments/transfer/document/upload/',
  tdsLogs: '/customers/#{accountId}/customerTaxation/logs',
  fetchUnregisteredUsers: '/accounts/unregistered-customers',
  registerUser: '/accounts/unregistered-customers/register',
  deals: '/deals',
  payoutsUpload: '/channel-partner/earnings/payouts/upload',
  getpayouts: '/channel-partner/earnings/payouts',
  invoice: '/invoice',
  accountStatement:
    '/channel-partners/customer/replaceAccountId/accountStatement',
  listOfspv: '/properties/',
  spvDetails: '/spvDetails',
  createEmailTemplate: '/email/template',
  segment: '/segment/',
  users: '/users',
  campaign: '/campaign/',
  evaluate: '/evaluate',
  whatsapp: '/whatsapp/template',
  crm: '/crm',
  lead: '/lead',
  nonEnpanelledCp: '/channelPartners',
  segmentFile: '/segment/upload',
  ekyc: 'ekyc',
  updateDematDetails: '/update-demat-details',
};

export default endpoints;
