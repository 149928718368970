/* tslint:disable */
import React, { FunctionComponent } from 'react';
import './RegistrationModal.module.scss';
import { RegistrationProps } from 'libs/src/hooks/useAuth';
import RegistrationBox from './registration';
export interface RegistrationModalProps {
  registrationData: RegistrationProps | null;
  onModalClose: () => void;
  onRegisterClick: (userData: RegistrationProps) => void;
  onLoginClick: () => void;
  onTnCClick: () => void;
  linkedFlag: boolean;
  ModalHeadingText?: string;
  origin?: string;
}

export interface IFormInput {
  firstname: string;
  lastname: string;
  emailAddress: string;
  mobileCountryCode: string;
  mobileNumber: string;
  additionalDetails: any;
}

const RegistrationModal: FunctionComponent<RegistrationModalProps> = ({
  registrationData,
  onModalClose,
  onRegisterClick,
  onLoginClick,
  onTnCClick,
  linkedFlag,
  ModalHeadingText,
  origin
}): any => {
  return (
    <div>
      <RegistrationBox
        registrationData={registrationData}
        onModalClose={onModalClose}
        onRegisterClick={onRegisterClick}
        onLoginClick={onLoginClick}
        onTnCClick={onTnCClick}
        linkedFlag={linkedFlag}
        ModalHeadingText={ModalHeadingText}
        origin={origin}
      />
    </div>
  );
};
export default RegistrationModal;
