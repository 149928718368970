import endpoints from 'libs/src/config/endpoints';

import { HTTPMethod, HTTPStatusCode } from 'libs/src/enums';
import { fetchAsync } from '@hBits.Core';

export const getBalanceAmountUrl = async (
  investmntId: string,
  // amount:number,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.balanceAmountUrl.replace('#{investmentId}', investmntId),
      // data: JSON.stringify({ amount: amount }),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getBalanceAmountUrl1 = async (
  investmntId: string,
  amount: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.balanceAmountUrl.replace('#{investmentId}', investmntId),
      data: { amount: amount },
    };
    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }

    return [];
  } catch (error) {
    return [];
  }
};

export const payBalanceAmountUsingBilldesk = async (
  investmntId: string,
  amount: string,
): Promise<any> => {
  try {
    console.log('from serviucw');
    const request = {
      method: HTTPMethod.PUT,
      url: endpoints.balanceAmountBilldeskUrl.replace(
        '#{investmentId}',
        investmntId,
      ),
      data: {
        amount: parseInt(amount),
      },
      headers: {
        'Content-Type': 'application/json', // Set appropriate content type
      },
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};
