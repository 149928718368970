import React, { useEffect, useState } from 'react';
import { fetchAllCountries } from '../../../../../../libs/src/services/modules/channel-partner';
import { Select } from '@chakra-ui/react';
import { Colors, Strings } from '@hBits.Core';
import colors from 'libs/src/theme/colors';

type CountryType = {
  id: string;
  name: string;
  iso: string;
  phoneCode: string;
  currency: string;
};

interface SelectCountryProps {
  selectedCountry: string | undefined;
  setSelectedCountry: (country: string) => void;
  isColorGrey?: any;
  isKyc?: boolean;
  isDisable?: boolean;
  testId?: string;
}

/**
 * @param {Object} props
 * @returns {JSX.Element} - A React Functional Component
 */
const SelectCountryDropdown: React.FC<SelectCountryProps> = ({
  selectedCountry,
  setSelectedCountry,
  isColorGrey = false,
  isKyc = false,
  isDisable = false,
  testId,
}) => {
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [placeholderColor, setPlaceholderColor] = useState(Colors.blackColor);

  const toCapitalize = (country: string) => {
    if (!country) return '';
    return country.charAt(0).toUpperCase() + country.slice(1).toLowerCase();
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(event.target.value);
  };

  useEffect(() => {
    fetchAllCountries().then((data) => {
      setCountries(data);
    });
  }, []);

  useEffect(() => {
    if (isColorGrey) {
      if(isKyc)
      setPlaceholderColor(colors.kycPlaceholder)
      else
      setPlaceholderColor(Colors.greyPlaceholder);
    } else {
      setPlaceholderColor(Colors.blackColor);
    }
  }, [isColorGrey]);

  return (
    <Select
      data-test={testId}
      width={'100%'}
      height={'40px'}
      fontSize={'16px'}
      borderRadius={0}
      borderColor={isKyc?colors.buttonBorderGrey:Colors.greyBorderColor}
      value={selectedCountry}
      onChange={handleChange}
      color={selectedCountry ? Colors.blackColor : placeholderColor}
      placeholder={`*${Strings.select} ${Strings.country}`}
      variant="flushed"
      disabled={isDisable}
      _disabled={{color: 'black'}}
    >
      {countries.map((country) => (
        <option
          key={country.id}
          value={country.id}
          style={{ color: colors.blackColor }}
        >
          {toCapitalize(country.name)}
        </option>
      ))}
    </Select>
  );
};

export default SelectCountryDropdown;
