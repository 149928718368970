import { HTTPMethod, fetchAsync, HTTPStatusCode } from '@hBits.Core';
import endpoints, { baseApiUrl } from '@hBits.Core/endpoints';

export const fetchBankData = () => {
  const req = {
    method: HTTPMethod.GET,
    data: {},
    url: baseApiUrl + endpoints.bankData,
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};

export const addBankDetails = async (
  accountId: any,
  holderId: any,
  basicDetailsPayload: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.POST,
      data: basicDetailsPayload,
      url:
        baseApiUrl +
        endpoints.customers +
        accountId +
        endpoints.getHolders +
        '/' +
        holderId +
        endpoints.bankDetails,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return response.error;
  } catch (error) {
    return [];
  }
};
export const updateBankDetails = async (
  accountId: any,
  holderId: any,
  basicDetailsPayload: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      data: basicDetailsPayload,
      url:
        baseApiUrl +
        endpoints.customers +
        accountId +
        endpoints.getHolders +
        '/' +
        holderId +
        endpoints.bankDetails,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return response.error;
  } catch (error) {
    return [];
  }
};
