import { Flex, Image, Text } from '@chakra-ui/react';

interface StatusTextFileProps {
  text?: string;
  image?: any;
  isVerified?: boolean;
}

const StatusTextFile = (props: StatusTextFileProps) => {
  const { image, text, isVerified } = props;
  return (
    <Flex w={'100%'} justifyContent={'center'} direction={'column'}>
      <Flex justifyContent={'center'}>
        <Image
          src={image}
          w={{ base: '25px', md: '50px' }}
          h={{ base: '25px', md: '50px' }}
          alt=""
        ></Image>
        <Text
          alignSelf={'center'}
          ml={2}
          fontWeight="bold"
          fontSize={{ base: '14px', md: '20px' }}
        >
          {text}
        </Text>
      </Flex>
      {isVerified && (
        <Flex justifyContent={'center'} mt={4}>
          <Text fontSize={{ base: '8px', md: '16px' }} fontWeight={'bold'}>
            For any further changes in the KYC, a mail should be sent to
            operations@hbits.co
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default StatusTextFile;
