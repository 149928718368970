import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { Colors } from '@hBits.Core';
import { useRef } from 'react';

const CollateralDeleteModal = (props: any) => {
  const { id, deleteCollateralImage, onClose } = props;
  const dialogCancelRef = useRef<any>();

  return (
    <AlertDialog
      isOpen={true}
      onClose={onClose}
      leastDestructiveRef={dialogCancelRef}
      isCentered
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay background={'blackAlpha.400'}>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Collateral
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete this collateral?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={dialogCancelRef}
              onClick={onClose}
              _hover={{
                backgroundColor: 'white',
              }}
              variant={'outline'}
              borderColor={Colors.blackColor}
            >
              Cancel
            </Button>
            <Button
              bg={'#FFB657'}
              onClick={() => {
                onClose();
                deleteCollateralImage(id);
              }}
              ml={3}
              _hover={{
                backgroundColor: '#FFB657',
              }}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CollateralDeleteModal;
