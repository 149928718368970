import * as React from 'react';
import { Divider, Flex, Text } from '@chakra-ui/react';
import styles from '../styles/index.module.scss';
import { Colors } from '@hBits.Core';
import DeleteTeamMeberButton from './DeleteTeamMember';
import mailIcon from '../../../../assets/my_team/mail-icon.svg';
import callIcon from '../../../../assets/my_team/phone-call.svg';
import {
  MAX_EMAIL_DISPLAY_LENGTH,
  MAX_NAME_DISPLAY_LENGTH,
} from 'libs/src/constants';
import { NUM_DIFFERENTIATION } from 'apps/landing-pages-web/src/components/common/utils/helper';

interface MembersDetailsInt {
  name: string;
  post: string | undefined;
  mobileCountryCode: string;
  mobileNumber: string;
  emailAddress: string;
  investmentValue: number;
  numberOfClients: number;
  membersAccountId: string;
}

const MemberCard = ({
  name,
  post,
  mobileCountryCode,
  mobileNumber,
  emailAddress,
  investmentValue,
  numberOfClients,
  membersAccountId,
}: MembersDetailsInt) => {
  return (
    <Flex className={styles['members_card']} gap={'1rem'} width={'100%'}>
      <Flex direction={'column'} flex={1}>
        <Flex justify={'space-between'}>
          <h3 className={styles['team_member_name']}>
            {name.slice(0, MAX_NAME_DISPLAY_LENGTH)}
          </h3>
          <DeleteTeamMeberButton
            name={name}
            email={emailAddress}
            membersAccountId={membersAccountId}
          />
        </Flex>
        <Text color={Colors.textGreyColor}>{post}</Text>
        <Divider borderColor={Colors.grey200} className="mt-2" />
      </Flex>
      <Flex
        gap={'0.5rem'}
        direction={'column'}
        fontSize={'lg'}
        fontWeight={'normal'}
        flex={1}
      >
        <Flex gap={'1rem'} align={'center'}>
          <img src={callIcon} />
          <Text color={Colors.textGreyColor}>
            {mobileCountryCode} {mobileNumber}
          </Text>
        </Flex>
        <Flex gap={'1rem'} align={'flex-start'} height={'61px'}>
          <img src={mailIcon} style={{ marginTop: '8px' }} />
          <Text color={Colors.textGreyColor} wordBreak={'break-all'}>
            {emailAddress.slice(0, MAX_EMAIL_DISPLAY_LENGTH)}
          </Text>
        </Flex>
      </Flex>
      <Flex flex={1} justify={'space-between'}>
        <Flex gap={'0.1rem'} direction={'column'}>
          <Text
            color={Colors.textGreyColor}
            fontSize={'sm'}
            fontWeight={'normal'}
          >
            Investment Value
          </Text>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            ₹{NUM_DIFFERENTIATION(investmentValue, false)}
          </Text>
        </Flex>
        <Flex gap={'0.1rem'} direction={'column'} align={'center'}>
          <Text
            color={Colors.textGreyColor}
            fontSize={'sm'}
            fontWeight={'normal'}
          >
            No. of clients
          </Text>
          <Text
            color={Colors.headerBlue}
            fontSize={'2xl'}
            fontWeight={'bold'}
            textDecoration={'underline'}
          >
            {numberOfClients}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MemberCard;
