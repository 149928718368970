import React from 'react';
import { FC } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import colors from '../../theme/colors';

interface CustomButtonProps {
  btnPressed?: () => void;
  btnText?: string | number;
  loading?: boolean;
  btnBlockStyle?: any;
  btnColor?: string;
  size?: string;
  borderRadius?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  fontWeight?: string;
  isDisable?: boolean;
  fontSize?: string;
  testId?: string;
  fullWidth?: boolean;
  width?: string | number;
}
const CustomButton: FC<CustomButtonProps> = (props) => {
  const {
    btnPressed,
    btnText,
    btnColor = colors.buttonColor,
    size,
    borderRadius = '5px',
    pt = '10px',
    pb = '10px',
    pl = '30px',
    pr = '30px',
    fontSize = '18px',
    fontWeight = 'bold',
    isDisable = false,
    testId,
    fullWidth = false,
    width = 'auto',
  } = props;
  return (
    <Flex direction="row">
      <button
        data-test={testId}
        style={{
          backgroundColor: btnColor,
          borderRadius: borderRadius,
          paddingTop: pt,
          paddingBottom: pb,
          paddingLeft: pl,
          paddingRight: pr,
          fontWeight: fontWeight,
          fontSize: fontSize,
          width: fullWidth ? '100%' : width,
        }}
        type="submit"
        onClick={btnPressed}
        disabled={isDisable}
      >
        {btnText}
      </button>
    </Flex>
  );
};

export default CustomButton;
