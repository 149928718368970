enum ReferralStatus {
  Invited = 'Invited',
  Registered = 'Registered',
  KycInProgress = 'KycInProgress',
  KycSubmitted = 'KycSubmitted',
  KycUpdated = 'KycUpdated',
  KycVerificationInProgress = 'KycVerificationInProgress',
  KycVerified = 'KycVerified',
  KycInvalidated = 'KycInvalidated',
}

export default ReferralStatus;
