import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Input,
  Link,
  Select,
  Text,
} from '@chakra-ui/react';
import { Colors } from '@hBits.Core';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import '../../styles/Collateral.scss';
import TncModal from 'libs/src/components/TncModal';
import PrivacyModal from 'libs/src/components/PrivacyModal';
import { useEffect, useState } from 'react';
import { getListOfProperties } from 'apps/customer-portal-web/src/services/dashboard/UpcomingProperties';

const CollateralForm = (props: any) => {
  const {
    handleSubmit,
    companyLogoUrl,
    setCompanyLogoUrl,
    handleOnSubmit,
    SettingLogoUrl,
    register,
    errors,
    control,
    setValue,
    setIsPreview,
    baseImage,
    setBaseImage,
    setStep,
    showBack,
  } = props;
  const isNumber = (number: any) => !isNaN(number) || 'Must be a number';
  const [isOpenTncModal, setIsOpenTncModal] = useState<boolean>(false);
  const [isOpenPrivacyModal, setIsOpenPrivacyModal] = useState<boolean>(false);
  const [propertyList, setPropertyList] = useState<any>();
  const [propertyImages, setPropertyImages] = useState<any>();
  const [selectedImage, setSelectedImage] = useState(null);

  const getPropertiesListsFromApi = async () => {
    const listOfProperties = await getListOfProperties();
    setPropertyList(listOfProperties);
  };

  const setPropertyAsBaseImage = (value: any) => {
    if (value) {
      const propertyObj = JSON.parse(value);
      setPropertyImages(propertyObj?.propertyMediaModel?.mobileBannerUrl);
      const baseImg = propertyObj?.propertyMediaModel?.mobileBannerUrl
        ? propertyObj?.propertyMediaModel?.mobileBannerUrl.length
          ? propertyObj?.propertyMediaModel?.mobileBannerUrl[0]?.imageUrl
            ? propertyObj?.propertyMediaModel?.mobileBannerUrl[0]?.imageUrl
            : propertyObj?.propertyMediaModel?.mobileBannerUrl[0]
          : ''
        : '';
      if (baseImg) {
        setBaseImage(baseImg);
        setSelectedImage(baseImg);
      } else {
        alert('there is no image available for this property');
      }
    }
  };

  const setSelectedImageFunc = (value: any) => {
    setBaseImage(value);
    setSelectedImage(value);
  };

  useEffect(() => {
    getPropertiesListsFromApi();
  }, []);

  return (
    <Flex>
      <form
        className="form-parent"
        onSubmit={handleSubmit((formData: any) => handleOnSubmit(formData))}
      >
        <Flex
          className="form-container"
          direction={'column'}
          py={[3, '3.75rem']}
          pe={[3, '1rem']}
          ps={['1rem']}
          justifyContent={'center'}
        >
          <FormControl isInvalid={Boolean(errors?.property)} my={3}>
            <Flex
              alignItems={'flex-start'}
              justify={'flex-start'}
              width={'100%'}
              direction={'column'}
              ps={[0, '1.60rem']}
              mb={4}
            >
              <Flex
                color={Colors.buttonBorderGrey}
                fontFamily={'Roboto'}
                fontSize={'18px'}
                fontWeight={'normal'}
                letterSpacing={0}
                pe={[0, '1.75rem']}
              >
                *Select Property
              </Flex>
              <Flex flexDirection={'column'} width={'100%'} pe={[0, 12]}>
                <Select
                  name={'property'}
                  id="property"
                  size="md"
                  pb={0}
                  borderColor={Colors.bgDarkBlue}
                  color={Colors.blackColor}
                  fontSize={'medium'}
                  variant="flushed"
                  placeholder="Property Name"
                  {...register('property', {
                    required: 'This is required.',
                  })}
                  onChange={(e) => setPropertyAsBaseImage(e.target.value)}
                >
                  {propertyList?.map((value: any) => (
                    <option
                      key={value.displayName}
                      value={JSON.stringify(value)}
                      id="property"
                    >
                      {value?.displayName}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.property?.message}</FormErrorMessage>
              </Flex>
            </Flex>
            {propertyImages && propertyImages.length && (
              <Flex
                alignItems={'flex-start'}
                justify={'flex-start'}
                width={'100%'}
                direction={'column'}
                ps={[0, '1.60rem']}
                mb={4}
              >
                <Flex
                  color={Colors.buttonBorderGrey}
                  fontFamily={'Roboto'}
                  fontSize={'18px'}
                  fontWeight={'normal'}
                  letterSpacing={0}
                  pe={[0, '1.75rem']}
                >
                  Select Property Image
                </Flex>
                <Flex mt={4} flexWrap={'wrap'} gap={1}>
                  {propertyImages.map((source: any) => (
                    <Image
                      src={source.imageUrl ? source.imageUrl : source}
                      key={source.imageUrl ? source.imageUrl : source}
                      p={2}
                      mr={4}
                      onClick={() =>
                        setSelectedImageFunc(
                          source.imageUrl ? source.imageUrl : source,
                        )
                      }
                      className={`image-thumbnail ${selectedImage ===
                          (source.imageUrl ? source.imageUrl : source)
                          ? 'active'
                          : ''
                        }`}
                    ></Image>
                  ))}
                </Flex>
              </Flex>
            )}
          </FormControl>
          <Flex direction={['column', 'row']} pe={[0, '1.75rem']}>
            <Flex
              w={['100%', '40%']}
              direction={'column'}
              alignItems={['center']}
              me={[0, 50]}
            >
              <Flex
                direction={'column'}
                alignItems={['center']}
                className="image-section-upper"
              >
                <Flex
                  direction={'column'}
                  alignItems={['center']}
                  className="form-info"
                >
                  Upload your company logo
                </Flex>
                {companyLogoUrl ? (
                  <Flex direction={'column'} alignItems={'center'}>
                    <Image
                      w={'70%'}
                      h={'auto'}
                      src={companyLogoUrl}
                      className="logo"
                      alt=""
                    />
                    <Text
                      cursor={'pointer'}
                      textAlign={'center'}
                      w={'auto'}
                      onClick={() => setCompanyLogoUrl('')}
                    >
                      Delete
                    </Text>
                  </Flex>
                ) : (
                  <>
                    <label
                      htmlFor="companyLogoUrl"
                      className="file-upload-button"
                    >
                      <div className="button-info">Choose File</div>
                      <div className="button-warning">
                        (Maximum file size 2 MB)
                      </div>
                    </label>
                    <FormControl
                      isInvalid={Boolean(errors?.companyLogoUrl)}
                      my={3}
                    >
                      <input
                        id="companyLogoUrl"
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={SettingLogoUrl}
                      />
                      <FormErrorMessage>
                        {errors.companyLogoUrl?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}
              </Flex>
              <Flex
                onClick={() => setIsPreview(true)}
                direction={'column'}
                alignItems={['center']}
                cursor={'pointer'}
                justifyContent={'flex-end'}
              >
                Preview
              </Flex>
            </Flex>
            <Flex w={['100%', '50%']} direction={'column'}>
              <div className="form-info">
                Company name and contact numbers that will appear on Collaterals
              </div>

              <FormControl
                isInvalid={Boolean(errors.companyName)}
                my={3}
                mt={8}
              >
                <Input
                  id="companyName"
                  variant="flushed"
                  placeholder="*Company Name"
                  fontSize={'medium'}
                  color={Colors.blackColor}
                  errorBorderColor="#e0e0e0"
                  _placeholder={{ color: Colors.buttonBorderGrey }}
                  {...register('companyName', {
                    required: 'This is required.',
                    minLength: {
                      value: 3,
                      message: 'Minimum characters should be 3',
                    },
                    pattern: {
                      value: /\S/,
                      message: 'Invalid city',
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.companyName?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.personName1)} my={3}>
                <Input
                  id="personName1"
                  variant="flushed"
                  errorBorderColor="#e0e0e0"
                  placeholder="*Full Name (Contact Person 1)"
                  fontSize={'medium'}
                  color={Colors.blackColor}
                  _placeholder={{ color: Colors.buttonBorderGrey }}
                  {...register('personName1', {
                    required: 'This is required.',
                    pattern: {
                      value:
                        /^([a-zA-Z\u0080-\u024F]+(?: |'))*[a-zA-Z\u0080-\u024F]*$/,
                      message: 'Invalid *Full Name (Contact Person 1)',
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.personName1?.message}
                </FormErrorMessage>
              </FormControl>

              <Flex
                flexDirection={'column'}
                my={3}
                className={'collateral-form'}
              >
                <Controller
                  control={control}
                  name="personMobileNumber1"
                  rules={{
                    required: {
                      value: true,
                      message: 'This is required.',
                    },
                    minLength: { value: 7, message: 'Phone Number is Invalid' },
                    validate: isNumber,
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <PhoneInput
                      inputProps={{
                        autoFocus: false,
                        prefix: '+',
                        required: true,
                      }}
                      country="in"
                      placeholder="*Mobile Number (Contact Person 1)"
                      enableSearch
                      isValid
                      onChange={(phone: string, country: any) => {
                        const reducedPhone = phone.replace(
                          country.dialCode,
                          '',
                        );
                        setValue('personMobileNumber1', reducedPhone);
                        setValue(
                          'personMobileCountryCode1',
                          `+${country.dialCode}`,
                        );
                      }}
                      countryCodeEditable={false}
                    />
                  )}
                />
                {errors.personMobileNumber1 && (
                  <div
                    className="chakra-form__error-message css-170ki1a"
                    style={{ color: '#e53e3e' }}
                  >
                    {errors.personMobileNumber1.message}
                  </div>
                )}
              </Flex>

              <FormControl isInvalid={Boolean(errors.personName2)} my={3}>
                <Input
                  id="personName2"
                  variant="flushed"
                  errorBorderColor="#e0e0e0"
                  fontSize={'medium'}
                  placeholder="Full Name (Contact Person 2)"
                  color={Colors.blackColor}
                  _placeholder={{ color: Colors.buttonBorderGrey }}
                  {...register('personName2', {
                    pattern: {
                      value:
                        /^([a-zA-Z\u0080-\u024F]+(?: |'))*[a-zA-Z\u0080-\u024F]*$/,
                      message: 'Invalid Full Name (Contact Person 2)',
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.personName2?.message}
                </FormErrorMessage>
              </FormControl>

              <Flex
                flexDirection={'column'}
                my={3}
                className={'collateral-form'}
              >
                <Controller
                  control={control}
                  name="personMobileNumber2"
                  rules={{
                    minLength: { value: 7, message: 'Phone Number is Invalid' },
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <PhoneInput
                      inputProps={{
                        autoFocus: false,
                        prefix: '+',
                        required: true,
                      }}
                      country="in"
                      placeholder="*Mobile Number (Contact Person 2)"
                      enableSearch
                      isValid
                      onChange={(phone: string, country: any) => {
                        const reducedPhone = phone.replace(
                          country.dialCode,
                          '',
                        );
                        setValue('personMobileNumber2', reducedPhone);
                        setValue(
                          'personMobileCountryCode2',
                          `+${country.dialCode}`,
                        );
                      }}
                      countryCodeEditable={false}
                    />
                  )}
                />
                {errors.personMobileNumber2 && (
                  <div
                    className="chakra-form__error-message css-170ki1a"
                    style={{ color: '#e53e3e' }}
                  >
                    {errors.personMobileNumber2.message}
                  </div>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex gap={4} mx={'20%'} mt={10} justifyContent={'center'}>
            {showBack && (
              <Button
                flex={1}
                variant={'outline'}
                borderColor={'black'}
                px={10}
                color={'black'}
                py={7}
                fontSize={'16px'}
                maxW={'199px'}
                maxH={'60px'}
                w={'199px'}
                h={'60px'}
                fontWeight={'bold'}
                onClick={() => setStep(1)}
                _hover={{
                  backgroundColor: 'white',
                }}
              >
                BACK
              </Button>
            )}
            <Button
              flex={1}
              bg="#FFB657"
              type="submit"
              size={'lg'}
              px={10}
              py={7}
              fontSize={'16px'}
              maxW={'199px'}
              maxH={'60px'}
              w={'199px'}
              h={'60px'}
              fontWeight={'bold'}
              _hover={{
                backgroundColor: '#FFB657',
              }}
              color={Colors.blackColor}
            >
              SUBMIT
            </Button>
          </Flex>
          <Flex direction={'row'} justifyContent={['center']} mt={10}>
            <Text
              color={Colors.textGreyColor}
              fontWeight={'light'}
              fontSize={'16px'}
            >
              By clicking 'Submit' I agree to abide by the{' '}
              <Link
                onClick={() => setIsOpenTncModal(true)}
                color={Colors.headerBlue}
                fontWeight={'bold'}
                style={{ textDecoration: 'underline' }}
              >
                Terms & Conditions
              </Link>{' '}
              and{' '}
              <Link
                onClick={() => setIsOpenPrivacyModal(true)}
                fontWeight={'bold'}
                color={Colors.headerBlue}
                style={{ textDecoration: 'underline' }}
              >
                Privacy Policy
              </Link>
            </Text>
          </Flex>
        </Flex>
      </form>
      {isOpenTncModal && (
        <TncModal onModalClose={() => setIsOpenTncModal(false)} />
      )}
      {isOpenPrivacyModal && (
        <PrivacyModal onModalClose={() => setIsOpenPrivacyModal(false)} />
      )}
    </Flex>
  );
};

export default CollateralForm;
