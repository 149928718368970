import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPMethod, HTTPStatusCode } from 'libs/src/enums';
import { fetchAsync } from '../../NetworkHelper';

export const getKycStatus = async (id: any): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.GET,
      url: baseApiUrl + endpoints.customer + '/' + id + endpoints.kycStatus,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getInvestMentSnakBarStatus = async (
  accountId: string,
  propertyId: string,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.GET,
      url: endpoints.investmentSnakBar
        .replace('#{accountId}', accountId)
        .replace('#{propertyId}', propertyId),
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getChannelPartnerKycStatus = async (id: any) => {
  try {
    const request = {
      method: HTTPMethod.GET,
      url: baseApiUrl + endpoints.channelPartners + '/' + id,
      ignoreUnauthorized: true,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getPropertyPageDetails = async (id: any) => {
  try {
    const request = {
      method: HTTPMethod.GET,
      url: baseApiUrl + endpoints.properties + '/' + id,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};
