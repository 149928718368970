import {
  Flex,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
  Link,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import { Environment } from '@hBits.Core';
import * as React from 'react';

const FooterdataFractionalOwnershipInvestment = () => {
  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );
  return (
    <Flex flexDirection="column" color="#94969f" marginTop={'100px'}>
      <h2>Introduction - Fractional Ownership</h2>
      <Text>
        In the realm of smart investing, fractional ownership shines as an
        attractive option, specially designed to welcome first-time investors.
        Understanding the natural worries that tag along when stepping into the
        financial world, fractional investing steps in as a helpful solution,
        easing concerns about how much money to put in and the level of risk
        involved.
        <br />
        <br />
        This fresh approach lets new investors join in on valuable assets like
        art, real estate, and stocks without needing to invest a huge amount. By
        pooling resources and managing them wisely, this type of{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/properties'}
        >
          real estate investment
        </Link>{' '}
        not only overcomes the usual investment barriers but also creates a
        sense of shared participation. This makes the complex investment
        landscape much easier for newcomers to explore.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Importance of fractional ownership in real estate
      </h2>
      <Text>
        The significance of fractional ownership in the realm of real estate
        investment cannot be overstated. It stands as a visionary approach that
        demystifies the traditional barriers to entry in the property market,
        catering to the discerning needs of astute investors. By providing an
        avenue for investors to collectively share ownership in prime real
        estate, fractional ownership transforms inaccessible real estate
        opportunities into tangible possibilities.
        <br />
        <br />
        This empowerment not only grants investors exposure to diversified
        property portfolios but also mitigates the substantial financial burden
        that often accompanies sole ownership. Moreover, fractional ownership in
        real estate ensures meticulous property management, further safeguarding
        investor interests. In essence, this innovative concept fuses collective
        wisdom with tangible asset ownership, epitomizing a transformative
        chapter in the annals of real estate investment.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Benefits of fractional ownership in real estate
      </h2>
      <Text>
        The advantages of fractional ownership in real estate are indeed
        profound, ushering in a new era of investment opportunities tailored to
        meet the aspirations of discerning investors. This innovative approach
        offers:
      </Text>
      <OrderedList>
        <ListItem>
          <strong>Diversified Portfolios:</strong> Fractional ownership allows
          investors to participate in a range of high-value properties,
          minimizing risk by spreading investments across different assets.
        </ListItem>
        <ListItem>
          <strong>Accessible Entry:</strong> By significantly lowering the
          financial threshold, fractional ownership in real estate makes premium
          real estate accessible to a broader spectrum of investors, removing
          traditional entry barriers.
        </ListItem>
      </OrderedList>
      <h2 style={{ marginTop: '20px' }}>
        Types of fractional property ownership
      </h2>
      <Text>
        The realm of fractional property ownership unfolds in diverse forms,
        each tailored to cater to distinct investor preferences.
      </Text>
      <UnorderedList>
        <ListItem>
          <strong> Co-ownership </strong> represents a collaborative approach,
          wherein multiple investors collectively share ownership and management
          responsibilities.
        </ListItem>
        <ListItem>
          <strong>Interval ownership </strong> offers the allure of fixed time
          periods, granting investors exclusive access to the property for
          designated periods annually.
        </ListItem>
        <ListItem>
          <strong>Equity sharing </strong> presents an avenue where investors
          hold shares akin to stocks, reaping benefits from property value
          appreciation and rental income alike.
        </ListItem>
      </UnorderedList>
      <Text>
        Such an intricate tapestry of options underscores the flexibility and
        tailored nature of fractional real estate ownership, ensuring that
        investors' unique aspirations are met with precision.
      </Text>
      <h3 style={{ marginTop: '20px' }}>Fractional ownership in real estate</h3>
      <Text>
        In the current investment climate, fractional ownership in real estate
        can be a particularly attractive option. With interest rates rising and
        the stock market volatile, real estate can offer a more stable
        investment opportunity.
        <br />
        <br />
        Additionally, fractional ownership can provide investors with access to
        properties that would otherwise be out of their reach, such as luxury
        vacation homes or commercial real estate.
      </Text>
      <h3 style={{ marginTop: '20px' }}>Fractional investment property</h3>
      <Text>
        The fractional investment property market is growing rapidly, with the
        global market size expected to reach $1.2 trillion by 2028. This growth
        is being driven by a number of factors, including:
        <br />
        <br />
        <strong>The rising cost of real estate:</strong> Fractional ownership
        offers a way for people to invest in real estate without having to come
        up with a large down payment.
        <br />
        <br />
        <strong>The increasing popularity of vacation rentals:</strong>{' '}
        Fractional ownership can give investors access to vacation rentals
        without having to buy an entire property.
        <br />
        <br />
        <strong>
          The growing availability of fractional investment platforms:
        </strong>{' '}
        There are now a number of fractional investment platforms that make it
        easy for people to invest in fractional properties. These platforms
        offer a variety of properties to choose from, and they make it easy to
        manage your investment.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Fractional ownership commercial real estate
      </h3>
      <Text>
        Fractional ownership allows investors to purchase a smaller share of a
        property, which can significantly reduce the upfront costs. For example,
        a commercial property that costs ₹10 crores could be divided into 100
        shares, each costing ₹10 lakhs. This makes it possible for investors
        with smaller budgets to participate in{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/commercial-real-estate-investment'}
        >
          commercial real estate investing.
        </Link>
        <br />
        <br />
        Fractional ownership commercial real estate is more liquid than
        traditional commercial real estate, making it easier to sell your shares
        if needed. This is because fractional ownership properties are often
        traded on secondary markets.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        How fractional property ownership works?
      </h2>
      <Text>
        Fractional property ownership operates on the premise of collective
        participation, ingeniously designed to facilitate shared ownership of
        valuable assets. Investors pool their resources to acquire a portion of
        the property, be it a luxury residence, commercial space, or vacation
        home.
        <br />
        <br />
        This joint ownership grants each investor a tangible stake, entitling
        them to a proportional share of the property's benefits, including
        potential rental income and capital appreciation. Collaboratively
        managed by designated entities, fractional ownership brings forth a
        harmonious synergy of ownership without the burdens of sole
        responsibility.
        <br />
        <br />
        This strategic alignment of resources and shared rewards underlines the
        essence of how fractional property ownership harmoniously bridges
        individual aspirations and collective opportunity.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Overview of fractional real estate market in India
      </h2>
      <Text>
        The real estate market in India is expected to grow significantly in the
        coming years, driven by the rising population, the growing economy, and
        the increasing investment in infrastructure. Investors who are looking
        for long-term growth opportunities should consider investing in the
        Indian real estate market.
        <br />
        <br />
        Some of the current fractional real estate trends in India include:
        <br />
        <br />
        <strong>The growth of second homes: </strong> More and more people are
        buying second homes in India, either for vacation or as an investment.
        This is being driven by the rising disposable income of Indians and the
        increasing popularity of short-term rentals.
        <br />
        <br />
        <strong>The growth of the rental market:</strong> The rental market in
        India is also growing rapidly. This is due to the increasing number of
        young professionals and students moving to urban areas for work or
        education.
        <br />
        <br />
        <strong>The rise of online real estate platforms:</strong> Online real
        estate platforms are becoming increasingly popular in India. These
        platforms make it easier for people to find and buy property, and they
        also offer a wider range of properties to choose from.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Opportunities and challenges of fractional ownership real estate India
      </h2>
      <Text>
        Fractional ownership real estate can be a good investment option for
        those who are looking to get into real estate but do not have the
        financial resources to purchase a property outright. However, it is
        important to be aware of the risks involved before investing.
        <br />
        <br />
        Opportunities:
        <br />
      </Text>
      <UnorderedList>
        <ListItem>
          The rising cost of real estate in India makes fractional ownership a
          more affordable option for many investors.
        </ListItem>
        <ListItem>
          The growing popularity of vacation rentals and co-living spaces is
          creating new opportunities for fractional ownership.
        </ListItem>
        <ListItem>
          The increasing availability of fractional ownership platforms is
          making it easier for investors to find and participate in fractional
          ownership opportunities.
        </ListItem>
      </UnorderedList>
      <Text>
        Challenges:
        <br />
      </Text>
      <UnorderedList>
        <ListItem>
          The lack of a clear regulatory framework for fractional ownership
          could pose challenges for investors.
        </ListItem>
        <ListItem>
          The potential for fraud and mismanagement is a risk that investors
          should be aware of.
        </ListItem>
        <ListItem>
          The illiquidity of fractional ownership assets can make it difficult
          to sell your shares if you need to.
        </ListItem>
      </UnorderedList>
      <Text>
        <br />
        If you are considering investing in fractional ownership real estate in
        India, it is important to do your research and understand the risks and
        rewards involved. You should also choose a reputable fractional
        ownership platform.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Comparison of fractional investment real estate in India with other
        countries
      </h2>
      <Text>
        Fractional real estate investment in India and other countries share
        some common features, but they also have differences that make them
        unique:
        <br />
      </Text>
      <Table variant="striped" border="2px solid" borderWidth="1px" mt={'20px'}>
        <Tbody>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Feature </Td>
            <Td border="2px solid">India </Td>
            <Td>Other Countries</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Regulatory framework</Td>
            <Td border="2px solid">Lack of a clear regulatory framework</Td>
            <Td>More established regulatory frameworks</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Popularity</Td>
            <Td border="2px solid">Growing popularity</Td>
            <Td>More established market</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Cost of investment</Td>
            <Td border="2px solid">Lower upfront costs</Td>
            <Td>Higher upfront costs</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Risk of fraud</Td>
            <Td border="2px solid">Higher risk of fraud</Td>
            <Td>Lower risk of fraud</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Liquidity</Td>
            <Td border="2px solid">Less liquid market</Td>
            <Td>More liquid market</Td>
          </Tr>
        </Tbody>
      </Table>
      <h2 style={{ marginTop: '20px' }}>
        What is fractional property investment?
      </h2>
      <Text>
        Fractional property investment involves multiple investors joining
        together to collectively purchase a share of a real estate property.
        Instead of one person owning the entire property, each investor owns a
        portion of it.
        <br />
        <br />
        This strategy allows individuals to invest in valuable properties
        without having to bear the full cost on their own. It's a way for people
        to enter the real estate market and benefit from potential property
        value appreciation and rental income without taking on the full
        financial commitment of sole ownership.
        <br />
        <br />
        While fractional property investment offers many advantages, it's
        important to consider potential challenges, such as coordination among
        multiple owners, decision-making processes, and legal and regulatory
        considerations that might vary depending on the jurisdiction.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Advantages and disadvantages of fractional property investment in India
      </h2>
      <Text>
        Here are some of the advantages and disadvantages of fractional property
        investment in India:
        <br />
        <br />
        <strong>Advantages:</strong>
        <br />
      </Text>
      <UnorderedList>
        <ListItem>Lower upfront costs</ListItem>
        <ListItem>Potential for attractive returns</ListItem>
        <ListItem>Diversification</ListItem>
        <ListItem>Liquidity</ListItem>
        <ListItem>Ease of ownership</ListItem>
      </UnorderedList>
      <Text>
        <strong>Disadvantages:</strong>
        <br />
      </Text>
      <UnorderedList>
        <ListItem>Potential for conflict</ListItem>
        <ListItem>Potential for fraud</ListItem>
        <ListItem>Limited control</ListItem>
        <ListItem>Regulatory uncertainty</ListItem>
      </UnorderedList>
      <Text>
        <br />
        Overall, fractional property investment can be a good investment option,
        but it is important to be aware of the risks involved.
        <br />
        <br />
        Here are some additional things to keep in mind when considering
        fractional property investment in India:
      </Text>
      <UnorderedList>
        <ListItem>
          {' '}
          The market is still relatively new and unregulated, so there is a risk
          of fraud.
        </ListItem>
        <ListItem>
          {' '}
          The prices of property can be volatile, so investors should be
          prepared for the possibility of losses.
        </ListItem>
        <ListItem>
          {' '}
          The market is also subject to government regulations, which can change
          at any time.
        </ListItem>
      </UnorderedList>
      <Text>
        <br />
        Despite these risks, the Indian fractional property investment market
        offers a number of potential benefits for investors. The market is
        growing rapidly, and there is a strong demand for property. Investors
        who are willing to do their research and take on some risk could
        potentially earn significant returns.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Factors to consider while doing fractional real estate investment
      </h2>
      <Text>
        When considering fractional real estate investment, remember to:
      </Text>
      <UnorderedList>
        <ListItem>
          Define Investment Goals: Determine whether you're aiming for income,
          wealth growth, or a combination of both.
        </ListItem>
        <ListItem>
          Gauge Risk Tolerance: Understand how much risk you're comfortable
          taking on in your investment.
        </ListItem>
        <ListItem>
          Research the Property: Scrutinize the property's location, condition,
          rental potential, and appreciation prospects.
        </ListItem>
        <ListItem>
          Choose a Reliable Platform: Opt for a reputable fractional ownership
          platform with transparent fees and legal protection.
        </ListItem>
        <ListItem>
          Evaluate Management: If third-party management is involved, research
          the company's reputation and track record.
        </ListItem>
        <ListItem>
          Understand Fees: Grasp the full extent of charges, including platform,
          management, and transaction fees.
        </ListItem>
        <ListItem>
          Consider Liquidity: Recognize that selling shares might be less
          straightforward than traditional investments.
        </ListItem>
        <ListItem>
          Navigate Regulations: Stay updated on evolving regulations governing
          fractional real estate investments.
        </ListItem>
        <ListItem>
          Start Small: Begin with modest investments to gain experience and
          minimize potential losses.
        </ListItem>
        <ListItem>
          Diversify: Spread investments across different properties and
          locations to manage risk.
        </ListItem>
        <ListItem>
          Rebalance: Adjust your portfolio as goals change to maintain the
          desired investment mix.
        </ListItem>
        <ListItem>
          Monitor Performance: Regularly track property values, rental income,
          and associated costs for informed decisions.
        </ListItem>
      </UnorderedList>
      <Text>
        By addressing these pointers, you can navigate fractional real estate
        investment more effectively and enhance your chances of success.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Risks associated with fractional real estate investment
      </h2>
      <Text>
        Fractional real estate investment is a relatively new investment option,
        and as such, it comes with some risks. Some of the risks associated with
        fractional real estate investment include:
      </Text>
      <UnorderedList>
        <ListItem>
          Lack of liquidity: Fractional real estate can be less liquid than
          traditional real estate, which means it may be difficult to sell your
          shares if you need to.
        </ListItem>
        <ListItem>
          Fraud: There have been some cases of fraud in the fractional real
          estate market, so it is important to do your research and choose a
          reputable fractional ownership platform.
        </ListItem>
        <ListItem>
          Limited control: As a fractional owner, you will not have the same
          level of control over the property as if you owned it outright.
        </ListItem>
        <ListItem>
          Management fees: Fractional real estate investments often come with
          high management fees, which can eat into your returns.
        </ListItem>
      </UnorderedList>
      <Text>
        These risks can be avoided by doing your research, choosing a reputable
        fractional ownership platform, and understanding the terms of the
        investment before you buy.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Differences between REITs and Fractional investment
      </h2>
      <Text>
        Here are some important differences between REITs and fractional
        investment:
      </Text>
      <UnorderedList>
        <ListItem>
          <strong>Ownership: </strong> REITs are shares of a company that owns
          real estate. Fractional investment is the act of owning a portion of a
          specific property.
        </ListItem>
        <ListItem>
          <strong>Liquidity: </strong> REITs are more liquid than fractional
          investments. This means that it is easier to sell your shares of a
          REIT if you need to.
        </ListItem>
        <ListItem>
          <strong>Management: </strong>REITs are managed by a professional team.
          Fractional investment is managed by the property owner.
        </ListItem>
        <ListItem>
          <strong>Taxation: </strong>REITs dividends are taxed as income.
          Fractional investment capital gains are taxed as capital gains.
        </ListItem>
        <ListItem>
          <strong>Risk: </strong>REITs are considered to be a lower-risk
          investment than fractional investment. This is because REITs are
          diversified across a variety of properties.
        </ListItem>
      </UnorderedList>
      <Text>
        <br />
        Ultimately, the best investment for you will depend on your individual
        circumstances and risk tolerance. If you are looking for a liquid
        investment with lower risk, then REITs may be a good option for you. If
        you are looking for an investment that gives you more control over a
        specific property, then fractional investment may be a better option.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Fractional property ownership vs. traditional real estate ownership
      </h2>
      <Text>
        Here are some differences between fractional property ownership and
        traditional real estate ownership:
      </Text>
      <UnorderedList>
        <ListItem>
          <strong>Ownership: </strong> In fractional property ownership, you own
          a portion of a property, such as a vacation home or a commercial
          property. In traditional real estate ownership, you own the entire
          property.
        </ListItem>
        <ListItem>
          <strong>Cost: </strong> Fractional property ownership can have lower
          upfront costs than traditional real estate ownership, as you only need
          to invest in a portion of the property.
        </ListItem>
        <ListItem>
          <strong>Liquidity: </strong>Fractional property ownership is less
          liquid than traditional real estate ownership, as it may be more
          difficult to sell your shares if you need to.
        </ListItem>
        <ListItem>
          <strong>Control: </strong>In fractional property ownership, you have
          less control over the property than in traditional real estate
          ownership. This is because you will need to share decision-making with
          the other owners.
        </ListItem>
        <ListItem>
          <strong>Risk: </strong>Fractional property ownership is considered to
          be a higher-risk investment than traditional real estate ownership.
          This is because you are investing in a smaller portion of the
          property, which means that you are more exposed to the risks
          associated with the property.
        </ListItem>
      </UnorderedList>
      <Text>
        <br />
        If you are looking for an investment with lower upfront costs and more
        liquidity, then fractional property ownership may be a good option for
        you. If you are looking for an investment that gives you more control
        and lower risk, then traditional real estate ownership may be a better
        option.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Fractional Ownership Commercial Real Estate - Overview
      </h2>
      <Text>
        The perks of fractional ownership in commercial real estate encompass a
        range of benefits. As a fractional owner, you're entitled to a portion
        of the property's rental income, reflecting a direct return on your
        investment. Additionally, any appreciation of the property's value
        translates into shared financial gain. Your ownership status might also
        grant you voting rights, enabling your input on key decisions like
        significant renovations or property sales. Moreover, access to amenities
        linked with the property, such as fitness facilities or pools, can
        augment your overall experience.
        <br />
        <br />
        The process of engaging in fractional ownership of commercial real
        estate involves several straightforward steps. You begin by selecting a
        trusted fractional ownership platform, then choose a specific property
        for investment. Following your investment, you receive shares in the
        property, entitling you to a proportional portion of rental income and
        potential capital appreciation. This approach proves advantageous for
        investors keen on commercial real estate but lacking the resources for
        an outright property purchase. As always, thorough research and a
        reliable fractional ownership platform are crucial for informed
        investment decisions.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Steps to get started with fractional ownership commercial real
      </h2>
      <Text>
        Here are the steps to get started with fractional ownership commercial
        real estate:
      </Text>
      <UnorderedList>
        <ListItem>
          <strong>Choose a fractional ownership platform: </strong> There are a
          number of fractional ownership platforms available, so it is important
          to choose one that is reputable and has a good track record.
        </ListItem>
        <ListItem>
          <strong>Do your research: </strong> Before you invest, it is important
          to do your research on the property, the fractional ownership
          platform, and the management company.
        </ListItem>
        <ListItem>
          <strong>Select a property to invest in: </strong>Consider your
          investment goals, risk tolerance, and time horizon when selecting a
          property.
        </ListItem>
        <ListItem>
          <strong>Make an investment: </strong>The amount of money you invest
          will depend on the property you select and your investment goals.
        </ListItem>
        <ListItem>
          <strong>Receive shares in the property: </strong>Once you have made
          your investment, you will receive shares in the property.
        </ListItem>
        <ListItem>
          <strong>
            Receive a share of the rental income and capital appreciation:{' '}
          </strong>
          As a fractional owner, you will receive a share of the rental income
          generated by the property and any capital appreciation that the
          property experiences.
        </ListItem>
      </UnorderedList>
      <h2 style={{ marginTop: '20px' }}>
        Advantages and drawbacks of fractional shares in India
      </h2>
      <Text>
        Here are the advantages and drawbacks of fractional shares in India:
        <br />
        <br />
        <strong>Advantages:</strong>
      </Text>
      <UnorderedList>
        <ListItem>
          <strong>Lower investment amount: </strong> Fractional shares allow
          investors to buy shares of a company with a smaller investment amount.
        </ListItem>
        <ListItem>
          <strong>Diversification: </strong> Fractional shares in India can help
          investors diversify their portfolios by investing in a variety of
          companies.
        </ListItem>
        <ListItem>
          <strong>Liquidity: </strong> Fractional shares can be more liquid than
          traditional shares, making it easier to sell them if needed.
        </ListItem>
        <ListItem>
          <strong>Accessibility: </strong>Fractional shares can be purchased
          through online platforms, making them more accessible to investors.
        </ListItem>
      </UnorderedList>
      <Text>
        <strong>Drawbacks:</strong>
      </Text>
      <UnorderedList>
        <ListItem>
          <strong>Management fees: </strong> Fractional shares in India may have
          higher management fees than traditional shares.
        </ListItem>
        <ListItem>
          <strong>Risk: </strong> Fractional shares in India are subject to the
          same risks as traditional shares, such as market volatility and
          company-specific risks.
        </ListItem>
        <ListItem>
          <strong>Regulatory uncertainty: </strong> The regulatory environment
          for fractional shares is still evolving in India, which could pose
          some risks to investors.
        </ListItem>
      </UnorderedList>

      <h2 style={{ marginTop: '20px' }}>
        Comparison of Traditional Shares & Fractional Shares in India
      </h2>
      <Table variant="striped" border="2px solid" borderWidth="1px" mt={'20px'}>
        <Tbody>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Feature </Td>
            <Td border="2px solid">Traditional Shares </Td>
            <Td>Fractional Shares</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Investment amount</Td>
            <Td border="2px solid">Requires a large investment amount</Td>
            <Td>
              Allows investors to buy shares with a smaller investment amount
            </Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Diversification</Td>
            <Td border="2px solid">
              Can be difficult to diversify a portfolio with traditional shares
            </Td>
            <Td>
              Can help investors diversify their portfolios by investing in a
              variety of companies
            </Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Liquidity</Td>
            <Td border="2px solid">
              Can be less liquid than fractional shares
            </Td>
            <Td>Can be more liquid than traditional shares</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Accessibility</Td>
            <Td border="2px solid">
              Can be difficult to purchase traditional shares
            </Td>
            <Td>More accessible to investors through online platforms</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Management fees</Td>
            <Td border="2px solid">May have lower management fees</Td>
            <Td>May have higher management fees</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Risk</Td>
            <Td border="2px solid">
              Subject to the same risks as fractional shares
            </Td>
            <Td>Subject to the same risks as traditional shares</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Regulatory framework</Td>
            <Td border="2px solid">Well-established regulatory framework</Td>
            <Td>Regulatory environment is still evolving</Td>
          </Tr>
        </Tbody>
      </Table>
      <h2 style={{ marginTop: '20px' }}>
        Why do fractional investment in real estate with hBits?
      </h2>
      <Text>
        Investing in real estate through{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/'}
        >
          hBits
        </Link>{' '}
        fractional investment offers a host of compelling advantages that set it
        apart as a strategic choice:
        <br />
      </Text>
      <UnorderedList>
        <ListItem>
          <strong>Low Ticket Size: </strong>Access high-quality assets with an
          affordable ticket size of ₹25 lakhs.
        </ListItem>
        <ListItem>
          <strong>Convenience: </strong>Enjoy the ease and convenience of remote
          access to distinctive data insights of every property.
        </ListItem>
        <ListItem>
          <strong>Attractive Returns: </strong> Anticipate attractive returns
          with an expected Internal Rate of Return (IRR) of 13-20% over 5 years,
          including average rental yields of 8-10%.
        </ListItem>
        <ListItem>
          <strong>Hassle-Free Ownership: </strong>Experience hassle-free
          ownership with hBits managing rent collection, maintenance, and
          property management.
        </ListItem>
        <ListItem>
          <strong>Improved Liquidity: </strong>Benefit from improved liquidity
          with seamless exits and continuous price discovery, ensuring awareness
          of asset value.
        </ListItem>
        <ListItem>
          <strong>Transparency: </strong>Access all data and information for
          easy and secure transactions.
        </ListItem>
      </UnorderedList>
      <Text>
        <br />
        These points underscore the value proposition of fractional real estate
        investment through hBits, emphasizing convenience, financial gains,
        hassle-free management, and transparency for investors.
      </Text>
      <h2 style={{ marginTop: '20px' }}>Frequently Asked Questions</h2>
      <h3 style={{ marginTop: '20px' }}>
        Q1. What is fractional ownership in real estate?
      </h3>
      <Text>
        Fractional ownership is a great way to invest in real estate without
        having to buy an entire property. With fractional ownership, you can own
        a portion of a property, such as a vacation home or a commercial
        property, with other investors. This can be a great way to diversify
        your portfolio and get exposure to real estate without having to commit
        a large amount of capital.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q2. How does fractional ownership real estate in India work?
      </h3>
      <Text>
        Fractional ownership in India involves multiple investors collectively
        purchasing a share of a property through a reputable platform. Investors
        contribute funds to acquire ownership, proportionate to their
        investment. Rental income and potential appreciation are shared among
        owners.
        <br />
        <br />
        Professional property management ensures smooth operations. Exit options
        may include selling shares. Transparency and regular updates are
        provided by the platform. Regulatory compliance is crucial. Fractional
        ownership democratizes real estate investment, granting access to
        diverse properties and potential returns without the need for full
        ownership.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q3. How to do fractional real estate investing in India?
      </h3>
      <Text>
        To engage in fractional real estate investing in India, follow these
        steps:
        <br />
      </Text>
      <UnorderedList>
        <ListItem>Choose a reputable platform.</ListItem>
        <ListItem>
          Browse properties available for fractional investment.
        </ListItem>
        <ListItem>
          Invest a specific amount to purchase a fraction of the property.
        </ListItem>
        <ListItem>
          Own a share of the property and receive related legal documentation.
        </ListItem>
        <ListItem>Earn rental income and potential appreciation.</ListItem>
        <ListItem>Benefit from professional property management.</ListItem>
        <ListItem>Stay updated through platform transparency.</ListItem>
        <ListItem>
          Explore exit options if needed. Always consider legal and regulatory
          aspects. Fractional investing in India offers a convenient way to
          access real estate opportunities with shared ownership.
        </ListItem>
      </UnorderedList>
      <h3 style={{ marginTop: '20px' }}>
        Q4. What are the benefits of fractional investment real estate?
      </h3>
      <Text>
        The benefits of fractional investment in real estate include access to
        high-value properties with lower entry costs, risk diversification
        across multiple properties, potential rental income, and property
        appreciation, professional management, and improved liquidity through
        easier exits. It's an avenue for individuals to engage in real estate
        without the burden of sole ownership, offering a balanced blend of
        returns and convenience.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q5. How does fractional real estate investment differ from other real
        estate investments?
      </h3>
      <Text>
        Fractional real estate investment stands apart by allowing multiple
        investors to collectively own a portion of a property, reducing entry
        costs and enabling risk diversification. Unlike traditional ownership,
        fractional investors enjoy shared rental income and appreciation.
        <br />
        <br />
        In contrast, sole ownership demands higher capital outlay and entails
        full responsibility. Real estate investment trusts (REITs) provide
        passive ownership without direct property ownership, while fix-and-flip
        involves property purchase for resale. Fractional ownership offers a
        unique balance of affordability, shared gains, and convenience.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q6. What are the legal implications of fractional ownership in India?
      </h3>
      <Text>
        The legal framework for fractional ownership in India is still evolving:
      </Text>
      <UnorderedList>
        <ListItem>
          The Transfer of Property Act, 1882, does not specifically mention
          fractional ownership.
        </ListItem>
        <ListItem>
          The Securities and Exchange Board of India (SEBI) is considering
          regulating fractional ownership.
        </ListItem>
        <ListItem>
          Investors should carefully consider the legal implications of
          fractional ownership before investing.
        </ListItem>
      </UnorderedList>
      <h3 style={{ marginTop: '20px' }}>
        Q7. What is the process of fractional investment property?
      </h3>
      <Text>
        The process of fractional investment property begins by selecting a
        reliable platform. Browse a curated range of properties available for
        fractional ownership. Invest in a fraction of the chosen property,
        unlocking access to prime real estate with a lower entry cost.
        <br />
        <br />
        Experience shared ownership, proportional rental income, and potential
        appreciation. Professional management eases day-to-day operations. Stay
        informed through transparent updates. Exit options offer flexibility.
        Fractional investment offers a gateway for new investors to venture into
        real estate with strategic benefits.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q8. What are the risks associated with fractional real estate in India?
      </h3>
      <Text>
        Exploring fractional real estate in India offers intriguing
        opportunities, coupled with important considerations. While potential
        illiquidity and management fees are factors, selecting reliable
        platforms can mitigate such concerns.
        <br />
        <br />
        The evolving regulatory landscape signals adaptation and potential
        clarity. Just like with traditional real estate, market dynamics and
        property-specific matters influence outcomes. The key to a successful
        journey lies in diligent research, informed decisions, and aligning with
        trustworthy platforms, paving the way for an optimistic venture into
        fractional real estate investment.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q9. What is the potential return on fractional property investment in
        India?
      </h3>
      <Text>
        Fractional property investment in India presents an enticing avenue for
        favorable returns, encompassing potential capital appreciation and
        rental income. Amidst the robust growth of India's real estate market,
        both residential and commercial sectors are thriving.
        <br />
        <br />
        By opting for fractional investment, individuals can tap into this
        promising trajectory without the need for full property ownership. The
        added allure lies in the prospect of rental income, aiding in cost
        offset. However, prudent decision-making is crucial, as every investment
        entails inherent risks that demand alignment with personal goals and
        risk tolerance.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q10. How do I choose the right fractional property investing in India?
      </h3>
      <Text>
        To select the ideal fractional property investment in India, follow
        these steps:
      </Text>
      <UnorderedList>
        <ListItem>
          <strong>Research Platforms: </strong>Explore reputable fractional
          ownership platforms with a proven track record.
        </ListItem>
        <ListItem>
          <strong>Property Analysis: </strong>Scrutinize property offerings,
          considering location, potential for appreciation, and rental income.
        </ListItem>
        <ListItem>
          <strong>Platform Transparency: </strong>Opt for platforms offering
          transparent fee structures, clear property details, and regular
          updates.
        </ListItem>
        <ListItem>
          <strong>Legal Compliance: </strong>Ensure the platform adheres to
          legal regulations and offers robust ownership documentation.
        </ListItem>
        <ListItem>
          <strong>Risk Assessment: </strong>Evaluate your risk tolerance and
          investment goals to match with the property's potential.
        </ListItem>
      </UnorderedList>
      <h3 style={{ marginTop: '20px' }}>
        Q11. Is fractional ownership investment in real estate suitable for
        first-time investors?
      </h3>
      <Text>
        Fractional ownership investment in real estate can be a suitable option
        for first-time investors, but it is important to do your research and
        understand the risks involved.
      </Text>
    </Flex>
  );
};

export default FooterdataFractionalOwnershipInvestment;
