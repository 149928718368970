export enum InvestmentStatus {
  EOIDocumentSigningPending = 'EOIDocumentSigningPending',
  EOIDocumentSignedButAmountPending = 'EOIDocumentSignedButAmountPending',
  EOIDocumentSignedAndAmountPaid = 'EOIDocumentSignedAndAmountPaid',
  PAS4SigningPending = 'PAS4SigningPending',
  PAS4SigningDone = 'PAS4SigningDone',
  BalanceAmountPending = 'BalanceAmountPending',
  BalanceAmountPaid = 'BalanceAmountPaid',
  CustomerIsEnjoyingROI = 'CustomerIsEnjoyingROI',
}

export enum InvestmentFriendlyStatus {
  EOIDocumentSigningPending = 'EOI Document Signing Pending',
  EOIDocumentSignedButAmountPending = 'EOI Document Signed But Amount Pending',
  EOIDocumentSignedAndAmountPaid = 'EOI Document Signed And Amount Paid',
  PAS4SigningPending = 'PAS4 Signing Pending',
  PAS4SigningDone = 'PAS4 Signing Done',
  BalanceAmountPending = 'Balance Amount Pending',
  BalanceAmountPaid = 'Balance Amount Paid',
  CustomerIsEnjoyingROI = '-',
}

export default InvestmentStatus;
