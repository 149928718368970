import * as React from 'react';
import { Environment } from 'libs/src/services/EnvironmentHelper';

export const ClarityTracking = () => {
    const nextPublicEnvName = Environment.getInstance().getParam('NEXT_PUBLIC_ENV_NAME', );

    return nextPublicEnvName === "Production" ? (
        <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
                __html: `
                    (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "jxkw6v3f2a");
                `,
            }}
        />
    ) : null;
};
