import { Button, Flex, Image, Text, useToast } from '@chakra-ui/react';
import colors from 'libs/src/theme/colors';
import fileImage from '../../../assets/kyc/file.png';
import pdfImage from '../../../assets/kyc/pdf-file.png';
import WordImage from '../../../assets/kyc/word.png';
import CrossSignImage from '../../../assets/kyc/circleLogo.svg';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { AddressProofType, FileUploadType, Strings } from '@hBits.Core';
import { capitalizeFirstLetter } from '../utils';
import styles from '../index.module.scss';
import uploadImage from '../../../assets/kyc/upload-icon.png';
import { getFileUploadUrl } from './getFileUploadUrl';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface UrlShowerProps {
  values: string;
  valuesType: string;
  deleteUrl: any;
  index?: any;
  isMultiple?: boolean;
  width?: string;
  height?: string;
  handleCrossSign?: any;
  holderNumber: number;
  rejectionReason?: string;
  documentName?: string;
  setAadharProofBackUrl?: any;
  aadharProofBackUrl?: string;
  saveAndContinueClicked?: boolean;
  isDisabledFlag?: boolean;
}

const UrlViewer = (props: UrlShowerProps) => {
  const { isAllFieldsDisable } = useSelector((state: any) => state.KycReducer);
  const toast = useToast();
  const {
    values,
    valuesType,
    deleteUrl,
    index,
    isMultiple = false,
    width = valuesType === 'Image' ? '160px' : '50px',
    height = valuesType === 'Image' ? '90px' : '50px',
    handleCrossSign,
    holderNumber,
    rejectionReason,
    documentName,
    setAadharProofBackUrl,
    aadharProofBackUrl,
    saveAndContinueClicked,
    isDisabledFlag = true,
  } = props;

  const downloadFile = () => {
    saveAs(values);
  };

  const renderFile = (type: string, url: string) => {
    switch (type) {
      case capitalizeFirstLetter(FileUploadType.Image):
        return <Image w={width} h={height} src={url} onClick={downloadFile} />;
      case capitalizeFirstLetter(FileUploadType.Pdf):
        return (
          <Image
            w={width}
            h={height}
            src={pdfImage}
            onClick={downloadFile}
            alt=""
          />
        );
      case capitalizeFirstLetter(FileUploadType.Word):
        return (
          <Image
            w={width}
            h={height}
            src={WordImage}
            onClick={downloadFile}
            alt=""
          />
        );
      default:
        return (
          <Image
            w={width}
            h={height}
            src={fileImage}
            onClick={downloadFile}
            alt=""
          />
        );
    }
  };

  const handleChangeUploadFile = async (e: any) => {
    const type = e.target.files[0].type.split('/');
    if (
      capitalizeFirstLetter(type[0]) === valuesType ||
      capitalizeFirstLetter(type[1]) === valuesType
    ) {
      const response = await getFileUploadUrl(e, toast);
      if (response) {
        setAadharProofBackUrl(response?.data?.documentUrl);
      }
    } else {
      toast({
        title: Strings.failed,
        description: Strings.frontBackError,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getCustomDelete = (isAadhar: boolean) => (
    <Button
      bg={'null'}
      p={0}
      _hover={{ bg: 'null' }}
      _active={{ bg: 'null' }}
      isDisabled={isAllFieldsDisable[holderNumber] && isDisabledFlag}
      _disabled={{ color: 'black' }}
      alignSelf={isMultiple ? 'flex-start' : 'center'}
      fontSize={'18px'}
      fontWeight={500}
      onClick={() => {
        isAadhar
          ? setAadharProofBackUrl('')
          : isMultiple
            ? deleteUrl(index)
            : deleteUrl(true);
      }}
    >
      Delete
    </Button>
  );

  return (
    <Flex>
      <Flex
        direction={'column'}
        cursor={'pointer'}
        ml={{ base: 0, md: isMultiple ? 4 : 0 }}
      >
        <Flex>
          <Flex ml={isMultiple ? 2 : 0} direction={'column'}>
            {renderFile(valuesType, values)}
            <Flex justifyContent={'center'}>{getCustomDelete(false)}</Flex>
          </Flex>
          {isMultiple && (
            <Flex>
              {documentName === AddressProofType.Aadhar && (
                <>
                  {aadharProofBackUrl && (
                    <Flex ml={isMultiple ? 2 : 0} direction={'column'}>
                      {renderFile(valuesType, aadharProofBackUrl)}
                      {getCustomDelete(true)}
                    </Flex>
                  )}
                  {!aadharProofBackUrl && (
                    <Flex ml={4} justifyContent={'center'}>
                      <label>
                        <Image
                          src={uploadImage}
                          cursor={'pointer'}
                          width={'35px'}
                          h={'35px'}
                          className={styles['crossSign']}
                          alt=""
                        ></Image>
                        <input
                          data-test='input-upload-backside-img'
                          id="file"
                          type="file"
                          accept="image/*,.doc, .docx,.pdf"
                          disabled={
                            isAllFieldsDisable[holderNumber] && isDisabledFlag
                          }
                          onChange={(e) => handleChangeUploadFile(e)}
                        />
                      </label>
                    </Flex>
                  )}
                </>
              )}
              {index > 0 && (
                <Flex alignSelf={'center'} pl={4}>
                  <Button
                    bg={'null'}
                    p={0}
                    _hover={{ bg: 'null' }}
                    _active={{ bg: 'null' }}
                    isDisabled={
                      isAllFieldsDisable[holderNumber] && isDisabledFlag
                    }
                    _disabled={{ color: 'black' }}
                    alignSelf={'center'}
                  >
                    <Image
                      src={CrossSignImage}
                      boxSize="20px"
                      cursor={'pointer'}
                      alignSelf={'center'}
                      className={styles['crossSignafterImage']}
                      onClick={() => handleCrossSign(index)}
                      alt=""
                    ></Image>
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
        {documentName === AddressProofType.Aadhar &&
          (aadharProofBackUrl === '' || aadharProofBackUrl === undefined) &&
          saveAndContinueClicked && (
            <div className={styles['errorRegMessage']}>
              {Strings.uploadBackAadharError}
            </div>
          )}
        {rejectionReason !== undefined && rejectionReason !== '' && (
          <p className={styles['errorRegMessage']}>{rejectionReason}</p>
        )}
      </Flex>
    </Flex>
  );
};

export default UrlViewer;
