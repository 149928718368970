import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Colors } from 'apps/customer-portal-web/src/theme';

const Dounut = (props) => {
  const { title, data, labels, colors } = props;
  const responsiveGraph = {
    sm: 480,
    mid: 700,
    lg: 980,
  };

  const getgraphWidth = () => {
    let width = screen.width;
    if (width >= responsiveGraph.lg) {
      return 300;
    }
    return 250;
  };

  const getgraphLegengMargin = () => {
    let width = screen.width;
    if (width >= responsiveGraph.lg) {
      return 40;
    }
    return 20;
  };

  const options = {
    chart: {
      width: '100%',
      type: 'donut',
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + '%';
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
            height: 300,
          },
          legend: {
            show: true,
            position: 'right',
          },
        },
      },
    ],
    legend: {
      position: 'right',
      offsetX: 20,
      offsetY: getgraphLegengMargin(),
      width: 120,
      labels: {
        colors: Colors.grey200,
      },
      markers: {
        width: 8,
        height: 8,
        radius: 12,
      },
    },
    labels: labels,
    plotOptions: {
      pie: {
        customScale: 0.9,
        donut: {
          size: '30%',
        },
      },
    },
    title: {
      text: title,
      align: 'center',
      margin: 10,
      offsetX: -60,
      offsetY: 0,
      floating: true,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: Colors.headerColor,
      },
    },
    colors: colors,
  };

  return (
    <Chart
      options={options}
      series={data}
      type="donut"
      width={getgraphWidth()}
      height={getgraphWidth()}
    />
  );
};

export default Dounut;
