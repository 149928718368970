import { createReducer } from '../reducerUtils';
import {
  HOLDER_ID,
  IS_NRI,
  TAB_VALUE,
  IS_DISPLAY_FOR_VERIICATION_IN_PROGRESS,
  KYC_STATUS_FOR_ACCOUNT,
  KYC_DATA,
  IS_GET_KYC_DATA,
  IS_ALL_FIELDS_DISABLED,
  IS_SUBMITTED_PAGE_VISIBLE,
  KYC_STATUS_FOR_HOLDER,
  ADDRESS_RECORD_NUMBER,
  IS_USER_TYPE_API,
  ADDRESS_HOLDER_NUMBER,
  ADDRESS_PERMANENT_HOLDER_NUMBER,
  ADDRESS_PROOF_HOLDER_NUMBER,
  ADDRESS_REPRESENTATIVE_HOLDER_NUMBER,
  ADDRESS_REPRESENTATIVE_PROOF_HOLDER_NUMBER,
  ADDRESS_REPRESENTATIVE_PERMANENT_HOLDER_NUMBER,
} from '../../constants/ReduxConstants';

interface KycReducerType {
  holderId: any;
  isNri: any;
  tabValue: any;
  isDisplayForVerificationInProgress: boolean;
  kycStatusForAccount: string;
  kycData: any;
  isGetKycData: boolean;
  isAllFieldsDisable: boolean;
  isSubmittedPageVisible: boolean;
  kycStatusForHolder: string;
  addressRecordNumber: number;
  isUserTypeApi: boolean;
  addressHolderNumber: number;
  addressProofHolderNumber: number;
  addressPermanentHolderNumber: number;
  addressRepresentativeHolderNumber: number;
  addressRepresentativeProofHolderNumber: number;
  addressRepresentativePermanentHolderNumber: number;
}

const initialState = {
  holderId: { 0: '' },
  isNri: { 0: false },
  tabValue: { 0: 0, 1: 0, 2: 0 },
  isDisplayForVerificationInProgress: false,
  kycStatusForAccount: '',
  kycStatusForHolder: { 0: '' },
  kycData: { 0: {} },
  isGetKycData: false,
  isAllFieldsDisable: { 0: false },
  isSubmittedPageVisible: { 0: false },
  addressRecordNumber: { 0: 1, 1: 1, 2: 1 },
  isUserTypeApi: false,
  addressHolderNumber: { 0: -2, 1: -2, 2: -2 },
  addressProofHolderNumber: { 0: -2, 1: -2, 2: -2 },
  addressPermanentHolderNumber: { 0: -2, 1: -2, 2: -2 },
  addressRepresentativeHolderNumber: { 0: -2, 1: -2, 2: -2 },
  addressRepresentativeProofHolderNumber: { 0: -2, 1: -2, 2: -2 },
  addressRepresentativePermanentHolderNumber: { 0: -2, 1: -2, 2: -2 },
};

export const setHolderId = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    holderId: payload,
  };
};
export const setAddressHolderNumber = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    addressHolderNumber: payload,
  };
};
export const setProofAddressHolderNumber = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    addressProofHolderNumber: payload,
  };
};
export const setPermanentAddressHolderNumber = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    addressPermanentHolderNumber: payload,
  };
};
export const setRepresentativeAddressHolderNumber = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    addressRepresentativeHolderNumber: payload,
  };
};
export const setRepresentativeProofAddressHolderNumber = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    addressRepresentativeProofHolderNumber: payload,
  };
};
export const setRepresentativePermanentAddressHolderNumber = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    addressRepresentativePermanentHolderNumber: payload,
  };
};
export const setNriState = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    isNri: payload,
  };
};
export const setTabIndexValue = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    tabValue: payload,
  };
};
export const setAddressRecordNumber = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    addressRecordNumber: payload,
  };
};

export const setDisplayStateForVerificationInProgress = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    isDisplayForVerificationInProgress: payload,
  };
};
export const setKycStatusForAccount = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    kycStatusForAccount: payload,
  };
};
export const setKycStatusForHolder = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    kycStatusForHolder: payload,
  };
};
export const setKycData = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    kycData: payload,
  };
};

export const setIsGetKycData = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    isGetKycData: payload,
  };
};

export const callUserTypeApi = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    isUserTypeApi: payload,
  };
};
export const setIsAllFieldsDisable = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    isAllFieldsDisable: payload,
  };
};

export const setIsSubmittedPageVisible = (
  state: KycReducerType,
  payload: any,
): typeof state => {
  return {
    ...state,
    isSubmittedPageVisible: payload,
  };
};

export default createReducer(initialState, {
  [HOLDER_ID]: setHolderId,
  [IS_NRI]: setNriState,
  [TAB_VALUE]: setTabIndexValue,
  [IS_DISPLAY_FOR_VERIICATION_IN_PROGRESS]:
    setDisplayStateForVerificationInProgress,
  [KYC_STATUS_FOR_ACCOUNT]: setKycStatusForAccount,
  [KYC_DATA]: setKycData,
  [IS_GET_KYC_DATA]: setIsGetKycData,
  [IS_ALL_FIELDS_DISABLED]: setIsAllFieldsDisable,
  [IS_SUBMITTED_PAGE_VISIBLE]: setIsSubmittedPageVisible,
  [KYC_STATUS_FOR_HOLDER]: setKycStatusForHolder,
  [ADDRESS_RECORD_NUMBER]: setAddressRecordNumber,
  [IS_USER_TYPE_API]: callUserTypeApi,
  [ADDRESS_HOLDER_NUMBER]: setAddressHolderNumber,
  [ADDRESS_PROOF_HOLDER_NUMBER]: setProofAddressHolderNumber,
  [ADDRESS_PERMANENT_HOLDER_NUMBER]: setPermanentAddressHolderNumber,
  [ADDRESS_REPRESENTATIVE_HOLDER_NUMBER]: setRepresentativeAddressHolderNumber,
  [ADDRESS_REPRESENTATIVE_PROOF_HOLDER_NUMBER]:
    setRepresentativeProofAddressHolderNumber,
  [ADDRESS_REPRESENTATIVE_PERMANENT_HOLDER_NUMBER]:
    setRepresentativePermanentAddressHolderNumber,
});
