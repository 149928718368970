import { HTTPStatusCode, fetchAsync } from '@hBits.Core';
import endpoints, { baseApiUrl } from '@hBits.Core/endpoints';

export const fetchUserEarnings = async (
  accountId: any,
  propertyId: string,
  fromMonth: number,
  toMonth: number,
  fromYear: number,
  toYear: number,
): Promise<any> => {
  try {
    const request = {
      url:
        baseApiUrl +
        endpoints.userEarnings
          .replace('#{propertyId}', propertyId)
          .replace('#{accountId}', accountId)
          .replace('#{fromMonth}', fromMonth.toString())
          .replace('#{toMonth}', toMonth.toString())
          .replace('#{fromYear}', fromYear.toString())
          .replace('#{toYear}', toYear.toString()),
    };
    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return {};
  } catch (error) {
    return [];
  }
};
