export enum InvestmentStage {
  NotStarted = 'NotStarted',
  PrimaryEOI = 'PrimaryEOI',
  TopUpEOI = 'TopUpEOI',
  PAS4 = 'PAS4',
  FullPay = 'FullPay',
  RegistrationComplete = 'RegistrationComplete',
  Abandoned = 'Abandoned',
  Transferred = 'Transferred',
}

export enum InvestmentStageUserFriendly {
  NotStarted = 'Not Started',
  PrimaryEOI = 'EOI',
  TopUpEOI = 'Top Up EOI',
  PAS4 = 'PAS4',
  FullPay = 'Full Pay',
  RegistrationComplete = 'Registration Complete',
  Abandoned = 'Abandoned',
  Transferred = 'Transferred',
}

export default InvestmentStage;
