import {
  CertificateIcon,
  CertificateSelectedIcon,
  MoneyIcon,
  MoneySelectedIcon,
  StarIcon,
  SuccessIcon,
  SuccessSelectedIcon,
} from '../../assets/customIcons';

const stepKeys = [
  {
    title: 'EOI',
    activeIcon: <StarIcon height="50px" width="50px" />,
    inactiveIcon: <StarIcon height="50px" width="50px" />,
    marginLeft: '13px',
  },
  {
    title: 'PAS4',
    activeIcon: <CertificateSelectedIcon height="50px" width="50px" />,
    inactiveIcon: <CertificateIcon height="50px" width="50px" />,
    marginLeft: '8px',
  },
  {
    title: 'Full Pay',
    activeIcon: <MoneySelectedIcon height="50px" width="50px" />,
    inactiveIcon: <MoneyIcon height="50px" width="50px" />,
    marginLeft: '3px',
  },
  {
    title: 'Registration',
    activeIcon: <SuccessSelectedIcon height="50px" width="50px" />,
    inactiveIcon: <SuccessIcon height="50px" width="50px" />,
    marginLeft: '-7px',
  },
];

export default stepKeys;
