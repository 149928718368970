import { isEmpty } from '@chakra-ui/utils';
import { Strings, UserType } from '@hBits.Core';
import { AccountManager, authTokenStorageProvider } from 'libs/src/auth';
import accountIdManager from 'libs/src/auth/account-manager';
import { InvestmentStage, KycStatus } from 'libs/src/enums';
import { Environment } from 'libs/src/services/EnvironmentHelper';
import {
  getChannelPartnerKycStatus,
  getInvestMentSnakBarStatus,
  getKycStatus,
  getPropertyPageDetails,
} from 'libs/src/services/modules/snackbar';
import colors from 'libs/src/theme/colors';
import * as React from 'react';
import { useEffect, useState } from 'react';
import star from '../../../src/assets/svg/star.svg';
import closeCircle2 from '../../../src/assets/svg/x_circle_2.svg';

// Custom Snackbars

const getPropertyId = (pathname = '') => {
  const tempPathName = pathname.split('/');
  return tempPathName[tempPathName.length - 1];
};

const propertyStatusConstnt = {
  propertyNotLiveYet: 'PropertyNotLiveYet',
  propertyCompletelyFunded: 'PropertyCompletelyFunded',
};

interface snackbarProps {
  isChannelPartner?: boolean;
}

const SnackBar = (props: snackbarProps) => {
  const {
    isChannelPartner = typeof window !== 'undefined' &&
      window.location.pathname === '/channel-partner',
  } = props;
  const [kycStatusCheck, setkycStatusCheck] = useState<any>({});
  const [showInvestmentSnak, setInvestmentSnak] = useState<any>({});
  const [inPropertyDetailsPage, setInPropertyDetailsPage] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState<any>();
  const [showSnackBar, setshowSnackBar] = useState(false);
  const { NotStarted, PrimaryEOI } = InvestmentStage;
  const [isinvestmentAmountEligible, setIsInvestmentAmountEligible] =
    useState<boolean>(true);
  let pathName = '';
  if (typeof window !== 'undefined') {
    pathName = window.location?.pathname;
  }
  const customerPortalHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_CUSTOMER_PORTAL_HOSTING_ROOT',
  );

  // for kyc
  const landingPageHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  useEffect(() => {
    const handleKycStatus = async () => {
      const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
      const ifEnrolledAsChannelPartner = authTokenModel?.userTypes.includes(
        UserType.ChannelPartner,
      );
      if (isChannelPartner && ifEnrolledAsChannelPartner) {
        if (accountIdManager?.getAccountId()) {
          const response = await getChannelPartnerKycStatus(
            accountIdManager?.getAccountId(),
          );
          if (isEmpty(response)) return;
          setshowSnackBar(true);
          setkycStatusCheck(response?.kycStatus);
        }
      } else {
        if (accountIdManager?.getAccountId()) {
          const response = await getKycStatus(accountIdManager?.getAccountId());
          if (isEmpty(response)) return;
          setshowSnackBar(true);
          setkycStatusCheck(response?.kycStatus);
        }
      }
    };
    handleKycStatus();
  }, []);

  useEffect(() => {
    const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
    const currentLinkedUser: string = AccountManager.getAccountId() || '';
    if (authTokenModel !== null && pathName.includes('viewproperty')) {
      getInvestMentSnakBarStatus(
        currentLinkedUser,
        getPropertyId(pathName),
      ).then((data) => {
        setInvestmentSnak(data);
      });
      getPropertyPageDetails(getPropertyId(pathName)).then((data) => {
        if (
          data?.propertyFinancials?.totalAssetCost -
            data?.propertyInvestment?.totalPropertyFunded <
          2500000
        ) {
          setIsInvestmentAmountEligible(false);
        }
        setPropertyDetails(data);
      });
    }
    if (pathName.includes('viewproperty')) {
      setInPropertyDetailsPage(true);
    } else {
      setInPropertyDetailsPage(false);
    }
  }, [pathName]);

  const updateKyc = () => {
    if (isChannelPartner) {
      window.location.assign(
        customerPortalHostingRoot + '/channel-partner/dashboard#kyc',
      );
    } else {
      window.location.assign(landingPageHostingRoot + '/kyc');
    }
  };

  const redrectToInvestmentPage = () => {
    window.location.assign(
      Environment.getInstance().getParam(
        'NEXT_PUBLIC_CUSTOMER_PORTAL_HOSTING_ROOT',
      ) + `/investment/${getPropertyId(pathName)}?invest`,
    );
  };

  const redirectToContinueInvestmentPage = () => {
    window.location.assign(
      Environment.getInstance().getParam(
        'NEXT_PUBLIC_CUSTOMER_PORTAL_HOSTING_ROOT',
      ) + `/investment/${getPropertyId(pathName)}`,
    );
  };

  const kycStatusSelector = () => {
    switch (kycStatusCheck) {
      case KycStatus.KycNotStarted || KycStatus.KycInProgress:
        return ['Submit your KYC to Invest', 'Submit KYC'];
      case KycStatus.KycSubmitted:
        return ['KYC Submitted', 'Update KYC'];
      case KycStatus.KycUpdated:
        return ['KYC Updated', 'Update KYC'];
      case KycStatus.KycVerificationInProgress:
        return ['KYC Verification In Progress ....', ''];
      case KycStatus.KycVerified:
        return ['KYC Verified', 'View KYC'];
      case KycStatus.KycInvalidated:
        return ['Resubmit your KYC to Invest', 'Resubmit KYC'];
      default:
        return ['Submit your KYC to Invest', 'Submit KYC'];
    }
  };

  const investSnakSelector = () => {
    switch (showInvestmentSnak?.investmentStatus) {
      case InvestmentStage.NotStarted:
        return 'EOI Stage';
      case PrimaryEOI:
        return 'EOI Stage';
      case InvestmentStage.TopUpEOI:
        return 'EOI Stage';
      case InvestmentStage.PAS4:
        return 'PAS4 Stage';
      case InvestmentStage.FullPay:
        return 'Full Pay Stage';
      case InvestmentStage.RegistrationComplete:
        return 'Registration Stage';
      case propertyStatusConstnt?.propertyNotLiveYet:
        return `Property going live in ${showInvestmentSnak?.goingLiveInDays} days`;
      default:
        return 'NotStarted';
    }
  };

  return (
    <div className="snackWrapper">
      {showSnackBar && (
        <div className="snackContentWrapper">
          <div className={'snackContent '}>
            <span>{kycStatusSelector()[0]}</span>
            {!(kycStatusCheck === KycStatus.KycVerificationInProgress) && (
              <button
                data-test="b-submit-kyc-button"
                className={'snackButton'}
                onClick={updateKyc}
              >
                {kycStatusSelector()[1]}
              </button>
            )}
            <div
              className="cross-img dispay-none"
              data-test="div-close-button"
              onClick={() => {
                setshowSnackBar(false);
              }}
            >
              <img src={closeCircle2} alt="X" />
            </div>
          </div>
        </div>
      )}

      {/* dynamic Investment snakbar Please run */}

      {showInvestmentSnak?.investmentStatus !==
        propertyStatusConstnt?.propertyCompletelyFunded &&
        (propertyDetails?.propertyInvestment
          ?.percentagePropertyFundedDisplayedToUser !== 100 ||
          showInvestmentSnak?.investmentStatus !==
            InvestmentStage.NotStarted) &&
        (isinvestmentAmountEligible ||
          showInvestmentSnak?.investmentStatus !==
            InvestmentStage.NotStarted) &&
        kycStatusCheck === KycStatus.KycVerified &&
        !isChannelPartner &&
        inPropertyDetailsPage && (
          <div
            className="snackContentWrapper"
            style={{
              backgroundColor:
                showInvestmentSnak?.investmentStatus === NotStarted
                  ? colors.buttonColor
                  : colors.white,
            }}
          >
            <div className={'snackContent ' + 'alternate'}>
              {showInvestmentSnak?.investmentStatus !== NotStarted ? (
                <div className={'star-img'}>
                  <img src={star} />
                </div>
              ) : (
                <></>
              )}
              <span>
                {showInvestmentSnak?.investmentStatus === NotStarted ? (
                  <>
                    <span>{Strings.startYourInvestmentStatus}</span>
                    <span className="ruppee-symbol">|</span>
                    <span className="ruppee-syumbol-style">₹</span>
                  </>
                ) : (
                  <>
                    <span style={{ paddingLeft: '8px' }}>
                      {showInvestmentSnak?.investmentStatus !==
                        propertyStatusConstnt?.propertyNotLiveYet &&
                        Strings.yourCurrentInvestmentStatus}
                    </span>
                    <span className="ruppee-symbol">
                      {investSnakSelector()}
                    </span>
                  </>
                )}
              </span>
              {showInvestmentSnak?.investmentStatus !==
                propertyStatusConstnt?.propertyNotLiveYet && (
                <button
                  className={'snackButton ' + 'btn-alternate'}
                  style={{
                    marginLeft: '2px',
                    backgroundColor: colors.headerBlue,
                    color: colors.white,
                  }}
                  onClick={() => {
                    if (showInvestmentSnak?.investmentStatus === NotStarted) {
                      redrectToInvestmentPage();
                    } else {
                      redirectToContinueInvestmentPage();
                    }
                  }}
                >
                  {showInvestmentSnak?.investmentStatus === NotStarted
                    ? Strings.investNow
                    : Strings.goThere}
                </button>
              )}
              <div
                className="cross-img dispay-none"
                onClick={() => {
                  setshowSnackBar(false);
                }}
              >
                <img src={closeCircle2} alt="X" />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default SnackBar;
