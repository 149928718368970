import { HTTPMethod, HTTPStatusCode, Strings, fetchAsync } from '@hBits.Core';
import endpoints, { baseApiUrl } from '@hBits.Core/endpoints';
import accountManager from '@hbits.lib/auth/account-manager';

export const addClient = async (newClientPayload: any) => {
  try {
    const request = {
      method: HTTPMethod.POST,
      url: baseApiUrl + endpoints.channelPartners + endpoints.cpCustomer,
      data: newClientPayload,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.NO_CONTENT) {
      return { error: false, errorMessage: null };
    }
    if (response.status === HTTPStatusCode.BAD_REQUEST) {
      return {
        error: true,
        errorMessage: response.error.errorMessage,
      };
    }
    return {
      error: false,
      errorMessage: null,
    };
  } catch (error) {
    return { error: true, errorMessage: Strings.internalServerError };
  }
};

export const getAllClientInformation = async (data: any) => {
  const cpId = accountManager.getChannelPartnerId();
  try {
    const request = {
      method: HTTPMethod.GET,
      params: data,
      url:
        baseApiUrl +
        endpoints.channelPartners +
        endpoints.customer +
        `?channelPartnerId=${cpId}`,
    };
    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return { data: response.data, error: false };
    }
    return { data: [], error: true };
  } catch (error) {
    return { data: [], error: true };
  }
};

export const fetchAccountStatement = async () => {
  const accountId = accountManager.getAccountId() as any;
  try {
    const request = {
      method: HTTPMethod.GET,
      params: {
        fetchAll: true,
      },
      url:
        baseApiUrl +
        endpoints.accountStatement.replace('replaceAccountId', accountId),
    };
    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return { data: response.data, error: false };
    }
    return { data: [], error: true };
  } catch (error) {
    return { data: [], error: true };
  }
};
