import { ChakraProvider, Flex } from '@chakra-ui/react';
import SnackBar from 'libs/src/components/SnackBar/SnackBar';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavbarLinkHash } from '../../screens/Dashboard/DashboardContents';
import { CustomThreeSectionWrapper, PageWrapper } from './styles';

interface CustomThreeSectionLayoutProps {
  background_img: string;
  isChannelPartner?: boolean;
  FirstSection: React.ElementType;
  SecondSection: React.ElementType;
  ThirdSection: React.ElementType;
}

const CustomThreePartition = ({
  background_img,
  FirstSection,
  SecondSection,
  ThirdSection,
  isChannelPartner = false,
}: CustomThreeSectionLayoutProps) => {
  const [controlMainSec, setControlMainSec] = useState<NavbarLinkHash>(
    NavbarLinkHash.POC,
  );
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const tableName: NavbarLinkHash =
      (location.hash as NavbarLinkHash) || NavbarLinkHash.Overview;
    setControlMainSec(tableName);
    navigate(tableName);
  }, []);
  return (
    <ChakraProvider>
      <PageWrapper background={background_img}>
        <Container>
          <Flex pt={130}>
            {isChannelPartner && <SnackBar isChannelPartner={true} />}
          </Flex>
          <CustomThreeSectionWrapper
            direction={['column', 'column', 'column', 'row']}
            align={'start'}
            // pl={[0, 0, 0, '10%']} can be revert back
            // pr={[0, 0, 0, '2%']} can be revert back
            pt={isChannelPartner ? '30px' : '100px'}
          >
            <Flex
              justify={'center'}
              direction={['column', 'row']}
              padding={'0 0.70rem'}
              w={['100%', '100%', '100%', '15%']}
            >
              {!isChannelPartner && (
                <FirstSection controlMainSecFunc={setControlMainSec} />
              )}
            </Flex>
            <Flex
              flex={7}
              w={['100%', '100%', '100%', '45%']}
              mt={[10, 10, 0, 0]}
              minH={'100vh'}
              direction={'column'}
            >
              <SecondSection controlMainSec={controlMainSec} />
            </Flex>
            <Flex
              flex={3}
              w={['100%', '100%', '100%', '30%']}
              mt={[10, 10, 0, 0]}
            >
              {!isChannelPartner && <ThirdSection />}
            </Flex>
          </CustomThreeSectionWrapper>
        </Container>
      </PageWrapper>
    </ChakraProvider>
  );
};

export default CustomThreePartition;
