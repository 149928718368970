export const resources = [
  {
    id: 5,
    text: 'Dashboard',
    url: '/dashboard',
    isTransparent: false,
    button: {
      isDropdown: false,
      value: [],
    },
  },
  {
    id: 1,
    text: 'Properties',
    url: '/properties',
    isTransparent: false,
    button: {
      isDropdown: false,
      value: [],
    },
  },
  {
    id: 6,
    text: 'Invest',
    url: '/customer/dashboard',
    isTransparent: false,
    button: {
      isDropdown: true,
      value: [
        {
          text: 'Fractional Ownership Investment',
          url: '/fractional-ownership-investment',
        },
        {
          text: 'Commercial Real Estate Investment',
          url: '/commercial-real-estate-investment',
        },
        {
          text: 'Preleased Property',
          url: '/preleased-property',
        },
      ],
    },
  },
  {
    id: 7,
    text: 'AIF',
    url: '/alternative-investment-funds-aif',
    isTransparent: true,
    button: {
      isDropdown: false,
      value: [],
    },
  },
  {
    id: 8,
    text: 'NRI',
    url: '/nri-real-estate-investment',
    isTransparent: true,
    button: {
      isDropdown: false,
      value: [],
    },
  },
  {
    id: 2,
    text: 'About Us',
    url: '/about',
    isTransparent: true,
    button: {
      isDropdown: false,
      value: [],
    },
  },
  {
    id: 3,
    text: 'How It Works',
    url: '/how-it-works',
    isTransparent: true,
    button: {
      isDropdown: false,
      value: [],
    },
  },
  {
    id: 4,
    text: 'Contact Us',
    url: '/contact-us',
    isTransparent: true,
    button: {
      isDropdown: false,
      value: [],
    },
  },
];
export const cpResources = [
  {
    id: 5,
    text: 'Dashboard',
    url: '/dashboard',
    isTransparent: false,
    button: {
      isDropdown: false,
      value: [],
    },
  },
];

export const whatsAppLink = 'https://wa.me/9987144647?text=Hi';
