import axios, { AxiosRequestConfig } from 'axios';
import { baseApiUrl } from '../config/endpoints';
import * as Sentry from '@sentry/react';
import AuthTokenStorageProvider from '../auth/auth-token-storage.provider';

/**
 * Create an Axios Client with defaults
 */
export const client = axios.create({
  baseURL: baseApiUrl,
});

export const setupInterceptors = (): void => {
  client.interceptors.request.use(
    (config): AxiosRequestConfig<any> => {
      const rawToken = AuthTokenStorageProvider.getRawAuthToken();
      if (rawToken && config.headers) {
        config.headers['Authorization'] = `Bearer ${rawToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
};

/**
 * Request Wrapper with default success/error actions
 */
export const request = function (options: any) {
  const onSuccess = function (response: any) {
    return response;
  };
  const onError = function (error: any) {
    Sentry.captureException(new Error(error));
    return error.response;
  };
  return client(options).then(onSuccess).catch(onError);
};
