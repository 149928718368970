import { Flex, useToast } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import CustomPanCardTile from '../HelperComponents/CustomPanCardTile';
import CustomPageBottom from '../HelperComponents/CustomPageBottom';
import * as Yup from 'yup';
import FileUpload from '../HelperComponents/FileUpload';
import AddressProofTile from '../HelperComponents/AddressProofTile';
import accountIdManager from 'libs/src/auth/account-manager';
import { useEffect, useState } from 'react';
import styles from '../index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  SetMultipleAddressProofDetails,
  setProofValues,
} from '../HelperComponents/SetMultipleAddressProofDetails';
import {
  addGuardianDetails,
  updateGuardianDetails,
} from 'apps/customer-portal-web/src/services/Kyc/guardianDetails';
import {
  checkMultipleDocumentsErrors,
  getHolderTypeName,
  toastMessage,
} from '../utils';
import { getKycData } from 'apps/customer-portal-web/src/services/Kyc/getKycData';
import { HTTPStatusCode, KycStatus } from '@hBits.Core';
import SelectCountryDropdown from '../../ChannelPartner/helper-components/CountriesDropDown';

export interface GuardianDetailsInt {
  panHolderName: string;
  panNumber: string;
  panIdProofUrl: string;
  panIdProofType: string;
  panRejectionReason: string;
  country: string;
  aadharNumber: string;
  aadharProofFrontUrl: string;
  aadharProofBackUrl: string;
  aadharProofType: string | undefined;
  gstCertificateNumber: string;
  gstCertificateProofUrl: string;
  gstCertificateProofType: string | undefined;
  passportNumber: string;
  passportProofUrl: string;
  passportProofType: string | undefined;
  trcNumber: string;
  trcProofUrl: string;
  trcProofType: string | undefined;
  drivingLicenseNumber: string;
  drivingLicenseProofUrl: string;
  drivingLicenseProofType: string | undefined;
  otherDocumentNumber: string;
  otherDocumentProofUrl: string;
  otherDocumentProofType: string | undefined;
  addressProofTypes: any;
}

interface GuardianDetailsProps {
  holderNumber: number;
}

const GuardianDetails = (props: GuardianDetailsProps) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const selectorState = useSelector((state: any) => state.KycReducer);
  const { holderNumber } = props;
  const [addressProofDetails, setAddressProofDetails] = useState<any>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<any>(['']);
  const [guardianDetailsData, setGuardianDetailsData] = useState<any>();
  const [saveAndContinueClicked, setSaveAndContinueClicked] =
    useState<boolean>(false);
  const [aadharProofBackUrl, setAadharProofBackUrl] = useState<string>();
  const {
    holderId,
    tabValue,
    kycStatusForHolder,
    kycStatusForAccount,
    isNri,
    isAllFieldsDisable,
  } = selectorState;
  useEffect(() => {
    getGuardianDetails();
  }, []);
  const getGuardianDetails = async () => {
    if (guardianDetailsData === undefined) {
      const response = await getKycData(accountIdManager?.getAccountId());
      if (response?.status === HTTPStatusCode.OK) {
        setGuardianDetailsData(
          response?.data?.holders[holderNumber]?.guardianDetails,
        );
      }
    }
  };

  const submitBtnClicked = () => {
    setSaveAndContinueClicked(true);
  };
  const setApiValues = () => {
    const response = guardianDetailsData;
    setAadharProofBackUrl(guardianDetailsData?.aadharProofBackUrl);
    const addressProofTypes = guardianDetailsData?.addressProofTypes;
    if (guardianDetailsData) {
      setAddressProofDetails(
        SetMultipleAddressProofDetails(
          addressProofTypes,
          addressProofDetails,
          response,
          isNri[holderNumber],
          true,
        )?.[0],
      );
      setSelectedDocuments(
        SetMultipleAddressProofDetails(
          addressProofTypes,
          addressProofDetails,
          response,
          isNri[holderNumber],
          true,
        )?.[1],
      );
    }
  };

  useEffect(() => {
    setApiValues();
  }, [guardianDetailsData]);
  const getInitialValues: () => GuardianDetailsInt = () => {
    let initialValues = {
      panHolderName: guardianDetailsData?.panHolderName,
      panNumber: guardianDetailsData?.panNumber,
      panIdProofUrl: guardianDetailsData?.panIdProofUrl,
      panIdProofType: guardianDetailsData?.panIdProofType,
      panRejectionReason: guardianDetailsData?.panRejectionReason,
      country: guardianDetailsData?.country,
      aadharProofBackUrl: '',
      aadharNumber: '',
      aadharProofFrontUrl: '',
      aadharProofType: undefined,
      gstCertificateNumber: '',
      gstCertificateProofUrl: '',
      gstCertificateProofType: undefined,
      passportNumber: '',
      passportProofUrl: '',
      passportProofType: undefined,
      trcNumber: '',
      trcProofUrl: '',
      trcProofType: undefined,
      drivingLicenseNumber: '',
      drivingLicenseProofUrl: '',
      drivingLicenseProofType: undefined,
      otherDocumentNumber: '',
      otherDocumentProofUrl: '',
      otherDocumentProofType: undefined,
      addressProofTypes: [],
      holderType: getHolderTypeName(holderNumber),
    };
    return initialValues;
  };

  const AddGuardianDetailsSchema = Yup.object().shape({
    panNumber: Yup.string()
      .matches(
        /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
        'Pan No format should be ABCDE1234F',
      )
      .required('This is required.'),
    panHolderName: Yup.string()
      .min(3, 'PAN Name entered is too Short!')
      .matches(
        /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
        '(A-Z a-z)letters only allowed',
      )
      .required('This is required.'),
    panIdProofUrl: Yup.string().required('This is required'),
  });

  const checkInvalidatedProofs = (data: any) => {
    if (data?.panRejectionReason != undefined) {
      return false;
    } else {
      if (
        !checkMultipleDocumentsErrors(addressProofDetails, aadharProofBackUrl)
      ) {
        return false;
      }
      return true;
    }
  };

  const onSubmit = async (data: any) => {
    setProofValues(addressProofDetails, data);
    data.aadharProofBackUrl = aadharProofBackUrl;
    const accountId = accountIdManager?.getAccountId();
    if (checkInvalidatedProofs(data)) {
      if (!guardianDetailsData) {
        const addedBasicDetailsResponse = await addGuardianDetails(
          accountId,
          holderId[holderNumber],
          data,
        );
        toastMessage(
          addedBasicDetailsResponse,
          toast,
          tabValue[holderNumber] + 1,
          holderNumber,
          dispatch,
          selectorState,
          kycStatusForHolder[holderNumber] === KycStatus.KycInvalidated
            ? true
            : false,
          kycStatusForAccount === KycStatus.KycInvalidated ? true : false,
        );
      }
      if (guardianDetailsData) {
        const updatedBasicDetailsResponse = await updateGuardianDetails(
          accountId,
          holderId[holderNumber],
          data,
        );
        toastMessage(
          updatedBasicDetailsResponse,
          toast,
          tabValue[holderNumber] + 1,
          holderNumber,
          dispatch,
          selectorState,
          kycStatusForHolder[holderNumber] === KycStatus.KycInvalidated
            ? true
            : false,
          kycStatusForAccount === KycStatus.KycInvalidated ? true : false,
        );
      }
    }
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      validationSchema={AddGuardianDetailsSchema}
      enableReinitialize
    >
      {({
        values,
        errors,
        setFieldTouched,
        setFieldValue,
        handleChange,
        touched,
      }) => (
        <Form>
          <Flex flexDirection={'column'} w={'100%'} flex={1}>
            <CustomPanCardTile
              setFieldTouched={setFieldTouched}
              valuePanNumber={values.panNumber}
              errorPanNumber={errors.panNumber}
              touchedPanNumber={touched.panNumber}
              valuePanName={values.panHolderName}
              errorPanName={errors.panHolderName}
              touchedPanName={touched.panHolderName}
              holderNumber={holderNumber}
              setFieldValue={setFieldValue}
            />
            <FileUpload
              text="*Please upload PAN Card"
              name="panIdProofUrl"
              nameType="panIdProofType"
              rejectedReasonName="panRejectionReason"
              onChange={setFieldValue}
              values={values.panIdProofUrl}
              errors={errors.panIdProofUrl}
              valuesType={values.panIdProofType}
              rejectionReason={values.panRejectionReason}
              holderNumber={holderNumber}
              setFieldTouched={setFieldTouched}
              touched={touched.panIdProofUrl}
              testId='for-guardian-details'
            />
            <Flex
              flex={1}
              direction={'column'}
              mt={{ base: 2, md: 8 }}
              w={ {base: '100%', md: '50%'}}
              pr={{ base: 0, md: 3 }}
            >
              <SelectCountryDropdown
                selectedCountry={values.country}
                setSelectedCountry={handleChange('country')}
                isColorGrey={true}
                isKyc={true}
                isDisable={isAllFieldsDisable[holderNumber]}
              />
              {errors.country && saveAndContinueClicked && (
                <div className={styles['errorRegMessage']}>
                  {errors.country.toString()}
                </div>
              )}
            </Flex>
            <AddressProofTile
              addressProofDetails={addressProofDetails}
              setAddressProofDetails={setAddressProofDetails}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              holderNumber={holderNumber}
              saveAndContinueClicked={saveAndContinueClicked}
              setAadharProofBackUrl={setAadharProofBackUrl}
              aadharProofBackUrl={aadharProofBackUrl}
              dataTestid='guardian-details'
              testId='for-guardian-details'
            />
            <CustomPageBottom
              holderNumber={holderNumber}
              btnClicked={submitBtnClicked}
            />
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default GuardianDetails;
