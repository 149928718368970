import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react';
import {
  AccountManager,
  Colors,
  EOIPaymentStatus,
  Environment,
  HTTPMethod,
  HTTPStatusCode,
  InvestmentStage,
  KycStatus,
  Strings,
  authTokenStorageProvider,
} from '@hBits.Core';
import colors from 'libs/src/theme/colors';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CustomStepper from '../../components/CustomStepper';
import DownloadWizid from '../../components/DownloadWizid';
import FullPageLoader from '../../components/FullPageLoader';
import { PropertyInvestmentStep } from '../../enums/propertyInvestmentEnum';
import { getInvestMentStatus } from '../../services';
import { updateInvestmentAmount } from '../../services/propertyInvestment/eoiStepServices';
import {
  setDownloadFileUrl,
  setInvestmentDetails,
  setInvestmentPropertyDetails,
  setLoader,
} from '../../store/actions';
import { getKycDataFunc } from '../Kyc/utils';
import AbandonedInvestmemnt from './AbandonedInvestment';
import EoiStep from './EOIStep';
import FullPay from './FullPay';
import PasStep from './PASStep';
import Registration from './Registration';
import './propertyInvestment.scss';
import stepKeys from './stepperSteps';
import { extractDownloadFile, getInvestmentStep, getLocation } from './util';

const PropertyInvestment: FC<{}> = () => {
  const {
    EOI: eoiStep,
    PAS4: pasStep,
    REGESTRATION: regestrationStep,
    DASHBOARD: dashboardStep,
  } = PropertyInvestmentStep;
  const [searchParams] = useSearchParams();
  const [notValidinvestment, setNotValidInvestmemt] = useState<string>('');
  const dispatch = useDispatch();
  const { propertyId = '' } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const investmentModal = useSelector(
    (state: any) => state.InvestmentReducer?.propertyData,
  );

  // for kyc
  const landingPageHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  useEffect(() => {
    const authToken = authTokenStorageProvider.getRawAuthToken();

    if (authToken) {
      const getKycStatus = async () => {
        const resp = await getKycDataFunc();
        const kycStatus = resp?.data.kycStatus;

        if (kycStatus !== KycStatus.KycVerified) {
          window.location.href = `${landingPageHostingRoot}/kyc`;
        }
        return resp;
      };
      getKycStatus();
    }
  }, []);

  //const bankAccountUsedId = searchParams.get('bank');
  const startInvestmentFlag = searchParams.has('invest');

  const { investmentAmountEoi, topUpAmountEoi, fullPay } = investmentModal;
  const mainLoader = useSelector(
    (state: any) => state.InvestmentReducer?.loader,
  );
  const propertyDetails = useSelector(
    (state: any) => state.InvestmentReducer?.propertyDetails || {},
  );
  const { propertyMediaModel } = propertyDetails;

  const firstInvestmentRender = async (count: number) => {
    const currentLinkedUser: any = AccountManager.getAccountId();
    // fetching property details ...
    const data = {};
    const requestType =
      startInvestmentFlag && count === 0 ? HTTPMethod.POST : HTTPMethod.GET;
    const investmentDetails = await getInvestMentStatus(
      currentLinkedUser,
      propertyId,
      data,
      requestType,
    );

    //removing ?invest params from url after creating a successful investment
    if (
      investmentDetails?.error?.errorMessage ===
      'Investment has already started'
    ) {
      const newQueryParams = {};
      const queryString = new URLSearchParams(newQueryParams).toString();
      const currentPathname = location.pathname;
      navigate(`${currentPathname}?${queryString}`, { replace: true });
    }

    dispatch(setInvestmentPropertyDetails(investmentDetails?.propertyDetails));
    // first investmentUpdate with given minTicketSizeApplied.
    if (investmentDetails?.investmentStage === InvestmentStage.NotStarted) {
      const updatedInvestmentDetails = await updateInvestmentAmount(
        investmentDetails?.id,
        investmentDetails?.minTicketSizeApplied,
      );
      dispatch(setInvestmentDetails(updatedInvestmentDetails));
    } else {
      dispatch(setInvestmentDetails(investmentDetails));
    }

    // handling ABANDONED investments
    if (investmentDetails.status !== HTTPStatusCode.OK) {
      await setNotValidInvestmemt(investmentDetails?.error?.additionalDetails);
    }
    setTimeout(() => {
      dispatch(setLoader(false));
      if (count === 0) {
        firstInvestmentRender(1);
      }
    }, 500);
  };

  useEffect(() => {
    const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
    if (authTokenModel !== null) {
      firstInvestmentRender(0);
    }
  }, [startInvestmentFlag]);

  const documentNotSigned = (error: string) => {
    toast({
      title: 'Error',
      description: error,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };
  useEffect(() => {
    // adding download slips to download Wizid.
    dispatch(setDownloadFileUrl([]));
    dispatch(setDownloadFileUrl(extractDownloadFile(investmentModal)));

    // check for EOI doc failure
    if (investmentAmountEoi?.eoiDocumentSigningStatus === 'Error') {
      documentNotSigned('EOI Document Signing Failed Please Re-Sign');
    }

    // check for TopUp EOI doc failure
    if (topUpAmountEoi?.eoiDocumentSigningStatus === 'Error') {
      documentNotSigned('TopUp EOI Document Signing Failed Please Re-Sign');
    }

    // EOI 5% payment failure
    if (
      investmentAmountEoi?.eoiPaymentStatus ===
      EOIPaymentStatus.TransactionFailed
    ) {
      documentNotSigned('EOI Payment Failed Please Re-Pay');
    }

    // EOI 5% TopUP payment failure
    if (
      topUpAmountEoi?.eoiPaymentStatus === EOIPaymentStatus.TransactionFailed
    ) {
      documentNotSigned('TopUp EOI Payment Failed Please Re-Pay');
    }
  }, [investmentModal]);

  const renderCurrentStep = () => {
    switch (getInvestmentStep(investmentModal?.investmentStage, fullPay)) {
      case eoiStep:
        return <EoiStep />;
      case pasStep:
        return <PasStep />;
      case regestrationStep:
        return <FullPay />;
      case dashboardStep:
        return <Registration />;
    }
    return <></>;
  };

  const renderTitle = () => {
    switch (getInvestmentStep(investmentModal?.investmentStage, fullPay)) {
      case eoiStep:
        return Strings.expressInterest;
      case pasStep:
        return Strings.pas4DocumentToBeSigned;
      case regestrationStep:
        return Strings.fullPayment;
      case dashboardStep:
        return Strings.registration;
    }
    return <></>;
  };

  const redirectToPropertyPage = () => {
    const landingPagesHostingRoot = Environment.getInstance().getParam(
      'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
    );
    window.location.assign(
      landingPagesHostingRoot +
        `/viewproperty/${getLocation(
          propertyDetails?.locations || [],
        )}/${propertyId}`,
    );
  };

  const isProduction =
    Environment.getInstance().getParam('NEXT_PUBLIC_ENV_NAME') === 'Production';

  return (
    <html>
      <head>
        <script src="https://code.jquery.com/jquery-latest.min.js"></script>
        {isProduction ? (
          <>
            <script
              type="module"
              src="https://pay.billdesk.com/jssdk/v1/dist/billdesksdk/billdesksdk.esm.js"
            ></script>
            <script
              noModule
              src="https://pay.billdesk.com/jssdk/v1/dist/billdesksdk.js"
            ></script>
            <link
              href="https://pay.billdesk.com/jssdk/v1/dist/billdesksdk/billdesksdk.css"
              rel="stylesheet"
            />
          </>
        ) : (
          <>
            <script
              type="module"
              src="https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js"
            ></script>
            <script
              noModule={undefined}
              src="https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js"
            ></script>
            <link
              href="https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.css"
              rel="stylesheet"
            ></link>
          </>
        )}
      </head>
      <body>
        <>
          {mainLoader ? (
            <Flex height={'100vh'}>
              <FullPageLoader />
            </Flex>
          ) : (
            <Box backgroundColor={Colors.bgDark} pt={'120px'}>
              {notValidinvestment && notValidinvestment.length > 0 ? (
                <Flex
                  height={'70vh'}
                  justifyContent="center"
                  alignItems={'center'}
                >
                  <AbandonedInvestmemnt
                    errorMsg={notValidinvestment}
                    isOpen={notValidinvestment.length > 0}
                  />
                </Flex>
              ) : (
                <>
                  <DownloadWizid />
                  <Flex
                    justify="center"
                    h="80px"
                    borderBottomColor={colors.buttonColor}
                    borderBottomWidth="1px"
                    paddingBottom={'10px'}
                  >
                    <CustomStepper
                      stepKeys={stepKeys}
                      currentStep={getInvestmentStep(
                        investmentModal?.investmentStage,
                        fullPay,
                      )}
                    />
                  </Flex>
                  <Flex
                    justify="center"
                    pt="50px"
                    pb="150px"
                    className="dynamic-compt-container"
                  >
                    <Flex
                      maxWidth={['90%', '90%', '80%', '75%']}
                      backgroundColor={'white'}
                      borderRadius={10}
                      paddingLeft={['25px', '25px', '50px', '50px']}
                      paddingRight={['25px', '25px', '50px', '50px']}
                      py={5}
                      flexDirection="column"
                      className="box-shadow-contsiner"
                    >
                      <Box>
                        <Text
                          className="headingName"
                          color={'#1a295b'}
                          fontSize={'20px'}
                          mt={'10px'}
                        >
                          {renderTitle()}
                        </Text>
                      </Box>
                      <Flex
                        flexDirection={['column', 'column', 'row', 'row']}
                        mt={'40px'}
                        justifyContent="center"
                        mb={'40px'}
                      >
                        <Flex
                          width={['100%', '100%', '80%', '80%']}
                          direction={['row', 'row', 'column', 'column']}
                        >
                          <Flex flexDirection={'column'} w={{ md: '100%' }}>
                            <Text
                              as={'b'}
                              fontSize={'16px'}
                              mb={'5px'}
                              className={'propertyName'}
                            >
                              {propertyDetails && propertyDetails?.displayName}
                            </Text>
                            <Image
                              height={{ base: '130px', md: '150px' }}
                              width={{ base: '100%', md: '80%' }}
                              objectFit="cover"
                              src={
                                propertyMediaModel &&
                                propertyMediaModel?.desktopBannerUrl[0]
                                  ?.imageUrl
                                  ? propertyMediaModel?.desktopBannerUrl[0]
                                      ?.imageUrl
                                  : propertyMediaModel?.desktopBannerUrl[0]
                              }
                              alt="property image"
                              borderRadius={'10px'}
                              marginTop={'10px'}
                              onClick={() => {
                                redirectToPropertyPage();
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                          </Flex>
                          <Flex
                            justifyContent={{
                              base: 'space-between',
                              md: 'space-between',
                            }}
                            marginTop={'10px'}
                            w={{ base: '50%', md: '80%' }}
                            direction={['column', 'column', 'row', 'row']}
                            ml={{ base: '20px', md: '0px' }}
                          >
                            <Flex
                              direction={'column'}
                              mt={{ base: '20px', md: '0px' }}
                            >
                              <Text fontSize="12px" color={'#535353'}>
                                Location
                              </Text>
                              <Text fontSize="13px" as="b" color={'#535353'}>
                                {getLocation(propertyDetails?.locations || [])}
                              </Text>
                            </Flex>
                            <Flex
                              alignItems={'flex-end'}
                              onClick={() => {
                                redirectToPropertyPage();
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <Text
                                as="b"
                                color={'#1a295b'}
                                className="viewDetailsText"
                              >
                                View Details
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex
                          width={['100%', '100%', '100%', '100%']}
                          direction={'column'}
                          // px={'2%'}
                          justifyContent="center"
                        >
                          {renderCurrentStep()}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              )}
            </Box>
          )}
        </>
      </body>
    </html>
  );
};

export default PropertyInvestment;
