import { Select } from '@chakra-ui/react';
import { Colors, Strings } from '@hBits.Core';
import * as React from 'react';

interface SelectDropdownProps {
  name: string;
  values: string[];
  value: string | undefined;
  placeholder: string;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

/**
 * @param {Object} props
 * @returns {JSX.Element} - A React Functional Component
 */
const SelectDropdown: React.FC<SelectDropdownProps> = ({
  name,
  values,
  value,
  placeholder,
  handleChange,
}): JSX.Element => {
  return (
    <Select
      width={'100%'}
      height={'40px'}
      fontSize={'16px'}
      borderRadius={0}
      borderColor={Colors.greyBorderColor}
      value={value}
      onChange={handleChange}
      placeholder={`*${placeholder}`}
      variant="flushed"
    >
      {values.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
      ))}
    </Select>
  );
};

export default SelectDropdown;
