import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { authTokenStorageProvider, Colors, Strings } from '@hBits.Core';
import { eventEmitter } from 'apps/landing-pages-web/src/components/events';
import { TOAST_TIMEOUT } from 'libs/src/constants';
import { deleteChannelPartner } from 'libs/src/services/modules/channel-partner';
import { useContext, useEffect, useState } from 'react';
import styles from '../styles/index.module.scss';
import { teamMembersContext } from '../TeamMembers';
import { TeamMemberType } from '../types/teamMember.type';
import circleLogo from '../../../../components/Kyc/resources/circleLogo.svg';
import deleteIcon from '../../../../assets/my_team/delete-outline.svg';

type TeamMemberProps = {
  name: string;
  email: string;
  membersAccountId: string;
};

interface AddFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  teamMember: TeamMemberProps;
}

const DeleteTeamMemberModel = ({
  isOpen,
  onClose,
  teamMember,
}: AddFormModalProps) => {
  const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([]);
  const [memberToTransferTheLeadsTo, setMemberToTransferTheLeadsTo] = useState({
    name: '',
    accountId: '',
  });
  const toast = useToast();

  const teamMemberContext = useContext(teamMembersContext);

  const filterTeamMembers = () => {
    const filteredTeamMembers = teamMemberContext?.filter(
      (member: TeamMemberType) =>
        member.accountId !== teamMember.membersAccountId,
    );
    setTeamMembers([...filteredTeamMembers]);
    const loggedInUser = authTokenStorageProvider.getAuthTokenModel();
    if (loggedInUser) {
      const loggedInMember: TeamMemberType = {
        firstName: loggedInUser.firstName,
        lastName: loggedInUser.lastName + ' (You)',
        accountId: loggedInUser.accountId,
        post: '',
        emailAddress: '',
        mobileCountryCode: '',
        mobileNumber: '',
        numberOfClients: 0,
        investmentValue: 0,
      };
      setTeamMembers((prev) => [loggedInMember, ...prev]);
    }
  };

  const handleDelete = async () => {
    const accountIdOfMemberTobeDeleted = teamMember.membersAccountId;
    const accountIdToTransferTheLeadsTo = memberToTransferTheLeadsTo.accountId;
    if (!accountIdOfMemberTobeDeleted || !accountIdToTransferTheLeadsTo) {
      toast({
        title: 'Error',
        description: 'Please Choose a Team Member',
        status: 'error',
        duration: TOAST_TIMEOUT,
        isClosable: true,
      });
      return;
    }
    const isMemberDeleted: boolean = await deleteChannelPartner(
      accountIdOfMemberTobeDeleted,
      accountIdToTransferTheLeadsTo,
    );
    if (isMemberDeleted) {
      toast({
        title: Strings.success,
        description: Strings.deletedMember,
        status: 'success',
        duration: TOAST_TIMEOUT,
        isClosable: true,
      });
      eventEmitter.dispatch('teamMemberUpdated');
      onClose();
      return;
    }
    toast({
      title: Strings.failed,
      description: Strings.internalServerError,
      status: 'error',
      duration: TOAST_TIMEOUT,
      isClosable: true,
    });
  };

  useEffect(() => {
    filterTeamMembers();
  }, [teamMemberContext]);

  return (
    <Modal
      size={['md', 'lg']}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent padding={['25px 32px', '25px 20px']}>
        <ModalHeader
          backgroundColor={Colors.white}
          color={Colors.blackColor}
          fontSize={['25px', '32px']}
          className="text-center"
        >
          Delete Team Member
        </ModalHeader>
        <ModalCloseButton marginRight={'1rem'} marginTop={'1rem'}>
          <img src={circleLogo}></img>
        </ModalCloseButton>
        <ModalBody>
          <Flex direction={'column'} gap={['2rem', '3.5rem']} align={'center'}>
            <Flex
              maxWidth={'300px'}
              direction={'column'}
              gap={['2rem', '3.5rem']}
            >
              <Text
                px={'0.5rem'}
                align={'center'}
                fontSize={['16px', '20px']}
                fontWeight={'medium'}
                color={Colors.blackColor}
              >
                Are you sure you want to delete {teamMember.name}'s profile?
              </Text>
              <Flex px={'0.5rem'} direction={'column'} gap={'1rem'}>
                <Text
                  align={'center'}
                  color={Colors.darkRed}
                  fontSize={['1rem', '1.1rem']}
                >
                  *Transfer {teamMember.name}'s Clientele to
                </Text>
                <Menu>
                  <MenuButton
                    as={Button}
                    textAlign={'left'}
                    rightIcon={<i className="fa-solid fa-chevron-down"></i>}
                    fontSize={['1rem', '1.1rem']}
                    color={Colors.bgDarkBlue}
                    bg={Colors.grey500}
                    _hover={{
                      bg: Colors.grey500,
                    }}
                    _active={{
                      bg: Colors.grey500,
                    }}
                  >
                    {memberToTransferTheLeadsTo.accountId === ''
                      ? 'Choose a Team Member'
                      : memberToTransferTheLeadsTo.name}
                  </MenuButton>
                  <MenuList
                    width={'244px'}
                    maxHeight={'250px'}
                    overflow={'auto'}
                  >
                    {teamMembers.map((teamMember) => (
                      <MenuItem
                        minH="48px"
                        key={teamMember.accountId}
                        onClick={() => {
                          setMemberToTransferTheLeadsTo({
                            name: `${teamMember.firstName} ${teamMember.lastName}`,
                            accountId: teamMember.accountId,
                          });
                        }}
                      >
                        <span>{`${teamMember.firstName} ${teamMember.lastName}`}</span>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
            <Flex
              direction={['column', 'row']}
              justify={'space-between'}
              gap={'1rem'}
              align={'center'}
              maxWidth={'400px'}
            >
              <Button
                padding={'1rem'}
                height={'50px'}
                width={'200px'}
                variant="outline"
                fontWeight={'bold'}
                onClick={handleDelete}
                fontSize={['0.9rem', '1rem']}
                color={Colors.blackColor}
                flex={'1'}
                _hover={{
                  bg: Colors.white,
                }}
                _active={{
                  bg: Colors.white,
                }}
              >
                TRANSFER & DELETE
              </Button>
              <Button
                padding={'1rem'}
                height={'50px'}
                width={'200px'}
                bgColor={Colors.orangeColor}
                color={Colors.blackColor}
                fontWeight={'bold'}
                onClick={onClose}
                fontSize={['0.9rem', '1rem']}
                flex={'1'}
                _hover={{
                  bg: Colors.orangeColor,
                }}
                _active={{
                  bg: Colors.orangeColor,
                }}
              >
                NO, CANCEL
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const DeleteTeamMeberButton = (teamMember: TeamMemberProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Flex as={'button'}>
        <img src={deleteIcon} onClick={() => setIsOpen(true)} />
      </Flex>
      <DeleteTeamMemberModel
        teamMember={teamMember}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default DeleteTeamMeberButton;
