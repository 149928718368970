import { useToast } from '@chakra-ui/react';
import { Environment } from '@hBits.Core';
import UploadProof from 'apps/customer-portal-web/src/components/Kyc/HelperComponents/UploadProof';
import accountIdManager from 'libs/src/auth/account-manager';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { KycStatus } from '../../../../../../../../../libs/src/enums/customer-kyc.enum';
import { capitalizeEachLetter } from '../../../../../../utils/index';
import { addGstDetails, getData, updateGstDetails } from '../ApiData/apiData';
import CpKycContainerBottomTile from '../HelperComponents/CpKycContainerBottomTile';
import '../index.scss';

type FormInputs = {
  gstNumber: string;
  documentSelected: any;
  sebiNumber: any;
  euinNumber: any;
  arnNumber: any;
  reraNumber: any;
  otherNumber: any;
};

interface GstDetailsData {
  gstNumber?: string;
  gstProofUrl?: string;
  gstProofType?: string;
  arnNumber?: any;
  euinNumber?: any;
  expiryDate?: any;
  amfProofUrl?: any;
  amfProofType?: any;
  sebiNumber?: any;
  sebiProofUrl?: any;
  sebiProofType?: any;
  reraNumber?: any;
  reraProofUrl?: any;
  reraProofType?: any;
  otherNumber?: any;
  otherProofUrl?: any;
  otherProofType?: any;
}
interface GstOthersDetailsProps {
  kycVerified: boolean;
  checkKycStatus: () => void;
  accountStatus: any;
  setShowIsSubmittedPage: any;
}

function GstOthersDetails(props: GstOthersDetailsProps) {
  const { kycVerified, checkKycStatus, accountStatus, setShowIsSubmittedPage } =
    props;
  const [gstProofUrl, setGstProofUrl] = useState('');
  const [gstProofType, setGstProofType] = useState<any>();
  const [errorGstStatusRejected, setErrorGstStatusRejected] = useState(false);
  const [errorGstStatusRejectedMessage, setErrorGstStatusRejectedMessage] =
    useState('');

  const [isAMFI, setIsAMFI] = useState(false);
  const [isSEBI, setIsSEBI] = useState(false);
  const [isRERA, setIsRERA] = useState(false);
  const [isOthers, setIsOthers] = useState(false);
  const [date, setDate] = useState<any>();
  const [amfProofUrl, setAmfProofUrl] = useState('');
  const [amfProofType, setAmfProofType] = useState<any>();
  const [errorAmfStatusRejected, setErrorAmfStatusRejected] = useState(false);
  const [errorAmfStatusRejectedMessage, setErrorAmfStatusRejectedMessage] =
    useState('');
  const [sebiProofUrl, setSebiProofUrl] = useState('');
  const [sebiProofType, setSebiProofType] = useState<any>();
  const [errorSebiStatusRejected, setErrorSebiStatusRejected] = useState(false);
  const [errorSebiStatusRejectedMessage, setErrorSebiStatusRejectedMessage] =
    useState('');
  const [reraProofUrl, setReraProofUrl] = useState('');
  const [reraProofType, setReraProofType] = useState<any>();
  const [errorReraStatusRejected, setErrorReraStatusRejected] = useState(false);
  const [errorReraStatusRejectedMessage, setErrorReraStatusRejectedMessage] =
    useState('');
  const [otherProofUrl, setOtherProofUrl] = useState('');
  const [otherProofType, setOtherProofType] = useState<any>();
  const [errorOtherStatusRejected, setErrorOtherStatusRejected] =
    useState(false);
  const [errorOtherStatusRejectedMessage, setErrorOtherStatusRejectedMessage] =
    useState('');
  const [isSaveAndContinueClicked, setIsSaveAndContinueClicked] =
    useState(false);
  const [gstDetailsHolderData, setGstDetailsHolderData] = useState<any>();
  const toast = useToast();
  const [userAccountId, setUserAccountId] = useState<string>();
  const [selectedDocument, setSelectedDocument] = useState<string>();

  // const customerPortalHostingRoot = Environment.getInstance().getParam(
  //   'NEXT_PUBLIC_CUSTOMER_PORTAL_HOSTING_ROOT',
  // );

  //new CP V3
  const newCustomerPortalHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_NEW_CUSTOMER_PORTAL_HOSTING_ROOT',
  );

  const {
    reset,
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
  } = useForm<FormInputs>();

  const presentDocument = (value: any) => {
    reset({
      arnNumber: '',
      euinNumber: '',
      reraNumber: '',
      otherNumber: '',
      sebiNumber: '',
      gstNumber: watch().gstNumber,
    });
    setSelectedDocument(value);
    switch (value) {
      case 'AMFI':
        setIsAMFI(true);
        setIsSEBI(false);
        setSebiProofUrl('');
        setSebiProofType(undefined);
        setIsRERA(false);
        setReraProofUrl('');
        setReraProofType(undefined);
        setIsOthers(false);
        setOtherProofUrl('');
        setOtherProofType(undefined);
        break;
      case 'SEBI':
        setIsAMFI(false);
        setAmfProofType(undefined);
        setAmfProofUrl('');
        setIsSEBI(true);
        setIsRERA(false);
        setReraProofUrl('');
        setReraProofType(undefined);
        setIsOthers(false);
        setOtherProofUrl('');
        setOtherProofType(undefined);
        break;
      case 'RERA':
        setIsAMFI(false);
        setAmfProofType(undefined);
        setAmfProofUrl('');
        setIsSEBI(false);
        setSebiProofUrl('');
        setSebiProofType(undefined);
        resetField(watch().sebiNumber);
        setIsRERA(true);
        setIsOthers(false);
        setOtherProofUrl('');
        setOtherProofType(undefined);
        break;
      case 'Others':
        setIsAMFI(false);
        setAmfProofType(undefined);
        setAmfProofUrl('');
        setIsSEBI(false);
        setSebiProofUrl('');
        setSebiProofType(undefined);
        resetField(watch().sebiNumber);
        setIsRERA(false);
        setReraProofUrl('');
        setReraProofType(undefined);
        setIsOthers(true);
        break;
      default:
        setIsAMFI(false);
        setIsSEBI(false);
        setSebiProofUrl('');
        setSebiProofType(undefined);
        setIsRERA(false);
        setReraProofUrl('');
        setReraProofType(undefined);
        setIsOthers(false);
        setOtherProofUrl('');
        setOtherProofType(undefined);
        break;
    }
  };

  const saveAndContinue = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setShowIsSubmittedPage(true);
    checkKycStatus();
    if (accountStatus == KycStatus.KycUpdated) {
      window.location.assign(newCustomerPortalHostingRoot + '/dashboard');
    }
  };

  React.useEffect(() => {
    //calling API
    if (!accountIdManager?.getAccountId()) return;
    setUserAccountId(accountIdManager?.getAccountId());
    getData(accountIdManager?.getAccountId()).then((res: any) => {
      if (res.channelPartnerOtherKycDetails) {
        if (res.channelPartnerOtherKycDetails.expiryDate)
          setDate(new Date(res.channelPartnerOtherKycDetails.expiryDate));
        if (res.channelPartnerOtherKycDetails.gstProofUrl) {
          setGstProofType(res.channelPartnerOtherKycDetails.gstProofType);
          setGstProofUrl(res.channelPartnerOtherKycDetails.gstProofUrl);
          if (
            res.channelPartnerOtherKycDetails.gstVerificationStatus ==
            'Rejected'
          ) {
            setErrorGstStatusRejected(true);
            setErrorGstStatusRejectedMessage(
              res.channelPartnerOtherKycDetails.gstRejectionReason,
            );
          }
        }
        if (res.channelPartnerOtherKycDetails.amfProofUrl) {
          presentDocument('AMFI');
          setAmfProofType(res.channelPartnerOtherKycDetails.amfProofType);
          setAmfProofUrl(res.channelPartnerOtherKycDetails.amfProofUrl);
          if (
            res.channelPartnerOtherKycDetails.amfVerificationStatus ==
            'Rejected'
          ) {
            setErrorAmfStatusRejected(true);
            setErrorAmfStatusRejectedMessage(
              res.channelPartnerOtherKycDetails.amfRejectionReason,
            );
          }
        } else if (res.channelPartnerOtherKycDetails.sebiProofUrl) {
          presentDocument('SEBI');
          setSebiProofType(res.channelPartnerOtherKycDetails.sebiProofType);
          setSebiProofUrl(res.channelPartnerOtherKycDetails.sebiProofUrl);
          if (
            res.channelPartnerOtherKycDetails.sebiVerificationStatus ==
            'Rejected'
          ) {
            setErrorSebiStatusRejected(true);
            setErrorSebiStatusRejectedMessage(
              res.channelPartnerOtherKycDetails.sebiRejectionReason,
            );
          }
        } else if (res.channelPartnerOtherKycDetails.reraProofUrl) {
          presentDocument('RERA');
          setReraProofType(res.channelPartnerOtherKycDetails.reraProofType);
          setReraProofUrl(res.channelPartnerOtherKycDetails.reraProofUrl);
          if (
            res.channelPartnerOtherKycDetails.reraVerificationStatus ==
            'Rejected'
          ) {
            setErrorReraStatusRejected(true);
            setErrorReraStatusRejectedMessage(
              res.channelPartnerOtherKycDetails.reraRejectionReason,
            );
          }
        } else if (res.channelPartnerOtherKycDetails.otherProofUrl) {
          presentDocument('Others');
          setOtherProofType(res.channelPartnerOtherKycDetails.otherProofType);
          setOtherProofUrl(res.channelPartnerOtherKycDetails.otherProofUrl);
          if (
            res.channelPartnerOtherKycDetails.otherVerificationStatus ==
            'Rejected'
          ) {
            setErrorOtherStatusRejected(true);
            setErrorOtherStatusRejectedMessage(
              res.channelPartnerOtherKycDetails.otherRejectionReason,
            );
          }
        }

        reset({
          gstNumber: res.channelPartnerOtherKycDetails.gstNumber,
          arnNumber: res.channelPartnerOtherKycDetails.arnNumber,
          euinNumber: res.channelPartnerOtherKycDetails.euinNumber,
          sebiNumber: res.channelPartnerOtherKycDetails.sebiNumber,
          reraNumber: res.channelPartnerOtherKycDetails.reraNumber,
          otherNumber: res.channelPartnerOtherKycDetails.otherNumber,
        });
        setGstDetailsHolderData(res.channelPartnerOtherKycDetails);
      }
    });
  }, []);

  const handleSubmitButton = () => {
    setIsSaveAndContinueClicked(true);
  };

  const onSubmit = (formData: any) => {
    const gstDetailsData: GstDetailsData = {
      gstNumber: formData.gstNumber,
      gstProofUrl: gstProofUrl,
      gstProofType: gstProofType,
      arnNumber: formData.arnNumber,
      euinNumber: formData.euinNumber,
      expiryDate: date,
      amfProofUrl: amfProofUrl,
      amfProofType: amfProofType,
      sebiNumber: formData.sebiNumber,
      sebiProofUrl: sebiProofUrl,
      sebiProofType: sebiProofType,
      reraNumber: formData.reraNumber,
      reraProofUrl: reraProofUrl,
      reraProofType: reraProofType,
      otherNumber: formData.otherNumber,
      otherProofUrl: otherProofUrl,
      otherProofType: otherProofType,
    };
    if (!gstProofUrl) {
      delete gstDetailsData['gstNumber'];
      delete gstDetailsData['gstProofUrl'];
      delete gstDetailsData['gstProofType'];
    }
    if (
      gstProofUrl ||
      amfProofUrl ||
      sebiProofUrl ||
      reraProofUrl ||
      otherProofUrl
    ) {
      if (
        !errorAmfStatusRejected &&
        !errorGstStatusRejected &&
        !errorOtherStatusRejected &&
        !errorReraStatusRejected &&
        !errorSebiStatusRejected
      ) {
        {
          !gstDetailsHolderData &&
            addGstDetails(userAccountId, gstDetailsData).then((response) => {
              if (response.status === 200 || response.status === 204) {
                toast({
                  title: 'Success',
                  description: 'Details have been Submitted',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
                saveAndContinue();
              } else {
                alert(response.error.errorMessage);
              }
            });
        }
        {
          gstDetailsHolderData &&
            updateGstDetails(userAccountId, gstDetailsData).then((response) => {
              if (response.status === 200 || response.status === 204) {
                toast({
                  title: 'Success',
                  description: 'Details have been Updated',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
                saveAndContinue();
              } else {
                alert(response.error.errorMessage);
              }
            });
        }
      }
    } else {
      saveAndContinue();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="cpkyc_row cpkyc_margin-top-2 ">
          <div className="cpkyc_col-12 cpkyc_col-md-6">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="GST Number"
                disabled={kycVerified}
                value={capitalizeEachLetter(watch().gstNumber)}
                {...register('gstNumber', {
                  pattern: {
                    value:
                      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    message: 'GST Number format should be 12ABCDE1234A2Z9',
                  },
                })}
              />
            </div>
            {errors.gstNumber ? (
              <>
                {errors.gstNumber.type === 'required' && (
                  <p className="cpkyc_errorMessage">
                    {errors.gstNumber.message}
                  </p>
                )}
                {errors.gstNumber.type === 'pattern' && (
                  <p className="cpkyc_errorMessage">
                    {errors.gstNumber.message}
                  </p>
                )}
              </>
            ) : null}
          </div>
        </div>
        <UploadProof
          textForPTag="Please upload GST Document"
          urlArgs="gstProofUrl"
          resetProofUrl={gstProofUrl}
          setresetProofUrl={setGstProofUrl}
          proofType={gstProofType}
          setProofType={setGstProofType}
          errorStatusRejected={errorGstStatusRejected}
          errorStatusRejectedMessage={errorGstStatusRejectedMessage}
          setErrorStatusRejected={setErrorGstStatusRejected}
          setErrorStatusRejectedMessage={setErrorGstStatusRejectedMessage}
          accountKycVerified={kycVerified}
          id="pan"
          isSaveAndContinueClicked={isSaveAndContinueClicked}
          isMandatory={false}
        />
        <div className="cpkyc_row cpkyc_margin-top-2 ">
          <div className="cpkyc_col-12 cpkyc_col-md-6">
            <div className="cpkyc_bottom-line">
              <select
                className="cpkyc_"
                {...register('documentSelected', {
                  required: false,
                })}
                disabled={kycVerified}
                value={selectedDocument}
                onChange={(e: any) => presentDocument(e.target.value)}
              >
                <option className="cpkyc_" value="" disabled selected hidden>
                  Select Other Document
                </option>
                <option className="cpkyc_ "></option>
                <option className="cpkyc_" value="AMFI">
                  AMFI
                </option>
                <option className="cpkyc_" value="SEBI">
                  SEBI
                </option>
                <option className="cpkyc_" value="RERA">
                  RERA
                </option>
                <option className="cpkyc_" value="Others">
                  Others
                </option>
              </select>
            </div>
          </div>
        </div>

        {isAMFI && (
          <>
            <div className="cpkyc_row cpkyc_margin-top-2 cpkyc_margin-top-1_for-mobile ">
              <div className="cpkyc_col-12 cpkyc_col-md-4">
                <div className="cpkyc_bottom-line">
                  <input
                    type="text"
                    className="cpkyc_"
                    placeholder="*ARN Number"
                    disabled={kycVerified}
                    value={capitalizeEachLetter(watch().arnNumber)}
                    {...register('arnNumber', {
                      required: true,
                    })}
                  />
                </div>
                {errors.arnNumber ? (
                  <>
                    {errors.arnNumber.type === 'required' && (
                      <p className="cpkyc_errorMessage">
                        ARN Number is required
                      </p>
                    )}
                  </>
                ) : null}
              </div>
              <div className="cpkyc_col-12 cpkyc_col-md-4 cpkyc_margin-top-1_for-mobile ">
                <div className="cpkyc_bottom-line">
                  <input
                    type="text"
                    className="cpkyc_"
                    placeholder="*EUIN Number"
                    disabled={kycVerified}
                    value={capitalizeEachLetter(watch().euinNumber)}
                    {...register('euinNumber', {
                      required: true,
                    })}
                  />
                </div>
                {errors.euinNumber ? (
                  <>
                    {errors.euinNumber.type === 'required' && (
                      <p className="cpkyc_errorMessage">
                        EUIN Number is required
                      </p>
                    )}
                  </>
                ) : null}
              </div>
              <div className="cpkyc_col-12 cpkyc_col-md-4 cpkyc_margin-top-1_for-mobile ">
                <div className="cpkyc_bottom-line cpkyc_reactPicker">
                  <DatePicker
                    placeholderText="*Expiry Date"
                    dateFormat={'dd/MM/yyyy'}
                    onChange={(date) => setDate(date)}
                    selected={date}
                    disabled={kycVerified}
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={115}
                    scrollableYearDropdown
                    closeOnScroll={true}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
                {!date && isSaveAndContinueClicked && (
                  <p className="cpkyc_errorMessage">Select Expiry date</p>
                )}
              </div>
            </div>
            <UploadProof
              textForPTag="*AMF Document Upload"
              urlArgs="amfProofUrl"
              resetProofUrl={amfProofUrl}
              setresetProofUrl={setAmfProofUrl}
              proofType={amfProofType}
              setProofType={setAmfProofType}
              errorStatusRejected={errorAmfStatusRejected}
              errorStatusRejectedMessage={errorAmfStatusRejectedMessage}
              setErrorStatusRejected={setErrorAmfStatusRejected}
              setErrorStatusRejectedMessage={setErrorAmfStatusRejectedMessage}
              accountKycVerified={kycVerified}
              id="Amf"
              isSaveAndContinueClicked={isSaveAndContinueClicked}
            />
          </>
        )}

        {isSEBI && (
          <>
            <div className="cpkyc_row cpkyc_margin-top-2 cpkyc_margin-top-1_for-mobile ">
              <div className="cpkyc_col-12 cpkyc_col-md-6">
                <div className="cpkyc_bottom-line">
                  <input
                    type="text"
                    className="cpkyc_"
                    placeholder="*SEBI Number"
                    disabled={kycVerified}
                    value={capitalizeEachLetter(watch().sebiNumber)}
                    {...register('sebiNumber', {
                      required: true,
                    })}
                  />
                </div>
                {errors.sebiNumber ? (
                  <>
                    {errors.sebiNumber.type === 'required' && (
                      <p className="cpkyc_errorMessage">
                        SEBI Number is required
                      </p>
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <UploadProof
              textForPTag="*SEBI Document Upload"
              urlArgs="sebiProofUrl"
              resetProofUrl={sebiProofUrl}
              setresetProofUrl={setSebiProofUrl}
              proofType={sebiProofType}
              setProofType={setSebiProofType}
              errorStatusRejected={errorSebiStatusRejected}
              errorStatusRejectedMessage={errorSebiStatusRejectedMessage}
              setErrorStatusRejected={setErrorSebiStatusRejected}
              setErrorStatusRejectedMessage={setErrorSebiStatusRejectedMessage}
              accountKycVerified={kycVerified}
              id="Sebi"
              isSaveAndContinueClicked={isSaveAndContinueClicked}
            />
          </>
        )}
        {isRERA && (
          <>
            <div className="cpkyc_row cpkyc_margin-top-2 cpkyc_margin-top-1_for-mobile ">
              <div className="cpkyc_col-12 cpkyc_col-md-6">
                <div className="cpkyc_bottom-line">
                  <input
                    type="text"
                    className="cpkyc_"
                    placeholder="*RERA Number"
                    disabled={kycVerified}
                    value={capitalizeEachLetter(watch().reraNumber)}
                    {...register('reraNumber', {
                      required: true,
                    })}
                  />
                </div>
                {errors.reraNumber ? (
                  <>
                    {errors.reraNumber.type === 'required' && (
                      <p className="cpkyc_errorMessage">
                        RERA Number is required
                      </p>
                    )}
                  </>
                ) : null}
              </div>
              <div className="cpkyc_col-12 cpkyc_col-md-6 cpkyc_flex-end cpkyc_margin-top-1_for-mobile ">
                <div className="cpkyc_bottom-line cpkyc_reactPicker">
                  <DatePicker
                    placeholderText="*Expiry Date"
                    dateFormat={'dd/MM/yyyy'}
                    onChange={(date) => setDate(date)}
                    selected={date}
                    disabled={kycVerified}
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={115}
                    scrollableYearDropdown
                    closeOnScroll={true}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
                {!date && isSaveAndContinueClicked && (
                  <p className="cpkyc_errorMessage">Select Expiry date</p>
                )}
              </div>
            </div>
            <UploadProof
              textForPTag="*RERA Document Upload"
              urlArgs="reraProofUrl"
              resetProofUrl={reraProofUrl}
              setresetProofUrl={setReraProofUrl}
              proofType={reraProofType}
              setProofType={setReraProofType}
              errorStatusRejected={errorReraStatusRejected}
              errorStatusRejectedMessage={errorReraStatusRejectedMessage}
              setErrorStatusRejected={setErrorReraStatusRejected}
              setErrorStatusRejectedMessage={setErrorReraStatusRejectedMessage}
              accountKycVerified={kycVerified}
              id="Rera"
              isSaveAndContinueClicked={isSaveAndContinueClicked}
            />
          </>
        )}
        {isOthers && (
          <>
            <div className="cpkyc_row cpkyc_margin-top-2 cpkyc_margin-top-1_for-mobile ">
              <div className="cpkyc_col-12 cpkyc_col-md-6">
                <div className="cpkyc_bottom-line">
                  <input
                    type="text"
                    className="cpkyc_"
                    placeholder="*Other Number"
                    disabled={kycVerified}
                    value={capitalizeEachLetter(watch().otherNumber)}
                    {...register('otherNumber', {
                      required: true,
                    })}
                  />
                </div>
                {errors.otherNumber ? (
                  <>
                    {errors.otherNumber.type === 'required' && (
                      <p className="cpkyc_errorMessage">
                        Other Number is required
                      </p>
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <UploadProof
              textForPTag="*Other Document Upload"
              urlArgs="otherProofUrl"
              resetProofUrl={otherProofUrl}
              setresetProofUrl={setOtherProofUrl}
              proofType={otherProofType}
              setProofType={setOtherProofType}
              errorStatusRejected={errorOtherStatusRejected}
              errorStatusRejectedMessage={errorOtherStatusRejectedMessage}
              setErrorStatusRejected={setErrorOtherStatusRejected}
              setErrorStatusRejectedMessage={setErrorOtherStatusRejectedMessage}
              accountKycVerified={kycVerified}
              id="Other"
              isSaveAndContinueClicked={isSaveAndContinueClicked}
            />
          </>
        )}
        <CpKycContainerBottomTile
          kycVerified={kycVerified}
          handleSubmitButton={handleSubmitButton}
        />
      </form>
    </>
  );
}
export default GstOthersDetails;
