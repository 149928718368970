import React, { useEffect, useState } from 'react';
import {
  Text,
  Flex,
  Input,
  Link,
  FormControl,
  FormErrorMessage,
  Button,
  Select,
} from '@chakra-ui/react';
import { AccountManager, Environment, Strings } from '@hBits.Core';
import { Colors } from 'apps/customer-portal-web/src/theme';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReferalTable from './ReferalTable';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import './referal.module.scss';
import {
  fetchAllCountries,
  fetchStateByCountryId,
} from 'libs/src/services/modules/channel-partner';
import {
  addReferral,
  fetchAllReferrals,
} from 'apps/customer-portal-web/src/services/investment-dashboard/Referral';
import ReferralSubmit from './ReferralSubmit';
import { authTokenStorageProvider } from '@hBits.Core';
import TncModal from 'libs/src/components/TncModal';
import PrivacyModal from 'libs/src/components/PrivacyModal';

export interface FormInput {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileCountryCode: string;
  mobileNumber: string;
  country: string;
  state: string;
  city: string;
  status: string;
}

type CountryType = {
  id: string;
  name: string;
  iso: string;
  phoneCode: string;
  currency: string;
};

type StateType = {
  id: string;
  name: string;
  countryName: string;
};

const Referal = () => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormInput>();

  const landingPage = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );
  const isNumber = (number: any) => !isNaN(number) || 'Must be a number';
  const [countryList, setCountryList] = useState<CountryType[]>([]);
  const [stateList, setStateList] = useState<StateType[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [submitted, setSubmitted] = useState<Boolean>(false);
  const [referralList, setReferralList] = useState<FormInput[]>([]);
  const [stateListEmpty, setStateListEmpty] = useState<Boolean>(false);
  const [isOpenTncModal, setIsOpenTncModal] = useState<boolean>(false);
  const [isOpenPrivacyModal, setIsOpenPrivacyModal] = useState<boolean>(false);

  const onSubmit: SubmitHandler<FormInput> = (data) => {
    const authToken = AccountManager.getAccountId();
    const accessToken = authTokenStorageProvider.getRawAuthToken();
    addReferral(authToken, accessToken, data).then((res) => {
      if (res.message && res.message === 'success') {
        setSubmitted(true);
        reset();
      } else {
        alert(res.message);
      }
    });
  };

  const toCapitalize = (country: string) => {
    if (!country) return '';
    return country.charAt(0).toUpperCase() + country.slice(1).toLowerCase();
  };

  useEffect(() => {
    fetchAllCountries().then((data) => {
      setCountryList(data);
    });
    const authToken = AccountManager.getAccountId();
    fetchAllReferrals(authToken).then((res) => setReferralList(res));
  }, [submitted]);

  useEffect(() => {
    const countryId = countryList.find(
      (country: CountryType) => country.name === selectedCountry,
    )?.id;
    countryId &&
      fetchStateByCountryId(countryId).then((data) => {
        setStateList(data);
        setStateListEmpty(data.length === 0);
      });
  }, [selectedCountry]);

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setSelectedCountry(event.target.value);

  return (
    <Flex
      flexDir={'column'}
      flex={1}
      mx={[0, 0, 5, 5]}
      mb={'100px'}
      mt={[5, 5, 0, 0]}
      w={'80%'}
    >
      <Text fontSize={'2xl'} as="b" color={Colors.headerColor}>
        {Strings.addReferalL}
      </Text>
      {submitted ? (
        <ReferralSubmit setSubmitted={setSubmitted} />
      ) : (
        <Flex
          bg={Colors.white}
          flex={1}
          mt={5}
          px={10}
          pt={5}
          borderRadius={10}
          flexDirection={'column'}
        >
          <Text fontSize={'2xl'} as="b" color={Colors.headerColor}>
            {Strings.basicDetails}
          </Text>
          <Flex mt={4} justifyContent="space-between" flexWrap={'wrap'}>
            <form
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl
                isInvalid={Boolean(errors.firstName)}
                w={['45%']}
                my={5}
              >
                <Input
                  id="firstName"
                  variant="flushed"
                  placeholder={Strings.firstName}
                  _placeholder={{ color: Colors.grey }}
                  {...register('firstName', {
                    required: 'First Name is required.',
                    pattern: {
                      value: /^[a-z]+$/i,
                      message: 'Invalid First Name',
                    },
                  })}
                  onChange={(e) => {
                    setValue('firstName', e.target.value.trim());
                  }}
                />
                <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={Boolean(errors.lastName)}
                w={['45%']}
                my={5}
              >
                <Input
                  id="lastName"
                  variant="flushed"
                  placeholder={Strings.lastName}
                  _placeholder={{ color: Colors.grey }}
                  {...register('lastName', {
                    required: 'Last Name is required.',
                    pattern: {
                      value: /^[a-z]+$/i,
                      message: 'Invalid Last Name',
                    },
                  })}
                  onChange={(e) => {
                    setValue('lastName', e.target.value.trim());
                  }}
                />
                <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={Boolean(errors.emailAddress)}
                w={['45%']}
                my={5}
              >
                <Input
                  id="emailAddress"
                  variant="flushed"
                  placeholder={Strings.email}
                  _placeholder={{ color: Colors.grey }}
                  {...register('emailAddress', {
                    required: 'Email is required.',
                    pattern: {
                      value: /[a-z0-9._]+@[a-z0-9.-]+\.[a-z]{2,3}$/,
                      message: 'Invalid Email Id',
                    },
                  })}
                  onChange={(e) => {
                    setValue('emailAddress', e.target.value.trim());
                  }}
                />
                <FormErrorMessage>
                  {errors.emailAddress?.message}
                </FormErrorMessage>
              </FormControl>

              <Flex
                w={'45%'}
                flexDirection={'column'}
                my={5}
                className="referral-phone-input"
              >
                <Controller
                  control={control}
                  name="mobileNumber"
                  rules={{
                    required: {
                      value: true,
                      message: 'Phone Number is required',
                    },
                    minLength: { value: 7, message: 'Phone Number is Invalid' },
                    validate: isNumber,
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <PhoneInput
                      inputProps={{
                        autoFocus: false,
                        prefix: '+',
                        required: true,
                      }}
                      country="in"
                      placeholder="Enter Mobile Number"
                      enableSearch
                      isValid
                      inputStyle={{
                        fontSize: 'var(--chakra-fontSizes-md)',
                      }}
                      onChange={(phone: string, country: any) => {
                        const reducedPhone = phone.replace(
                          country.dialCode,
                          '',
                        );
                        setValue('mobileNumber', reducedPhone);
                        setValue('mobileCountryCode', `+${country.dialCode}`);
                      }}
                      countryCodeEditable={false}
                    />
                  )}
                />
                {errors.mobileNumber && (
                  <div
                    className="chakra-form__error-message css-170ki1a"
                    style={{ color: '#e53e3e' }}
                  >
                    {errors.mobileNumber.message}
                  </div>
                )}
              </Flex>

              <FormControl
                isInvalid={Boolean(errors.country)}
                w={['30%']}
                my={5}
              >
                <Select
                  id="country"
                  variant="flushed"
                  placeholder={Strings.country}
                  _placeholder={{ color: Colors.grey }}
                  {...register('country', {
                    required: 'Country is required.',
                  })}
                  onChange={handleCountryChange}
                >
                  {countryList.map((country) => (
                    <option key={country.id} value={country.name}>
                      {toCapitalize(country.name)}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
              </FormControl>

              {stateListEmpty ? (
                <FormControl
                  isInvalid={Boolean(errors.state)}
                  w={['30%']}
                  my={5}
                >
                  <Input
                    variant="flushed"
                    placeholder={Strings.state}
                    _placeholder={{ color: Colors.grey }}
                    {...register('state', {
                      required: 'State is required.',
                      pattern: {
                        value:
                          /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
                        message: 'Invalid state',
                      },
                    })}
                  />
                  <FormErrorMessage>{errors.state?.message}</FormErrorMessage>
                </FormControl>
              ) : (
                <FormControl
                  isInvalid={Boolean(errors.state)}
                  w={['30%']}
                  my={5}
                >
                  <Select
                    id="state"
                    variant="flushed"
                    placeholder={Strings.state}
                    _placeholder={{ color: Colors.grey }}
                    {...register('state', {
                      required: 'State is required.',
                    })}
                  >
                    {stateList.map((state) => (
                      <option key={state.id} value={state.name}>
                        {toCapitalize(state.name)}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.state?.message}</FormErrorMessage>
                </FormControl>
              )}

              <FormControl isInvalid={Boolean(errors.city)} w={['30%']} my={5}>
                <Input
                  variant="flushed"
                  placeholder={Strings.city}
                  _placeholder={{ color: Colors.grey }}
                  {...register('city', {
                    required: 'City is required.',
                    pattern: {
                      value:
                        /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
                      message: 'Invalid city',
                    },
                  })}
                />
                <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
              </FormControl>

              <Flex w={'100%'} justifyContent="center">
                <Button
                  mt={4}
                  isLoading={isSubmitting}
                  type="submit"
                  bgColor={Colors.buttonColor}
                  fontSize={'16px'}
                  px={20}
                  py={7}
                  textDecoration="none"
                  _hover={{ bg: '#ffb657' }}
                >
                  {Strings.send}
                </Button>
              </Flex>
            </form>
          </Flex>

          <Flex alignItems={'center'} mt={5} flexDirection="column" pb={5}>
            <Flex mt={5}>
              <Text fontSize={'lg'} color={Colors.grey600}>
                {Strings.byclickingSend}
                <u>
                  {' '}
                  <Link
                    fontSize={'lg'}
                    color={Colors.headerColor}
                    isExternal
                    fontWeight={'bold'}
                    onClick={() => setIsOpenTncModal(true)}
                  >
                    {Strings.termsandCondition}
                  </Link>
                </u>
                and
                <u>
                  <Link
                    fontSize={'lg'}
                    fontWeight={'bold'}
                    isExternal
                    color={Colors.headerColor}
                    onClick={() => setIsOpenPrivacyModal(true)}
                  >
                    {Strings.privacyPolicy}
                  </Link>
                </u>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
      {isOpenTncModal && (
        <TncModal onModalClose={() => setIsOpenTncModal(false)} />
      )}
      {isOpenPrivacyModal && (
        <PrivacyModal onModalClose={() => setIsOpenPrivacyModal(false)} />
      )}
      {referralList?.length !== 0 && (
        <>
          <Text fontSize={'2xl'} as="b" color={Colors.headerColor} mt={10}>
            {Strings.pastReferal}
          </Text>
          <ReferalTable referralList={referralList} />
        </>
      )}
    </Flex>
  );
};

export default Referal;
