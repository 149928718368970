import { Box, Progress, Text } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { getInvestMentStatus } from '../../services';
import { AccountManager, HTTPMethod } from '@hBits.Core';
import { useParams, useSearchParams } from 'react-router-dom';
import { NUM_FORMATTING } from 'apps/landing-pages-web/src/components/common/utils/helper';

const PaymentHistory = ({ investmentDetails }: any) => {
  const [calculateAmount, setCalculateAmount] = useState<any>({
    paid_amount: '',
    balance_amount: '',
  });

  useEffect(() => {
    const totalTransactionAmount = investmentDetails?.transactions?.reduce(
      (total: any, transactions: any) => {
        return total + parseFloat(transactions?.amount);
      },
      0,
    );
    setCalculateAmount({
      paid_amount: totalTransactionAmount,
      balance_amount:
        investmentDetails?.totalInvestmentAmount - totalTransactionAmount,
    });
  }, [investmentDetails]);

  return (
    <div className="w-full max-h-72 overflow-y-scroll no-scrollbar">
      <Box>
        <Text className="text-[#535353] text-[18px] font-medium mb-6">
          Your Payment History
        </Text>
      </Box>
      <Box className="flex flex-col gap-2">
        {investmentDetails?.transactions?.length > 0 ? (
          investmentDetails?.transactions?.map((data: any) => {
            return (
              <div>
                <Box className="flex flex-row justify-between  items-center gap-4 border-b-[1px] pb-1">
                  <Text className="text-[13px] font-normal text-[#535353]">
                    {data.transactionTime.split(' ')[0]}
                  </Text>
                  <Text className="text-[13px] font-normal text-[#535353]">
                    {data.transactionTime.split(' ')[1]}
                  </Text>

                  <Text className="text-[13px] font-bold text-black w-[40%]">
                    {` ₹${NUM_FORMATTING(data.amount)}`}
                  </Text>
                </Box>
              </div>
            );
          })
        ) : (
          <div className="text-[#535353] font-medium text-sm">
            <Text className="pb-2">You have not done any payments yet</Text>
            <div className="border-b-[2px]"></div>
          </div>
        )}

        {investmentDetails?.transactions?.length > 0 && (
          <Box className="flex flex-row justify-between w-full items-center gap-4 border-b-[1px] pb-1">
            <Text className="text-[10px] font-bold text-black ">
              Total paid so far
            </Text>
            <Text className="text-[13px] font-bold text-black ">
              {` ₹${NUM_FORMATTING(calculateAmount?.paid_amount)}`}
            </Text>
          </Box>
        )}

        <Box className="flex flex-row justify-between w-full items-center gap-4  pb-1">
          <Text className="text-[9px] font-bold text-black ">
            Balance amount to be paid
          </Text>
          <Text className="text-[13px] font-bold text-red-500 ">
            {` ₹${NUM_FORMATTING(calculateAmount?.balance_amount)}`}
          </Text>
        </Box>
      </Box>
    </div>
  );
};

export default PaymentHistory;
