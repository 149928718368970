import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Colors, Environment, Strings } from '@hBits.Core';
import { Formik } from 'formik';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SelectCountryDropdown from '../../../../../ChannelPartner/helper-components/CountriesDropDown';
import SelectStatesDropdown from '../../../../../ChannelPartner/helper-components/StatesDropDown';
import styles from '../styles/index.module.scss';
import PhoneInput from 'react-phone-input-2';
import * as Yup from 'yup';
import { addClient } from 'apps/customer-portal-web/src/services/dashboard/client';
import { TOAST_TIMEOUT } from 'libs/src/constants';
import circleLogo from '../../../../../../components/Kyc/resources/circleLogo.svg';
import { eventEmitter } from 'apps/landing-pages-web/src/components/events';
import { isAddClient } from 'apps/customer-portal-web/src/constants/globalConstants';
import { CustomLink } from 'apps/customer-portal-web/src/screens/ChannelPartner/styled-componets';
import TncModal from 'libs/src/components/TncModal';
import PrivacyModal from 'libs/src/components/PrivacyModal';

interface AddFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}
interface AddMembersInt {
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileNumber: string;
  country: string;
  state: string;
  city: string;
  comment: string;
}

interface AddClientAPIResponse {
  error: boolean;
  errorMessage: string | null | undefined;
}

const AddFormModal = ({ isOpen, onClose }: AddFormModalProps) => {
  const [countryCode, setCountryCode] = useState(91);
  const [minMobileNumberLength, setMinMobileNumberLength] = useState(22);
  const toast = useToast();
  const [isOpenTncModal, setIsOpenTncModal] = useState<boolean>(false);
  const [isOpenPrivacyModal, setIsOpenPrivacyModal] = useState<boolean>(false);

  const landingPage = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );

  const getInitialValues: () => AddMembersInt = () => {
    let initialValues = {
      firstName: '',
      lastName: '',
      emailAddress: '',
      mobileNumber: '',
      country: '',
      state: '',
      city: '',
      comment: '',
    };
    return initialValues;
  };

  const AddClientSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .min(9, 'Invalid Mobile Number!')
      .max(minMobileNumberLength, 'Invalid Mobile Number!')
      .required('This is required.'),
    firstName: Yup.string()
      .min(3, 'First Name entered is too Short!')
      .max(30, 'First Name entered is too Long!')
      .matches(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/, 'Invalid First Name')
      .required('This is required.'),
    lastName: Yup.string()
      .min(3, 'Last Name entered is too Short!')
      .max(30, 'Last Name entered is too Long!')
      .matches(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/, 'Invalid Last Name')
      .required('This is required.'),
    emailAddress: Yup.string()
      .max(40, 'Invalid Email')
      .matches(
        /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        'Invalid Email',
      )
      .required('This is required.'),
    country: Yup.string().required('This is required.'),
    state: Yup.string().trim().required('This is required.'),
    city: Yup.string().trim().required('This is required.'),
    comment: Yup.string().trim(),
  });

  const toggleTncModal = () => {
    setIsOpenTncModal(!isOpenTncModal);
  };

  const togglePrivacyModal = () => {
    setIsOpenPrivacyModal(!isOpenPrivacyModal);
  };

  const handleSubmit = async (data: any) => {
    if (data.comment == '') {
      data.comment = undefined;
    }
    const body = { ...data };
    body.mobileNumber = body.mobileNumber.slice(
      countryCode.toString().length,
      body.mobileNumber.length,
    );
    body.mobileCountryCode = `+${countryCode}`;

    const addedClientResponse: AddClientAPIResponse = await addClient(body);

    if (!addedClientResponse.error) {
      toast({
        title: Strings.success,
        description: Strings.addedMember,
        status: 'success',
        duration: TOAST_TIMEOUT,
        isClosable: true,
      });
      onClose();
      eventEmitter.dispatch('ClientUpdated');
      return;
    }
    toast({
      title: Strings.failed,
      description: addedClientResponse.errorMessage,
      status: 'error',
      duration: TOAST_TIMEOUT,
      isClosable: true,
    });
  };

  return (
    <Modal
      size={['md', '4xl']}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent padding={['15px 10px', '35px 70px']}>
        <ModalHeader
          fontWeight="500"
          backgroundColor={Colors.white}
          color={Colors.blackColor}
          fontSize={['18px', '2rem']}
          className="text-center"
          px={['0.5rem', '1rem']}
        >
          Add Client
        </ModalHeader>
        <ModalCloseButton mr={3} mt={3}>
          <img src={circleLogo}></img>
        </ModalCloseButton>
        <ModalBody>
          <Formik
            initialValues={getInitialValues()}
            onSubmit={handleSubmit}
            validationSchema={AddClientSchema}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              setFieldTouched,
              errors,
              touched,
            }) => (
              <Flex
                justifyContent={'space-between'}
                direction={'column'}
                rowGap={['1rem', '3rem']}
                columnGap={'7rem'}
              >
                <Flex
                  justifyContent={'space-between'}
                  direction={'column'}
                  rowGap={['1rem', '3rem']}
                  columnGap={'7rem'}
                  maxHeight={['450px', 'fit-content']}
                  overflowY={'auto'}
                  className={styles['add_client_input']}
                >
                  <Flex gap={'1.5rem'} direction={['column', 'row']}>
                    <Flex flex={1} direction={'column'} gap={'1'}>
                      <Input
                        placeholder={'*First Name'}
                        borderRadius={0}
                        borderColor={Colors.greyBorderColor}
                        onChange={handleChange('firstName')}
                        onBlur={() => setFieldTouched('firstName')}
                        value={values.firstName}
                        variant="flushed"
                      />
                      {errors.firstName && touched.firstName && (
                        <div className={styles['errorRegMessage']}>
                          {errors.firstName.toString()}
                        </div>
                      )}
                    </Flex>
                    <Flex flex={1} direction={'column'} gap={'1'}>
                      <Input
                        placeholder={'*Last Name'}
                        onChange={handleChange('lastName')}
                        onBlur={() => setFieldTouched('lastName')}
                        value={values.lastName}
                        borderRadius={0}
                        borderColor={Colors.greyBorderColor}
                        variant="flushed"
                      />
                      {errors.lastName && touched.lastName && (
                        <div className={styles['errorRegMessage']}>
                          {errors.lastName.toString()}
                        </div>
                      )}
                    </Flex>
                  </Flex>
                  <Flex gap={'1.5rem'} direction={['column', 'row']}>
                    <Flex flex={1} direction={'column'} gap={'1'}>
                      <Input
                        placeholder={'*Email'}
                        onChange={handleChange('emailAddress')}
                        onBlur={() => setFieldTouched('emailAddress')}
                        value={values.emailAddress}
                        borderRadius={0}
                        borderColor={Colors.greyBorderColor}
                        variant="flushed"
                      />
                      {errors.emailAddress && touched.emailAddress && (
                        <div className={styles['errorRegMessage']}>
                          {errors.emailAddress.toString()}
                        </div>
                      )}
                    </Flex>
                    <Flex flex={1} direction={'column'} gap={'1'} pt={'10px'}>
                      <PhoneInput
                        inputProps={{
                          autoFocus: false,
                          prefix: '+',
                        }}
                        country="in"
                        placeholder="Enter Mobile Number*"
                        enableSearch
                        isValid
                        countryCodeEditable={false}
                        value={values.mobileNumber}
                        onChange={(ph: any, data: any) => {
                          handleChange('mobileNumber')(ph);
                          setCountryCode(data.dialCode);
                          setMinMobileNumberLength(data.format.length || 22);
                        }}
                        onBlur={() => setFieldTouched('mobileNumber')}
                      />
                      {errors.mobileNumber && touched.mobileNumber && (
                        <div className={styles['errorRegMessage']}>
                          {errors.mobileNumber.toString()}
                        </div>
                      )}
                    </Flex>
                  </Flex>
                  <Flex gap={'1.5rem'} direction={['column', 'row']}>
                    <Flex flex={1} direction={'column'} gap={'1'}>
                      <SelectCountryDropdown
                        selectedCountry={values.country}
                        setSelectedCountry={handleChange('country')}
                        isColorGrey={true}
                      />
                      {errors.country && touched.country && (
                        <div className={styles['errorRegMessage']}>
                          {errors.country.toString()}
                        </div>
                      )}
                    </Flex>
                    <Flex flex={1} direction={'column'} gap={'1'}>
                      <SelectStatesDropdown
                        selectedCoutry={values.country}
                        selectedState={values.state}
                        setSelectedState={handleChange('state')}
                        isColorGrey={true}
                      />
                      {errors.state && touched.state && (
                        <div className={styles['errorRegMessage']}>
                          {errors.state.toString()}
                        </div>
                      )}
                    </Flex>
                    <Flex flex={1} direction={'column'} gap={'0.9'}>
                      <Input
                        placeholder={'*City'}
                        onChange={handleChange('city')}
                        onBlur={() => setFieldTouched('city')}
                        value={values.city}
                        borderRadius={0}
                        borderColor={Colors.greyBorderColor}
                        variant="flushed"
                        height={'40px'}
                      />
                      {errors.city && touched.city && (
                        <div className={styles['errorRegMessage']}>
                          {errors.city.toString()}
                        </div>
                      )}
                    </Flex>
                  </Flex>
                  <Flex flex={1} direction={'column'} gap={1}>
                    <Input
                      onChange={handleChange('comment')}
                      onBlur={() => setFieldTouched('comment')}
                      value={values.comment}
                      placeholder={'Your Comment Here'}
                      style={{ paddingTop: '30px', paddingBottom: '80px' }}
                    />
                  </Flex>
                  <Text
                    textAlign={'left'}
                    color={Colors.textGreyColor}
                    fontWeight={'light'}
                    fontSize="16px"
                  >
                    {Strings.submitClickInfo}
                    <CustomLink onClick={toggleTncModal}>
                      {Strings.tnc}
                    </CustomLink>
                    {Strings.and}
                    <CustomLink onClick={togglePrivacyModal}>
                      {Strings.pp}
                    </CustomLink>
                  </Text>
                </Flex>
                <Flex justifyContent={'center'}>
                  <Button
                    type={'submit'}
                    py={'1.5rem'}
                    px={'4rem'}
                    bgColor={Colors.orangeColor}
                    className={styles['proceed_btn']}
                    _hover={{ bg: Colors.orangeColor }}
                    _active={{ bg: Colors.orangeColor }}
                    onClick={() => handleSubmit()}
                  >
                    SUBMIT
                  </Button>
                </Flex>
              </Flex>
            )}
          </Formik>
          {isOpenTncModal && <TncModal onModalClose={toggleTncModal} />}
          {isOpenPrivacyModal && (
            <PrivacyModal onModalClose={togglePrivacyModal} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const AddClientForm = () => {
  const location = useLocation();
  const openAddClientModal = () => {
    if (location.search.includes(isAddClient)) {
      return true;
    }
    return false;
  };
  const [isOpen, setIsOpen] = useState(openAddClientModal());
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        bgColor={Colors.bgDarkBlue}
        color={Colors.orangeColor}
        variant="solid"
        onClick={() => {
          setIsOpen(true);
        }}
        className={styles['add_client_btn']}
        _hover={{ bg: Colors.bgDarkBlue }}
        _active={{ bg: Colors.bgDarkBlue }}
      >
        <i className="fa-solid fa-plus"></i>
        <span className="px-2" style={{ fontSize: '16px' }}>
          ADD CLIENT
        </span>
      </Button>
      <Flex>
        <AddFormModal isOpen={isOpen} onClose={handleClose} />
      </Flex>
    </>
  );
};

export default AddClientForm;
