export const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
};

export const downloadFileUrl = 'downloadFileUrl';

export const isAddClient = 'isAddClient';
