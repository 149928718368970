import { Flex, Spinner, Text } from '@chakra-ui/react';
import { Environment } from '@hBits.Core';
import DocumentIcon from 'apps/customer-portal-web/src/assets/DocumentIcon.svg';
import ViewKycIcon from 'apps/customer-portal-web/src/assets/ViewKycIcon.svg';
import CustomButton from 'apps/customer-portal-web/src/components/CustomButton';
import { getPas4EoiDocumentStatus } from 'apps/customer-portal-web/src/services/propertyInvestment/pas4StepServices';
import { setInvestmentDetails } from 'apps/customer-portal-web/src/store/actions';
import colors from 'apps/customer-portal-web/src/theme/colors';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomFrame from '../../CustomFrame';

interface EOIStep1Props {
  openZoho?: any;
  pas4DocLoader?: boolean;
}

const PAS4Step1: FC<EOIStep1Props> = (props) => {
  const { openZoho, pas4DocLoader } = props;
  const propertyDetails = useSelector(
    (state: any) => state.InvestmentReducer?.propertyDetails || {},
  );

  const activeSPVIndex = propertyDetails?.propertyInvestment?.spvs.findIndex(
    (investmentDetails: { isActive: Boolean }) => investmentDetails.isActive,
  );

  const investmentModal = useSelector(
    (state: any) => state.InvestmentReducer?.propertyData,
  );
  const { investmentAmountEoi, pas4 } = investmentModal;
  const { investmentId } = investmentAmountEoi;
  const dispatch = useDispatch();
  const [draftPAS4Modal, setDraftPAS4Modal] = useState(false);

  const closeEDocumentModal = async () => {
    const signedDocumentStatus = await getPas4EoiDocumentStatus(investmentId);
    dispatch(setInvestmentDetails(signedDocumentStatus));
  };

  useEffect(() => {
    if (pas4?.pas4DocumentSigningStatus === 'InProgress') {
      closeEDocumentModal();
    }
  }, []);

  const redirectToKyc = () => {
    const NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT =
      'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT';
    window.location.assign(
      Environment.getInstance().getParam(
        NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT,
      ) + '/kyc',
    );
  };
  return (
    <>
      <Flex
        width={'100%'}
        flexDirection={'column'}
        pl={[0, 0, 3, 3]}
        mt={[3, 3, 0, 0]}
        className="flex items-center justify-center"
      >
        <Flex className="flex flex-col justify-center w-[80%]">
          <Text as="b" fontSize="14px" color={colors.grey}>
            We advise you to check and confirm your KYC details, before
            proceeding to PAS4 Document stage. KYC details cannot be edited in
            PAS4 Document, once sent.
          </Text>
          <Flex
            direction={'column'}
            mt={3}
            borderTopWidth=".5px"
            borderTopColor={colors.blackColor}
            pt={2}
          ></Flex>
          <Flex mt={'100px'} flexDirection="column">
            {pas4DocLoader ? (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            ) : (
              <Flex>
                <CustomButton
                  btnText={'SIGN THE DOCUMENT'}
                  btnPressed={() => openZoho()}
                />
              </Flex>
            )}

            <Flex
              flexDirection={['column', 'row', 'row', 'row']}
              justify="space-between"
              mt={'10px'}
            >
              <Flex
                alignItems={'center'}
                mt={'15px'}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setDraftPAS4Modal(true);
                }}
              >
                <img src={DocumentIcon} />
                <Text
                  as="b"
                  fontSize="13px"
                  color={'#1a295b'}
                  className="viewDetailsText"
                  ml={'5px'}
                >
                  Draft PAS4 Document
                </Text>
              </Flex>
              <Flex
                alignItems={'center'}
                mt={'15px'}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  redirectToKyc();
                }}
              >
                <img src={ViewKycIcon} />
                <Text
                  as="b"
                  fontSize="13px"
                  color={'#1a295b'}
                  className="viewDetailsText"
                  ml={'5px'}
                >
                  View My KYC Details
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {draftPAS4Modal && (
        <CustomFrame
          isOpen={draftPAS4Modal}
          zohoDocLink={
            propertyDetails?.propertyInvestment?.pas4s?.[activeSPVIndex]
              ?.pas4DocumentDraftUrl
          }
          isTopUp={false}
          onClose={() => setDraftPAS4Modal(false)}
          title="Draft EOI Document"
          onCloseFrame={() => setDraftPAS4Modal(false)}
        />
      )}
    </>
  );
};

export default PAS4Step1;
