import { createReducer } from '../reducerUtil';
import {
  UPDATE_LOADING_STATUS,
  SET_ERROR,
  SET_SUCCESS,
  RESET_APP_REDUCER,
} from '../../constants/ReduxConstants';

interface AppReducerType {
  loading: boolean;
  error: object | null;
  success: object | null;
}

const initialState = {
  loading: false,
  error: null,
  success: null,
};

export const updateLoadingStatus = (
  state: AppReducerType,
  payload: boolean,
): typeof state => ({
  ...state,
  loading: payload,
});

export const setError = (state: AppReducerType, payload: object) => ({
  ...state,
  error: payload,
});

export const setSuccess = (state: AppReducerType, payload: object) => ({
  ...state,
  success: payload,
});

export const resetAppReducer = (): AppReducerType => initialState;

export default createReducer(initialState, {
  [UPDATE_LOADING_STATUS]: updateLoadingStatus,
  [SET_ERROR]: setError,
  [SET_SUCCESS]: setSuccess,
  [RESET_APP_REDUCER]: resetAppReducer,
});
