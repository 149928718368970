import { authTokenStorageProvider } from 'libs/src/auth';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPStatusCode } from 'libs/src/enums';
import { HTTPMethod } from 'libs/src/enums';
import { setAccessToken } from '../../AuthHelper';
import { fetchAsync } from '../../NetworkHelper';

type userData = {
  token?: string | null;
};

export const sendLoginOTP = async (
  userData: userData,
  isTestLogin: boolean = false,
) => {
  try {
    const headerToken = userData?.token || null;
    delete userData.token;

    const request = {
      method: HTTPMethod.POST,
      data: userData,
      url: `${baseApiUrl}${
        isTestLogin ? endpoints.testLogin : endpoints.sendLoginOTP
      }`,
      headers: headerToken
        ? { 'hb-captcha-token': headerToken, 'recaptcha-action': 'Login' }
        : '',
    };

    const response = await fetchAsync(request);

    return response;
  } catch (_error) {
    return null;
  }
};

export const verifyLoginOTP = async (userData: object) => {
  try {
    const request = {
      method: HTTPMethod.POST,
      data: userData,
      url: baseApiUrl + endpoints.verifyLoginOTP,
    };

    const response = await fetchAsync(request);

    // Code to set Access token
    if (
      response.status === HTTPStatusCode.OK &&
      response?.data &&
      (response?.data as any)?.accessToken
    ) {
      authTokenStorageProvider.storeRawAuthToken(response?.data?.accessToken);
      setAccessToken(response?.data?.accessToken);
    }
    return response;
  } catch (_error) {
    return null;
  }
};

export const resendLoginOTP = async (accountId: string) => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      data: { accountId },
      url: baseApiUrl + endpoints.resendLoginOTP,
    };

    const response = await fetchAsync(request);

    return response;
  } catch (_error) {
    return null;
  }
};

export const userLogOff = async (accessToken: any) => {
  try {
    const request = {
      method: HTTPMethod.POST,
      data: { accessToken: accessToken },
      url: `${endpoints.logOff}`,
    };

    const response = await fetchAsync(request);
    return response;
  } catch (_error) {
    return null;
  }
};

export const getUserData = (id: any) => {
  const req = {
    method: HTTPMethod.GET,
    data: {},
    url: baseApiUrl + endpoints.customers + id,
  };
  return fetchAsync(req).then((response) => {
    return response.data;
  });
};
