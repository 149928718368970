import { combineReducers } from 'redux';
import InvestmentReducer from './InvestmentReducer/Investment.Reducer';
import KycReducer from './KycReducer';

const rootReducer = combineReducers({
  InvestmentReducer,
  KycReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
