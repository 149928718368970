import React, { useState } from 'react';
import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import colors from 'libs/src/theme/colors';
import { useLocation } from 'react-router-dom';
import { getBalanceAmountUrl } from 'apps/customer-portal-web/src/services/propertyInvestment/fullPayServices';
import CustomInput from 'apps/customer-portal-web/src/components/CustomButton';
import { useSelector } from 'react-redux';
import { Environment, Strings } from '@hBits.Core';
import PaymentRecid from '../../PaymentRecid';

interface EOIStep1Props {
  openZoho?: any;
  setCurrentPas4Value?: any;
}

const FullPay2: FC<EOIStep1Props> = (props) => {
  const investmentModal = useSelector(
    (state: any) => state.InvestmentReducer?.propertyData,
  );
  let location = useLocation();
  const { fullPay, investmentAmountEoi } = investmentModal;

  const removePgRedirect = () => {
    const customerProtalWeb = Environment.getInstance().getParam(
      'NEXT_PUBLIC_CUSTOMER_PORTAL_HOSTING_ROOT',
    );
    window.location.assign(customerProtalWeb + location.pathname);
  };

  return (
    <>
      {location.search.includes('?transactionStatus') ? (
        <PaymentRecid
          removePgRedirect={removePgRedirect}
          amount={fullPay?.balanceAmount}
          recidUrl={fullPay?.balanceAmountPaymentTransactionUrls[0]}
          isSucess={true}
        />
      ) : (
        <Flex
          width={'100%'}
          flexDirection={'column'}
          pl={[0, 0, 3, 3]}
          mt={[3, 3, 0, 0]}
        >
          <Flex
            width={'60%'}
            borderBottomWidth=".5px"
            borderBottomColor={colors.blackColor}
            pb={3}
          >
            <Text as="b" fontSize="14px" color={colors.grey200}>
              {Strings.registrationProcess}
            </Text>
          </Flex>
          <Flex direction={'column'} pt={2}>
            <Text fontSize="14px" color={colors.grey}>
              {Strings.weWillGetBack}
            </Text>
            <Text fontSize="14px" mt={'50px'} color={colors.grey} pr={'30px'}>
              {Strings.forQueries2}
            </Text>
          </Flex>
          <Flex mt={12} flexDirection="column">
            <Flex></Flex>
            <Text fontSize="14px" as="b" color={colors.grey} mt={3}>
              {Strings.forQueries}
            </Text>
            <Text fontSize="14px" as="b" color={colors.headerBlue} mt={1}>
              {Strings.contactusregestration}
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default FullPay2;
