import { Strings } from '@hBits.Core';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { HTTPMethod, HTTPStatusCode } from 'libs/src/enums';
import { fetchAsync } from '../../NetworkHelper';

export const fetchAllCountries = async (): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.GET,
      url: baseApiUrl + endpoints.countriesList,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchStateByCountryId = async (
  countryId: string,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.GET,
      url:
        baseApiUrl +
        endpoints.countriesList +
        `${countryId}` +
        endpoints.states,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const enrollAsChnnelPartner = async (enrollmentPayload: any) => {
  try {
    const request = {
      method: HTTPMethod.PUT,
      url: baseApiUrl + endpoints.channelPartnerEnrollment,
      data: enrollmentPayload,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addChannelPartner = async (newMemberPayload: any) => {
  try {
    const request = {
      method: HTTPMethod.POST,
      url: baseApiUrl + endpoints.channelPartner + endpoints.addMember,
      data: newMemberPayload,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.NO_CONTENT) {
      return { error: false, errorMessage: null };
    }
    if (response.status === HTTPStatusCode.BAD_REQUEST) {
      return {
        error: true,
        errorMessage: response.error.errorMessage,
      };
    }
    return {
      error: false,
      errorMessage: null,
    };
  } catch (error) {
    return { error: true, errorMessage: Strings.internalServerError };
  }
};

export const deleteChannelPartner = async (
  accountIdOfMemberTobeDeleted: string,
  accountIdToTransferTheLeadsTo: string,
): Promise<boolean> => {
  try {
    const request = {
      method: HTTPMethod.DELETE,
      url: `${baseApiUrl}${endpoints.channelPartners}${endpoints.members}/${accountIdOfMemberTobeDeleted}?accountIdToTransferTheLeadsTo=${accountIdToTransferTheLeadsTo}`,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.NO_CONTENT) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getAllTeamMembers = async (accountId: string) => {
  try {
    const request = {
      method: HTTPMethod.GET,
      url:
        baseApiUrl +
        endpoints.channelPartners +
        `/${accountId}` +
        endpoints.children,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return { data: response.data, error: false };
    }
    return { data: [], error: true };
  } catch (error) {
    return { data: [], error: true };
  }
};

export const fetchPOC = async (accountId: any): Promise<any> => {
  try {
    const request = {
      url:
        baseApiUrl + endpoints.customers + `${accountId}` + endpoints.leadOwner,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }

    return [];
  } catch (error) {
    return [];
  }
};

export const fetchCollateral = async (accountId: any): Promise<any> => {
  try {
    const request = {
      url: baseApiUrl + '/channel-partners/' + accountId + '/collaterals/',
    };
    const response = await fetchAsync(request);
    if (response.status == HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const postCollateral = async (
  collateralPayload: any,
  accountId: any,
): Promise<any> => {
  try {
    const request = {
      method: HTTPMethod.POST,
      url: baseApiUrl + `/channel-partners/${accountId}/collaterals/`,
      data: collateralPayload,
    };
    const response = await fetchAsync(request);
    if (response.status == HTTPStatusCode.OK) {
      return { status: 'success', data: response.data };
    }
    return { status: 'failed', data: response };
  } catch (error) {
    return [];
  }
};

export const deleteCollateral = async (collaterlId: any): Promise<any> => {
  try {
    const request = {
      url: baseApiUrl + '/channel-partners/' + collaterlId + '/collateral/',
      method: HTTPMethod.DELETE,
    };
    const response = await fetchAsync(request);
    if (response.status == HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};
