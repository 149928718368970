import React, { useState } from 'react';
import { dmsUpload, dmsDelete } from '../ApiData/ApiData';
import { useToast } from '@chakra-ui/react';
import pdfImage from '../resources/pdf-file.png';
import wordImage from '../resources/word.png';
import fileImage from '../resources/file.png';
import { saveAs } from 'file-saver';
import './../kyc.scss';

interface UploadProofProps {
  textForPTag: string;
  urlArgs: string;
  resetProofUrl: string;
  setresetProofUrl: any;
  id: string;
  errorStatusRejected: boolean;
  errorStatusRejectedMessage: string;
  setErrorStatusRejected: any;
  setErrorStatusRejectedMessage: any;
  accountKycVerified: boolean;
  isSaveAndContinueClicked: boolean;
  proofType: string;
  setProofType: any;
  isMandatory?: boolean;
}

export function capitalizeFirstLetter(string = '') {
  return string
    ?.toLowerCase()
    ?.split(' ')
    ?.map(function (word) {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1);
    })
    ?.join(' ');
}

export default function UploadProof(props: UploadProofProps) {
  const {
    textForPTag,
    resetProofUrl,
    setresetProofUrl,
    id,
    proofType,
    setProofType,
    errorStatusRejected,
    isSaveAndContinueClicked,
    errorStatusRejectedMessage,
    setErrorStatusRejected,
    setErrorStatusRejectedMessage,
    accountKycVerified,
    isMandatory = true,
  } = props;
  const toast = useToast();
  const [file, setFile] = React.useState('');
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  function uploadDocument(event: any) {
    setErrorStatusRejected(false);
    setErrorStatusRejectedMessage('');
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      const fileSize = event.target.files[0].size / (1024 * 1024);
      if (fileSize < 2) {
        setFileSizeError(false);
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        const type = event.target.files[0].type.split('/');
        if (type[0] == 'image') {
          formData.append('type', 'image');
        } else if (type[1] == 'pdf') {
          formData.append('type', 'pdf');
        } else if (type[1] == 'msword') {
          formData.append('type', 'word');
        } else {
          setFileTypeError(true);
          return;
        }

        dmsUpload(formData).then((res: any) => {
          setresetProofUrl(res.documentUrl);
          setProofType(capitalizeFirstLetter(res.documentType));
        });
      } else {
        setFileSizeError(true);
        setFile('');
      }
    }
  }

  const handleClick = () => {
    saveAs(resetProofUrl);
  };

  const deleteUploadedFile = () => {
    setFile('');
    setresetProofUrl('');
  };
  return (
    <div className="kyc_margin-for-upload-proof kyc_update-kyc-margin-mobile">
      {resetProofUrl && (
        <div className="kyc_drag-image kyc_drag-image-block">
          <div className="kyc_imageDiv">
            {proofType == 'Image' && (
              <>
                <img
                  className="kyc_image-size"
                  src={resetProofUrl}
                  onClick={handleClick}
                />
                <button
                  className="kyc_ kyc_delete-image kyc_margin-top-1"
                  disabled={accountKycVerified}
                  onClick={deleteUploadedFile}
                >
                  Delete
                </button>
              </>
            )}
            {proofType == 'Pdf' && (
              <>
                <img
                  className="kyc_pdfImage"
                  src={pdfImage}
                  onClick={handleClick}
                />
                <button
                  className="kyc_ kyc_delete-image kyc_margin-top-1 kyc_delete_pdf"
                  disabled={accountKycVerified}
                  onClick={deleteUploadedFile}
                >
                  Delete
                </button>
              </>
            )}
            {proofType == 'Word' && (
              <>
                <img
                  className="kyc_pdfImage"
                  src={wordImage}
                  onClick={handleClick}
                />
                <button
                  className="kyc_ kyc_delete-image kyc_margin-top-1 kyc_delete_pdf"
                  disabled={accountKycVerified}
                  onClick={deleteUploadedFile}
                >
                  Delete
                </button>
              </>
            )}
            {!proofType && (
              <>
                <img
                  className="kyc_pdfImage"
                  src={fileImage}
                  onClick={handleClick}
                />
                <button
                  className="kyc_ kyc_delete-image kyc_margin-top-1 kyc_delete_pdf"
                  disabled={accountKycVerified}
                  onClick={deleteUploadedFile}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {!resetProofUrl && (
        <div>
          <p className="kyc_p-tag-for-r-4">{textForPTag}</p>
          <label data-test="label-upload-image" className=" kyc_drag-image">
            <input
              data-test="input-file-upload-partner-address"
              id={id}
              className="kyc_"
              type="file"
              hidden
              disabled={accountKycVerified}
              accept="image/*,.doc, .docx,.pdf"
              onChange={(e: any) => {
                uploadDocument(e);
              }}
            />
            <div className="kyc_file">Choose File</div>
            <span className="kyc_span-file">(Maximum file size 2 MB)</span>
          </label>
          {!resetProofUrl && isSaveAndContinueClicked && isMandatory && (
            <p className="kyc_errorMessage">Upload the document</p>
          )}
          {fileSizeError && (
            <p className="kyc_errorMessage">
              Document should be less than 2 MB
            </p>
          )}
          {fileTypeError && (
            <p style={{ color: 'red', fontSize: '16px', paddingTop: '4px' }}>
              Images,PDF,Word documents only allowed
            </p>
          )}
        </div>
      )}
      {errorStatusRejected && (
        <p className="kyc_errorMessage">{errorStatusRejectedMessage}</p>
      )}
    </div>
  );
}
