import UpcomingProperties from '../../Dashboard/components/UpcomingProperties';
import RmDetails from './rmDetails';

const TokenSection = () => {
  return (
    <div className="flex flex-col">
      <UpcomingProperties />
      <RmDetails />
    </div>
  );
};

export default TokenSection;
