import { createReducer } from '../reducerUtil';
import { INCREMENT, DECREMENT } from '../../constants/ReduxConstants';

interface CounterReducerType {
  count: any;
}

const initialState = {
  count: 0,
};

export const increaseCounter = (state: CounterReducerType): typeof state => ({
  ...state,
  count: state.count + 1,
});

export const decreaseCounter = (state: CounterReducerType): typeof state => ({
  ...state,
  count: state.count - 1,
});

export default createReducer(initialState, {
  [INCREMENT]: increaseCounter,
  [DECREMENT]: decreaseCounter,
});
