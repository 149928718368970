import { fetchAsync, HTTPMethod, HTTPStatusCode } from '@hBits.Core';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';

export const fetchAllReferrals = async (accountId: any): Promise<any> => {
  try {
    const request = {
      url: endpoints.investments + `/${accountId}` + endpoints.referral,
    };

    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.OK) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const addReferral = async (
  accountId: any,
  accessToken: any,
  referralPayload: any,
) => {
  try {
    const request = {
      method: HTTPMethod.POST,
      url: endpoints.investments + `/${accountId}` + endpoints.addReferral,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: referralPayload,
    };
    const response = await fetchAsync(request);
    if (response.status === HTTPStatusCode.NO_CONTENT) {
      return { message: 'success' };
    }
    return { message: response.error?.errorMessage };
  } catch (error) {
    return { message: 'failed' };
  }
};
