import { Flex, Text } from '@chakra-ui/react';
import { Colors } from '@hBits.Core';
import styled from 'styled-components';

const size = {
  mobileL: '425px',
};

export const device = {
  mobileL: `(max-width: ${size.mobileL})`,
};

export const CustomBtn = styled.button`
  background-color: ${Colors.bgDarkBlue};
  color: ${Colors.white};
  font-size: 17.6px;
  font-weight: 600;
  padding: 5px 32px;
  border-radius: 6px;

  &.inv {
    background-color: ${Colors.white};
    color: ${Colors.blackColor};
    border-color: ${Colors.buttonBorderGrey};
    border-width: 1px;
  }
  @media ${device.mobileL} {
    font-size: 17.6px;
  }
`;

export const CustomCheckBox = styled.div`
  cursor: pointer;
  background-color: ${Colors.bgDarkBlue};
  color: ${Colors.white};
  display: flex;
  gap: 0.5rem;
  min-width: 85px;
  width: 100%;

  padding: 5px 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  height: 36px;

  &.unchecked {
    background-color: ${Colors.white};
    color: ${Colors.blackColor};
    border: 1px solid ${Colors.borderLightGrey};
  }

  div.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 15px;
    width: 15px;
    border: 1px solid ${Colors.borderLightGrey};
    border-radius: 50%;
  }

  div.checked {
    background-color: ${Colors.orangeColor};
    color: ${Colors.bgDarkBlue};
    font-family: Roboto !important;
    font-size: 0.8rem !important;
    font-weight: 700 !important;
    border: 1px solid ${Colors.orangeColor};
  }

  div.checked::before {
  }

  div.unchecked {
    background-color: ${Colors.white};
  }
`;

export const CustomLink = styled.a`
  color: ${Colors.bgDarkBlue};
  padding: 0 0.5rem;
  text-decoration: underline;
  cursor: pointer;
  font-Weight: bold;
`;

export const ErrorMessage = styled(Text)`
  color: ${Colors.red};
  font-size: 14px;
`;
