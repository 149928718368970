import { Grid, useToast } from '@chakra-ui/react';
import { authTokenStorageProvider, Strings } from '@hBits.Core';
import { TOAST_TIMEOUT } from 'libs/src/constants';
import { getAllTeamMembers } from 'libs/src/services/modules/channel-partner';
import { useEffect, useState, createContext } from 'react';
import { TeamMemberType } from '../types/teamMember.type';
import MemberCard from '../helper-components/MemberCard';
import { eventEmitter } from 'apps/landing-pages-web/src/components/events';

export const teamMembersContext = createContext<TeamMemberType[]>([]);

interface TeamMembersProps {
  searchQuery: string;
}

const TeamMembers = ({ searchQuery }: TeamMembersProps) => {
  const toast = useToast();
  const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([]);

  const searchFilter = (teamMembers: TeamMemberType) => {
    const searchQueryTrimmed = searchQuery.trim().toLowerCase();
    if (searchQueryTrimmed === '') return true;
    if (
      `${teamMembers.firstName} ${teamMembers.lastName}`
        .toLowerCase()
        .includes(searchQueryTrimmed)
    )
      return true;
    if (teamMembers.firstName.toLowerCase().includes(searchQueryTrimmed))
      return true;
    if (teamMembers.lastName.toLowerCase().includes(searchQueryTrimmed))
      return true;
    if (teamMembers.emailAddress.toLowerCase().includes(searchQueryTrimmed))
      return true;
    if (teamMembers.mobileNumber.toLowerCase().includes(searchQueryTrimmed))
      return true;
    return false;
  };

  const fetchTeamMembers = async () => {
    const authTokenModel = authTokenStorageProvider.getAuthTokenModel();
    if (authTokenModel?.accountId) {
      const { data, error } = await getAllTeamMembers(
        authTokenModel?.accountId,
      );
      if (!error && data?.length >= 0) {
        setTeamMembers([...data]);
        return;
      }
      toast({
        title: Strings.failed,
        description: Strings.internalServerError,
        status: 'error',
        duration: TOAST_TIMEOUT,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchTeamMembers();
    eventEmitter.subscribe('teamMemberUpdated', fetchTeamMembers);
  }, []);

  return (
    <teamMembersContext.Provider value={teamMembers}>
      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']} gap={'2rem'}>
        {teamMembers.filter(searchFilter).map((teamMember) => (
          <MemberCard
            name={`${teamMember.firstName} ${teamMember.lastName}`}
            post={teamMember.post}
            mobileCountryCode={teamMember.mobileCountryCode}
            mobileNumber={teamMember.mobileNumber}
            emailAddress={teamMember.emailAddress}
            investmentValue={teamMember.investmentValue}
            numberOfClients={teamMember.numberOfClients}
            membersAccountId={teamMember.accountId}
          />
        ))}
      </Grid>
      {teamMembers.length > 0 &&
        teamMembers.filter(searchFilter).length === 0 && <p>No Record Found</p>}
    </teamMembersContext.Provider>
  );
};

export default TeamMembers;
