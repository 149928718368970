import * as React from 'react';
import { fetchPOC } from 'libs/src/services/modules/channel-partner';
import { Box, Text, Flex, Input, Image, Container } from '@chakra-ui/react';
import colors from 'libs/src/theme/colors';
import { Colors } from 'apps/customer-portal-web/src/theme';
import { authTokenStorageProvider } from '@hBits.Core';
import './styles/POC.scss';
import telephone from '../../../../../src/assets/telephone.png';
import letter from '../../../../../src/assets/letter.png';
const POC = () => {
  const [POCDetail, setPOCDetail] = React.useState<any>();
  const authToken = authTokenStorageProvider.getAuthTokenModel();
  const getPOC = async (): Promise<void> => {
    const pocDetails = await fetchPOC(authToken?.accountId);
    setPOCDetail(pocDetails);
  };
  React.useEffect(() => {
    getPOC();
  }, []);
  return (
    <div style={{ minHeight: '886px' }}>
      <div className="title">POC</div>
      <div className="card">
        <div
          className="details"
          style={{ borderBottom: '0.5px solid #b3b3b3' }}
        >
          <div className="name">
            {!POCDetail?.leadOwner?.fullName
              ? '-'
              : POCDetail?.leadOwner?.fullName}
          </div>
          <div className="designation">
            {!POCDetail?.leadOwner?.designation
              ? '-'
              : POCDetail?.leadOwner?.designation}
          </div>
        </div>
        <div className="contact">
          <div className="phone">
            <img className="phoneIcon" src={telephone} />
            <p className="phone-number">
              {!POCDetail?.leadOwner?.mobileNumber
                ? '-'
                : POCDetail?.leadOwner?.mobileNumber}
            </p>
          </div>
          <div className="phone">
            <img className="phoneIcon" src={letter} />
            <p className="phone-number">
              {!POCDetail?.leadOwner?.emailAddress
                ? '-'
                : POCDetail?.leadOwner?.emailAddress}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default POC;
