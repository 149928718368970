import React, { useEffect, useState } from 'react';
import '../index.scss';
import { useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import CpKycContainerBottomTile from '../HelperComponents/CpKycContainerBottomTile';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import {
  fetchCountryData,
  fetchStateData,
} from '../../../../../../components/Kyc/ApiData/ApiData';
import { getData, updateBasicDetails } from '../ApiData/apiData';
import accountIdManager from 'libs/src/auth/account-manager';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useToast } from '@chakra-ui/react';
import {
  capitalizeFirstLetter,
  isEmpty,
  capitalizeEachLetter,
} from '../../../../../../utils/index';
import UploadProof from 'apps/customer-portal-web/src/components/Kyc/HelperComponents/UploadProof';
import { Colors } from '@hBits.Core';

type FormInputs = {
  firstName: string;
  lastName: string;
  companyName: string;
  emailAddress: any;
  address: string;
  pincode: string;
  state: string;
  city: string;
};
interface BasicDetailsProps {
  updateStateCallback: (state: any) => void;
  kycVerified: boolean;
}

function BasicDetails(props: BasicDetailsProps) {
  const { updateStateCallback, kycVerified } = props;
  const [countryData, setCountryData] = useState<any[]>([]);
  const [countryName, setCountryName] = useState<string>();
  const [stateName, setStateName] = useState<string>();
  const [isCountry, setIsCountry] = useState('Select-Country');
  const [isSaveAndContinueClicked, setIsSaveAndContinueClicked] =
    useState(false);

  React.useEffect(() => {
    const url = baseApiUrl + endpoints.countriesList;
    fetchCountryData().then((response) => setCountryData(response));
  }, []);

  const setCountryId = () => {
    let temp;
    for (let itm = 0; itm < countryData.length; itm++) {
      if (countryData[itm].name === countryName) {
        setIsCountry(countryData[itm].id);
      }
    }
  };
  useEffect(() => {
    setCountryId();
  }, [countryName, isCountry, countryData]);

  const updateCountry = (newCountrystatus: string) => {
    setStateName('');
    setIsCountry(String(parseInt(newCountrystatus)));
  };
  const updateCountryName = (newCountryName: string) => {
    const temp = countryData.filter((itm) => itm.id == newCountryName);
    setStateName('');
    setCountryName(temp[0].name);
  };

  const updateStateName = (newStateName: string) => {
    setStateName(newStateName);
  };

  const [stateData, setStateData] = useState<any[]>([]);

  React.useEffect(() => {
    fetchStateData(isCountry).then((response) => setStateData(response));
  }, [isCountry]);

  const onCountrySelect = (e: any) => {
    setStateName('');
    updateCountry(String(e.target.value));
    updateCountryName(e.target.value);
    resetField('state');
  };
  const [countryCode, setCountryCode] = useState(12);
  const [mobileNo, setMobileNo] = useState<any>();

  const handleCountryCode = (ph: any) => {
    setMobileNo(ph);
    if (ph.slice(0, 2) !== '91') {
      setCountryCode(9);
    }
    if (countryCode !== 12 && ph.slice(0, 2) === '91') {
      setCountryCode(12);
    }
  };

  const [isCompanySelected, setIsCompanySelected] = useState(false);
  const [isIndividualSelected, setIsIndividualSelected] = useState(true);
  const [userAccountId, setUserAccountId] = useState<string>();
  const [companyLogoUrl, setCompanyLogoUrl] = useState('');
  const [companyLogoType, setCompanyLogoType] = useState<any>();
  const [errorCompanyLogoStatusRejected, setErrorCompanyLogoStatusRejected] =
    useState(false);
  const [
    errorCompanyLogoStatusRejectedMessage,
    setErrorCompanyLogoStatusRejectedMessage,
  ] = useState('');

  React.useEffect(() => {
    if (!accountIdManager?.getAccountId()) return;
    setUserAccountId(accountIdManager?.getAccountId());
    getData(accountIdManager?.getAccountId()).then((res: any) => {
      if (isEmpty(res)) return;
      if (res?.companyLogoUrl) {
        setCompanyLogoUrl(res?.companyLogoUrl);
        setCompanyLogoType(res?.companyLogoType);
      }
      setCountryName(res?.country);
      if (res?.state) {
        setStateName(res?.state);
      }
      if (res?.channelPartnerType == 'Individual') {
        setIsIndividualSelected(true);
        setIsCompanySelected(false);
      } else {
        setIsCompanySelected(true);
        setIsIndividualSelected(false);
      }

      if (res?.mobileNumber) {
        const temp = res?.mobileCountryCode + res?.mobileNumber;
        setMobileNo(temp);
      }
      reset({
        firstName: res?.firstName,
        lastName: res?.lastName,
        emailAddress: res?.emailAddress,
        companyName: res?.companyName,
        address: res?.fullAddress,
        pincode: res?.pincode,
        city: res?.city,
      });
    });
  }, []);

  const toast = useToast();
  const {
    reset,
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
  } = useForm<FormInputs>();

  const saveAndContinue = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    updateStateCallback('panbankdetails');
  };
  const onSubmit = (formData: any) => {
    setIsSaveAndContinueClicked(true);
    let tempMobileNumber = mobileNo;
    tempMobileNumber = tempMobileNumber.includes('+')
      ? tempMobileNumber.slice(1, tempMobileNumber.length)
      : tempMobileNumber;
    const mobileCountryCode: string = tempMobileNumber.slice(0, 2);

    const basicDetailsData = {
      channelPartnerType: isIndividualSelected ? 'Individual' : 'Company',
      firstName: formData.firstName,
      lastName: formData.lastName,
      companyName: formData.companyName,
      companyLogoType: companyLogoType,
      companyLogoUrl: companyLogoUrl,
      emailAddress: formData.emailAddress,
      mobileCountryCode: `+${mobileCountryCode}`,
      mobileNumber: tempMobileNumber.slice(2, 13),
      fullAddress: formData.address,
      pincode: formData.pincode,
      country: countryName,
      state: stateName,
      city: formData.city,
    };

    updateBasicDetails(userAccountId, basicDetailsData).then((response) => {
      if (response.status === 200 || response.status === 204) {
        toast({
          title: 'Success',
          description: 'Details have been Updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        saveAndContinue();
      } else {
        alert(response.error.additionalDetails);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        <div className="cpkyc_individualcompanydiv">
          <p>I am an</p>
          <button
            type="button"
            className={isIndividualSelected ? 'selected' : ''}
            onClick={() => {
              setIsCompanySelected(false);
              setIsIndividualSelected(true);
            }}
            disabled={true}
          >
            Individual
          </button>
          <button
            type="button"
            className={isCompanySelected ? 'selected' : ''}
            onClick={() => {
              setIsCompanySelected(true);
              setIsIndividualSelected(false);
            }}
            disabled={true}
          >
            Company
          </button>
        </div>
        {isCompanySelected && (
          <>
            <div className="cpkyc_margin-top-2">
              <div className="cpkyc_bottom-line">
                <input
                  type="text"
                  className="cpkyc_"
                  placeholder="*Company Name"
                  disabled={kycVerified}
                  value={capitalizeFirstLetter(watch().companyName)}
                  {...register('companyName', {
                    required: 'Company Name is required',
                    minLength: {
                      value: 3,
                      message: 'Length should be more than 3',
                    },
                  })}
                />
              </div>
              {errors.companyName ? (
                <>
                  {errors.companyName.type === 'required' && (
                    <p className="cpkyc_errorMessage">
                      {errors.companyName.message}
                    </p>
                  )}
                  {errors.companyName.type === 'minLength' && (
                    <p className="cpkyc_errorMessage">
                      {errors.companyName.message}
                    </p>
                  )}
                </>
              ) : null}
            </div>
            <UploadProof
              textForPTag="*Please upload the company logo"
              urlArgs="companyLogo"
              resetProofUrl={companyLogoUrl}
              setresetProofUrl={setCompanyLogoUrl}
              proofType={companyLogoType}
              setProofType={setCompanyLogoType}
              errorStatusRejected={false}
              errorStatusRejectedMessage={''}
              setErrorStatusRejected={setErrorCompanyLogoStatusRejected}
              setErrorStatusRejectedMessage={
                setErrorCompanyLogoStatusRejectedMessage
              }
              accountKycVerified={kycVerified}
              id="companyLogo"
              isSaveAndContinueClicked={isSaveAndContinueClicked}
            />
          </>
        )}
        <div className="cpkyc_row cpkyc_margin-top-2 cpkyc_margin-top-1_for-mobile  ">
          <div className="cpkyc_col-12 cpkyc_col-md-6">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="*First Name"
                disabled={kycVerified}
                value={capitalizeFirstLetter(watch().firstName)}
                {...register('firstName', {
                  required: 'FirstName is required',
                  minLength: {
                    value: 3,
                    message: 'Length should be more than 3',
                  },
                  pattern: {
                    value: /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
                    message: '(A-Z a-z)letters only allowed',
                  },
                })}
              />
            </div>
            {errors.firstName ? (
              <>
                {errors.firstName.type === 'required' && (
                  <p className="cpkyc_errorMessage">
                    {errors.firstName.message}
                  </p>
                )}
                {errors.firstName.type === 'minLength' && (
                  <p className="cpkyc_errorMessage">
                    {errors.firstName.message}
                  </p>
                )}
                {errors.firstName.type === 'pattern' && (
                  <p className="cpkyc_errorMessage">
                    {errors.firstName.message}
                  </p>
                )}
              </>
            ) : null}
          </div>
          <div className="cpkyc_col-12 cpkyc_col-md-6 cpkyc_margin-top-1_for-mobile ">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="*Last Name"
                disabled={kycVerified}
                value={capitalizeFirstLetter(watch().lastName)}
                {...register('lastName', {
                  required: 'LastName is required',
                  minLength: {
                    value: 3,
                    message: 'Length should be more than 3',
                  },
                  pattern: {
                    value: /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
                    message: '(A-Z a-z)letters only allowed',
                  },
                })}
              />
            </div>
            {errors.lastName ? (
              <>
                {errors.lastName.type === 'required' && (
                  <p className="cpkyc_errorMessage">
                    {errors.lastName.message}
                  </p>
                )}
                {errors.lastName.type === 'minLength' && (
                  <p className="cpkyc_errorMessage">
                    {errors.lastName.message}
                  </p>
                )}
                {errors.lastName.type === 'pattern' && (
                  <p className="cpkyc_errorMessage">
                    {errors.lastName.message}
                  </p>
                )}
              </>
            ) : null}
          </div>
        </div>
        <div className="cpkyc_row cpkyc_margin-top-2 cpkyc_margin-top-1_for-mobile ">
          <div className="cpkyc_col-12 cpkyc_col-md-6">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_ cpkyc_padding-0-5"
                placeholder="*Email Id"
                disabled={true}
                {...register('emailAddress', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Email is invalid',
                  },
                })}
              />
            </div>
            {errors.emailAddress ? (
              <>
                {errors.emailAddress.type === 'required' && (
                  <p className="cpkyc_errorMessage">Email is required</p>
                )}
                {errors.emailAddress.type === 'pattern' && (
                  <p className="cpkyc_errorMessage">Email is invalid</p>
                )}
              </>
            ) : null}
          </div>
          <div className="cpkyc_col-12 cpkyc_col-md-6 cpkyc_flex-end cpkyc_margin-top-1_for-mobile ">
            <div className="phone-input">
              <PhoneInput
                inputProps={{
                  autoFocus: false,
                  prefix: '+',
                }}
                disabled={true}
                country="in"
                placeholder="*Enter Mobile Number*"
                enableSearch
                isValid
                countryCodeEditable={false}
                value={mobileNo}
                onChange={(ph: any) => {
                  handleCountryCode(ph);
                }}
              />
            </div>
          </div>
        </div>
        <div className="cpkyc_row cpkyc_margin-top-2 cpkyc_margin-top-1_for-mobile ">
          <div className="cpkyc_col-12 cpkyc_col-md-5">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="*Address"
                disabled={kycVerified}
                value={capitalizeFirstLetter(watch().address)}
                {...register('address', {
                  required: 'Address is required',
                })}
              />
            </div>
            {errors.address ? (
              <>
                {errors.address.type === 'required' && (
                  <p className="cpkyc_errorMessage">{errors.address.message}</p>
                )}
              </>
            ) : null}
          </div>
          <div className="cpkyc_col-12 cpkyc_col-md-4 cpkyc_margin-top-1_for-mobile ">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="*Pincode"
                disabled={kycVerified}
                value={capitalizeEachLetter(watch().pincode)}
                {...register('pincode', {
                  required: 'Pincode is required',
                  maxLength: {
                    value: 20,
                    message: 'Length should not exceed 30',
                  },
                })}
              />
            </div>
            {errors.pincode ? (
              <>
                {errors.pincode.type === 'required' && (
                  <p className="cpkyc_errorMessage">{errors.pincode.message}</p>
                )}
                {errors.pincode.type === 'maxLength' && (
                  <p className="cpkyc_errorMessage">{errors.pincode.message}</p>
                )}
              </>
            ) : null}
          </div>
        </div>
        <div className="cpkyc_row cpkyc_margin-top-2 cpkyc_margin-top-1_for-mobile ">
          <div className="cpkyc_col-12 cpkyc_col-md-4">
            <div className="cpkyc_bottom-line">
              <select
                className="cpkyc_"
                onChange={onCountrySelect}
                value={isCountry}
                disabled={kycVerified}
              >
                <option
                  className="cpkyc_"
                  value="Select-Country"
                  disabled
                  selected
                  hidden
                >
                  *Select Country
                </option>
                {countryData.map((option) => (
                  <option className="cpkyc_" id={option.id} value={option.id}>
                    {capitalizeFirstLetter(option.name)}
                  </option>
                ))}
              </select>
            </div>
            {(countryName == 'Select-Country' || countryName == undefined) &&
              isSaveAndContinueClicked && (
                <p style={{ color: 'red' }}>Select a country</p>
              )}
          </div>
          <div className="cpkyc_col-12 cpkyc_col-md-4 cpkyc_margin-top-1_for-mobile ">
            <div className="cpkyc_bottom-line">
              {stateData.length > 0 && (
                <select
                  className="cpkyc_"
                  value={stateName}
                  onChange={(e) => updateStateName(e.target.value)}
                  disabled={kycVerified}
                >
                  <option value="" disabled selected hidden>
                    *State
                  </option>
                  {isCountry === 'Select-Country' && (
                    <option className="cpkyc_">-</option>
                  )}
                  {isCountry !== 'Select-Country' && (
                    <option className="cpkyc_" value="Select-Region">
                      Select Region
                    </option>
                  )}
                  {stateData.map((option) => (
                    <option
                      className="cpkyc_"
                      id={option.id}
                      value={option.name}
                    >
                      {option.name}
                    </option>
                  ))}
                </select>
              )}

              {stateData.length == 0 && (
                <input
                  type="text"
                  placeholder="*State"
                  className="kyc_ padding-0-6"
                  style={{ fontSize: '1rem', color: Colors.blackColor }}
                  disabled={kycVerified}
                  value={capitalizeFirstLetter(stateName)}
                  {...register('state', {
                    required: 'State is required',
                    pattern: {
                      value: /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
                      message: 'Letters(A-Z, a-z) are only allowed',
                    },
                  })}
                  onChange={(e) => updateStateName(e.target.value)}
                />
              )}
            </div>
            {(stateName == 'Select-Region' || stateName == undefined) &&
              isSaveAndContinueClicked && (
                <p style={{ color: 'red' }}>State is Required</p>
              )}
            {errors.state ? (
              <>
                {errors.state.type === 'pattern' && (
                  <p style={{ color: 'red' }}>{errors.state.message}</p>
                )}
              </>
            ) : null}
          </div>
          <div className="cpkyc_col-12 cpkyc_col-md-4 cpkyc_flex-end cpkyc_margin-top-1_for-mobile ">
            <div className="cpkyc_bottom-line">
              <input
                type="text"
                className="cpkyc_"
                placeholder="*City"
                disabled={kycVerified}
                value={capitalizeFirstLetter(watch().city)}
                {...register('city', {
                  required: 'City is Required',
                  pattern: {
                    value: /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
                    message: 'Letters(A-Z, a-z) are only required',
                  },
                })}
              />
            </div>
            {errors.city ? (
              <>
                {errors.city.type === 'required' && (
                  <p className="cpkyc_errorMessage">{errors.city.message}</p>
                )}
                {errors.city.type === 'pattern' && (
                  <p className="cpkyc_errorMessage">{errors.city.message}</p>
                )}
              </>
            ) : null}
          </div>
        </div>
      </>
      <div>
        <CpKycContainerBottomTile kycVerified={kycVerified} />
      </div>
    </form>
  );
}
export default BasicDetails;
