enum SignUpAttemptSources {
  Enquiry = 'Enquiry',
  SignUp = 'SignUp',
  Callback = 'Callback',
  Partner = 'Partner',
  Property = 'Property',
  AIF = 'AIF',
  Moneycontrol = 'Moneycontrol',
  NRI = 'NRI',
}

export default SignUpAttemptSources;
