import {
  Flex,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
  Link,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import { Environment } from '@hBits.Core';
import * as React from 'react';

const FooterdataPreLeasedProperty = () => {
  const landingPagesHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
  );
  return (
    <Flex flexDirection="column" color="#94969f" marginTop={'100px'}>
      <h2>Preleased property & its appeal in the real estate market</h2>
      <Text>
        Fractional ownership of preleased property has emerged as an appealing
        concept in the ever-evolving real estate market. Preleased properties
        refer to properties that have already been leased out to tenants,
        generating a stable and predictable income stream.
        <br />
        <br />
        This unique investment opportunity allows individuals to own a fraction
        of a preleased real estate, enabling them to benefit from rental income
        and potential property appreciation without the burden of sole
        ownership.
        <br />
        <br />
        The appeal of fractional ownership lies in its potential to provide
        diversification, reduced risk, and access to premium properties that may
        have otherwise been unaffordable.
        <br />
        <br />
        Furthermore,{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/fractional-ownership-investment'}
        >
          fractional ownership
        </Link>{' '}
        offers a hassle-free investment experience as property management and
        tenant-related responsibilities are typically handled by professional
        management companies.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Potential of preleased property for passive income & attractive returns
      </h2>
      <Text>
        Fractional ownership of preleased properties for sale is important for
        generating passive income and attractive returns. When you invest in a
        preleased property, it means the property is already rented out,
        ensuring a steady flow of rental income right away.
        <br />
        <br />
        By owning a fraction of the property, you can benefit from the real
        estate market without the hassle of managing the property yourself.
        <br />
        <br />
        It's a great way to diversify your investment portfolio and access
        properties that may have been out of reach otherwise. Plus, when the
        time comes to sell, preleased properties often have the potential for
        increased value, offering the opportunity for appealing returns.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Concept of preleased commercial property and its advantages
      </h2>
      <Text>
        The concept of preleased commercial property and its advantages make it
        an appealing investment option. Here are the benefits in a nutshell:
      </Text>
      <UnorderedList>
        <ListItem>
          Steady Rental Income: Investing in preleased commercial real estate
          property means you start earning rental income from day one, providing
          a reliable and consistent cash flow.
        </ListItem>
        <ListItem>
          Reduced Risk: As the property is already leased, you have greater
          certainty about the income potential, minimizing the risk of extended
          vacancies or non-payment.
        </ListItem>
        <ListItem>
          Diversification: Fractional ownership allows you to diversify your
          investment portfolio by owning a fraction of multiple commercial
          properties, reducing overall risk exposure.
        </ListItem>
      </UnorderedList>
      <h2 style={{ marginTop: '20px' }}>
        Guide on how to find preleased commercial property for sale
      </h2>
      <Text>
        When embarking on the search for a preleased{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/commercial-real-estate-investment'}
        >
          commercial property for sale
        </Link>
        , it is essential to follow a systematic approach to ensure a successful
        investment. Here is a guide to assist you in this endeavor.
      </Text>
      <OrderedList>
        <ListItem>
          Conduct thorough market research to identify potential locations and
          property types that align with your investment goals.
        </ListItem>
        <ListItem>
          Seek the assistance of experienced real estate agents or brokers who
          specialize in commercial properties.
        </ListItem>
        <ListItem>
          Online real estate platforms often provide comprehensive property
          details, financial data, and tenant information, enabling you to make
          informed decisions.
        </ListItem>
        <ListItem>
          Consider hBits: In your search for preleased commercial properties,
          hBits emerges as a smart and convenient option. With their experienced
          team and focus on curated real estate portfolios, hBits can guide you
          through the entire process, from property selection to acquisition.
          Their expertise ensures reliable tenant relationships and hassle-free
          management.
        </ListItem>
      </OrderedList>
      <h2 style={{ marginTop: '20px' }}>
        Things to consider while looking for a preleased commercial property for
        sale
      </h2>
      <Text>
        When searching for a preleased commercial property for sale, it is
        crucial to consider key factors that contribute to a successful
        investment. Assessing the location for its accessibility and
        suitability, reviewing the lease agreement terms, inspecting the
        property's condition, conducting market analysis to understand rental
        income growth potential, and evaluating future potential for
        appreciation and expansion opportunities are essential considerations.
        By carefully considering these factors, you can make an informed
        decision and secure a pre-leased property that aligns with your business
        needs and investment goals.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Evaluating preleased property for sale
      </h2>
      <Text>
        When considering financing options for a preleased property for sale, it
        is important to explore various avenues to make a well-informed
        decision. Traditional financing options such as bank loans or mortgages
        can be viable choices, as they offer competitive interest rates and
        structured repayment plans.
        <br />
        <br />
        However, it is also worth exploring alternative financing options like
        real estate investment trusts (REITs) or crowdfunding platforms that
        provide access to pooled capital. These options may offer flexibility,
        diversification, and the opportunity to invest with a smaller capital
        outlay. Careful consideration of the financing options available will
        enable you to select the one that aligns with your investment strategy
        and financial objectives when purchasing a preleased warehouse.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Introduction to preleased warehouse for sale
      </h2>
      <Text>
        A preleased warehouse for sale, as the name suggests, refers to a
        warehouse property that already has a tenant in place, ensuring a steady
        rental income from day one. This enticing investment option combines the
        stability of a leased property with the potential for long-term capital
        appreciation.
        <br />
        <br />
        These warehouses are carefully selected, offering desirable locations,
        robust infrastructure, and reputable tenants. Investors are drawn to the
        reliability and convenience of preleased warehouses, as they eliminate
        the uncertainties associated with finding tenants and negotiating lease
        agreements.
        <br />
        <br />
        With a sophisticated approach and meticulous evaluation of factors like
        tenant quality, lease agreement terms, rental income sustainability,
        property condition, and location, investors can secure a pre-leased
        warehouse that promises steady income and future growth. Embark on this
        journey of prudent investment as we delve into the realm of preleased
        warehouse for sale.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Finding a preleased warehouse for sale
      </h2>
      <Text>
        When seeking to invest in preleased commercial properties, finding a pre
        leased warehouse for sale requires a methodical approach. Begin by
        researching the market to identify areas with high demand and growth
        potential.
        <br />
        <br />
        Engage with real estate professionals specialising in commercial
        properties for valuable insights and access to preleased warehouse for
        sale. Network with industry connections and property owners to discover
        off-market opportunities.
        <br />
        <br />
        Perform due diligence on tenant credibility, lease terms, property
        condition, and location suitability. Consider your budget and explore
        financing options. By following these steps, you can effectively
        navigate the search process and find a suitable preleased warehouse for
        investment in the realm of commercial properties.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Step by step process to buy preleased property
      </h3>
      <Text>
        To{' '}
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/properties'}
        >
          buy preleased property for investment
        </Link>
        , follow these simplified steps:
      </Text>
      <OrderedList>
        <ListItem>
          Research: Explore the real estate market to identify high-demand
          areas.
        </ListItem>
        <ListItem>
          Engage Professionals: Seek guidance from specialised agents or
          brokers.
        </ListItem>
        <ListItem>
          Property Selection: Choose a preleased property aligned with your
          investment goals.
        </ListItem>
        <ListItem>
          Due Diligence: Evaluate property condition, lease agreements, and
          tenant history.
        </ListItem>
        <ListItem>
          Financing Options: Explore suitable financing options.
        </ListItem>
        <ListItem>
          Offer and Negotiation: Make an offer and negotiate with the seller.
        </ListItem>
        <ListItem>
          Closing: Complete necessary paperwork and finalise the transaction.
        </ListItem>
      </OrderedList>
      <Text>
        By following this step-by-step process, you can buy preleased property
        for investment in a structured and informed manner.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Documents requirements to buy preleased property
      </h3>
      <Text>
        Here is a simple tabular comparison of the documents typically required
        to buy preleased property:
      </Text>
      <Table variant="striped" border="2px solid" borderWidth="1px" mt={'20px'}>
        <Tbody>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Document type </Td>
            <Td border="2px solid">Buyer’s Requirements </Td>
            <Td>Seller’s Requirements</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Sale Agreement</Td>
            <Td border="2px solid">Required</Td>
            <Td>Required</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Title Deed</Td>
            <Td border="2px solid">Required</Td>
            <Td>Provided by Seller</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Lease Agreement </Td>
            <Td border="2px solid">Not Applicable</Td>
            <Td>Provided by Seller</Td>
          </Tr>
          <Tr border="2px solid" textAlign="center" blockSize={'50px'}>
            <Td border="2px solid">Property Tax Receipts</Td>
            <Td border="2px solid">Not Applicable</Td>
            <Td>Provided by Seller</Td>
          </Tr>
        </Tbody>
      </Table>
      <Text mt={'20px'}>
        Please note that the specific documents required may vary depending on
        local regulations and the nature of the transaction. It is recommended
        to consult with legal professionals and real estate experts for accurate
        and comprehensive guidance tailored to your specific situation to buy
        preleased property
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Benefits to invest in preleased commercial properties
      </h2>
      <Text>
        Investing in preleased commercial properties, particularly preleased
        warehouses, offers numerous benefits. Firstly, it provides an immediate
        and steady rental income stream, ensuring a predictable cash flow. The
        existing lease agreements mitigate the risk of tenant selection and
        vacancy.
        <br />
        <br />
        Moreover, preleased commercial properties often attract reputable
        tenants, enhancing the stability of the investment. These properties
        also have the potential for appreciation in value over time, allowing
        investors to benefit from rental income and capital gains.
        <br />
        <br />
        Additionally, investing in preleased warehouses offers a passive
        investment opportunity, as property management and lease-related
        responsibilities are typically handled by professional management
        companies.
        <br />
        <br />
        Overall, it provides the potential for consistent income, reduced risk,
        and long-term wealth accumulation, making it an attractive option for
        investors seeking stability and returns in the commercial real estate
        market.
      </Text>

      <h2 style={{ marginTop: '20px' }}>
        Evaluating preleased property for investment
      </h2>
      <Text>
        Evaluating preleased property for investment requires a meticulous and
        informed approach.
      </Text>
      <Text>
        <strong>Advantages:</strong>
      </Text>
      <OrderedList>
        <ListItem>
          Immediate Rental Income: Investors benefit from an established rental
          income stream right from the start.
        </ListItem>
        <ListItem>
          Reduced Risk: Lease agreements provide stability and mitigate the risk
          of tenant selection and vacancies.
        </ListItem>
      </OrderedList>
      <Text>
        <strong>Disadvantages:</strong>
      </Text>
      <OrderedList>
        <ListItem>
          Limited Flexibility: Investors may have limited control over lease
          terms and tenant selection.
        </ListItem>
        <ListItem>
          Dependence on Tenant: The success of the investment relies on the
          reliability and financial stability of the tenant.
        </ListItem>
      </OrderedList>
      <Text>
        Despite the potential limitations, evaluating preleased commercial
        property for investment offers attractive advantages, including
        immediate rental income, reduced risk, potential appreciation, and
        passive investment opportunities.
      </Text>
      <h2 style={{ marginTop: '20px' }}>
        Strategies for successful preleased property for investment
      </h2>
      <Text>
        To ensure a successful preleased property for investment, consider the
        following strategies:
      </Text>
      <UnorderedList>
        <ListItem>
          Thorough Due Diligence: Conduct a comprehensive analysis of the
          property, lease terms, tenant credibility, and potential for rental
          income growth.
        </ListItem>
        <ListItem>
          Location Selection: Choose preleased properties in areas with strong
          market demand, economic growth, and development prospects.
        </ListItem>
        <ListItem>
          Diversification: Invest in a diversified portfolio of preleased
          properties to spread risk across different locations and sectors.
        </ListItem>
        <ListItem>
          Long-Term Vision: Focus on long-term investment goals and consider
          factors such as property appreciation and potential lease renewals.
        </ListItem>
        <ListItem>
          Professional Guidance: Seek advice from real estate professionals,
          legal experts, and financial advisors with experience in preleased
          property investments.
        </ListItem>
      </UnorderedList>
      <h2 style={{ marginTop: '20px' }}>
        Why choose hBits to invest in preleased commercial properties
      </h2>
      <Text>
        <Link
          color={'#2B6CB0'}
          fontStyle="italic"
          fontWeight="bold"
          href={landingPagesHostingRoot + '/'}
        >
          hBits
        </Link>{' '}
        emerges as a compelling choice for investing in preleased commercial
        properties due to its comprehensive approach and experienced team.
        However, it is important to acknowledge that all investments come with
        inherent risks. Potential risks in pre-leased properties include tenant
        defaults, market fluctuations, and property maintenance issues.
        <br />
        <br />
        These risks can be mitigated through hBits' thorough due diligence
        process, which includes evaluating tenant quality, lease agreements, and
        property conditions. Additionally, hBits' diversification strategy
        across multiple properties and locations helps to reduce risk exposure.
        Investors should carefully review hBits' reports, assess their risk
        appetite, and seek professional advice to make informed investment
        decisions and mitigate potential risks effectively.
      </Text>
      <h2 style={{ marginTop: '20px' }}>Frequently Asked Questions</h2>
      <h3 style={{ marginTop: '20px' }}>
        Q1. How does a preleased property work?
      </h3>
      <Text>
        A preleased property is like a promising venture with a head start. It
        involves a property that has already been leased to a tenant before it
        is even constructed or completed. Essentially, it's an agreement where
        the tenant commits to leasing the property in advance, providing a
        guaranteed income stream for the owner.
        <br />
        <br />
        This arrangement allows investors to minimize risks and enjoy a stable
        cash flow from day one. It's like having a ticket to success before the
        curtains even rise!
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q2. Why invest in a preleased property?
      </h3>
      <Text>
        Investing in a preleased property is a savvy move for those seeking
        stability and assured returns. With this property, you're not starting
        from scratch; instead, you step into an established income stream right
        from the beginning. This means you can bypass the uncertainties and
        risks associated with finding tenants and waiting for rental income to
        flow in.
        <br />
        <br />
        By opting for it, you secure a ready-made investment opportunity that
        promises a seamless path to profitability. It's like hitting the ground
        running, ensuring a smooth and engaging journey in the world of real
        estate investment.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q3. How is a traditional property different from a preleased property
        for sale?
      </h3>
      <Text>
        A traditional property and a preleased property for sale differ in their
        income potential and risk profile. In a traditional property, the buyer
        assumes the responsibility of finding tenants and generating rental
        income after the purchase. This process entails uncertainty and
        potential vacancies, affecting cash flow.
        <br />
        <br />
        On the other hand, this type of property already has a tenant in place,
        providing an established income stream from day one. This mitigates the
        risk of tenant selection and vacancy periods, offering investors a more
        secure and predictable return on investment. Opting for a preleased
        property ensures a professional and engaging investment experience with
        a higher level of stability.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q4. What should I look for in the due diligence process for a preleased
        property for sale?
      </h3>
      <Text>
        When conducting due diligence for a preleased property for sale, several
        key factors deserve careful consideration.
      </Text>
      <OrderedList>
        <ListItem>
          Evaluate the strength and stability of the existing lease agreement,
          examining its duration, rental escalation clauses, and the financial
          health of the tenant.
        </ListItem>
        <ListItem>
          Assess the location and market dynamics to ensure sustained demand for
          the property.
        </ListItem>
        <ListItem>
          Scrutinise the property's condition and any necessary repairs or
          maintenance obligations. Furthermore, analyze the historical rental
          performance and potential for future growth.
        </ListItem>
        <ListItem>
          Review the property's legal and regulatory compliance. Thoroughly
          examining these aspects will enable you to make an informed investment
          decision.
        </ListItem>
      </OrderedList>
      <h3 style={{ marginTop: '20px' }}>
        Q5. How can I find a preleased commercial property for sale?
      </h3>
      <Text>
        Finding a preleased commercial property for sale requires a strategic
        approach. Begin by engaging with reputable real estate agents or brokers
        who specialize in preleased properties. They possess extensive market
        knowledge and can provide you with a list of available options.
        <br />
        <br />
        Online platforms and commercial property listing websites can also be
        valuable resources in your search. Networking with industry
        professionals and attending real estate events can lead to potential
        opportunities.
        <br />
        <br />
        Additionally, keeping an eye on property auctions and investment
        newsletters can uncover hidden gems. Remember, patience and persistence
        are key when seeking out the perfect property.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q6. What are the market trends and future potential of a preleased
        commercial property for sale?
      </h3>
      <Text>
        The market trends and future potential of a preleased commercial
        property for sale are promising. As businesses continue to seek reliable
        spaces to operate, the demand for these properties remains strong.
        <br />
        <br />
        These properties offer stability, assured rental income, and reduced
        vacancy risks. Moreover, with the potential for rental escalations and
        lease renewals, investors can expect steady cash flow and potential
        appreciation over time.
        <br />
        <br />
        The commercial sector's growth, coupled with the convenience and
        attractiveness of preleased properties, makes them an enticing
        investment option. By capitalizing on these market trends, investing in
        such a property holds significant potential for long-term financial
        success.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q7. What is a preleased warehouse for sale?
      </h3>
      <Text>
        It is a lucrative investment opportunity in the commercial real estate
        market. It refers to a warehouse facility that has already been leased
        to a tenant before being offered for sale. This arrangement provides a
        buyer with the advantage of an established income stream from rental
        payments.
        <br />
        <br />
        Preleased warehouses are in high demand due to the growing logistics and
        e-commerce sectors, making them an attractive option for investors
        seeking stable returns. By acquiring a preleased warehouse for sale,
        investors can secure a ready-made asset with a promising future in the
        ever-expanding world of warehousing and distribution.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q8. How can I find a preleased warehouse for sale?
      </h3>
      <Text>
        When searching for a preleased warehouse for sale, a systematic approach
        can yield fruitful results. Begin by connecting with reputable
        commercial real estate brokers who specialize in preleased properties.
        They possess valuable market knowledge and extensive networks to help
        you find suitable options.
        <br />
        <br />
        Online platforms and commercial property listing websites are also
        valuable resources in your search. Networking within the logistics and
        warehousing industry, attending industry conferences, and engaging with
        industry professionals can uncover hidden opportunities.
        <br />
        <br />
        Staying updated on property auctions and subscribing to industry
        newsletters can provide additional leads. By employing these strategies,
        you can enhance your chances of finding the perfect warehouse to meet
        your investment objectives.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q9. What factors should I consider when I buy preleased property?
      </h3>
      <Text>
        Several factors deserve careful consideration when deciding to buy
        preleased property.
      </Text>
      <OrderedList>
        <ListItem>
          Evaluate the strength and stability of the existing lease agreement,
          including its duration, rental escalations, and tenant's financial
          health.
        </ListItem>
        <ListItem>
          Analyze the location and market dynamics to ensure sustained demand
          for the property.
        </ListItem>
        <ListItem>
          Scrutinize the property's condition and any maintenance obligations
          and furthermore, assess the historical rental performance and
          potential for future growth.
        </ListItem>
        <ListItem>
          Legal and regulatory compliance should also be thoroughly examined.
        </ListItem>
      </OrderedList>
      <Text>
        By diligently considering these factors, you can make an informed
        investment decision and maximize your chances of a successful and
        profitable venture.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q10. How can I ensure a smooth transaction when I buy preleased
        property?
      </h3>
      <Text>
        To ensure a smooth transaction when you buy preleased property, it's
        crucial to follow a few essential steps. First, conduct thorough due
        diligence, examining lease agreements, tenant financials, property
        conditions, and legal compliance.
        <br />
        <br />
        Engage the services of experienced professionals such as real estate
        lawyers, appraisers, and property inspectors to guide you through the
        process. Negotiate the terms and conditions carefully, including
        purchase price, lease transfer, and any necessary repairs.
        <br />
        <br />
        Seek clarity on any ongoing obligations or maintenance responsibilities.
        Finally, ensure all necessary documentation is in order and work closely
        with the seller and other parties involved to facilitate a seamless
        closing.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q11. What financing options are available to invest in preleased
        commercial properties?
      </h3>
      <Text>
        When looking to invest in preleased commercial properties, there are
        various financing options to consider. Traditional bank loans offer
        competitive interest rates and terms, requiring a strong credit history
        and collateral.
        <br />
        <br />
        Private lenders and real estate investment groups provide alternative
        financing solutions with greater flexibility. Seller financing allows
        for direct negotiation with the property owner.
        <br />
        <br />
        Crowdfunding platforms have also gained popularity, pooling funds from
        multiple investors. Exploring these financing avenues enables investors
        to seize the opportunity and maximize their potential returns.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q12. Are there any tax benefits or incentives to invest in preleased
        commercial properties?
      </h3>
      <Text>
        Investors in preleased commercial properties may enjoy tax benefits and
        incentives. Depreciation of the property's value can provide tax
        deductions, reducing taxable income.
        <br />
        <br />
        Additionally, expenses related to property maintenance, repairs, and
        operating costs may be tax-deductible. Some jurisdictions offer
        incentives such as tax credits or exemptions to encourage investments in
        specific industries or regions.
        <br />
        <br />
        It is advisable to consult with a tax professional to understand the
        specific tax benefits, ensuring you maximize your returns while staying
        compliant with tax regulations.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q13. Are there any specific industries or sectors that offer attractive
        opportunities of preleased property for investment?
      </h3>
      <Text>
        Certain industries or sectors present attractive opportunities for pre
        leased property for investment. The logistics and e-commerce sectors,
        for instance, experience consistent growth and demand for warehousing
        and distribution centres.
        <br />
        <br />
        Healthcare facilities, including medical offices and clinics, provide
        stable and long-term lease agreements. Retail spaces in prime locations
        with reputable anchor tenants also offer promising prospects.
        <br />
        <br />
        Additionally, sectors such as technology, manufacturing, and education
        often require specialised commercial properties, creating potential
        investment opportunities. By targeting these specific industries or
        sectors, investors can optimize their returns in the dynamic real estate
        market.
      </Text>
      <h3 style={{ marginTop: '20px' }}>
        Q14. How can I stay updated on the latest market trends of preleased
        property for investment?
      </h3>
      <Text>
        Staying updated on the latest market trends of preleased property for
        investment requires an active approach. Engage with reputable real
        estate publications, industry-specific magazines, and online forums to
        access insightful articles and expert opinions.
        <br />
        <br />
        Follow influential real estate professionals and organisations on social
        media platforms for timely updates. Attend industry conferences,
        seminars, and webinars to gain valuable market insights from industry
        leaders. Networking with fellow investors and professionals in the real
        estate sector can provide firsthand information on emerging
        opportunities.
      </Text>
    </Flex>
  );
};

export default FooterdataPreLeasedProperty;
