import { Box } from '@chakra-ui/react';
import { isWebView } from '@hbits.lib/util';
import { Header } from 'libs/src/components/Header';
import { FC } from 'react';
import Footer from '../../../../../libs/src/components/Footer';
import { environment } from '../../environments/environment';
interface IDashboardLayout {
  children: JSX.Element;
}

const BaseLayout: FC<IDashboardLayout> = ({ children }) => {
  const isWebview = isWebView();
  return (
    <>
      <Header env={environment} />
      <Box className="overflow-hidden dashboard-container">
        <Box className="dashboard-main">{children}</Box>
      </Box>
      {!isWebview && <Footer />}
    </>
  );
};

export default BaseLayout;
