import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Colors, EOIDocumentSigningStatus, Environment } from '@hBits.Core';
import RuppeIcon from 'apps/customer-portal-web/src/assets/RuppeIcon.svg';
import { EditIcon } from 'apps/customer-portal-web/src/assets/customIcons';
import CustomButton from 'apps/customer-portal-web/src/components/CustomButton';
import {
  deleteTopUp,
  getBillDeskJWT,
  getPaymentUrl,
  getTopUpPaymentUrl,
  setEoiDocumentSucess,
  setEoiTopUpDocumentSucess,
  updateInvestmentAmount,
  updateMinTokenAmount,
  updateMinTopUpTokenAmount,
  updateTopAmount,
} from 'apps/customer-portal-web/src/services/propertyInvestment/eoiStepServices';
import { setInvestmentDetails } from 'apps/customer-portal-web/src/store/actions';
import colors from 'apps/customer-portal-web/src/theme/colors';
import { BilldeskPaymentDetails } from 'apps/customer-portal-web/src/types';
import { NUM_FORMATTING } from 'apps/landing-pages-web/src/components/common/utils/helper';
import { Form, Formik, useFormik } from 'formik';
import jwtDecode from 'jwt-decode';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BillDeskLogo from '../../../../assets/billdesk-logo.svg';
import CashFreeLogo from '../../../../assets/cash-free-logo.svg';
import CustomFrame from '../../CustomFrame';
import PaymentHistory from '../../paymentHistory';
import PaymentProgress from '../../paymentProgress';
interface EOIStep1Props {
  openZoho?: any;
  minTicketSizeApplied?: string;
  investmentAmountToBePaidAtEOI?: string;
  investmentAmount?: string;
  minPercentageToPaid?: string;
  investmentId?: string;
  eoiDocumentSigningStatus?: string;
  minInvestmentAmountToBePaidAtEOI?: string;
  topUpAmountEoi?: any;
  investmentSlabApplied?: string;
  eoiDocLoader?: boolean;
  maxTicketSizeApplied?: string;
  eoiInvestmentAmountPaid?: string;
  investmentAmountEoi?: any;
}

interface FromikValues {
  totalInvestAmount: string;
  initialTokenAmount: string;
}

const EOIStep1: FC<EOIStep1Props> = (props) => {
  const {
    openZoho,
    investmentAmountToBePaidAtEOI,
    minTicketSizeApplied,
    investmentAmount,
    minPercentageToPaid,
    investmentId = '',
    eoiDocumentSigningStatus,
    minInvestmentAmountToBePaidAtEOI = '0',
    topUpAmountEoi,
    eoiDocLoader,
    maxTicketSizeApplied,
    eoiInvestmentAmountPaid = '0',
  } = props;
  const dispatch = useDispatch();
  const toast = useToast();
  const isTopUp = Object.keys(topUpAmountEoi || {}).length > 0;
  const [amountLoader, setAmountLoader] = useState<boolean>(false);
  const [eoiPaymentLoader, setEoiPaymmentLoader] = useState<boolean>(false);
  const [draftEoiModal, setDraftEoiModal] = useState(false);
  const [displayPayInitialOptions, setDisplayPayInitialOptions] =
    useState<boolean>(false);

  const documentSigningError = (error: string) => {
    toast({
      title: 'Error',
      description: error,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const propertyDetails = useSelector(
    (state: any) => state.InvestmentReducer?.propertyDetails || {},
  );

  const activeSPVIndex = propertyDetails?.propertyInvestment?.spvs.findIndex(
    (investmentDetails: { isActive: Boolean }) => investmentDetails.isActive,
  );

  const investmentModal = useSelector(
    (state: any) => state.InvestmentReducer?.propertyData,
  );

  const getInitialValues: () => FromikValues = () => {
    var initialValues = {
      totalInvestAmount: isTopUp
        ? topUpAmountEoi?.investmentAmount || ''
        : investmentAmount || '',
      initialTokenAmount: isTopUp
        ? (
            parseFloat(topUpAmountEoi?.minInvestmentAmountToBePaidAtEOI) -
            parseFloat(topUpAmountEoi?.eoiInvestmentAmontPaid)
          ).toString() || ''
        : (
            parseFloat(minInvestmentAmountToBePaidAtEOI) -
            parseFloat(eoiInvestmentAmountPaid)
          ).toString() || '',
    };
    return initialValues;
  };

  const SignupSchema = Yup.object().shape({
    totalInvestAmount: Yup.number()
      .min(
        parseInt(minTicketSizeApplied || ''),
        `*Please input a minimum amount of ₹${minTicketSizeApplied}`,
      )
      .max(
        parseInt(maxTicketSizeApplied || ''),
        `*Please input amount less than  ₹${maxTicketSizeApplied}`,
      ),
    initialTokenAmount: Yup.number().max(
      parseInt(investmentAmount || ''),
      `*Please input amount less than  ₹${investmentAmount}`,
    ),
  });
  const SignupTopUpSchema = Yup.object().shape({
    totalInvestAmount: Yup.number().min(
      parseInt(investmentModal?.minimumTopUpSizeApplied),
      `*Please input a minimum amount of ₹${investmentModal?.minimumTopUpSizeApplied}`,
    ),
    initialTokenAmount: Yup.number()
      // .min(
      //   parseInt(topUpAmountEoi?.minInvestmentAmountToBePaidAtEOI),
      //   `*Please input amount more than  ₹${topUpAmountEoi?.minInvestmentAmountToBePaidAtEOI}`,
      // )
      .max(
        parseInt(topUpAmountEoi?.investmentAmount),
        `*Please input amount less than  ₹${topUpAmountEoi?.investmentAmount}`,
      ),
  });

  const handleSubmit = () => {
    if (eoiDocumentSigningStatus === EOIDocumentSigningStatus.InProgress) {
      closeEDocumentModal();
    }
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: isTopUp ? SignupTopUpSchema : SignupSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const {
    handleChange,
    values,
    errors,
    touched,
    isValid,
    handleSubmit: handleSubmitFormData,
  } = formik;

  const updateInvmentAmount = async (
    investAmount: string,
    successCallback: () => void,
  ) => {
    setAmountLoader(true);
    const updatedAmount = await updateInvestmentAmount(
      investmentId,
      investAmount,
      investmentModal,
      (error: string) => {
        toast({
          title: 'Error',
          description: error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
      successCallback,
    );
    dispatch(setInvestmentDetails(updatedAmount));
    setAmountLoader(false);
  };

  const updateTopUpAmount = async (
    topUpAmount: string,
    successCallback: () => void,
  ) => {
    try {
      setAmountLoader(true);
      const updatedAmount = await updateTopAmount(
        investmentId,
        topUpAmount,
        investmentModal,
        (error: string) => {
          toast({
            title: 'Error',
            description: error,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        },
        successCallback,
      );
      dispatch(setInvestmentDetails(updatedAmount));
      setAmountLoader(false);
    } catch (error) {}
  };

  const deleteTopUpInvestment = async () => {
    try {
      setAmountLoader(true);
      const updatedAmount = await deleteTopUp(investmentId, (error: string) => {
        toast({
          title: 'Error',
          description: error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
      dispatch(setInvestmentDetails(updatedAmount));
      setAmountLoader(false);
    } catch (error) {}
  };

  const updateMinToknAmount = async (tokenAmount: string) => {
    try {
      setAmountLoader(true);
      const updatedAmount = await updateMinTokenAmount(
        investmentId,
        tokenAmount,
        investmentModal,
        (error: string) => {
          toast({
            title: 'Error',
            description: error,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        },
      );
      dispatch(setInvestmentDetails(updatedAmount));
      setAmountLoader(false);
    } catch (error) {}
  };

  const updateMinTopupToknAmount = async (tokenAmount: string) => {
    try {
      setAmountLoader(true);
      const updatedAmount = await updateMinTopUpTokenAmount(
        investmentId,
        tokenAmount,
        investmentModal,
        (error: string) => {
          toast({
            title: 'Error',
            description: error,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        },
      );
      dispatch(setInvestmentDetails(updatedAmount));
      setAmountLoader(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (eoiDocumentSigningStatus === EOIDocumentSigningStatus.InProgress) {
      closeEDocumentModal();
    }
  }, []);

  const closeEDocumentModal = async () => {
    if (isTopUp) {
      const signedDocument = await setEoiTopUpDocumentSucess(
        investmentId,
        investmentModal,
        (error: string) => {
          documentSigningError(error);
        },
      );
      dispatch(setInvestmentDetails(signedDocument));
    } else {
      const signedDocument = await setEoiDocumentSucess(
        investmentId,
        investmentModal,
        (error: string) => {
          documentSigningError(error);
        },
      );
      dispatch(setInvestmentDetails(signedDocument));
    }
  };

  const isZohoCompleted = () => {
    if (eoiDocumentSigningStatus !== EOIDocumentSigningStatus.Signed) {
      return true;
    }
    return false;
  };

  const isTopUpZohoCompleted = () => {
    if (
      topUpAmountEoi?.eoiDocumentSigningStatus !==
      EOIDocumentSigningStatus.Signed
    ) {
      return true;
    }
    return false;
  };

  const paymentError = (error: string) => {
    toast({
      title: 'Error',
      description: error,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const payCashFree = async (initialTokenAmount: string) => {
    try {
      setEoiPaymmentLoader(true);
      if (isTopUp) {
        await updateMinTopupToknAmount(initialTokenAmount);
        const cashFreePaymentUrl = await getTopUpPaymentUrl(
          investmentId,
          (error: string) => {
            paymentError(error);
          },
        );
        if (!Array.isArray(cashFreePaymentUrl)) {
          window.location.replace(cashFreePaymentUrl?.pgUrl);
        }
      } else {
        await updateMinToknAmount(initialTokenAmount);
        const cashFreePaymentUrl = await getPaymentUrl(
          investmentId,
          (error: string) => {
            paymentError(error);
          },
        );
        if (!Array.isArray(cashFreePaymentUrl)) {
          window.location.replace(cashFreePaymentUrl?.pgUrl);
        }
      }
    } finally {
      setEoiPaymmentLoader(false);
    }
  };

  const payUsingBillDesk = async (initialTokenAmount: string) => {
    setEoiPaymmentLoader(true);

    try {
      await updateMinToknAmount(initialTokenAmount);
      const billdeskPayment = await getBillDeskJWT(
        investmentId,
        (error: string) => {
          paymentError(error);
        },
      );

      if (billdeskPayment) {
        const billdeskPaymentDetails: BilldeskPaymentDetails =
          jwtDecode(billdeskPayment);
        const { bdorderid, mercid, links } = billdeskPaymentDetails;
        const { parameters } = links[1];
        const { rdata } = parameters;

        const form = document.forms['sdklaunch'];

        console.log(billdeskPaymentDetails, 'create order response');

        form.setAttribute(
          'action',
          Environment.getInstance().getParam(
            'NEXT_PUBLIC_BILDESK_PAYMENT_PROVIDER_BASE_URL',
          ),
        );
        form.elements['bdorderid'].value = bdorderid;
        form.elements['merchantid'].value = mercid;
        form.elements['rdata'].value = rdata;
        form.submit();
      }
    } finally {
      setEoiPaymmentLoader(false);
    }
  };

  const checkPaymentOptions = ({
    initialTokenAmount,
  }: {
    initialTokenAmount: string;
  }) => {
    const { pgProvider } = propertyDetails;

    if (pgProvider.length === 2) {
      setDisplayPayInitialOptions(true);
    } else {
      setDisplayPayInitialOptions(false);
      pgProvider[0] === 'BillDesk'
        ? payUsingBillDesk(initialTokenAmount)
        : payCashFree(initialTokenAmount);
    }
  };

  //need to test on dev
  // useEffect(() => {
  //   // Trigger the API call when the component mounts and default values are set
  //   const initialValues = getInitialValues();
  //   if (initialValues.initialTokenAmount) {
  //     updateMinToknAmount(initialValues.initialTokenAmount);
  //   }
  //   // if (initialValues.totalInvestAmount) {
  //   //   updateInvmentAmount(initialValues.totalInvestAmount);
  //   // }
  // }, []);

  //need to test in dev
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (propertyDetails && investmentModal) {
      setIsDataReady(true);
    }
  }, [propertyDetails, investmentModal]);

  if (!isDataReady) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    ); // or any other loading indicator
  }

  const updateUserTopupAndSignDocument = async () => {
    await updateTopUpAmount(values.totalInvestAmount, openZoho);
  };

  const updateUserInvestmentAndSignDocument = async () => {
    await updateInvmentAmount(values.totalInvestAmount, openZoho);
  };

  return (
    <>
      <>
        <div className="w-full">
          <Flex
            width={['100%', '100%', '100%', '100%']}
            height="100%"
            flexDirection={'column'}
            pl={[0, 0, 3, 3]}
            mt={[3, 3, 0, 0]}
          >
            {propertyDetails?.pgProvider?.includes('BillDesk') && (
              <form
                name="sdklaunch"
                id="sdklaunch"
                onSubmit={handleSubmitFormData}
              >
                <input
                  type="hidden"
                  id="merchantLogo"
                  name="merchantLogo"
                  value="base64 image of logo"
                />
                <input
                  type="hidden"
                  id="flowType"
                  name="flowType"
                  value="payments"
                />
                <input
                  type="hidden"
                  id="retryCount"
                  name="retryCount"
                  value="1"
                />
                <input type="hidden" id="bdorderid" name="bdorderid" />
                <input type="hidden" id="merchantid" name="merchantid" />
                <input type="hidden" id="rdata" name="rdata" />
              </form>
            )}
            {displayPayInitialOptions ? (
              <div className="mt-5 md:mt-0">
                <div>
                  <CustomButton
                    isDisable={!isValid}
                    btnText="PAY USING BILL DESK"
                    btnPressed={() =>
                      payUsingBillDesk(values.initialTokenAmount)
                    }
                    fullWidth
                  />
                  <img
                    src={BillDeskLogo}
                    height="40px"
                    width="auto"
                    className="payment-gateway-logo"
                  />
                </div>
                <div className="text-center headingName">or</div>
                <div>
                  <CustomButton
                    isDisable={!isValid}
                    btnText="PAY USING CASH FREE"
                    btnPressed={() => payCashFree(values.initialTokenAmount)}
                    fullWidth
                  />

                  <img
                    src={CashFreeLogo}
                    height="40px"
                    width="auto"
                    className="payment-gateway-logo"
                  />
                </div>
              </div>
            ) : (
              <div className="w-full mt-10 md:mt-0">
                <div className="flex flex-col w-full ">
                  <div className="flex flex-col justify-center w-full gap-6 lg:flex-row">
                    <div
                      className={
                        !isZohoCompleted() ? 'lg:w-[60%]' : 'lg:w-[90%]'
                      }
                    >
                      <div>
                        {!isZohoCompleted() && !isTopUp && (
                          <div className="mt-3 lg:mt-0">
                            <PaymentProgress
                              investmentAmountEoi={props.investmentAmountEoi}
                              from={'eoi'}
                              getInitialValues={getInitialValues}
                            />
                          </div>
                        )}

                        {!isZohoCompleted() &&
                          !isTopUpZohoCompleted() &&
                          !isTopUp && (
                            <PaymentProgress
                              investmentAmountEoi={props.investmentAmountEoi}
                              from={'eoi'}
                              getInitialValues={getInitialValues}
                            />
                          )}

                        {!isZohoCompleted() &&
                          !isTopUpZohoCompleted() &&
                          isTopUp && (
                            <PaymentProgress
                              investmentAmountEoi={props.investmentAmountEoi}
                              from={'topup'}
                              getInitialValues={getInitialValues}
                            />
                          )}

                        {isZohoCompleted() && (
                          <>
                            <Text
                              fontSize="12px"
                              className="min-payment-text"
                              mt={'12px'}
                              color={colors.grey200}
                            >
                              Enter the amount you wish to invest
                            </Text>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                color="#1a295b"
                                fontSize="1.2em"
                                children={<img src={RuppeIcon} />}
                              />
                              <Input
                                placeholder="Enter amount"
                                variant="flushed"
                                w="80%"
                                fontWeight={'bold'}
                                fontSize={'20px'}
                                color={Colors.headerBlue}
                                disabled={amountLoader}
                                value={NUM_FORMATTING(values.totalInvestAmount)}
                                onChange={(e) => {
                                  const {
                                    target: { value },
                                  } = e;
                                  const formatNumber = value.replace(/,/g, '');
                                  handleChange('totalInvestAmount')(
                                    formatNumber,
                                  );
                                }}
                              />
                              <InputRightElement
                                children={<EditIcon />}
                                mr={['50px']}
                              />
                            </InputGroup>
                            {/* Validation message for amount less than totalInvestAmount */}
                            {errors.totalInvestAmount &&
                              touched.totalInvestAmount && (
                                <Text fontSize={'12px'} mt={1} color="red">
                                  {errors.totalInvestAmount}
                                </Text>
                              )}
                          </>
                        )}

                        {/* second */}
                        {isZohoCompleted() && (
                          <>
                            <Text
                              fontSize="10px"
                              className="min-payment-text"
                              mt={'12px'}
                              color="red"
                            >
                              5% is advised to be paid as partial payment
                            </Text>
                            <Flex
                              alignItems="center"
                              bg="lightgrey"
                              w="80%"
                              p="3px"
                              borderRadius="4px"
                            >
                              <Box>
                                <img src={RuppeIcon} alt="Rupee Icon" />
                              </Box>
                              <Text
                                ml="10px"
                                fontWeight="bold"
                                fontSize="20px"
                                color={Colors.headerBlue}
                              >
                                {NUM_FORMATTING(
                                  (
                                    parseFloat(
                                      values.totalInvestAmount || '0',
                                    ) * 0.05
                                  ).toFixed(2),
                                )}
                              </Text>
                            </Flex>
                          </>
                        )}

                        {/* topup */}

                        {isTopUp && !isTopUpZohoCompleted() && (
                          <>
                            <Text
                              fontSize="12px"
                              className="mt-10 min-payment-text md:mt-0"
                              mt={'12px'}
                              color={colors.grey200}
                            >
                              Enter the amount you wish to invest
                            </Text>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                color="#1a295b"
                                fontSize="1.2em"
                                children={<img src={RuppeIcon} />}
                              />
                              <Input
                                placeholder="Enter amount"
                                variant="flushed"
                                w="80%"
                                fontWeight={'bold'}
                                fontSize={'20px'}
                                color={Colors.headerBlue}
                                disabled={amountLoader}
                                value={NUM_FORMATTING(
                                  values.initialTokenAmount,
                                )}
                                onChange={(e) => {
                                  const {
                                    target: { value },
                                  } = e;
                                  const formatNumber = value.replace(/,/g, '');
                                  handleChange('initialTokenAmount')(
                                    formatNumber,
                                  );
                                }}
                              />
                              <InputRightElement
                                children={<EditIcon />}
                                mr={['50px']}
                              />
                            </InputGroup>

                            {errors.initialTokenAmount && (
                              <>
                                {/* {errors.initialTokenAmount.includes(
                                    `*Please input amount more than ₹${topUpAmountEoi?.minInvestmentAmountToBePaidAtEOI}`
                                  ) && (
                                      <Text fontSize={'12px'} mt={1} color="red">
                                        {errors.initialTokenAmount}
                                      </Text>
                                    )} */}

                                {/* {errors.initialTokenAmount.includes(
                                    `*Please input amount less than ₹${topUpAmountEoi?.investmentAmount}`
                                  ) && (
                                      <Text fontSize={'12px'} mt={1} color="red">
                                        {errors.initialTokenAmount}
                                      </Text>
                                    )} */}
                              </>
                            )}
                          </>
                        )}

                        {!isZohoCompleted() &&
                          isTopUpZohoCompleted() &&
                          isTopUp && (
                            <>
                              <Text
                                fontSize="12px"
                                className="min-payment-text"
                                mt={'12px'}
                                color={colors.grey200}
                              >
                                Enter the amount you wish to invest
                              </Text>
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  color="#1a295b"
                                  fontSize="1.2em"
                                  children={<img src={RuppeIcon} />}
                                />
                                <Input
                                  placeholder="Enter amount"
                                  variant="flushed"
                                  w="80%"
                                  fontWeight={'bold'}
                                  fontSize={'20px'}
                                  color={Colors.headerBlue}
                                  disabled={amountLoader}
                                  value={NUM_FORMATTING(
                                    values.totalInvestAmount,
                                  )}
                                  onChange={(e) => {
                                    const {
                                      target: { value },
                                    } = e;
                                    const formatNumber = value.replace(
                                      /,/g,
                                      '',
                                    );
                                    handleChange('totalInvestAmount')(
                                      formatNumber,
                                    );
                                  }}
                                />
                                <InputRightElement
                                  children={<EditIcon />}
                                  mr={['50px']}
                                />
                              </InputGroup>

                              {errors.totalInvestAmount && (
                                <>
                                  {/* //for topup */}
                                  <Text fontSize={'12px'} mt={1} color="red">
                                    {errors.totalInvestAmount}
                                  </Text>

                                  {/* for eoi */}
                                  {errors.totalInvestAmount.includes(
                                    `*Please input amount less than ₹${topUpAmountEoi?.investmentAmount}`,
                                  ) && (
                                    <Text fontSize={'12px'} mt={1} color="red">
                                      {errors.totalInvestAmount}
                                    </Text>
                                  )}
                                </>
                              )}
                              <Text
                                fontSize="10px"
                                className="min-payment-text"
                                mt={'12px'}
                                color="red"
                              >
                                5% is advised to be paid as partial payment
                              </Text>
                              <Flex
                                alignItems="center"
                                bg="lightgrey"
                                w="80%"
                                p="3px"
                                borderRadius="4px"
                              >
                                <Box>
                                  <img src={RuppeIcon} alt="Rupee Icon" />
                                </Box>
                                <Text
                                  ml="10px"
                                  fontWeight="bold"
                                  fontSize="20px"
                                  color={Colors.headerBlue}
                                >
                                  {NUM_FORMATTING(
                                    (
                                      parseFloat(
                                        values.totalInvestAmount || '0',
                                      ) * 0.05
                                    ).toFixed(2),
                                  )}
                                </Text>
                              </Flex>
                            </>
                          )}
                        {/* /err */}

                        {/* topup end */}
                        {!isZohoCompleted() && !isTopUp && (
                          <>
                            <Text
                              fontSize="12px"
                              className="min-payment-text"
                              mt={'12px'}
                              color={colors.grey200}
                            >
                              {/* {`Initial payment of ${minPercentageToPaid}% will be required to start the process`} */}
                              Enter the amount for partial payment
                            </Text>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                color="#1a295b"
                                fontSize="1.2em"
                                children={<img src={RuppeIcon} />}
                              />
                              <Input
                                placeholder="Enter amount"
                                variant="flushed"
                                w="80%"
                                fontWeight={'bold'}
                                fontSize={'20px'}
                                color={Colors.headerBlue}
                                disabled={amountLoader}
                                value={NUM_FORMATTING(
                                  values.initialTokenAmount,
                                )}
                                onChange={(e) => {
                                  const {
                                    target: { value },
                                  } = e;
                                  const formatNumber = value.replace(/,/g, '');
                                  handleChange('initialTokenAmount')(
                                    formatNumber,
                                  );
                                }}
                              />
                              <InputRightElement
                                children={<EditIcon />}
                                mr={['50px']}
                              />
                            </InputGroup>
                          </>
                        )}
                        {errors?.initialTokenAmount &&
                          touched?.initialTokenAmount && (
                            <Text fontSize={'12px'} mt={1} color="red">
                              {' '}
                              {errors.initialTokenAmount.toString()}
                            </Text>
                          )}

                        <Text
                          fontSize="13px"
                          className="min-payment-text"
                          mt={3}
                        >
                          {/* {`Kindly sign the EOI document and pay minimum ${minPercentageToPaid}% Token amount to move to next step`} */}
                          {`${
                            !isZohoCompleted()
                              ? 'Amount to be in the multiples of 1,000’s'
                              : 'Kindly sign the EOI document and pay minimum 5% Token amount to move to next step'
                          }`}
                        </Text>
                        {amountLoader || eoiDocLoader || eoiPaymentLoader ? (
                          <Flex
                            justifyContent={'flex-start'}
                            alignItems="center"
                            ml={['10px', '10px', '20px', '20px']}
                            mt={'20px'}
                          >
                            <Spinner
                              thickness="4px"
                              speed="0.65s"
                              emptyColor="gray.200"
                              color="blue.500"
                              size="xl"
                            />
                          </Flex>
                        ) : (
                          <Flex mt={'20px'} justify={'space-between'}>
                            {!isTopUp ? (
                              <CustomButton
                                isDisable={!isValid}
                                btnText={
                                  isZohoCompleted()
                                    ? 'SIGN THE DOCUMENT'
                                    : `PAY ₹ ${NUM_FORMATTING(
                                        values.initialTokenAmount,
                                      )}`
                                }
                                btnPressed={
                                  isZohoCompleted()
                                    ? () =>
                                        updateUserInvestmentAndSignDocument()
                                    : () => {
                                        checkPaymentOptions(values);
                                      }
                                }
                              />
                            ) : (
                              <>
                                <CustomButton
                                  isDisable={!isValid}
                                  btnText={
                                    isTopUpZohoCompleted()
                                      ? 'SIGN THE DOCUMENT'
                                      : 'PAY THE INITIAL 5%'
                                  }
                                  fontSize="14px"
                                  pl="5px"
                                  pr="5px"
                                  btnPressed={
                                    isTopUpZohoCompleted()
                                      ? () => updateUserTopupAndSignDocument()
                                      : () => {
                                          checkPaymentOptions(values);
                                        }
                                  }
                                />
                                {topUpAmountEoi?.eoiPaymentStatus !==
                                  'InProgress' && (
                                  <div style={{ marginLeft: '1rem' }}>
                                    <CustomButton
                                      btnText={'CANCEL TOP-UP'}
                                      fontSize="14px"
                                      pl="5px"
                                      pr="5px"
                                      btnPressed={() => deleteTopUpInvestment()}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </Flex>
                        )}
                      </div>
                    </div>
                    {!isZohoCompleted() && (
                      <div className="lg:w-[45%] mt-5 lg-mt-0 ">
                        <PaymentHistory investmentDetails={investmentModal} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Flex>
        </div>
      </>
      {draftEoiModal && (
        <CustomFrame
          isOpen={draftEoiModal}
          zohoDocLink={
            propertyDetails?.propertyInvestment?.eois?.[activeSPVIndex]
              ?.eoiDocumentDraftUrl
          }
          isTopUp={isTopUp}
          onClose={() => setDraftEoiModal(false)}
          title="Draft EOI Document"
          investmentId={investmentId}
          onCloseFrame={() => setDraftEoiModal(false)}
        />
      )}
      {/* {popUpModal && (
        <EoiPayPopUpModal
          isModalOpen={popUpModal}
          closeModal={() => setPopUpModal(false)}
        />
      )} */}
    </>
  );
};

export default EOIStep1;
