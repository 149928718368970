import { Flex, Select, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import colors from 'libs/src/theme/colors';
import kycBackgroundImage from '../../assets/kyc/kycBackground.png';
import KycContainer from './KycContainer';
import styles from './index.module.scss';
import { Colors, HTTPStatusCode, KycStatus } from '@hBits.Core';
import { categoryType, setTabValueWhenKycIsInvalidated } from './utils';
import { getKycDataFunc } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  HOLDER_ID,
  IS_ALL_FIELDS_DISABLED,
  IS_NRI,
  IS_SUBMITTED_PAGE_VISIBLE,
  KYC_DATA,
  KYC_STATUS_FOR_ACCOUNT,
  KYC_STATUS_FOR_HOLDER,
  TAB_VALUE,
} from '../../store/constants/ReduxConstants';
import verifiedImage from '../../assets/kyc/greentick.png';
import verificationInProgressImage from '../../assets/kyc/hourglass.png';
import StatusTextFile from './HelperComponents/StatusTextFile';
import { updateUserType } from '../../services/Kyc/getKycData';
import accountIdManager from 'libs/src/auth/account-manager';
import invalidatedImage from '../../assets/kyc/invaidated.jpeg';
import useAuth from 'libs/src/hooks/useAuth';
import dynamic from 'next/dynamic';
import KycVideoSnackBar from './HelperComponents/KycVideoSnackbar';
import KycVideoModal from './HelperComponents/KycVideoModal';

const NomineeModal = dynamic(
  () => import('../../../../../libs/src/components/NomineeModal'),
  {
    suspense: false,
  },
);

interface userHolderTypes {
  name: string;
  tabName: string[];
}

const Kyc = () => {
  const { nomineeModalState, onNomineeModalClose } = useAuth();
  const dispatch = useDispatch();
  const [kycVideoModalState, setKycVideoModalState] = useState(true);
  const [userHolderType, setUserHolderType] = useState<userHolderTypes>(
    categoryType[0],
  );
  const [holders, setHolders] = useState<number[]>([1]);
  const {
    isGetKycData,
    kycStatusForAccount,
    isSubmittedPageVisible,
    isAllFieldsDisable,
    tabValue,
  } = useSelector((state: any) => state.KycReducer);
  const addHolders = () => {
    setHolders([...holders, holders.length + 1]);
  };

  const crossClicked = (value: boolean) => {
    if (value) {
      setHolders([...holders.slice(0, -1)]);
    }
  };

  const setUserType = async (event: any) => {
    setUserHolderType(event);
    const data = {
      accountType: event.name,
      holderType: 'Primary',
    };
    const response = await updateUserType(
      accountIdManager?.getAccountId(),
      data,
    );
  };

  const getCategoryType = (value: any) => {
    for (let itm of categoryType) {
      if (itm.name === value) {
        setUserHolderType(itm);
      }
    }
  };

  const getAccountDetails = async () => {
    const response = await getKycDataFunc();
    if (response?.status === HTTPStatusCode.OK) {
      let tempHolderIds = {},
        tempNriState = {},
        tempKycStatusForHolder = {},
        tempIsAllFieldsDisable = {},
        tempIsSubmittedPageVisible = {},
        tempKycData = {},
        tempTabValues = { 0: 0, 1: 0, 2: 0 };
      getCategoryType(response?.data?.holders[0]?.accountType);
      setHolders(response?.data?.holders);
      for (let i = 0; i < response?.data?.holders?.length; i++) {
        tempHolderIds = {
          ...tempHolderIds,
          [i]: response?.data?.holders[i]?.id,
        };
        tempNriState = {
          ...tempNriState,
          [i]:
            response?.data?.holders[i]?.residentialStatus === 'NRI'
              ? true
              : false,
        };
        tempKycStatusForHolder = {
          ...tempKycStatusForHolder,
          [i]: response?.data?.holders[i]?.kycStatus,
        };
        if (
          response?.data?.holders[i]?.kycStatus === KycStatus.KycVerified ||
          response?.data?.holders[i]?.kycStatus ===
          KycStatus.KycVerificationInProgress
        ) {
          tempIsAllFieldsDisable = {
            ...tempIsAllFieldsDisable,
            [i]: true,
          };
        }
        if (
          response?.data?.holders[i]?.kycStatus === KycStatus.KycSubmitted ||
          response?.data?.holders[i]?.kycStatus === KycStatus.KycUpdated
        ) {
          tempIsSubmittedPageVisible = {
            ...tempIsSubmittedPageVisible,
            [i]: true,
          };
        }
        tempKycData = {
          ...tempKycData,
          [i]: response?.data?.holders[i],
        };
        if (response?.data?.kycStatus === KycStatus.KycInvalidated) {
          if (
            response?.data?.holders[i]?.kycStatus === KycStatus.KycInvalidated
          ) {
            const value = await setTabValueWhenKycIsInvalidated(
              i,
              dispatch,
              true,
              isSubmittedPageVisible,
            );
            tempTabValues = {
              ...tempTabValues,
              [i]: value,
            };
          }
        }
      }
      dispatch({
        type: TAB_VALUE,
        payload: tempTabValues,
      });
      dispatch({
        type: HOLDER_ID,
        payload: tempHolderIds,
      });
      dispatch({
        type: IS_NRI,
        payload: tempNriState,
      });
      dispatch({
        type: KYC_DATA,
        payload: tempKycData,
      });

      dispatch({
        type: KYC_STATUS_FOR_ACCOUNT,
        payload: response?.data?.kycStatus,
      });
      dispatch({
        type: KYC_STATUS_FOR_HOLDER,
        payload: tempKycStatusForHolder,
      });
      dispatch({
        type: IS_ALL_FIELDS_DISABLED,
        payload: tempIsAllFieldsDisable,
      });
      dispatch({
        type: IS_SUBMITTED_PAGE_VISIBLE,
        payload: tempIsSubmittedPageVisible,
      });
      return;
    }
  };

  useEffect(() => {
    getAccountDetails();
  }, [isGetKycData]);

  return (
    <>
      <KycVideoSnackBar setKycVideoModalState={setKycVideoModalState} />
      <Flex backgroundImage={kycBackgroundImage}>
        <Flex
          maxW={'1500px'}
          minH={'100vh'}
          m={'auto'}
          w={'100%'}
          flexDirection={'row'}
          mt={'80px'}
          p={'1rem'}
        >
          <Flex w={{ base: '0%', md: '15%' }}></Flex>
          <Flex
            w={{ base: '100%', md: '70%' }}
            mt={'30px'}
            flexDirection={'column'}
          >
            <Flex
              justifyContent={'space-between'}
              w={'100%'}
              h={'10%'}
              alignItems="center"
              p={2}
              color={colors.bgDarkBlue}
              fontSize={{ base: '20px', md: '27px' }}
              fontWeight={'500'}
              lineHeight={'1.2'}
            >
              <Flex>Update KYC Details</Flex>
              {holders.length < 3 &&
                !(kycStatusForAccount === KycStatus.KycVerified) && (
                  <Flex
                    fontSize={{ base: '14px', md: '21px' }}
                    cursor={'pointer'}
                    onClick={() => addHolders()}
                    zIndex={8}
                  >
                    Add More Holders
                  </Flex>
                )}
            </Flex>
            {kycStatusForAccount === KycStatus.KycVerified && (
              <Flex
                w={'100%'}
                mt={4}
                className={styles['kyccontainer_card']}
                padding={{ base: '1rem', md: '2rem' }}
              >
                <StatusTextFile
                  text="Kyc has been Verified"
                  image={verifiedImage}
                  isVerified={true}
                />
              </Flex>
            )}
            {kycStatusForAccount === KycStatus.KycInvalidated && (
              <Flex
                w={'100%'}
                mt={4}
                className={styles['kyccontainer_card']}
                padding={{ base: '1rem', md: '2rem' }}
              >
                <StatusTextFile
                  text="Kyc has been Invalidated"
                  image={invalidatedImage}
                />
              </Flex>
            )}
            {kycStatusForAccount === KycStatus.KycVerificationInProgress && (
              <Flex
                w={'100%'}
                mt={4}
                className={styles['kyccontainer_card']}
                padding={{ base: '1rem', md: '2rem' }}
              >
                <StatusTextFile
                  text="KYC verification in progress...."
                  image={verificationInProgressImage}
                />
              </Flex>
            )}
            <Flex w={'100%'} mt={4}>
              <Flex
                className={styles['kyccontainer_card']}
                flexDirection={'column'}
                padding={{ base: '1rem', md: '2rem' }}
              >
                <Text
                  fontSize={'12px'}
                  fontWeight={'500'}
                  color={Colors.greyUserNameColor}
                >
                  Choose Type
                </Text>
                <Flex pt={2}>
                  <Select
                    data-test='s-drop-down'
                    width={'100%'}
                    height={'30px'}
                    fontSize={'16px'}
                    borderRadius={'5px'}
                    disabled={isAllFieldsDisable[0]}
                    _disabled={{ color: 'black' }}
                    borderColor={Colors.greyBorderColor}
                    value={JSON.stringify(userHolderType)}
                    onChange={(e) => {
                      setUserType(JSON.parse(e.target.value));
                      dispatch({
                        type: TAB_VALUE,
                        payload: {
                          ...tabValue,
                          [0]: 0,
                        },
                      });
                    }}
                    color={Colors.blackColor}
                    border={'1px solid #c9c9c9'}
                    _focus={{
                      boxShadow: 'none',
                      outline: 'none',
                      border: '1px solid #c9c9c9',
                    }}
                  >
                    {categoryType?.map((value) => (
                      <option
                        key={value.name}
                        value={JSON.stringify(value)}
                        style={{ color: Colors.blackColor }}
                      >
                        {value.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </Flex>
            </Flex>
            <Flex direction={'column'}>
              {holders.map((value: any, index: any) => (
                <KycContainer
                  userHolderType={userHolderType}
                  holderNumber={index}
                  holderLength={holders.length}
                  crossClicked={crossClicked}
                />
              ))}
            </Flex>
          </Flex>
          <Flex w={{ base: '0%', md: '15%' }}></Flex>
        </Flex>
      </Flex>
      {nomineeModalState && (
        <NomineeModal
          isOpen={nomineeModalState}
          onModalClose={onNomineeModalClose}
        />
      )}
      {kycVideoModalState && (
        <KycVideoModal
          isOpen={kycVideoModalState}
          onModalClose={() => setKycVideoModalState(false)}
        />
      )}
    </>
  );
};
export default Kyc;
