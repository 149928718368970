import { MutableRefObject, useEffect, useRef, useState } from 'react';
import '../styles/Collateral.scss';
import { useForm } from 'react-hook-form';
import { dmsUpload } from 'apps/customer-portal-web/src/components/Kyc/ApiData/ApiData';
import {
  fetchCollateral,
  postCollateral,
  deleteCollateral,
} from 'libs/src/services/modules/channel-partner';
import html2canvas from 'html2canvas';
import { Flex, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import downloadjs from 'downloadjs';
import CollateralList from './CollateralList';
import CollateralForm from './CollateralForm';
import CollateralPreview from './CollateralPreview';
import { AccountManager } from '@hBits.Core';

type FormInputs = {
  companyLogoUrl: string;
  companyName: string;
  personName1: string;
  personMobileNumber1: string;
  personName2: string;
  personMobileNumber2: string;
  collateralId: string;
  property: string;
};

const toDataUrl = (url: string, callback: any) => {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

const Collaterals = () => {
  const [companyLogoUrl, setCompanyLogoUrl] = useState('');
  const [collatralList, setCollateralList] = useState([]);
  const [baseImage, setBaseImage] = useState<any>();
  const [step, setStep] = useState(0);
  const [isPreview, setIsPreview] = useState(false);
  const previewRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (step !== 3 && step !== 4) {
      getCollateral();
    }
  }, [step]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm<FormInputs>();

  const SettingLogoUrl = (event: any) => {
    if (event.target.files) {
      const fileSize = event.target.files[0]?.size / (1024 * 1024);
      const fileType = event.target.files[0]?.type;
      if (fileSize > 2) {
        setError('companyLogoUrl', {
          message: 'File size should be less than 2MB.',
        });
      } else if (!fileType.match('image/*')) {
        setError('companyLogoUrl', {
          message: 'File type not supported. Only images are allowed.',
        });
      } else {
        clearErrors('companyLogoUrl');
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'image');
        dmsUpload(formData).then((data) => {
          setCompanyLogoUrl(data.documentUrl);
        });
      }
    }
  };

  const getCollateral = async (): Promise<void> => {
    const authToken = AccountManager.getAccountId();
    const list = await fetchCollateral(authToken);
    if (collatralList && collatralList.length == 0) setStep(1);
    setCollateralList(list?.collaterals);
  };

  const downloadImage = (url: any) => {
    // convert image into base64 and then download
    toDataUrl(url, function (imageBase64: any) {
      downloadjs(imageBase64, 'download.png', 'image/png'); // myBase64 is the base64 string
    });
  };

  const deleteCollateralImage = async (id: any) => {
    await deleteCollateral(id).then(() => getCollateral());
  };

  const uploadCollateral = async (data: any): Promise<void> => {
    const authToken = AccountManager.getAccountId();
    const response = await postCollateral(data, authToken);
    if (response.status === 'success') {
      setStep(4);
    } else if (response?.data?.error?.errorMessage) {
      setStep(2);
      alert(response.data.error.errorMessage);
    }
  };

  const handleCaptureClick = async () => {
    const collateralElmt =
      document.querySelector<HTMLCanvasElement>('.collateral');
    if (!collateralElmt) return;
    const canvas = await html2canvas(collateralElmt, {
      useCORS: true,
      allowTaint: true,
    });
    const dataURL = canvas.toDataURL('image/jpeg');
    downloadjs(dataURL, 'download.jpeg', 'image/jpeg');
  };

  const handleOnSubmit = async (formvalues: any) => {
    if (!formvalues.property) {
      setError('property', { message: 'Property is required' });
    } else if (
      formvalues.personName2.length !== 0 &&
      !formvalues.personMobileNumber2
    ) {
      setError('personMobileNumber2', { message: 'Mobile number required' });
    } else if (
      formvalues.personMobileNumber1 === formvalues.personMobileNumber2
    ) {
      setError('personMobileNumber2', {
        message: 'Mobile Numbers cannot be same',
      });
    } else if (
      formvalues.personMobileNumber2 &&
      formvalues.personName2.length === 0
    ) {
      setError('personName2', {
        message: 'Name is required',
      });
    } else {
      clearErrors('personMobileNumber2');
      clearErrors('property');
      clearErrors('personMobileNumber1');
      clearErrors('personName2');
      clearErrors('companyLogoUrl');
      setStep(3);
      if (!formvalues.personName2 && !formvalues.personMobileNumber2) {
        delete formvalues.personName2;
        delete formvalues.personMobileNumber2;
        delete formvalues.personMobileCountryCode2;
      }
      if (!companyLogoUrl || companyLogoUrl.length === 0) {
        delete formvalues.companyLogoUrl;
      }

      setTimeout(async () => {
        if (!previewRef.current) return;
        html2canvas(previewRef.current, {
          useCORS: true,
          allowTaint: true,
          ignoreElements: function (e: any) {
            if (
              (e.tagName === 'A' && e.host !== window.location.host) ||
              e.getAttribute('loading') === 'lazy'
            ) {
              return true;
            } else {
              return false;
            }
          },
        }).then(function (canvas) {
          canvas?.toBlob(
            (blob: any) => {
              const formData = new FormData();
              if (blob) {
                formData.append('file', blob, 'collateral.jpg');
                formData.append('type', 'image');
              }
              dmsUpload(formData).then((data) => {
                const emailPreview = data.documentUrl;
                const whatsappPreview = data.documentUrl;
                if (!companyLogoUrl || companyLogoUrl.length === 0) {
                  uploadCollateral({
                    ...formvalues,
                    emailPreview,
                    whatsappPreview,
                  });
                } else {
                  uploadCollateral({
                    ...formvalues,
                    companyLogoUrl,
                    emailPreview,
                    whatsappPreview,
                  });
                }
              });
            },
            'image/jpg',
            1,
          );
        });
      }, 300);
    }
  };

  return (
    <Flex ms={[5, 10]} me={5} direction={'column'}>
      <Flex> Customize Collaterals </Flex>
      {step === 1 ? (
        collatralList && collatralList.length ? (
          <CollateralList
            setStep={setStep}
            collatralList={collatralList}
            downloadImage={downloadImage}
            deleteCollateralImage={deleteCollateralImage}
            reset={reset}
            setCompanyLogoUrl={setCompanyLogoUrl}
          />
        ) : (
          <CollateralForm
            handleSubmit={handleSubmit}
            setBaseImage={setBaseImage}
            companyLogoUrl={companyLogoUrl}
            setCompanyLogoUrl={setCompanyLogoUrl}
            handleOnSubmit={handleOnSubmit}
            SettingLogoUrl={SettingLogoUrl}
            register={register}
            errors={errors}
            control={control}
            setValue={setValue}
            setIsPreview={setIsPreview}
            setStep={setStep}
            showBack={collatralList?.length !== 0}
          />
        )
      ) : step === 2 ? (
        <CollateralForm
          handleSubmit={handleSubmit}
          setBaseImage={setBaseImage}
          companyLogoUrl={companyLogoUrl}
          setCompanyLogoUrl={setCompanyLogoUrl}
          handleOnSubmit={handleOnSubmit}
          SettingLogoUrl={SettingLogoUrl}
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          setIsPreview={setIsPreview}
          setStep={setStep}
          showBack={collatralList?.length !== 0}
        />
      ) : (
        <CollateralPreview
          baseImage={baseImage}
          companyLogoUrl={companyLogoUrl}
          watch={watch}
          handleCaptureClick={handleCaptureClick}
          previewRef={previewRef}
          isPreview={isPreview}
          setIsPreview={setIsPreview}
          step={step}
          setStep={setStep}
        />
      )}
      <Modal
        isOpen={isPreview}
        onClose={() => {
          setIsPreview(false);
        }}
        blockScrollOnMount
      >
        <ModalOverlay />
        <ModalContent>
          <CollateralPreview
            baseImage={baseImage}
            companyLogoUrl={companyLogoUrl}
            watch={watch}
            handleCaptureClick={handleCaptureClick}
            previewRef={previewRef}
            isPreview={isPreview}
            setIsPreview={setIsPreview}
            step={step}
            setStep={setStep}
          />
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Collaterals;
