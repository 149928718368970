import { Button, Flex } from '@chakra-ui/react';
import { Environment } from '@hBits.Core';
import { Colors } from 'apps/customer-portal-web/src/theme';
import '../kyc.scss';
import greentick from '../resources/greentick.png';

interface KycSubmittedProps {
  setSkipAndSave: any;
  isChannelPartner: boolean;
  setShowIsSubmittedPage: any;
  updateStateCallback?: any;
}

function KycStatusSubmitted(props: KycSubmittedProps) {
  const {
    setSkipAndSave,
    isChannelPartner,
    setShowIsSubmittedPage,
    updateStateCallback,
  } = props;

  const newCustomerPortalHostingRoot = Environment.getInstance().getParam(
    'NEXT_PUBLIC_NEW_CUSTOMER_PORTAL_HOSTING_ROOT',
  );

  const updateDetails = () => {
    if (isChannelPartner) {
      setShowIsSubmittedPage(false);
      updateStateCallback('basicdetails');
    } else {
      setSkipAndSave(false);
    }
  };
  const moveToProperties = () => {
    if (isChannelPartner) {
      window.location.assign(newCustomerPortalHostingRoot + '/dashboard');
    } else {
      const hostingRoot = Environment.getInstance().getParam(
        'NEXT_PUBLIC_LANDING_PAGES_HOSTING_ROOT',
      );
      window.location.assign(hostingRoot + '/properties');
    }
  };

  return (
    <div>
      <div className="kyc_margin-top-1">
        <div className="kyc_text-align-center kyc_padding-top-3 kyc_padding-bottom-3">
          <img src={greentick} className="kyc_green-tick"></img>
          <br />
          <h6 className="kyc_h6-tag-for-kyc-status-page">
            Details have been Submitted
          </h6>
          <p className="kyc_p-tag-for-kyc-status-page">
            Thank You for submitting your details.
            <br />
            <br />
            You can still update your details till the KYC goes under
            verification process.
          </p>
          <br />

          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent={'center'}
          >
            <Flex justifyContent={'center'}>
              <Button
                className="kyc_submitted_btn"
                _hover={{ bg: Colors.orangeColor }}
                _active={{ bg: Colors.orangeColor }}
                onClick={updateDetails}
              >
                Update Details
              </Button>
            </Flex>
            <Flex
              justifyContent={'center'}
              pt={{ base: 4, md: 0 }}
              pl={{ base: 0, md: 6 }}
            >
              <Button
                className="kyc_submitted_btn kyc_continue_btn"
                _hover={{ bg: Colors.orangeColor }}
                _active={{ bg: Colors.orangeColor }}
                onClick={moveToProperties}
              >
                {isChannelPartner ? 'Back To Dashboard' : 'Continue'}
              </Button>
            </Flex>
          </Flex>
        </div>
      </div>
    </div>
  );
}
export default KycStatusSubmitted;
