import { authTokenStorageProvider } from '../auth';
import { Environment } from '../services/EnvironmentHelper';

declare const document: any;
class AccountManager {
  private accountId = '';

  private static ENVIRONMENT_PREFIX = Environment.getInstance().getParam(
    'NEXT_PUBLIC_ENV_NAME',
  );

  private static AUTH_ACCOUNTID_COOKIE_NAME = `${AccountManager.ENVIRONMENT_PREFIX}-hb-account-id`;

  private static readonly LOGOUT_FLAG = 'LOGOUT_FLAG';

  private setEnvironmentPrefix(): void {
    AccountManager.ENVIRONMENT_PREFIX = Environment.getInstance().getParam(
      'NEXT_PUBLIC_ENV_NAME',
    );

    AccountManager.AUTH_ACCOUNTID_COOKIE_NAME = `${AccountManager.ENVIRONMENT_PREFIX}-hb-account-id`;
  }

  public setAccountId(newAccountId: string) {
    if (!AccountManager.ENVIRONMENT_PREFIX) {
      this.setEnvironmentPrefix();
    }
    const parentHostingRoot = Environment.getInstance().getParam(
      'NEXT_PUBLIC_PARENT_HOSTING_DOMAIN',
    );

    const domain = `domain=${parentHostingRoot}; `;
    document.cookie = `${AccountManager.AUTH_ACCOUNTID_COOKIE_NAME}=${newAccountId}; ${domain}path=/`;
  }

  public getAccountId() {
    if (!AccountManager.ENVIRONMENT_PREFIX) {
      this.setEnvironmentPrefix();
    }
    let accountId;
    const splitCookieArr = document.cookie.split(';');
    if (splitCookieArr.length > 0) {
      splitCookieArr.forEach((cookieString: string) => {
        let accountIdIndex = cookieString.indexOf(
          `${AccountManager.AUTH_ACCOUNTID_COOKIE_NAME}=`,
        );
        if (accountIdIndex >= 0) {
          accountId = cookieString.split(
            `${AccountManager.AUTH_ACCOUNTID_COOKIE_NAME}=`,
          )[1];
        }
      });
    }

    if (accountId != null && accountId != undefined) {
      return accountId;
    } else {
      const currentAccountId =
        authTokenStorageProvider.getAuthTokenModel()?.accountId;
      if (currentAccountId) {
        this.setAccountId(currentAccountId);
      }
      return currentAccountId;
    }
  }

  public clearAccountId(): void {
    const parentHostingRoot = Environment.getInstance().getParam(
      'NEXT_PUBLIC_PARENT_HOSTING_DOMAIN',
    );
    document.cookie =
      AccountManager.AUTH_ACCOUNTID_COOKIE_NAME +
      '=' +
      ';path=/' +
      `;domain=${parentHostingRoot}` +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }

  public setLogoutFlag(value: string) {
    const parentHostingRoot = Environment.getInstance().getParam(
      'NEXT_PUBLIC_PARENT_HOSTING_DOMAIN',
    );

    const domain = `domain=${parentHostingRoot}; `;
    document.cookie = `${AccountManager.LOGOUT_FLAG}=${value}; ${domain}path=/`;
  }

  public getLogoutFlag() {
    let flag;
    if (typeof document !== 'undefined') {
      const splitCookieArr = document && document.cookie.split(';');
      if (splitCookieArr.length > 0) {
        splitCookieArr.forEach((cookieString: string) => {
          let accountIdIndex = cookieString.indexOf('LOGOUT_FLAG=');
          if (accountIdIndex >= 0) {
            flag = cookieString.split('LOGOUT_FLAG=')[1];
          }
        });
      }
    }

    if (flag != null && flag != undefined) {
      return flag;
    } else {
      return null;
    }
  }

  public clearLogoutFlag(): void {
    const parentHostingRoot = Environment.getInstance().getParam(
      'NEXT_PUBLIC_PARENT_HOSTING_DOMAIN',
    );
    document.cookie =
      AccountManager.LOGOUT_FLAG +
      '=' +
      ';path=/' +
      `;domain=${parentHostingRoot}` +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }

  //channel-parter-id

  private channelPartnerId = '';
  private static AUTH_CHANNELPARTNER_COOKIE_NAME = `${AccountManager.ENVIRONMENT_PREFIX}-hb-channel-partner-id`;

  public setChannelPartnerId(newchannelPartnerId: string) {
    if (!AccountManager.ENVIRONMENT_PREFIX) {
      this.setEnvironmentPrefix();
    }
    const parentHostingRoot = Environment.getInstance().getParam(
      'NEXT_PUBLIC_PARENT_HOSTING_DOMAIN',
    );

    const domain = `domain=${parentHostingRoot}; `;
    document.cookie = `${AccountManager.AUTH_CHANNELPARTNER_COOKIE_NAME}=${newchannelPartnerId}; ${domain}path=/`;
  }

  public getChannelPartnerId() {
    if (!AccountManager.ENVIRONMENT_PREFIX) {
      this.setEnvironmentPrefix();
    }
    let channelPartnerId;
    const splitCookieArr = document.cookie.split(';');
    if (splitCookieArr.length > 0) {
      splitCookieArr.forEach((cookieString: string) => {
        let channelPartnerIdIndex = cookieString.indexOf(
          `${AccountManager.AUTH_CHANNELPARTNER_COOKIE_NAME}=`,
        );
        if (channelPartnerIdIndex >= 0) {
          channelPartnerId = cookieString.split(
            `${AccountManager.AUTH_CHANNELPARTNER_COOKIE_NAME}=`,
          )[1];
        }
      });
    }

    if (channelPartnerId != null && channelPartnerId != undefined) {
      return channelPartnerId;
    } else {
      return channelPartnerId;
    }
  }
}

export default new AccountManager();
