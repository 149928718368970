/* tslint:disable */
import { Text } from '@chakra-ui/react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import './RegistrationModal.module.scss';
// import './registration.css';/
import Loader from 'apps/landing-pages-web/src/components/common/loader';
import { Form, Formik } from 'formik';
import accountIdManager from 'libs/src/auth/account-manager';
import {
  HTTPStatusCode,
  RelationshipWithMinorEnum,
  SignUpAttemptSources,
} from 'libs/src/enums';
import { RegistrationProps } from 'libs/src/hooks/useAuth';
import { addLinkedAccounts } from 'libs/src/services/modules/linkedAccounts/addLinkedAccounts';
import { Colors } from 'libs/src/theme';
import colors from 'libs/src/theme/colors';
import { useRouter } from 'next/router';
import * as React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as Yup from 'yup';
import { moneycontrolDomain } from '../../../../apps/landing-pages-web/src/constant/index';
import closeCircle from '../../assets/svg/close-circle-outline.svg';
import getKycData from '../../services/kycData';
import { initializeSignup } from '../../services/modules';
import BotInput from '../BotInput';
import BotCheckbox from '../BotInput/bot-checkbox';
import RecaptchaBox from '../Recaptcha';
import GoogleRecaptchaBox from '../Recaptcha/googleRecaptcha';

const Utils = {
  getBaseURL: (baseURL = '', apiUrl = '') => {
    const url = baseURL.endsWith('/') ? baseURL.slice(0, -1) : baseURL;
    return url + apiUrl;
  },
};

export interface RegistrationModalProps {
  registrationData: RegistrationProps | null;
  onModalClose: () => void;
  onRegisterClick: (userData: RegistrationProps) => void;
  onLoginClick: () => void;
  onTnCClick: () => void;
  linkedFlag: boolean;
  ModalHeadingText?: string;
  origin?: string;
}

export interface IFormInput {
  firstname: string;
  lastname: string;
  emailAddress: string;
  mobileCountryCode: string;
  mobileNumber: string;
  additionalDetails: any;
}

interface LoginDetailInt {
  firstname?: string;
  lastname?: string;
  emailAddress?: string;
  mobileNumber?: string;
  userEmail?: string;
  token: string | null;
}

const RegistrationBox: FunctionComponent<RegistrationModalProps> = ({
  registrationData,
  onModalClose,
  onRegisterClick,
  onLoginClick,
  onTnCClick,
  linkedFlag,
  ModalHeadingText = 'Create an account',
  origin,
}): any => {
  const [serverError, setServerError] = useState<string | undefined>('');
  const [countryCode, setCountryCode] = useState(12);
  const [countryCodeLength, setCountryCodeLength] = useState(2);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [parentAccountDetails, setParentAccountDetails] = useState<any>();
  const [relationWithParentAccount, setRelationWithParentAccount] =
    useState<string>();
  const [
    otherRelationShipWithParentAccount,
    setOtherRelationShipWithParentAccount,
  ] = useState<string>();
  const [isSaveAndContinueClicked, setIsSaveAndContinueClicked] =
    useState(false);
  const [sameMobileNoAsParent, setSameMobileNoAsParent] = useState(false);
  const [sameEmailAsParent, setSameEmailAsParent] = useState(false);
  const router = useRouter();

  const SignupSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .min(countryCode, 'Phone Number is Invalid')
      .max(22, 'Phone Number is Invalid')
      .required('Phone Number is required'),
    firstname: Yup.string()
      .matches(/^[^-\s]/, `First Name shouldn't be start with space`)
      .matches(
        /^[a-zA-Z_-\s]*$/,
        'Special characters or numbers are not allowed',
      )
      .required('First Name is required '),
    lastname: Yup.string()
      .required('Last Name is required')
      .matches(/^[^-\s]/, `Last Name shouldn't be start with space`)
      .matches(
        /^[a-zA-Z_-\s]*$/,
        'Special characters or numbers are not allowed',
      ),
    emailAddress: Yup.string()
      .matches(/^[^-\s]/, `Email shouldn't be start with space`)
      .matches(
        /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}\s*$/,
        'Invalid Email Id',
      )
      .required('Email is required'),
    ...(!linkedFlag &&
      process.env['NEXT_PUBLIC_ENV_NAME'] === 'Production' && {
        token: Yup.string().required('Please select Recaptcha').nullable(),
      }),
  });

  if (typeof window !== 'undefined') {
    const urlparams = window.location.search;
    const searchParams: any = new URLSearchParams(urlparams);
    const landingUrl = localStorage.getItem('landingUrl');
    const signupUrl = router?.pathname;
    var arr = new Array();
    for (const [key, value] of searchParams) {
      arr.push([key, value]);
    }
    arr.push(['url', `${window.location.pathname}${window.location.search}`]);
    arr.push(['landingUrl', landingUrl]);
    arr.push(['signupUrl', signupUrl]);
    if (origin == SignUpAttemptSources.Property) {
      arr.push(['propertyName', 'Tech MNC at IT Hub']);
    }
    var additionalDetails: Object = Object.fromEntries(arr);
  }

  useEffect(() => {
    if (window.location.pathname.includes('channel-partner')) {
      origin = SignUpAttemptSources.Partner;
    }
    if (
      window.location.search.includes('Moneycontrol') ||
      window.location.host === moneycontrolDomain
    ) {
      origin = SignUpAttemptSources.Moneycontrol;
    }
  }, []);

  const getSignUpSource = () => {
    switch (origin) {
      case SignUpAttemptSources.Enquiry:
        return SignUpAttemptSources.Enquiry;
      case SignUpAttemptSources.Callback:
        return SignUpAttemptSources.Callback;
      case SignUpAttemptSources.Partner:
        return SignUpAttemptSources.Partner;
      case SignUpAttemptSources.Property:
        return SignUpAttemptSources.Property;
      case SignUpAttemptSources.AIF:
        return SignUpAttemptSources.AIF;
      case SignUpAttemptSources.NRI:
        return SignUpAttemptSources.NRI;
      case SignUpAttemptSources.Moneycontrol:
        return SignUpAttemptSources.Moneycontrol;
      default:
        return SignUpAttemptSources.SignUp;
    }
  };

  const handleSubmit = async (data: any) => {
    setIsLoaderVisible(true);
    const newData = { ...data };
    newData.firstname = data.firstname.trim();
    newData.lastname = data.lastname.trim();
    newData.emailAddress = data.emailAddress.trim();
    let token = newData?.token || null;
    delete newData['token'];
    let mobileNumber = `${
      newData.mobileNumber || registrationData?.mobileNumber
    }`;
    mobileNumber = mobileNumber.includes('+')
      ? mobileNumber.slice(1, mobileNumber.length)
      : mobileNumber;
    const mobileCountryCode: string = mobileNumber.slice(0, countryCodeLength);
    newData.mobileCountryCode = `+${mobileCountryCode}`;
    newData.mobileNumber = mobileNumber.slice(countryCodeLength);
    newData.additionalDetails = additionalDetails!;
    newData.source = getSignUpSource();
    let response: any;
    try {
      if (linkedFlag) {
        if (
          (sameEmailAsParent || sameMobileNoAsParent) &&
          !relationWithParentAccount
        ) {
          setIsLoaderVisible(false);
          return;
        }
        response = await addLinkedAccounts({
          ...newData,
          sameMobileAsParent: sameMobileNoAsParent,
          sameEmailAsParent: sameEmailAsParent,
          relationWithParent:
            relationWithParentAccount === 'Other'
              ? otherRelationShipWithParentAccount
              : relationWithParentAccount,
        });
        if (response) {
          setIsLoaderVisible(false);
        }
      } else {
        newData.google_recaptcha_token = token || null;
        response = await initializeSignup({ ...newData });
        if (response) {
          setIsLoaderVisible(false);
        }
      }
      if (response && response['status'] == HTTPStatusCode.OK) {
        newData.mobileNumber = newData.mobileCountryCode + newData.mobileNumber;
        onRegisterClick({
          ...newData,
          attemptId: response.data['attemptId'],
          id: response.data.id,
        } as RegistrationProps);
      } else if (response['error']) {
        if (!linkedFlag && (origin === 'Callback' || origin === 'Enquiry')) {
          router.push('thankyou');
          onModalClose();
        } else {
          setServerError(
            response?.error?.additionalDetails || response?.error?.errorMessage,
          );
          setResetCaptcha(true);
        }
      }
    } catch (error) {
      //show error state
      setServerError(response?.error?.additionalDetails);
    }
  };

  const getInitialValues: () => LoginDetailInt = () => {
    const newInitialValues = useMemo(() => {
      return {
        firstname: registrationData === null ? '' : registrationData?.firstname,
        lastname: registrationData === null ? '' : registrationData?.lastname,
        emailAddress: sameEmailAsParent
          ? parentAccountDetails?.emailAddress
          : registrationData === null
          ? ''
          : registrationData?.emailAddress,
        mobileNumber: sameMobileNoAsParent
          ? parentAccountDetails?.mobileCountryCode +
            parentAccountDetails?.mobileNumber
          : registrationData === null
          ? '+91'
          : registrationData?.mobileNumber,
        userEmail: '',
        token: linkedFlag ? null : '',
        checkedState: false,
      };
    }, [sameMobileNoAsParent, sameEmailAsParent]);
    return newInitialValues;
  };

  function capitalizeFirstLetter(string = '') {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  }
  const handleCountryCode = (ph: any) => {
    if (ph.slice(0, 2) !== '91') {
      setCountryCode(9);
    }
    if (countryCode !== 12 && ph.slice(0, 2) === '91') {
      setCountryCode(12);
    }
  };
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false);

  const handleSameAsParentCheckbox = async (value: any) => {
    const accountId = accountIdManager?.getAccountId();
    const accountDetails = await getKycData(accountId);
    setParentAccountDetails(accountDetails?.data);
    if (value === 'mobile') {
      setSameMobileNoAsParent(!sameMobileNoAsParent);
    }
    if (value === 'email') {
      setSameEmailAsParent(!sameEmailAsParent);
    }
  };

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={`${process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY}`}
      >
        <Formik
          initialValues={getInitialValues()}
          onSubmit={handleSubmit}
          validationSchema={SignupSchema}
          enableReinitialize={true}
        >
          {({
            handleChange,
            values,
            setFieldTouched,
            setFieldValue,
            errors,
            touched,
            isValid,
          }) => (
            <Form>
              <div className="RegModalBackground">
                <div
                  className="modalContainer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="titleCloseBtn">
                    <button
                      data-test="button-close"
                      type="button"
                      onClick={onModalClose}
                    >
                      <img src={closeCircle} alt="X" />
                    </button>
                  </div>
                  <div className="modalBody">
                    <div className="title">
                      <h3>
                        {linkedFlag
                          ? `Create a Linked Account`
                          : ModalHeadingText}
                      </h3>
                    </div>
                    <div className="inputContainer">
                      <div className="Wrapper">
                        <div className="grid-item-1" id="fName">
                          <input
                            type="text"
                            data-test="input-first-name"
                            placeholder="First Name*"
                            value={capitalizeFirstLetter(values.firstname)}
                            onChange={handleChange('firstname')}
                            onBlur={() => setFieldTouched('firstname')}
                          />
                          {errors.firstname && touched.firstname && (
                            <div
                              data-test="div-inline-error-msg-firstname"
                              className="errorRegMessage"
                            >
                              {errors.firstname.toString()}
                            </div>
                          )}
                        </div>
                        <div className="grid-item-2" id="lName">
                          <input
                            type="text"
                            data-test="input-last-name"
                            placeholder="Last Name*"
                            value={capitalizeFirstLetter(values.lastname)}
                            onChange={handleChange('lastname')}
                            onBlur={() => setFieldTouched('lastname')}
                          />
                          {errors.lastname && touched.lastname && (
                            <div
                              data-test="div-inline-error-msg-lastname"
                              className="errorRegMessage"
                            >
                              {errors.lastname.toString()}
                            </div>
                          )}
                        </div>
                        <div className="grid-item-3" id="emaildId">
                          <input
                            type="text"
                            data-test="input-email"
                            placeholder="Email Id*"
                            value={values.emailAddress}
                            disabled={sameEmailAsParent}
                            style={{
                              opacity: sameEmailAsParent ? 0.5 : 1,
                              cursor: sameEmailAsParent ? 'not-allowed' : '',
                            }}
                            onChange={handleChange('emailAddress')}
                            onBlur={() => setFieldTouched('emailAddress')}
                          />
                          {errors.emailAddress && touched.emailAddress && (
                            <div
                              data-test="div-inline-error-msg-emailid"
                              className="errorRegMessage"
                            >
                              {errors.emailAddress.toString()}
                            </div>
                          )}
                        </div>
                        <div
                          data-test="input-phone-number"
                          className="grid-item-4"
                          id="mobileNumber"
                          style={{
                            opacity: sameMobileNoAsParent ? 0.5 : 1,
                          }}
                        >
                          <PhoneInput
                            inputProps={{
                              autoFocus: false,
                              prefix: '+',
                            }}
                            inputStyle={{
                              fontSize: '1rem',
                              color: 'black',
                              letterSpacing: 0.2,
                              height: '2.3rem',
                            }}
                            country="in"
                            placeholder="Enter Mobile Number*"
                            enableSearch
                            isValid
                            disabled={sameMobileNoAsParent}
                            countryCodeEditable={false}
                            value={values.mobileNumber}
                            onChange={(ph: any, country: any) => {
                              handleChange('mobileNumber')(ph);
                              handleCountryCode(ph);
                              setCountryCodeLength(country?.dialCode.length);
                            }}
                            onBlur={() => setFieldTouched('mobileNumber')}
                          />
                          <br />
                          {!linkedFlag && (
                            <Text
                              fontSize={13}
                              mt={'-15px'}
                              color={colors.grey200}
                              fontWeight={'400'}
                            >
                              An OTP will be sent to this number.
                            </Text>
                          )}

                          {errors.mobileNumber && touched.mobileNumber && (
                            <div
                              data-test="div-inline-error-msg-mobileno"
                              className="errorRegMessage"
                            >
                              {errors.mobileNumber.toString()}
                            </div>
                          )}
                        </div>
                        {(sameEmailAsParent || sameMobileNoAsParent) && (
                          <>
                            <div className="grid-item-5" id="relation">
                              <select
                                placeholder="*Relation With Parent"
                                className="relationWithParentDropdown"
                                onChange={(e) =>
                                  setRelationWithParentAccount(e.target.value)
                                }
                                style={
                                  relationWithParentAccount
                                    ? {
                                        color: colors.blackColor,
                                        height: '45px',
                                      }
                                    : { color: '#535353', height: '45px' }
                                }
                                value={relationWithParentAccount}
                              >
                                <option value="">Relation*</option>
                                {Object.values(RelationshipWithMinorEnum)?.map(
                                  (value) => (
                                    <option
                                      key={value}
                                      value={value}
                                      style={{ color: Colors.blackColor }}
                                    >
                                      {value}
                                    </option>
                                  ),
                                )}
                              </select>
                              {!relationWithParentAccount &&
                                isSaveAndContinueClicked && (
                                  <div className="errorRegMessage">
                                    <p>Select an Option</p>
                                  </div>
                                )}
                            </div>
                            {relationWithParentAccount === 'Other' && (
                              <div className="grid-item-6" id="otherRelation">
                                <input
                                  type="text"
                                  placeholder="*Relation With Parent"
                                  value={capitalizeFirstLetter(
                                    otherRelationShipWithParentAccount,
                                  )}
                                  style={{ height: '45px' }}
                                  onChange={(e: any) =>
                                    setOtherRelationShipWithParentAccount(
                                      e.target.value,
                                    )
                                  }
                                />
                                {!otherRelationShipWithParentAccount &&
                                  isSaveAndContinueClicked && (
                                    <div className="errorRegMessage">
                                      Relation is Required
                                    </div>
                                  )}
                              </div>
                            )}
                          </>
                        )}
                        <BotInput
                          {...{ values, handleChange, setFieldTouched }}
                        />
                      </div>

                      {serverError != '' && (
                        <div style={{ marginBottom: 10 }}>
                          <p className="serverErrorMessage">{serverError}</p>
                        </div>
                      )}
                      {linkedFlag && (
                        <>
                          <div className="linked_checkbox">
                            <div className="linked_checkbox">
                              <label>
                                Contact details same as my account &nbsp; Mobile
                                No. &nbsp;
                              </label>
                              <input
                                data-test="input-linked-account-checkbox"
                                type="checkbox"
                                style={{ height: '13px' }}
                                onChange={() => {
                                  handleSameAsParentCheckbox('mobile');
                                }}
                              />
                              <label>&nbsp;&nbsp;Email &nbsp;</label>
                              <input
                                data-test="input-linked-account-checkbox"
                                type="checkbox"
                                style={{ height: '13px' }}
                                onChange={() => {
                                  handleSameAsParentCheckbox('email');
                                }}
                              />
                              &nbsp;&nbsp;
                            </div>
                            <div className="linked_checkbox">
                              <input
                                data-test="input-linked-account-checkbox"
                                type="checkbox"
                                style={{ height: '13px' }}
                                onChange={() => {
                                  setCheckboxClicked(!checkboxClicked);
                                }}
                              />
                              &nbsp;&nbsp;
                              <label>
                                *Confirm if all the details are correct.{' '}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <BotCheckbox />
                    {!linkedFlag &&
                      process.env['NEXT_PUBLIC_ENV_NAME'] === 'Develop' && (
                        <GoogleRecaptchaBox
                          {...{
                            setFieldValue,
                            errors,
                            touched,
                          }}
                        />
                      )}
                    {!linkedFlag &&
                      process.env['NEXT_PUBLIC_ENV_NAME'] !== 'Develop' && (
                        <RecaptchaBox
                          {...{ setFieldValue, errors, touched, resetCaptcha }}
                        />
                      )}
                    <div className="verifyButtonContainer">
                      {!linkedFlag && (
                        <button
                          data-test="button-proceed"
                          type="submit"
                          disabled={!isValid}
                          style={{
                            backgroundColor: Colors.buttonColor,
                            color: Colors.headerBlue,
                            border: 'none',
                            outline: 'none !important',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            paddingLeft: '30px',
                            paddingRight: '30px',
                            borderRadius: '3px',
                            fontWeight: '800',
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: '13px',
                            opacity: !isValid ? 0.5 : 1,
                            cursor: !isValid ? 'not-allowed' : 'pointer',
                          }}
                        >
                          PROCEED
                        </button>
                      )}
                      {linkedFlag && (
                        <button
                          data-test="button-proceed"
                          type="submit"
                          disabled={!isValid || !checkboxClicked}
                          style={{
                            backgroundColor: Colors.buttonColor,
                            color: Colors.headerBlue,
                            border: 'none',
                            outline: 'none !important',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            paddingLeft: '30px',
                            paddingRight: '30px',
                            borderRadius: '3px',
                            fontWeight: '800',
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: '13px',
                            opacity: !isValid || !checkboxClicked ? 0.5 : 1,
                            cursor:
                              !isValid || !checkboxClicked
                                ? 'not-allowed'
                                : 'pointer',
                          }}
                          onClick={() => setIsSaveAndContinueClicked(true)}
                        >
                          PROCEED
                        </button>
                      )}
                    </div>
                    {!linkedFlag && (
                      <>
                        <div className="bottomContent">
                          <p>
                            Already using hBits?{' '}
                            <span
                              data-test="span-login-link"
                              style={{ cursor: 'pointer' }}
                              onClick={onLoginClick}
                            >
                              Login
                            </span>{' '}
                            to Dashboard
                          </p>
                          <div />
                        </div>

                        <div className="tncBottom">
                          <p>
                            By clicking on the 'Proceed' button I agree to the{' '}
                            <span
                              data-test="span-terms-condition"
                              style={{ cursor: 'pointer' }}
                              onClick={onTnCClick}
                            >
                              Terms & Conditions
                            </span>
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </GoogleReCaptchaProvider>
      {isLoaderVisible && <Loader />}
    </>
  );
};
export default RegistrationBox;
