import { useLocation } from 'react-router-dom';
import { InvestmentHash } from '../utils';
import Analaytics from './Analaytics';
import Documents from './Documents';
import Overview from './Overview';
import Portfolio from './Portfolio';
import Referal from './Referal';
import StatementOfAccount from './Statement';

interface MainSectionProps {
  controlMainSec?: number;
}
const MainSection = ({ controlMainSec }: MainSectionProps) => {
  const location = useLocation();
  const tabName = location.hash;
  const renderSection = () => {
    switch (tabName) {
      case InvestmentHash.Overview:
        return <Overview />;
      case InvestmentHash.Portfolio:
        return <Portfolio />;
      case InvestmentHash.Documnets:
        return <Documents />;
      case InvestmentHash.Analytics:
        return <Analaytics />;
      case InvestmentHash.Referral:
        return <Referal />;
      case InvestmentHash.Statement:
        return <StatementOfAccount />;
      default:
        return <Overview />;
    }
  };
  return renderSection();
};

export default MainSection;
