/*eslint-disable*/
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styles from './emailVerifyModal.module.scss';
import { Text } from '@chakra-ui/react';
import { HTTPStatusCode, SignUpAttemptSources } from 'libs/src/enums';
import { verifyEmailOTP } from 'apps/customer-portal-web/src/services/Kyc/emailVerify';
import { resendEmailOTP } from 'apps/customer-portal-web/src/services/Kyc/emailVerify';
import { SubmitHandler, useForm } from 'react-hook-form';
import closeCircle from 'libs/src/assets/svg/close-circle-outline.svg';
import { Colors } from 'libs/src/theme';

export interface EmailVerifyOtpModalProps {
  attemptId: string;
  onChangeMobileClick: () => void;
  onModalClose: () => void;
  onEmailVerified: () => void;
  EmailValue: any;
}

export interface IFormInput {
  attemptId: string;
  emailOtp: string;
}

const EmailVerifyOtpModal: FunctionComponent<EmailVerifyOtpModalProps> = ({
  attemptId,
  onChangeMobileClick,
  onModalClose,
  onEmailVerified,
  EmailValue,
}) => {
  const { register, watch } = useForm<IFormInput>();

  const [serverError, setServerError] = useState('');
  const watchValues = watch();

  const onSubmit = (data: any) => {
    const newData = {
      emailOtp: data,
      attemptId: attemptId,
    };
    verifyEmailOTP(newData)
      .then((response) => {
        console.log(response?.status === HTTPStatusCode.NO_CONTENT);
        if (response?.status == HTTPStatusCode.NO_CONTENT) {
          onModalClose();
          onEmailVerified();
        } else if (response != null && response['error']) {
          // Set error message
          const errorMsg: any = 'Either the OTP is expired or invalid';
          setServerError(errorMsg);
        }
      })
      .catch((error) => { });
  };

  const triggerOTPResend = () => {
    setServerError('');
    const newData = {
      emailAddress: EmailValue,
      attemptId: attemptId,
    };
    resendEmailOTP(newData)
      .then((response) => {
        if (response && response['status'] == HTTPStatusCode.NO_CONTENT) {
          onClickReset();
        } else if (response != null && response['error']) {
          onClickReset();
        }
      })
      .catch((error) => {
        setServerError(error);
      });
  };

  //new timer
  const Ref = useRef<any>(null);
  const [newtimer, setnewTimer] = useState<any>(119);

  const getTimeRemaining = (e: any) => {
    const currentSec = new Date();
    const total = e.getTime() - currentSec.getTime();
    const seconds = Math.floor((total / 1000) % 120);
    return {
      total,
      seconds,
    };
  };

  const startTimer = (e: any) => {
    let { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setnewTimer(seconds > 9 ? seconds : '0' + seconds);
    }
  };

  const clearTimer = (e: any) => {
    setnewTimer(119);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);

    // @ts-ignore
    if (Ref) {
      Ref.current = id;
    }
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 119);
    return deadline;
  };
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  const isValidOtpString = (watchVal: any) => {
    if (watchVal === undefined || Object.keys(watchVal).length === 0) {
      return true;
    }
    return watchVal?.emailOtp.length != 6;
  };

  return (
    <div className={styles['emailmodalBackground']}>
      <div className={styles['modalOtpContainer']}>
        <div className={styles['titleCloseBtn']}>
          <button type="button" onClick={onModalClose}>
            <img src={closeCircle} alt="X" />
          </button>
        </div>
        <div className={styles['modalBody']}>
          <div className={styles['title']}>
            <h3>Enter OTP</h3>
          </div>
          <div className={styles['subtitle']}>
            <p>Please enter the 6 digit OTP received on your e-mail address.</p>
          </div>
          <div className={styles['inputOtpContainer']}>
            <div className={styles['OtpWrapper']}>
              <div
                className={styles['grid-item-2']}
                data-test="div-find-input-email-otp"
                id="lName"
              >
                <input
                  data-test="input-email-otp"
                  type="tel"
                  placeholder="E-mail OTP"
                  {...register('emailOtp', {
                    required: 'This is required.',
                  })}
                />{' '}
              </div>
              {serverError != '' && (
                <div
                  data-test="div-inline-error-msg-invalid-otp-signup"
                  className={styles['serverError']}
                >
                  <p className="serverErrorMessage">{serverError}</p>
                </div>
              )}
              {newtimer > 0 && (
                <Text fontSize={13} color={'#535353'} textAlign="center">
                  {`OTP expires in ${newtimer} sec...`}
                </Text>
              )}

              <div
                className={styles['resendContainer']}
                style={newtimer > 0 ? { opacity: 0.5 } : { opacity: 1 }}
              >
                <Text
                  className={styles['otp_text']}
                  textAlign="center"
                  color="#535353"
                >
                  Didn't Receive?{' '}
                  <button
                    data-test="button-resed-email-otp"
                    style={{
                      cursor: 'pointer',
                      border: 'none',
                      backgroundColor: 'white',
                    }}
                    onClick={() => triggerOTPResend()}
                    disabled={newtimer > 0}
                  >
                    Resend OTP{' '}
                  </button>
                </Text>
              </div>
            </div>
          </div>
          <div
            className={styles['verifyButtonContainer']}
            style={{ marginBottom: '30px' }}
          >
            <div className={styles['skiptext']}>
              <p>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={onChangeMobileClick}
                >
                  BACK
                </span>
              </p>
            </div>
            <div
              data-test="button-email-otp-verify"
              // disabled={isValidOtpString(watchValues)}
              style={{
                backgroundColor: Colors.buttonColor,
                color: Colors.headerBlue,
                border: 'none',
                outline: 'none !important',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '30px',
                paddingRight: '30px',
                borderRadius: '3px',
                fontWeight: '800',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '13px',
                opacity: isValidOtpString(watchValues) ? 0.5 : 1,
                cursor: isValidOtpString(watchValues)
                  ? 'not-allowed'
                  : 'pointer',
              }}
              onClick={() => onSubmit(watchValues.emailOtp)}
            >
              VERIFY OTP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailVerifyOtpModal;
