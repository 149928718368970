import {
  INVESTMENT_USER,
  UPDATE_ALL_PROPERTIES,
  UPDATE_GET_ALL_PROPERTIES_STATUS,
  INVESTMENT_DOCUMENTS,
} from '../constants/ReduxConstants';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';
import { fetchAsync, HTTPStatusCode } from '@hBits.Core';
import { API_REQ_STATUS } from '../../utils';

export const investmentUser = (payload: any) => ({
  type: INVESTMENT_USER,
  payload,
});

export function getAllProperties(accountId: any) {
  return async (dispatch: any) => {
    try {
      const request = {
        url:
          baseApiUrl +
          endpoints.investmentProperties.replace('#{accountId}', accountId),
      };
      dispatch({
        type: UPDATE_GET_ALL_PROPERTIES_STATUS,
        payload: API_REQ_STATUS.LOADING,
      });
      const response = await fetchAsync(request);
      const newData = response.data.filter(
        (item: any) =>
          item?.percentageOwned > 0 || item?.investmentStage === 'Transferred',
      );

      if (response.status === HTTPStatusCode.OK) {
        dispatch({
          type: UPDATE_ALL_PROPERTIES,
          payload: newData,
        });
        dispatch({
          type: UPDATE_GET_ALL_PROPERTIES_STATUS,
          payload: API_REQ_STATUS.SUCCESS,
        });
      } else {
        dispatch({
          type: UPDATE_GET_ALL_PROPERTIES_STATUS,
          payload: API_REQ_STATUS.ERROR,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_GET_ALL_PROPERTIES_STATUS,
        payload: API_REQ_STATUS.ERROR,
      });
    }
  };
}

export const investmentDashboard = (payload: any) => ({
  type: INVESTMENT_DOCUMENTS,
  payload,
});
