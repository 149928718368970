import { Flex, Image, Select, Text } from '@chakra-ui/react';
import IndividualHolder from './IndividualHolder';
import PrivateHolder from './PrivateHolder';
import LLPHolder from './LLPHolder';
import HUFHolder from './HUFHolder';
import PartnershipHolder from './PartnershipHolder';
import JointHolder from './JointHolder';
import styles from '../index.module.scss';
import crossIcon from '../../../assets/kyc/circleLogo.svg';
import colors from 'libs/src/theme/colors';
import { useDispatch, useSelector } from 'react-redux';
import { categoryType, getHolderTypeName } from '../utils';
import KycSubmittedPage from '../KycScreens/KycSubmittedPage';
import { AccountTypeEnum, Colors, KycStatus } from '@hBits.Core';
import StatusTextFile from '../HelperComponents/StatusTextFile';
import verifiedImage from '../../../assets/kyc/greentick.png';
import InvalidatedImage from '../../../assets/kyc/invaidated.jpeg';
import { useEffect, useState } from 'react';
import accountIdManager from 'libs/src/auth/account-manager';
import { updateUserType } from 'apps/customer-portal-web/src/services/Kyc/getKycData';
import TrustHolder from './TrustHolder';
import { TAB_VALUE } from 'apps/customer-portal-web/src/store/constants/ReduxConstants';

interface UserHolderType {
  tabName: string[];
  name: string;
}

interface KycContainerProps {
  userHolderType: UserHolderType;
  holderNumber: number;
  holderLength: number;
  crossClicked: (value: boolean) => void;
}

const KycContainer = (props: KycContainerProps) => {
  const {
    isSubmittedPageVisible,
    kycStatusForHolder,
    kycStatusForAccount,
    kycData,
    isUserTypeApi,
    isAllFieldsDisable,
    tabValue,
  } = useSelector((state: any) => state.KycReducer);
  const dispatch = useDispatch();
  const { userHolderType, holderNumber, holderLength, crossClicked } = props;
  const { tabName, name } = userHolderType;
  const [userTypeName, setUserTypeName] = useState<any>();
  const [userTypeTabName, setUserTypeTabName] = useState<any>();
  const [userHolderData, setUserHolderData] = useState<any>();

  useEffect(() => {
    setUserTypeName(name);
    setUserTypeTabName(tabName);
  }, [userHolderType]);

  useEffect(() => {
    if (holderNumber != 0) {
      if (!kycData[holderNumber]?.accountType) {
        setUserTypeName(categoryType[0].name);
        setUserTypeTabName(categoryType[0].tabName);
      }
      for (let itm of categoryType) {
        if (itm.name === kycData[holderNumber]?.accountType) {
          setUserHolderData(itm);
          setUserTypeTabName(itm.tabName);
          setUserTypeName(itm.name);
        }
      }
    }
  }, [holderNumber,kycStatusForHolder[holderNumber]]);

  const onClickCross = () => {
    crossClicked(true);
  };

  useEffect(() => {
    if (userHolderData && holderNumber !== 0 && !kycData[holderNumber]) {
      handleUserTypeOnChange(userHolderData);
    }
  }, [isUserTypeApi]);

  const handleUserTypeOnChange = async (value: any) => {
    setUserHolderData(value);
    const data = {
      accountType: value.name,
      holderType: getHolderTypeName(holderNumber),
    };
    const response = await updateUserType(
      accountIdManager?.getAccountId(),
      data,
    );
    setUserTypeName(value.name);
    setUserTypeTabName(value.tabName);
  };

  const renderIndividualType = () => {
    switch (userTypeName) {
      case AccountTypeEnum.Individual:
        return (
          <IndividualHolder
            tabName={userTypeTabName}
            holderNumber={holderNumber}
          />
        );
      case AccountTypeEnum.Private:
        return (
          <PrivateHolder
            tabName={userTypeTabName}
            holderNumber={holderNumber}
          />
        );
      case AccountTypeEnum.LLP:
        return (
          <LLPHolder tabName={userTypeTabName} holderNumber={holderNumber} />
        );
      case AccountTypeEnum.Huf:
        return (
          <HUFHolder tabName={userTypeTabName} holderNumber={holderNumber} />
        );
      case AccountTypeEnum.Joint:
        return (
          <JointHolder tabName={userTypeTabName} holderNumber={holderNumber} />
        );
      case AccountTypeEnum.Partnership:
        return (
          <PartnershipHolder
            tabName={userTypeTabName}
            holderNumber={holderNumber}
          />
        );
      case AccountTypeEnum.Trust:
        return (
          <TrustHolder tabName={userTypeTabName} holderNumber={holderNumber} />
        );
      default:
        return (
          <IndividualHolder tabName={tabName} holderNumber={holderNumber} />
        );
    }
  };

  return (
    <Flex
      className={styles['kyccontainer_card']}
      mt={8}
      w={'100%'}
      direction={'column'}
      padding={{ base: '1rem', md: '2rem' }}
    >
      {isSubmittedPageVisible[holderNumber] && (
        <KycSubmittedPage holderNumber={holderNumber} />
      )}
      {!isSubmittedPageVisible[holderNumber] && (
        <>
          {holderLength > 1 && (
            <Flex justifyContent={'flex-end'}>
              <Flex cursor={'pointer'}>
                <Image
                  src={crossIcon}
                  w={{ base: '20px', md: '35px' }}
                  onClick={() => onClickCross()}
                  alt=""
                />
              </Flex>
            </Flex>
          )}
          {kycStatusForHolder[holderNumber] === KycStatus.KycVerified &&
            kycStatusForAccount !== KycStatus.KycVerified && (
              <Flex w={'100%'} mb={4}>
                <StatusTextFile
                  text={`Kyc has been Verified for ${getHolderTypeName(
                    holderNumber,
                  )} Holder`}
                  image={verifiedImage}
                  isVerified={true}
                />
              </Flex>
            )}
          {kycStatusForHolder[holderNumber] === KycStatus.KycInvalidated && (
            <Flex w={'100%'} mb={4}>
              <StatusTextFile
                text={`Kyc has been Invalidated for ${getHolderTypeName(
                  holderNumber,
                )} Holder`}
                image={InvalidatedImage}
              />
            </Flex>
          )}
          {holderLength > 1 && (
            <Flex justifyContent={'flex-start'} ml={4}>
              <Flex
                color={colors.bgDarkBlue}
                fontWeight={600}
                fontSize={{ base: '18px', md: '22px' }}
                mb={4}
              >
                {getHolderTypeName(holderNumber) + ' Holder'}
              </Flex>
            </Flex>
          )}
          {holderNumber !== 0 && (
            <Flex
              flexDirection={'column'}
              padding={{ base: '1rem', md: '1rem' }}
            >
              <Text
                fontSize={'12px'}
                fontWeight={'500'}
                color={Colors.greyUserNameColor}
              >
                Choose Type
              </Text>
              <Flex pt={2}>
                <Select
                  width={'100%'}
                  height={'30px'}
                  fontSize={'16px'}
                  borderRadius={'5px'}
                  borderColor={Colors.greyBorderColor}
                  value={JSON.stringify(userHolderData)}
                  onChange={(e) => {
                    handleUserTypeOnChange(JSON.parse(e.target.value));
                    dispatch({
                      type: TAB_VALUE,
                      payload: {
                        ...tabValue,
                        [holderNumber]: 0,
                      },
                    });
                  }}
                  disabled={isAllFieldsDisable[holderNumber]}
                  _disabled={{ color: 'black' }}
                  color={Colors.blackColor}
                  border={'1px solid #c9c9c9'}
                  _focus={{
                    boxShadow: 'none',
                    outline: 'none',
                    border: '1px solid #c9c9c9',
                  }}
                >
                  {categoryType?.map((value) => (
                    <option
                      key={value.name}
                      value={JSON.stringify(value)}
                      style={{ color: Colors.blackColor }}
                    >
                      {value.name}
                    </option>
                  ))}
                </Select>
              </Flex>
            </Flex>
          )}
          <Flex>{renderIndividualType()}</Flex>
        </>
      )}
    </Flex>
  );
};
export default KycContainer;
