declare global {
  interface Window {
    MSStream?: any;
    standalone?: any;
  }
  interface Navigator {
    standalone?: any;
  }
}

export const isWebView = (): boolean => {
  if (typeof navigator === 'undefined' || typeof window === 'undefined') {
    return false; // Fallback for SSR safety
  }

  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Detect iOS WebView
  const isIOSWebView =
    /iPhone|iPod|iPad/i.test(userAgent) &&
    !window.MSStream && // Safe usage after extending the Window interface
    !navigator.standalone && // Exclude "Add to Home Screen" apps
    !/Safari/.test(userAgent); // Exclude Safari browser

  // Detect Android WebView
  const isAndroidWebView =
    /Android/.test(userAgent) &&
    /Version\/[0-9.]+/.test(userAgent) && // WebView includes "Version/x.x"
    /wv/.test(userAgent); // WebView includes "wv"

  return isIOSWebView || isAndroidWebView;
};
