export default {
  blackColor: '#000000',
  placeholderColor: '#1a202c',
  themeColor: '#E0A867',
  statusBarColor: 'transparent',
  headerColor: '#FFFFFFCC',
  purple: '#BA178C',
  purple200: '#ba178c80',
  grey: '#333333',
  greyPlaceholder: '#718096',
  grey100: '#999999',
  grey200: '#666666',
  grey300: '#E9ECF3',
  grey400: '#F9FBFF',
  grey500: '#F8F8F8',
  bgDark: '#F2F2F2',
  bgLight: '#FBFCFF',
  shadowColor: '#7574b5',
  borderColor: '#D3E0FC',
  blue500: '#8E9BBB',
  red: '#E34C4C',
  pink: '#ED64C6',
  borderBlue: '#07635D',
  sapphire: '#1B3066',
  headerBlack: '#1F1F1F',
  headerBlue: '#1A295B',
  white: '#FFFFFF',
  greyBorderColor: '#ccc',
  lightThemeColor: '#FDD7AC',
  bgGrey: '#e0e0e0',
  bgLightBlue: '#F9F9F9',
  bgDarkBlue: '#1a295b',
  buttonBorderGrey: '#a5a5a5',
  orangeColor: '#ffb657',
  borderLightGrey: '#707070',
  textGreyColor: '#535353',
  green: '#07635D',
  yellow: '#ffbe00',
  lightGrey: '#e2e2e2',
  buttonColor: '#ffb351',
  darkRed: '#D10000',
  lightRed: '#FF0000',
  lightGreen: '#008F00',
  silver: '#C1C1C1',
  dividerLine: '#CCCCCC',
  greyUserNameColor: '#808080',
  kycPlaceholder: '#535353',
};
