import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const CustomThreeSectionWrapper = styled(Flex)`
  height: 100%;
  width: 100%;
`;

interface ShadowedProps {
  readonly background: any;
}
export const PageWrapper = styled.div<ShadowedProps>`
  background-image: ${(props) => `url(${props['background']})`};
  height: 100%;
`;
