import NavigationSection from '../../components/NavSection/DashboardNavigationSection';
import { NavbarLinksName } from './DashboardContents';
import CustomThreePartition from '../../components/CustomThreePartition';
import backgroundImg from '../../assets/bg_img_dashboard.png';
import UpcomingProperties from './components/UpcomingProperties';
import MainSection from './components/MainSection';
import { Flex } from '@chakra-ui/layout';

const Dashboard = () => {
  const DashboardNavigationSection = ({ controlMainSecFunc }: any) => {
    return (
      <NavigationSection
        navbarItems={NavbarLinksName}
        handleTabNav={controlMainSecFunc}
      />
    );
  };

  return (
    <>
      <CustomThreePartition
        background_img={backgroundImg}
        FirstSection={DashboardNavigationSection}
        SecondSection={MainSection}
        ThirdSection={UpcomingProperties}
        isChannelPartner = {true}
      />
    </>
  );
};

export default Dashboard;
