import { FunctionComponent, useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import './TncModal.module.scss';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ChakraProvider,
  Flex,
  Text,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import * as React from 'react';
export interface TncModalProps {
  onModalClose: () => void;
}

const TncModal: FunctionComponent<TncModalProps> = ({ onModalClose }) => {
  const redirectToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    addEventListener('focus', redirectToTop);
  }, []);
  return (
    <ChakraProvider>
      <Modal
        isOpen={true}
        onClose={onModalClose}
        isCentered={true}
        size={'5xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ padding: 0 }}>
            <Flex
              bg={'#1a295b'}
              flex={1}
              pt={3}
              pb={3}
              pl={[0, 0, '60px', '60px']}
              pr={[0, 0, '60px', '60px']}
              justifyContent={'space-between'}
              style={{ zIndex: 10000000 }}
            >
              <Text fontSize="3xl" ml={5} color={'white'}>
                Terms and Conditions
              </Text>
              <Flex
                data-test="button-close-terms-conditions"
                className="titleCloseBtnTnc"
                onClick={onModalClose}
              >
                <Flex
                  borderWidth={'1px'}
                  borderColor="white"
                  justifyContent={'center'}
                  alignItems="center"
                  borderRadius={50}
                  h={'35px'}
                  w={'35px'}
                  style={{ cursor: 'pointer' }}
                >
                  <Text color="white" fontSize={'20px'}>
                    X
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex
              style={{
                overflow: 'scroll',
                height: '70vh',
              }}
              pl={[0, 0, '50px', '50px']}
              pr={[0, 0, '50px', '50px']}
              pt={'30px'}
              className="modalBody main-container-flex"
              flexDirection={'column'}
            >
              <Flex flexDirection={'column'}>
                <Text fontSize="3xl" color={'#1a295b'} as="b">
                  Terms and Conditions
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={5}>
                  This section contains the Terms of Use of this website. By
                  accessing{' '}
                  <a href="https://www.hbits.co" style={{ fontWeight: 'bold' }}>
                    www.hbits.co
                  </a>{' '}
                  (“this Website”) and any of its pages, user hereby accepts and
                  agrees to be bound by and to comply with (i) these Terms of
                  Use, and (ii) the Privacy Policy available on this Website.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  1. Introduction
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  This Platform (hereinafter defined) is owned and operated by{' '}
                  <span className="hBit_red_span">
                    hBits Proptech Private Limited
                  </span>{' '}
                  <span className="term-in-use"> (“hBits”) </span>, a company
                  incorporated under the laws of India and having its registered
                  office located at Ground Floor, G-4, Court Chambers, 35, Sir
                  Vithaldas Thackersey Marg, New Marine Lines, Mumbai City,
                  Maharashtra 400020 India. The terms hBits’ website, “we”,
                  “our” and “us” in these{' '}
                  <span className="term-in-use">"Terms of Use"</span>
                  Terms of Use refer to hBits..
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  These terms and conditions of use (“Terms of Use”) describe
                  the terms applicable to user’s use of this Website,
                  mobile-optimized versions of the website, digital
                  applications, and any other media formats for the purposes of
                  use and promotion of hBits (collectively referred to as the
                  “Covered Platforms”), and other products, software and
                  services, from time to time, on the{' '}
                  <span className="term-in-use">Covered Platforms</span> , owned
                  or licensed and operated by hBits and its affiliates, such as
                  email, chat, bulletin board services, information related to
                  recommendations, news groups, forums, communities, personal
                  web pages, calendars, and/or other message (together the
                  “Services”)
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  “User” as aforesaid includes user’s permitted assigns,
                  successors, heirs and legal representatives, unless the
                  context requires otherwise;
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  By using this Website, the User expressly represents that
                  he/she has read and understood these Terms of Use and agrees
                  to be bound by these Terms of Use. Furthermore, by using this
                  Website, the User expressly represents that (a) he/she has the
                  capacity to be bound by these Terms of Use; (b) if he/she is
                  acting on behalf of a company or other entity, then he/she has
                  the relevant authority to bind such company or entity; (c)
                  he/she will not violate any rights of hBits, including
                  intellectual property rights such as copyright or trademark
                  rights; and (d) he/she has not been or is not suspended or
                  removed from the Website by hBits for any reason.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  2. Information Sharing and Confidentiality
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  By using our service, User confirm that User is of the
                  required legal age and competent to form a binding contract
                  and is not a person barred from receiving services under the
                  laws as applicable in India. User also agree to provide true,
                  accurate, current, complete and updated information about
                  themselves as prompted by our Product’s/Service’s registration
                  form or any information provided by user through other means
                  of communication. If User provide any information that is
                  untrue, inaccurate, not current or incomplete (or becomes
                  untrue, inaccurate, not current or incomplete), or we have
                  reasonable grounds to suspect that such information is untrue,
                  inaccurate, not current or incomplete, we have the right to
                  suspend or terminate User account and refuse any and all
                  current or future use of our Product/Service (or any portion
                  thereof). User undertakes to indemnify and keep indemnified
                  hBits for any loss, claim, damage, costs (including legal and
                  professional costs) or whatsoever relating to any abuse or
                  violation including those aforesaid.
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  "<span>User Information</span>" is defined as any information
                  provided to us by the User during the registration process, in
                  the feedback area or through any email feature including but
                  not limited to information regarding User’s name, address,
                  telephone number, date of birth, gender, e-mail address,
                  organization, etc. (“
                  <span>Personal Information</span>”). We will protect User
                  Information in accordance with our Privacy Policy (separately
                  uploaded). HBits will treat all the Personal Information
                  provided by User(s) confidential. If User use the
                  Product/Services/Website, User solely will be responsible for
                  maintaining the confidentiality of User Login ID and Password
                  and for restricting access to User computer, and User agree to
                  accept responsibility for all activities that occur under User
                  Login ID or Password. We shall not be liable to any person for
                  any loss or damage which may arise as a result of any failure
                  by User to protect User password or account. User undertakes
                  to indemnify and keep indemnified hBits for any loss, claim,
                  damage, costs (including legal and professional costs) or
                  whatsoever relating to User responsibility or liability or
                  failure including those aforesaid. User agree to notify us
                  immediately of any unauthorized use of User account or any
                  other breach of security. We reserve the right to refuse
                  service, terminate accounts, or remove or edit content in its
                  sole discretion.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  3. User Agree and Confirm
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <UnorderedList>
                    <ListItem>
                      That User shall use the Services provided by us, for
                      lawful purposes only and shall comply with all applicable
                      laws and regulations while using the Covered Platform and
                      Services.
                    </ListItem>
                    <ListItem>
                      {' '}
                      That User shall provide authentic and true information in
                      all instances where such information is requested of the
                      User. We reserve the right to confirm and validate the
                      information and other details provided by the User at any
                      point of time. If upon confirmation User details are found
                      to be false (wholly or partly), we have the right and the
                      sole discretion to reject the registration and debar the
                      User from using the Covered Platform and Services and/or
                      other affiliated products without prior intimation
                      whatsoever.
                    </ListItem>
                    <ListItem>
                      {' '}
                      That User shall access the Covered Platform and Services
                      and transaction thereon at its sole risk and shall use its
                      best judgment before entering into any transaction.
                    </ListItem>
                    <ListItem>
                      {' '}
                      That the use of this Website does not amount to the
                      creation of an attorney-client relationship. Any
                      transmission, receipt or use of this Website and
                      information and materials contained therein, or sending an
                      e-mail to our offices, will not create or constitute an
                      attorney-client relationship.
                    </ListItem>
                    <ListItem>
                      That Users should make themselves aware of the laws of
                      their respective country or those applicable to them in
                      relation to any of the matters described in these pages.
                      If the User chooses to access the Website then it shall be
                      deemed to be done on his/ her own initiative and he/she
                      shall be held responsible for compliance with applicable
                      local, national or international laws.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  4. Disclaimer of Liability and Warranty
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  The content published on this Website may include inaccuracies
                  or errors, including but not limited to pricing errors or
                  arithmetical errors. We do not guarantee the accuracy of, and
                  disclaim all liability for any errors or other inaccuracies
                  relating to the information and description of the content,
                  products, and services. We expressly reserve the right to
                  correct any pricing errors or arithmetical calculations on the
                  Website and/or on pending reservations made under an incorrect
                  price or calculation. hBits makes no representation about the
                  suitability of the information, Software, products, and
                  services contained on this site for any purpose, and the
                  inclusion or offering of any products or services on this site
                  does not constitute any endorsement or recommendation of such
                  products or services. All such information, software,
                  products, and services are provided on "AS IS" basis without
                  express or implied warranties, representations, endorsements
                  or conditions of any kind. hBits disclaims all warranties and
                  conditions that this Website, its services or any email sent
                  from hBits, its affiliates, and/or their respective or
                  associated service providers are free of viruses or other
                  harmful components. hBits hereby disclaims all warranties and
                  conditions with regard to this information, software,
                  products, and services, including all implied warranties and
                  conditions of merchantability, fitness for a particular
                  purpose, title, and no infringement. hBits and its affiliates
                  have no liability and will make no refund in the event of any
                  delay, cancellation, strike, force majeure or other causes
                  beyond their direct control, and they have no responsibility
                  for any additional expense omissions delays or acts of any
                  government or authority. In no event shall hBits and/or its
                  affiliates be liable for any direct, indirect, punitive,
                  incidental, special, or consequential damages arising out of,
                  or in any way connected with, User’s access to, display of or
                  use of this Website or with the delay or inability to access,
                  display or use this Website (including, but not limited to,
                  User’s reliance upon opinions appearing on this site; any
                  computer viruses, information, software, linked sites,
                  products, and services obtained through this site; or
                  otherwise arising out of the access to, display of or use of
                  this Website) whether based on a theory of negligence,
                  contract, tort, strict liability, or otherwise, and even if
                  hBits and/or its affiliates their respective service providers
                  have been advised of the possibility of such damages. The User
                  understands that the Website is used by him/her solely at
                  his/her own risk, cost and liability.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  5. User agrees and confirms that User shall not use the
                  Covered Platform and Services for any of the following
                  purposes:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <UnorderedList>
                    <ListItem>
                      Disseminating any unlawful, harassing, libelous, abusive,
                      threatening, harmful, vulgar, obscene, or otherwise
                      objectionable material/content.
                    </ListItem>
                    <ListItem>
                      Transmitting material that encourages conduct that
                      constitutes a criminal offence, results in civil liability
                      or otherwise breaches any relevant laws, regulations or
                      code of practice.
                    </ListItem>
                    <ListItem>
                      Gaining unauthorized access to other computer systems.
                    </ListItem>
                    <ListItem>
                      Using, displaying, mirroring or framing the product/
                      website, or any individual element within the
                      product/website or services, hBits’ name, any hBits
                      trademark, logo, product logo, product trademark or other
                      proprietary information, or the lausert and design of any
                      page or form
                    </ListItem>

                    <ListItem>
                      Interfering with any other person's use or enjoyment of
                      the product/website.
                    </ListItem>
                    <ListItem>
                      Breaching any applicable laws, rules or regulations.
                    </ListItem>

                    <ListItem>
                      Interfering or disrupting networks or web sites connected
                      to the product/website.
                    </ListItem>
                    <ListItem>
                      Making, transmitting or storing electronic copies of
                      materials protected by copyright without the permission of
                      the copyright owner.
                    </ListItem>
                    <ListItem>
                      Collecting or storing any personally identifiable
                      information from the website / product or services from
                      other users of the website / product or services without
                      their express permission.
                    </ListItem>

                    <ListItem>
                      Violating any applicable law or regulation.
                    </ListItem>
                    <ListItem>
                      Encouraging or enabling any other individual to do any of
                      the foregoing.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  6. Payments through other electronic modes for value added
                  services.
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  User agrees, understands and confirms that the credit
                  card/debit card/wallets details provided by User towards
                  subscription fees for availing of any of our Product/Services
                  will be correct and accurate and User shall not use the credit
                  card/debit card which is not lawfully owned by User, i.e. in a
                  transaction, User must use User’s own card/account. User
                  further agrees and undertakes to provide the correct and valid
                  credit card details when making payment on our
                  Product/Services. We otherwise do not store any information
                  related to User’s Credit card/ Debit card or Net banking. In
                  case we do not receive an authorization from the respective
                  bank or the transaction gets interrupted due to any reason,
                  the transaction will be treated as failed and no order will be
                  processed for that transaction. In this case, if any amount
                  has been deducted from User’s account, the same will be
                  credited back. The liability for use of a card fraudulently
                  will be on User and the onus to 'prove otherwise' shall be
                  exclusively on User and hBits shall not be responsible in any
                  manner whatsoever.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  7. Cookies and Third Party Advertising
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  This Website uses Google Ad Words remarketing service to
                  advertise on Google and other third party websites, to
                  previous visitors of our Website, who have done any activity
                  on our Website. This could be in the form of an advertisement
                  in the Google search result page, or a site in the Google
                  Display Network. Third-party vendors, including but not
                  limited to Google, Facebook and LinkedIn, use cookies to serve
                  ads based on someone's past visits to the hBits insurance
                  website. Please note that any data collected will be used in
                  accordance with our own Privacy Policy and Google's privacy
                  policy. User may set preferences for how Google advertises to
                  User using the Google Ad Preferences page.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  8. Fraudulent/Declined Transactions
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Our payment partner’s (being the Payment Gateways and
                  facilitators and Banks) and their fraud detection team
                  constantly monitors User account in order to avoid fraudulent
                  accounts and transactions. We reserve the right to initiate
                  legal proceedings against such persons for fraudulent use of
                  our Product/Services and any other unlawful acts or omissions
                  in breach of these terms and conditions. In the event of
                  detection of any fraudulent or declined transaction, prior to
                  initiation of legal actions, we reserve the right to
                  immediately delete such User account(s) and dishonor all past
                  and pending orders without any liability. For the purpose of
                  this clause, we shall owe no liability for any refunds.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  9. Session Control
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  There is an internal session manager that ensures that even if
                  User are not around User’s browser for more than 20 minutes it
                  asks for a login once the User is back to ensure that there is
                  no unwanted external intervention in User’s transactions/use
                  of the WebSite.
                </Text>
              </Flex>

              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  10. Information we collect and that User provide
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Our analytic tools may also capture anonymous information such
                  as User demographic and geographic information, with a view to
                  assist us in improving customer experience. We use this
                  information to evaluate traffic patterns on our Website, so
                  that we can make it more useful to our Users. The information
                  we learn from User helps us personalize and continually
                  improve User experience on our platform. We do not voluntarily
                  make this information available to third parties, nor do we
                  use it for any other purpose, except as set out herein. Set
                  out below are the types of information we gather:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <UnorderedList>
                    <ListItem>
                      Information User Give Us: We receive and store any
                      information the User enter on our Product or give us in
                      any other way, including but not limited to name, email
                      id, phone number, address etc. User can choose not to
                      provide certain information, but in such event User may
                      not be able to take advantage of many of our features. We
                      use the information that User provides for such purposes
                      as responding to User requests, improving our product, and
                      communicating with the User. The User needs to fill all
                      the mandatory information for placing an order with us. We
                      may, as required or permitted by law or in the course of
                      performing our regulatory responsibilities, provide
                      personally identifiable information, such as User name and
                      address, or any other information that User provides,
                      without User’s permission to persons or organizations that
                      request this information. hBits has the right to share
                      limited details regarding the subscriber with each other
                      as per the requirement.
                    </ListItem>
                    <ListItem>
                      Automatic Information: We receive and store certain types
                      of information whenever the User interact with us. For
                      example, like many products, we use "cookies," and we
                      obtain certain types of information when User’s Web
                      browser accesses our product or advertisements and other
                      content served by or on behalf of hBits on other products.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  11. Content submitted by user
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits does not claim ownership of any materials made available
                  by the User through the Website. At hBits' sole discretion,
                  such materials may be included in the Service in whole or in
                  part or in a modified form. With respect to such materials
                  User submit or make available for inclusion on the Website,
                  User grant hBits a perpetual, irrevocable, non-terminable,
                  worldwide, royalty-free and non-exclusive license to use,
                  copy, distribute, publicly display, modify, create derivative
                  works, and sublicense such materials or any part of such
                  materials (as well as use the name that User submit in
                  connection with such submitted content). User hereby
                  represent, warrant and covenant that any materials provided by
                  the User do not include anything (including, but not limited
                  to, text, images or video) to which User do not have the full
                  right to grant the license specified in this Section. We take
                  no responsibility and assume no liability for any submitted
                  content posted or submitted by User. We have no obligation to
                  post User comments; we reserve the right in our absolute
                  discretion to determine which comments are published on the
                  Site. If User does not agree to these Terms of USe, please do
                  not provide us with any submitted content. User agrees that
                  User is fully responsible for the content submitted by the
                  User. User are prohibited from posting or transmitting to or
                  from this Website: (i) any unlawful, threatening, libelous,
                  defamatory, obscene, pornographic, or other material or
                  content that would violate rights of publicity and/or privacy
                  or that would violate any law; (ii) any commercial material or
                  content (including, but not limited to, solicitation of funds,
                  advertising, or marketing of any good or services); and (iii)
                  any material or content that infringes, misappropriates or
                  violates any copyright, trademark, patent right or other
                  proprietary right of any third party. User shall be solely
                  liable for any damages resulting from any violation of the
                  foregoing restrictions, or any other harm resulting from User
                  posting of content to this Website and hBits shall not be
                  responsible in any manner whatsoever.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  12. Intellectual Property Right
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  The content and information contained within the Covered
                  Platform or Services or delivered to User in connection with
                  User’s use of our Covered Platform is the property of hBits
                  and any other third party (where applicable). The trademark,
                  trade names and logos (the "Trade Marks") that are used and
                  displayed on our website include registered and unregistered
                  Trade Marks of us and other third parties. Nothing on our
                  website should be construed as granting any license or right
                  to use any Trade Marks displayed on our website. We retain all
                  proprietary rights on our website. User is prohibited from
                  using the same without written permission of hBits of such or
                  such other parties. The materials on this website are
                  protected by copyright and no part of such materials may be
                  modified, reproduced, stored in a retrieval system,
                  transmitted (in any form or by any means), copied,
                  distributed, used for creating derivative works or used in any
                  other way for commercial or public purposes without the prior
                  written consent of hBits.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  14. Termination
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  The following terms shall apply only if there is no other
                  written contract or agreement between the parties with respect
                  to the following terms:
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  <UnorderedList>
                    <ListItem>
                      Either User or hBits may terminate the relationship
                      between us, with or without cause, at any time to be
                      effective immediately.
                    </ListItem>
                    <ListItem>
                      While hBits will try to provide User prior notice of any
                      termination of user account, user agrees that hBits may
                      under certain circumstances, immediately terminate User
                      Account. Causes for termination may include, but shall not
                      be limited to, User’s breach of the Terms of Use, requests
                      by law enforcement or government agencies, non-payment of
                      fees owed by User in connection with the access and use of
                      the Covered Platform or the Services.
                    </ListItem>
                    <ListItem>
                      hBits shall not be liable to User or any third party for
                      the termination of any Covered Platform or the Services.
                      If User objects to any Terms of Use or become dissatisfied
                      with the Service or Covered Platform in any way, User’s
                      only recourse is to: (a) discontinue use of the Covered
                      Platform or the Services; and (b) notify hBits of such
                      discontinuance.
                    </ListItem>
                    <ListItem>
                      Upon termination of the relationship, User’s right to
                      access or use the Covered Platforms and the Services shall
                      immediately cease. User shall have no right, and hBits
                      shall have no obligation thereafter, to execute any of
                      User’s uncompleted tasks or forward any unread or unsent
                      messages to User or any third party. Once User
                      registration is terminated, cancelled or suspended, any
                      data that user have stored on the Covered Platforms may
                      not be retrieved later.
                    </ListItem>

                    <ListItem>
                      In the event User’s relationship with us is terminated due
                      to cause or breach of the Terms of Use on User’s part, we
                      will be entitled to make appropriate deductions from the
                      amount available in User’s Accounts, prior to refunding
                      the balance to user’s personal bank account.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  15. Governing Law and Jurisdiction
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  These Terms shall be construed in accordance with the
                  applicable laws of India without giving effect to any
                  principles of conflicts of laws. The Courts at Mumbai shall
                  have exclusive jurisdiction in any proceedings or disputes
                  arising in relation to or out of these Terms and its
                  interpretation thereof. hBits causes the control and
                  maintenance of this Website from India.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  16. Terms and Conditions, Notices, and Revisions
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  If User choose to visit this Website, User visit and any
                  dispute over privacy is subject to this Notice and our Terms
                  of Use, including limitations on damages, resolution of
                  disputes, and application of the laws of India.
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  If user has any concern about privacy at our Product, please
                  contact us with a thorough description, and we will try to
                  resolve it. Our business changes constantly, and our Privacy
                  Policy and the Terms of Use will also change; but User should
                  check our website frequently to see recent changes or updates.
                  Unless stated otherwise, our current Privacy Policy applies to
                  all information that we have about User and User account.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  17. SMS and Voice Communications
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  All customers who register on our Website agree, acknowledge
                  and accept as per the Terms of Use and that even if they are
                  registered under NDNC (National Do Not Call) or DND (Do Not
                  Disturb), by accepting the terms, hBits is authorised to call
                  and assist User and/or SMS/E-Mail the customers by virtue of
                  them having voluntarily registered in{' '}
                  <a href="https://www.hbits.co" style={{ fontWeight: 'bold' }}>
                    www.hbits.co
                  </a>{' '}
                  . hBits, will not be responsible for any NDNC Registry
                  regulation that will come into play.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  18. Survival of Terms After Agreement Ends
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Notwithstanding any other provisions of this Terms of Use or
                  the Privacy Policy, or any general legal principles to the
                  contrary, any provision of this Terms of Use that imposes or
                  contemplates continuing obligations on the user will survive
                  the expiration or termination of this Terms of Use.
                </Text>
              </Flex>

              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  19. Modification
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits reserves the right to delete, modify, change, update or
                  discontinue any or all of the information on this Website
                  without giving notice to any User, individual, group of
                  individuals, institutions and any such governing bodies.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  20. Password Protection
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  We do not represent or warrant that the Website will be
                  available and meet User’s requirements, that access will not
                  be interrupted, that there will be no delays, failures, errors
                  or loss of transmitted information, that no viruses or other
                  contaminating or destructive properties will be transmitted or
                  that no damage or loss will occur to User’s computer system.
                  User have sole responsibility for adequate protection and back
                  up of data and/or equipment and for undertaking reasonable and
                  appropriate precautions to scan for computer viruses or other
                  destructive properties. We make no representations or
                  warranties regarding the accuracy, functionality or
                  performance of any third party software that may be used in
                  connection with the Website.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  21. Investigations
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits reserves the right to investigate complaints or reported
                  violations of these Terms of Use and to take any action we
                  deem necessary and appropriate. Such action may include but
                  not be limited to reporting any suspected unlawful activity to
                  law enforcement officials, regulators, or other third parties.
                  In addition, we may take action to disclose any information
                  necessary or appropriate to such persons or entities relating
                  to User’s profiles, e-mail addresses, usage history, IP
                  addresses and traffic information.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  22. Legal Remedies
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  hBits reserves the right to seek all remedies available at law
                  and in equity for violations of these Terms by the User.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  23. Privacy Policy
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  Please review our Privacy Policy to understand what kind of
                  information we gather from User and the specific measures we
                  take to protect User’ personal information. The Privacy Policy
                  is hereby incorporated into these Terms of Use by reference.
                </Text>
              </Flex>
              <Flex flexDirection={'column'} mt={5}>
                <Text fontSize="xl" as="b">
                  24. Reach us
                </Text>
                <Text fontSize="lg" fontWeight={'400'} mt={3}>
                  If user has any questions or comments regarding the Terms of
                  Use, please contact us via email at{' '}
                  <a href="mailto:info@hbits.co" style={{ fontWeight: 'bold' }}>
                    info@hbits.co
                  </a>
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};
export default TncModal;
