import { AccountManager, fetchAsync, HTTPMethod } from '@hBits.Core';
import endpoints, { baseApiUrl } from 'libs/src/config/endpoints';

export const getListOfPropertiesInvestedIn = async () => {
  try {
    const req = {
      method: HTTPMethod.GET,
      data: {},
      url: baseApiUrl + endpoints.channelPartners + endpoints.overview,
    };
    return fetchAsync(req).then((response) => {
      return response.data;
    });
  } catch (_error) {
    return null;
  }
};

export const getChannelParterDeatils = async () => {
  const accountId = AccountManager.getAccountId();
  try {
    const req = {
      method: HTTPMethod.GET,
      data: {},
      url: baseApiUrl + endpoints.channelPartners + `/${accountId}`,
    };
    return fetchAsync(req).then((response) => {
      return response.data;
    });
  } catch (_error) {
    return null;
  }
};
