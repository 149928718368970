import React, { useEffect, useMemo, useState } from 'react';
import { Text, Flex, Select } from '@chakra-ui/react';
import { Strings, AccountManager } from '@hBits.Core';
import { NUM_DIFFERENTIATION } from 'apps/landing-pages-web/src/components/common/utils/helper';
import colors from 'libs/src/theme/colors';
import { Colors } from 'apps/customer-portal-web/src/theme';
import styles from '../mainsection.module.scss';
import Dounut from '../../Charts/DounutChart/Dounut';
import { fetchInvestmentStats } from 'apps/customer-portal-web/src/services/investment-dashboard/Overview';
import PropertyCard from './PropertyCard';
import SinglePropertyCard from './SinglePropertyCard';
import InvestmentInfoCard from './InvestmentInfoCard';
import {
  useAppDispatch,
  useAppSelector,
} from 'apps/customer-portal-web/src/store/hooks';
import { getAllProperties } from 'apps/customer-portal-web/src/store/actions/investment.actions';

const Overview = () => {
  const dispatch = useAppDispatch();

  const [stats, setStats] = useState({
    locations: { data: [], labels: [''] },
    properties: { data: [], labels: [''] },
    totalInvestmentValue: 0,
    totalPropertiesOwned: 0,
    totalEarnings: 0,
  });

  const accountId = AccountManager.getAccountId();

  const [selectedLocation, setSelectedLocation] = useState<string>();

  const properties = useAppSelector(
    (state) => state.InvestmentReducer.properties,
  );

  const fetchData = async () => {
    const res = await Promise.allSettled([
      fetchInvestmentStats(accountId),
      dispatch(getAllProperties(accountId)),
    ]);

    const [resStats, resProperties] = res;
    if (resStats.status === 'fulfilled') {
      setStats(() => {
        const locationsData: number[] = [];
        const locationsLabels: string[] = [];
        const propertiesData: number[] = [];
        const propertiesLabels: string[] = [];

        const processItems = (
          items: any[],
          dataArray: number[],
          labelArray: string[],
        ) => {
          items.forEach((item) => {
            if (item.investmentPercentage) {
              dataArray.push(item.investmentPercentage);
              labelArray.push(item.name);
            }
          });
        };

        processItems(
          resStats.value?.locations || [],
          locationsData,
          locationsLabels,
        );
        processItems(
          resStats.value?.properties || [],
          propertiesData,
          propertiesLabels,
        );

        return {
          ...resStats.value,
          locations: {
            data: locationsData,
            labels: locationsLabels,
          },
          properties: {
            data: propertiesData,
            labels: propertiesLabels,
          },
          totalPropertiesOwned: propertiesData.length,
        };
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const locations = useMemo(() => {
    return [
      ...new Set(
        properties.map((p: any) => {
          return p?.propertyDetails?.locations[0]?.city;
        }),
      ),
    ].sort();
  }, [properties]);

  const filteredProperties = useMemo(() => {
    if (!selectedLocation) {
      return properties;
    }
    return properties?.filter(
      (p: any) => p?.propertyDetails?.locations[0]?.city === selectedLocation,
    );
  }, [properties, selectedLocation]);

  const renderProperties = () => {
    const PropertyComponent =
      filteredProperties?.length === 1 ? SinglePropertyCard : PropertyCard;

    return (
      <Flex wrap={'wrap'}>
        {filteredProperties.map((p: any) => {
          return (
            <PropertyComponent
              key={p.propertyId}
              propertyId={p?.propertyId}
              displayName={p?.propertyDetails?.displayName}
              ownedPercentage={p?.percentageOwned}
              city={p?.propertyDetails?.locations[0]?.city}
              image={
                p?.propertyDetails?.propertyMediaModel?.propertyBannerBox[0]
                  ?.imageUrl
                  ? p?.propertyDetails?.propertyMediaModel?.propertyBannerBox[0]
                      ?.imageUrl
                  : p?.propertyDetails?.propertyMediaModel?.propertyBannerBox[0]
              }
              investedValue={p?.totalInvestmentAmount}
              monthlyRent={Math.round(
                p?.propertyDetails?.propertyFinancials?.contractualAnnualRent /
                  12,
              )}
              showViewDetails={true}
              investmentStage={p?.investmentStage}
            />
          );
        })}
      </Flex>
    );
  };
  const textSlicer = (value: string = '', dlimiter: number = 0) => {
    return value?.length >= dlimiter
      ? `${value.substring(0, dlimiter)}..`
      : value;
  };

  const getTrimedGraphLabels = (data: any = [], dlimiter: number) => {
    return data.map((item: any) => textSlicer(item, dlimiter));
  };

  return (
    <Flex flex={1} px={5} flexDirection={'column'} mb={100}>
      <Flex
        pt={3}
        className={styles['box-shadow-contsiner']}
        bg={Colors.white}
        borderRadius={4}
        flexDirection={['column', 'row', 'row', 'row']}
        justifyContent={'center'}
        alignItems={'center'}
        hidden={!stats.totalPropertiesOwned}
      >
        <Dounut
          title={'Location Overview'}
          data={stats?.locations?.data}
          labels={getTrimedGraphLabels(stats?.locations?.labels, 15)}
          colors={[Colors.buttonColor, Colors.headerColor, '#003cd5']}
        />
        <Dounut
          title={'Property Overview'}
          data={stats?.properties?.data}
          labels={getTrimedGraphLabels(stats?.properties?.labels, 15)}
          colors={[Colors.buttonColor, Colors.headerColor, '#003cd5']}
        />
      </Flex>
      <Flex
        mt={stats.totalPropertiesOwned ? 10 : 0}
        justifyContent={'space-between'}
        flexDirection={['column', 'column', 'column', 'row']}
      >
        <InvestmentInfoCard
          label={'Total Amount Invested'}
          value={`₹${NUM_DIFFERENTIATION(stats.totalInvestmentValue)}`}
        />
        <InvestmentInfoCard
          label={'Total Earnings'}
          value={`₹${NUM_DIFFERENTIATION(stats.totalEarnings, false)}`}
        />
        <InvestmentInfoCard
          label={'Properties Owned'}
          value={stats.totalPropertiesOwned}
        />
      </Flex>
      <Flex mt={10} flexDirection="column">
        <Flex alignItems={'center'} mb={[0, 5]}>
          <Text fontSize="lg" as={'b'} color={Colors.headerColor} mr={2}>
            {Strings.properties}
          </Text>
          {locations?.length > 1 && (
            <>
              <Text fontSize="lg" color={Colors.headerColor} mr={2}>
                |
              </Text>
              <Select
                w="auto"
                variant="unstyled"
                borderRadius={5}
                onChange={(event) => setSelectedLocation(event.target.value)}
                color={Colors.headerColor}
                fontWeight="500"
              >
                <option
                  value=""
                  style={{
                    color: Colors.headerColor,
                    fontWeight: '400',
                  }}
                >
                  Ownership by location
                </option>
                {locations.map((name: any) => (
                  <option
                    value={name}
                    style={{
                      color: Colors.headerColor,
                      fontWeight: '400',
                    }}
                  >
                    {name}
                  </option>
                ))}
              </Select>
            </>
          )}
        </Flex>
        {renderProperties()}
      </Flex>
    </Flex>
  );
};

export default Overview;
