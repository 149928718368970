import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'apps/customer-portal-web/src/theme';
import styles from '../../styles/Overview.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAddClient } from 'apps/customer-portal-web/src/constants/globalConstants';

interface InvestmentDetailCardProps {
  label: string;
  value: any;
  isClient?: any;
}

const InvestmentDetailCard = ({
  label,
  value,
  isClient,
}: InvestmentDetailCardProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const redirectToAddClient = () => {
    navigate({
      pathname: location.pathname,
      search: `?${isAddClient}=${true}`,
      hash: 'clients',
    });
  };

  return (
    <Flex
      className={styles['box-shadow-contsiner']}
      w={['100%', '31%']}
      h={'150px'}
      bg={Colors.white}
      borderRadius={8}
      flexDirection={'column'}
      justifyContent="center"
      alignItems={'center'}
      py={10}
      borderTopColor={Colors.buttonColor}
      borderTopWidth={2}
      mt={[5, 5, 5, 0]}
    >
      <Flex
        flexDirection={isClient && window.innerWidth < 990 ? 'row' : 'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Text fontSize={'30px'} as={'b'} color={Colors.headerColor}>
          {value}
        </Text>
        <Text
          fontSize="18px"
          color={Colors.grey200}
          mb={{ base: 0, md: 2 }}
          ml={{ base: 2, md: 2 }}
          fontWeight={'400'}
        >
          {label}
        </Text>
      </Flex>
      {isClient && (
        <Button
          bgColor={Colors.bgDarkBlue}
          color={Colors.orangeColor}
          onClick={() => redirectToAddClient()}
          className={styles['add_client_btn_overview']}
          _hover= {{bg: Colors.bgDarkBlue}}
          _active={{bg: Colors.bgDarkBlue}}
          mt = {[3, 3, 0, 0]}
          py={'8px'}
        >
          <i className="fa-solid fa-plus"></i>
          <span className="px-2" style={{ fontSize: '14px' }}>
            ADD CLIENT
          </span>
        </Button>
      )}
    </Flex>
  );
};

export default InvestmentDetailCard;
